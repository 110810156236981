import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import { getErrorToastMessage, getSuccessToastMessage } from "@/utils/common";
import { downloadFile } from "@/utils/actions";
import { AVAILABLE_FILTER_KEYS } from "@/utils/constants/filters";
import vToast from "@/utils/vToast";

import {
  EXPENSE_FORM_VIEWS,
  SELECTED_EXPENSES_STATS_TAB,
} from "@/constants/expense";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import API from "@/api";

import { setIsFormSubmissionProgress } from "./loadersError";

const expensesInitialState = {
  expenses: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetching: true,
    hasMore: true,
  },
  sliderExpenseList: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetching: false,
    hasMore: true,
  },
  selectedExpenseTab: SELECTED_EXPENSES_STATS_TAB?.[0],
  expenseListOnSliderViewState: {
    expenseListSliderPaginationResetBool: true,
    selectedExpenseItemId: null,
  },
  unsettledExpenses: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetching: false,
    hasMore: true,
  },
  repayments: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetching: false,
    hasMore: true,
  },
  isDeclineDetailsFetching: false,
  declineDetails: null,
  splitExpense: null,
  isFetchingSplitExpense: false,
  isSplitExpenseSuccess: false,
  isFlagExpenseInProcess: false,
  spendOverviewData: null,
  isSpendOverviewFetching: false,
  creditOverviewData: null,
  isCreditOverviewFetching: false,
  creditDetails: null,
  isFetchingCreditDetails: false,
  expenseDetails: null,
  dataActionInProgressId: null,
  isFetchingExpenseDetails: false,
  missingDetails: {
    userRemindStatus: false,
    expandStatusIndex: null,
  },
  isFetchingMissingDetails: false,
  isFetchingStatistics: false,
  selectedExpense: null,
  selectedExpenseActivity: null,
  isFetchingExpense: false,
  isFetchingExpenseActivity: false,
  expenseOverviewDetails: null,
  isExpenseOverviewDetails: false,
  isBulkApprovalInProgress: false,
  uploadedReceipts: null,
  selectedExpenseCategory: 10,
  undoSplitExpense: false,
  isUndoSplitInProgress: false,
  isFetchingReceipts: false,
  isValidAccountingSlider: false,
  missingDetailsCount: null,
  filtersKey: {
    overview: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.amount,
      AVAILABLE_FILTER_KEYS.submissionPolicyStatus,
      AVAILABLE_FILTER_KEYS.transactionStatus,
      AVAILABLE_FILTER_KEYS.receiptStatus,
      AVAILABLE_FILTER_KEYS.department,
    ],

    needsReview: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.amount,
      AVAILABLE_FILTER_KEYS.submissionPolicyStatus,
      AVAILABLE_FILTER_KEYS.transactionStatus,
      AVAILABLE_FILTER_KEYS.receiptStatus,
      AVAILABLE_FILTER_KEYS.department,
    ],
    flagged: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.amount,
      AVAILABLE_FILTER_KEYS.submissionPolicyStatus,
      AVAILABLE_FILTER_KEYS.receiptStatus,
      AVAILABLE_FILTER_KEYS.flaggedBy,
    ],
    declines: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.amount,
      AVAILABLE_FILTER_KEYS.declineReason,
    ],
  },
  myVolopayFiltersKey: {
    overview: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.amount,
      AVAILABLE_FILTER_KEYS.submissionPolicyStatus,
      AVAILABLE_FILTER_KEYS.transactionStatus,
      AVAILABLE_FILTER_KEYS.receiptStatus,
      AVAILABLE_FILTER_KEYS.accountingStatus,
      AVAILABLE_FILTER_KEYS.copilots,
    ],
    flagged: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.amount,
      AVAILABLE_FILTER_KEYS.submissionPolicyStatus,
      AVAILABLE_FILTER_KEYS.receiptStatus,
      AVAILABLE_FILTER_KEYS.accountingStatus,
      AVAILABLE_FILTER_KEYS.flaggedBy,
      AVAILABLE_FILTER_KEYS.copilots,
    ],
    declines: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.amount,
      AVAILABLE_FILTER_KEYS.declineReason,
      AVAILABLE_FILTER_KEYS.copilots,
    ],
  },
  globalMerchantDetails: null,
  isFetchingMerchantDetails: false,
  changedLinkedToStatus: {
    isChanged: false,
    inProgress: false,
  },
  missingDetailsPeople: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: 0,
    isFetchingPeople: false,
    hasMore: true,
  },
};

// Expenses Reducers
const expensesSlice = createSlice({
  name: "expenses",
  initialState: expensesInitialState,
  reducers: {
    setInitialStateExpenses(state) {
      state.expenses = expensesInitialState.expenses;
    },
    setExpenses(state, action) {
      state.expenses.list = action.payload;
    },
    setSliderExpenses(state, action) {
      state.sliderExpenseList.list = action.payload;
    },
    setSliderOnResetBoolean(state, action) {
      state.expenseListOnSliderViewState.expenseListSliderPaginationResetBool =
        action.payload;
    },
    setSelectedExpenseId(state, action) {
      state.expenseListOnSliderViewState.selectedExpenseItemId = action.payload;
    },
    setSelectedExpenseSliderTab(state, action) {
      state.selectedExpenseTab = action.payload;
    },
    setSliderpage(state, action) {
      state.sliderpage = action.payload;
    },
    setSplitExpenseSliderId(state, action) {
      state.splitExpenseSliderId = action.payload;
    },
    setExpense(state, action) {
      const { id, value } = action.payload;
      const expenseIndex = state.expenses.list.findIndex(
        (item) => +item.id === +id
      );

      if (expenseIndex !== -1) {
        state.expenses.list[expenseIndex] = value;
      }
    },
    resetExpensesListAndPagination(state) {
      state.expenses.list = [];
      state.expenses.page = 1;
      state.expenses.hasMore = true;
      state.expenses.total = 0;
    },
    updatedExpenses(state, action) {
      state.expenses.list = state.expenses.list.map((expense) => {
        if (expense.id === action.payload.id) {
          return { ...expense, ...action.payload };
        }
        return expense;
      });
    },
    resetSliderExpensesListAndPagination(state) {
      state.sliderExpenseList = {
        list: [],
        page: 1,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        total: null,
        isFetching: false,
        hasMore: true,
      };
    },
    addExpenses(state, action) {
      state.expenses.list = [...state.expenses.list, ...action.payload];
    },
    addSliderExpenses(state, action) {
      state.sliderExpenseList.list = [
        ...state.sliderExpenseList.list,
        ...action.payload,
      ];
    },
    deleteExpense(state, action) {
      state.expenses.list = state.expenses.list.filter(
        (val) => val?.id !== action.payload
      );
    },
    setIsExpensesFetching(state, action) {
      state.expenses.isFetching = action.payload;
    },
    setSliderIsExpensesFetching(state, action) {
      state.sliderExpenseList.isFetching = action.payload;
    },
    setSelectedExpense(state, action) {
      state.selectedExpense = action.payload;
    },
    setExpensespage(state, action) {
      state.expenses.page = action.payload;
    },
    setSliderExpensesPage(state, action) {
      state.sliderExpenseList.page = action.payload;
    },
    setExpensesTotal(state, action) {
      state.expenses.total = action.payload;
    },
    setSelectedExpenseCategory(state, action) {
      state.selectedExpenseCategory = action.payload;
    },
    setSliderExpensesTotal(state, action) {
      state.sliderExpenseList.total = action.payload;
    },
    setExpensesHasMore(state) {
      state.expenses.hasMore =
        state.expenses.list.length < state.expenses.total;
    },
    setSliderExpensesHasMore(state) {
      state.sliderExpenseList.hasMore =
        state.sliderExpenseList.list.length < state.sliderExpenseList.total;
    },
    setExpensesLimit(state, action) {
      state.expenses.limit = action.payload;
    },
    setSliderExpensesLimit(state, action) {
      state.sliderExpenseList.limit = action.payload;
    },
    setSpendOverviewData(state, action) {
      state.spendOverviewData = action.payload;
    },
    setIsSpendOverviewFetching(state, action) {
      state.isSpendOverviewFetching = action.payload;
    },
    setCreditOverviewData(state, action) {
      state.creditOverviewData = action.payload;
    },
    setIsCreditOverviewFetching(state, action) {
      state.isCreditOverviewFetching = action.payload;
    },
    setCreditDetails(state, action) {
      state.creditDetails = action.payload;
    },
    setIsFetchingCreditDetails(state, action) {
      state.isFetchingCreditDetails = action.payload;
    },
    setMissingDetails(state, action) {
      state.missingDetails.data = action.payload;
    },
    setUserIdsForMissingDetails(state, action) {
      state.missingDetails.userIds = [action.payload];
    },
    setIsFetchingMissingDetails(state, action) {
      state.isFetchingMissingDetails = action.payload;
    },
    selectExpense(state, action) {
      state.selectedExpense = action.payload;
    },
    selectExpenseActivity(state, action) {
      state.selectedExpenseActivity = action.payload;
    },
    setIsFetchingSelectedExpense(state, action) {
      state.isFetchingExpense = action.payload;
    },
    setExpenseActivityFetchState(state, action) {
      state.isFetchingExpenseActivity = action.payload;
    },
    setRepayments(state, action) {
      state.repayments.list = action.payload;
    },
    resetRepayments(state) {
      state.repayments.list = [];
      state.repayments.page = 1;
      state.repayments.hasMore = true;
      state.repayments.total = 0;
    },
    addRepayments(state, action) {
      state.repayments.list = [...state.repayments.list, ...action.payload];
    },
    setIsFetchingRepayments(state, action) {
      state.repayments.isFetching = action.payload;
    },
    setRepaymentspage(state, action) {
      state.repayments.page = action.payload;
    },
    setRepaymentsTotal(state, action) {
      state.repayments.total = action.payload;
    },
    setRepaymentsHasMore(state) {
      state.repayments.hasMore =
        state.repayments.list.length < state.repayments.total;
    },
    setRepaymentsLimit(state, action) {
      state.repayments.limit = action.payload;
    },
    setExpenseOverviewDetails(state, action) {
      state.expenseOverviewDetails = action.payload;
    },
    setIsExpenseOverviewDetails(state, action) {
      state.isExpenseOverviewDetails = action.payload;
    },
    setExpenseDetails(state, action) {
      state.expenseDetails = action.payload;
    },
    setIsExpenseDetails(state, action) {
      state.isFetchingExpenseDetails = action.payload;
    },
    setIsFetchingStatistics(state, action) {
      state.isFetchingStatistics = action.payload;
    },
    setUnsettledExpense(state, action) {
      state.unsettledExpenses.list = action.payload;
    },
    addUnsettledExpense(state, action) {
      state.unsettledExpenses.list = [
        ...state.unsettledExpenses.list,
        ...action.payload,
      ];
    },
    setIsFetchingUnsettledExpenses(state, action) {
      state.unsettledExpenses.isFetching = action.payload;
    },
    setUnexpensespage(state, action) {
      state.unsettledExpenses.page = action.payload;
    },
    setUnexpensesTotal(state, action) {
      state.unsettledExpenses.total = action.payload;
    },
    setUnexpensesHasMore(state) {
      state.unsettledExpenses.hasMore =
        state.expenses.list.length < state.expenses.total;
    },
    setUnexpensesLimit(state, action) {
      state.unsettledExpenses.limit = action.payload;
    },
    setSplitExpense(state, action) {
      state.splitExpense = action.payload;
    },
    setIsFetchingSplitExpense(state, action) {
      state.isFetchingSplitExpense = action.payload;
    },
    setUploadedReceipts(state, action) {
      state.uploadedReceipts = action.payload;
    },
    setIsFetchingReceipts(state, action) {
      state.isFetchingReceipts = action.payload;
    },
    setIsBulkApprovalInProgress(state, action) {
      state.isBulkApprovalInProgress = action.payload;
    },

    setAttachment(state, action) {
      const updatedReceipts = action.payload;

      state.selectedExpense = {
        ...state.selectedExpense,
        receipts: updatedReceipts,
      };
    },
    setIsFlagExpenseInProcess(state, action) {
      state.isFlagExpenseInProcess = action.payload;
    },
    setIsSplitExpenseSuccess(state, action) {
      state.isSplitExpenseSuccess = action.payload;
    },
    setIsDeclineDetailsFetching(state, action) {
      state.isDeclineDetailsFetching = action.payload;
    },
    setDeclineDetails(state, action) {
      state.declineDetails = action.payload;
    },
    setIsValidAccountingSlider(state, action) {
      state.isValidAccountingSlider = action.payload;
    },
    setMerchantDetails(state, action) {
      state.globalMerchantDetails = action.payload;
    },
    setIsFetchingMerchantDetails(state, action) {
      state.isFetchingMerchantDetails = action.payload;
    },
    setMissingDetailsCount(state, action) {
      state.missingDetailsCount = action.payload;
    },
    setIsLinkedToChanged(state, action) {
      state.changedLinkedToStatus.isChanged = action.payload;
    },
    setIsLinkedToInprogress(state, action) {
      state.changedLinkedToStatus.inProgress = action.payload;
    },
    setUndoSplitExpense(state, action) {
      state.undoSplitExpense = action.payload;
    },
    setIsUndoSplitInProgress(state, action) {
      state.isUndoSplitInProgress = action.payload;
    },
    setUserRemindStatus(state, action) {
      state.missingDetails.userRemindStatus = action.payload;
    },
    setExpandStatusIndexIndex(state, action) {
      state.missingDetails.expandStatusIndex = action.payload;
    },
    setMissingDetailsUsers(state, action) {
      state.missingDetailsPeople.list = action.payload;
    },
    addMissingDetailsUsers(state, action) {
      state.missingDetailsPeople.list = [
        ...state.missingDetailsPeople.list,
        ...action.payload,
      ];
    },
    setIsFetchingMissingDetailsUsers(state, action) {
      state.missingDetailsPeople.isFetching = action.payload;
    },
    setMissingDetailsUsersTotal(state, action) {
      state.missingDetailsPeople.total = action.payload;
    },
    setMissingDetailsUsersListLimit(state, action) {
      state.missingDetailsPeople.limit = action.payload;
    },
    setMissingDetailsUserListHasMore(state) {
      state.missingDetailsPeople.hasMore =
        state.missingDetailsPeople.list?.length <
        state.missingDetailsPeople.total;
    },
    setMissingDetailsUsersListPage(state, action) {
      state.missingDetailsPeople.page = action.payload;
    },

    setDataActionInProgressId(state, action) {
      state.dataActionInProgressId = action.payload;
    },
    resetMissingDetailsListStoreState(state) {
      state.missingDetailsPeople = {
        list: [],
        page: 1,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        total: null,
        isFetching: false,
        hasMore: true,
      };
    },
  },
});

// Expenses Actions
export const fetchExpenses = createAsyncThunk(
  "expenses/fetchExpenses",
  async (params, { dispatch }) => {
    dispatch(setIsExpensesFetching(true));

    const [error, response] = await to(API.Expenses.all(params));
    if (response?.data) {
      if (response.data?.page === 1) {
        dispatch(setExpenses(response.data.list));
      } else {
        dispatch(addExpenses(response.data.list));
      }
      dispatch(setExpensesLimit(response.data.limit));
      dispatch(setExpensesTotal(response.data.total));
      dispatch(setExpensespage(response.data.page));
      dispatch(setExpensesHasMore());
      dispatch(setIsExpensesFetching(false));
    } else if (error) {
      dispatch(setIsExpensesFetching(false));
    }
  }
);
export const retrieveExpenseSliderTransactionList = createAsyncThunk(
  "expenses/fetchExpenses",
  async (params, { dispatch }) => {
    dispatch(setSliderIsExpensesFetching(true));

    const [error, response] = await to(API.Expenses.all(params));
    if (response.data) {
      if (response.data?.page === 1) {
        dispatch(setSliderExpenses(response.data.list));
      } else {
        dispatch(addSliderExpenses(response.data.list));
      }
      dispatch(setSliderExpensesLimit(response.data.limit));
      dispatch(setSliderExpensesTotal(response.data.total));
      dispatch(setSliderExpensesPage(response.data.page));
      dispatch(setSliderExpensesHasMore());
    }
    dispatch(setSliderIsExpensesFetching(false));
  }
);

export const fetchUnsettledexpenses = createAsyncThunk(
  "expenses/fetchUnsettledexpenses",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingUnsettledExpenses(true));

    const [err, response] = await to(API.Expenses.all(params));

    if (response.data) {
      if (params.page === 1) {
        dispatch(setUnsettledExpense(response.data.list));
      } else {
        dispatch(addUnsettledExpense(response.data.list));
      }
      dispatch(setUnexpensesLimit(response.data.limit));
      dispatch(setUnexpensesTotal(response.data.total));
      dispatch(setUnexpensespage(response.data.page));
      dispatch(setUnexpensesHasMore());
    }
    dispatch(setIsFetchingUnsettledExpenses(false));
  }
);

export const fetchAndSelectExpense = createAsyncThunk(
  "expense/fetchAndSelectExpense",
  async (params, { dispatch }) => {
    const { expenseId, onSuccess = () => {} } = params;
    dispatch(setIsFetchingSelectedExpense(true));
    const [err, response] = await to(API.Expenses.get({ expenseId }));

    if (!err && response) {
      dispatch(setSelectedExpense(response.data));
      onSuccess(response.data);
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFetchingSelectedExpense(false));
  }
);

export const fetchSelectedExpenseActivity = createAsyncThunk(
  "expense/fetchSelectedExpenseActivity",
  async ({ expenseId, view = EXPENSE_FORM_VIEWS.ACCOUNTING }, { dispatch }) => {
    dispatch(setExpenseActivityFetchState(true));

    const response = await API.Expenses.getActivities(expenseId);
    dispatch(setExpenseActivityFetchState(false));
    dispatch(selectExpenseActivity(response));
  }
);

export const fetchSpendCreditOverview = createAsyncThunk(
  "expenses/fetchSpendCreditOverview",
  async (params, { dispatch }) => {
    dispatch(setIsSpendOverviewFetching(true));
    setTimeout(async () => {
      const [err, response] = await to(API.Expenses.getCreditDetails());

      if (response.data) {
        dispatch(setSpendOverviewData(response.data));
      }
      dispatch(setIsSpendOverviewFetching(false));
    }, 2000);
  }
);

export const fetchStatistics = createAsyncThunk(
  "expenses/fetchStatistics",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingStatistics(true));

    await Promise.all([
      dispatch(fetchExpenseOverviewDetails(params)),
      dispatch(fechMissingDetailsCount(params)),
    ]);

    dispatch(setIsFetchingStatistics(false));
  }
);
export const uploadReceipts = createAsyncThunk(
  "expenses/uploadReceipts",
  async (params, { dispatch }) => {
    const { onSuccess, ...rest } = params;

    dispatch(setIsFetchingReceipts(true));

    const [error, response] = await to(API.Expenses.uploadRecipts(rest));

    if (response) {
      dispatch(setSelectedExpense(response.data?.expense));

      vToast(getSuccessToastMessage(response));
      if (onSuccess) {
        onSuccess(response?.data?.expense);
      }
    } else if (error) {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsFetchingReceipts(false));
  }
);
export const fetchMissingDetails = createAsyncThunk(
  "expenses/fetchMissingDetails",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingMissingDetails(true));
    const [error, response] = await to(API.Expenses.getMissingDetils(params));
    if (response) {
      dispatch(setMissingDetails(response.data));
    }
    dispatch(setIsFetchingMissingDetails(false));
  }
);
export const fetchExpenseOverviewDetails = createAsyncThunk(
  "expense/fetchExpenseOverviewDetails",
  async (params, { dispatch }) => {
    dispatch(setIsExpenseOverviewDetails(true));
    const [error, response] = await to(
      API.Expenses.fetchExpenseDetails(params)
    );

    if (response) {
      dispatch(setExpenseOverviewDetails(response.data));
    }
    dispatch(setIsExpenseOverviewDetails(false));
  }
);
export const updateSelectedExpense = createAsyncThunk(
  "expenses/updateSelectedExpense",
  async (params, { dispatch }) => {
    const response = await API.Expenses.get(params.expenseId);
    if (response) {
      dispatch(selectExpense({ ...response, memo: params.payload }));
    }
  }
);

export const updateSplitExpenses = createAsyncThunk(
  "expenses/updateSplitExpenses",
  async (params, { dispatch }) => {
    const response = await API.Expenses.get(params);
    const splitExpenses = params.data;

    if (response) {
      dispatch(selectExpense({ ...response, splitExpenses }));
    }
  }
);

export const fetchExpenseSplit = createAsyncThunk(
  "expenses/fetchExpenseSplit",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingSplitExpense(true));
    const [err, response] = await to(API.Expenses.getSplitExpense(params));

    if (response?.data) dispatch(setSplitExpense(response?.data));
    dispatch(setIsFetchingSplitExpense(false));
  }
);
export const updateExpenseSplit = createAsyncThunk(
  "expenses/updateExpenseSplit",
  async (params, { dispatch }) => {
    dispatch(setIsSplitExpenseSuccess(false));
    dispatch(setIsFetchingSplitExpense(true));
    const { onSuccess = () => {}, ...rest } = params;
    const [err, response] = await to(API.Expenses.updateSplitExpense(rest));
    if (!err && response.data) {
      dispatch(setIsSplitExpenseSuccess(true));
      onSuccess();
      vToast(getSuccessToastMessage());
    } else {
      vToast(getErrorToastMessage(err.message));
    }
    dispatch(setIsFetchingSplitExpense(false));
  }
);
export const updateExpense = createAsyncThunk(
  "expenses/updateExpense",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const { onSuccess, ...rest } = params;
    const [error, response] = await to(API.Expenses.update(rest));

    if (!error && response?.data) {
      vToast(getSuccessToastMessage(response, response?.data));
      dispatch(setSelectedExpense(response?.data?.expense));
      dispatch(updatedExpenses(response?.data));
      if (onSuccess) onSuccess(response?.data?.expense);
    } else vToast(getErrorToastMessage(error));

    dispatch(setIsFormSubmissionProgress(false));
  }
);
export const flagExpense = createAsyncThunk(
  "expenses/flagExpense",
  async (params, { dispatch }) => {
    const { id, payload, onSuccess, ...rest } = params;
    dispatch(setIsFlagExpenseInProcess(true));
    const [error, response] = await to(
      API.Expenses.flagExpense({
        expenseId: id,
        payload,
      })
    );

    if (!error && response?.data) {
      vToast(getSuccessToastMessage(response, response?.data));
      params.reset();
      if (onSuccess) onSuccess();
    } else vToast(getErrorToastMessage(error));

    dispatch(setIsFlagExpenseInProcess(false));
  }
);
export const bulkApprove = createAsyncThunk(
  "expenses/bulkApprove",
  async (params, { dispatch }) => {
    const { payload, onSuccess, ...rest } = params;
    dispatch(setIsBulkApprovalInProgress(true));

    const [error, response] = await to(API.Expenses.bulkApprove(payload));

    if (!error && response?.data) {
      vToast(getSuccessToastMessage(response, response?.data));
      dispatch(setIsBulkApprovalInProgress(false));
      if (onSuccess) onSuccess();
    } else vToast(getErrorToastMessage(error));
  }
);

export const deleteExpenseAttachment = createAsyncThunk(
  "expenses/deleteExpenseAttachment",
  async (params, { dispatch }) => {
    const { id, onSuccess, ...rest } = params; // id of main entity, not attachment id
    const [error, response] = await to(API.Misc.deleteAttachment({ id }));

    if (error) {
      vToast(getErrorToastMessage(error));
    }

    if (!error && response) {
      vToast(getSuccessToastMessage(response));

      if (onSuccess) onSuccess(response?.data);
    }
  }
);
export const downloadExpenseAttachment = createAsyncThunk(
  "expenses/deleteAttachment",
  async (params, { dispatch }) => {
    const { file, receiptId } = params;
    const [error, response] = await to(API.Misc.getAttachementUrl(receiptId));

    if (error) {
      vToast(getErrorToastMessage(error));
    }

    if (!error && response) {
      downloadFile(response?.data?.url, file?.fileName, file?.contentType);

      vToast(getSuccessToastMessage(response));
    }
  }
);
export const remindExpense = createAsyncThunk(
  "expenses/remindExpense",
  async (params, { dispatch }) => {
    const { payload, onSuccess = null } = params;
    dispatch(setIsFormSubmissionProgress(true));
    const [error, response] = await to(API.Expenses.remind(payload));

    if (response) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) {
        onSuccess();
      }
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsFormSubmissionProgress(false));
  }
);
export const dismissSubmissionPolicy = createAsyncThunk(
  "expenses/remindExpense",
  async ({ expenseId, onSuccess }, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const [error, response] = await to(
      API.Expenses.dismissSubmissionPolicy(expenseId)
    );

    if (!error && response) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) onSuccess();
    } else {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsFormSubmissionProgress(false));
  }
);
export const requestRepayment = createAsyncThunk(
  "expenses/requestRepayment",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const [error, response] = await to(
      API.Expenses.requestRepayment(params.params)
    );

    if (error) {
      vToast(getErrorToastMessage(error));
    }

    if (!error && response) {
      vToast(getSuccessToastMessage(response));
      if (params?.reset) params.reset();
    }
    dispatch(setIsFormSubmissionProgress(false));
  }
);
export const requestReceived = createAsyncThunk(
  "expenses/requestReceived",
  async (params, { dispatch }) => {
    const { id, onSuccess = () => {} } = params;

    dispatch(setDataActionInProgressId(id));

    const [error, response] = await to(API.Expenses.repaymentReceived(id));

    if (error) {
      vToast(getErrorToastMessage(error));
    }

    if (!error && response) {
      vToast(getSuccessToastMessage(response));
      onSuccess(response?.data);
    }

    dispatch(setDataActionInProgressId(null));
  }
);
export const repaymentRequestCanceled = createAsyncThunk(
  "expenses/repaymentRequestCanceled",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const [error, response] = await to(
      API.Expenses.cancelRepayment(params.params)
    );
    if (error) {
      vToast(getErrorToastMessage(error));
    }

    if (!error && response) {
      vToast(getSuccessToastMessage(response));
      if (params?.reset) params.reset();
    }
    dispatch(setIsFormSubmissionProgress(false));
  }
);
export const declineDetails = createAsyncThunk(
  "expenses/declineDetails",
  async (params, { dispatch }) => {
    dispatch(setIsDeclineDetailsFetching(true));
    const [error, response] = await to(API.Expenses.declineDetails(params));

    if (!error && response) {
      dispatch(setDeclineDetails(response?.data));
    }
    dispatch(setIsDeclineDetailsFetching(false));
  }
);

export const verifyExpense = createAsyncThunk(
  "expenses/verifyExpense",
  async (params, { dispatch }) => {
    const { onSuccess = () => {}, ...rest } = params;
    dispatch(setIsDeclineDetailsFetching(true));
    const [error, response] = await to(
      API.AccountingTransactions.verifyTransaction(rest)
    );
    if (error) {
      vToast(getErrorToastMessage(error));
    }

    if (!error && response) {
      setSelectedExpense(response?.data);
      vToast(getErrorToastMessage(response));
      onSuccess(response?.data);
    }
    dispatch(setIsDeclineDetailsFetching(false));
  }
);
export const unverifyExpense = createAsyncThunk(
  "expenses/unverifyExpense",
  async (params, { dispatch }) => {
    const { onSuccess = () => {}, ...rest } = params;
    dispatch(setIsDeclineDetailsFetching(true));
    const [error, response] = await to(
      API.AccountingTransactions.unverifyTransaction(rest)
    );
    if (error) {
      vToast(getErrorToastMessage(error));
    }

    if (!error && response) {
      setSelectedExpense(response?.data);
      vToast(getErrorToastMessage(response));
      onSuccess(response?.data);
    }
    dispatch(setIsDeclineDetailsFetching(false));
  }
);
export const markAsSyncedExpense = createAsyncThunk(
  "expenses/markAsSyncedExpense",
  async (params, { dispatch }) => {
    dispatch(setIsDeclineDetailsFetching(true));
    const [error, response] = await to(
      API.AccountingTransactions.markAsSyncedTransaction(params)
    );
    if (error) {
      vToast(getErrorToastMessage(error));
    }

    if (!error && response) {
      setSelectedExpense(response?.data);
      vToast(getErrorToastMessage(response));
    }
    dispatch(setIsDeclineDetailsFetching(false));
  }
);
export const syncedExpense = createAsyncThunk(
  "expenses/syncedExpense",
  async (params, { dispatch }) => {
    const { onSuccess = () => {}, ...rest } = params;
    dispatch(setIsDeclineDetailsFetching(true));
    const [error, response] = await to(
      API.AccountingTransactions.syncTransaction(rest)
    );
    if (error) {
      vToast(getErrorToastMessage(error));
    }

    if (!error && response) {
      setSelectedExpense(response?.data);
      vToast(getErrorToastMessage(response));
      onSuccess(response?.data);
    }
    dispatch(setIsDeclineDetailsFetching(false));
  }
);
export const fetchMerchantDetail = createAsyncThunk(
  "expenses/fetchMerchantDetail",
  async (id, { dispatch }) => {
    dispatch(setIsFetchingMerchantDetails(true));
    const [err, response] = await to(API.Expenses.getMerchantDetails(id));
    if (response) {
      dispatch(setMerchantDetails(response));
    }
    dispatch(setIsFetchingMerchantDetails(false));
  }
);
export const fechMissingDetailsCount = createAsyncThunk(
  "expenses/fechMissingDetailsCount",
  async (params, { dispatch }) => {
    const [err, response] = await to(
      API.Expenses.getMissingDetailsCount({ params })
    );
    if (response) {
      dispatch(setMissingDetailsCount(response?.data?.count));
    }
  }
);

export const changeLinkedTo = createAsyncThunk(
  "cards/card-budgets",
  async (payload, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    dispatch(setIsLinkedToInprogress(true));
    dispatch(setIsLinkedToChanged(false));
    const [err, response] = await to(
      API.Expenses.update({ expenseId: payload?.id, payload })
    );
    if (!err && response) {
      dispatch(setIsLinkedToInprogress(false));
      dispatch(setIsLinkedToChanged(true));
      vToast({ title: "Project switching is sucessfull" });
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFormSubmissionProgress(false));
    // payload id : project_id or id
    // what we have to send here in case the slider opens from card slider.
  }
);
export const undoSplitExpense = createAsyncThunk(
  "expenses/undoExpenseSplit",
  async (params, { dispatch }) => {
    dispatch(setIsUndoSplitInProgress(true));
    const { id, payload, onSuccess = () => {} } = params;
    const [err, response] = await to(API.Expenses.undoSplit(id, payload));
    if (response?.data) {
      vToast(getSuccessToastMessage(response));
      onSuccess(response?.data);
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsUndoSplitInProgress(false));
  }
);

export const fetchUsersWithMissingDetails = createAsyncThunk(
  "company/fetchPeople",
  async (params, { dispatch }) => {
    const { page } = params;
    dispatch(setIsFetchingMissingDetailsUsers(true));
    const [err, response] = await to(API.User.all(params));

    if (response?.data) {
      if (page === 1) {
        dispatch(setMissingDetailsUsers(response.data.list));
      } else {
        dispatch(addMissingDetailsUsers(response.data.list));
      }
      dispatch(setMissingDetailsUsersTotal(response.data.total));
      dispatch(setMissingDetailsUsersListLimit(response.data.limit));
      dispatch(setMissingDetailsUsersListPage(response.data.page));
      dispatch(setMissingDetailsUserListHasMore());
    }
    dispatch(setIsFetchingMissingDetailsUsers(false));
  }
);

// Exporting Modules

export const {
  setExpenses,
  addExpenses,
  setExpense,
  resetExpensesListAndPagination,
  setIsExpensesFetching,
  setSelectedExpense,
  setExpensespage,
  setExpensesTotal,
  setExpensesHasMore,
  setExpensesLimit,
  setSpendOverviewData,
  setIsSpendOverviewFetching,
  setCreditOverviewData,
  setIsCreditOverviewFetching,
  setCreditDetails,
  setIsFetchingCreditDetails,
  setMissingDetails,
  setIsFetchingMissingDetails,
  selectExpense,
  selectExpenseActivity,
  setIsFetchingSelectedExpense,
  setExpenseActivityFetchState,
  setRepayments,
  addRepayments,
  setIsFetchingRepayments,
  setInitialStateExpenses,
  setSliderpage,
  setSplitExpenseSliderId,
  setSliderPageType,
  setExpenseOverviewDetails,
  setIsExpenseOverviewDetails,
  setExpenseDetails,
  setIsExpenseDetails,
  setIsFetchingStatistics,
  setUnsettledExpense,
  addUnsettledExpense,
  setUnexpensespage,
  setUnexpensesTotal,
  setUnexpensesHasMore,
  setUnexpensesLimit,
  setIsFetchingUnsettledExpenses,
  resetRepayments,
  setRepaymentspage,
  setRepaymentsTotal,
  setRepaymentsHasMore,
  setRepaymentsLimit,
  setSplitExpense,
  setIsFetchingSplitExpense,
  setUploadedReceipts,
  setIsFetchingReceipts,
  setIsBulkApprovalInProgress,
  setAttachment,
  setIsFlagExpenseInProcess,
  setIsSplitExpenseSuccess,
  deleteExpense,
  updatedExpenses,
  setIsDeclineDetailsFetching,
  setDeclineDetails,
  setIsValidAccountingSlider,
  setMerchantDetails,
  setIsFetchingMerchantDetails,
  setUserIdsForMissingDetails,
  setMissingDetailsCount,
  setIsLinkedToChanged,
  setIsLinkedToInprogress,
  setUndoSplitExpense,
  setIsUndoSplitInProgress,
  setSliderExpenses,
  addSliderExpenses,
  setSliderExpensesLimit,
  setSliderExpensesTotal,
  setSliderExpensesPage,
  setSliderIsExpensesFetching,
  setSliderExpensesHasMore,
  resetSliderExpensesListAndPagination,
  setUserRemindStatus,
  setExpandStatusIndexIndex,
  setSelectedExpenseSliderTab,
  setSliderOnResetBoolean,
  setSelectedExpenseId,
  setIsFetchingMissingDetailsUsers,
  setMissingDetailsUsers,
  addMissingDetailsUsers,
  setMissingDetailsUsersTotal,
  setMissingDetailsUsersListLimit,
  setMissingDetailsUsersListPage,
  setMissingDetailsUserListHasMore,
  resetMissingDetailsListStoreState,
  setDataActionInProgressId,
  setSelectedExpenseCategory,
} = expensesSlice.actions;

export default expensesSlice.reducer;
