import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import {
  downloadFileFromResponse,
  getErrorToastMessage,
  getSuccessToastMessage,
} from "@/utils/common";
import vToast from "@/utils/vToast";
import API from "@/api";

import { setIsFormSubmissionProgress } from "./loadersError";

const clientInitialState = {
  client: {
    id: null,
    name: null,
    entityName: null,
    entityNumber: null,
    kybStatus: null,
    logoUrl: null,
    baseCountry: null,
    defaultCurrency: null,
    createdAt: null,
    companySize: null,
    clientUrl: null,
    gstNumber: null,
    status: null,
    subdomain: null,
    billPayEnabled: null,
    accountingEnabled: false,
    accountingIntegrationSoftware: null,
    accountingContinuousBillSync: false,
    accountingTransferFeeSync: false,
    cardMultiCurrencyEnabled: null,
    departmentManagerEditEnabled: null,
    projectManagerEditEnabled: null,
    physicalCardPolicyEnabled: null,
    clientAccountEnabled: null,
    clientBudgetType: null,
    creditEnabled: null,
    isCreditWorthy: null,
    creditFreeze: null,
    currentPaymentProvider: null,
    currentCardProvider: null,
    maxApproverLevels: null,
    maxPoliciesInPolicyGroup: null,
    physicalCardsEnabled: null,
    reimbursementEnabled: null,
    splitExpensesEnabled: null,
    cardProviders: [],
    reimbursementReportsEnabled: null,
    paymentsAutoApproveEnabled: false,
    cardsClaimSettlementEnabled: false,
    reimbursementTransactionAllowedDateRange: null,
    slackNotificationEnabled: false,
    whatsappNotificationEnabled: false,
  },
  updatingClientSettings: false,
  isFetching: false,
  isFetchingClientDetails: false,
  accountDetails: null,
  wallets: {
    list: [],
    isFetching: false,
    isFetched: false,
  },
  payrollWallet: null,
  isFetchingPayrollWallet: null,
  isFetchedPayrollWallet: null,
  currencies: null,
  generatedFundQuote: {},
};

const clientSlice = createSlice({
  name: "client",
  initialState: clientInitialState,
  reducers: {
    setClient: (state, action) => {
      state.client = action.payload;
    },
    setIsFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    setAccountDetails: (state, action) => {
      state.accountDetails = action.payload;
    },
    setIsFetchingClientDetails: (state, action) => {
      state.isFetchingClientDetails = action.payload;
    },
    setUpdatingClientSettings: (state, action) => {
      state.updatingClientSettings = action.payload;
    },
    setWallets: (state, action) => {
      state.wallets.list = action.payload;
    },
    resetWallets: (state) => {
      state.wallets = clientInitialState.wallets;
    },
    setIsFetchingAccountWallets: (state, action) => {
      state.wallets.isFetching = action.payload;
    },
    setIsFetchedAccountWallets: (state, action) => {
      state.wallets.isFetched = action.payload;
    },
    setPayrollWallet(state, action) {
      state.payrollWallet = action.payload;
    },
    resetPayrollWallet(state) {
      state.payrollWallet = clientInitialState.payrollWallet;
      state.isFetchingPayrollWallet =
        clientInitialState.isFetchingPayrollWallet;
      state.isFetchedPayrollWallet = clientInitialState.isFetchedPayrollWallet;
    },
    setIsFetchingPayrollWallet(state, action) {
      state.isFetchingPayrollWallet = action.payload;
    },
    setIsFetchedPayrollWallet(state, action) {
      state.isFetchedPayrollWallet = action.payload;
    },
    setCurrencies(state, action) {
      state.currencies = action.payload;
    },
    setGeneratedFundQuote(state, action) {
      state.generatedFundQuote = action.payload;
    },
  },
});
export const fetchClient = createAsyncThunk(
  "client/fetchClient",
  async (_, { dispatch }) => {
    dispatch(setIsFetchingClientDetails(true));

    const [err, response] = await to(API.Account.client());
    if (response) {
      dispatch(setClient(response.data));
    }
    dispatch(setIsFetchingClientDetails(false));
  }
);

export const updateClientSettings = createAsyncThunk(
  "client/updateClientSettings",
  async (params, { dispatch }) => {
    const {
      onSuccess = () => {},
      onError = () => {},
      setting,
      ...rest
    } = params;
    dispatch(setUpdatingClientSettings(true));
    const [err, response] = await to(
      API.Client.updateClientSettings({ setting, ...rest })
    );
    if (response) {
      dispatch(setClient(response.data));
      onSuccess(response);
    } else {
      onError(err);
      vToast(getErrorToastMessage(err));
    }
    dispatch(setUpdatingClientSettings(false));
  }
);

export const fetchAccountWallets = createAsyncThunk(
  "client/fetchAccountWallets",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingAccountWallets(true));
    const [err, response] = await to(API.Wallets.all(params));
    if (response) {
      dispatch(setWallets(response.data));
      dispatch(setIsFetchedAccountWallets(true));
    }
    dispatch(setIsFetchingAccountWallets(false));
  }
);

export const fetchPayrollWallet = createAsyncThunk(
  "client/fetchPayrollWallet",
  async (params, { dispatch }) => {
    const [error, response] = await to(API.Payrolls.payrollWallet(params));

    setIsFetchingPayrollWallet(true);

    if (!error && response) {
      dispatch(setPayrollWallet(response?.data));
      dispatch(setIsFetchedPayrollWallet(true));
    } else {
      vToast(getErrorToastMessage(error));
    }

    setIsFetchingPayrollWallet(false);
  }
);

export const fetchAccountDetail = createAsyncThunk(
  "client/fetchAccountDetail",
  async (params, { dispatch }) => {
    dispatch(setIsFetching(true));
    const [err, response] = await to(API.Account.fetchAccountDetail(params));
    if (response) {
      dispatch(setAccountDetails(response.data));
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFetching(false));
  }
);

export const downloadAccountDetail = createAsyncThunk(
  "client/downloadAccountDetail",
  async (params, { dispatch }) => {
    const [err, response] = await to(API.Account.downloadAccountDetail(params));

    if (response) {
      const responseData = response?.data?.data || response?.data; // TODO response-nesting
      await downloadFileFromResponse(
        responseData,
        "accountDetail.pdf",
        "application/pdf"
      );
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const generateFundQuote = createAsyncThunk(
  "client/generateFundQuote",
  async ({ params, onSuccess = null }, { dispatch }) => {
    dispatch(setIsFetching(true));
    const [err, response] = await to(
      API.CardPrefundPaymet.generateQuote(params)
    );
    if (response) {
      dispatch(setGeneratedFundQuote(response?.data));
      if (onSuccess) {
        onSuccess(response?.data);
      }
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFetching(false));
  }
);

export const exchangeFunds = createAsyncThunk(
  "client/exchangeFunds",
  async (params, { dispatch }) => {
    dispatch(setIsFetching(true));
    dispatch(setIsFormSubmissionProgress(true));
    const { value, onSuccess = () => {} } = params;
    const [err, response] = await to(
      API.CardPrefundPaymet.exchangeFunds(value)
    );
    if (response) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) {
        onSuccess();
      }
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFormSubmissionProgress(false));
    dispatch(setIsFetching(false));
  }
);

export const fetchCurrencies = createAsyncThunk(
  "client/fetchCurrencies",
  async (params, { dispatch }) => {
    const [error, response] = await to(API.Misc.getCurrencies(params));

    if (!error && response) {
      dispatch(setCurrencies(response?.data));
    } else if (error) {
      vToast(getErrorToastMessage(error));
    }
  }
);

export const toggleClientSetting = createAsyncThunk(
  "global_settings/toggleClientSetting",
  async (payload, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const [error, response] = await to(
      API.GlobalSettings.toggleSetting(payload)
    );

    if (!error && response) {
      dispatch(fetchClient());
      vToast(getSuccessToastMessage(response?.data?.message));
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const {
  setClient,
  setIsFetchingClientDetails,
  setWallets,
  resetWallets,
  setIsFetching,
  setAccountDetails,
  setIsFetchingAccountWallets,
  setIsFetchedAccountWallets,
  setPayrollWallet,
  resetPayrollWallet,
  setUpdatingClientSettings,
  setIsFetchingPayrollWallet,
  setIsFetchedPayrollWallet,
  setCurrencies,
  setGeneratedFundQuote,
  setAlerts,
  setIsFetchingAlerts,
  setIsLoadingAlerts,
} = clientSlice.actions;

export default clientSlice.reducer;
