import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";
import usePagination from "@/hooks/usePagination";

import {
  createReport,
  fetchReportReimbursements,
  reviewReport,
  setCreateReportFormData,
  setReimbursementInitialState,
  setSelectedClaimForReport,
  updateReport,
} from "@/store/reducers/reimbursement";

import {
  createReportFormDataSelector,
  isCreatingReportSelector,
  isReimbursementsFetchingSelector,
  reimbursementHasMoreSelector,
  reimbursementTotalSelector,
  reimbursementpageSelector,
  reimbursementsListSelector,
  selectedAllClaimsForReportSelector,
  selectedClaimForReportSelector,
} from "@/store/selectors/reimbursement";
import { userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";

import ApproversSectionComponent from "@/components/Cards/Sliders/OrderPhysicalCardSlider/ApproversSection";
import FormSummaryInfo from "@/components/common/FormSummaryInfo";
import { SORTING_TYPE, SORT_KEYS } from "@/utils/constants/sorting";
import { REIMBURSEMENT_STATUS } from "@/utils/constants/reimbursement";
import {
  PAYMENT_APPROVERS_API_QPARAMS,
  REIMBURSEMENT,
} from "@/utils/constants/payments";
import {
  CREATE_REPORT_KEYS,
  SORT_COLUMN_BY_MISSING_DETAILS,
} from "@/utils/constants/myVolopay";
import { amountToCurrency, camelToSnake } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT } from "@/constants/pagination";

import ReportClaimListComponent from "../ReportClaimListComponent";

function configFunction() {
  return [
    {
      name: CREATE_REPORT_KEYS.NAME,
      label: "Report name",
      value: undefined,
      show: true,
    },
    {
      name: CREATE_REPORT_KEYS.DESCRIPTION,
      label: "What is this report for?",
      value: undefined,
      show: true,
    },
    {
      name: CREATE_REPORT_KEYS.PROJECT_ID,
      label: "Linked to",
      value: undefined,
      show: true,
    },
    {
      name: CREATE_REPORT_KEYS.NO_OF_CLAIMS,
      label: "No of claims",
      value: undefined,
      show: true,
    },
    {
      name: CREATE_REPORT_KEYS.TOTAL_AMOUNT,
      label: "Total amount",
      value: undefined,
      show: true,
    },
  ];
}

function ReviewReport() {
  const itemRef = useLeftHeaderTitle({
    title: "myVolopay.reimbursements.reports.create.review.title",
  });

  const status = {
    active: [
      REIMBURSEMENT_STATUS.pending_approval,
      REIMBURSEMENT_STATUS.approved,
      REIMBURSEMENT_STATUS.processing,
    ],
    drafts: REIMBURSEMENT_STATUS.in_draft,
    history: [REIMBURSEMENT_STATUS.paid, REIMBURSEMENT_STATUS.denied],
  };

  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();

  const reviewId = searchParam.get(SLIDERS_SEARCH_PARAMS.reports.reviewId);
  const [tab] = window.location.pathname.split("/").slice(-1);

  // Selectors
  const createReportFormData = useSelector(createReportFormDataSelector);
  const reimbursements = useSelector(reimbursementsListSelector);
  const { id } = useSelector(userSelector);
  const isFetching = useSelector(isReimbursementsFetchingSelector);
  const hasMore = useSelector(reimbursementHasMoreSelector);
  const totalReimbursements = useSelector(reimbursementTotalSelector);
  const reimbursementPage = useSelector(reimbursementpageSelector);
  const selectedClaimIdForReport = useSelector(selectedClaimForReportSelector);
  const selectedAllClaimsForReport = useSelector(
    selectedAllClaimsForReportSelector
  );
  const currentUser = useSelector(userSelector);

  const isLoading = useSelector(isCreatingReportSelector);

  const isEmpty = !reimbursements?.length;
  // States
  const [formFields, setFormFields] = useState([]);

  const filterOptions = {
    status: REIMBURSEMENT_STATUS.in_draft,
  };

  const onReset = () => {
    setPageNum(1);
    dispatch(setReimbursementInitialState());
  };

  const loadMore = () => {
    if (selectedClaimIdForReport?.length)
      dispatch(
        fetchReportReimbursements({
          page: pageNum,
          limit: PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT,
          ...(reviewId
            ? { by_report_id: reviewId }
            : selectedAllClaimsForReport
              ? { user: id }
              : { by_ids: selectedClaimIdForReport }),
          ...filterOptions,
          [SORT_KEYS.COLUMN]: SORT_COLUMN_BY_MISSING_DETAILS,
          [SORT_KEYS.DIRECTION]: SORTING_TYPE.INC,
        })
      );
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    inSlider: true,
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const customValue = (fieldName) => {
    switch (fieldName) {
      case CREATE_REPORT_KEYS.PROJECT_ID: {
        return `${createReportFormData.linkedTo} : ${createReportFormData.deptOrProjectName}`;
      }

      case CREATE_REPORT_KEYS.TOTAL_AMOUNT: {
        return `${amountToCurrency(createReportFormData.totalAmount)}`;
      }
      default:
        return createReportFormData[fieldName];
    }
  };

  const CREATE_REPORT_SUMMARY_CONFIG = configFunction();

  const params = {
    project_id: createReportFormData?.value?.projectId,
    amount: createReportFormData?.totalAmount?.amount,
    currency: createReportFormData?.totalAmount?.currency,
    [PAYMENT_APPROVERS_API_QPARAMS.POLICY_GROUP_TYPE]: REIMBURSEMENT,
  };

  const cancelHandler = () => {
    searchParam.delete(SLIDERS_SEARCH_PARAMS.reports.reviewReport);
    setSearchParam(searchParam);
  };
  const onSuccess = () => {
    searchParam.delete(SLIDERS_SEARCH_PARAMS.reports.reviewReport);
    searchParam.delete(SLIDERS_SEARCH_PARAMS.reports.create);
    searchParam.delete(SLIDERS_SEARCH_PARAMS.reports.reviewId);

    setSearchParam(searchParam);
    dispatch(setCreateReportFormData(null));
    dispatch(setSelectedClaimForReport([]));
  };

  const updateAndReviewReportApiCall = (payload) => {
    const correctedKeysObject = Object.entries(payload).reduce(
      (accum, [key, value]) => {
        accum[camelToSnake(key)] = value;
        return accum;
      },
      {}
    );

    dispatch(
      updateReport({
        id: reviewId,
        payload: correctedKeysObject,
        noToast: true,
        onSuccess: () => {
          dispatch(
            reviewReport({
              id: reviewId,
              onSuccess,
              onFail: cancelHandler,
            })
          );
        },
      })
    );
  };

  const createAndReviewReportApiCall = (payload) => {
    const correctedKeysObject = Object.entries(payload).reduce(
      (accum, [key, value]) => {
        accum[camelToSnake(key)] = value;
        return accum;
      },
      {}
    );

    dispatch(
      createReport({
        payload: correctedKeysObject,
        noToast: true,
        tab,
        onSuccess: (reportId) => {
          dispatch(
            reviewReport({
              id: reportId,
              onSuccess,
              onFail: cancelHandler,
            })
          );
        },
      })
    );
  };

  const createReportCtaHandler = () => {
    const payload = {
      ...createReportFormData?.value,
    };

    if (selectedAllClaimsForReport) {
      payload.all = true;
    } else {
      payload.claimIds = selectedClaimIdForReport;
    }

    if (reviewId) {
      updateAndReviewReportApiCall(payload);
    } else {
      createAndReviewReportApiCall(payload);
    }
  };

  useEffect(() => {
    if (!createReportFormData?.value?.name) {
      searchParam.delete(SLIDERS_SEARCH_PARAMS.reports.reviewReport, true);
      setSearchParam(searchParam);
    }
  }, [createReportFormData]);

  useEffect(() => {
    if (createReportFormData) {
      setFormFields(
        CREATE_REPORT_SUMMARY_CONFIG.map((field) => ({
          ...field,
          value: customValue(field.name),
        }))
      );
    }
  }, [createReportFormData]);

  return (
    <div className="slider-content-container">
      <div className="slider-content-core">
        <div className="flex flex-col gap-8">
          <div ref={itemRef} className="flex flex-col">
            <Text
              classes="font-bold text-3xl"
              translationKey="myVolopay.reimbursements.reports.create.review.title"
            />
            <Text
              classes="font-medium text-neutral-500 text-sm"
              translationKey="myVolopay.reimbursements.reports.create.review.desc"
            />
            <div className="mt-6 ">
              {createReportFormData && formFields.length !== 0 ? (
                <FormSummaryInfo
                  formFields={formFields}
                  labelClasses="!w-8/12"
                />
              ) : (
                <LoaderSkeleton
                  borderRadius="200"
                  count={5}
                  fullWidth
                  classes="py-4 my-2"
                />
              )}
            </div>
          </div>
          <div>
            <div className="flex flex-col mb-6">
              <Text
                classes="font-bold text-xl"
                translationKey="myVolopay.reimbursements.reports.claims"
              />
              <Text
                classes="font-normal text-neutral-500 text-base"
                translationKey="myVolopay.reimbursements.reports.listOfClaimsAttached"
              />
            </div>

            <div className="max-h-[45vh] overflow-auto hide-scroll-bar">
              <ReportClaimListComponent
                dataVariables={{
                  data: reimbursements,
                  isFetching,
                  hasMore,
                  isEmpty,
                  total: totalReimbursements,
                  page: reimbursementPage,
                  paginationLimit: PAGINATION_PER_REQUEST_LIMIT_VERY_SHORT,
                }}
                infiniteScrollVariables={{ onScroll }}
                onClickOpenSlider={(val) => {
                  searchParam.append(
                    SLIDERS_SEARCH_PARAMS.reimbursements.review,
                    val?.id
                  );
                  setSearchParam(searchParam);
                }}
              />
            </div>
          </div>

          {createReportFormData ? (
            <div className="mb-6">
              <ApproversSectionComponent
                params={params}
                approvalDescription="cards.pCards.sliders.createPhysicalCardsDetailsSummary.approvers.approvalDescription"
                descriptionTextClasses="text-neutral-500"
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex justify-end gap-4 px-5 py-4 slider-footer ">
        <Button
          label="myVolopay.reimbursements.reports.create.createFooterCtas.cancel"
          variant="tertiary"
          classes="w-16 px-5 py-3"
          onClick={cancelHandler}
          disabled={isLoading}
        />
        <Button
          id="createReportBtn"
          label="myVolopay.reimbursements.reports.create.createFooterCtas.createReport"
          variant="primary"
          classes="w-16 px-5 py-3"
          btnType="button"
          onClick={createReportCtaHandler}
          showLoader={isLoading}
        />
      </div>
    </div>
  );
}

export default ReviewReport;
