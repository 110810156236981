import { MODULES } from "@/utils/constants/app";

import { REIMBURSEMENTS_TYPES } from "./reimbursement";
import { SETTINGS_KEY } from "./settings";
import { TAG_MODULE_TITLES } from "./tags";

export const POLICY_LAYER = {
  company: "company",
  custom: "custom",
  department: "department",
  project: "project",
};

export const POLICY_TYPE = {
  CLAIM: "claim",
  APPROVAL: "approval",
  CUSTOM_APPROVAL: "customApproval",
  SUBMISSION: "submission",
  CUSTOM_SUBMISSION: "customSubmission",
  CUSTOM_EXPENSE_REVIEW: "customExpenseReviewPolicy",
};

export const APPROVAL_POLICY_PAYLOADS = {
  cards: "cardRequestPolicyGroup",
  reimbursements: "reimbursementPolicyGroup",
  payments: "paymentPolicyGroup",
};

export const SUBMISSION_POLICY_LIST = {
  cards: "expenseSubmissionPolicy",
  expenses: "expenseSubmissionPolicy",
  payments: "paymentSubmissionPolicy",
  reimbursements: "outOfPocketSubmissionPolicy",
  outOfPocket: "outOfPocketSubmissionPolicy",
  mileage: "mileageSubmissionPolicy",
};

export const POLICY_GROUP_MAPPING = {
  cards: "cards",
  payments: "payments",
  reimbursement: "reimbursement",
  reimbursements: "reimbursements",
  physicalCard: "physical_cards",
  payroll: "payroll",
  expenseReview: "expenseReview",
  qrPayReview: "qrPayReview",
  qrWallets: "qrWallets",
  qrpay: "qrpay",
  claim: "claim",
  outOfPocket: "outOfPocket",
};

export const APPROVAL_PAYLOAD_MAPPING = {
  cards: "cards",
  payments: "payments",
  reimbursements: "reimbursements",
  physicalCard: "physical_cards",
  payroll: "payroll",
  expenseReview: "expense_review",
  qrPayReview: "qrpay_review",
  qrWallets: "qrwallets",
  qrpay: "qrpay",
  claim: "claim",
};

export const CUSTOM_PAYLOAD_MAPPING = {
  cards: "card_request",
  payments: "payment",
  reimbursement: "reimbursement",
  reimbursements: "reimbursement",
  expenses: "expense",
  outOfPocket: "out_of_pocket",
  mileage: "mileage",
  expense_review: "expense_review",
};

export const SUBMISSION_PAYLOAD_MAPPING = {
  cards: "expenses",
  payments: "payments",
  reimbursements: "out_of_pocket",
  outOfPocket: "out_of_pocket",
  mileage: "mileage",
  payroll: "payroll",
  qrWallets: "qrwallets",
  qrpay: "qrpay",
};

export const SUBMISSION_POLICY_MODULE = {
  cards: TAG_MODULE_TITLES.CARD,
  payments: TAG_MODULE_TITLES.BILL_PAY,
  reimbursements: TAG_MODULE_TITLES.REIMBURSEMENT,
};

export const CUSTOM_SUBMISSION_POLICY_PARAM = {
  expenses: "expense",
  payments: "payment",
  reimbursements: "out_of_pocket",
  outOfPocket: "out_of_pocket",
  mileage: "mileage",
  payroll: "payroll",
  qrWallets: "qrwallets",
  qrpay: "qrpay",
};

export const POLICY_CTA = {
  EDIT_GLOBAL_POLICY: "globalPolicyEdit",
  EDIT_POLICY: "editPolicy",
};

export const REIMBURSEMENTS_TYPES_TABS = [
  {
    name: "Out of pocket",
    id: REIMBURSEMENTS_TYPES.OUT_OF_POCKET,
    key: 0,
    count: null,
  },
  {
    name: "Mileage",
    id: REIMBURSEMENTS_TYPES.MILEAGE,
    key: 1,
    count: null,
  },
];

export const POLICY_OPTIONS_CONFIG = [
  {
    title: "policy.policyOptions.approval.title",
    description: "policy.policyOptions.approval.description",
    icon: "CircleCheck",
    key: "approval",
    isCustom: false,
    sliderId: SETTINGS_KEY.approvalPolicy,
  },
  {
    title: "policy.policyOptions.submissionPolicy.title",
    description: "policy.policyOptions.submissionPolicy.description",
    icon: "Sheild",
    key: "submissionPolicy",
    sliderId: SETTINGS_KEY.submissionPolicy,
    isCustom: false,
  },
  {
    title: "policy.policyOptions.claimPolicy.title",
    description: "policy.policyOptions.claimPolicy.description",
    icon: "AccountBalanceWallet",
    key: "claimPolicy",
    sliderId: SETTINGS_KEY.claimPolicy,
    module: MODULES.REIMBURSEMENTS,
    isCustom: false,
  },
  {
    title: "policy.policyOptions.expensePolicy.title",
    description: "policy.policyOptions.expensePolicy.description",
    icon: "NewReleases",
    key: "expensReviewPolicy",
    sliderId: SETTINGS_KEY.expenseReviewPolicy,
    isCustom: false,
    module: MODULES.EXPENSE,
  },
];

export const DEFAULT_APPROVAL_POLICY_CONFIG = {
  cardRequestPolicyGroup: {
    title: "policy.approvalModules.cards.title",
    description: "policy.approvalModules.cards.description",
    icon: "CreditCard",
    key: "cardPolicy",
    sliderId: MODULES.CARDS,
    customSliderId: MODULES.CARDS,
  },
  paymentPolicyGroup: {
    title: "policy.approvalModules.billPay.title",
    description: "policy.approvalModules.billPay.description",
    icon: "BillPay",
    key: "billPayPolicy",
    sliderId: MODULES.PAYMENTS,
    customSliderId: MODULES.PAYMENTS,
  },
  reimbursementPolicyGroup: {
    title: "policy.approvalModules.reimbursements.title",
    description: "policy.approvalModules.reimbursements.description",
    icon: "AccountBalanceWallet",
    key: "reimbursementPolicy",
    sliderId: MODULES.REIMBURSEMENTS,
    customSliderId: MODULES.REIMBURSEMENTS,
  },
  qrpayPolicyGroup: {
    title: "policy.approvalModules.qrPays.title",
    description: "policy.approvalModules.qrPays.description",
    icon: "QrPay",
    key: "qrPayPolicy",
    sliderId: "qrpays",
    customSliderId: "qrpay",
  },
};

export const DEFAULT_SUBMISSION_POLICY_CONFIG = {
  expenseSubmissionPolicy: {
    title: "policy.submissionModules.cards.title",
    description: "policy.submissionModules.cards.description",
    icon: "CreditCard",
    key: "cardPolicy",
    sliderId: MODULES.CARDS,
    customSliderId: SUBMISSION_PAYLOAD_MAPPING.cards,
  },
  paymentSubmissionPolicy: {
    title: "policy.submissionModules.payments.title",
    description: "policy.submissionModules.payments.description",
    icon: "BillPay",
    key: "billPayPolicy",
    sliderId: MODULES.PAYMENTS,
    customSliderId: MODULES.PAYMENTS,
  },
  outOfPocketSubmissionPolicy: {
    title: "policy.submissionModules.reimbursements.title",
    description: "policy.submissionModules.reimbursements.description",
    icon: "AccountBalanceWallet",
    key: "reimbursementPolicy",
    sliderId: MODULES.REIMBURSEMENTS,
    customSliderId: MODULES.REIMBURSEMENTS,
  },
  qrpaySubmissionPolicy: {
    title: "policy.submissionModules.qrPay.title",
    description: "policy.submissionModules.qrPay.description",
    icon: "QrPay",
    key: "qrPayPolicy",
    sliderId: "qrpays",
    customSliderId: "qrpay",
  },
};
