import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  appendToCreateBillFormLineItems,
  mergeIntoCreateBillTotalContext,
  setCreateBillForm,
  setCreateBillFormLineItems,
} from "@/store/reducers/payments";

import { payrollMultiLineItemsEnabledSelector } from "@/store/selectors/client";
import { createBillFormLineItemsSelector } from "@/store/selectors/payments";
import {
  additivePurchaseTaxChildrenSelector,
  additivePurchaseTaxParentSelector,
  isPurchaseBillTaxesReadySelector,
  subtractivePurchaseTaxChildrenSelector,
  subtractivePurchaseTaxParentSelector,
} from "@/store/selectors/purchase-bills";
import {
  accountingCategoryTagSelector,
  accountingNonCategoryTags,
  billPayCustomTagsSelector,
  payrollCustomTagsSelector,
} from "@/store/selectors/tags";
import { selectedVendorSelector } from "@/store/selectors/vendors";

import { ocrResults } from "@/store/selectors/ocr";

import AmountToBePaid from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/AmountToBePaid";
import LineItemsList from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/LineItemsList";
import TextWithTooltipAndAmount from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/TextWithTooltipAndAmount";
import {
  CREATE_BILL_FLOW_CONTEXT_KEYS,
  CREATE_BILL_TAX_RESPONSE,
  LINE_ITEM_KEY,
  LINE_ITEM_KEYS,
  TAX_AT_LINE_ITEM_LEVEL_ADDITIVE,
  TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE,
  TAX_KEYS,
} from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";
import {
  getTagItemWithData,
  getTaxAmount,
} from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/utils";
import EnabledAtLineItemLevelSwitches from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/sections/LineItemsSection/EnabledAtLineItemLevelSwitches";
import Heading from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/sections/LineItemsSection/Heading";
import QuoteSection from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/sections/LineItemsSection/QuoteSection";
import TaxSectionAdditive from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/sections/LineItemsSection/TaxSectionAdditive";
import TaxSectionSubtractive from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/sections/LineItemsSection/TaxSectionSubtractive";

import Alert from "@/components/core/Alert";
import { GST, VENDOR_TYPES } from "@/constants/vendors";

export default function LineItemsSection({
  values,
  errors,
  handleChange,
  setValues,
  senderCurrency,
  beneficiaryCurrency,
  inPayrollContext,
  disabled,
  isPayOutsideVolopay,
  createBillFlowContext,
  ocrMode,
  billOCRSuccess,
  isDependentAPIFetching,
  isYSTRPotentiallyApplicable,
  isYSTRApplicable,
}) {
  const dispatch = useDispatch();
  const vendor = useSelector(selectedVendorSelector);
  const isYSTREditEnabled = values?.isYSTREditEnabled;
  const isPurchaseBillTaxesReady = useSelector(
    isPurchaseBillTaxesReadySelector
  );

  const ocrResult = useSelector(ocrResults);
  const additiveParentTax = useSelector(additivePurchaseTaxParentSelector);
  const additiveChildrenTax = useSelector(additivePurchaseTaxChildrenSelector);
  const subtractiveParentTax = useSelector(
    subtractivePurchaseTaxParentSelector
  );
  const subtractiveChildrenTax = useSelector(
    subtractivePurchaseTaxChildrenSelector
  );
  const accountingCategoryTag = useSelector(accountingCategoryTagSelector);
  const nonCategoryTags = useSelector(accountingNonCategoryTags);
  const customTags = useSelector((state) =>
    inPayrollContext
      ? payrollCustomTagsSelector(state)
      : billPayCustomTagsSelector(state)
  );

  const allTags = [
    ...(accountingCategoryTag ? [accountingCategoryTag] : []),
    ...nonCategoryTags,
    ...customTags,
  ];

  const lineItemsForForm = useSelector(createBillFormLineItemsSelector);

  // all these are "totals" of some kind

  const preciseSubtotal = +Object.entries(values).reduce((accum, [k, v]) => {
    if (v && k.startsWith(LINE_ITEM_KEY) && k.endsWith(LINE_ITEM_KEYS.AMOUNT)) {
      const feID = k.split(".").at(1);
      if (!values?.[`${LINE_ITEM_KEY}.${feID}.${LINE_ITEM_KEYS._DESTROY}`])
        return accum + parseFloat(v?.value ?? v); // TECH_DEBT, second line item is an integer while others are { value, currency }. Why this difference in structure
    }
    return accum;
  }, 0);
  const subtotal = Number(preciseSubtotal.toFixed(2));

  const taxCalcFunc = (
    parentTax,
    childrenTax,
    isAdditiveTax = true,
    lineItemsForFormEntries = []
  ) => {
    if (!parentTax) return 0;

    const lineItemLevelEnabled =
      values[
        isAdditiveTax
          ? TAX_AT_LINE_ITEM_LEVEL_ADDITIVE
          : TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE
      ];
    if (!lineItemLevelEnabled) {
      const parentKeyName = parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
      const parentValueKey = `${parentKeyName}-value`;
      const parentAmount = getTaxAmount(subtotal, values[parentValueKey]);
      const sumOfChildrenAmount = childrenTax.reduce((accum, childTax) => {
        const taxKeyName = childTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
        const childValueKey = `${taxKeyName}-value`;
        const optionalSwitchKey = `${taxKeyName}-${TAX_KEYS.OPTIONAL_ENABLED}`;
        const optionalSwitchEnabled = values[optionalSwitchKey];

        return (
          accum +
          (optionalSwitchEnabled
            ? getTaxAmount(subtotal, values[childValueKey])
            : 0)
        );
      }, 0);
      return parentAmount + sumOfChildrenAmount;
    }

    // apply at lineItemLevel ON
    const totalFromAllLineItems = lineItemsForFormEntries.reduce(
      (accum, entry) => {
        const id = entry[LINE_ITEM_KEYS.FEID];

        // parent tax of the line item entry
        const parentFormKey = `${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.TAX}.${
          parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME]
        }`;
        const parentValueKey = `${parentFormKey}-value`;
        const parentAmount = getTaxAmount(subtotal, values[parentValueKey]);

        // children tax of the line item entry
        const sumOfChildrenAmount = childrenTax.reduce((accum2, childTax) => {
          // child tax
          const prefix = `${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.TAX}`;
          const taxKeyName = childTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
          const childValueKey = `${prefix}.${taxKeyName}-value`;

          // determine if the optional switch is ON
          const optionalSwitchKey = `${prefix}.${taxKeyName}-${TAX_KEYS.OPTIONAL_ENABLED}`;
          const optionalSwitchEnabled = values[optionalSwitchKey];

          return (
            accum2 +
            (optionalSwitchEnabled
              ? getTaxAmount(subtotal, values[childValueKey])
              : 0)
          );
        }, 0);
        return accum + parentAmount + sumOfChildrenAmount;
      },
      0
    );

    return totalFromAllLineItems;
  };

  const preciseGst = useMemo(
    () =>
      +taxCalcFunc(
        additiveParentTax,
        additiveChildrenTax,
        true,
        lineItemsForForm.filter((item) => !item[LINE_ITEM_KEYS._DESTROY])
      )
  );
  const gst = Number(preciseGst.toFixed(2));

  const preciseTotal = +(preciseSubtotal + preciseGst);

  const total = Number(+(subtotal + gst).toFixed(2));

  const preciseTds = useMemo(
    () =>
      +taxCalcFunc(
        subtractiveParentTax,
        subtractiveChildrenTax,
        false,
        lineItemsForForm.filter((item) => !item[LINE_ITEM_KEYS._DESTROY])
      )
  );
  const tds = Number(preciseTds.toFixed(2));

  const preciseQuote = +(preciseTotal - preciseTds);
  const quote = Number(+(total - tds).toFixed(2));

  // pure function
  const getSingleLineItemEntry = (newEntryData = null) => {
    const newEntryId = lineItemsForForm.length;

    const newEntry = {
      [LINE_ITEM_KEYS.FEID]: newEntryId,
      [LINE_ITEM_KEYS.BEID]: null,
      [LINE_ITEM_KEYS._DESTROY]: false,
      [LINE_ITEM_KEYS.AMOUNT]: newEntryData?.[LINE_ITEM_KEYS.AMOUNT] ?? "",
      [LINE_ITEM_KEYS.DESCRIPTION]:
        newEntryData?.[LINE_ITEM_KEYS.DESCRIPTION] ?? "",
      [LINE_ITEM_KEYS.ACCOUNTING_DESCRIPTION]:
        newEntryData?.[LINE_ITEM_KEYS.ACCOUNTING_DESCRIPTION] ?? "",
      // tags
      [LINE_ITEM_KEYS.ACCCOUNTING_TAGS]: allTags.map((tag) =>
        getTagItemWithData(newEntryData, newEntryId)(tag)
      ),
    };

    // add taxes
    (inPayrollContext
      ? [
          {
            parentTax: subtractiveParentTax,
            childrenTax: subtractiveChildrenTax,
          },
        ]
      : [
          { parentTax: additiveParentTax, childrenTax: additiveChildrenTax },
          {
            parentTax: subtractiveParentTax,
            childrenTax: subtractiveChildrenTax,
          },
        ]
    ).forEach(({ parentTax, childrenTax }) => {
      if (!parentTax?.[CREATE_BILL_TAX_RESPONSE.LINE_ITEM_LEVEL_APPLICABLE])
        return;

      // parent tax
      const parentTaxKeyName = parentTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
      const parentId = `${LINE_ITEM_KEYS.TAX}.${parentTaxKeyName}-id`;
      const parentDropdownKey = `${LINE_ITEM_KEYS.TAX}.${parentTaxKeyName}-dropdown`;
      const parentValueKey = `${LINE_ITEM_KEYS.TAX}.${parentTaxKeyName}-value`;
      const parentTagValueKey = `${LINE_ITEM_KEYS.TAX}.${parentTaxKeyName}-tag-value-id`;
      const parentCategoryDropdownKey = `${LINE_ITEM_KEYS.TAX}.${parentTaxKeyName}-category-dropdown`;
      const parentDropdownLabelKey = `${LINE_ITEM_KEYS.TAX}.${parentTaxKeyName}-dropdown-label`;
      newEntry[parentId] = "";
      newEntry[parentDropdownKey] = "";
      newEntry[parentValueKey] = "";
      newEntry[parentTagValueKey] = "";
      newEntry[parentCategoryDropdownKey] = "";
      newEntry[parentDropdownLabelKey] = "";

      // children taxes
      childrenTax.forEach((childTax) => {
        const taxKeyName = childTax[CREATE_BILL_TAX_RESPONSE.KEY_NAME];
        const childId = `${LINE_ITEM_KEYS.TAX}.${taxKeyName}-id`;
        const childVisibleKey = `${LINE_ITEM_KEYS.TAX}.${taxKeyName}-visible`;
        const childDropdownKey = `${LINE_ITEM_KEYS.TAX}.${taxKeyName}-dropdown`;
        const childValueKey = `${LINE_ITEM_KEYS.TAX}.${taxKeyName}-value`;
        const childTagValueKey = `${LINE_ITEM_KEYS.TAX}.${taxKeyName}-tag-value-id`;
        const childCategoryDropdownKey = `${LINE_ITEM_KEYS.TAX}.${taxKeyName}-category-dropdown`;
        const childDropdownLabelKey = `${LINE_ITEM_KEYS.TAX}.${taxKeyName}-dropdown-label`;
        const switchKey = `${LINE_ITEM_KEYS.TAX}.${taxKeyName}-${TAX_KEYS.OPTIONAL_ENABLED}`;

        newEntry[childId] = "";
        newEntry[childVisibleKey] = true;
        newEntry[childDropdownKey] = "";
        newEntry[childValueKey] = "";
        newEntry[childTagValueKey] = "";
        newEntry[childCategoryDropdownKey] = "";
        newEntry[childDropdownLabelKey] = "";
        newEntry[switchKey] = false;
      });
    });
    return newEntry;
  };

  // impure function
  const onAddLineItemHandler = (newEntryData = null) => {
    // save form data (other than line items)
    dispatch(setCreateBillForm(values));

    // save line items current data (from values to the array in store)

    dispatch(
      setCreateBillFormLineItems(
        lineItemsForForm.map((lineItem) => {
          const id = lineItem[LINE_ITEM_KEYS.FEID];
          const existingEntryWithLatestData = {};
          Object.entries(lineItem).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              existingEntryWithLatestData[key] = value.map((objInArr) => {
                const replicatedObjectFromLatestValues = Object.keys(
                  objInArr
                ).reduce(
                  (accum, k) => ({
                    ...accum,
                    [k]: values[k],
                  }),
                  {}
                );

                return replicatedObjectFromLatestValues;
              });
            } else {
              existingEntryWithLatestData[key] =
                values[`${LINE_ITEM_KEY}.${id}.${key}`];
            }
          });

          return existingEntryWithLatestData;
        })
      )
    );

    // add empty entry
    const newEntry = getSingleLineItemEntry(newEntryData);
    dispatch(appendToCreateBillFormLineItems(newEntry));
  };

  const onDeleteLineItemHandler = (id) => {
    // save form data (other than line items)
    dispatch(setCreateBillForm(values));

    // save line items current data (from values to the array in store)
    // TODO: bug, deletion of item causes all items below to get deleted

    // mark _destroy

    dispatch(
      setCreateBillFormLineItems(
        lineItemsForForm.map((lineItem) => {
          const _id = lineItem[LINE_ITEM_KEYS.FEID];
          const existingEntryWithLatestData = {};
          Object.entries(lineItem).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              existingEntryWithLatestData[key] = value; // tags, save as is
            } else {
              existingEntryWithLatestData[key] =
                values[`${LINE_ITEM_KEY}.${_id}.${key}`];
            }
          });

          if (_id === id) {
            existingEntryWithLatestData[LINE_ITEM_KEYS._DESTROY] = true;
          }

          return existingEntryWithLatestData;
        })
      )
    );
  };

  // automatically add an entry if there's none
  useEffect(() => {
    if (
      !isDependentAPIFetching &&
      isPurchaseBillTaxesReady &&
      lineItemsForForm?.length === 0
    ) {
      onAddLineItemHandler();
    }
  }, [isDependentAPIFetching, isPurchaseBillTaxesReady, lineItemsForForm]);

  useEffect(() => {
    dispatch(
      mergeIntoCreateBillTotalContext({
        [CREATE_BILL_FLOW_CONTEXT_KEYS.INVOICE_SUBTOTAL]: subtotal,
      })
    );
  }, [subtotal]);
  useEffect(() => {
    dispatch(
      mergeIntoCreateBillTotalContext({
        [CREATE_BILL_FLOW_CONTEXT_KEYS.QUOTE_ARGUMENT]: quote,
      })
    );
  }, [quote]);

  const isShowTaxBreakdown = !!(additiveParentTax || subtractiveParentTax);
  const payrollMultiLineItemsEnabled = useSelector(
    payrollMultiLineItemsEnabledSelector
  );

  const multiLineItemsEnabled =
    !inPayrollContext || payrollMultiLineItemsEnabled;

  const showIndividualVendorGstAlert =
    ocrResult?.data?.taxes?.find((p) => p?.name === GST) &&
    vendor?.type === VENDOR_TYPES.INDIVIDUAL;

  return (
    <div className="mt-12">
      <Heading
        title={
          inPayrollContext
            ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.sections.paymentInformation.title"
            : "billPay.bill.invoiceInbox.createBill.sections.lineItems.title"
        }
        onAddLineItemHandler={onAddLineItemHandler}
        hidePlusButton={isYSTREditEnabled || !multiLineItemsEnabled}
      />

      <div className={`${disabled ? "opacity-50" : ""} mt-6`}>
        <LineItemsList
          values={values}
          errors={errors}
          handleChange={handleChange}
          setValues={setValues}
          taxAtLineItemLevel={
            values[TAX_AT_LINE_ITEM_LEVEL_ADDITIVE] ||
            values[TAX_AT_LINE_ITEM_LEVEL_SUBTRACTIVE]
          }
          beneficiaryCurrency={beneficiaryCurrency}
          onAddLineItemHandler={onAddLineItemHandler}
          onDeleteLineItemHandler={onDeleteLineItemHandler}
          inPayrollContext={inPayrollContext}
          createBillFlowContext={createBillFlowContext}
          ocrMode={ocrMode}
          billOCRSuccess={billOCRSuccess}
          isHidePlusButton={isYSTREditEnabled || !multiLineItemsEnabled}
        />
        {isShowTaxBreakdown ? (
          <div
            aria-label="totals"
            className="p-6 border border-t-0 rounded-b-lg border-neutral-200"
          >
            {/* Invoice subtotal */}
            {additiveParentTax ? (
              <TextWithTooltipAndAmount
                text="billPay.bill.invoiceInbox.createBill.sections.lineItems.invoiceSubtotal"
                tooltipText={
                  additiveParentTax?.[CREATE_BILL_TAX_RESPONSE.KEY_NAME]
                    ? "billPay.bill.invoiceInbox.createBill.sections.lineItems.exclusiveOfXYZTax"
                    : ""
                }
                tooltipTextProps={{
                  taxName:
                    additiveParentTax?.[CREATE_BILL_TAX_RESPONSE.KEY_NAME],
                }}
                currency={beneficiaryCurrency}
                amount={subtotal}
              />
            ) : null}
            {/* GST/PPN, PPnBM */}
            <TaxSectionAdditive
              values={values}
              errors={errors}
              handleChange={handleChange}
              setValues={setValues}
              beneficiaryCurrency={beneficiaryCurrency}
              subtotal={subtotal}
              gst={gst}
              createBillFlowContext={createBillFlowContext}
              ocrMode={ocrMode}
              billOCRSuccess={billOCRSuccess}
            />

            {showIndividualVendorGstAlert ? (
              <Alert
                title="billPay.vendors.createVendor.gstIndividualAlertTitle"
                description="billPay.vendors.createVendor.gstIndividualAlertDesc"
                variant="warning"
                wrapperDivClass="items-start my-1"
                classes="my-6"
              />
            ) : null}

            {/* Invoice total */}
            <div className="mt-1">
              <TextWithTooltipAndAmount
                text={
                  inPayrollContext
                    ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.paymentTotal"
                    : "billPay.bill.invoiceInbox.createBill.sections.lineItems.invoiceTotal"
                }
                tooltipText={
                  additiveParentTax?.[CREATE_BILL_TAX_RESPONSE.KEY_NAME]
                    ? "billPay.bill.invoiceInbox.createBill.sections.lineItems.inclusiveOfXYZTax"
                    : ""
                }
                tooltipTextProps={{
                  taxName:
                    additiveParentTax?.[CREATE_BILL_TAX_RESPONSE.KEY_NAME],
                }}
                currency={beneficiaryCurrency}
                amount={total}
              />
            </div>
            {/* TDS */}
            <TaxSectionSubtractive
              values={values}
              errors={errors}
              handleChange={handleChange}
              setValues={setValues}
              beneficiaryCurrency={beneficiaryCurrency}
              subtotal={subtotal}
              tds={tds}
              createBillFlowContext={createBillFlowContext}
              ocrMode={ocrMode}
              billOCRSuccess={billOCRSuccess}
            />

            {/* Apply GST (switch) at line item level (when it's ON) */}
            <EnabledAtLineItemLevelSwitches
              values={values}
              errors={errors}
              handleChange={handleChange}
              createBillFlowContext={createBillFlowContext}
              ocrMode={ocrMode}
              billOCRSuccess={billOCRSuccess}
            />
          </div>
        ) : (
          <AmountToBePaid
            amount={total}
            currency={beneficiaryCurrency}
            titleText="billPay.bill.invoiceInbox.createBill.sections.lineItems.invoiceTotal"
            blueBorder={false}
            darkGrayBorder
            inReqSlider
            classes="rounded-tl-none rounded-tr-none border-t-0"
            descriptionText={
              isYSTRPotentiallyApplicable
                ? inPayrollContext
                  ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.sections.paymentInformation.employeeReceivesThisAmount"
                  : "billPay.bill.invoiceInbox.createBill.sections.lineItems.vendorReceivesThisAmount"
                : ""
            }
          />
        )}

        <QuoteSection
          isEditable={isYSTRPotentiallyApplicable}
          handleChange={handleChange}
          errors={errors}
          values={values}
          setValues={setValues}
          senderCurrency={senderCurrency}
          beneficiaryCurrency={beneficiaryCurrency}
          quoteAmount={quote}
          preciseQuoteAmount={preciseQuote}
          vendor={vendor}
          isPayOutsideVolopay={isPayOutsideVolopay}
          tooltipText={
            subtractiveParentTax?.[CREATE_BILL_TAX_RESPONSE.KEY_NAME]
              ? inPayrollContext
                ? "payroll.salaryPayment.payrollInbox.createSalaryPayment.taxXSubtractedFromPaymentTotal"
                : "billPay.bill.invoiceInbox.createBill.sections.lineItems.taxXSubtractedFromInvoiceTotal"
              : ""
          }
          tooltipTextProps={{
            taxName: subtractiveParentTax?.[CREATE_BILL_TAX_RESPONSE.KEY_NAME],
          }}
          isYSTRPotentiallyApplicable={isYSTRPotentiallyApplicable}
          isYSTRApplicable={isYSTRApplicable}
        />
      </div>
    </div>
  );
}

LineItemsSection.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
  senderCurrency: PropTypes.string,
  beneficiaryCurrency: PropTypes.string,
  inPayrollContext: PropTypes.bool,
  disabled: PropTypes.bool,
  createBillFlowContext: PropTypes.any,
  ocrMode: PropTypes.string,
  billOCRSuccess: PropTypes.bool,
  isPayOutsideVolopay: PropTypes.bool,
  isDependentAPIFetching: PropTypes.bool,
  isYSTRPotentiallyApplicable: PropTypes.bool,
  isYSTRApplicable: PropTypes.bool,
};
