import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  appendSelectedClaimForReport,
  createReimbursementClaim,
  fetchMileageRateList,
  review,
  setReimbursementInitialState,
  updateClaim,
  updateReportClaim,
} from "@/store/reducers/reimbursement";

import { categoriesListSelector } from "@/store/selectors/categories";
import {
  accountingIntegrationSoftwareSelector,
  reimbursementMakerCheckerEnabledSelector,
  reimbursementReportsEnabledSelector,
} from "@/store/selectors/client";
import { isFormSubmissionProgressSelector } from "@/store/selectors/loadersError";
import { isFetchingPaymentApproversSelector } from "@/store/selectors/payments";
import {
  createClaimFormDetailsSelector,
  isCreateLoadingSelector,
  mileageRateListSelector,
  selectedReimbursementSelector,
  setClaimCategoryLimitDetailSelector,
} from "@/store/selectors/reimbursement";
import {
  accountingCategoryTagSelector,
  nonAccountingCategoryTagSelector,
  reimbursementCustomTagsSelector,
} from "@/store/selectors/tags";
import { vendorsListSelector } from "@/store/selectors/vendors";

import Button from "@/components/core/Button";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Text from "@/components/core/Text";

import ApproversSectionComponent from "@/components/Cards/Sliders/OrderPhysicalCardSlider/ApproversSection";
import DistanceToAmount from "@/components/Reimbursement/CreateReimbursement/DistanceToAmount";
import TextWithTooltipIcon from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/TextWithTooltipIcon";
import FormSummaryInfo from "@/components/common/FormSummaryInfo";
import vToast from "@/utils/vToast";
import {
  CREATE_REIMBURSEMENT_TYPE,
  TYPE_REIMBURSEMENT,
} from "@/utils/constants/reimbursement";
import {
  PAYMENT_APPROVERS_API_QPARAMS,
  REIMBURSEMENT,
} from "@/utils/constants/payments";
import {
  amountToCurrency,
  commaFormattedNumber,
  dateToString,
} from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { CREATE_CLAIM_PARAMS, FLEXIBLE } from "@/constants/reimbursement";

import { convertReimbursmentPayload, getCorrectedPayload } from "../utils";

function configFunction(show, claimCategoryLimitDetails, reportEnabled) {
  return [
    {
      name: CREATE_CLAIM_PARAMS.REIMBURSEMENT_TYPE,
      label: "reimbursement.confirmClaim.claimTypeQuestion",
      value: undefined,
      show: true,
    },
    {
      name: CREATE_CLAIM_PARAMS.MERCHANT,
      label: "reimbursement.createReimbursement.merchant",
      value: undefined,
      show,
    },
    {
      name: CREATE_CLAIM_PARAMS.START_LOCATION,
      label: "reimbursement.createReimbursement.startLocation",
      value: undefined,
      show: !show,
    },
    {
      name: CREATE_CLAIM_PARAMS.END_LOCATION,
      label: "reimbursement.createReimbursement.endLocation",
      value: undefined,
      show: !show,
    },
    {
      name: CREATE_CLAIM_PARAMS.TRANSACTION_AMOUNT,
      label: "reimbursement.createReimbursement.amountHeading",
      value: undefined,
      show,
    },
    {
      name: CREATE_CLAIM_PARAMS.CATEGORY_ID,
      label: "reimbursement.createReimbursement.category",
      value: undefined,
      show,

      icon:
        claimCategoryLimitDetails?.categoryLimitExceeded &&
        claimCategoryLimitDetails?.type === FLEXIBLE ? (
          <TextWithTooltipIcon
            icon="FlexibleLimit"
            tooltipText="reimbursement.createReimbursement.flexibleLimitExceeded"
            tooltipTextProps={{
              amount: amountToCurrency({
                value: claimCategoryLimitDetails?.categoryLimit,
                currency: claimCategoryLimitDetails?.amount?.currency,
              }),
            }}
            text=""
            direction="left"
            tooltipClasses="text-neutral-500"
            iconClasses="cursor-pointer bg-warning-50 text-warning-500 border-2 border-warning-100"
            maxWidth="43%"
            customStyle={{
              whiteSpace: "pre-line",
              overflowWrap: "break-word",
              textAlign: "start",
            }}
          />
        ) : null,
    },

    {
      name: CREATE_CLAIM_PARAMS.TRANSACTION_DATE,
      label: "reimbursement.createReimbursement.transactionDate",
      value: undefined,
      show,
    },

    {
      name: CREATE_CLAIM_PARAMS.TOTAL_MILES,
      label: "reimbursement.confirmClaim.noOfMiles",
      value: undefined,
      show: !show,
    },
    {
      name: CREATE_CLAIM_PARAMS.TRAVEL_COUNTRY,
      label:
        "reimbursement.approvals.sliderHeadings.requestDetailsComponent.travelledCountry",
      value: undefined,
      show: !show,
    },
    {
      name: CREATE_CLAIM_PARAMS.TRAVEL_DATE,
      label: "reimbursement.createReimbursement.travelDate",
      value: undefined,
      show: !show,
    },
    {
      name: CREATE_CLAIM_PARAMS.TRANSACTION_DATE,
      label: "reimbursement.createReimbursement.travelDate",
      value: undefined,
      show: !show,
    },
    {
      name: CREATE_CLAIM_PARAMS.PROJECT_ID,
      label: "reimbursement.confirmClaim.linkedTo",
      value: undefined,
      show: !reportEnabled,
    },
  ];
}

function ConfirmClaimSlider() {
  const ref = useLeftHeaderTitle({
    title: "reimbursement.confirmClaim.title",
  });
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();
  const { t } = useTranslation();

  const currentClaimFormDetails = useSelector(createClaimFormDetailsSelector);
  const selectedReimbursement = useSelector(selectedReimbursementSelector);
  const merchants = useSelector(vendorsListSelector); // merchant control options
  const categories = useSelector(categoriesListSelector); // category control options
  const mileageRateList = useSelector(mileageRateListSelector);
  const claimCategoryLimitDetails = useSelector(
    setClaimCategoryLimitDetailSelector
  );
  const makerCheckerStatus = useSelector(
    reimbursementMakerCheckerEnabledSelector
  );

  const isFetching = useSelector(isFetchingPaymentApproversSelector);

  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const accountingEnabled = !!accountingSoftware;
  // cutom for payrolll
  const customTags = useSelector(reimbursementCustomTagsSelector);
  // vendor Id

  // accounting category
  const accountingCategory = useSelector(accountingCategoryTagSelector);
  // accounting non category
  const nonAccountingCategory = useSelector(nonAccountingCategoryTagSelector);
  const allKeysAccounting = {
    ...(accountingEnabled
      ? { [accountingCategory?.id]: accountingCategory }
      : {}),
    ...(accountingEnabled
      ? nonAccountingCategory
          ?.map((tag) => ({
            [tag?.id]: tag,
          }))
          .reduce((acc, curr) => ({ ...acc, ...curr }), {})
      : {}),
    ...customTags
      ?.map((tag) => ({
        [tag?.id]: tag,
      }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
  };
  const [formFields, setFormFields] = useState([]);

  const countryName = currentClaimFormDetails?.travelCountry;
  const kms = currentClaimFormDetails?.totalMiles;

  const mileage = mileageRateList?.find(
    (data) => data.countryName === countryName
  );
  const currency = mileage?.currency;
  const mileageRate = mileage?.rate;

  const distanceToAmountCalc = kms === 0 ? 0 : (mileageRate * kms).toFixed(2);

  const reportEnabled = useSelector(reimbursementReportsEnabledSelector);

  const reviewReportId = searchParam.get(
    SLIDERS_SEARCH_PARAMS.reports.reviewId
  );

  const outOfPocketType =
    currentClaimFormDetails?.reimbursementType ===
    CREATE_REIMBURSEMENT_TYPE.outOfPocket.value;

  const CREATE_CLAIM_SUMMARY_CONFIG = configFunction(
    outOfPocketType,
    claimCategoryLimitDetails,
    reportEnabled
  );

  const reimId =
    searchParam.get(SLIDERS_SEARCH_PARAMS.reimbursements.review) ||
    searchParam.get(SLIDERS_SEARCH_PARAMS.reimbursements.editClaim);

  const editMode = searchParam.has(
    SLIDERS_SEARCH_PARAMS.reimbursements.editClaim
  );

  const [tab] = window.location.pathname.split("/").slice(-1);

  const isCreateLoading = useSelector(isCreateLoadingSelector);
  const isReviewLoading = useSelector(isFormSubmissionProgressSelector);
  const isLoading = isCreateLoading || isReviewLoading;

  useEffect(() => {
    if (!currentClaimFormDetails) {
      searchParam.delete(SLIDERS_SEARCH_PARAMS.reimbursements.confirm);
      setSearchParam(searchParam);
    }
  }, [currentClaimFormDetails]);

  const customValue = (fieldName) => {
    switch (fieldName) {
      case CREATE_CLAIM_PARAMS.REIMBURSEMENT_TYPE:
        if (currentClaimFormDetails[fieldName] === "out_of_pocket") {
          return TYPE_REIMBURSEMENT.out_of_pocket;
        }
        return TYPE_REIMBURSEMENT.mileage;

      case CREATE_CLAIM_PARAMS.TRANSACTION_AMOUNT:
        return `${currentClaimFormDetails[fieldName]} ${
          currency ?? currentClaimFormDetails?.transactionCurrency
        }`;
      case CREATE_CLAIM_PARAMS.TOTAL_MILES:
        return `${currentClaimFormDetails[fieldName]} kms`;

      case CREATE_CLAIM_PARAMS.MERCHANT: {
        const merchantObj = merchants
          ? merchants?.find(
              (data) => data.id === currentClaimFormDetails[fieldName]
            )
          : null;
        return `${merchantObj?.name}`;
      }
      case CREATE_CLAIM_PARAMS.CATEGORY_ID: {
        const categoryObj = categories?.find(
          (data) => data.id === currentClaimFormDetails[fieldName]
        );
        return `${categoryObj?.name}`;
      }
      case CREATE_CLAIM_PARAMS.TRANSACTION_DATE: {
        return `${dateToString(currentClaimFormDetails[fieldName])}`;
      }
      case CREATE_CLAIM_PARAMS.TRAVEL_DATE: {
        return `${dateToString(currentClaimFormDetails[fieldName])}`;
      }

      case CREATE_CLAIM_PARAMS.PROJECT_ID: {
        return `${currentClaimFormDetails.linkedTo} : ${currentClaimFormDetails.deptOrProjectName}`;
      }
      default:
        return currentClaimFormDetails[fieldName];
    }
  };

  useEffect(() => {
    dispatch(fetchMileageRateList());
  }, []);

  const params = {
    project_id: currentClaimFormDetails?.projectId,
    amount: currentClaimFormDetails?.transactionAmount,
    currency: currency ?? currentClaimFormDetails?.transactionCurrency,
    [PAYMENT_APPROVERS_API_QPARAMS.POLICY_GROUP_TYPE]: REIMBURSEMENT,
  };

  useEffect(() => {
    if (currentClaimFormDetails) {
      setFormFields(
        CREATE_CLAIM_SUMMARY_CONFIG.map((field) => ({
          ...field,
          value: customValue(field.name),
        }))
      );
    }
  }, [currentClaimFormDetails]);

  const cancelHandler = () => {
    searchParam.delete(SLIDERS_SEARCH_PARAMS.reimbursements.confirm, true);
    setSearchParam(searchParam);
  };

  const onSuccess = () => {
    searchParam.delete(SLIDERS_SEARCH_PARAMS.reimbursements.confirm);

    // when the slider is opened by clicking on edit claim or review
    if (reimId) {
      if (editMode) {
        // if edit claim slider is opened
        searchParam.delete(SLIDERS_SEARCH_PARAMS.reimbursements.editClaim);
      } else {
        // if review claim slider is opened
        searchParam.delete(SLIDERS_SEARCH_PARAMS.reimbursements.review);
      }
    }
    // when slider is opened by clicking on create reimbursement
    else searchParam.delete(SLIDERS_SEARCH_PARAMS.reimbursements.create);

    setSearchParam(searchParam);
  };

  const submitHandler = () => {
    const correctedKeysObject = getCorrectedPayload(
      currentClaimFormDetails,
      allKeysAccounting,
      selectedReimbursement ?? {}
    );

    const filteredObject = Object.fromEntries(
      Object.entries(correctedKeysObject)?.filter(([, val]) => val)
    );

    if (editMode) {
      const filteredPayload = {
        ...filteredObject,
        receipts: filteredObject?.receipts?.filter(
          (file) => file instanceof File
        ),
      };
      const formData = convertReimbursmentPayload(filteredPayload);
      dispatch(
        updateClaim({
          id: reimId,
          payload: formData,
          onSuccess,
        })
      );
    } else if (reimId) {
      const filteredPayload = {
        ...filteredObject,
        receipts: filteredObject?.receipts?.filter(
          (file) => file instanceof File
        ),
      };
      const formData = convertReimbursmentPayload(filteredPayload);
      dispatch(
        updateClaim({
          id: reimId,
          payload: formData,
          noToast: true,
          onSuccess: () => {
            dispatch(
              review({
                id: reimId,
                onSuccess,
              })
            );
          },
        })
      );
    } else {
      const formData = convertReimbursmentPayload(filteredObject);
      dispatch(
        createReimbursementClaim({
          payload: formData,
          tab,
          noToast: true,
          onSuccess: (responseId, data) => {
            if (reportEnabled) {
              if (reviewReportId) {
                dispatch(
                  updateReportClaim({
                    id: reviewReportId,
                    payload: {
                      add_ids: [responseId],
                    },
                    onSuccess: () => {
                      dispatch(setReimbursementInitialState());
                      onSuccess();
                    },
                  })
                );
              } else {
                dispatch(appendSelectedClaimForReport(responseId));
                searchParam.delete(SLIDERS_SEARCH_PARAMS.reimbursements.create);
                setSearchParam(searchParam);
                onSuccess();
              }
            } else if (data?.submissionPolicyMet) {
              dispatch(
                review({
                  id: responseId,
                  onSuccess,
                })
              );
            } else {
              searchParam.delete(SLIDERS_SEARCH_PARAMS.reimbursements.confirm);

              searchParam.append(
                SLIDERS_SEARCH_PARAMS.reimbursements.review,
                responseId
              );

              vToast({
                title: t(
                  "reimbursement.createReimbursement.submissionPolicyToast.title"
                ),
                description: t(
                  "reimbursement.createReimbursement.submissionPolicyToast.title"
                ),
                variant: "danger",
              });

              searchParam.delete(SLIDERS_SEARCH_PARAMS.reimbursements.create);
              setSearchParam(searchParam);
            }
          },
        })
      );
    }
  };

  return (
    <>
      <div className="flex flex-col gap-8 slider-content-core px-9 ">
        <div className="flex flex-col">
          <Text
            refProp={ref}
            classes="font-bold text-3xl"
            translationKey={
              reportEnabled
                ? "myVolopay.reimbursements.reports.create.review.claimCreationReviewTitle"
                : "reimbursement.confirmClaim.title"
            }
          />
          <Text
            classes="font-medium text-neutral-500 text-sm"
            translationKey={
              reportEnabled
                ? "myVolopay.reimbursements.reports.create.review.claimCreationReviewDesc"
                : "reimbursement.confirmClaim.subtitle"
            }
          />
        </div>

        <div>
          {currentClaimFormDetails && formFields.length !== 0 ? (
            <FormSummaryInfo formFields={formFields} labelClasses="w-20" />
          ) : (
            <LoaderSkeleton
              borderRadius="200"
              count={5}
              fullWidth
              classes="py-4 my-2"
            />
          )}
        </div>

        {outOfPocketType ? null : (
          <div className="mb-6">
            <DistanceToAmount
              leftValue={{
                translationKey: "distanceUnit",
                translationProps: { kms: commaFormattedNumber(kms) },
                distance: kms,
              }}
              rightValue={{
                translationKey: "reimbursement.createReimbursement.amountValue",
                translationProps: {
                  amount: amountToCurrency(distanceToAmountCalc, currency),
                },
                amount: distanceToAmountCalc,
                currency,
              }}
              leftText="reimbursement.createReimbursement.distanceTravelled"
              rightText="reimbursement.createReimbursement.amountHeading"
              description={{
                translationKey: "reimbursement.createReimbursement.mileageDesc",
                translationProps: {
                  mileageRate,
                  currency,
                },
              }}
            />
          </div>
        )}
        {reportEnabled ? null : currentClaimFormDetails ? (
          <div className=" mb-14">
            <ApproversSectionComponent
              params={params}
              approvalDescription="cards.pCards.sliders.createPhysicalCardsDetailsSummary.approvers.approvalDescription"
              descriptionTextClasses="text-neutral-500"
              makerCheckerStatus={makerCheckerStatus}
            />
          </div>
        ) : null}
      </div>

      <div className="p-6 slider-footer">
        <div className="flex justify-end gap-4">
          <Button
            label="filter.listButton.cancel"
            type="neutral"
            classes="w-[160px] font-medium"
            disabled={isLoading}
            onClick={cancelHandler}
          />

          <Button
            label={
              reportEnabled
                ? "myVolopay.reimbursements.reports.create.review.addClaimCta"
                : "billPay.bill.invoiceInbox.createBill.ctas.submit"
            }
            classes="w-[160px] text-white font-medium"
            variant="primary"
            btnType="submit"
            disabled={isFetching}
            showLoader={isLoading}
            form="create-claim-details-form"
            onClick={submitHandler}
          />
        </div>
      </div>
    </>
  );
}

export default ConfirmClaimSlider;
