import { useState } from "react";
import { useSelector } from "react-redux";

import { allNavUrlsSelector } from "@/store/selectors/navigations";
import { userAllowedActionsConfigSelector } from "@/store/selectors/user";

import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import OrderPhysicalCardButton from "@/components/Cards/PhysicalCardPageHelper/OrderPhysicalCardButton";
import { checkIfUserActionAllowed } from "@/utils/common";

import { CARDS_CTA_KEYS } from "@/constants/Cards";
import { ROUTES } from "@/constants/routes";

import "./styles.scss";

const selectedTabs = [
  {
    name: "cards.cardStatuses.all",
    count: null,
    path: ROUTES.cards.physicalCards.all.absolutePath,
    key: 1,
  },
  {
    name: "cards.cardStatuses.yetToActivate",
    count: null,
    path: ROUTES.cards.physicalCards.yetToActivate.absolutePath,
    key: 2,
  },
  {
    name: "cards.cardStatuses.blocked",
    count: null,
    path: ROUTES.cards.physicalCards.blocked.absolutePath,
    key: 3,
  },
];

export default function PhysicalCards() {
  const [selectedTab, setSelectedTab] = useState(selectedTabs[0]);

  const allNavUrls = useSelector(allNavUrlsSelector);
  const userAllowedActions = useSelector(userAllowedActionsConfigSelector);

  return (
    <div className="pr-11.5 pl-11">
      <div className="flex items-end justify-between pb-10">
        <div className="flex flex-col">
          <Text
            translationKey="cards.title.plural"
            color="neutral-500"
            classes="text-sm font-semibold"
          />
          <Text
            classes="text-4xl font-extrabold"
            translationKey="cards.pCards.title"
          />
        </div>

        <OrderPhysicalCardButton classes="h-11" />
      </div>

      <Tabs
        items={selectedTabs}
        selectedTab={selectedTab?.key}
        setCurrentTab={(tab) => setSelectedTab(tab)}
      />
    </div>
  );
}
