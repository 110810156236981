// note: avoid module imports and business logic code here, in order to prevent cycle issues

export const VP_MODALS = {
  // object to maintain uniqueness of modals
  EDIT_RECURRING_PAYMENT: "EDIT_RECURRING_PAYMENT",
  ARCHIVE_RECURRING_PAYMENT: "ARCHIVE_RECURRING_PAYMENT",
  DELETE_RECURRING_PAYMENT: "DELETE_RECURRING_PAYMENT", // delete vs archive: delete has an extra `upcoming` option
  EDIT_BILL_RESET_APPROVAL_POLICY_BILLPAY:
    "EDIT_BILL_RESET_APPROVAL_POLICY_BILLPAY",
  //
  EDIT_RECURRING_PAYROLL: "EDIT_RECURRING_PAYROLL",
  ARCHIVE_RECURRING_PAYROLL: "ARCHIVE_RECURRING_PAYROLL",
  DELETE_RECURRING_PAYROLL: "DELETE_RECURRING_PAYROLL", // delete vs archive: delete has an extra `upcoming` option
  EDIT_BILL_RESET_APPROVAL_POLICY_PAYROLL:
    "EDIT_BILL_RESET_APPROVAL_POLICY_PAYROLL",
  SCHEDULED_PAYMENT: "SCHEDULED_PAYMENT",
  SKIP_APPROVAL: "SKIP_APPROVAL",
};

export const AXIOS_API_METHOD = {
  GET: "get",
  POST: "post",
  DELETE: "delete",
  PUT: "put",
};

export const MODULES = {
  CARDS: "cards",
  BILL_PAY: "billPay",
  REIMBURSEMENTS: "reimbursements",
  PAYROLL: "payroll",
  TOP_UPS: "topUps",
  OUT_OF_POCKET: "outOfPocket",
  MILEAGE: "mileage",
  PAYMENTS: "payments",
  QRPAY: "qrPay",
  PHYSICAL_CARD: "physicalCard",
  EXPENSE: "expense",
};

export const OWNER_TYPE_MASTER_HISTORY = {
  CARD: "Card",
  EXPENSE: "Expense",
  Budget: "Budget",
  PROJECT: "Project",
  QR_PAY: "QrPay",
  CARD_REQUEST: "CardRequest",
  VIRTUAL_CARD_REQUEST: "VirtualCardRequest",
  PHYSICAL_CARD_REQUEST: "PhysicalCardRequest",
  LOCATION: "Location",
  BUDGET: "Budget",
  REIMBURSEMENT: "Reimbursement",
  PAYMENT: "Payment",
  PURCHASE_BILL: "PurchaseBill",
  VENDOR: "Vendor",
  TOP_UP_REQUEST: "TopUpRequest",
  USER: "user",
  PAYROLL: "Payroll",
};
