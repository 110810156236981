import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLoadingErrorInjector from "@/hooks/useErrorLoader";
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { setUserIdsForMissingDetails } from "@/store/reducers/expense";
import {
  fetchAndSelectUser,
  resetSelectedUserCardsListAndPagination,
} from "@/store/reducers/user";

import {
  isAdminSelector,
  isExternalAccountantSelector,
  isFetchingSelectedUserSelector,
  selectedUserSelector,
  userSelector,
} from "@/store/selectors/user";

import Alert from "@/components/core/Alert";
import Avatar from "@/components/core/Avatar";
import Badge from "@/components/core/Badge";
import Button from "@/components/core/Button";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import UserDetailsOverview from "@/components/Company/UserDetailsOveriew";
import UserDetailsSettings from "@/components/Company/UserDetailsSettings";
import { ROLES_TYPE } from "@/components/common/BillPayAndPayroll/Settings/Sliders/common/SettingsType";
import ModuleHistory from "@/components/common/ModuleHistory";
import { OWNER_TYPE_MASTER_HISTORY } from "@/utils/constants/app";
import { dateToString } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  COMPANY_PEOPLE_USER_STATUS,
  COMPANY_USER_DETAILS_TAB,
} from "@/constants/company";
import {
  EDIT_PROFILE_CTA_KEY,
  USER_ACCESS_SETTINGS_KEYS,
  USER_STATUS,
  USER_STATUS_CTA_MAP,
} from "@/constants/user";

export default function UserDetail({ setOnClose }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentUser = useSelector(userSelector);
  const selectedUser = useSelector(selectedUserSelector);
  const [selectedTab, setSelectedTab] = useState(0);
  const userId = searchParams.get(SLIDERS_SEARCH_PARAMS.company.people.id);
  const isFetchingSelectedUserInfo = useSelector(
    isFetchingSelectedUserSelector
  );

  const isExternalAccountant = useSelector(isExternalAccountantSelector);

  const selectedUserIsExternalAccountant =
    selectedUser?.role?.name === ROLES_TYPE.EXTERNAL_ACCOUNTANT;

  const isAdmin = useSelector(isAdminSelector);
  const displayName = selectedUser?.displayName;

  const isBlockedOrFreezed =
    [USER_STATUS.FREEZED, USER_STATUS.BLOCKED].includes(selectedUser?.status) ||
    isExternalAccountant ||
    selectedUserIsExternalAccountant;

  const ref = useLeftHeaderTitle({ title: displayName });
  const updatedCompanyUserDetailsTab = useMemo(() => {
    let isUserAccessKeysPresent = false;

    Object.values(USER_ACCESS_SETTINGS_KEYS).forEach((key) => {
      const isPresent = selectedUser?.ctas?.find((cta) => cta === key);
      isUserAccessKeysPresent = isUserAccessKeysPresent || !!isPresent;
    });

    const myProfileTabs = JSON.parse(JSON.stringify(COMPANY_USER_DETAILS_TAB));

    myProfileTabs.splice(1, 1);

    return !isUserAccessKeysPresent ? myProfileTabs : COMPANY_USER_DETAILS_TAB;
  }, [selectedUser?.ctas]);

  const restrictionBy =
    selectedUser?.status === COMPANY_PEOPLE_USER_STATUS.FREEZED
      ? selectedUser?.metaData?.freezedBy
      : selectedUser?.metaData?.blockedBy;

  const restrictedByTheUser =
    restrictionBy === currentUser?.displayName ? "you" : restrictionBy;
  const restrictedDate = selectedUser?.metaData?.date;

  useEffect(() => {
    dispatch(
      fetchAndSelectUser({
        userId: searchParams.get(SLIDERS_SEARCH_PARAMS.company.people.id),
      })
    );

    dispatch(setUserIdsForMissingDetails(userId));

    return () => {
      dispatch(resetSelectedUserCardsListAndPagination());
    };
  }, []);

  const getAlertTitleKey = () => {
    if (isAdmin) {
      return restrictedDate !== null
        ? "company.people.peopleMyProfileSlider.restrictedByAdminViewWithDate"
        : "company.people.peopleMyProfileSlider.restrictedByAdminView";
    }
    return restrictedDate !== null
      ? "company.people.peopleMyProfileSlider.restrictedByUserViewWithDate"
      : "company.people.peopleMyProfileSlider.restrictedByUserView";
  };

  const allState = useLoadingErrorInjector({
    apiKey: "User:get",
    showLoader: false,
    error: {
      header: "",
    },
  });

  return (
    <div className="pb-4 slider-content-core" ref={allState?.attach}>
      <div className="flex flex-col gap-6">
        <div className="flex items-center gap-4">
          <Avatar
            size="3xl"
            imgSrc={selectedUser?.avatarUrl}
            imgClasses="w-14.5 h-14.5"
            name={displayName}
            color={selectedUser?.colorCode}
          />
          <div className="flex flex-col text-3xl">
            <div className="flex items-center gap-2">
              <Text
                translationKey={displayName}
                classes="font-bold text-neutral-800"
                refProp={ref}
              />
              <Badge
                translationKey={USER_STATUS_CTA_MAP[selectedUser?.status]?.text}
                variant={USER_STATUS_CTA_MAP[selectedUser?.status]?.color}
                classes="w-[calc(theme(spacing.3)*9)]"
              />
            </div>
            <Text
              translationKey={selectedUser?.email}
              classes="text-sm font-medium text-neutral-500"
            />
          </div>
        </div>

        <div className="flex flex-row gap-5 ">
          {selectedUser?.ctas?.includes(EDIT_PROFILE_CTA_KEY) ? (
            <Button
              preIcon={!isBlockedOrFreezed ? "Edit" : ""}
              variant="tertiary"
              onClick={() => {
                searchParams.append(
                  SLIDERS_SEARCH_PARAMS.company.people.edit,
                  true
                );
                setSearchParams(searchParams);
              }}
              classes="text-neutral-500 px-5 py-3 w-auto"
              label={
                isBlockedOrFreezed
                  ? "company.people.peopleMyProfileSlider.viewDetails"
                  : "company.people.peopleMyProfileSlider.editProfile"
              }
            />
          ) : (
            ""
          )}
          {selectedUser?.ctas?.includes("setting") ? (
            <Button
              preIcon="Settings"
              variant="tertiary"
              classes="text-neutral-500 px-5 py-3 w-auto"
              label="Settings"
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {!selectedUserIsExternalAccountant &&
      !isExternalAccountant &&
      isBlockedOrFreezed ? (
        <div className="my-8">
          <Alert
            icon="Lock"
            wrapperDivClass="justify-start gap-6"
            iconClasses="w-6 h-6 text-neutral-500"
            variant="danger"
            title={getAlertTitleKey()}
            titleTransalationProp={{
              restricted: selectedUser?.status,
              user: restrictedByTheUser,
              date: dateToString(restrictedDate),
            }}
            textClasses="text-base font-semibold text-neutral-800"
          />
        </div>
      ) : null}

      <Tabs
        items={updatedCompanyUserDetailsTab}
        selectedTab={selectedTab?.key}
        setCurrentTab={(tab) => {
          setSelectedTab(tab.key);
        }}
        mode
        classes="mt-9"
      />
      {selectedTab === 0 ? (
        <UserDetailsOverview />
      ) : selectedTab === 1 ? (
        <UserDetailsSettings />
      ) : (
        <ModuleHistory
          ownerId={selectedUser?.id}
          ownerType={OWNER_TYPE_MASTER_HISTORY.USER}
        />
      )}
    </div>
  );
}
