import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  addProject,
  archiveProject,
  fetchAndSelectDepartment,
  fetchAndSelectProject,
  fetchDepartments,
  fetchPeople,
  fetchPeopleShallow,
  fetchProjects,
  resetBudgetFormData,
  resetBudgetFromData,
  resetDepartmentOrProjectFormData,
  setDepartmentOrProjectFormData,
  setSelectedDepartment,
  setSelectedProject,
  updateProject,
} from "@/store/reducers/company";
import { setIsFormSubmissionProgress } from "@/store/reducers/loadersError";
import { setCurrentPolicies } from "@/store/reducers/policy";

import {
  approvalPolicyEnabledSelector,
  claimPolicyEnabledSelector,
  defaultCurrencySelector,
  expenseReviewPolicyEnabledSelector,
  submissionPolicyEnabledSelector,
} from "@/store/selectors/client";
import {
  allPeopleSelector,
  budgetFormDataSelector,
  departmentOrProjectFormDataSelector,
  isFetchingPeopleSelector,
  selectedDepartmentSelector,
  selectedProjectSelector,
} from "@/store/selectors/company";

import { availableModulesSelector } from "@/store/selectors/user";
import Alert from "@/components/core/Alert";
import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import BudgetPill from "@/components/common/BudgetPill";
import CollapsibleSection from "@/components/common/CollapsibleSection";
import { useForm } from "@/utils/useForm";
import {
  amountToCurrency,
  capitalizeFirstLetter,
  convertDateStringTOYYYYMMDD,
  dateToString,
  validateAndConvertToISO8601,
} from "@/utils/common";

import { MODULES } from "@/utils/constants/app";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { FORM_TYPE } from "@/constants/common";
import {
  BE_FE_FREQUENCY_MAP,
  BUDGET_FREQUENCY_LABELS,
  DEPARTMENT,
  PROJECT,
} from "@/constants/company";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import {
  POLICY_GROUP_MAPPING,
  POLICY_OPTIONS_CONFIG,
} from "@/constants/policy";
import { SETTINGS_KEY } from "@/constants/settings";

import FlexibleBudgetBadge from "../common/FlexibleBudgetBadge";
import AddDepartmentMainForm from "./AddDepartmentMainForm";
import AddProjectMainForm from "./AddProjectMainForm";

function AddProjectDepartment({ type, isInEditMode, setOnClose }) {
  const dispatch = useDispatch();

  const [dateError, setDateError] = useState("");
  const currency = useSelector(defaultCurrencySelector);
  const isFetchingPeople = useSelector(isFetchingPeopleSelector);
  const submissionPolicyEnabled = useSelector(submissionPolicyEnabledSelector);
  const approvalPolicyEnabled = useSelector(approvalPolicyEnabledSelector);
  const claimPolicyEnabled = useSelector(claimPolicyEnabledSelector);
  const isDepartment = type === DEPARTMENT;
  const people = useSelector(allPeopleSelector);
  const departmentOrProjectForm = useSelector(
    departmentOrProjectFormDataSelector
  );
  const availableModules = useSelector(availableModulesSelector);
  const [isBudgetOptionOpened, setIsBudgetOptionOpened] = useState(false);
  const [isPolicyDetails, setIsPolicyDetails] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const budgetForms = useSelector(budgetFormDataSelector);
  const [defineBudgetButtonDisabled, setDefineBudgetButtonDisabled] =
    useState(false);
  const expenseReviewPolicyEnabled = useSelector(
    expenseReviewPolicyEnabledSelector
  );

  const selectedData = useSelector((state) =>
    isDepartment
      ? selectedDepartmentSelector(state)
      : selectedProjectSelector(state)
  );

  const onFinal = () => {
    dispatch(setIsFormSubmissionProgress(false));
  };

  const [closeAlert, setCloseAlert] = useState({
    budgets: true,
    policy: true,
  });

  useEffect(() => {
    // checking if slider closed
    setOnClose((searchParam, isLastSlider) => {
      let searchParamKey = null;
      if (isDepartment)
        searchParamKey =
          SLIDERS_SEARCH_PARAMS.company.department?.[
            isInEditMode ? "edit" : "add"
          ];
      else
        searchParamKey =
          SLIDERS_SEARCH_PARAMS.company.project?.[
            isInEditMode ? "edit" : "add"
          ];
      if (!searchParam.includes(searchParamKey)) {
        dispatch(resetDepartmentOrProjectFormData());
        dispatch(resetBudgetFromData());
      }
    });
  }, []);

  const initialState = isDepartment
    ? {
        name: {
          value: departmentOrProjectForm?.name ?? selectedData?.name ?? "",
          validate: { required: true },
        },
        ...(isInEditMode
          ? {}
          : {
              departmentManagers: {
                value: departmentOrProjectForm?.departmentManagers ?? [],
                validate: { required: true },
              },
            }),
      }
    : {
        name: {
          value: departmentOrProjectForm?.name ?? selectedData?.name ?? "",
          validate: { required: true },
        },
        startDate: {
          value:
            (departmentOrProjectForm?.startDate ?? selectedData?.startDate)
              ? new Date(
                  validateAndConvertToISO8601(
                    departmentOrProjectForm?.startDate ??
                      selectedData?.startDate
                  )
                )
              : "",
          validate: {
            required: true,
          },
        },
        endDate: {
          value:
            (departmentOrProjectForm?.endDate ?? selectedData?.endDate)
              ? new Date(
                  validateAndConvertToISO8601(
                    departmentOrProjectForm?.endDate ?? selectedData?.endDate
                  )
                )
              : "",
        },
        ...(isInEditMode
          ? {}
          : {
              projectOwners: {
                value: departmentOrProjectForm?.projectOwners ?? [],
                validate: { required: false },
              },
              projectMembers: {
                value: departmentOrProjectForm?.projectMembers ?? [],
                validate: { required: false },
              },
            }),
      };

  const handleCloseOfSlider = () => {
    if (isDepartment)
      searchParams.delete(
        SLIDERS_SEARCH_PARAMS.company.department?.[
          isInEditMode ? "edit" : "add"
        ]
      );
    else
      searchParams.delete(
        SLIDERS_SEARCH_PARAMS.company.project?.[isInEditMode ? "edit" : "add"]
      );

    searchParams.delete(SLIDERS_SEARCH_PARAMS.company.budget.create);
    setSearchParams(searchParams);
    dispatch(setDepartmentOrProjectFormData(null));
    dispatch(resetBudgetFormData());
  };

  const handleArchive = () => {
    const id = searchParams.get(
      type === DEPARTMENT
        ? SLIDERS_SEARCH_PARAMS.company.department.edit
        : SLIDERS_SEARCH_PARAMS.company.project.edit
    );

    const onSuccess = (response) => {
      searchParams.delete(SLIDERS_SEARCH_PARAMS.company[type]?.id);
      setSearchParams(searchParams);
      handleCloseOfSlider();
      if (isDepartment) {
        dispatch(
          fetchDepartments({
            page: 1,
            per_page: 10,
            department: isDepartment,
          })
        );
      } else if (!isDepartment) {
        dispatch(
          fetchProjects({
            page: 1,
            per_page: 10,
            department: isDepartment,
          })
        );
      }
    };

    const payload = {
      id,
      department: type === DEPARTMENT,
    };
    dispatch(archiveProject({ id, payload, onSuccess }));
  };

  const handleInnerSubmit = () => {
    let dataValues = null;
    let departmentManagerApiPayload = null;
    if (type === PROJECT) {
      const { projectMembers, projectOwners, ...data } = values;
      dataValues = data;
      departmentManagerApiPayload = projectOwners?.length
        ? projectOwners?.map((item) => ({
            user_id: item,
            member_type: "owner",
          }))
        : [];
      departmentManagerApiPayload = [
        ...departmentManagerApiPayload,
        ...(projectMembers?.map((item) => ({
          user_id: item,
          member_type: "member",
        })) ?? []),
      ];
    } else {
      const { departmentManagers, ...data } = values;
      dataValues = data;
      departmentManagerApiPayload = departmentManagers?.length
        ? departmentManagers?.map((item) => ({
            user_id: item,
            member_type: "owner",
          }))
        : [];
    }
    const budgetApipayload = budgetForms?.map((item) => ({
      limit: item?.amount ?? item?.limit,
      frequency: item?.frequency,
      flexible: item?.flexible,
      modules: item?.budgetFor,
    }));

    let storeData = {
      ...dataValues,
      ...(departmentManagerApiPayload?.length
        ? { project_members_attributes: departmentManagerApiPayload }
        : {}),
      budgets_attributes: budgetApipayload,
      department: type === DEPARTMENT,
      type: capitalizeFirstLetter(type),
    };

    if (type === PROJECT) {
      const _storeData = structuredClone(storeData);
      delete _storeData.startDate;
      delete _storeData.endDate;
      storeData = {
        ..._storeData,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      };
    }

    const id = searchParams.get(
      type === DEPARTMENT
        ? SLIDERS_SEARCH_PARAMS.company.department.edit
        : SLIDERS_SEARCH_PARAMS.company.project.edit
    );
    const onSuccess = (response) => {
      handleCloseOfSlider();
      if (isInEditMode) {
        if (isDepartment) {
          dispatch(
            fetchDepartments({
              limit: PAGINATION_PER_REQUEST_LIMIT,
              page: 1,
              department: isDepartment,
            })
          );
        } else {
          dispatch(
            fetchProjects({
              limit: PAGINATION_PER_REQUEST_LIMIT,
              page: 1,
              department: isDepartment,
            })
          );
        }
      } else if (isDepartment) {
        dispatch(setSelectedDepartment(response));
        dispatch(
          fetchDepartments({
            limit: PAGINATION_PER_REQUEST_LIMIT,
            page: 1,
            department: isDepartment,
          })
        );
      } else {
        dispatch(setSelectedProject(response));
        dispatch(
          fetchProjects({
            limit: PAGINATION_PER_REQUEST_LIMIT,
            page: 1,
            department: isDepartment,
          })
        );
      }
    };

    if (isInEditMode) {
      dispatch(setIsFormSubmissionProgress(true));
      dispatch(updateProject({ id, payload: storeData, onSuccess, onFinal }));
    } else dispatch(addProject({ ...storeData, onSuccess }));
  };

  const {
    values,
    handleChange,
    handleSubmit,
    isFormButtonDisabled,
    errors,
    _setValues: setValues,
  } = useForm(initialState, handleInnerSubmit, {
    formId: "project-department-form",
  });

  const [policyDetailConfig, setPolicyDetailConfig] = useState(
    POLICY_OPTIONS_CONFIG
  );

  const formattedStartDate = useMemo(() => {
    return (
      convertDateStringTOYYYYMMDD(
        dateToString(
          values?.startDate,
          { year: "numeric", month: "2-digit" },
          true
        )
      ) ?? ""
    );
  }, [values?.startDate]);

  const formattedEndDate = useMemo(() => {
    return values?.endDate
      ? convertDateStringTOYYYYMMDD(
          dateToString(
            values?.endDate,
            { year: "numeric", month: "2-digit" },
            true
          )
        )
      : "";
  }, [values?.endDate]);

  useEffect(() => {
    if (isDepartment && isInEditMode) {
      const id = searchParams.get(
        SLIDERS_SEARCH_PARAMS.company.department.edit
      );
      dispatch(fetchAndSelectDepartment({ id }));
    } else if (type === PROJECT && isInEditMode) {
      const id = searchParams.get(SLIDERS_SEARCH_PARAMS.company.project.edit);
      dispatch(fetchAndSelectProject({ id }));
    } else if (isDepartment) dispatch(setSelectedDepartment(null));
    else dispatch(setSelectedProject(null));
  }, [type, isInEditMode]);

  useEffect(() => {
    const _preexistingBudgetFor = [
      ...new Set(budgetForms?.map((budget) => budget?.budgetFor).flat(2)),
    ];
    setDefineBudgetButtonDisabled(_preexistingBudgetFor.length === 3);
    setIsBudgetOptionOpened(budgetForms?.length > 0);
  }, [budgetForms]);

  useEffect(() => {
    dispatch(fetchPeopleShallow({ shallow: true }));
  }, []);

  const handleSliderClick = (sliderId) => {
    if (sliderId === SETTINGS_KEY.claimPolicy)
      searchParams.set(
        SLIDERS_SEARCH_PARAMS.reimbursements.settings.spendControls.claimPolicy,
        FORM_TYPE.preview
      );
    else if (sliderId === SETTINGS_KEY.expenseReviewPolicy)
      searchParams.set(
        SLIDERS_SEARCH_PARAMS.settings.policyModule,
        POLICY_GROUP_MAPPING.expenseReview
      );
    else
      searchParams.set(
        SLIDERS_SEARCH_PARAMS.settings[sliderId],
        isDepartment ? DEPARTMENT : PROJECT
      );

    let policies = selectedData?.policyGroups;
    if (sliderId === SETTINGS_KEY.submissionPolicy)
      policies = selectedData?.submissionPolicies;

    dispatch(setCurrentPolicies(policies));

    setSearchParams(searchParams);
  };

  const handleNavigationToBudget = (inEdit = false, index = null) => {
    let _searchParam = null;
    if (inEdit) {
      _searchParam =
        SLIDERS_SEARCH_PARAMS.company.budget.editBudget?.[
          isDepartment ? "newDeptBudget" : "newProjBudget"
        ];
    } else
      _searchParam =
        SLIDERS_SEARCH_PARAMS.company.budget.createForm?.[
          isDepartment ? "newDeptBudget" : "newProjBudget"
        ];

    searchParams.append(SLIDERS_SEARCH_PARAMS.company.budget.storeMode, true);
    searchParams.append(_searchParam, inEdit ? index : true);
    setSearchParams(searchParams);
  };

  const checkEnabled = (policyType) => {
    switch (policyType) {
      case SETTINGS_KEY.approvalPolicy:
        return approvalPolicyEnabled;
      case SETTINGS_KEY.submissionPolicy:
        return submissionPolicyEnabled;
      case SETTINGS_KEY.claimPolicy:
        return claimPolicyEnabled;
      case SETTINGS_KEY.expenseReviewPolicy:
        return expenseReviewPolicyEnabled;
      default:
        return false;
    }
  };

  const isDisabledInEditMode = useMemo(() => {
    if (isInEditMode) {
      const isNameIsSame =
        selectedData?.name?.toLowerCase() === values?.name?.toLowerCase();
      const startDateIsSame = selectedData?.startDate === formattedStartDate;
      const endDateIsSame = selectedData?.endDate === formattedEndDate;

      return isNameIsSame;
      // return startDateIsSame && endDateIsSame && isNameIsSame; # commenting since project doesn't have endDate and startDate editable
    }

    return false;
  }, [values, selectedData]);

  useEffect(() => {
    const policyOptions = POLICY_OPTIONS_CONFIG?.filter(
      (item) =>
        !item?.module ||
        availableModules?.includes(item.module) ||
        (item.module === MODULES.EXPENSE && expenseReviewPolicyEnabled)
    );
    setPolicyDetailConfig(policyOptions);
  }, [JSON.stringify(availableModules)]);
  const policyDetails = () => (
    <div className="flex flex-col gap-3 mb-9">
      {policyDetailConfig.map((item) => (
        <div
          className="relative flex items-center justify-between p-4 overflow-hidden cursor-pointer card-wrapper"
          key={item?.key}
          onClick={() => handleSliderClick(item?.sliderId)}
        >
          <div className="flex items-center mr-3">
            <div
              className={
                item.isCustom ? "w-2 h-full bg-primary-500 absolute left-0" : ""
              }
            />
            <div
              className={`flex items-center justify-center p-3 mr-3 rounded-full ${
                checkEnabled(item?.sliderId)
                  ? "bg-primary-50"
                  : "bg-neutral-200"
              }`}
            >
              <Icon
                name={item.icon}
                className={`w-5 h-5 ${
                  checkEnabled(item?.sliderId)
                    ? "text-primary-500"
                    : "text-neutral-500"
                }`}
              />
            </div>
            <div>
              <div>
                <Text
                  classes="text-neutral-800 font-semibold"
                  translationKey={item.title}
                />
                <icon name="Info" className="text-neutral-500" />
              </div>
              <Text
                classes="text-neutral-500 text-sm font-semibold"
                translationKey={item.description}
              />
            </div>
          </div>

          <Icon name="ArrowForward" />
        </div>
      ))}
    </div>
  );

  return (
    <>
      <div className="flex flex-col justify-between gap-9 pb-9 slider-content-core">
        <div className="flex flex-col gap-9">
          <form
            id="project-department-form"
            onSubmit={handleSubmit}
            className="flex flex-col gap-9"
          >
            {isDepartment ? (
              <AddDepartmentMainForm
                isInEditMode={isInEditMode}
                fetchOption={() => {
                  dispatch(fetchPeople({ status: "active" }));
                }}
                errors={errors}
                value={values}
                handleChange={handleChange}
                people={people}
                isFetching={isFetchingPeople}
              />
            ) : (
              <AddProjectMainForm
                isInEditMode={isInEditMode}
                projectMember={people}
                projectOwner={people}
                fetchProjectOwnerOptions={() => {
                  dispatch(fetchPeople({ status: "active" }));
                }}
                fetchProjectMemberOptions={() => {
                  dispatch(fetchPeople({ status: "active" }));
                }}
                setValues={setValues}
                isFetchingProjectMember={isFetchingPeople}
                isFetchingProjectOwnerOptions={isFetchingPeople}
                errors={errors}
                value={values}
                dateError={dateError}
                setDateError={setDateError}
                handleChange={handleChange}
              />
            )}
          </form>
          {/* Advance details */}
          <div className="flex flex-col gap-5">
            {!isInEditMode ? (
              <Text
                classes="text-xl text-neutral-800 font-semibold"
                translationKey="company.department.addNewDepartment.advanceOptions"
              />
            ) : null}
            {/* Budgets  */}
            {!isInEditMode ? (
              <CollapsibleSection
                value={isBudgetOptionOpened}
                handleChange={() => setIsBudgetOptionOpened((prev) => !prev)}
                title="company.department.addNewDepartment.budgets.title"
                description={`company.department.${
                  isDepartment ? "addNewDepartment" : "addNewProject"
                }.budgets.description`}
              >
                <div className="flex flex-col gap-3 mt-3 mb-6">
                  {closeAlert?.budgets && !budgetForms?.length ? (
                    <Alert
                      wrapperDivClass="items-start"
                      variant="neutral"
                      title="company.department.addNewDepartment.budgets.alert.title"
                      description={`company.department.${
                        isDepartment ? "addNewDepartment" : "addNewProject"
                      }.budgets.alert.description`}
                      iconClasses="mt-1 mr-4"
                      close={() =>
                        setCloseAlert((prev) => ({
                          ...prev,
                          budgets: !prev.budgets,
                        }))
                      }
                    />
                  ) : null}
                  {budgetForms?.length ? (
                    <div className="flex flex-col gap-3">
                      {budgetForms?.map((budgetForm, index) => (
                        <div
                          className="relative flex flex-col gap-5 p-3 card-wrapper"
                          key={`budgetForm-${index}`}
                        >
                          <Icon
                            name="Edit"
                            className="absolute cursor-pointer text-neutral-500 top-5 right-5"
                            handleClick={() =>
                              handleNavigationToBudget(true, index)
                            }
                          />
                          <div className="flex flex-row gap-2">
                            <Text
                              classes="font-semibold text-neutral-800"
                              translationKey="company.budgets.budgetFor"
                            />
                            <span>:</span>
                            <BudgetPill
                              keyForModules="budgetFor"
                              id={index}
                              hideInfo
                              data={budgetForm?.budgetFor ? [budgetForm] : []}
                            />
                          </div>
                          <div className="flex flex-row items-center gap-2 px-5 py-3 text-sm rounded-sm bg-neutral-50">
                            <span>
                              {amountToCurrency(budgetForm?.amount, currency)}
                            </span>
                            <Text
                              classes="lowercase"
                              translationKey={
                                BUDGET_FREQUENCY_LABELS[
                                  BE_FE_FREQUENCY_MAP[budgetForm?.frequency]
                                ]
                              }
                            />
                            {budgetForm?.flexible ? (
                              <FlexibleBudgetBadge />
                            ) : null}
                          </div>
                        </div>
                      ))}
                      {defineBudgetButtonDisabled ? (
                        <Tooltip
                          maxWidth="50%"
                          direction="top"
                          id="company-department-addNewDepartment-budgets-defineNewBudgetBtn"
                        >
                          <Text translationKey="company.department.addNewProject.budgets.alreadyBudgeted" />
                        </Tooltip>
                      ) : null}
                      <Button
                        btnType="button"
                        id="company-department-addNewDepartment-budgets-defineNewBudgetBtn"
                        variant="tertiary"
                        classes="w-15.5 h-8"
                        labelStyleClasses="text-xs font-bold"
                        disabled={defineBudgetButtonDisabled}
                        label="company.department.addNewDepartment.budgets.defineNewBudgetBtn"
                        onClick={() => {
                          dispatch(setDepartmentOrProjectFormData(values));
                          handleNavigationToBudget();
                        }}
                      />
                    </div>
                  ) : (
                    <div className="flex items-center justify-between p-4 card-wrapper">
                      <div className="flex items-center gap-3">
                        <Icon
                          name="PieChart"
                          className="w-10 h-10 p-3 rounded-full bg-integrations-50 text-integrations-500"
                        />

                        <Text
                          classes="text-neutral-800 font-semibold"
                          translationKey="company.department.addNewDepartment.budgets.noBudgetDefined"
                        />
                      </div>
                      <Button
                        btnType="button"
                        labelStyleClasses="text-xs font-bold"
                        variant="tertiary"
                        classes="w-15.5 h-8"
                        label="company.department.addNewDepartment.budgets.defineNewBudgetBtn"
                        onClick={() => {
                          dispatch(setDepartmentOrProjectFormData(values));
                          handleNavigationToBudget();
                        }}
                      />
                    </div>
                  )}
                </div>
              </CollapsibleSection>
            ) : null}
            {/* policy details */}
            {isInEditMode ? (
              <>
                <div className="flex flex-col text-lg font-semibold text-neutral-800 ">
                  <Text translationKey="policy.policyDetails.title" />
                  <Text
                    classes="text-sm text-neutral-500"
                    translationKey={`policy.policyDetails.description${
                      isDepartment ? "Department" : "Project"
                    }`}
                  />
                </div>
                {policyDetails()}
              </>
            ) : (
              <CollapsibleSection
                value={isPolicyDetails}
                handleChange={() => setIsPolicyDetails((prev) => !prev)}
                iconClasses="mt-1"
                title="policy.policyDetails.title"
                description={`company.department.${
                  isDepartment ? "addNewDepartment" : "addNewProject"
                }.policyDetails.description`}
              >
                <div className="flex flex-col gap-3 my-6">
                  <Alert
                    wrapperDivClass="items-start"
                    variant="neutral"
                    title={
                      isDepartment
                        ? "company.department.addNewDepartment.policyDetails.alertTitleDepartment"
                        : "company.department.addNewDepartment.policyDetails.alertTitleProject"
                    }
                    description={
                      isDepartment
                        ? "company.department.addNewDepartment.policyDetails.alertDescriptionDepartment"
                        : "company.department.addNewDepartment.policyDetails.alertDescriptionProject"
                    }
                    iconClasses="mt-1 mr-4"
                  />
                  {policyDetails()}
                </div>
              </CollapsibleSection>
            )}
          </div>
        </div>

        {/* commenting this since archiveDepartment/project will be picking after v2 release */}
        {/* {isInEditMode ? (
          <div className="border-t-[1px] flex flex-col gap-3 border-neutral-200 pt-8 mt-6">
            <Button
              label={
                isDepartment
                  ? "company.misc.archiveDepartment"
                  : "company.misc.archiveProject"
              }
              classes="w-16.2"
              type="danger"
              variant="secondary"
              onClick={handleArchive}
            />
            <Text
              classes="text-sm text-neutral-500 font-semibold"
              translationKey={
                isDepartment
                  ? "company.department.archiving.department"
                  : "company.department.archiving.project"
              }
            />
          </div>
        ) : null} */}
      </div>
      <div className="flex justify-end gap-2 px-3 py-4 slider-footer">
        <Button
          btnType="reset"
          form="project-department-form"
          classes="text-neutral-500 w-15.5"
          variant="tertiary"
          label="misc.cancel"
          onClick={handleCloseOfSlider}
        />
        <Button
          form="project-department-form"
          btnType="submit"
          disabled={
            isFormButtonDisabled ||
            (!isInEditMode &&
              (isDepartment
                ? !values?.departmentManagers?.length
                : !values?.projectOwners?.length)) ||
            isDisabledInEditMode
          }
          classes="w-15.5"
          label={
            isInEditMode
              ? "company.department.addNewProject.saveChanges"
              : isDepartment
                ? "company.department.addNewDepartment.footerBtn"
                : "company.department.addNewProject.footerBtn"
          }
        />
      </div>
    </>
  );
}

export default AddProjectDepartment;
AddProjectDepartment.propTypes = {
  type: PropTypes.string,
  isInEditMode: PropTypes.bool,
};
