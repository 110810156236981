import PropTypes from "prop-types";
import { useRef, useState } from "react";

import Icon from "@/components/core/Icon";
import Modal from "@/components/core/Modal";
import Text from "@/components/core/Text";
import Carousel from "@/components/core/Carousel";

import InappGuideButton from "@/components/PageTopSection/InAppGuide/InappGuideButton";

import { CAROUSEL_MODE } from "@/constants/common";

/**
 * VideoSection component
 *
 * This component displays a section with video thumbnails in a carousel. Users can click
 * on a video thumbnail to open a modal, which either shows the video thumbnail with a play
 * button or directly opens the video for playback. Each video item may also include a
 * redirection link displayed as an icon.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Array<Object>} props.videos - Array of video data objects.
 * @param {string} props.videoSectionClasses - Additional CSS classes for the video section container.
 *
 * @example
 * const videos = [
 *   {
 *     title: "Video 1",
 *     description: "Description 1",
 *     videoUrl: "https://example.com/video1",
 *     thumbnailUrl: "https://example.com/thumbnail1",
 *     redirectionUrl: "https://example.com"
 *   },
 * ];
 * <VideoSection videos={videos} videoSectionClasses="custom-class" />
 */

function VideoSection({
  videos,
  videoSectionClasses,
  showPaginatedArrows = false,
}) {
  const [openVideoModal, setOpenVideoModal] = useState();
  const carouselRef = useRef({});
  const [currentPage, setCurrentPage] = useState(0);
  const videosLength = videos?.length;

  function openVideoPlayModal(videoInfo) {
    setOpenVideoModal({
      ...openVideoPlayModal,
      open: true,
      redirectionLink: videoInfo?.redirectionUrl,
      videoUrl: videoInfo?.videoUrl,
      thumbNailUrl: videoInfo?.thumbnailUrl,
    });
  }

  function navigateToGivenUrl(url) {
    if (url) {
      window.open(url, "_blank");
    }
  }

  function handleCloseVideoModal() {
    setOpenVideoModal({
      open: false,
      redirectionLink: null,
      videoUrl: null,
      thumbNailUrl: null,
      playVideo: false,
    });
  }

  const { handleLeftButton, handleRightButton } = carouselRef.current;

  return (
    <div>
      {videos?.length > 0 ? (
        <div
          className={`h-full overflow-x-hidden rounded-lg bg-primary-50 no-scrollbar ${videoSectionClasses}`}
        >
          <div className="flex flex-col gap-4">
            <Carousel
              mode={CAROUSEL_MODE.MULTI_VIEW}
              refProp={carouselRef}
              isStepPaginationButtonsDisabled
              getCurrentPage={(page) => {
                setCurrentPage(page);
              }}
              containerClasses="items-end"
            >
              {videos?.map((video, index) => {
                const thumbNailImage = video?.thumbnailUrl;
                const redirectionUrl = video?.redirectionUrl;

                return (
                  <div
                    key={index}
                    className="flex flex-col gap-3"
                    onClick={() => openVideoPlayModal(video)}
                  >
                    <div className="flex flex-col items-start gap-2 ">
                      <div className="flex flex-row items-center gap-2 w-75">
                        {video?.title ? (
                          <Text
                            translationKey={video?.title}
                            classes="text-base font-semibold h-full"
                          />
                        ) : null}

                        {redirectionUrl ? (
                          <Icon
                            name="NEArrow"
                            className="cursor-pointer text-primary-500"
                            handleClick={() =>
                              navigateToGivenUrl(redirectionUrl)
                            }
                          />
                        ) : null}
                      </div>

                      {video?.description ? (
                        <Text
                          translationKey={video?.description}
                          classes="text-xs font-medium text-neutral-500"
                        />
                      ) : null}
                    </div>

                    <div
                      style={{
                        flexGrow: "1",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "end",
                      }}
                    >
                      <InappGuideButton
                        thumbNailImage={thumbNailImage}
                        handleButtonClick={() => {
                          setOpenVideoModal({
                            ...openVideoModal,
                            playVideo: true,
                          });
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </Carousel>

            <Modal
              open={openVideoModal?.open ?? false}
              onClose={handleCloseVideoModal}
            >
              <div className="m-4">
                {openVideoModal?.playVideo ? (
                  <iframe
                    width="724"
                    height="464"
                    className="rounded-lg iframe-container"
                    src={`${openVideoModal?.videoUrl}&autoplay=1&rel=0`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share fullscreen"
                    allowfullscreen
                  />
                ) : (
                  <InappGuideButton
                    externalClasses="w-181 h-116"
                    handleButtonClick={() => {
                      setOpenVideoModal({
                        ...openVideoModal,
                        playVideo: true,
                      });
                    }}
                    thumbNailImage={openVideoModal?.thumbNailUrl}
                  />
                )}

                {openVideoModal?.redirectionLink ? (
                  <div className="flex flex-row items-center justify-between mt-5">
                    <Text
                      translationKey="myVolopay.gettingStarted.helpCenter.readMore"
                      classes="text-sm font-semibold text-primary-500"
                    />

                    <Icon
                      name="NEArrow"
                      className="cursor-pointer text-primary-500"
                      handleClick={() =>
                        navigateToGivenUrl(openVideoModal?.redirectionLink)
                      }
                    />
                  </div>
                ) : null}
              </div>
            </Modal>
            {showPaginatedArrows ? (
              <div className="flex flex-row items-center justify-between gap-3 mt-4">
                <div className="flex flex-row justify-end w-full gap-2 flex-grow-1">
                  <div
                    className={`p-1 bg-white cursor-pointer rounded-2xl items-center ${
                      currentPage === 0
                        ? "bg-neutral-50 pointer-events-none"
                        : " "
                    }`}
                    onClick={handleLeftButton}
                  >
                    <Icon
                      name="LeftArrow"
                      className={`${
                        currentPage === 0
                          ? "text-neutral-300 pointer-events-none"
                          : ""
                      }`}
                    />
                  </div>

                  <div className="flex items-center">
                    <Text
                      translationKey={`${currentPage + 1}/${videosLength}`}
                      classes="text-sm font-medium text-neutral-500"
                    />
                  </div>

                  <div
                    className={`p-1 bg-white cursor-pointer rounded-2xl ${
                      currentPage === videosLength - 1
                        ? "bg-neutral-50 pointer-events-none"
                        : " "
                    }`}
                    onClick={handleRightButton}
                  >
                    <Icon
                      name="RightArrow"
                      className={
                        currentPage === videosLength - 1
                          ? "text-neutral-300"
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}

VideoSection.propTypes = {
  videos: PropTypes.array,
  videoSectionClasses: PropTypes.string,
  showPaginatedArrows: PropTypes.bool,
};

export default VideoSection;
