import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

import { cardsProvidersListItemSelector } from "@/store/selectors/client";
import { allNavUrlsSelector } from "@/store/selectors/navigations";
import {
  userAllowedActionsConfigSelector,
  userSelector,
} from "@/store/selectors/user";

import Button from "@/components/core/Button";

import OptionsSectionPopup from "@/components/Cards/PhysicalCardPageHelper/OptionsSection";
import { allowedActionsForCurrentPage } from "@/utils/common";

import {
  ORDER_PHYSICAL_CARDS_TYPE,
  ORDER_PHYSICAL_CARD_OPTIONS_KEYS,
} from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ROUTES } from "@/constants/routes";

function OrderPhysicalCardButton({ classes, insideTable = false }) {
  const cardProviderConfig = useSelector(cardsProvidersListItemSelector)?.[0];
  const userAllowedActions = useSelector(userAllowedActionsConfigSelector);
  const allNavUrls = useSelector(allNavUrlsSelector);
  const ctas = allowedActionsForCurrentPage(allNavUrls, userAllowedActions);
  const locationObject = useLocation();

  const bulkPhysicalCardOrderEnabled =
    cardProviderConfig?.config?.bulkPhysicalCardOrderEnabled;

  const showPhysicalCardOrderOptionsPopup = bulkPhysicalCardOrderEnabled;
  const currentUser = useSelector(userSelector);
  const currentUserId = currentUser?.id;

  const [searchParams, setSearchParams] = useSearchParams();

  const ORDER_PHYSICAL_CARD_OPTIONS_KEYS_CONFIG = {
    [ORDER_PHYSICAL_CARD_OPTIONS_KEYS.BULK_PERSONALIZED_ORDER_CARD]: {
      label: "cards.pCards.orderPhysicalCardOptions.bulkPersonalised.title",
      description:
        "cards.pCards.orderPhysicalCardOptions.bulkPersonalised.description",
      key: ORDER_PHYSICAL_CARD_OPTIONS_KEYS.BULK_PERSONALIZED_ORDER_CARD,
      route: {
        paramName:
          SLIDERS_SEARCH_PARAMS.cards.physicalCards.create.physicalCard,
        paramValue: ORDER_PHYSICAL_CARDS_TYPE.BULK_PHYSICAL_CARD_ORDER.key,
      },
    },
    [ORDER_PHYSICAL_CARD_OPTIONS_KEYS.INDIVIDUAL_PERSONALIZED_ORDER_CARD]: {
      label:
        "cards.pCards.orderPhysicalCardOptions.individualPersonalised.title",
      description:
        "cards.pCards.orderPhysicalCardOptions.individualPersonalised.description",
      key: ORDER_PHYSICAL_CARD_OPTIONS_KEYS.INDIVIDUAL_PERSONALIZED_ORDER_CARD,
      route: {
        paramName:
          SLIDERS_SEARCH_PARAMS.cards.physicalCards.create.physicalCard,
        paramValue:
          ORDER_PHYSICAL_CARDS_TYPE.PERSONALISED_PHYSICAL_CARD_ORDER.key,
      },
    },
    [ORDER_PHYSICAL_CARD_OPTIONS_KEYS.BULK_DOMESTIC_PERSONALIZED_ORDER_CARD]: {
      label: "cards.pCards.orderPhysicalCardOptions.bulkDomestic.title",
      description:
        "cards.pCards.orderPhysicalCardOptions.bulkDomestic.description",
      key: ORDER_PHYSICAL_CARD_OPTIONS_KEYS.BULK_DOMESTIC_PERSONALIZED_ORDER_CARD,
      route: {
        paramName:
          SLIDERS_SEARCH_PARAMS.cards.physicalCards.create.physicalCard,
        paramValue: ORDER_PHYSICAL_CARDS_TYPE.BULK_PHYSICAL_CARD_ORDER.key,
      },
    },
    [ORDER_PHYSICAL_CARD_OPTIONS_KEYS.INDIVIDUAL_INTERNATIONAL_ORDER_CARD]: {
      label: "cards.pCards.orderPhysicalCardOptions.international.title",
      description:
        "cards.pCards.orderPhysicalCardOptions.international.description",
      key: ORDER_PHYSICAL_CARD_OPTIONS_KEYS.INDIVIDUAL_INTERNATIONAL_ORDER_CARD,
      route: {
        paramName:
          SLIDERS_SEARCH_PARAMS.cards.physicalCards.create.physicalCard,
        paramValue:
          ORDER_PHYSICAL_CARDS_TYPE.INTERNATIONAL_PHYSICAL_CARD_ORDER.key,
      },
      additionalOnClick: () => {
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.cards.selectedUserCard,
          currentUserId
        );
      },
    },
    [ORDER_PHYSICAL_CARD_OPTIONS_KEYS.INDIVIDUAL_DOMESTIC_PERSONALIZED_ORDER_CARD]:
      {
        label:
          "cards.pCards.orderPhysicalCardOptions.personalisedDomestic.title",
        description:
          "cards.pCards.orderPhysicalCardOptions.personalisedDomestic.title",
        key: ORDER_PHYSICAL_CARD_OPTIONS_KEYS.INDIVIDUAL_DOMESTIC_PERSONALIZED_ORDER_CARD,
        route: {
          paramName:
            SLIDERS_SEARCH_PARAMS.cards.physicalCards.create.physicalCard,
          paramValue:
            ORDER_PHYSICAL_CARDS_TYPE.PERSONALISED_PHYSICAL_CARD_ORDER.key,
        },
        additionalOnClick: () => {
          if (
            locationObject.pathname.includes(
              ROUTES.myVolopay.cards.physicalCards.absolutePath
            )
          ) {
            searchParams.append(
              SLIDERS_SEARCH_PARAMS.cards.selectedUserCard,
              currentUserId
            );
          }
        },
      },
    [ORDER_PHYSICAL_CARD_OPTIONS_KEYS.BULK_INTERNATIONAL_PERSONALIZED_ORDER_CARD]:
      {
        label: "cards.pCards.orderPhysicalCardOptions.international.title",
        description:
          "cards.pCards.orderPhysicalCardOptions.international.description",
        key: ORDER_PHYSICAL_CARD_OPTIONS_KEYS.BULK_INTERNATIONAL_PERSONALIZED_ORDER_CARD,
        route: {
          paramName:
            SLIDERS_SEARCH_PARAMS.cards.physicalCards.create.physicalCard,
          paramValue:
            ORDER_PHYSICAL_CARDS_TYPE.INTERNATIONAL_PHYSICAL_CARD_ORDER.key,
        },
      },
  };

  const buttonOptions = useMemo(() => {
    const options = [];

    if (ctas?.length > 0) {
      ctas?.forEach((cta) => {
        if (ORDER_PHYSICAL_CARD_OPTIONS_KEYS_CONFIG?.[cta]) {
          options.push({
            ...ORDER_PHYSICAL_CARD_OPTIONS_KEYS_CONFIG?.[cta],
            route: {
              paramName: SLIDERS_SEARCH_PARAMS.cards.physicalCards.create,
              paramValue:
                ORDER_PHYSICAL_CARD_OPTIONS_KEYS_CONFIG?.[cta].route.paramValue,
            },
          });
        }
      });

      return options;
    }

    return [];
  }, [ctas]);

  const handleOptionChange = (key) => {
    if (ORDER_PHYSICAL_CARD_OPTIONS_KEYS_CONFIG[key].additionalOnClick) {
      ORDER_PHYSICAL_CARD_OPTIONS_KEYS_CONFIG[key].additionalOnClick();
    }

    searchParams.append(
      ORDER_PHYSICAL_CARD_OPTIONS_KEYS_CONFIG[key].route.paramName,
      ORDER_PHYSICAL_CARD_OPTIONS_KEYS_CONFIG[key].route.paramValue
    );
    setSearchParams(searchParams);
  };

  function handleOptionClickOfOrderCardButtonWithNonOptions() {
    const key = buttonOptions?.[0]?.key;
    handleOptionChange(key);
  }

  return buttonOptions?.length > 0 ? (
    buttonOptions?.length === 1 ? (
      <div>
        <Button
          label={`cards.pCards.orderButtonLabels.${
            showPhysicalCardOrderOptionsPopup ? "plural" : "singular"
          }`}
          preIcon="ShoppingBag"
          classes={`${classes} w-[calc(theme(spacing.16)+theme(spacing.5))] text-btn-lg font-semibold`}
          onClick={handleOptionClickOfOrderCardButtonWithNonOptions}
        />
      </div>
    ) : (
      <OptionsSectionPopup
        options={buttonOptions}
        classes={classes}
        showOptions={showPhysicalCardOrderOptionsPopup}
        handleOptionChange={handleOptionChange}
      />
    )
  ) : null;
}

OrderPhysicalCardButton.propTypes = {
  classes: PropTypes.string,
  insideTable: PropTypes.bool,
};

export default OrderPhysicalCardButton;
