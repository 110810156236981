import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  accountingEnabledSelector,
  accountingIntegrationSoftwareSelector,
  currentPaymentProviderSelector,
} from "@/store/selectors/client";
import { createBillFormLineItemsSelector } from "@/store/selectors/payments";
import { isFetchingPurchaseBillQuoteSelector } from "@/store/selectors/purchase-bills";

import Icon from "@/components/core/Icon";
import Input from "@/components/core/Input";

import BoltOnTheLeft from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/BoltOnTheLeft";
import TagsForLineItemListEntry from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/LineItemsList/TagsForLineItemListEntry";
import TaxesForLineItemListEntry from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/LineItemsList/TaxesForLineItemListEntry";
import {
  CREATE_BILL_FLOW_CONTEXT_KEYS,
  LINE_ITEM_KEY,
  LINE_ITEM_KEYS,
} from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";

import { ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP } from "@/constants/accounting";
import { PAYMENT_PROVIDERS } from "@/constants/provider";

export default function LineItemsList({
  values,
  errors,
  handleChange,
  setValues,
  taxAtLineItemLevel = false,
  beneficiaryCurrency,
  onAddLineItemHandler = () => {},
  onDeleteLineItemHandler = () => {},
  inPayrollContext,
  createBillFlowContext,
  ocrMode,
  billOCRSuccess,
  isHidePlusButton,
}) {
  const { t } = useTranslation();

  const accountingEnabled = useSelector(accountingEnabledSelector);
  const accountingIntegrationSoftware = useSelector(
    accountingIntegrationSoftwareSelector
  );
  const lineItemsForForm = useSelector(createBillFormLineItemsSelector);
  const currentPaymentProvider = useSelector(currentPaymentProviderSelector);
  const isFetchingQuote = useSelector(isFetchingPurchaseBillQuoteSelector);

  const isYSTRInCharge = values?.isYSTRInCharge;
  const isYSTREditEnabled = values?.isYSTREditEnabled;
  const currentLineItems = lineItemsForForm?.filter((data) => !data?._destroy);
  const onlyOneLineItem = currentLineItems?.length === 1;

  return (
    <div
      aria-label="line-item-entries"
      className={`relative bg-neutral-50 border-[1px] border-neutral-300 rounded-t-lg flex flex-col gap-[1px] ${
        lineItemsForForm.length === 0 ? "pt-6" : ""
      }`}
    >
      {lineItemsForForm
        .filter((item) => !item[LINE_ITEM_KEYS._DESTROY])
        .map((entry, index) => {
          const id = entry[LINE_ITEM_KEYS.FEID];

          return (
            <div
              aria-label="line-item-row"
              key={id}
              className={`px-6 py-6 grid grid-cols-2 gap-x-8 gap-y-6 first:border-none border-t-[1px] border-neutral-300 ${
                index === lineItemsForForm.length - 1 ? "pb-8" : ""
                // some bottom space for the Add button
              }`}
            >
              {/* top left */}
              <BoltOnTheLeft
                showBolt={
                  ocrMode &&
                  billOCRSuccess &&
                  createBillFlowContext.current[
                    CREATE_BILL_FLOW_CONTEXT_KEYS.OCR_DETECTED_LINE_ITEMS
                  ]?.[id]?.[LINE_ITEM_KEYS.AMOUNT]?.toString() ===
                    values[
                      `${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.AMOUNT}`
                    ]?.toString()
                }
                isArtificial={
                  values[
                    `${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.IS_ARTIFICIAL}`
                  ]
                }
              >
                <Input
                  aria-label="line-item-amount-input"
                  name={`${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.AMOUNT}`}
                  label={
                    [
                      PAYMENT_PROVIDERS.AIRWALLEX,
                      PAYMENT_PROVIDERS.WALLEX,
                    ].includes(currentPaymentProvider)
                      ? "billPay.bill.invoiceInbox.createBill.sections.lineItems.amount"
                      : "billPay.bill.invoiceInbox.createBill.sections.lineItems.amountExclusiveOfTax"
                  }
                  labelExtraClasses="font-semibold text-xs text-neutral-500"
                  classes=""
                  value={
                    values[`${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.AMOUNT}`]
                  }
                  error={
                    errors[`${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.AMOUNT}`]
                  }
                  onChange={(...args) => {
                    setValues((prev) => ({ ...prev, isYSTRInCharge: false }));
                    return handleChange(...args);
                  }}
                  placeholder="forms.enterAmount"
                  rightText={beneficiaryCurrency}
                  type="number"
                  {...(index === 0 && isYSTRInCharge
                    ? {
                        disabled: isYSTREditEnabled || isFetchingQuote,
                        isLoading: isFetchingQuote,
                        isRightTextLoading: isFetchingQuote,
                      }
                    : {
                        disabled: isFetchingQuote,
                      })}
                />
              </BoltOnTheLeft>
              {/* top right */}

              <div className="flex items-center justify-between gap-3">
                {accountingEnabled ? (
                  <Input
                    name={`${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.ACCOUNTING_DESCRIPTION}`}
                    label={`${
                      ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[
                        accountingIntegrationSoftware
                      ]
                    } ${t(
                      "billPay.bill.invoiceInbox.createBill.sections.lineItems.description"
                    )}`}
                    labelExtraClasses="font-semibold text-xs text-neutral-500"
                    value={
                      values[
                        `${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.ACCOUNTING_DESCRIPTION}`
                      ] ?? ""
                    }
                    error={
                      errors[
                        `${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.ACCOUNTING_DESCRIPTION}`
                      ]
                    }
                    onChange={handleChange}
                    placeholder={` ${t(
                      "billPay.bill.invoiceInbox.createBill.sections.lineItems.enter"
                    )} ${
                      ACCOUNTING_SOFTWARE_SLUG_TO_NAME_MAP[
                        accountingIntegrationSoftware
                      ]
                    } ${t(
                      "billPay.bill.invoiceInbox.createBill.sections.lineItems.description"
                    )}`}
                    outsideDivClasses="w-full"
                  />
                ) : (
                  <BoltOnTheLeft
                    showBolt={
                      ocrMode &&
                      billOCRSuccess &&
                      createBillFlowContext.current[
                        CREATE_BILL_FLOW_CONTEXT_KEYS.OCR_DETECTED_LINE_ITEMS
                      ]?.[id]?.[LINE_ITEM_KEYS.DESCRIPTION] ===
                        values[
                          `${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.DESCRIPTION}`
                        ]
                    }
                    isArtificial={
                      values[
                        `${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.IS_ARTIFICIAL}`
                      ]
                    }
                  >
                    <Input
                      name={`${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.DESCRIPTION}`}
                      label="billPay.bill.invoiceInbox.createBill.sections.lineItems.descriptionTitlecase"
                      labelExtraClasses="font-semibold text-xs text-neutral-500"
                      value={
                        values[
                          `${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.DESCRIPTION}`
                        ]
                      }
                      error={
                        errors[
                          `${LINE_ITEM_KEY}.${id}.${LINE_ITEM_KEYS.DESCRIPTION}`
                        ]
                      }
                      onChange={handleChange}
                      placeholder="billPay.bill.invoiceInbox.createBill.sections.lineItems.descriptionTitlecase"
                      outsideDivClasses="w-full"
                    />
                  </BoltOnTheLeft>
                )}

                {/* show at all times, acts as clear button if just one line item */}
                {isHidePlusButton ? null : (
                  <Icon
                    name="Delete"
                    className={`w-4 cursor-pointer  ${
                      onlyOneLineItem
                        ? "text-neutral-400 cursor-not-allowed"
                        : "text-neutral-500"
                    }`}
                    handleClick={() => {
                      if (!onlyOneLineItem) onDeleteLineItemHandler(id);
                      else console.warn("Cannot delete single last item");
                    }}
                  />
                )}
              </div>
              <TagsForLineItemListEntry
                values={values}
                errors={errors}
                handleChange={handleChange}
                setValues={setValues}
                id={id}
                inPayrollContext={inPayrollContext}
              />
              {/* TODO: custom field, copy UI from tax row */}
              {/* Tax at line item level, full row */}
              {taxAtLineItemLevel ? (
                <TaxesForLineItemListEntry
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  setValues={setValues}
                  beneficiaryCurrency={beneficiaryCurrency}
                  id={id}
                  inPayrollContext={inPayrollContext}
                  createBillFlowContext={createBillFlowContext}
                  ocrMode={ocrMode}
                  billOCRSuccess={billOCRSuccess}
                />
              ) : null}
            </div>
          );
        })}
      {/* Add line entry manually, button. Use <Button /> if possible */}

      {isHidePlusButton ? null : (
        <span
          onClick={onAddLineItemHandler}
          className="absolute grid -translate-x-1/2 translate-y-1/2 bg-white rounded-full shadow-md cursor-pointer -bottom-0 left-1/2 w-9 h-9 place-items-center"
        >
          <Icon name="Add" />
          {/* <Button /> */}
        </span>
      )}
    </div>
  );
}

LineItemsList.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
  taxAtLineItemLevel: PropTypes.bool,
  beneficiaryCurrency: PropTypes.string,
  onAddLineItemHandler: PropTypes.func,
  inPayrollContext: PropTypes.bool,
  createBillFlowContext: PropTypes.any,
  ocrMode: PropTypes.string,
  billOCRSuccess: PropTypes.bool,
  isHidePlusButton: PropTypes.bool,
};
