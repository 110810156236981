import PropTypes from "prop-types";
import React from "react";

function LivquikIframeComponent({ iFrameUrl, hide }) {
  return (
    <div className={`flex justify-between h-full ${hide ? "blur-sm" : ""}`}>
      <iframe
        src={iFrameUrl}
        title="liviquick card frame"
        className="w-full h-full"
        allow="clipboard-write *"
      />
    </div>
  );
}

export default LivquikIframeComponent;

LivquikIframeComponent.propTypes = {
  iFrameUrl: PropTypes.string,
  hide: PropTypes.bool,
};
