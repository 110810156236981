import PropTypes from "prop-types";

import Text from "@/components/core/Text";
import VirtualizedTable from "@/components/core/VirtualizedTable";
import { amountToCurrency, dateToTimeStampString } from "@/utils/common";
import { LEDGER_TABLE_HEADERS } from "@/utils/constants/ledger";

export default function LedgerTable({
  type,
  tableArgs,
  headers,
  headerIds,
  ledgers,
  isFetching,
  hasMore,
  handleRefChange,
}) {
  const getComponent = (id, _data, index) => {
    switch (id) {
      case LEDGER_TABLE_HEADERS.ledgerDate:
        return (
          <Text
            classes="text-sm font-semibold"
            translationKey={dateToTimeStampString(_data.value)}
          />
        );
      case LEDGER_TABLE_HEADERS.balance:
      case LEDGER_TABLE_HEADERS.amount:
        return (
          <Text
            classes="text-sm font-semibold"
            translationKey={amountToCurrency(_data.value, _data.currency)}
          />
        );
      case LEDGER_TABLE_HEADERS.description:
        return (
          <div className="flex flex-1 overflow-hidden">
            <Text
              classes="font-semibold text-sm inline-block"
              translationKey={_data.value}
              showTooltip
              truncate
            />
          </div>
        );
      case LEDGER_TABLE_HEADERS.ledgerType:
        return (
          <Text
            classes="text-sm font-semibold"
            truncate
            showTooltip
            translationKey={`accounting.ledger.ledgerType.${_data.value}`}
          />
        );
      default:
        return (
          <Text classes="text-sm font-semibold" translationKey={_data.value} />
        );
    }
  };

  return (
    <>
      <VirtualizedTable
        {...tableArgs}
        data={ledgers}
        isLoading={isFetching}
        headerConfig={headers?.filter((header) => headerIds?.[header?.id])}
        // rows are not clickable so disabling every row
        getEnableRowClick={() => false}
        getCellComponent={(rowData, headerId, header) =>
          getComponent(headerId, rowData?.getValue(), rowData?.row?.index)
        }
        handleRefChange={(ref, index) => {
          if (index === (ledgers?.length ?? 0) - 1 && hasMore) {
            handleRefChange(ref);
          }
        }}
        showCheckBoxRow={false}
      />
    </>
  );
}
LedgerTable.propTypes = {
  type: PropTypes.string,
  tableArgs: PropTypes.object,
  headers: PropTypes.array,
  headerIds: PropTypes.object,
  ledgers: PropTypes.array,
  isFetching: PropTypes.bool,
  hasMore: PropTypes.bool,
  handleRefChange: PropTypes.func,
};
