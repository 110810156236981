import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { batch } from "react-redux";
import { createSearchParams } from "react-router-dom";

import to from "await-to-js";

import Tracker from "@openreplay/tracker";

import { fetchCategories } from "@/store/reducers/categories";
import { fetchAccountWallets, fetchClient } from "@/store/reducers/client";
import { setIsFormSubmissionProgress } from "@/store/reducers/loadersError";
import { fetchTags } from "@/store/reducers/tags";
import {
  bootChatBox,
  bootIntercomAsVisitor,
  shutdownChatBox,
} from "@/utils/chatbox";
import {
  decryptData,
  getErrorToastMessage,
  getSuccessToastMessage,
  inVendorMailFlow,
  subdomain,
} from "@/utils/common";
import { getRedirectUrl, getUrlWithRedirectUri } from "@/utils/routes";
import { decryptMasterData, decryptUserPermissions } from "@/utils/user";
import vToast from "@/utils/vToast";

import { KYC_STEPS_STATUS } from "@/constants/onboarding";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { ROUTES } from "@/constants/routes";
import {
  DEVICES,
  LOGIN_ERROR_TYPES,
  PASSWORD_UPDATE_ERROR,
  SESSION_ERROR_MESSAGE,
  SESSION_ERROR_TYPES,
  TRUSTED_DEVICE_ID,
  USER_INBOX_TABS,
} from "@/constants/user";
import API from "@/api";

const initialState = {
  isLoading: false,
  isLoggedIn: false,
  isGoogleLoginLoading: false,
  currentUser: null,
  authData: null,
  mfaData: null,
  activeSessions: [],
  pendingActions: {
    reviewExpense: 0,
    pendingToVerify: 0,
    pendingToSync: 0,
  },
  quickActions: {},
  isMfa: false,
  lastModifiedPassword: "12 June 23 14:24",
  isFetchingPendingActions: false,
  isFetchingQuickActions: false,
  departmentId: null,
  selectedUser: {
    info: {},
    isFetchingSelectedUser: false,
    cardsList: {
      list: [],
      hasMore: true,
      limit: PAGINATION_PER_REQUEST_LIMIT,
      isFetching: false,
      page: 0,
      total: 0,
    },
  },
  selectedUserEditFormDetails: {},
  selectedUserDepartmentChangeConfirmation: false,
  selectedUserLocationChangeConfirmation: false,
  selectedUserRoleChangeConfirmation: false,
  allUser: null,
  isFetchingAllUser: false,
  isUserAccessChanged: {
    inProgress: false,
    changed: false,
  },
  inProgressPersonalDetails: undefined,
  accountLockedType: null,
  addressDetailsInprogress: undefined,
  identityDetailsInprogress: undefined,
  userBlockRequirements: null,
  userBlockRequirementsCount: null,
  userBlockDepartments: [],
  userBlockProjects: [],
  userDepartmentRequirements: null,
  userDepartmentRequirementsCount: null,
  isFetchingUserDepartmentRequirements: false,
  isFetchingUserBlockRequirements: false,
  userRolesList: {
    list: [],
    isFetching: false,
  },
  userAdminRoleList: {
    list: [],
    isFetching: false,
  },

  vkycConsentStatus: false,
  ckycConsentStatus: false,
  cKycUpdateStatus: undefined,
  physicalCard: null,
  isFetchingPhysicalCard: false,
  trustedDevices: null,
  loggedInDevices: null,
  isFetchingTrustedDevices: false,
  isFetchingLoggedInDevices: false,
  isTrustedDeviceOperationInProgress: false,
  notifications: {
    list: [],
    hasMore: true,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    isFetching: false,
    page: 0,
    total: 0,
    unreadCount: 0,
    isLoading: false,
    unreadIds: new Set(),
  },
  isFetchingConnectedAccounts: false,
  connectedAccounts: [],
  navigationConfig: null,
  actionsConfig: null,
  unReadNotificationsTabsCount: { notifications: 0, messages: 0 },
  userNotificationCurrentTab: USER_INBOX_TABS[0],
  bannerRetryCtaLink: "",
  isLoadingConnectEntity: false,
  userKycStatusInfo: {
    data: {},
    isFetching: false,
  },
  masterData: null,
  isFetchingMasterData: false,
};
// reducers
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsLogInInProgress(state, action) {
      state.isLogInInProgress = action.payload;
    },
    setIsLoggedIn(state, action) {
      state.isLoggedIn = action.payload;
    },
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
    setCurrentUserMfaMode(state, action) {
      state.currentUser.mfaMode = action.payload;
    },
    setLoggingUserCred(state, action) {
      state.currentUser = { ...state.currentUser, ...action.payload };
    },
    setAuthData(state, action) {
      state.authData = action.payload;
    },
    setActiveSessions(state, action) {
      state.activeSessions = action.payload;
    },
    setIsMfa(state, action) {
      state.isMfa = action.payload;
    },
    setAccountLockedType(state, action) {
      state.accountLockedType = action.payload;
    },
    // pending actions
    setUsersPendingActions(state, action) {
      state.pendingActions = action.payload;
    },
    setQuickActions(state, action) {
      state.quickActions = action.payload;
    },
    setUsersIsFetchingPendingActions(state, action) {
      state.isFetchingPendingActions = action.payload;
    },
    setIsFetchingQuickActions(state, action) {
      state.isFetchingQuickActions = action.payload;
    },
    setSelectedUser(state, action) {
      state.selectedUser.info = action.payload;
    },
    setIsFetchingSelectedUserInfo(state, action) {
      state.selectedUser.isFetchingSelectedUser = action.payload;
    },
    resetState: () => ({ ...initialState }),
    setIsGoogleLoading(state, action) {
      state.isGoogleLoginLoading = action.payload;
    },
    setUserAccess: (state, action) => {
      state.isUserAccessChanged.changed = action.payload.changed;
      state.isUserAccessChanged.inProgress = action.payload.inProgress;
    },
    setSelectedEditFormDetails(state, action) {
      state.selectedUserEditFormDetails = action.payload;
    },
    setSelectedUserDepartmentChangeConfirmation(state, action) {
      state.selectedUserDepartmentChangeConfirmation = action.payload;
    },
    setSelectedUserLocationChangeConfirmation(state, action) {
      state.selectedUserLocationChangeConfirmation = action.payload;
    },
    setSelectedUserRoleChangeConfirmation(state, action) {
      state.selectedUserRoleChangeConfirmation = action.payload;
    },
    resetUserAccess(state) {
      state.isUserAccessChanged = { inProgress: false, changed: false };
    },
    setPersonalDetailsInprogress(state, action) {
      state.inProgressPersonalDetails = action.payload;
    },
    setAddressDetailsInprogress(state, action) {
      state.addressDetailsInprogress = action.payload;
    },
    setIdentityDetailsInprogress(state, action) {
      state.identityDetailsInprogress = action.payload;
    },
    setUserRolesList(state, action) {
      state.userRolesList = {
        ...state.userRolesList,
        list: action.payload,
      };
    },

    setUserAdminRoleList(state, action) {
      state.userAdminRoleList = {
        ...state.userAdminRoleList,
        list: action.payload,
      };
    },
    setIsFetchingUserRolesList(state, action) {
      state.userRolesList.isFetching = action.payload;
    },

    setIsFetchingUserAdminsRolesList(state, action) {
      state.userAdminRoleList.isFetching = action.payload;
    },

    setUserRolesListInitialState(state) {
      state.userRolesList = {
        list: [],
        isFetching: false,
      };
    },
    setSelectedUserList(state, action) {
      state.selectedUser.cardsList.list = action.payload;
    },
    setIsFetchingSelectedUserList(state, action) {
      state.selectedUser.cardsList.isFetching = action.payload;
    },
    setSelectedUserCardsConfig(state, action) {
      const { page, total, limit } = action.payload;

      if (page) {
        state.selectedUser.cardsList.page = page;
      }

      if (limit) {
        state.selectedUser.cardsList.limit = limit;
      }

      if (total)
        state.selectedUser.cardsList.hasMore =
          state.selectedUser.cardsList.list?.length < total;

      if (total) {
        state.selectedUser.cardsList.total = total;
      }
    },
    resetSelectedUserCardsListAndPagination(state) {
      state.selectedUser.cardsList.list = [];
      state.selectedUser.cardsList.page = 1;
      state.selectedUser.cardsList.hasMore = true;
      state.selectedUser.cardsList.total = 0;
      state.selectedUser.cardsList.isFetching = false;
    },
    appendSelectedUserCardsList(state, action) {
      state.selectedUser.cardsList.list = [
        ...state.cards.list,
        ...action.payload,
      ];
    },
    setVkycConsent(state, action) {
      state.vkycConsentStatus = action.payload;
    },
    setCkycConsent(state, action) {
      state.ckycConsentStatus = action.payload;
    },
    setCKycUpdateStatus(state, action) {
      state.cKycUpdateStatus = action.payload;
    },
    setUserBlockRequirements(state, action) {
      state.userBlockRequirements = action.payload;
    },
    setIsFetchingUserBlockRequirements(state, action) {
      state.isFetchingUserBlockRequirements = action.payload;
    },
    setUserBlockRequirementsCount(state, action) {
      state.userBlockRequirementsCount = action.payload;
    },
    setUserBlockDepartments(state, action) {
      state.userBlockDepartments = action.payload;
    },
    setUserBlockProjects(state, action) {
      state.userBlockProjects = action.payload;
    },
    setUserDepartmentRequirements(state, action) {
      state.userDepartmentRequirements = action.payload;
    },
    setIsFetchingUserDepartmentRequirements(state, action) {
      state.isFetchingUserDepartmentRequirements = action.payload;
    },
    setUserDepartmentRequirementsCount(state, action) {
      state.userDepartmentRequirementsCount = action.payload;
    },

    setUserPermission(state, action) {
      state.currentUser = {
        ...state.currentUser,
        permissions: action.payload,
      };
    },
    setPhysicalUserCard(state, action) {
      state.physicalCard = action.payload;
    },
    setIsFetchingPhysicalUserCard(state, action) {
      state.isFetchingPhysicalCard = action.payload;
    },
    updateCurrentUserDetails(state, action) {
      state.currentUser = {
        ...state.currentUser,
        ...action.payload,
      };
    },
    setTrustedDevices(state, action) {
      state.trustedDevices = action.payload;
    },
    setIsFetchingTrustedDevices(state, action) {
      state.isFetchingTrustedDevices = action.payload;
    },
    setLoggedInDevices(state, action) {
      state.loggedInDevices = action.payload;
    },
    setIsFetchingLoggedInDevices(state, action) {
      state.isFetchingLoggedInDevices = action.payload;
    },
    setIsTrustedDeviceOperationInProgress(state, action) {
      state.isTrustedDeviceOperationInProgress = action.payload;
    },
    setNotifications(state, action) {
      state.notifications.list = action.payload;
    },
    resetNotifications(state) {
      state.notifications.hasMore = true;
      state.notifications = initialState.notifications;
    },
    addNotifications(state, action) {
      state.notifications.list = [
        ...state.notifications.list,
        ...action.payload,
      ];
    },
    setNotificationsPage(state, action) {
      state.notifications.page = action.payload;
    },
    setNotificationsLimit(state, action) {
      state.notifications.limit = action.payload;
    },
    setNotificationsTotal(state, action) {
      state.notifications.total = action.payload;
    },
    setNotificationsUnreadCount(state, action) {
      state.notifications.unreadCount = action.payload;
    },
    setNotificationsHasMore(state) {
      state.notifications.hasMore =
        state.notifications.list.length < state.notifications.total;
    },
    setIsFetchingNotifications(state, action) {
      state.notifications.isFetching = action.payload;
    },
    setIsLoadingNotifications(state, action) {
      state.notifications.isLoading = action.payload;
    },
    setNotificationsUnreadIds(state, action) {
      state.notifications.unreadIds = new Set(action.payload);
    },
    setConnectedAccounts(state, action) {
      state.connectedAccounts = action.payload;
    },
    setIsFetchingConnectedAccounts(state, action) {
      state.isFetchingConnectedAccounts = action.payload;
    },
    setMfaData(state, action) {
      state.mfaData = action.payload;
    },
    setNavigationConfig(state, action) {
      state.navigationConfig = action.payload;
    },
    setActionsConfig(state, action) {
      state.actionsConfig = action.payload;
    },
    setUnreadNotificationTabsCount(state, action) {
      state.unReadNotificationsTabsCount = action.payload;
    },
    setNotificationTabsCount(state, action) {
      state.unReadNotificationsTabsCount.notifications = action.payload;
    },
    setMessagesTabsCount(state, action) {
      state.unReadNotificationsTabsCount.messages = action.payload;
    },
    setUserNotificationCurrentTab(state, action) {
      state.userNotificationCurrentTab = action.payload;
    },
    setBannerRetryLink(state, action) {
      state.bannerRetryCtaLink = action.payload;
    },
    setIsLoadingConnectEntity(state, action) {
      state.isLoadingConnectEntity = action.payload;
    },
    setUserKycStatusInfo(state, action) {
      state.userKycStatusInfo.data = action.payload;
    },
    setIsFetchingKycStatusInfo(state, action) {
      state.userKycStatusInfo.isFetching = action.payload;
    },
    setMasterData(state, action) {
      state.masterData = action.payload;
    },
    setIsFetchingMasterData(state, action) {
      state.isFetchingMasterData = action.payload;
    },
  },
});

// helpers

/**
 * store access token and related keys, into localStorage
 */
export const registerToken = ({ headers, userId }) => {
  // expires: 30 min
  localStorage.setItem("access-token", headers["access-token"]);
  localStorage.setItem("token-type", headers["token-type"]);
  localStorage.setItem("client", headers.client);
  localStorage.setItem("expiry", headers.expiry);
  localStorage.setItem("uid", headers.uid);
  localStorage.setItem("id", userId);
};

/**
 * Clear residual cookies (we have abandoned cookies)
 *
 * idempotent
 */
export const clearResidualCookies = () => {
  const deleteCookie = (name) => {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  };
  deleteCookie("access-token");
  deleteCookie("token-type");
  deleteCookie("client");
  deleteCookie("expiry");
  deleteCookie("uid");
  deleteCookie("id");
};

/**
 * remove access token and related keys, from localStorage
 */
const deregisterToken = () => {
  localStorage.removeItem("access-token");
  localStorage.removeItem("token-type");
  localStorage.removeItem("client");
  localStorage.removeItem("expiry");
  localStorage.removeItem("uid");
  localStorage.removeItem("id");
};

const handleSignInSuccess = (
  responseHeaders,
  userData,
  dispatch,
  navigate,
  validate,
  navigations,
  actions
) => {
  registerToken({
    headers: responseHeaders,
    userId: userData.id,
  });
  bootChatBox(userData);
  userData.permissions = decryptUserPermissions(userData);
  batch(() => {
    dispatch(setCurrentUser(userData));
    dispatch(setIsLoggedIn(true));
    dispatch(setNavigationConfig(decryptData(navigations, userData)));
    dispatch(setActionsConfig(decryptData(actions, userData)));
    dispatch(fetchModulesData(userData));
  });

  if (!validate)
    window.localStorage.setItem(TRUSTED_DEVICE_ID, userData?.trustedDeviceId);

  if (userData.loggedIntoMultipleDevices) {
    if (navigate) {
      return navigate(
        getUrlWithRedirectUri(ROUTES.loginExceeded.base.absolutePath)
      );
    }
  }

  dispatch(fetchClient());
  dispatch(fetchCategories());
  dispatch(fetchAccountWallets());
  dispatch(fetchTags());
  const redirectUrl = getRedirectUrl();

  if (!validate && navigate && !inVendorMailFlow()) navigate(redirectUrl);
};
// async thunks
export const login = createAsyncThunk(
  "user/login",
  async (params, { dispatch }) => {
    const { navigate, ...rest } = params;
    dispatch(setIsLoading(true));

    const trustedDeviceId = window.localStorage.getItem(TRUSTED_DEVICE_ID);
    if (trustedDeviceId) {
      rest.trusted_device = trustedDeviceId;
    }
    const [error, response] = await to(API.Auth.signIn(rest));
    if (response) {
      // if multi factor auth then response {message:["otp"],mfa:true,via:""}

      if (response.data.connect_entity) {
        // redirect back to old subdomain
        if (response.data.redirect_url)
          window.location.href = response.data.redirect_url;
      }

      let windowLocationSearchParams = window.location.search;
      windowLocationSearchParams = windowLocationSearchParams.slice(
        1,
        windowLocationSearchParams.length
      );
      const searchParamFromUri = {};
      windowLocationSearchParams.split("&").forEach((item) => {
        const [key, value] = item.split("=");
        searchParamFromUri[key] = decodeURIComponent(value);
      });

      if (response.data.mfa) {
        dispatch(setAuthData(rest));
        vToast(getSuccessToastMessage(response));
        dispatch(setMfaData(response?.data));
        const searchParams = {
          via: response?.data?.via?.length > 0 ? response.data.via : "email",
          ...(response?.data?.one_time_token && {
            oneTimeToken: response.data.one_time_token,
          }),
          ...(response?.data?.action_type && {
            actionType: response.data.action_type,
          }),
          ...(response?.data?.from_subdomain && {
            fromSubdomain: response.data.from_subdomain,
          }),
          ...searchParamFromUri,
        };

        navigate({
          pathname: ROUTES.twoFactor.base.absolutePath,
          search: createSearchParams(searchParams).toString(),
        });
      } else {
        const { userDetails, navigations, actions } = response.data;
        // login api
        dispatch(setAuthData({}));
        handleSignInSuccess(
          response.headers,
          userDetails,
          dispatch,
          navigate,
          false,
          navigations,
          actions
        );
      }

      const tracker = new Tracker({
        projectKey: import.meta.env.VITE_TRACKER_PROJECT_API_KEY,
        __DISABLE_SECURE_MODE: import.meta.env.VITE_MODE === "development",
      });

      tracker.start();
      tracker.setUserID(response?.data?.userDetails?.email);
      tracker.setMetadata("account", subdomain());
      tracker.setMetadata(
        "country",
        window.localStorage.getItem("api_subdomain")?.split("-")[1] || "sg"
      );
    } else if (error) {
      const errorCode = error.response.data?.code;
      const isAccountLocked = [
        SESSION_ERROR_TYPES.ACCOUNT_LOCKED,
        SESSION_ERROR_TYPES.ACCOUNT_BLOCKED,
        SESSION_ERROR_TYPES.ADMIN_FREEZED,
        SESSION_ERROR_TYPES.ADMIN_UNFREEZED,
        SESSION_ERROR_TYPES.INACTIVITY_LOCKED,
        SESSION_ERROR_TYPES.OTP_RETRY_COUNT_EXHAUSTED,
        SESSION_ERROR_TYPES.COMPANY_ACCOUNT_LOCKED,
      ]?.includes(errorCode);
      if (isAccountLocked && SESSION_ERROR_TYPES[errorCode]) {
        dispatch(setAccountLockedType(errorCode));
        navigate(getUrlWithRedirectUri(ROUTES.accountLocked.absolutePath));
      } else if (!isAccountLocked && SESSION_ERROR_TYPES[errorCode]) {
        vToast(
          getErrorToastMessage(
            error,
            SESSION_ERROR_MESSAGE[SESSION_ERROR_TYPES[errorCode]]
          )
        );
      } else vToast(getErrorToastMessage(error, "errorMessage.loginFailed"));
    }

    dispatch(setIsLoading(false));
  }
);

// connect entity
export const connectEntity = createAsyncThunk(
  "user/connectEntity",
  async (params, { dispatch }) => {
    dispatch(setIsLoadingConnectEntity(true));
    const [err, response] = await to(API.User.connectEntity(params));

    if (response?.data) {
      dispatch(setIsLoadingConnectEntity(false));
      window.open(response.data.redirect_url);
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsLoadingConnectEntity(false));
  }
);

// switch entity
export const switchEntity = createAsyncThunk(
  "user/switchEntity",
  async (params, { dispatch }) => {
    const [err, response] = await to(API.User.switchEntity(params));

    if (response?.data) {
      dispatch(
        logout({
          onSuccess: () => {
            dispatch(setIsLoading(false));
            window.location.replace(response.data.redirect_url);
          },
        })
      );
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);

// fetch entities/accounts
export const getConnectedEntities = createAsyncThunk(
  "user/getConnectedEntities",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingConnectedAccounts(true));
    const [err, response] = await to(API.User.getConnectedEntities(params));

    if (response?.data) dispatch(setConnectedAccounts(response.data));
    else vToast(getErrorToastMessage(err));
    dispatch(setIsFetchingConnectedAccounts(false));
  }
);

// switch entity
export const validateUserSwitch = createAsyncThunk(
  "user/validateUserSwitch",
  async (params, { dispatch }) => {
    const { navigate, ...rest } = params;
    const [err, response] = await to(API.User.validateUserSwitch(rest));

    if (response?.data) {
      const { data } = response;
      const headers = {
        "access-token": data["access-token"],
        "token-type": data["token-type"],
        client: data.client,
        expiry: data.expiry,
        uid: data.uid,
      };
      registerToken({ headers, userId: data["user-id"] });
      dispatch(validateToken({ navigate, validate: false }));
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);

// /oauth-login
export const googleLogin = createAsyncThunk(
  "login/googleAuth",
  async (params, { dispatch }) => {
    const { navigate, ...rest } = params;
    dispatch(setIsGoogleLoading(true));
    const [error, response] = await to(API.Auth.googleSignIn(rest));
    if (response?.data) {
      // because need to navigate to google login page
      window.location.href = response.data.url;
    } else {
      vToast(getErrorToastMessage(error));
    }

    dispatch(setIsGoogleLoading(false));
  }
);

export const googleCodeVerification = createAsyncThunk(
  "login/googleAuth",
  async (params, { dispatch }) => {
    const { navigate, ...rest } = params;
    dispatch(setIsLoading(true));
    const [error, response] = await to(API.Auth.googleCodeVerification(rest));

    if (response) {
      if (response.data.connect_entity) {
        // redirect back to old subdomain
        if (response.data.redirect_url)
          window.location.href = response.data.redirect_url;
        return;
      }

      if (response?.data?.redirect_url) {
        window.location.href = response.data.redirect_url;
        return;
      }
      handleSignInSuccess(
        response.headers,
        response.data?.userDetails,
        dispatch,
        navigate,
        false,
        response.data?.navigations,
        response.data?.actions
      );
    } else {
      const errorCode = error.response.data?.code;
      const isAccountLocked = [
        SESSION_ERROR_TYPES.ACCOUNT_LOCKED,
        SESSION_ERROR_TYPES.ACCOUNT_BLOCKED,
        SESSION_ERROR_TYPES.ADMIN_FREEZED,
        SESSION_ERROR_TYPES.ADMIN_UNFREEZED,
        SESSION_ERROR_TYPES.INACTIVITY_LOCKED,
        SESSION_ERROR_TYPES.OTP_RETRY_COUNT_EXHAUSTED,
        SESSION_ERROR_TYPES.COMPANY_ACCOUNT_LOCKED,
      ]?.includes(errorCode);
      if (isAccountLocked && SESSION_ERROR_TYPES[errorCode]) {
        dispatch(setAccountLockedType(errorCode));
        // updating current user email from error
        dispatch(setCurrentUser({ email: error.response.data?.email }));
        navigate(getUrlWithRedirectUri(ROUTES.accountLocked.absolutePath));
        return;
      }
      // taking user to login page then triggering toast so error toast will not go away
      // otherwise what will happen that toast will be shown on oAuth page and then we'll navigate to login page
      navigate(getUrlWithRedirectUri(ROUTES.login.base.absolutePath));
      if (!isAccountLocked && SESSION_ERROR_TYPES[errorCode]) {
        vToast(
          getErrorToastMessage(
            error,
            SESSION_ERROR_MESSAGE[SESSION_ERROR_TYPES[errorCode]]
          )
        );
      } else vToast(getErrorToastMessage(error, "errorMessage.loginFailed"));
    }
    dispatch(setIsLoading(false));
  }
);

export const logout = createAsyncThunk(
  "user/logout",
  async (param, { dispatch }) => {
    const { onSuccess, ...rest } = param ?? {};
    const { navigate } = rest;
    dispatch(setIsLoading(true));
    await to(API.Auth.logout());
    deregisterToken();
    shutdownChatBox();
    bootChatBox();
    if (navigate)
      navigate(getUrlWithRedirectUri(ROUTES.login.base.absolutePath));
    dispatch(setIsLoading(false));

    if (onSuccess) onSuccess();
  }
);

export const refreshUserData = createAsyncThunk(
  "user/refreshUserData",
  async (params, { dispatch }) => {
    dispatch(setIsLoading(true));
    if (localStorage.getItem("access-token")) {
      const [error, response] = await to(API.Auth.validateToken());

      const { navigate = () => {}, onSuccess = () => {} } = params;

      if (error) {
        dispatch(logout({ navigate }));
        return;
      }

      if (response?.data?.success && response?.data?.data) {
        const userData = response.data.data?.userDetails;
        dispatch(setCurrentUser(userData));
        dispatch(
          setUserPermission(decryptData(userData?.permissions, userData))
        );
        dispatch(
          setNavigationConfig(
            decryptData(response.data.data?.navigations, userData)
          )
        );
        dispatch(
          setActionsConfig(decryptData(response.data.data?.actions, userData))
        );

        onSuccess();
      }
    }

    dispatch(setIsLoading(false));
  }
);

export const validateToken = createAsyncThunk(
  "user/validateToken",
  async ({ navigate = () => {}, validate = true }, { dispatch }) => {
    dispatch(setIsLoading(true));

    if (localStorage.getItem("access-token")) {
      const [error, response] = await to(API.Auth.validateToken());
      if (error) {
        dispatch(logout({ navigate }));
        return;
      }

      if (response?.data?.success && response?.data?.data) {
        handleSignInSuccess(
          response.headers,
          response.data.data?.userDetails,
          dispatch,
          navigate,
          validate,
          response.data.data?.navigations,
          response.data.data?.actions
        );
      }
    }

    dispatch(setIsLoading(false));
  }
);

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (params, { dispatch }) => {
    const { navigate, ...rest } = params;
    dispatch(setIsLoading(true));
    const [error, response] = await to(
      API.Auth.sendResetPasswordLink(rest.data)
    );
    if (!error && response) {
      vToast(
        getSuccessToastMessage(response, {
          title: "successfulMessage.resetLinkSuccesfullyHeader",
          message: "successfulMessage.successfulEmailResent",
        })
      );
      navigate(ROUTES.forgotPassword.verifyInbox.absolutePath);
    } else vToast(getErrorToastMessage(error));
    dispatch(setIsLoading(false));
  }
);

export const resetPasswordVerification = createAsyncThunk(
  "user/resetPasswordVerification",
  async (params, { dispatch }) => {
    dispatch(setIsLoading(true));

    const [error, response] = await to(
      API.Auth.resetPasswordVerification(params)
    );
    if (!error && response) {
      // dispatch(setAuthData(JSON.stringify(response)));
    } else if (error) vToast(getErrorToastMessage(error));
    dispatch(setIsLoading(false));
  }
);

export const checkInvitation = createAsyncThunk(
  "user/acceptInvitation",
  async (params, { dispatch }) => {
    dispatch(setIsLoading(true));
    const { navigate, ...rest } = params;
    const [error, response] = await to(API.Auth.checkInvitation(rest?.params));
    if (error) {
      // dont show success toast
      vToast(getErrorToastMessage(error, "errorMessage.invalidInvitation"));
      dispatch(setIsLoading(false));
      return navigate(ROUTES.login.base.absolutePath);
    }

    dispatch(setIsLoading(false));
  }
);

export const invite = createAsyncThunk(
  "user/acceptInvitation",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    dispatch(setIsLoading(true));
    const { onSuccess = () => {}, ...rest } = params;
    const [error, response] = await to(API.Auth.invite(rest));
    if (error) vToast(getErrorToastMessage(error));
    else {
      vToast(getSuccessToastMessage(response));
      onSuccess();
    }
    dispatch(setIsLoading(false));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const createPassword = createAsyncThunk(
  "user/createPassword",
  async (params, { dispatch }) => {
    const { navigate, ...rest } = params;
    dispatch(setIsLoading(true));
    const [error, response] = await to(API.Auth.createPassword(rest));
    if (response) {
      vToast(
        getSuccessToastMessage(response, {
          title: "successfulMessage.passwordSetSuccessfulTitle",
          message: "successfulMessage.passwordSetSuccessful",
        })
      );
      navigate(ROUTES.login.base.absolutePath);
    } else if (error) vToast(getErrorToastMessage(error));
    dispatch(setIsLoading(false));
  }
);

export const getActiveSessions = createAsyncThunk(
  "user/getActiveSessions",
  async (params, { dispatch }) => {
    dispatch(setIsLoading(true));
    const [error, response] = await to(API.User.activeSessions());
    if (response) {
      const filteredResponse = response?.data?.filter(
        (item) =>
          item?.clientId !== response?.headers?.client &&
          item?.device === DEVICES.PC
      );

      dispatch(setActiveSessions(filteredResponse));
      if (filteredResponse?.length === 0) {
        if (!inVendorMailFlow()) window.location.href = getRedirectUrl();
      }
    } else {
      const doNotHaveValidToken = !error?.response?.data?.valid_token;
      if (doNotHaveValidToken) window.location.pathname = "/";
    }
    dispatch(setIsLoading(false));
  }
);

export const removeSession = createAsyncThunk(
  "user/removeSession",
  async (params, { dispatch }) => {
    const [error, response] = await to(API.User.removeSession(params));
    if (response) {
      dispatch(getActiveSessions({}));
      vToast(getSuccessToastMessage(response));
    }
    if (error) {
      vToast(getErrorToastMessage(error));
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (params, { dispatch }) => {
    dispatch(setIsLoading(true));
    const { navigate, ...rest } = params;
    const [error, response] = await to(API.Auth.resetPassword(rest.params));

    if (response) {
      if (response.data.mfa) {
        dispatch(setIsMfa(true));
        dispatch(setMfaData(response?.data));
        vToast(getSuccessToastMessage(response));
      } else {
        dispatch(setIsMfa(false));
        vToast(
          getSuccessToastMessage(response, {
            title: "successfulMessage.passwordResetSuccessfulHeader",
            message: "successfulMessage.passwordResetSuccessful",
          })
        );
        if (navigate) navigate(ROUTES.login.base.absolutePath);
      }
    } else if (LOGIN_ERROR_TYPES[error.response.data.code]) {
      vToast(
        getErrorToastMessage(
          error,
          PASSWORD_UPDATE_ERROR[LOGIN_ERROR_TYPES[error.response.data.code]]
        )
      );
    } else vToast(getErrorToastMessage(error));

    dispatch(setIsLoading(false));
  }
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (params, { dispatch }) => {
    dispatch(setIsLoading(true));

    const { onSuccess, ...rest } = params;
    const [error, response] = await to(API.Auth.resetPassword(rest.params));

    if (response) {
      vToast(
        getSuccessToastMessage(response, {
          title: "successfulMessage.passwordResetSuccessfulHeader",
          message: "successfulMessage.passwordResetSuccessful",
        })
      );
      if (onSuccess) onSuccess();
    } else vToast(getErrorToastMessage(error));
    dispatch(setIsLoading(false));
    bootIntercomAsVisitor();
  }
);

export const resendOtp = createAsyncThunk(
  "user/resendOtp",
  async (params, { dispatch }) => {
    const [err, response] = await to(API.User.resendOtp(params?.id, {}));
  }
);

export const fetchCurrentUserDetails = createAsyncThunk(
  "user/fetchCurrentUserDetails",
  async (payload, { dispatch }) => {
    const [err, response] = await to(API.User.get(payload));
    // Different endpoint from signIn
    if (response) {
      dispatch(setCurrentUser(response));
    }
  }
);

export const fetchAndSelectUser = createAsyncThunk(
  "user/fetchAndSelectUser",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingSelectedUserInfo(true));
    const { onSuccess = () => {} } = params;
    const [err, response] = await to(API.User.get(params));
    if (!err && response?.data) {
      dispatch(setSelectedUser(response.data));
      onSuccess(response.data);
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFetchingSelectedUserInfo(false));
  }
);

export const fetchUsersPendingActions = createAsyncThunk(
  "user/fetchUsersPendingActions",
  async (params, { dispatch }) => {
    dispatch(setUsersIsFetchingPendingActions(true));
    const [err, response] = await to(API.Dashboard.getPendingActions());

    if (response?.data) {
      dispatch(setUsersPendingActions(response?.data));
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setUsersIsFetchingPendingActions(false));
  }
);

export const fetchQuickActions = createAsyncThunk(
  "user/fetchQuickActions",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingQuickActions(true));
    const [err, response] = await to(API.Dashboard.getQuickActions());

    if (response?.data) {
      dispatch(setQuickActions(response?.data));
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFetchingQuickActions(false));
  }
);

export const changeUserAccess = createAsyncThunk(
  "user/changeUserAccess",
  async (payload, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));

    dispatch(setUserAccess({ changed: false, inProgress: true }));
    const { onSuccess = () => {}, ...rest } = payload;
    const [err, response] = await to(API.User.changeUserAccess(rest));
    if (response) {
      dispatch(setUserAccess({ changed: true, inProgress: false }));
      onSuccess();
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const updateUserDetails = createAsyncThunk(
  "user/updateUserDetails",
  async (params, { dispatch }) => {
    const {
      payload,
      id,
      onSuccess,
      onFinal = () => {},
      skipToast = false,
    } = params;
    dispatch(setPersonalDetailsInprogress(true));

    const [error, response] = await to(API.User.update(payload, id));
    if (response?.data) {
      if (!skipToast) vToast(getSuccessToastMessage(response));
      if (onSuccess) onSuccess(response?.data?.data);
    } else if (!skipToast) {
      vToast(getErrorToastMessage(error, "errorMessage.updateFailed"));
    }

    dispatch(setPersonalDetailsInprogress(false));
    if (onFinal) {
      onFinal();
    }
  }
);

export const updateUserAvatar = createAsyncThunk(
  "user/updateUserAvatar",
  async (params, { dispatch }) => {
    const { payload, id, onSuccess } = params;
    dispatch(setPersonalDetailsInprogress(true));
    const [error, response] = await to(API.User.updateAvatar(payload, id));
    if (response?.data) {
      vToast({
        title:
          "company.people.peopleMyProfileSlider.avatarUpdatetoastMessages.userAvatarUpdateSuccessful",
        variant: "success",
      });
      if (onSuccess) onSuccess();
    } else {
      vToast(
        error,
        "company.people.peopleMyProfileSlider.avatarUpdatetoastMessages.userAvatarUpdateFailed"
      );
    }
    dispatch(setPersonalDetailsInprogress(false));
  }
);

export const updateAddressDetails = createAsyncThunk(
  "user/updateAddressDetails",
  async (params, { dispatch }) => {
    const { onSuccess, ...rest } = params;
    dispatch(setAddressDetailsInprogress(true));
    const [error, response] = await to(API.User.updateAddressDetails(rest));
    if (response?.data) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) onSuccess();
    } else if (error) {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setAddressDetailsInprogress(false));
  }
);

export const updateIdentityDetails = createAsyncThunk(
  "user/updateIdentityDetails",
  async (params, { dispatch }) => {
    const { onSuccess = () => {}, payload } = params;

    dispatch(setIdentityDetailsInprogress(true));
    const [error, response] = await to(API.User.updateIdentityDetails(payload));
    if (!error && response) {
      vToast({
        title: "Identity details successfully updated",
        variant: "success",
      });
      if (onSuccess) {
        onSuccess();
      }
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIdentityDetailsInprogress(false));
  }
);

export const fetchUserRolesList = createAsyncThunk(
  "user/fetchUserRolesList",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingUserRolesList(true));
    const [err, response] = await to(API.User.all(params));
    if (!err && response) {
      dispatch(setUserRolesList(response.data));
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsFetchingUserRolesList(false));
  }
);

export const fetchAdminRolesList = createAsyncThunk(
  "user/fetchAdminRolesList",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingUserAdminsRolesList(true));
    const [err, response] = await to(API.User.all(params));
    if (!err && response) {
      dispatch(setUserAdminRoleList(response.data));
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsFetchingUserAdminsRolesList(false));
  }
);

export const updateUserRolesList = createAsyncThunk(
  "user/updateUserRolesList",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const {
      onSuccess = () => {},
      payload,
      onError = () => {},
      toastData,
      enablePayrollApiHandler = () => {},
    } = params;

    dispatch(setIsLoading(true));
    const [error, response] = await to(API.User.updateUserRoles({ payload }));

    if (response) {
      onSuccess({ toastData, permissions: response?.data });
      dispatch(setUserPermission(response?.data));
      if (enablePayrollApiHandler) enablePayrollApiHandler();
    }

    if (error) {
      vToast(getErrorToastMessage(error));
      if (onError) {
        onError();
      }
    }

    dispatch(setIsLoading(false));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const getCardsListForSelectedUser = createAsyncThunk(
  "user/cardsList",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingSelectedUserList(true));
    const [error, response] = await to(API.Cards.all(params));
    if (response) {
      if (response?.data?.page > 1) {
        dispatch(appendSelectedUserCardsList(response?.data?.list));
      } else {
        dispatch(setSelectedUserList(response?.data?.list));
      }
      dispatch(
        setSelectedUserCardsConfig({
          page: response?.data?.page,
          limit: response?.data?.limit,
          total: response?.data?.total,
        })
      );
      dispatch(setIsFetchingSelectedUserList(false));
    }
  }
);

export const ckycConsentChange = createAsyncThunk(
  "user/ckycConsentChange",
  async ({ onSuccess }, { dispatch }) => {
    dispatch(setCkycConsent(false));

    const [error, response] = await to(API.User.updateKycConsent("ckyc"));

    if (response) {
      if (onSuccess) {
        onSuccess(response?.data);
      }

      dispatch(setCkycConsent(true));
    } else if (error) {
      vToast(getErrorToastMessage());
    }
  }
);

export const vkycConsentChange = createAsyncThunk(
  "user/vkycConsentChange",
  async ({ onSuccess }, { dispatch }) => {
    dispatch(setVkycConsent(false));

    const [error, response] = await to(API.User.updateKycConsent("vkyc"));

    if (response) {
      dispatch(setVkycConsent(true));

      if (onSuccess) {
        onSuccess(response?.data);
      }
    } else if (error) {
      vToast(getErrorToastMessage());
    }
  }
);

export const kycStatusUpdate = createAsyncThunk(
  "user/vkycConsentChange",
  async (params, { dispatch }) => {
    const { onSuccess = () => {} } = params;
    dispatch(setCKycUpdateStatus(false));

    const [error, response] = await to(API.User.updateCKycStatus());

    if (response) {
      if (onSuccess) {
        onSuccess();
      }
      dispatch(setCKycUpdateStatus(true));
    }
  }
);

export const fetchUserBlockRequirementsCount = createAsyncThunk(
  "user/fetchUserBlockRequirementsCount",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingUserBlockRequirements(true));
    const [error, response] = await to(
      API.User.blockUserRequirementsCount(params)
    );
    if (error) {
      vToast(getErrorToastMessage(error));
    } else {
      dispatch(setUserBlockRequirementsCount(response?.data));
    }
    dispatch(setIsFetchingUserBlockRequirements(false));
  }
);

export const fetchDepartmentRequirementsCount = createAsyncThunk(
  "user/fetchDepartmentRequirementsCount",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingUserDepartmentRequirements(true));
    const [error, response] = await to(
      API.User.departmentRequirementsCount(params)
    );
    if (error) {
      vToast(getErrorToastMessage(error));
    } else {
      dispatch(setUserDepartmentRequirementsCount(response?.data));
    }
    dispatch(setIsFetchingUserDepartmentRequirements(false));
  }
);

export const fetchUserBlockPolicyRequirements = createAsyncThunk(
  "user/fetchUserBlockPolicyRequirements",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingUserBlockRequirements(true));
    const [error, response] = await to(
      API.User.blockUserPolicyRequirements(params)
    );
    if (error) {
      vToast(getErrorToastMessage(error));
    } else {
      dispatch(setUserBlockRequirements(response?.data));
    }
    dispatch(setIsFetchingUserBlockRequirements(false));
  }
);

export const fetchUserBlockDepartments = createAsyncThunk(
  "user/fetchUserBlockDepartments",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingUserBlockRequirements(true));
    const [error, response] = await to(API.Company.Project.all(params));
    if (error) {
      vToast(getErrorToastMessage(error));
    } else {
      dispatch(setUserBlockDepartments(response?.data));
    }
    dispatch(setIsFetchingUserBlockRequirements(false));
  }
);

export const fetchUserBlockProjects = createAsyncThunk(
  "user/fetchUserBlockProjects",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingUserBlockRequirements(true));
    const [error, response] = await to(API.Company.Project.all(params));
    if (error) {
      vToast(getErrorToastMessage(error));
    } else {
      dispatch(setUserBlockProjects(response?.data));
    }
    dispatch(setIsFetchingUserBlockRequirements(false));
  }
);

export const fetchUserPhysicalCard = createAsyncThunk(
  "user/getUserPhysicalCard",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingPhysicalUserCard(true));
    const { onSuccess = () => {}, id, ...rest } = params;

    const [error, response] = await to(API.User.myPhysicalCard(id, rest));

    if (response?.data) {
      dispatch(setPhysicalUserCard(response?.data));
      onSuccess();
    }

    if (error) {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsFetchingPhysicalUserCard(false));
  }
);

export const fetchTrustedDevices = createAsyncThunk(
  "user/fetchTrustedDevices",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingTrustedDevices(true));
    const [error, response] = await to(API.Security.getDevicesYouTrust());
    if (response?.data) {
      dispatch(setTrustedDevices(response?.data));
    }
    dispatch(setIsFetchingTrustedDevices(false));
  }
);
export const revokeAllTrustedDevices = createAsyncThunk(
  "user/revokeAllTrustedDevices",
  async (params, { dispatch }) => {
    const { onSuccess = () => {} } = params;

    dispatch(setIsFetchingTrustedDevices(true));
    const [error, response] = await to(API.Security.revokeAllTrustedDevices());
    if (response?.data) {
      dispatch(setTrustedDevices([]));
      onSuccess();
    }
    dispatch(setIsFetchingTrustedDevices(false));
  }
);

export const removeTrustedDevice = createAsyncThunk(
  "user/removeTrustedDevice",
  async (params, { dispatch, getState }) => {
    const { onSuccess = () => {}, id } = params;

    dispatch(setIsTrustedDeviceOperationInProgress(true));
    const [error, response] = await to(API.Security.removeTrustedDevice(id));
    if (response?.data) {
      const state = getState();
      dispatch(
        setTrustedDevices(
          state.user.trustedDevices.filter((device) => device.id !== id)
        )
      );
      vToast(getSuccessToastMessage(response));
      if (onSuccess) onSuccess();
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsTrustedDeviceOperationInProgress(false));
  }
);
export const fetchLoggedInDevices = createAsyncThunk(
  "user/fetchLoggedInDevices",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingLoggedInDevices(true));
    const [error, response] = await to(API.User.activeSessions());
    if (response?.data) {
      dispatch(setLoggedInDevices(response?.data));
    }
    dispatch(setIsFetchingLoggedInDevices(false));
  }
);

export const revokeAllLoggedInDevices = createAsyncThunk(
  "user/revokeAllLoggedInDevices",
  async (params, { dispatch }) => {
    const { onSuccess = () => {}, ...rest } = params;

    const [error, response] = await to(API.User.revokeAllLoggedInDevices());
    if (response) {
      dispatch(getActiveSessions({}));
      vToast(getSuccessToastMessage(response));
      if (onSuccess) {
        onSuccess();
      }
    }
    if (error) {
      vToast(getErrorToastMessage(error));
    }
  }
);

export const activateMFA = createAsyncThunk(
  "user/activateMFA",
  async (params, { dispatch }) => {
    const { payload, onSuccess } = params;
    const [error, response] = await to(API.User.activateMfa(payload));
    if (response?.data) {
      dispatch(setCurrentUserMfaMode(payload.mfa_mode));
      vToast(getSuccessToastMessage(response));
      if (onSuccess) onSuccess();
    } else {
      vToast(getErrorToastMessage(error));
    }
  }
);

export const fetchUserNotifications = createAsyncThunk(
  "user/fetchUserNotifications",
  async (params, { dispatch }) => {
    const { onSuccess, ...rest } = params;
    dispatch(setIsFetchingNotifications(true));
    const [error, response] = await to(API.Notifications.all(rest));

    if (response?.data) {
      if (response.data.page === 1) {
        dispatch(setNotifications(response.data.list));
      } else dispatch(addNotifications(response.data.list));
      dispatch(setNotificationsUnreadCount(response.data.unreadCount));
      dispatch(setNotificationsUnreadIds(new Set()));
      dispatch(setNotificationsPage(response.data.page));
      dispatch(setNotificationsTotal(response.data.total));
      dispatch(setNotificationsLimit(response.data.limit));
      dispatch(setNotificationsHasMore());
      if (onSuccess) onSuccess(response?.data?.list);
    } else vToast(getErrorToastMessage(error));
    dispatch(setIsFetchingNotifications(false));
  }
);

export const readAllUserNotifications = createAsyncThunk(
  "user/readAllUserNotifications",
  async (_params, { dispatch }) => {
    const { params, onSuccess = () => {} } = _params;
    dispatch(setIsLoadingNotifications(true));
    const [error, response] = await to(API.Notifications.readAll(params));
    if (response) {
      if (params?.notification_ids)
        dispatch(setNotificationsUnreadIds(params.notification_ids));
      if (params.all) vToast(getSuccessToastMessage(response));
      onSuccess();
    }
    dispatch(setIsLoadingNotifications(false));
  }
);

export const getUnreadNotificationCounts = createAsyncThunk(
  "user/getUnreadNotificationCounts",
  async (_params, { dispatch }) => {
    const [error, response] = await to(
      API.Notifications.fetchUnreadNotifications()
    );

    if (response?.data) {
      dispatch(setUnreadNotificationTabsCount(response?.data));
    }
  }
);

export const fetchBannerRetryCtaRedirectionLink = createAsyncThunk(
  "user/fetchBannerRetryCtaRedirectionLink",
  async (_params, { dispatch }) => {
    const [error, response] = await to(API.User.getRetryLinkForBannerCta());

    if (response?.data) {
      dispatch(setBannerRetryLink(response?.data));
    }
  }
);

export const fetchUserKycStatusInfo = createAsyncThunk(
  "user/fetchUserKycStatusInfo",
  async (_params, { dispatch }) => {
    dispatch(setIsFetchingKycStatusInfo(true));
    const [error, response] = await to(API.User.getUserKycStatusInfo());
    const kycState = response?.data?.kycState;
    const showKycPendingStateToaster =
      kycState && kycState === KYC_STEPS_STATUS.PENDING;

    if (response?.data) {
      dispatch(setUserKycStatusInfo(response?.data));
    }

    if (showKycPendingStateToaster || error) {
      vToast(
        getErrorToastMessage(
          showKycPendingStateToaster
            ? {
                message:
                  "onboarding.onboardingSteps.pineLabs.kycPendingStatusToastText",
              }
            : error
        )
      );
    }

    dispatch(setIsFetchingKycStatusInfo(false));
  }
);
export const fetchModulesData = createAsyncThunk(
  "user/fetchModulesData",
  async (userData, { dispatch }) => {
    dispatch(setIsFetchingMasterData(true));

    const [err, response] = await to(API.Modules.getModules());
    if (response?.data) {
      const masterData = decryptMasterData(response?.data, userData ?? null);
      dispatch(setMasterData(masterData));
    }

    dispatch(setIsFetchingMasterData(false));
  }
);
// actions
export const {
  setIsLoading,
  setIsLoggedIn,
  setCurrentUser,
  setIsLogInInProgress,
  setLoggingUserCred,
  setAuthData,
  setUsersPendingActions,
  setQuickActions,
  setUsersIsFetchingPendingActions,
  setIsFetchingQuickActions,
  setIsGoogleLoading,
  setSelectedUser,
  setIsFetchingSelectedUserInfo,
  setUserAccess,
  setSelectedEditFormDetails,
  setSelectedUserDepartmentChangeConfirmation,
  setSelectedUserLocationChangeConfirmation,
  setSelectedUserRoleChangeConfirmation,
  resetUserAccess,
  setPersonalDetailsInprogress,
  setActiveSessions,
  setAccountLockedType,
  setIsMfa,
  setAddressDetailsInprogress,
  setIdentityDetailsInprogress,
  setUserRolesList,
  setUserAdminRoleList,
  setIsFetchingUserRolesList,
  setIsFetchingUserAdminsRolesList,
  setUserRolesListInitialState,
  setSelectedUserList,
  setIsFetchingSelectedUserList,
  resetSelectedUserCardsListAndPagination,
  appendSelectedUserCardsList,
  setSelectedUserCardsConfig,
  setVkycConsent,
  setCkycConsent,
  setCKycUpdateStatus,
  setUserBlockRequirements,
  setIsFetchingUserBlockRequirements,
  setUserBlockRequirementsCount,
  setUserBlockDepartments,
  setUserBlockProjects,
  setUserDepartmentRequirements,
  setIsFetchingUserDepartmentRequirements,
  setUserDepartmentRequirementsCount,

  setUserPermission,
  setPhysicalUserCard,
  setIsFetchingPhysicalUserCard,
  updateCurrentUserDetails,
  setTrustedDevices,
  setLoggedInDevices,
  setIsFetchingLoggedInDevices,
  setIsFetchingTrustedDevices,
  setIsTrustedDeviceOperationInProgress,
  setCurrentUserMfaMode,
  // user notifications
  setNotifications,
  addNotifications,
  resetNotifications,
  setNotificationsPage,
  setNotificationsTotal,
  setNotificationsUnreadCount,
  setNotificationsHasMore,
  setIsFetchingNotifications,
  setIsLoadingNotifications,
  setNotificationsLimit,
  setNotificationsUnreadIds,
  setConnectedAccounts,
  setIsFetchingConnectedAccounts,
  setMfaData,
  setNavigationConfig,
  setActionsConfig,
  setUnreadNotificationTabsCount,
  setUserNotificationCurrentTab,
  setMessagesTabsCount,
  setNotificationTabsCount,
  setBannerRetryLink,
  setIsLoadingConnectEntity,
  setUserKycStatusInfo,
  setIsFetchingKycStatusInfo,
  setMasterData,
  setIsFetchingMasterData,
} = userSlice.actions;

export default userSlice.reducer;
