import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  REIMBURSEMENTS_SLICE_ATTRIBUTE_KEY,
  checkDuplicateReimbursement,
  createReimbursementClaim,
  fetchAndSelectReimbursement,
  fetchClaimCategoryLimitData,
  fetchReimbursementClaimSubmissionPolicy,
  removeReimbursement,
  resetEditAmountState,
  setClaimCategoryLimitDetails,
  setCreateClaimFormDetails,
  setCreatingduplicateReimbursement,
  setReimbursementClaimSubmissionPolicy,
  setSelectedClaimForReport,
  setSelectedReimbursement,
  updateClaim,
  updateReportClaim,
} from "@/store/reducers/reimbursement";
import {
  fetchAndSelectVendor,
  setSelectedVendor,
} from "@/store/reducers/vendors";

import { merchantListSelector } from "@/store/selectors/cards";
import {
  accountingIntegrationSoftwareSelector,
  claimAmountEditEnabledSelector,
  defaultCurrencySelector,
  reimbursementReportsEnabledSelector,
  submissionPolicyEnabledSelector,
} from "@/store/selectors/client";
import { projectsSelector } from "@/store/selectors/company";
import {
  createClaimFormDetailsSelector,
  creatingduplicateReimbursementSelector,
  editAmountReasonSelector,
  editAmountSelector,
  isCreateLoadingSelector,
  isFetchingReimbursementClaimSubmissionPolicySelector,
  mileageRateListSelector,
  reimbursementClaimSubmissionPolicySelector,
  selectedReimbursementSelector,
  setClaimCategoryLimitDetailSelector,
} from "@/store/selectors/reimbursement";
import {
  accountingCategoryTagSelector,
  nonAccountingCategoryTagSelector,
  reimbursementCustomTagsSelector,
} from "@/store/selectors/tags";
import { userSelector } from "@/store/selectors/user";
import { selectedVendorBankDetailsPresentSelector } from "@/store/selectors/vendors";

import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

import { generatePayloadFromFormValue } from "@/components/GenericForm/common";
import OutOfThePocketForm from "@/components/Reimbursement/CreateReimbursement/OutOfThePocketForm";
import ReimbursementType from "@/components/Reimbursement/CreateReimbursement/ReimbursementType";
import {
  convertReimbursmentPayload,
  getAllKeysAccounting,
  getCorrectedPayload,
  getTagsPreview,
} from "@/components/Reimbursement/utils";
import vToast from "@/utils/vToast";
import { useForm } from "@/utils/useForm";
import {
  CONST_TYPE_REIMBURSEMENT,
  CREATE_REIMBURSEMENT_TYPE,
  REIMBURSEMENT_STATUS,
} from "@/utils/constants/reimbursement";
import { USE_FORM_TYPE_OF_VALIDATION } from "@/utils/constantUseForm";
import {
  capitalizeFirstLetter,
  getConstantTimeDate,
  getDateInPattern,
  validateAndConvertToISO8601,
} from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  MERCHANT,
  NON_FLEXIBLE,
  REIMBURSEMENT_PAYLOAD_KEY,
} from "@/constants/reimbursement";
import { OWNER_TYPE_USER } from "@/constants/user";
import { VENDOR_LINKED_TO_TYPES } from "@/constants/vendors";

export default function CreateReimbursementSlider({ setOnClose, setOnBack }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParam, setSearchParam] = useSearchParams();
  const [limit, setLimit] = useState(0);
  const selectedReimbursement = useSelector(selectedReimbursementSelector);

  const editAmount = useSelector(editAmountSelector);
  const editAmountReason = useSelector(editAmountReasonSelector);
  const isCreating = useSelector(isCreateLoadingSelector);
  const submissionPolicyEnabled = useSelector(submissionPolicyEnabledSelector);
  const submissionPolicy = useSelector(
    reimbursementClaimSubmissionPolicySelector
  );
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const accountingEnabled = !!accountingSoftware;
  const submissionPolicyTags = useMemo(
    () => [
      ...(accountingEnabled ? (submissionPolicy?.accountingTags ?? []) : []),
      // ignore accountingTags is software is not integrated.
      ...(submissionPolicy?.customTags || []),
    ],
    [submissionPolicy, accountingEnabled]
  );
  const submissionPolicyMap = useMemo(
    () =>
      submissionPolicyTags?.length
        ? submissionPolicyTags?.reduce(
            (acc, curr) => ({ ...acc, [curr.tagId]: curr }),
            {}
          )
        : {},
    [JSON.stringify(submissionPolicyTags)]
  );

  const isFetchingSubmissionPolicy = useSelector(
    isFetchingReimbursementClaimSubmissionPolicySelector
  );

  const [presentMerchantData, setPresentMerchantData] = useState(null);
  const mileageRateList = useSelector(mileageRateListSelector);
  const reportEnabled = useSelector(reimbursementReportsEnabledSelector);

  const updateRequiredKeyOfTag = (data) => {
    return data?.map((tag) => {
      const submissionPolicyOfTag = submissionPolicyMap?.[tag?.id];
      if (reportEnabled) {
        return { ...tag, required: true };
      }
      if (!submissionPolicyOfTag?.required) return { ...tag, required: false };
      if (submissionPolicyOfTag?.required && !submissionPolicyOfTag?.amount)
        return { ...tag, required: true };
      return {
        ...tag,
        required:
          parseFloat(submissionPolicyOfTag?.amount || 0) <
          parseFloat(limit || 0),
      };
    });
  };

  // cutom for payrolll
  const _customTags = useSelector(reimbursementCustomTagsSelector);
  // tags with submission policy required details added because tag is optional or not detail is wrong
  const customTags =
    useMemo(
      () => updateRequiredKeyOfTag(_customTags) ?? [],
      [JSON.stringify(submissionPolicyMap), JSON.stringify(_customTags), limit]
    ) ?? [];

  // accounting category
  const _accountingCategory = useSelector(accountingCategoryTagSelector);
  const accountingCategory = useMemo(
    () => updateRequiredKeyOfTag([_accountingCategory])?.[0] ?? {},
    [
      JSON.stringify(submissionPolicyMap),
      JSON.stringify(_accountingCategory),
      limit,
    ]
  );

  // accounting non category
  const _nonAccountingCategory = useSelector(nonAccountingCategoryTagSelector);

  const nonAccountingCategory =
    useMemo(
      () => updateRequiredKeyOfTag(_nonAccountingCategory) ?? [],
      [
        JSON.stringify(submissionPolicyMap),
        JSON.stringify(_nonAccountingCategory),
        limit,
      ]
    ) ?? [];

  const allTags = useMemo(
    () =>
      [
        ...(accountingEnabled
          ? [accountingCategory, ...nonAccountingCategory]
          : []),
        ...customTags,
      ].filter(Boolean),
    [accountingCategory, nonAccountingCategory, customTags]
  );

  const isThisReimbursementIsDuplicate = useSelector(
    creatingduplicateReimbursementSelector
  );
  const reimId =
    searchParam.get(SLIDERS_SEARCH_PARAMS.reimbursements.review) ||
    searchParam.get(SLIDERS_SEARCH_PARAMS.reimbursements.editClaim);

  const editMode = searchParam.has(
    SLIDERS_SEARCH_PARAMS.reimbursements.editClaim
  );

  const currentUser = useSelector(userSelector);
  const claimAmountEditEnabled = useSelector(claimAmountEditEnabledSelector);
  const isApproverEdit =
    claimAmountEditEnabled &&
    editMode &&
    currentUser?.id !== selectedReimbursement?.createdBy?.id;

  const createReportMode = searchParam.has(
    SLIDERS_SEARCH_PARAMS.reports.create
  );

  const reviewReportId = searchParam.get(
    SLIDERS_SEARCH_PARAMS.reports.reviewId
  );

  const claimCreationMode = searchParam.get(
    SLIDERS_SEARCH_PARAMS.reimbursements.create
  );

  const currentClaimFormDetails = useSelector(createClaimFormDetailsSelector);

  const claimCategoryLimitDetails = useSelector(
    setClaimCategoryLimitDetailSelector
  );
  const projects = useSelector(projectsSelector);

  const allKeysAccountingArgs = {
    accountingEnabled,
    accountingCategory,
    nonAccountingCategory,
    customTags,
  };
  const allKeysAccounting = useMemo(
    () => getAllKeysAccounting(allKeysAccountingArgs),
    [JSON.stringify(allKeysAccountingArgs)]
  );
  const merchants = useSelector(merchantListSelector); // merchant control options
  const defaultCurrency = useSelector(defaultCurrencySelector);

  const [tab] = window.location.pathname.split("/").slice(-1);

  const { departmentId, departmentName, id, vendorId } =
    useSelector(userSelector);
  const bankDetailsPresent = useSelector(
    selectedVendorBankDetailsPresentSelector
  );
  const selectedCategory = selectedReimbursement?.accountingTags?.find(
    (tag) => tag.tagId === accountingCategory?.id
  );
  const [reimburseType, setReimburseType] = useState(
    CREATE_REIMBURSEMENT_TYPE.outOfPocket.value
  );

  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);

  const [prefilledData, setPrefilledData] = useState();

  const initialFormValue = {
    reimbursementType: {
      value:
        currentClaimFormDetails?.reimbursementType ??
        selectedReimbursement?.type ??
        reimburseType,
      validate: {
        required: false,
      },
    },
    transactionAmount: {
      value:
        selectedReimbursement?.claimAmount?.value ??
        currentClaimFormDetails?.transactionAmount ??
        "",
      validate: {
        required: true,
      },
    },
    transactionCurrency: {
      value:
        selectedReimbursement?.claimAmount?.currency ??
        currentClaimFormDetails?.transactionCurrency ??
        defaultCurrency,
      validate: {
        required: true,
      },
    },
    transactionDate: {
      value:
        currentClaimFormDetails?.transactionDate ??
        selectedReimbursement?.transactionDate ??
        "",
      validate: {
        required: reimburseType === CREATE_REIMBURSEMENT_TYPE.outOfPocket.value,
        [USE_FORM_TYPE_OF_VALIDATION.onOrBeforeDate]: getConstantTimeDate(
          new Date()
        ),
      },
    },
    receipts: {
      value: currentClaimFormDetails?.receipts ?? prefilledData?.receipts ?? [],
      validate: {
        required: false,
      },
    },
    remarks: {
      value: prefilledData?.memo ?? currentClaimFormDetails?.remarks ?? "",
      validate: {
        required: false,
      },
    },
    ...(reportEnabled
      ? {}
      : {
          projectId: {
            value:
              currentClaimFormDetails?.projectId ??
              prefilledData?.linkedTo?.id ??
              departmentId,
            validate: {
              required: false,
            },
          },
        }),
    totalMiles: {
      value:
        currentClaimFormDetails?.totalMiles ??
        selectedReimbursement?.distanceTravelled ??
        "",
      validate: {
        required: reimburseType !== CREATE_REIMBURSEMENT_TYPE.outOfPocket.value,
      },
    },
    travelDate: {
      value:
        currentClaimFormDetails?.travelDate ??
        selectedReimbursement?.travelDate ??
        "",
      validate: {
        required: reimburseType !== CREATE_REIMBURSEMENT_TYPE.outOfPocket.value,
        [USE_FORM_TYPE_OF_VALIDATION.onOrBeforeDate]: getConstantTimeDate(
          new Date()
        ),
      },
    },
    travelCountry: {
      value:
        currentClaimFormDetails?.travelCountry ??
        selectedReimbursement?.travelCountry ??
        "",
      validate: {
        required: reimburseType !== CREATE_REIMBURSEMENT_TYPE.outOfPocket.value,
      },
    },
    startLocation: {
      value:
        currentClaimFormDetails?.startLocation ??
        selectedReimbursement?.startLocation ??
        "",
      validate: {
        required: false,
      },
    },

    endLocation: {
      value:
        currentClaimFormDetails?.endLocation ??
        selectedReimbursement?.endLocation ??
        "",
      validate: {
        required: false,
      },
    },
    merchant: {
      value:
        currentClaimFormDetails?.merchant ??
        selectedReimbursement?.merchant?.name ??
        "",
      validate: {
        required: reimburseType === CREATE_REIMBURSEMENT_TYPE.outOfPocket.value,
      },
    },
    categoryId: {
      value:
        currentClaimFormDetails?.categoryId ??
        selectedReimbursement?.category?.id ??
        "",
      validate: {
        required:
          !isApproverEdit &&
          reimburseType === CREATE_REIMBURSEMENT_TYPE.outOfPocket.value,
      },
    },
    ownerId: {
      value: id,
    },
    ownerType: {
      value: capitalizeFirstLetter(OWNER_TYPE_USER),
    },
    ...(accountingEnabled
      ? {
          [accountingCategory?.id]: {
            value: currentClaimFormDetails?.[accountingCategory?.id] ?? "",
          },
        }
      : {}),
    ...(accountingEnabled && accountingCategory
      ? {
          [accountingCategory?.id]: {
            value:
              currentClaimFormDetails?.[accountingCategory?.id] ??
              selectedCategory?.tagValueId ??
              "",
          },
        }
      : {}),
    ...(accountingEnabled && nonAccountingCategory?.length
      ? nonAccountingCategory
          ?.map((tag) => {
            const selectednonCategory = prefilledData?.accountingTags?.find(
              (accountingTag) => accountingTag?.tagId === tag?.id
            );
            const selectedValue =
              selectednonCategory?.tagValueId ||
              selectednonCategory?.customTextValue;
            return {
              [tag?.id]: {
                value:
                  currentClaimFormDetails?.[tag?.id] ?? selectedValue ?? "",
              },
            };
          })
          ?.reduce((acc, curr) => ({ ...acc, ...curr }), {})
      : {}),
    ...(customTags?.length
      ? customTags
          ?.map((tag) => {
            const selectedCustomTag = prefilledData?.customTags?.find(
              (customTag) => customTag?.tagId === tag?.id
            );
            const selectedValue =
              selectedCustomTag?.tagValueId ||
              selectedCustomTag?.customTextValue;
            return {
              [tag?.id]: {
                value:
                  currentClaimFormDetails?.[tag?.id] ?? selectedValue ?? "",
              },
            };
          })
          ?.reduce((acc, curr) => ({ ...acc, ...curr }), {})
      : {}),

    ...(!reportEnabled
      ? {
          linkedTo: {
            value:
              currentClaimFormDetails?.linkedTo ??
              prefilledData?.linkedTo?.title ??
              VENDOR_LINKED_TO_TYPES.DEPARTMENT,
            validate: {
              required: false,
            },
          },
        }
      : {}),
  };

  const cancelHandler = () => {
    if ((createReportMode || reportEnabled) && !reimId) {
      setPrefilledData(null);
      dispatch(setSelectedReimbursement(null));
      dispatch(setCreateClaimFormDetails(null));
      searchParam.delete(SLIDERS_SEARCH_PARAMS.reimbursements.create);
      setSearchParam(searchParam);
    } else {
      searchParam.delete(SLIDERS_SEARCH_PARAMS.reimbursements.review);
      setSearchParam(searchParam);
    }
  };

  const submitHandler = (_formEvent, _latestValues, _resetValues, rest) => {
    const { setErrors } = rest || {};
    const data = {
      ...values,
      ...presentMerchantData,
    };

    if (
      !isApproverEdit &&
      !isSubmissionTagsSatisfied({
        transactionAmount: values?.transactionAmount,
        setErrors,
      })
    ) {
      const message = t(
        "billPay.bill.invoiceInbox.createBill.warnings.requirementMissing"
      );
      const desc = t(
        "billPay.bill.invoiceInbox.createBill.warnings.requirementMissingDesc"
      );
      vToast({
        title: message,
        description: desc,
        variant: "warning",
      });
      return;
    }

    if (!reportEnabled) {
      if (values?.projectId === departmentId) {
        data.deptOrProjectName = departmentName;
      } else if (projects?.length) {
        const { name: projectName } =
          projects?.find((project) => project.id === values?.projectId) ?? {}; // this will never fail in prod, but keep fallback for staging
        data.deptOrProjectName = projectName;
      } else {
        data.deptOrProjectName = departmentName;
      }
    }

    if (
      (!reportEnabled && !editMode) ||
      ((createReportMode || reviewReportId) && claimCreationMode)
    ) {
      dispatch(setCreateClaimFormDetails(data));
      searchParam.append(SLIDERS_SEARCH_PARAMS.reimbursements.confirm, true);
      setSearchParam(searchParam);
    } else {
      handleSaveAsDraft();
    }
  };

  const {
    handleChange,
    values,
    _setValues: setValues,
    errors,
    handleSubmit,
    isFormButtonDisabled,
  } = useForm(initialFormValue, submitHandler, {
    formId: "create-claim-details-form",
  });

  const saveCompleteForm = () => {
    dispatch(setCreateClaimFormDetails(values));
  };

  useEffect(() => {
    if (
      values?.receipts?.length ||
      values?.remarks ||
      customTags?.find((g) => values?.[g?.id]) ||
      nonAccountingCategory?.find((g) => values?.[g?.id]) ||
      values?.[accountingCategory?.id]
    ) {
      setSaveButtonEnabled(true);
      return;
    }

    switch (values?.reimbursementType) {
      case CREATE_REIMBURSEMENT_TYPE.outOfPocket.value:
        setSaveButtonEnabled(
          values?.merchant ||
            (values?.transactionCurrency && values?.transactionAmount) ||
            values?.transactionDate
        );
        break;

      case CREATE_REIMBURSEMENT_TYPE.mileage.value:
        setSaveButtonEnabled(
          values?.totalMiles || values?.travelCountry || values?.travelDate
        );
        break;

      default:
        setSaveButtonEnabled(true);
        break;
    }
  }, [values]);

  const amountToBeReceivedByClaimer = values?.transactionAmount;
  const isReceiptRequired = !!(
    submissionPolicy?.receipt?.required &&
    amountToBeReceivedByClaimer > Number(submissionPolicy?.receipt?.amount || 0)
  );

  const isReceiptError = isReceiptRequired && !values?.receipts?.length;

  const isMemoRequired =
    submissionPolicy?.memo?.required &&
    amountToBeReceivedByClaimer > Number(submissionPolicy?.memo?.amount || 0);
  const isMemoError = isMemoRequired && !values?.remarks;

  const mileage = useMemo(
    () =>
      mileageRateList?.find(
        (data) => data.countryName === values?.travelCountry
      ),
    [values?.travelCountry]
  );

  const createClaimButtonDisabled =
    (!isApproverEdit && isFetchingSubmissionPolicy) ||
    (reimburseType !== CREATE_REIMBURSEMENT_TYPE.outOfPocket.value &&
      !mileage) ||
    (claimCategoryLimitDetails?.categoryLimitExceeded &&
      claimCategoryLimitDetails?.type === NON_FLEXIBLE) ||
    (reportEnabled ? false : !values?.projectId) ||
    (!isApproverEdit && isReceiptError) ||
    (!isApproverEdit && isMemoError);

  const onSuccess = () => {
    dispatch(setCreateClaimFormDetails(null));
    dispatch(resetEditAmountState(null));

    dispatch(setSelectedVendor(null));
    dispatch(setSelectedReimbursement(null));
    dispatch(setClaimCategoryLimitDetails(null));
    setPrefilledData(null);

    if (reportEnabled && !reimId && !createReportMode) {
      dispatch(setSelectedClaimForReport([]));
    }
    if (reimId) {
      searchParam.delete(SLIDERS_SEARCH_PARAMS.reimbursements.review);
      searchParam.delete(SLIDERS_SEARCH_PARAMS.reimbursements.editClaim);
    } else {
      searchParam.delete(SLIDERS_SEARCH_PARAMS.reimbursements.create);
    }

    searchParam.delete(SLIDERS_SEARCH_PARAMS.reimbursements.pageType);
    setSearchParam(searchParam);
  };

  const openAddBankDetailsPage = () => {
    const data = {
      ...values,
      ...presentMerchantData,
    };

    if (!reportEnabled) {
      if (values?.projectId === departmentId) {
        data.deptOrProjectName = departmentName;
      } else {
        const { name: projectName } =
          projects?.find((project) => project.id === values?.projectId) ?? {}; // this will never fail in prod, but keep fallback for staging
        data.deptOrProjectName = projectName;
      }
    }

    dispatch(setCreateClaimFormDetails(data));

    searchParam.append(
      [SLIDERS_SEARCH_PARAMS.company.people.preAddBankDetails],
      true
    );
    setSearchParam(searchParam);
  };

  const handleSaveAsDraft = () => {
    let data = {};
    if (presentMerchantData) {
      data = {
        ...values,
        ...presentMerchantData,
        merchant: "",
      };
    } else {
      data = values;
    }

    const filteredObject = generatePayloadFromFormValue(data);

    if (!reimId && !reviewReportId) {
      const correctedEditedData = getCorrectedPayload(
        filteredObject,
        allKeysAccounting
      );

      const formData = convertReimbursmentPayload(correctedEditedData);

      dispatch(
        createReimbursementClaim({
          payload: formData,
          noToast: false,
          tab,
          onSuccess,
        })
      );
    } else {
      const correctedEditedData = getCorrectedPayload(
        filteredObject,
        allKeysAccounting,
        selectedReimbursement
      );

      const filteredReceiptFromResponse = {
        ...correctedEditedData,
        ...(isApproverEdit && editAmount
          ? {
              amount_to_be_paid: editAmount,
              amount_change_reason: editAmountReason,
            }
          : {}),

        receipts: data?.receipts?.filter((file) => file instanceof File),
      };

      const formData = convertReimbursmentPayload(filteredReceiptFromResponse);

      if (reviewReportId && !reimId) {
        dispatch(
          createReimbursementClaim({
            payload: formData,
            noToast: false,
            tab,
            onSuccess: (responseId) => {
              dispatch(
                updateReportClaim({
                  id: reviewReportId,
                  payload: {
                    add_ids: [responseId],
                  },
                  onSuccess,
                })
              );
            },
          })
        );
      } else {
        dispatch(
          updateClaim({
            id: reimId,
            payload: formData,
            onSuccess: () => {
              if (
                selectedReimbursement?.status === REIMBURSEMENT_STATUS.denied
              ) {
                dispatch(
                  removeReimbursement({
                    value: Number(reimId),
                    key: REIMBURSEMENTS_SLICE_ATTRIBUTE_KEY,
                  })
                );
              } else {
                onSuccess();
              }
            },
          })
        );
      }
    }
  };

  const isRenderTagsErrors = ({ unFullfilledTagsSet, setErrors }) => {
    setErrors((prev) => ({
      ...prev,
      ...Array.from(unFullfilledTagsSet).reduce(
        (accum, tagId) => ({
          ...accum,
          [tagId]: t("misc.required"),
        }),
        {}
      ),
    }));
  };
  //
  const isSubmissionTagsSatisfied = ({ transactionAmount, setErrors }) => {
    const tagsPayload = getTagsPreview({
      values,
      selectedReimbursement,
      allTags,
    });
    // Product logic
    // submissionPolicy subset of allTags, guaranteed
    // submissionPolicy is the validation, not allTags (even if it has a required true)

    // This IFFE checks if atleast one occurence of submission policy custom tags are present (considers all line items)
    const applicableSubmissionPolicyTags = submissionPolicyTags.filter(
      (sPolicyTag) =>
        sPolicyTag.required &&
        transactionAmount > Number(sPolicyTag.amount || 0)
    );

    if (!applicableSubmissionPolicyTags.length) return true;

    const unFullfilledTagsSet = new Set();
    applicableSubmissionPolicyTags.forEach((tag) => {
      const tagData = tagsPayload.find(
        (item) => item[REIMBURSEMENT_PAYLOAD_KEY.TAG_ID] === tag.tagId
      );

      if (
        !(
          tagData?.[REIMBURSEMENT_PAYLOAD_KEY.TAG_VALUE_ID] ||
          tagData?.[REIMBURSEMENT_PAYLOAD_KEY.CUSTOM_TEXT_VALUE]
        )
      )
        unFullfilledTagsSet.add(tag.tagId);
    });

    const isFullFilledRequiredTags =
      tagsPayload.length && unFullfilledTagsSet.size === 0;

    if (!isFullFilledRequiredTags)
      isRenderTagsErrors({ unFullfilledTagsSet, setErrors });

    return isFullFilledRequiredTags;
  };

  useEffect(() => {
    const merchantIdObj = merchants?.find(
      (item) => item.name === values?.merchant
    );

    if (merchantIdObj) {
      setPresentMerchantData({
        merchantOwnerId: merchantIdObj?.id,
        merchantOwnerType: MERCHANT,
      });
    } else {
      setPresentMerchantData(null);
    }
  }, [values?.merchant]);

  useEffect(() => {
    return () => {
      dispatch(setCreatingduplicateReimbursement(null));
    };
  }, []);
  useEffect(() => {
    if (vendorId) dispatch(fetchAndSelectVendor({ id: vendorId }));
  }, [vendorId]);

  useEffect(() => {
    if (claimAmountEditEnabled && !values?.reimbursementType) {
      return;
    }
    if (selectedReimbursement?.type !== values?.reimbursementType) {
      dispatch(setSelectedReimbursement(null));
    }
    dispatch(setClaimCategoryLimitDetails(null));
  }, [values?.reimbursementType]);

  useEffect(() => {
    if (selectedReimbursement) {
      setPrefilledData(selectedReimbursement);
    } else if (reportEnabled) {
      setPrefilledData(null);
    }
  }, [selectedReimbursement]);
  useEffect(() => {
    setLimit(values?.transactionAmount);
  }, [values?.transactionAmount]);
  useEffect(() => {
    if (
      values?.transactionAmount &&
      values?.transactionCurrency &&
      values?.projectId &&
      values?.linkedTo &&
      values?.categoryId
    ) {
      if (createReportMode) {
        return;
      }
      dispatch(
        fetchClaimCategoryLimitData({
          amount: values?.transactionAmount,
          currency: values?.transactionCurrency,
          project_id: values?.projectId,
          category_id: values?.categoryId,
        })
      );
    }
  }, [
    values?.transactionAmount,
    values?.transactionCurrency,
    values?.linkedTo,
    values?.projectId,
    values?.categoryId,
  ]);

  useEffect(() => {
    if (selectedReimbursement) return;

    if (
      (editMode && values?.reimbursementType === prefilledData?.type) ||
      (reimId && values?.reimbursementType === prefilledData?.type)
    ) {
      dispatch(
        fetchAndSelectReimbursement({
          reimId,
          onSuccess: () => {
            dispatch(resetEditAmountState());
          },
        })
      );
    }
  }, [values?.reimbursementType, prefilledData?.type, reimId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        (reimburseType === CONST_TYPE_REIMBURSEMENT.mileage &&
          values?.travelDate &&
          values?.totalMiles) ||
        (reimburseType === CONST_TYPE_REIMBURSEMENT.outOfPocket &&
          values?.transactionCurrency &&
          values?.transactionAmount &&
          values?.transactionDate &&
          values?.merchant)
      ) {
        const {
          travelDate,
          totalMiles,
          transactionCurrency,
          transactionAmount,
          transactionDate,
          merchant,
        } = values;
        const mileageObject = {
          travel_date: travelDate
            ? getDateInPattern(
                new Date(validateAndConvertToISO8601(travelDate)),
                "yyyy-mm-dd"
              )
            : null,
          total_miles: totalMiles,
          current_id: reimId,
        };
        const outOfPocketObject = {
          transaction_currency: transactionCurrency,
          transaction_amount: transactionAmount,
          transaction_date: transactionDate
            ? getDateInPattern(
                new Date(validateAndConvertToISO8601(transactionDate)),
                "yyyy-mm-dd"
              )
            : null,
          merchant,
          current_id: reimId,
        };

        dispatch(
          checkDuplicateReimbursement({
            reimbursement_type: reimburseType,
            ...(reimburseType === CONST_TYPE_REIMBURSEMENT.mileage
              ? mileageObject
              : outOfPocketObject),

            user_id: currentUser?.id,
          })
        );
      }
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [
    reimburseType,
    values?.transactionAmount,
    values?.transactionDate,
    values?.travelDate,
    values?.transactionCurrency,
    values?.merchant,
    values?.totalMiles,
  ]);
  useEffect(() => {
    setOnClose((searchParamArray) => {
      const isClosed =
        !searchParamArray?.includes(
          SLIDERS_SEARCH_PARAMS.reimbursements.create
        ) &&
        !searchParamArray?.includes(
          SLIDERS_SEARCH_PARAMS.reimbursements.editClaim
        ) &&
        !searchParamArray?.includes(SLIDERS_SEARCH_PARAMS.reports.create) &&
        (!searchParamArray?.includes(
          SLIDERS_SEARCH_PARAMS.reimbursements.review
        ) ||
          !searchParamArray?.includes(
            SLIDERS_SEARCH_PARAMS.reimbursements.confirm
          ));

      if (isClosed) {
        dispatch(setSelectedVendor(null));
        dispatch(setSelectedReimbursement(null));
        dispatch(setCreateClaimFormDetails(null));
        dispatch(setClaimCategoryLimitDetails(null));
        dispatch(resetEditAmountState());
        setPrefilledData(null);

        if (reportEnabled) {
          dispatch(setSelectedClaimForReport([]));
        }
      }
    });
  }, []);

  useEffect(() => {
    setOnBack(() => {
      setPrefilledData(null);
      dispatch(setSelectedReimbursement(null));
      dispatch(setCreateClaimFormDetails(null));
    });
  }, []);

  useEffect(() => {
    if (
      submissionPolicyEnabled &&
      values?.projectId &&
      values?.reimbursementType &&
      !reportEnabled
    ) {
      dispatch(
        fetchReimbursementClaimSubmissionPolicy({
          projectId: values?.projectId,
          type: values?.reimbursementType,
        })
      );
    }

    return () => {
      dispatch(setReimbursementClaimSubmissionPolicy(null));
    };
  }, [
    values?.projectId,
    submissionPolicyEnabled,
    reportEnabled,
    values?.reimbursementType,
  ]);

  return (
    <>
      <div className="flex flex-col slider-content-core px-9 ">
        <div className="flex flex-col">
          <CreateHeaderReimbursment
            title={
              editMode
                ? "reimbursement.ctas.edit"
                : "reimbursement.createReimbursement.title"
            }
          />
          <Text
            classes=" text-neutral-500 text-sm"
            translationKey="reimbursement.createReimbursement.subtitle"
          />
        </div>

        <form onSubmit={handleSubmit} id="create-claim-details-form">
          <div className="my-9">
            <Text
              classes="font-bold text-xl"
              translationKey="reimbursement.createReimbursement.typeHeading"
            />
            <ReimbursementType
              handleChange={handleChange}
              values={values}
              errors={errors}
              setReimbursementType={setReimburseType}
              disabled={isApproverEdit}
            />
            <div className="flex flex-col mt-9">
              <Text
                classes="font-bold text-xl"
                translationKey="reimbursement.createReimbursement.detailsHeading"
              />
              <OutOfThePocketForm
                accountingSoftware={accountingSoftware}
                accountingEnabled={accountingEnabled}
                customTags={customTags}
                accountingCategory={accountingCategory}
                nonAccountingCategory={nonAccountingCategory}
                handleChange={handleChange}
                values={values}
                errors={errors}
                reimbursementType={values?.reimbursementType}
                setValues={setValues}
                claimCategoryLimitDetails={claimCategoryLimitDetails}
                isThisReimbursementIsDuplicate={isThisReimbursementIsDuplicate}
                isReceiptError={isReceiptError}
                isMemoError={isMemoError}
                isApproverEdit={isApproverEdit}
                saveCompleteForm={saveCompleteForm}
                editMode={editMode}
              />
            </div>

            {bankDetailsPresent ? null : (
              <div className="flex flex-col gap-6 p-4 my-8 font-semibold rounded-lg bg-neutral-50">
                <div className="flex flex-col">
                  <Text
                    translationKey="company.people.peopleMyProfileSlider.personalBankDetails"
                    classes="text-lg"
                  />
                  <Text
                    translationKey="company.people.peopleMyProfileSlider.personalBankDetailsDesc"
                    classes="text-xs text-neutral-500"
                  />
                </div>
                <Button
                  className="text-primary-600 border border-neutral-300 w-fit py-1.5 px-5 text-sm font-medium rounded-lg flex items-center gap-2 cursor-pointer"
                  onClick={openAddBankDetailsPage}
                  label="billPay.vendors.createVendor.addBankDetails"
                  preIcon="Add"
                  iconClasses="p-0.75 -mr-2"
                  innerClasses="gap-1 font-bold text-xs"
                />
              </div>
            )}
          </div>
        </form>
      </div>
      <div className="p-6 slider-footer">
        <div className="flex justify-end gap-4">
          {reportEnabled ? (
            (createReportMode || reviewReportId) && !reimId ? (
              <>
                <Button
                  label="reimbursement.createReimbursement.footer.cancel"
                  type="neutral"
                  classes="w-fit px-8 py-3 font-medium"
                  onClick={cancelHandler}
                />

                <Button
                  label="myVolopay.reimbursements.reports.create.review.continueCta"
                  classes="w-fit px-5 py-3 text-white font-medium"
                  variant="primary"
                  btnType="submit"
                  form="create-claim-details-form"
                  disabled={isFormButtonDisabled || createClaimButtonDisabled}
                />
              </>
            ) : (
              <>
                <Button
                  label="reimbursement.createReimbursement.footer.cancel"
                  type="neutral"
                  classes="w-fit px-8 py-3 font-medium"
                  onClick={cancelHandler}
                  disabled={isCreating}
                />

                <Button
                  label={
                    reimId
                      ? "myVolopay.reimbursements.reports.create.createFooterCtas.save"
                      : "reimbursement.createReimbursement.footer.saveAsDraft"
                  }
                  classes="w-16 px-5 py-3 text-white font-medium"
                  variant="primary"
                  showLoader={isCreating}
                  onClick={submitHandler}
                  disabled={!saveButtonEnabled}
                />
              </>
            )
          ) : (
            <>
              <Button
                label={
                  editMode
                    ? "reimbursement.createReimbursement.footer.cancel"
                    : "reimbursement.createReimbursement.footer.saveAsDraft"
                }
                type="neutral"
                classes="w-fit px-8 py-3 font-medium"
                onClick={editMode ? cancelHandler : handleSaveAsDraft}
                disabled={reimId ? false : !saveButtonEnabled}
                showLoader={isCreating}
              />

              <Button
                label={
                  editMode
                    ? "reimbursement.createReimbursement.footer.updateClaim"
                    : "reimbursement.createReimbursement.footer.saveAndContinue"
                }
                classes="w-fit px-5 py-3 text-white font-medium"
                variant="primary"
                btnType="submit"
                form="create-claim-details-form"
                disabled={
                  createClaimButtonDisabled ? true : isFormButtonDisabled
                }
                showLoader={editMode && isCreating}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
const CreateHeaderReimbursment = ({ title }) => {
  const ref = useLeftHeaderTitle({ title });
  return (
    <Text refProp={ref} classes="font-bold text-3xl" translationKey={title} />
  );
};

CreateReimbursementSlider.propTypes = {
  setOnClose: PropTypes.func,
  setOnBack: PropTypes.func,
};
