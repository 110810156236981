import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  fetchPeopleShallow,
  fetchRoleDetails,
  fetchRoles,
} from "@/store/reducers/company";
import {
  enablePayrollSettings,
  setPayrollToggleSwitchState,
} from "@/store/reducers/payments";
import {
  fetchUserRolesList,
  refreshUserData,
  setUserRolesListInitialState,
  updateUserRolesList,
  validateToken,
} from "@/store/reducers/user";

import {
  allPeopleSelector,
  isFetchingPeopleSelector,
  roleDetailsSelector,
  rolesSelector,
} from "@/store/selectors/company";
import { enablePayrollSelector } from "@/store/selectors/payments";
import { userRolesListSelector } from "@/store/selectors/user";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";

import EditAddPeopleDumb from "@/components/common/BillPayAndPayroll/Settings/Sliders/common/EditAddPeopleDumb";
import {
  REIMBURSEMENT_SETTINGS_TYPE,
  ROLES_TYPE,
} from "@/components/common/BillPayAndPayroll/Settings/Sliders/common/SettingsType";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";
import vToast from "@/utils/vToast";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { PAYROLL } from "@/utils/constants/payments";
import { calculateAddsAndDeletes } from "@/utils/common";

import { CARDS_CONTEXT } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ROLE_PERMISSIONS_PEOPLE_TAB_PARAMS } from "@/constants/company";
import { REIMBURSEMENT_CONTEXT } from "@/constants/reimbursement";
import {
  PERMISSIONS,
  READ_ONLY,
  ROLE_TITLES,
  ROLE_TITLES_LABELS,
  ROLE_TITLES_LABELS_PLURAL,
} from "@/constants/user";

/**
 * The component is used to manage and edit lists of people with different roles or types
 * this is extensible component for managing new types of users of different types
 */
export default function EditAddPeopleSmartComponent({
  type,
  context,
  setOnClose = () => {},
  searchParamKey,
  showTitleAndDescription = true,
  showCancelButton = false,
  peoplePermission = false,
  handleCancel = () => {},
  shallow = true,
  useCancelForDiscardEditing = false,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const readOnlyMode =
    searchParams.get(SLIDERS_SEARCH_PARAMS.payrollPayments.permissionType) ===
    READ_ONLY;

  const allUsers = useSelector(allPeopleSelector);
  const isShallowFetching = useSelector(isFetchingPeopleSelector);
  const existingList = useSelector(userRolesListSelector)?.list;
  const isFetchingExistingList = useSelector(userRolesListSelector)?.isFetching;

  const enablePayroll = useSelector(enablePayrollSelector);

  const roles = useSelector(rolesSelector);

  const roleDetails = useSelector(roleDetailsSelector);
  const role = roles?.find((role_obj) => {
    if (type === ROLES_TYPE.TOGGLE_PAYROLL)
      return role_obj.name === ROLES_TYPE.PAYROLL_ADMINS;
    return role_obj.name === type;
  });

  const exceptIds = roles
    ?.filter(
      (roleItem) => roleItem.roleType === PAYROLL && roleItem.name !== type
    )
    ?.map((filteredData) => filteredData.id);

  const isRoleIdNotPresent = !role?.id;
  const [tempExistingList, setTempExistingList] = useState(existingList);
  const [deletedIds, setDeletedIds] = useState([]);

  const permissionListToCheck = [
    PERMISSIONS.GLOBAL_SETTINGS_MANAGE,
    PERMISSIONS.PAYROLL_ADMINISTRATE,
    PERMISSIONS.PAYROLL_MANAGE,
  ];

  const [editable, setEditable] = useState(false);

  const adminRoleId = roles?.find(
    (currentRole) => currentRole?.name === ROLES_TYPE.ADMIN
  )?.id;

  const fetchRolesApi = () => {
    if (shallow && context !== BILL_PAYROLL_CONTEXT.PAYROLL) {
      dispatch(
        fetchRoles({
          shallow: true,
        })
      );
    } else {
      dispatch(fetchRoles({ with_payroll_admin: true }));
    }
  };

  useEffect(() => {
    if (type === ROLES_TYPE.TOGGLE_PAYROLL || peoplePermission) {
      setEditable(true);
    }
  }, [type, peoplePermission]);

  const payrollAdminUsers = allUsers?.filter(
    (user) => user?.role === ROLE_TITLES.ADMIN
  );

  useEffect(() => {
    if (existingList.length !== 0) setEditable(false);
  }, [existingList]);

  const groupAdminAndManager = (user) => {
    const rolesToCheck = [
      {
        role: ROLE_TITLES.ADMIN,
        groupKey: ROLE_TITLES.ADMIN,
      },
      {
        role: ROLE_TITLES.DEPARTMENT_MANAGER,
        groupKey: ROLE_TITLES.DEPARTMENT_MANAGER,
      },
    ];

    const matchingRoles = rolesToCheck
      ?.filter((roleObj) => (context ? user?.role === roleObj?.role : false))
      ?.map((matchingRole) => ({
        groupKey: matchingRole.groupKey,
        label: user?.name,
        icon: ROLES_TYPE.ADMIN,
        subLabel: [
          t(ROLE_TITLES_LABELS[user?.role]),
          user.department,
          user.location,
        ]
          .filter((textItem) => textItem?.length > 0)
          .join(" • "),
      }));

    return matchingRoles;
  };

  useEffect(() => {
    if (readOnlyMode) setEditable(false);
  }, [readOnlyMode]);

  useEffect(() => {
    setTempExistingList(existingList);
  }, [type, existingList]);

  useEffect(() => {
    fetchRolesApi();
  }, []);

  useEffect(() => {
    // fetch existing
    fetchUserList();

    fetchDataEditable();
  }, [type, role?.id, context, roles]);

  const [editablePeopleIds, seteditablePeopleIds] = useState([]);

  const noOfPayrollAdmin = roles.find(
    (d) => d.name === ROLES_TYPE.PAYROLL_ADMINS
  )?.totalPeople;

  const noOfPayrollManager = roles.find(
    (d) => d.name === ROLES_TYPE.PAYROLL_MANAGERS
  )?.totalPeople;

  // compute the List UI (sections with counts + individuals)

  const peopleListItems = useMemo(() => {
    const sections = {}; // building sections
    const individuals = []; // pushing indviduals
    const deleteArray = [];

    const sourceArray = tempExistingList;

    sourceArray
      ?.filter((item) => !deleteArray.includes(item.id))
      ?.forEach((user) => {
        const groupsUserIsInvolvedIn = [
          BILL_PAYROLL_CONTEXT.BILLPAY,
          REIMBURSEMENT_CONTEXT,
          QRPAY_EXPENSES_CONTEXT.EXPENSES,
          CARDS_CONTEXT.CARDS,
          ROLE_PERMISSIONS_PEOPLE_TAB_PARAMS.PEOPLE,
        ].includes(context)
          ? groupAdminAndManager(user)
          : [];
        const isIndividual = groupsUserIsInvolvedIn?.length === 0;

        if (isIndividual) {
          individuals.push({
            itemId: user.id,
            deletable: true,

            itemData: {
              id: user.id,
              role: user?.role ?? user?.roles?.[0]?.name,
              displayName: user.displayName,
              departmentName: user.departmentName,
              locationName: user.locationName,
              avatarUrl: user.avatarUrl,
              colorCode: user.colorCode,
            },
          });
        } else {
          groupsUserIsInvolvedIn?.forEach(({ groupKey, icon = "" }) => {
            const ids = sections?.[groupKey]?.itemData?.ids ?? [];
            ids.push(user.id);

            sections[groupKey] = {
              itemId: groupKey,
              isGroup: true,
              deletable: false,

              itemData: {
                ids,
                icon,
                mainText: ROLE_TITLES_LABELS_PLURAL[groupKey],
                subText:
                  ids.length === 1
                    ? t("misc.oneMemberSingular", { count: ids.length })
                    : t("misc.xMemberPlural", { count: ids.length }),
              },
            };
          });
        }
      });

    if (
      context === PAYROLL &&
      ![ROLES_TYPE.TOGGLE_PAYROLL, ROLES_TYPE.PAYROLL_ADMINS]?.includes(type)
    ) {
      if (noOfPayrollAdmin) {
        sections.payroll_admin = {
          itemId: "payrollAdmin",
          isGroup: true,
          deletable: false,

          itemData: {
            icon: "Admin",
            mainText:
              noOfPayrollAdmin === 1
                ? ROLE_TITLES_LABELS.payroll_admin
                : ROLE_TITLES_LABELS_PLURAL.payroll_admin,
            subText:
              noOfPayrollAdmin === 1
                ? t("misc.oneMemberSingular", { count: 1 })
                : t("misc.xMemberPlural", { count: noOfPayrollAdmin }),
          },
        };
      }

      if (noOfPayrollManager && type !== ROLES_TYPE.PAYROLL_MANAGERS) {
        sections.payroll_manager = {
          itemId: "payrollManager",
          isGroup: true,
          deletable: false,

          itemData: {
            icon: "Admin",
            mainText:
              noOfPayrollAdmin === 1
                ? ROLE_TITLES_LABELS.payroll_manager
                : ROLE_TITLES_LABELS_PLURAL.payroll_manager,
            subText:
              noOfPayrollManager === 1
                ? t("misc.oneMemberSingular", { count: 1 })
                : t("misc.xMemberPlural", { count: noOfPayrollManager }),
          },
        };
      }
    }

    const sectionArray = Object.values(sections);
    sectionArray.sort((a, b) => a.itemId.localeCompare(b.itemId));
    return [...sectionArray, ...individuals];
  }, [existingList, tempExistingList, editable, allUsers, context]);

  const [selectedPeople, setSelectedPeople] = useState(peopleListItems);

  const fetchDataEditable = () => {
    if (!context || !type || !roles) return;

    // We call roles api above, so not required here
    // fetchRolesApi();
    dispatch(
      fetchPeopleShallow({
        ...(context === PAYROLL &&
        type !== ROLES_TYPE.TOGGLE_PAYROLL &&
        type !== ROLES_TYPE.PAYROLL_ADMINS
          ? {
              except_role: exceptIds,
            }
          : null),
      })
    );
  };

  /*
  This function is designed to be adaptable and extendable for different 'selectType' values.
  can be extended or modified in the future to handle additional 'selectType' values.
  */

  const getMetaData = (selectType) => {
    switch (selectType) {
      case ROLES_TYPE.AP_CLERK:
        return {
          headerTitle: "billPay.settings.sliders.apClerks.title",
          headerDescription: "billPay.settings.sliders.apClerks.description",
          listTitle:
            "billPay.settings.sliders.apClerks.addAPClerks.listOfAPClerks",
          selectLabel: "select",
          editTitle: "billPay.settings.sliders.apClerks.addAPClerks.title",
          editDescription:
            "billPay.settings.sliders.apClerks.addAPClerks.description",
        };
      case ROLES_TYPE.PAYMENT_INITIATORS:
        return {
          headerTitle: "billPay.settings.sliders.paymentInitiators.title",
          headerDescription:
            "billPay.settings.sliders.paymentInitiators.description",
          selectLabel: "select",
          listTitle:
            "billPay.settings.sliders.paymentInitiators.addPaymentInitiators.listOfPaymentInitiators",
          editTitle:
            "billPay.settings.sliders.paymentInitiators.addPaymentInitiators.title",
          editDescription:
            "billPay.settings.sliders.paymentInitiators.addPaymentInitiators.description",
        };
      case ROLES_TYPE.TOGGLE_PAYROLL:
        return {
          headerTitle:
            "payroll.payrollSettings.payrollSliders.enablePayroll.title",
          headerDescription:
            "payroll.payrollSettings.payrollSliders.enablePayroll.description",
          listTitle:
            "payroll.payrollSettings.payrollSliders.payrollUsers.addPayrollAdmins.listOfPayrollAdmins",
          selectLabel: "select",
          editTitle:
            "payroll.payrollSettings.payrollSliders.enablePayroll.addPayrollAdmins.title",
          editDescription:
            "payroll.payrollSettings.payrollSliders.enablePayroll.addPayrollAdmins.description",
          noteConfig: {
            hasNote: true,
            text: "payroll.payrollSettings.payrollSliders.enablePayroll.noteTitle",
            description:
              "payroll.payrollSettings.payrollSliders.enablePayroll.noteDesc",
          },
        };
      case ROLES_TYPE.PAYROLL_ADMINS:
        return {
          headerTitle: readOnlyMode
            ? "payroll.payrollSettings.payrollSliders.payrollUsers.readOnlyTitle"
            : "payroll.payrollSettings.payrollSliders.payrollUsers.title",
          headerDescription:
            "payroll.payrollSettings.payrollSliders.payrollUsers.description",
          listTitle:
            "payroll.payrollSettings.payrollSliders.payrollUsers.addPayrollAdmins.listOfPayrollAdmins",
          selectLabel: "select",
          editTitle:
            "payroll.payrollSettings.payrollSliders.payrollUsers.addPayrollAdmins.title",
          editDescription:
            "payroll.payrollSettings.payrollSliders.payrollUsers.addPayrollAdmins.description",
          noteConfig: {
            hasNote: !enablePayroll,
            text: "payroll.payrollSettings.payrollSliders.enablePayroll.noteTitle",
            description:
              "payroll.payrollSettings.payrollSliders.enablePayroll.noteDesc",
          },
        };
      case ROLES_TYPE.PAYROLL_MANAGERS:
        return {
          headerTitle:
            "payroll.payrollSettings.payrollSliders.payrollManagers.title",
          headerDescription:
            "payroll.payrollSettings.payrollSliders.payrollManagers.description",
          listTitle:
            "payroll.payrollSettings.payrollSliders.payrollManagers.addPayrollManagers.listOfPayrollManagers",
          selectLabel: "select",
          editTitle:
            "payroll.payrollSettings.payrollSliders.payrollManagers.addPayrollManagers.title",
          editDescription:
            "payroll.payrollSettings.payrollSliders.payrollManagers.addPayrollManagers.description",
          emptyDataTitle:
            "payroll.payrollSettings.payrollSliders.payrollManagers.emptyDataTitle",
          emptyDataDescription:
            "payroll.payrollSettings.payrollSliders.payrollManagers.emptyDataDescription",
          emptyButtonLabel:
            "payroll.payrollSettings.payrollSliders.payrollManagers.emptyButtonLabel",
          onClickHandler: editButtonHandler,
        };
      case ROLES_TYPE.PAYROLL_MEMBERS:
        return {
          headerTitle:
            "payroll.payrollSettings.payrollSliders.payrollMembers.title",
          headerDescription:
            "payroll.payrollSettings.payrollSliders.payrollMembers.description",
          listTitle:
            "payroll.payrollSettings.payrollSliders.payrollMembers.addPayrollMembers.listOfPayrollMembers",
          selectLabel: "select",
          editTitle:
            "payroll.payrollSettings.payrollSliders.payrollMembers.addPayrollMembers.title",
          editDescription:
            "payroll.payrollSettings.payrollSliders.payrollMembers.addPayrollMembers.description",
          emptyDataTitle:
            "payroll.payrollSettings.payrollSliders.payrollMembers.emptyDataTitle",
          emptyDataDescription:
            "payroll.payrollSettings.payrollSliders.payrollMembers.emptyDataDescription",
          emptyButtonLabel:
            "payroll.payrollSettings.payrollSliders.payrollMembers.emptyButtonLabel",
          onClickHandler: editButtonHandler,
        };
      case ROLES_TYPE.PAYROLL_CLERKS:
        return {
          headerTitle:
            "payroll.payrollSettings.payrollSliders.payrollClerks.title",
          headerDescription:
            "payroll.payrollSettings.payrollSliders.payrollClerks.description",
          listTitle:
            "payroll.payrollSettings.payrollSliders.payrollClerks.addPayrollClerks.listOfPayrollClerks",
          selectLabel: "select",
          editTitle:
            "payroll.payrollSettings.payrollSliders.payrollClerks.addPayrollClerks.title",
          editDescription:
            "payroll.payrollSettings.payrollSliders.payrollClerks.addPayrollClerks.description",
          emptyDataTitle:
            "payroll.payrollSettings.payrollSliders.payrollClerks.emptyDataTitle",
          emptyDataDescription:
            "payroll.payrollSettings.payrollSliders.payrollClerks.emptyDataDescription",
          emptyButtonLabel:
            "payroll.payrollSettings.payrollSliders.payrollClerks.emptyButtonLabel",
          onClickHandler: editButtonHandler,
        };
      case ROLES_TYPE.PAYROLL_PAYMENT_INITIATORS:
        return {
          headerTitle:
            "payroll.payrollSettings.payrollSliders.paymentInitiators.title",
          headerDescription:
            "payroll.payrollSettings.payrollSliders.paymentInitiators.description",
          listTitle:
            "payroll.payrollSettings.payrollSliders.paymentInitiators.addPaymentInitiators.listOfPaymentInitiators",
          selectLabel: "select",
          editTitle:
            "payroll.payrollSettings.payrollSliders.paymentInitiators.addPaymentInitiators.title",
          editDescription:
            "payroll.payrollSettings.payrollSliders.paymentInitiators.addPaymentInitiators.description",
          emptyDataTitle:
            "payroll.payrollSettings.payrollSliders.paymentInitiators.emptyDataTitle",
          emptyDataDescription:
            "payroll.payrollSettings.payrollSliders.paymentInitiators.emptyDataDescription",
          emptyButtonLabel:
            "payroll.payrollSettings.payrollSliders.paymentInitiators.emptyButtonLabel",
          onClickHandler: editButtonHandler,
        };
      case REIMBURSEMENT_SETTINGS_TYPE.REIM_PAYMENT_INITIATORS:
        return {
          headerTitle: "reimbursement.settings.sliders.paymentInitiators.title",
          headerDescription:
            "reimbursement.settings.sliders.paymentInitiators.description",
          selectLabel: "select",
          listTitle:
            "reimbursement.settings.sliders.paymentInitiators.addPaymentInitiators.listOfPaymentInitiators",
          editTitle:
            "reimbursement.settings.sliders.paymentInitiators.addPaymentInitiators.title",
          editDescription:
            "reimbursement.settings.sliders.paymentInitiators.addPaymentInitiators.description",
          emptyDataTitle:
            "payroll.payrollSettings.payrollSliders.paymentInitiators.addPaymentInitiators.emptyDataTitle",
          emptyDataDescription:
            "payroll.payrollSettings.payrollSliders.paymentInitiators.addPaymentInitiators.emptyDataDescription",
          emptyButtonLabel:
            "payroll.payrollSettings.payrollSliders.paymentInitiators.addPaymentInitiators.emptyButtonLabel",
          onClickHandler: editButtonHandler,
        };
      case ROLES_TYPE.CARD_MANAGER:
        return {
          headerTitle: "cardSetting.cardManagers.title",
          headerDescription:
            "cardSetting.cardManagers.addCardManagers.description1",
          listTitle:
            "cardSetting.cardManagers.addCardManagers.listOfCardManagers",
          selectLabel: "select",
          editTitle: "cardSetting.cardManagers.addCardManagers.title",
          editDescription: "cardSetting.cardManagers.description",
        };
      case ROLES_TYPE.EXPENSE_MANAGER:
        return {
          headerTitle: "cardSetting.expenseManagers.title",
          headerDescription:
            "cardSetting.expenseManagers.addExpenseManagers.description1",
          listTitle:
            "cardSetting.expenseManagers.addExpenseManagers.listOfExpenseManagers",
          selectLabel: "select",
          editTitle: "cardSetting.expenseManagers.addExpenseManagers.title",
          editDescription: "cardSetting.expenseManagers.description",
        };
      default:
        return {
          headerTitle: roleDetails?.name ?? "",
          headerDescription: roleDetails?.description ?? "",
          listTitle: "company.rolesPermissions.tabs.people.listOfPeople" ?? "",
          listTitleTranslationProps: { people: roleDetails?.name },
          selectLabel: "select",
          editTitle: "",
          editDescription: "",
        };
    }
  };

  // Handler for the "Edit" button
  const editButtonHandler = () => {
    // searchParams.append("editable", true); // replaced by state

    setEditable(true);
    const currentExisting = tempExistingList?.filter(
      (obj) => !deletedIds.includes(obj.id)
    );
    setTempExistingList(currentExisting);
  };

  // Handler to discard changes
  const onDiscard = () => {
    // searchParams.delete("editable"); // replaced by state
    if (type === ROLES_TYPE.TOGGLE_PAYROLL) {
      searchParams.delete(ROLES_TYPE.TOGGLE_PAYROLL);
      setSearchParams({});
      return;
    }
    if (useCancelForDiscardEditing) {
      handleCancel();
    }
    setEditable(false);
    setSearchParams(searchParams);
    seteditablePeopleIds([]);
    setDeletedIds([]);
  };

  const onEnablePayrollSuccessfully = () => {
    dispatch(validateToken());
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.payrollPayments.settings.general.togglePayroll
    );

    setSearchParams(searchParams);
  };

  const enablePayrollApiHandler = () => {
    if (!enablePayroll && type === ROLES_TYPE.TOGGLE_PAYROLL) {
      dispatch(enablePayrollSettings({ onEnablePayrollSuccessfully }));
    }
  };

  const onError = () => {
    if (type === ROLES_TYPE.PAYROLL_ADMINS) {
      setSelectedPeople(peopleListItems);
    }
  };

  const onSuccess = ({ toastData, permissions }) => {
    setEditable(false);

    const { addedCount, deletedCount, roleType } = toastData;
    fetchUserList();
    seteditablePeopleIds([]);

    dispatch(fetchRoleDetails({ roleId: parseInt(role?.id, 10) }));

    if ([ROLES_TYPE.PAYROLL_ADMINS, ROLES_TYPE.TOGGLE_PAYROLL].includes(type)) {
      dispatch(refreshUserData({ navigate }));
    }

    const titleTranslationPropData =
      roleType === ROLES_TYPE?.TOGGLE_PAYROLL
        ? { role: ROLES_TYPE.PAYROLL_ADMINS }
        : { role: roleType };

    const descriptionTranslationPropData =
      roleType === ROLES_TYPE?.TOGGLE_PAYROLL
        ? ROLES_TYPE.PAYROLL_ADMINS
        : roleType;

    if (addedCount !== 0) {
      vToast({
        title:
          addedCount > 1
            ? "payroll.payrollSettings.payrollGeneralSettingToast.addedTitle.multiple"
            : "payroll.payrollSettings.payrollGeneralSettingToast.addedTitle.individual",
        titleTranslationProp: titleTranslationPropData,
        description:
          addedCount > 1
            ? "payroll.payrollSettings.payrollGeneralSettingToast.addedDescription.multiple"
            : "payroll.payrollSettings.payrollGeneralSettingToast.addedDescription.individual",
        descriptionTranslationProp: {
          count: addedCount,
          role: descriptionTranslationPropData,
        },
        variant: "success",
      });
    } else if (deletedCount !== 0) {
      vToast({
        title:
          deletedCount > 1
            ? "payroll.payrollSettings.payrollGeneralSettingToast.removedTitle.multiple"
            : "payroll.payrollSettings.payrollGeneralSettingToast.removedTitle.individual",
        titleTranslationProp: titleTranslationPropData,
        description:
          deletedCount > 1
            ? "payroll.payrollSettings.payrollGeneralSettingToast.removedDescription.multiple"
            : "payroll.payrollSettings.payrollGeneralSettingToast.removedDescription.individual",
        descriptionTranslationProp: {
          count: deletedCount,
          role: descriptionTranslationPropData,
        },
        variant: "success",
      });
    }

    const permissionCheckResults = permissionListToCheck.reduce(
      (result, permission) => {
        const foundPermission = permissions?.some(
          (userPerm) =>
            userPerm?.name?.toLowerCase() === permission.toLowerCase()
        );
        result[permission] = foundPermission;

        return result;
      },
      {}
    );

    const {
      [PERMISSIONS.GLOBAL_SETTINGS_MANAGE]: readOnlyView,
      [PERMISSIONS.PAYROLL_ADMINISTRATE]: payrollAdministrativeView,
      [PERMISSIONS.PAYROLL_MANAGE]: payrollManageView,
    } = permissionCheckResults;

    if (
      readOnlyView &&
      !payrollAdministrativeView &&
      context === BILL_PAYROLL_CONTEXT.PAYROLL
    ) {
      setSearchParams();
    }
  };

  const newUserIdsData = [
    ...editablePeopleIds,
    ...selectedPeople.map((data) => data.itemId),
  ];

  const oldUserIdsData = selectedPeople.map((data) => data.itemId);

  // Submit handler
  const onSubmit = () => {
    const { added: addedUserRole = [], removed: deletedUserRole = [] } =
      calculateAddsAndDeletes(newUserIdsData, oldUserIdsData);

    const payloadArr = [
      ...deletedIds.map((userId) => ({ id: userId, _destroy: true })),
      ...addedUserRole.map((userId) => ({ id: userId })),
      ...deletedUserRole.map((userId) => ({ id: userId, _destroy: true })),
    ];

    dispatch(
      updateUserRolesList({
        toastData: {
          addedCount: addedUserRole?.length,
          deletedCount: deletedIds?.length,
          roleType: type,
        },
        payload: {
          role_id: role?.id,
          user_ids: payloadArr,
        },
        onSuccess,
        onError,
        enablePayrollApiHandler,
      })
    );
    setSearchParams(searchParams);

    seteditablePeopleIds([]);
    setDeletedIds([]);
  };

  function fetchUserList() {
    const roleIdsArray = role?.id ? [role?.id] : [];
    if (
      [
        QRPAY_EXPENSES_CONTEXT.EXPENSES,
        CARDS_CONTEXT.CARDS,
        REIMBURSEMENT_CONTEXT,
        BILL_PAYROLL_CONTEXT.BILLPAY,
      ]?.includes(context)
    ) {
      if (adminRoleId) {
        roleIdsArray?.push(adminRoleId);
      }
    }

    if (roleIdsArray?.length > 0) {
      dispatch(
        fetchUserRolesList({
          shallow: true,
          role: roleIdsArray,
        })
      );
    }
  }

  useEffect(() => {
    setOnClose((searchParamArray) => {
      const isClosedPayrollAdminSlider = !searchParamArray?.includes(
        SLIDERS_SEARCH_PARAMS.payrollPayments.settings.general.togglePayroll
      );
      if (
        !enablePayroll &&
        searchParamKey ===
          SLIDERS_SEARCH_PARAMS.payrollPayments.settings.general
            .togglePayroll &&
        !searchParamArray?.length &&
        isClosedPayrollAdminSlider &&
        type === ROLES_TYPE.TOGGLE_PAYROLL
      ) {
        // setOnBack will get called in 2 scenarios, 1st - on clicking on cross icon and 2nd - on clicking on submit button. since we close the slider. so the cleanup should happen in one case i.e by clicking on cross icon
        vToast({
          title:
            "payroll.payrollSettings.payrollSliders.enablePayroll.vToastDisabledTitle",
          description:
            "payroll.payrollSettings.payrollSliders.enablePayroll.vToastDisabledDescription",
          variant: "danger",
        });
        dispatch(setPayrollToggleSwitchState(false));
      }

      setEditable(false);
      dispatch(setUserRolesListInitialState());
    });
  }, [enablePayroll, type]);

  return isShallowFetching ||
    isFetchingExistingList ||
    (isRoleIdNotPresent && type !== ROLES_TYPE.TOGGLE_PAYROLL) ? (
    <div className="px-8">
      {showTitleAndDescription ? (
        <div>
          <LoaderSkeleton size="xl4" borderRadius="200" fullWidth count={1} />
          <LoaderSkeleton
            heigh="md"
            borderRadius="200"
            fullWidth
            classes="mb-8"
          />
        </div>
      ) : null}

      <LoaderSkeleton
        size="lg"
        borderRadius="200"
        fullWidth
        count={5}
        classes="py-4 my-2"
      />
    </div>
  ) : (
    <EditAddPeopleDumb
      allUsers={
        type === ROLES_TYPE.TOGGLE_PAYROLL || type === ROLES_TYPE.PAYROLL_ADMINS
          ? payrollAdminUsers
          : allUsers
      }
      context={context}
      isFetchingExistingList={isFetchingExistingList}
      peopleListItems={peopleListItems}
      peoplePermission={peoplePermission}
      metaData={getMetaData(type)}
      editable={editable}
      editButtonHandler={editButtonHandler}
      showCancelButton={showCancelButton}
      cancelButtonHandler={handleCancel}
      onDiscard={onDiscard}
      onSubmit={onSubmit}
      setEditable={setEditable}
      deletedIds={deletedIds}
      setDeletedIds={setDeletedIds}
      editablePeopleIds={editablePeopleIds}
      seteditablePeopleIds={seteditablePeopleIds}
      selectedPeople={selectedPeople}
      setSelectedPeople={setSelectedPeople}
      type={type}
      dropdownLabelFastView={
        context !== PAYROLL
          ? "payroll.payrollSettings.payrollSliders.userGroups"
          : null
      }
      dropdownLabelFlatView={
        type === ROLES_TYPE.PAYROLL_ADMINS || type === ROLES_TYPE.TOGGLE_PAYROLL
          ? "payroll.payrollSettings.payrollSliders.payrollUsers.addPayrollAdmins.allAdmin"
          : "payroll.payrollSettings.payrollSliders.allUsers"
      }
      groupAdminAndManager={
        [
          BILL_PAYROLL_CONTEXT.BILLPAY,
          REIMBURSEMENT_CONTEXT,
          QRPAY_EXPENSES_CONTEXT.EXPENSES,
          CARDS_CONTEXT.CARDS,
        ].includes(context)
          ? groupAdminAndManager
          : null
      }
      hideButton={readOnlyMode}
      showTitleAndDescription={showTitleAndDescription}
    />
  );
}
EditAddPeopleSmartComponent.propTypes = {
  type: PropTypes.string,
  context: PropTypes.string,
  searchParamKey: PropTypes.string,
  showTitleAndDescription: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  handleCancel: PropTypes.func,
  peoplePermission: PropTypes.bool,
  shallow: PropTypes.bool,
};
