import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  fetchAndSelectCard,
  fetchCardControls,
  getMerchantRestrictions,
  getMerchantShallow,
  setCardSettingsFormInfo,
  updateCardSettingsInfo,
} from "@/store/reducers/cards";
import { fetchCategories } from "@/store/reducers/categories";
import { getCardRulesByRuleId } from "@/store/reducers/rules";

import {
  cardControlsListSelector,
  cardSettingsFormInfoSelector,
  isCardSettingsUpdatingSelector,
  isFetchingMerchantRestrictionsSelector,
  merchantRestrictionsInfoSelector,
  selectedCardProviderDataSelector,
  selectedCardSelector,
  shallowMerchantListSelector,
} from "@/store/selectors/cards";
import { categoriesListSelector } from "@/store/selectors/categories";
import {
  isFetchingRulesSelector,
  rulesListSelector,
} from "@/store/selectors/rules";
import {
  accountingTagsSelector,
  cardsCustomTagsSelector,
} from "@/store/selectors/tags";

import Button from "@/components/core/Button";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";

import AddControlsSection from "@/components/Cards/Sliders/CardMerchantCategoryControls/AddControlsSection";
import {
  computeShowWhen,
  formatLinkedMccRestrictionsApiResponse,
  formatLinkedRulesApiResponse,
  getCardRulesForReview,
  getMccListForPreview,
  getPayloadForUpdatingMccDetails,
  getPayloadForUploadingCardRules,
} from "@/components/Cards/Sliders/CardMerchantCategoryControls/util";
import AddCardRulesSection from "@/components/Cards/Sliders/CardRules/AddCardRulesSection";
import {
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_STRING,
  FIELD_TYPE_SWITCH,
  generatePayloadFromFormValue,
} from "@/components/GenericForm/common";
import DateInputTextBox from "@/components/common/DateInputTextBox";
import EntityListView, {
  renderingListType,
} from "@/components/common/EntityListView";
import EntityListViewLoader from "@/components/common/EntityListView/EntityListViewLoader";
import { useForm } from "@/utils/useForm";
import {
  camelToSnake,
  checkTwoObjectAreExactlySame,
  dateToString,
  extractValuesFromInitialValue,
  getRange,
} from "@/utils/common";

import {
  CARDS_ALL_SLIDER_MENU_KEYS,
  CARDS_SLIDER_ACTION_TYPES,
  CARD_SETTINGS_FORM_FIELD_KEYS,
  CARD_TYPE,
  CARD_TYPES,
} from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

import AddSection from "../Common/AddSection";
import CardControlSwitchField from "./CardControl/CardControlSwitchField";
import CardControlsInputField from "./CardControl/CardControlsInputField";
import EditCardContols from "./ICICI/EditCardContols";
import SettingTabSkelaton from "./SettingTabSkelaton";

export default function CardSettings() {
  const dispatch = useDispatch();
  const ref = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const cardId = searchParams.get(SLIDERS_SEARCH_PARAMS.cards.id);
  const cardSettingsFormInfo = useSelector(cardSettingsFormInfoSelector);
  const categoryList = useSelector(categoriesListSelector);
  const currentLinkedMccRestrictions = useSelector(
    merchantRestrictionsInfoSelector
  );
  const currentLinkedCardRules = useSelector(rulesListSelector);
  const accountingTags = useSelector(accountingTagsSelector);
  const customTags = useSelector(cardsCustomTagsSelector);
  const cardControlsList = useSelector(cardControlsListSelector);
  const cardInfo = useSelector(selectedCardSelector);
  const displayName = cardInfo?.cardHolder?.displayName;

  const autoBlockDate = cardInfo?.autoBlockDate;
  const currency = cardInfo?.currency;
  const cardType = cardInfo?.type;
  const transactionAmountLimit = cardInfo?.transactionAmountLimit;
  const ruleId = cardInfo?.ruleId;
  const isUpadatingCardSettings = useSelector(isCardSettingsUpdatingSelector);
  const notifyLimit = cardInfo?.notifyLimit;
  const notifyLimitAmount = cardInfo?.notifyLimitAmount;
  const [backUpValues, setBackUpValues] = useState({});
  const isFetchingLinkedMerchantRestrictionsInfo = useSelector(
    isFetchingMerchantRestrictionsSelector
  );
  const isFetchingCardRules = useSelector(isFetchingRulesSelector);
  const merchantList = useSelector(shallowMerchantListSelector);

  const selectedCardProviderInfo = useSelector(
    selectedCardProviderDataSelector
  );
  const providerActions = selectedCardProviderInfo?.config?.actions;
  const {
    isTransactionAmountLimitEnabled = false,
    isMccEnabled = false,
    maxAllowedDate = null,
  } = selectedCardProviderInfo?.config ?? {};
  const editTransactionLimit = providerActions?.transactionAmountLimit;
  const editCardControls = providerActions?.cardControls;
  const cardName = cardInfo?.name;

  const staticFieldsInitialValues = {
    ...(cardType === CARD_TYPE.PHYSICAL
      ? {
          [CARD_SETTINGS_FORM_FIELD_KEYS.CARD_HOLDER]: {
            value: displayName,
            validate: {
              required: false,
            },
          },
        }
      : {}),
    ...(cardType === CARD_TYPE.VIRTUAL
      ? {
          [CARD_SETTINGS_FORM_FIELD_KEYS.CARD_NAME]: {
            value: cardName,
            validate: {
              required: true,
            },
          },
        }
      : {}),
    [CARD_SETTINGS_FORM_FIELD_KEYS.EXPIRY]: {
      value: autoBlockDate,
      validate: {
        required: false,
      },
    },
    ...(isTransactionAmountLimitEnabled &&
    editTransactionLimit !== CARDS_SLIDER_ACTION_TYPES.INTENT_URL
      ? {
          [CARD_SETTINGS_FORM_FIELD_KEYS.TRANSACTION_AMOUNT_LIMIT]: {
            value: transactionAmountLimit,
            validate: {
              required: false,
              minNumber: 1,
            },
          },
        }
      : {}),
    [CARD_SETTINGS_FORM_FIELD_KEYS.MERCHANT_AND_CATEGORY]: {
      value: {},
      validate: {
        required: false,
      },
    },
    [CARD_SETTINGS_FORM_FIELD_KEYS.CARD_RULES]: {
      value: {
        id: ruleId,
        tags: getPayloadForUploadingCardRules(
          cardSettingsFormInfo?.[CARD_SETTINGS_FORM_FIELD_KEYS.CARD_RULES]
        ),
      },
    },
    [CARD_SETTINGS_FORM_FIELD_KEYS.MERCHANT_AND_CATEGORY]: {
      value: getPayloadForUpdatingMccDetails(
        cardSettingsFormInfo?.[
          CARD_SETTINGS_FORM_FIELD_KEYS.MERCHANT_AND_CATEGORY
        ]
      ),
    },
    [CARD_SETTINGS_FORM_FIELD_KEYS.LOW_CARD_BALANCE]: {
      value:
        cardSettingsFormInfo?.[
          CARD_SETTINGS_FORM_FIELD_KEYS.LOW_CARD_BALANCE
        ] || !!notifyLimit,
    },
    [CARD_SETTINGS_FORM_FIELD_KEYS.NOTIFY_LOW_CARD_BALANCE]: {
      value:
        cardSettingsFormInfo?.[
          CARD_SETTINGS_FORM_FIELD_KEYS.NOTIFY_LOW_CARD_BALANCE
        ] || notifyLimitAmount,
    },
  };

  const staticFieldsSchema = [
    {
      name: CARD_SETTINGS_FORM_FIELD_KEYS.CARD_HOLDER,
      type: "string",
      label: "cards.cardDetails.editCardDetails.basicDetails.label",
      hide: cardType === CARD_TYPES.VIRTUAL,
    },
    {
      name: CARD_SETTINGS_FORM_FIELD_KEYS.CARD_NAME,
      type: FIELD_TYPE_STRING,
      label: "cards.cardDetails.editCardDetails.basicDetails.cardName",
      hide: cardType === CARD_TYPES.PHYSICAL,
    },
    {
      name: CARD_SETTINGS_FORM_FIELD_KEYS.EXPIRY,
      type: "date",
      label:
        "cards.cardDetails.editCardDetails.basicDetails.autoBlockDate.fieldName",
      mainHeading: cardType === CARD_TYPE.VIRTUAL ? "Advance Options" : null,
      description:
        "cards.cardDetails.editCardDetails.basicDetails.autoBlockDate.description",
      hide: cardType === CARD_TYPE.PHYSICAL,
      maxAllowedDate,
    },
    getTransactionAmountLimitFieldConfig(),
    {
      name: CARD_SETTINGS_FORM_FIELD_KEYS.MERCHANT_AND_CATEGORY,
      type: "merchantAndCategorySection",
      hide: !isMccEnabled,
    },
    {
      name: CARD_SETTINGS_FORM_FIELD_KEYS.CARD_RULES,
      type: "cardRulesSection",
    },
    {
      name: "editCardControls",
      type: "editCardControlsSection",
      hide: !editCardControls,
      onClick:
        editCardControls === CARDS_SLIDER_ACTION_TYPES.INTENT_URL
          ? () => {
              searchParams.set(
                SLIDERS_SEARCH_PARAMS.cards.internationalCardOperationSlider,
                CARDS_ALL_SLIDER_MENU_KEYS.EDIT_CARD_CONTROLS
              );
              setSearchParams(searchParams);
            }
          : () => {},
    },
    {
      name: CARD_SETTINGS_FORM_FIELD_KEYS.LOW_CARD_BALANCE,
      title: "cards.cardDetails.editCardDetails.lowCardBalance.label",
      description: "cards.cardDetails.editCardDetails.lowCardBalance.help",
      type: FIELD_TYPE_SWITCH,
      titleClasses: "text-lg font-semibold",
    },
    {
      name: CARD_SETTINGS_FORM_FIELD_KEYS.NOTIFY_LOW_CARD_BALANCE,
      type: "number",
      rightText: currency,
      label:
        "cards.cardDetails.editCardDetails.lowCardBalance.fields.notificationThreshold.label",
      description:
        "cards.cardDetails.editCardDetails.lowCardBalance.fields.notificationThreshold.help",
    },
  ];

  const [initialValues, formSchema] = useMemo(() => {
    // setting the intial values of card controls
    const cardChannelsSchema = [];
    cardControlsList?.map((cardControlField) => {
      staticFieldsInitialValues[cardControlField?.key] = {
        value: cardControlField?.value,
        validate: cardControlField?.validate,
        errorStatement: cardControlField?.errorStatement,
      };

      cardChannelsSchema?.push({
        name: cardControlField?.key,
        type: cardControlField?.type,
        title: cardControlField?.title,
        description: cardControlField?.description,
        showWhen: cardControlField?.showWhen,
        isEditable: cardControlField?.isEditable,
      });
    });
    staticFieldsSchema?.splice(5, 0, ...(cardChannelsSchema ?? []));

    return [staticFieldsInitialValues, staticFieldsSchema];
  }, [cardControlsList]);

  const {
    handleChange,
    values,
    errors,
    handleSubmit,
    _setValues: setValues,
    isFormButtonDisabled,
  } = useForm(
    initialValues,
    () => {
      handleFormSubmit();
    },
    { formId: "card_settings_click" }
  );

  const merchantAndCategoryControlsPreviewList = useMemo(() => {
    handleChange({
      target: {
        name: CARD_SETTINGS_FORM_FIELD_KEYS.MERCHANT_AND_CATEGORY,
        value: getPayloadForUpdatingMccDetails(
          cardSettingsFormInfo?.[
            CARD_SETTINGS_FORM_FIELD_KEYS.MERCHANT_AND_CATEGORY
          ]
        ),
      },
    });

    return getMccListForPreview(
      cardSettingsFormInfo?.[
        CARD_SETTINGS_FORM_FIELD_KEYS.MERCHANT_AND_CATEGORY
      ],
      categoryList,
      merchantList
    );
  }, [cardSettingsFormInfo, merchantList, categoryList]);

  const cardRulesPreviewList = useMemo(() => {
    const allTags = [...accountingTags, ...customTags];

    handleChange({
      target: {
        value: {
          id: ruleId,
          tags: getPayloadForUploadingCardRules(
            cardSettingsFormInfo?.[CARD_SETTINGS_FORM_FIELD_KEYS.CARD_RULES]
          ),
        },
        name: CARD_SETTINGS_FORM_FIELD_KEYS.CARD_RULES,
      },
    });

    return getCardRulesForReview(
      cardSettingsFormInfo?.[CARD_SETTINGS_FORM_FIELD_KEYS.CARD_RULES],
      allTags
    );
  }, [cardSettingsFormInfo]);

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(getMerchantShallow());
    dispatch(fetchCardControls(cardId));

    fetchCardRules();

    if (
      !currentLinkedMccRestrictions ||
      Object.entries(currentLinkedMccRestrictions).length === 0
    ) {
      fetchMerchantRestrictions();
    }
  }, []);

  useEffect(() => {
    setBackUpValues(values);
  }, [cardInfo, currentLinkedCardRules, currentLinkedMccRestrictions]);

  useEffect(() => {
    if (
      Object.entries(
        cardSettingsFormInfo[
          CARD_SETTINGS_FORM_FIELD_KEYS.MERCHANT_AND_CATEGORY
        ] || {}
      ).length === 0
    ) {
      const formattedLinkedMccRestrictionsResponse =
        formatLinkedMccRestrictionsApiResponse(currentLinkedMccRestrictions);

      dispatch(
        setCardSettingsFormInfo({
          [CARD_SETTINGS_FORM_FIELD_KEYS.MERCHANT_AND_CATEGORY]:
            formattedLinkedMccRestrictionsResponse,
        })
      );
    }
  }, [currentLinkedMccRestrictions]);

  useEffect(() => {
    if (
      Object.entries(
        cardSettingsFormInfo[CARD_SETTINGS_FORM_FIELD_KEYS.CARD_RULES] || {}
      ).length === 0
    ) {
      const formattedResponse = formatLinkedRulesApiResponse(
        currentLinkedCardRules
      );

      dispatch(
        setCardSettingsFormInfo({
          [CARD_SETTINGS_FORM_FIELD_KEYS.CARD_RULES]: formattedResponse,
        })
      );
    }
  }, [currentLinkedCardRules]);

  function openControlsHandler() {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.cards.cardSliderSettings.controlsPage,
      true
    );
    setSearchParams(searchParams);
  }

  function openRulesHandler() {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.cards.cardSliderSettings.rulesPage,
      true
    );
    setSearchParams(searchParams);
  }

  function handleFormSubmit() {
    const updatedValues = {};
    const formValues = Object.values(values);

    Object.keys(values || {}).map((formField, index) => {
      updatedValues[camelToSnake(formField)] = formValues[index];
    });

    const currentLinkedTagsIds = JSON.stringify(
      currentLinkedCardRules?.map((linkedRule) => linkedRule?.tagValueId)
    );

    const newCurrentLinkedTagsIds = JSON.stringify(
      updatedValues?.card_rules?.tags?.map(
        (newLinkedRule) => newLinkedRule?.tag_value_id
      )
    );

    if (
      updatedValues?.card_rules?.tags?.length <= 0 ||
      currentLinkedTagsIds === newCurrentLinkedTagsIds
    ) {
      delete updatedValues?.card_rules;
    }
    // donot want to filter empty value of card_expiry_date
    const cardExpiryDate =
      updatedValues?.[CARD_SETTINGS_FORM_FIELD_KEYS.EXPIRY];

    // donot want to filter empty value of transaction_amount_limit
    const cardTransactionAmount =
      updatedValues?.[CARD_SETTINGS_FORM_FIELD_KEYS.TRANSACTION_AMOUNT_LIMIT];

    const _updatedValues = getCardChannelsWithChangedValues(updatedValues);

    const payloadToSendUpdatedValues = {
      ...generatePayloadFromFormValue(_updatedValues),
      [camelToSnake(CARD_SETTINGS_FORM_FIELD_KEYS.EXPIRY)]: cardExpiryDate,

      [camelToSnake(CARD_SETTINGS_FORM_FIELD_KEYS.TRANSACTION_AMOUNT_LIMIT)]:
        cardTransactionAmount || null,
    };

    dispatch(
      updateCardSettingsInfo({
        id: cardId,
        payload: payloadToSendUpdatedValues,
        onSuccess: onUpdatingSuccess,
      })
    );
  }

  function onUpdatingSuccess() {
    dispatch(
      fetchAndSelectCard({
        cardId,
        onSuccess: (updatedCardDetails) => {
          dispatch(getCardRulesByRuleId(updatedCardDetails?.ruleId));
          fetchMerchantRestrictions();
          dispatch(fetchCardControls(cardId));
        },
      })
    );
  }

  function getCardChannelsWithChangedValues(updatedValues) {
    cardControlsList?.forEach((cardChannel) => {
      const channelKey = cardChannel?.key;
      const previousChannelValue = cardChannel?.value;

      if (values?.[channelKey] === previousChannelValue) {
        delete updatedValues?.[channelKey];
      }
    });

    return updatedValues;
  }

  function checkFieldDisabilityByFieldName(fieldName) {
    switch (fieldName) {
      case CARD_SETTINGS_FORM_FIELD_KEYS.CARD_HOLDER:
        return true;
      case CARD_SETTINGS_FORM_FIELD_KEYS.EXPIRY:
        return false;

      case CARD_SETTINGS_FORM_FIELD_KEYS.NOTIFY_LOW_CARD_BALANCE:
        return !values[CARD_SETTINGS_FORM_FIELD_KEYS.LOW_CARD_BALANCE];

      default:
        return false;
    }
  }
  const onCancel = () => {
    searchParams.delete(SLIDERS_SEARCH_PARAMS.cards.id);
    setSearchParams(searchParams);
  };
  function resetForm() {
    setValues(extractValuesFromInitialValue(initialValues));
  }

  function fetchMerchantRestrictions() {
    dispatch(
      getMerchantRestrictions({
        params: { owner_type: "Card", owner_id: cardId },
      })
    );
  }

  function fetchCardRules() {
    if (ruleId && typeof ruleId !== typeof "") {
      dispatch(getCardRulesByRuleId(ruleId));
    }
  }

  function getTransactionAmountLimitFieldConfig() {
    if (isTransactionAmountLimitEnabled) {
      return {
        name: CARD_SETTINGS_FORM_FIELD_KEYS.TRANSACTION_AMOUNT_LIMIT,
        type:
          editTransactionLimit === CARDS_SLIDER_ACTION_TYPES.INTENT_URL
            ? "transactionAmountLimitSection"
            : "number",
        rightText: currency,
        label:
          "cards.cardDetails.editCardDetails.transactionLimit.addSection.title",
        mainHeading:
          cardType === CARD_TYPE.PHYSICAL
            ? "cards.cardDetails.editCardDetails.transactionLimit.mainHeading"
            : null,
        description:
          "cards.cardDetails.editCardDetails.transactionLimit.description",
        onClick:
          editTransactionLimit === CARDS_SLIDER_ACTION_TYPES.INTENT_URL
            ? () => {
                searchParams.set(
                  SLIDERS_SEARCH_PARAMS.cards.internationalCardOperationSlider,
                  CARDS_ALL_SLIDER_MENU_KEYS.EDIT_TRANSACTION_LIMIT
                );
                setSearchParams(searchParams);
              }
            : () => {},
        clearable: true,
      };
    }

    return {};
  }
  const isDifference = useMemo(() => {
    const extractedValues = extractValuesFromInitialValue(initialValues);
    return !checkTwoObjectAreExactlySame(extractedValues, values);
  }, [JSON.stringify(initialValues), JSON.stringify(values)]);

  return (
    <>
      {!isUpadatingCardSettings ? (
        <div className="flex flex-col p-0 slider-content-core gap-9">
          <form
            onSubmit={handleSubmit}
            id="card_settings_click"
            className="mb-20"
          >
            {formSchema.map((field, index) => {
              const {
                name,
                type,
                title = "",
                description = "",
                label = "",
                isEditable = true,
                mainHeading = null,
                rightText = null,
                hide = false,
                titleClasses = "",
                showWhen,
                onClick,
                maxAllowedDate: _maxAllowedDate = null,
              } = field;

              const disabled = checkFieldDisabilityByFieldName(name);

              let FieldComponent = <></>;

              switch (type) {
                case FIELD_TYPE_STRING:
                case FIELD_TYPE_NUMBER:
                  FieldComponent = (
                    <Input
                      label={label}
                      rightText={rightText}
                      placeholder={label}
                      name={name}
                      type={type}
                      error={errors[name]}
                      value={values[name]}
                      onChange={handleChange}
                      disabled={disabled}
                    />
                  );
                  break;

                case "controlInputField":
                  FieldComponent = computeShowWhen(showWhen, values) ? (
                    <CardControlsInputField
                      name={name}
                      title={title}
                      description={description}
                      handleChange={handleChange}
                      isEditable={isEditable}
                      value={values?.[name]}
                      error={errors?.[name]}
                    />
                  ) : null;
                  break;

                case FIELD_TYPE_SWITCH:
                  FieldComponent = (
                    <CardControlSwitchField
                      name={name}
                      value={values[name]}
                      title={title}
                      description={description}
                      handleChange={handleChange}
                      isEditable={isEditable}
                      titleClasses={titleClasses}
                    />
                  );

                  break;

                case "date": {
                  const dateInputExtraProp = { minDate: new Date() };
                  if (_maxAllowedDate) {
                    const { from, to } = getRange(_maxAllowedDate, []);
                    if (to) {
                      const [day, month, year] = to.split("-");
                      const date = new Date();
                      date.setFullYear(year, month - 1, day);
                      dateInputExtraProp.maxDate = date;
                    }
                  }
                  FieldComponent = (
                    <DateInputTextBox
                      name={name}
                      label={label}
                      disabled={disabled}
                      description={description}
                      value={dateToString(values[name])}
                      dateInputExtraProp={dateInputExtraProp}
                      clearable
                      error={errors?.[name]}
                      handleChange={handleChange}
                    />
                  );
                  break;
                }
                case "transactionAmountLimitSection":
                  FieldComponent = (
                    <AddSection
                      title="cards.cardDetails.editCardDetails.transactionLimit.addSection.title"
                      description="cards.cardDetails.editCardDetails.transactionLimit.addSection.description"
                      onClick={onClick}
                    />
                  );
                  break;

                case "editCardControlsSection":
                  FieldComponent = <EditCardContols onClick={onClick} />;
                  break;

                case "merchantAndCategorySection":
                  FieldComponent = !isFetchingLinkedMerchantRestrictionsInfo ? (
                    merchantAndCategoryControlsPreviewList?.[0]?.list?.length <=
                    0 ? (
                      <AddControlsSection
                        showAddControlsSection
                        openControlsHandler={openControlsHandler}
                      />
                    ) : (
                      <EntityListView
                        list={merchantAndCategoryControlsPreviewList}
                        type={renderingListType.CATEGORIZED}
                        count={
                          merchantAndCategoryControlsPreviewList[0]?.list
                            ?.length || 0
                        }
                        showCount
                        showEditIcon
                        handleEditClick={openControlsHandler}
                        mainHeading="cards.cardDetails.editCardDetails.entityListView.merchantAndCategoryControls.mainHeading"
                        headingDescription="cards.cardDetails.editCardDetails.entityListView.merchantAndCategoryControls.headingDescription"
                        isFetching={isFetchingLinkedMerchantRestrictionsInfo}
                      />
                    )
                  ) : (
                    <EntityListViewLoader />
                  );
                  break;

                case "cardRulesSection":
                  FieldComponent = !isFetchingCardRules ? (
                    cardRulesPreviewList?.length <= 0 ? (
                      <AddCardRulesSection
                        showRulesSection
                        openRulesHandler={openRulesHandler}
                      />
                    ) : (
                      <EntityListView
                        list={cardRulesPreviewList}
                        count={cardRulesPreviewList?.length}
                        type={renderingListType.PLAIN}
                        showCount
                        showEditIcon
                        handleEditClick={openRulesHandler}
                        mainHeading="cards.cardDetails.editCardDetails.entityListView.cardRules.mainHeading"
                        headingDescription="cards.cardDetails.editCardDetails.entityListView.cardRules.headingDescription"
                        isFetching={isFetchingCardRules}
                      />
                    )
                  ) : (
                    <EntityListViewLoader />
                  );
                  break;

                default:
                  break;
              }

              return !hide ? (
                <div key={`${name}-${index}`} className="my-8">
                  {mainHeading ? (
                    <div className="mb-4 mt-9">
                      <Text
                        classes="text-lg font-semibold text-neutral-800"
                        translationKey={mainHeading}
                      />
                    </div>
                  ) : null}

                  {FieldComponent}
                </div>
              ) : null;
            })}
          </form>
        </div>
      ) : (
        <SettingTabSkelaton />
      )}

      <div className="fixed p-6 slider-footer">
        <div className="flex items-center justify-end gap-3">
          {/* if there no difference then show cancel */}
          <Button
            label={isDifference ? "Reset" : "misc.cancel"}
            classes="w-16 text-neutral-500 font-medium"
            variant="tertiary"
            onClick={isDifference ? resetForm : onCancel}
          />

          <Button
            label="Save Changes"
            classes="w-16 text-white font-medium"
            variant="primary"
            btnType="submit"
            disabled={
              isUpadatingCardSettings || isFormButtonDisabled || !isDifference
            }
            form="card_settings_click"
          />
        </div>
      </div>
    </>
  );
}
