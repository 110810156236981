import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateReimbursement } from "@/store/reducers/reimbursement";

import { reimbursementReportsEnabledSelector } from "@/store/selectors/client";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  paymentHistoryFiltersSelector,
  reimbursementPaymentHistoryAmountSelector,
  reimbursementPaymentHistoryHasMoreSelector,
  reimbursementPaymentHistorySelector,
  reimbursementPaymentHistoryTotalListSelector,
  reimbursementPaymentHistoryTotalPaidSelector,
} from "@/store/selectors/reimbursement";
import { userSelector } from "@/store/selectors/user";

import Filters from "@/components/core/Filters";
import Text from "@/components/core/Text";

import Export from "@/components/Exports";
import ReimbursementPaidSliderPaymentHistoryTable from "@/components/Reimbursement/ReimbursementPaymentsSlider/ReimbursementPaidSliderPaymentHistoryTable";
import { handleExport } from "@/utils/exports";
import { amountToCurrency } from "@/utils/common";

import { EXPORT_PAGE, EXPORT_PAGE_TYPE } from "@/constants/exports";

export default function MyVolopayReimbursementPaymentHistory() {
  const dispatch = useDispatch();
  const filters = useSelector(paymentHistoryFiltersSelector);
  const reimbursementPaymentHistory = useSelector(
    reimbursementPaymentHistorySelector
  );
  const currentUser = useSelector(userSelector);
  const hasMore = useSelector(reimbursementPaymentHistoryHasMoreSelector);
  const isEmpty = reimbursementPaymentHistory?.list?.length === 0;
  const totalRows = useSelector(reimbursementPaymentHistoryTotalListSelector);

  const totalPaid = useSelector(reimbursementPaymentHistoryTotalPaidSelector);
  const totalAmount = useSelector(reimbursementPaymentHistoryAmountSelector);
  const appliedFilters = useSelector(appliedFilterSelector);

  const reportEnabled = useSelector(reimbursementReportsEnabledSelector);

  const [sorting, setSorting] = useState({
    type: null,
    category: null,
  });

  // Selected transactions
  const [selectedData, setSelectedData] = useState([]);
  const [deSelectedIds, setDeSelectedIds] = useState([]);
  const [headerSelectAll, setHeaderSelectAll] = useState(false);
  const [bulkSelectAll, setBulkSelectAll] = useState(false);

  const headerSelected = headerSelectAll; // all selected or else unchecked

  const handleRowSelection = (data) => {
    if (selectedData?.includes(data?.id)) {
      setSelectedData((prev) => prev.filter((id) => id !== data?.id));
      setHeaderSelectAll(false);
      if (bulkSelectAll) {
        setDeSelectedIds((prev) => [...prev, data?.id]);
      }
    } else {
      setSelectedData((prev) => [...prev, data?.id]);
      setDeSelectedIds((prev) => prev.filter((id) => id !== data?.id));
    }
    dispatch(
      updateReimbursement({
        id: data.id,
        value: {
          ...data,
          selected: !data.selected,
        },
      })
    );
  };

  const headerSelectionHandler = (checked) => {
    if (checked) {
      if (bulkSelectAll) {
        setDeSelectedIds([]);
      } else {
        setHeaderSelectAll(true);
        setSelectedData(
          reimbursementPaymentHistory?.list?.map((data) => data.id)
        );
      }
    } else {
      setHeaderSelectAll(false);
      setSelectedData([]);
    }
  };

  useEffect(() => {
    // When user selects - 'Select all transactions'
    if (bulkSelectAll) {
      setHeaderSelectAll(true);
      if (!deSelectedIds.length) {
        setSelectedData(
          reimbursementPaymentHistory?.list?.map((data) => data.id)
        );
      } else {
        const filteredIds = reimbursementPaymentHistory?.list
          ?.map((data) => data.id)
          .filter((id) => !deSelectedIds.includes(id));
        setSelectedData(filteredIds);
      }
    }
  }, [bulkSelectAll, reimbursementPaymentHistory?.list, deSelectedIds]);

  const exportHandler = () => {
    handleExport(false, selectedData?.length, appliedFilters, dispatch, {
      ...(bulkSelectAll
        ? {
            ...EXPORT_PAGE_TYPE.REIMBURSEMENT_SETTLEMENT,
            deselected_ids: deSelectedIds,
          }
        : { selected_ids: selectedData }),
      export_type: EXPORT_PAGE.REIMBURSEMENT_SETTLEMENT,
    });
  };

  const clearSelection = () => {
    setBulkSelectAll(false);
    setHeaderSelectAll(false);
    setSelectedData([]);
    setDeSelectedIds([]);
    dispatch(
      updateReimbursement(
        reimbursementPaymentHistory?.list?.map((payment) => {
          return { ...payment, selected: !payment.selected };
        })
      )
    );
  };

  const multiSelectData = {
    headerSelected,
    headerSelectAll,
    bulkSelectAll,
    setBulkSelectAll,
    selectedData,
    isEmpty,
    headerSelectionHandler,
    handleRowSelection,
    exportHandler,
    totalReimbursementsSelected: totalRows,
    clearSelection,
    deSelectedIds,
    setDeSelectedIds,
  };

  return (
    <div className="px-11">
      <div className="flex items-center justify-between mb-6 ">
        <div>
          <div className="flex gap-2 font-semibold text-neutral-500 font-sm">
            <Text translationKey="myVolopay.title" />
            <Text translationKey="/" noTranslate />
            <Text translationKey="myVolopay.reimbursements.title" />
          </div>
          <Text
            classes="text-4xl font-extrabold"
            translationKey="myVolopay.reimbursements.childNav.paymentHistory"
          />
        </div>
      </div>
      <section className="mb-12">
        <div className="flex items-center justify-between">
          <div className="w-full">
            <Filters filters={filters} />
          </div>
          <div className="flex justify-end ">
            <Export
              totalExports={totalRows}
              storeName="reimbursement"
              sectionName="reimbursements"
              // selectedFilter={appliedFilters}
              additionalFilters={{
                ...EXPORT_PAGE_TYPE.REIMBURSEMENT_SETTLEMENT,
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5 w-[404px] rounded-xl ">
          <div className="flex flex-col card-wrapper">
            <Text
              translationKey="myVolopay.reimbursements.unsettledAmount"
              classes="text-sm font-semibold text-neutral-500"
            />

            <Text
              classes="text-neutral-800 font-semibold"
              translationKey={amountToCurrency(
                totalAmount?.value || 0,
                totalAmount?.currency
              )}
            />
          </div>
          <div className="flex flex-col card-wrapper">
            <Text
              translationKey={
                reportEnabled
                  ? "reimbursement.payments.sliderHeadings.totalReports"
                  : "myVolopay.reimbursements.unsettledClaims"
              }
              classes="text-sm font-semibold text-neutral-500"
            />

            <Text
              classes="text-neutral-800 font-semibold"
              translationKey={totalPaid || 0}
            />
          </div>
        </div>
        <div className="mt-6" id="tabContent">
          <ReimbursementPaidSliderPaymentHistoryTable
            data={reimbursementPaymentHistory}
            sorting={sorting}
            setSorting={setSorting}
            reportEnabled={reportEnabled}
            multiSelectData={multiSelectData}
            userId={currentUser?.id}
            showMultiSelect
            extraApiParam={{ total_amount_required: true }}
          />
        </div>
      </section>
    </div>
  );
}
