import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { elementScroll, useVirtualizer } from "@tanstack/react-virtual";
import EmptyData from "@/components/core/EmptyData";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import IndeterminateCheckbox from "@/components/core/VirtualizedTable/IndeterminateCheckbox";
import {
  getCommonPinningStyles,
  getMissingOrFalseKeys,
} from "@/components/core/VirtualizedTable/helper";

import TableLoader from "@/components/Accounting/Ledger/LedgerTable/TableLoader";

import { SORTING_TYPE } from "@/utils/constants/sorting";

const CHECKBOX_ROW_WIDTH = 50;
/**
 * This function is use to smooth scroll flow during virtualization it scroll might move very frequently can be fixed my using this function
 * The ease-in-out quintic interpolation function.
 * @param {number} t - The input from 0 to 1.
 * @returns {number} The interpolated value from 0 to 1.
 * @see https://easings.net/#easeInOutQuint
 */
function easeInOutQuint(t) {
  return t < 0.5 ? 16 * t * t * t * t * t : 1 - (-2 * t + 2) ** 5 / 2;
}
//  styles are impoted of same Table component
// src/components/core/Table/styles.scss // this is component
/**
 * A dynamic row height table component with sticky headers and columns, virtual scrolling, and row selection.
 * https://tanstack.com/virtual/v3/docs/examples/react/table
 * @param {Object} props - The component props.
 * @param {string} props.id - The ID of the table.
 * @param {Array} props.data - The data for the table.
 * @param {Array} props.headerConfig - The configuration for table headers.
 * @param {Function} props.getCellComponent - Function to get the cell component.
 * @param {Array} props.colWidths - The widths of the columns.
 * @param {number} props.numberOfStickyColsLeft - The number of sticky columns on the left.
 * @param {number} props.numberOfStickyColsRight - The number of sticky columns on the right.
 * @param {boolean} props.headerSticky - Whether the header should be sticky.
 * @param {Function} props.onRowClick - Function to handle row clicks.
 * @param {boolean} props.isLoading - Whether the table is loading.
 * @param {Function} props.handleRefChange - Function to handle reference changes.
 * @param {boolean} props.bulkApproveVisible - Whether bulk approve is visible.
 * @param {ReactNode} props.bulkApproveContent - The content for bulk approve.
 * @param {string} props.bulkApproveWidth - The width for bulk approve.
 * @param {string} props.bulkApproveHeight - The height for bulk approve.
 * @param {string} props.bulkApproveTransform - The transform for bulk approve.
 * @param {Function} props.onSortingChange - Function to handle sorting changes.
 * @param {boolean} props.showCheckBoxRow - Whether to show the checkbox row.
 * @param {Function} props.getEnableRowSelection - Function to determine if row selection is enabled.
 * @param {Function} props.getEnableRowClick - Function to determine if row click is enabled.
 * @param {Function} props.getRowId - Function to get the row ID.
 * @param {boolean} props.selectAllRows - Whether to select all rows.
 * @param {Function} props.onRowSelectionChange - Function to handle row selection changes.
 * @param {React.Ref} ref - The ref for the table component.
 * @returns {JSX.Element} - The rendered table component.
 */
const VirtualizedTable = (props, ref) => {
  const {
    id,
    data,
    headerConfig: _headerConfig,
    getCellComponent,
    colWidths: _colWidths,
    numberOfStickyColsLeft: _numberOfStickyColsLeft,
    numberOfStickyColsRight,
    headerSticky,
    onRowClick,
    isLoading = true,
    handleRefChange,
    bulkApproveVisible,
    bulkApproveContent,
    bulkApproveWidth,
    bulkApproveHeight,
    bulkApproveTransform,
    onSortingChange = () => {},
    showCheckBoxRow = true,
    getEnableRowSelection = () => true,
    getEnableRowClick = () => true,
    getRowId,
    selectAllRows = true,
    onRowSelectionChange = () => {},
    estimatedRowHeight = 33,
    heightOfTable = "100vh",
    isActionLoading,
    rightColWidths,
    extraCellClass = () => "",
    tableRowClasses = () => "",
    emptyDataTitle = "common.defaultEmptyVirtualTable",
    emptyDataDescription = "",
    emptyDataDescriptionProps = {},
    emptyDataChildren = null,
    emptyDataIconSrc = "",
    tableLoader = null,
  } = props;
  const [sorting, setSorting] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const previousRowsSelected = useRef({});
  const deselectedRowsSelected = useRef([]);
  // The virtualizer needs to know the scrollable container element
  const tableContainerRef = useRef(null);
  const numberOfStickyColsLeft = showCheckBoxRow
    ? _numberOfStickyColsLeft
      ? _numberOfStickyColsLeft + 1
      : _numberOfStickyColsLeft
    : _numberOfStickyColsLeft;

  const headerConfig = useMemo(
    () =>
      showCheckBoxRow
        ? [
            {
              id: "select",
              header: ({ table }) => (
                <div className="flex items-center h-full px-1">
                  <IndeterminateCheckbox
                    {...{
                      checked: table.getIsAllRowsSelected(),
                      indeterminate: table.getIsSomeRowsSelected(),
                      onChange: table.getToggleAllRowsSelectedHandler(),
                    }}
                  />
                </div>
              ),
              cell: ({ row }) =>
                row?.getCanSelect() ? (
                  <div className="flex items-center px-1">
                    <IndeterminateCheckbox
                      {...{
                        checked: row.getIsSelected(),
                        disabled: !row.getCanSelect(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                      }}
                    />
                  </div>
                ) : null,
            },
          ].concat(_headerConfig)
        : _headerConfig,
    [showCheckBoxRow, JSON.stringify(_headerConfig)]
  );
  const colWidths = showCheckBoxRow
    ? [CHECKBOX_ROW_WIDTH]?.concat(_colWidths)
    : _colWidths;

  const extraSpaceLeft = useMemo(() => {
    const updatedCol = colWidths?.slice(
      numberOfStickyColsLeft || 0,
      colWidths.length - (numberOfStickyColsRight || 0)
    );
    const sumOfColWidths = updatedCol?.reduce((a, b) => a + b, 0);
    const tableWidth = tableContainerRef.current?.clientWidth;
    const _rightTableWidths = colWidths?.slice(0, numberOfStickyColsLeft);
    const _rightColSum = _rightTableWidths?.length
      ? _rightTableWidths?.reduce((a, b) => a + b, 0)
      : 0;
    const _leftColWidths = colWidths?.slice(
      colWidths.length - (numberOfStickyColsRight || 0),
      colWidths.length
    );
    const _leftColSum = _leftColWidths?.length
      ? _leftColWidths?.reduce((a, b) => a + b, 0)
      : 0;
    const extraSpace =
      (tableWidth - sumOfColWidths - _rightColSum - _leftColSum) /
      (updatedCol?.length ?? 0);
    return extraSpace < 0 || extraSpace === Infinity ? 0 : extraSpace;
  }, [JSON.stringify(_colWidths), tableContainerRef.current?.clientWidth]);

  const columnPinning = {
    left: numberOfStickyColsLeft
      ? headerConfig?.slice(0, numberOfStickyColsLeft)?.map((item) => item.id)
      : null,
    right: numberOfStickyColsRight
      ? headerConfig
          ?.slice(
            headerConfig.length - numberOfStickyColsRight,
            headerConfig.length
          )
          ?.map((i) => i?.id)
      : null,
  };

  const columns = useMemo(
    () =>
      headerConfig?.map((item, index, arr) => ({
        accessorFn: (info) => info?.[item?.id],
        id: item.id,
        header: (args) =>
          item?.header ? (
            item?.header(args)
          ) : (
            <div className={`text-xs font-semibold ${item?.classes}`}>
              <Text
                translationKey={item?.label || item?.title}
                translationProps={item?.labelTranslationProps || {}}
              />
            </div>
          ),
        enableSorting: item?.sortable ?? false,
        cell: (args) =>
          item?.cell
            ? item?.cell(args)
            : getCellComponent(args, item.id, item, isActionLoading),
        ...(colWidths?.[index] ? { size: colWidths?.[index] } : {}),
        ...(rightColWidths?.[arr.length - 1 - index]
          ? { size: rightColWidths?.[arr.length - 1 - index] }
          : {}),
      })) || [],
    [showCheckBoxRow, JSON.stringify(headerConfig), isActionLoading]
  );

  const table = useReactTable({
    data,
    defaultColumn: {
      size: "auto",
    },
    columns,
    initialState: {
      columnPinning,
    },
    state: {
      rowSelection,
      sorting,
    },
    getRowId,
    enableRowSelection: (row) => getEnableRowSelection(row),
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { rows } = table.getRowModel();

  const scrollingRef = useRef();
  const scrollToFn = useCallback((offset, canSmooth, instance) => {
    const duration = 500;
    const start = tableContainerRef.current?.scrollTop || 0;
    // eslint-disable-next-line no-multi-assign
    const startTime = (scrollingRef.current = Date.now());

    const run = () => {
      if (scrollingRef.current !== startTime) return;
      const now = Date.now();
      const elapsed = now - startTime;
      const progress = easeInOutQuint(Math.min(elapsed / duration, 1));
      const interpolated = start + (offset - start) * progress;

      if (elapsed < duration) {
        requestAnimationFrame(run);
      }
      elementScroll(interpolated, canSmooth, instance);
    };

    requestAnimationFrame(run);
  }, []);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => estimatedRowHeight,
    getScrollElement: () => tableContainerRef.current,
    // measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
    scrollToFn,
  });

  useEffect(() => {
    onSortingChange({
      category: sorting?.[0]?.id,
      type: sorting?.[0]?.id
        ? sorting?.[0]?.desc
          ? SORTING_TYPE.DEC
          : SORTING_TYPE.INC
        : null,
    });
  }, [JSON.stringify(sorting)]);

  useEffect(() => {
    let deselectedIds = [];

    if (selectAllRows) {
      const currentlyDeselectedRows = getMissingOrFalseKeys(
        previousRowsSelected.current,
        rowSelection
      );
      const rowIds = new Set(Object.keys(rowSelection));
      const _correctDeselected = deselectedRowsSelected.current?.filter(
        (row) => !rowIds.has(row)
      );
      deselectedIds = [
        ...new Set([..._correctDeselected, ...currentlyDeselectedRows]),
      ];
      deselectedRowsSelected.current = deselectedIds;
      previousRowsSelected.current = rowSelection;
    }
    onRowSelectionChange({
      selectedRows: rowSelection,
      selectedRowsArrayOfObject: table
        .getSelectedRowModel()
        .rows?.map((row) => row?.original),
      deselectedRowsIds: deselectedIds,
      deselectedRowsIdsSet: new Set(deselectedIds),
      selectAllRows,
      isHeaderSelected: table.getIsAllRowsSelected(),
    });
  }, [JSON.stringify(rowSelection), selectAllRows]);

  useEffect(() => {
    const deselectedRowsSet = new Set(deselectedRowsSelected.current);

    const allRowIds = rows
      .filter((row) => row.getCanSelect())
      .map((row) => row?.id);

    if (selectAllRows) {
      const newSelection = allRowIds.reduce(
        (acc, rowId) => ({
          ...acc,
          [rowId]: !deselectedRowsSet.has(`${rowId}`),
        }),
        {}
      );

      table.setRowSelection(newSelection);
    }
  }, [JSON.stringify(data)]);

  useImperativeHandle(ref, () => ({
    clearSelection: () => {
      table.setRowSelection({});
      deselectedRowsSelected.current = [];
    },
  }));

  const dynamicLoaderWidthAsPerHeader = useMemo(
    () =>
      table
        .getHeaderGroups()
        .flatMap((headerGroup) =>
          headerGroup?.headers.map((header, i) =>
            (showCheckBoxRow && i === 0) || header?.column?.getIsPinned()
              ? header.getSize()
              : header.getSize() + extraSpaceLeft
          )
        )
        .flat(),
    [table, showCheckBoxRow, extraSpaceLeft]
  );
  const outerWidth = useRef(null);

  // All important CSS styles are included as inline styles for this example. This is not recommended for your code.
  return !data?.length && !isLoading ? (
    <div ref={outerWidth} className="w-full app">
      <div
        className="!w-full vp-core-table-container vp-core-table-2-container"
        ref={tableContainerRef}
        style={{
          // height: heightOfTable, // should be a fixed height
          minHeight: "auto",
          maxHeight: heightOfTable,
          height: "auto",
        }}
      >
        <table className="vp-core-table-html-table">
          <thead
            className={`vp-core-table-header vp-core-table-2-header ${
              headerSticky ? "vp-core-table-sticky-header" : ""
            }`}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                className="vp-core-table-row vp-core-table-header-row vp-core-table-2-header-row"
                key={headerGroup.id}
              >
                {headerGroup.headers.map((header, i, arr) => {
                  return (
                    <th
                      className={`vp-core-table-cell vp-core-table-header-cell
                        vp-core-table-sticky-item vp-core-table-sticky-header-cell-left ${
                          i === numberOfStickyColsLeft - 1
                            ? "vp-core-table-sticky-left-inner-cell vp-core-table-sticky-header-left-inner-cell"
                            : i === arr.length - numberOfStickyColsRight
                              ? "vp-core-table-sticky-right-inner-cell vp-core-table-sticky-header-right-inner-cell"
                              : ""
                        } ${
                          header.column.getCanSort()
                            ? "select-none cursor-pointer"
                            : ""
                        } flex items-center`}
                      {...(header.column.getCanSort()
                        ? { onClick: header.column.getToggleSortingHandler() }
                        : {})}
                      key={`header-${header.id}-${i}`}
                      style={{
                        flexGrow: 1,
                        ...getCommonPinningStyles(header?.column),
                        width:
                          (showCheckBoxRow && i === 0) ||
                          header?.column?.getIsPinned()
                            ? header.getSize()
                            : header.getSize() + extraSpaceLeft,
                      }}
                    >
                      {header.column.getCanSort() ? (
                        <div
                          key={`header-can-sort-asc-${header.id}`}
                          className="flex flex-col justify-center mr-3 "
                        >
                          <Icon
                            className={
                              header.column.getIsSorted() === SORTING_TYPE.INC
                                ? "text-neutral-500"
                                : "text-neutral-300"
                            }
                            name="ArrowDropUp"
                          />
                          <Icon
                            className={
                              header.column.getIsSorted() === SORTING_TYPE.DEC
                                ? "text-neutral-500"
                                : "text-neutral-300"
                            }
                            name="ArrowDropDown"
                          />
                        </div>
                      ) : null}
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
        </table>
        <div className="w-full h-full py-[calc(10%)]">
          <EmptyData
            title={emptyDataTitle}
            imgSrc={emptyDataIconSrc || undefined}
          >
            <Text
              translationKey={emptyDataDescription}
              translationProps={emptyDataDescriptionProps}
              classes="text-sm text-neutral-800 font-medium text-center"
            />
            {emptyDataChildren}
          </EmptyData>
        </div>
      </div>
    </div>
  ) : (
    <div className="w-full app">
      {bulkApproveVisible && bulkApproveContent ? (
        <div
          className="fixed left-[20%] z-[99] rounded-xl bg-white border-neutral-200 border-[1px] shadow-sm flex overflow-hidden"
          style={{
            width: bulkApproveWidth || "60%",
            height: bulkApproveHeight,
            transform: bulkApproveTransform,
            boxShadow: "5px 5px 15px rgba(0,0,0,0.1)",
            top: window.innerHeight * 0.85,
          }}
        >
          {bulkApproveContent}
        </div>
      ) : null}
      <div
        className="!w-full vp-core-table-container vp-core-table-2-container"
        ref={tableContainerRef}
        style={{
          // height: heightOfTable, // should be a fixed height
          minHeight: "auto",
          maxHeight: heightOfTable,
          height: "auto",
        }}
      >
        {/* Even though we're still using sematic table tags, we must use CSS grid and flexbox for dynamic row heights */}
        <table
          id={id}
          className="vp-core-table-html-table"
          style={{ display: "grid" }}
        >
          <thead
            className={`vp-core-table-header vp-core-table-2-header z-20 ${
              headerSticky ? "vp-core-table-sticky-header" : ""
            }`}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                className="vp-core-table-row vp-core-table-header-row vp-core-table-2-header-row"
                key={headerGroup.id}
              >
                {headerGroup.headers.map((header, i, arr) => {
                  return (
                    <th
                      className={`vp-core-table-cell vp-core-table-header-cell
                        vp-core-table-sticky-item vp-core-table-sticky-header-cell-left ${
                          i === numberOfStickyColsLeft - 1
                            ? "vp-core-table-sticky-left-inner-cell vp-core-table-sticky-header-left-inner-cell"
                            : i === arr.length - numberOfStickyColsRight
                              ? "vp-core-table-sticky-right-inner-cell vp-core-table-sticky-header-right-inner-cell"
                              : ""
                        } ${
                          header.column.getCanSort()
                            ? "select-none cursor-pointer"
                            : ""
                        } flex items-center`}
                      {...(header.column.getCanSort()
                        ? { onClick: header.column.getToggleSortingHandler() }
                        : {})}
                      key={`header-${header.id}-${i}`}
                      style={{
                        flexGrow: 1,
                        ...getCommonPinningStyles(header?.column),
                        width:
                          (showCheckBoxRow && i === 0) ||
                          header?.column?.getIsPinned()
                            ? header.getSize()
                            : header.getSize() + extraSpaceLeft,
                      }}
                    >
                      {header.column.getCanSort() ? (
                        <div
                          key={`header-can-sort-asc-${header.id}`}
                          className="flex flex-col justify-center mr-3 "
                        >
                          <Icon
                            className={
                              header.column.getIsSorted() === SORTING_TYPE.INC
                                ? "text-neutral-500"
                                : "text-neutral-300"
                            }
                            name="ArrowDropUp"
                          />
                          <Icon
                            className={
                              header.column.getIsSorted() === SORTING_TYPE.DEC
                                ? "text-neutral-500"
                                : "text-neutral-300"
                            }
                            name="ArrowDropDown"
                          />
                        </div>
                      ) : null}
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody
            className="vp-core-table-body"
            style={{
              display: "grid",
              height: `${rowVirtualizer.getTotalSize()}px`, // tells scrollbar how big the table is
              position: "relative", // needed for absolute positioning of rows
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = rows[virtualRow.index];

              return (
                <>
                  <tr
                    className={`vp-core-table-row vp-core-table-body-row ${
                      tableRowClasses(row?.original) || ""
                    }`}
                    data-index={virtualRow.index} // needed for dynamic row height measurement
                    ref={(node) => {
                      handleRefChange(node, row?.index);

                      return rowVirtualizer.measureElement(node);
                    }} // measure dynamic row height
                    key={`vp-core-table-row-${row.id}`}
                    style={{
                      display: "flex",
                      position: "absolute",
                      transform: `translateY(${virtualRow.start}px)`, // this should always be a `style` as it changes on scroll
                      width: "100%",
                    }}
                  >
                    {row.getVisibleCells().map((cell, i, arr) => {
                      return (
                        <td
                          className={`vp-core-table-cell vp-core-table-body-cell
                            vp-core-table-sticky-item vp-core-table-sticky-body-cell-left ${
                              i === numberOfStickyColsLeft - 1
                                ? "vp-core-table-sticky-left-inner-cell vp-core-table-sticky-body-left-inner-cell"
                                : i === arr.length - numberOfStickyColsRight
                                  ? " vp-core-table-sticky-right-inner-cell vp-core-table-sticky-body-right-inner-cell "
                                  : ""
                            }
                            } ${
                              showCheckBoxRow && row.getIsSelected()
                                ? "selected-row-cell"
                                : ""
                            } items-center flex ${
                              extraCellClass(
                                cell?.row?.original,
                                headerConfig?.find(
                                  (item) => item?.id === cell?.column?.id
                                )
                              ) || ""
                            } ${cell.column.getSize() + extraSpaceLeft}`}
                          key={`${cell.id}-${i}`}
                          style={{
                            backgroundColor: "white",
                            flexGrow: 1,
                            ...getCommonPinningStyles(cell?.column),
                            width:
                              (showCheckBoxRow && i === 0) ||
                              cell?.column?.getIsPinned()
                                ? cell.column.getSize()
                                : cell.column.getSize() + extraSpaceLeft,
                          }}
                        >
                          <div
                            className="w-full max-w-full"
                            onClick={() => {
                              return ((showCheckBoxRow &&
                                cell?.column?.id !== "select") ||
                                !showCheckBoxRow) &&
                                getEnableRowClick(
                                  cell?.column?.id,
                                  row?.original
                                )
                                ? onRowClick(row?.original)
                                : () => {};
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </tbody>
          {isLoading ? (
            <TableLoader
              checkBoxWidth={CHECKBOX_ROW_WIDTH}
              extraSpaceLeft={extraSpaceLeft}
              columns={headerConfig}
              colWidths={dynamicLoaderWidthAsPerHeader}
              showCheckBoxRow={showCheckBoxRow}
            />
          ) : null}
        </table>
      </div>
    </div>
  );
};
export default React.forwardRef(VirtualizedTable);

VirtualizedTable.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headerConfig: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      header: PropTypes.func,
      cell: PropTypes.func,
      sortable: PropTypes.bool,
    })
  ).isRequired,
  getCellComponent: PropTypes.func.isRequired,
  colWidths: PropTypes.arrayOf(PropTypes.number),
  numberOfStickyColsLeft: PropTypes.number,
  numberOfStickyColsRight: PropTypes.number,
  headerSticky: PropTypes.bool,
  onRowClick: PropTypes.func,
  isLoading: PropTypes.bool,
  handleRefChange: PropTypes.func,
  bulkApproveVisible: PropTypes.bool,
  bulkApproveContent: PropTypes.node,
  bulkApproveWidth: PropTypes.string,
  bulkApproveHeight: PropTypes.string,
  bulkApproveTransform: PropTypes.string,
  onSortingChange: PropTypes.func,
  showCheckBoxRow: PropTypes.bool,
  getEnableRowSelection: PropTypes.func,
  getRowId: PropTypes.func.isRequired,
  selectAllRows: PropTypes.bool,
  onRowSelectionChange: PropTypes.func,
  getEnableRowClick: PropTypes.func,
};
