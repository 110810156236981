import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  checkDuplicateReimbursement,
  fetchAndSelectReimbursement,
  fetchClaimCategoryLimitData,
  fetchMileageRateList,
  setCreatingduplicateReimbursement,
  setSelectedReimbursement,
  setSelectedReport,
} from "@/store/reducers/reimbursement";

import { reimbursementReportsEnabledSelector } from "@/store/selectors/client";
import { selectedReimbursementSelector } from "@/store/selectors/reimbursement";

import Tabs from "@/components/core/Tabs";

import ApprovalSliderHeader from "@/components/Reimbursement/ApprovalSlider/ApprovalSliderHeader";
import PaymentSliderFooter from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/PaymentSliderFooter";
import ModuleHistory from "@/components/common/ModuleHistory";
import {
  API_END_PAGE_TYPE,
  CONST_TYPE_REIMBURSEMENT,
} from "@/utils/constants/reimbursement";
import {
  APPROVAL_OR_PAYMENT_STATUS_COLOR,
  APPROVAL_OR_PAYMENT_STATUS_TEXT,
} from "@/utils/constants/payments";
import { amountToCurrency } from "@/utils/common";

import { OWNER_TYPE_MASTER_HISTORY } from "@/utils/constants/app";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { TABS } from "@/constants/common";
import { REIMBURSEMENT_CONTEXT } from "@/constants/reimbursement";

import ApprovalOverviewTab from "./ApprovalOverviewTab";

const selectedTabs = TABS;

export default function ReimbursementSlider({
  setOnClose,
  searchParamKey,
  closeDrawer,
}) {
  const [pageType, setPageType] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const reimbursement = useSelector((state) =>
    selectedReimbursementSelector(state)
  );

  const [sliderId, setSliderId] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState(selectedTabs[0]);

  const reportEnabled = useSelector(reimbursementReportsEnabledSelector);

  useEffect(() => {
    if (sliderId || pageType) {
      dispatch(
        fetchAndSelectReimbursement({
          reimId: parseInt(sliderId, 10),
          type: API_END_PAGE_TYPE[pageType],
        })
      );
    }
  }, [sliderId, pageType]);

  useEffect(() => {
    dispatch(setSelectedReimbursement(null));
  }, [pageType]);

  const getLeftHeader = () => {
    if (reimbursement?.merchant?.name) {
      return {
        title: reimbursement?.merchant?.name,
        img: reimbursement?.createdBy?.colorCode,
      };
    }
    if (reimbursement?.distanceTravelled) {
      return {
        title: t("reimbursement.approvals.sliderHeadings.distanceTravelled", {
          distanceTravelled: reimbursement?.distanceTravelled,
        }),
        icon: "Location",
        iconStyle:
          "bg-warning-50 text-warning-500 p-2 border-[1px] border-warning-100 w-10 h-10 flex justify-center text-sm  items-center rounded-full",
      };
    }
  };

  const ref = useLeftHeaderTitle(getLeftHeader(), { root: null }, true);

  const hideFooterCondition = reportEnabled || selectedTab.key !== 1;
  const slider = useRef();

  const scrollToComments = () => {
    slider.current.scrollTop = slider.current.scrollHeight;
  };

  useEffect(() => {
    setSliderId(
      searchParams
        .getAll(SLIDERS_SEARCH_PARAMS.reimbursements.approveId)
        .at(-1) ||
        searchParams.get(SLIDERS_SEARCH_PARAMS.reimbursements.activeClaimId) ||
        searchParams.get(SLIDERS_SEARCH_PARAMS.reimbursements.historyClaimId) ||
        searchParams.get(SLIDERS_SEARCH_PARAMS.reimbursements.claimId)
    );
    setPageType(
      searchParams.get(SLIDERS_SEARCH_PARAMS.reimbursements.pageType)
    );

    setOnClose((searchParamArray) => {
      const isClosed = !searchParamArray?.includes(searchParamKey);

      if (isClosed) {
        dispatch(setSelectedReimbursement(null));
        dispatch(setCreatingduplicateReimbursement(null));
        if (reportEnabled) dispatch(setSelectedReport(null));
      }
    });
  }, [searchParams]);

  useEffect(() => {
    if (!reimbursement) return;
    const timeout = setTimeout(() => {
      if (
        (reimbursement?.type === CONST_TYPE_REIMBURSEMENT.mileage &&
          reimbursement?.travelDate &&
          reimbursement?.distanceTravelled) ||
        (reimbursement?.type === CONST_TYPE_REIMBURSEMENT.outOfPocket &&
          reimbursement?.claimAmount?.currency &&
          reimbursement?.claimAmount?.value &&
          reimbursement?.transactionDate &&
          reimbursement?.merchant)
      ) {
        const {
          travelDate,
          distanceTravelled,
          claimAmount,
          transactionDate,
          merchant,
        } = reimbursement;
        const mileageObject = {
          travel_date: travelDate,
          total_miles: distanceTravelled,
          current_id: sliderId,
        };
        const outOfPocketObject = {
          transaction_currency: claimAmount?.currency,
          transaction_amount: claimAmount?.value,
          transaction_date: transactionDate,
          merchant: merchant?.name,
          current_id: sliderId,
        };

        dispatch(
          checkDuplicateReimbursement({
            reimbursement_type: reimbursement?.type,
            ...(reimbursement?.type === CONST_TYPE_REIMBURSEMENT.mileage
              ? mileageObject
              : outOfPocketObject),

            ...(reimbursement?.createdBy?.id
              ? { user_id: reimbursement?.createdBy?.id }
              : {}),
          })
        );
      }
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [reimbursement]);

  useEffect(() => {
    if (
      reimbursement?.claimAmount?.value &&
      reimbursement?.claimAmount?.currency &&
      reimbursement?.linkedTo?.id &&
      reimbursement?.category?.id
    ) {
      dispatch(
        fetchClaimCategoryLimitData({
          amount: reimbursement?.claimAmount?.value,
          currency: reimbursement?.claimAmount?.currency,
          project_id: reimbursement?.linkedTo?.id,
          category_id: reimbursement?.category?.id,
        })
      );
      dispatch(fetchMileageRateList());
    }
  }, [reimbursement]);

  return (
    reimbursement && (
      <div ref={ref} className="slider-content-container">
        <div ref={slider} className="slider-content-core scroll-smooth">
          <div>
            <ApprovalSliderHeader
              header={amountToCurrency(
                reimbursement?.amount?.value,
                reimbursement?.amount?.currency
              )}
              badgeText={
                APPROVAL_OR_PAYMENT_STATUS_TEXT[
                  reimbursement?.transactionStatus ?? reimbursement?.status
                ]
              }
              badgeColor={
                APPROVAL_OR_PAYMENT_STATUS_COLOR[
                  reimbursement?.transactionStatus ?? reimbursement?.status
                ]
              }
              submitedDate={reimbursement?.submissionDate}
            />
          </div>

          <div className="my-4">
            <Tabs
              items={selectedTabs}
              selectedTab={selectedTab?.key}
              setCurrentTab={(tab) => setSelectedTab(tab)}
              mode
            />
            <div className="" id="tabContent">
              {selectedTab.key === 1 ? (
                <ApprovalOverviewTab data={reimbursement} />
              ) : (
                <ModuleHistory
                  ownerId={reimbursement?.id}
                  ownerType={OWNER_TYPE_MASTER_HISTORY.REIMBURSEMENT}
                />
              )}
            </div>
          </div>
        </div>

        {hideFooterCondition ? null : (
          <div className="px-6 py-4 slider-footer">
            <PaymentSliderFooter
              context={REIMBURSEMENT_CONTEXT}
              payment={reimbursement}
              tabType={pageType}
              closeDrawer={closeDrawer}
              scrollToComments={scrollToComments}
            />
          </div>
        )}
      </div>
    )
  );
}

ReimbursementSlider.propTypes = {
  setOnClose: PropTypes.func,
  searchParamKey: PropTypes.string,
  closeDrawer: PropTypes.func,
};
