import PropTypes from "prop-types";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { availableModulesSelector } from "@/store/selectors/user";
import { dontShowUnsettledInCreditSelector } from "@/store/selectors/client";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import BalanceCard from "@/components/Company/Overview/MyAccounts/shared/BalanceCard";
import { amountToCurrency, dateToString } from "@/utils/common";

import { MODULES } from "@/utils/constants/app";
import { ROUTES } from "@/constants/routes";

export default function CreditAccountWidget({ creditDetails, companyName }) {
  const dontShowUnsettledInCredit = useSelector(
    dontShowUnsettledInCreditSelector
  );
  const navigate = useNavigate();
  const availableModule = useSelector(availableModulesSelector);
  const creditDetailsArray = useMemo(
    () =>
      [
        {
          key: "dashboard.myAccounts.monthlyCreditLimit",
          translationKey: "company.credit.creditLimit",
          tooltipKey: "dashboard.myAccounts.monthlyCreditLimitTooltip",
          tooltipProps: { companyName },
          currencyValue: creditDetails?.creditLimit,
          currencyType: creditDetails?.currency,
          spanClass: "text-sm font-semibold text-neutral-800",
          tooltipId: "dashboard-myAccounts-monthlyCreditLimit",
          show: true,
        },
        {
          key: "dashboard.myAccounts.upComingDueOn",
          translationKey: "company.credit.dueAsOnToday",
          tooltipKey: "dashboard.cashback.upComingDueOnTooltip",
          tooltipProps: {
            date: dateToString(creditDetails?.creditBill?.dueDate),
          },
          currencyValue:
            parseFloat(creditDetails?.creditLimit) -
            parseFloat(creditDetails?.creditAvailableBalance?.value),
          currencyType: creditDetails?.currency,
          spanClass: "text-sm font-semibold text-warning-500",
          tooltipId: "dashboard-cashback-upComingDueOn",
          show: creditDetails?.creditBill,
        },
        {
          key: "dashboard.myAccounts.unsettledExpenses",
          translationKey: "dashboard.myAccounts.unsettledExpenses",
          tooltipKey: "dashboard.myAccounts.unsettledExpensesTooltip",
          currencyValue: creditDetails?.unsettledAmount,
          currencyType: creditDetails?.currency,
          spanClass: "text-sm font-semibold text-danger-600",
          tooltipId: "dashboard-myAccounts-unsettledExpenses",
          show:
            !dontShowUnsettledInCredit &&
            availableModule?.includes(MODULES.CARDS),
        },
      ]?.filter((item) => item.show),
    [JSON.stringify(creditDetails), JSON.stringify(availableModule)]
  );

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between" id="upper">
        <div className="flex items-center gap-8">
          <BalanceCard
            label="company.credit.totalOutstanding"
            amount={
              parseFloat(creditDetails?.creditLimit) -
              parseFloat(creditDetails?.creditAvailableBalance?.value)
            }
            currency={creditDetails?.currency}
            showCr
          />
          <BalanceCard
            label="company.credit.availableLimit"
            amount={creditDetails?.creditAvailableBalance?.value}
            currency={creditDetails?.currency}
          />
        </div>
        <div id="right">
          <div
            className="min-w-[100px] flex flex-col gap-2 items-center hover:cursor-pointer select-none"
            onClick={() => {
              navigate(ROUTES.dashboard.credit.absolutePath);
            }}
          >
            <div className="flex items-center justify-center w-10 h-10 rounded-full bg-primary-50">
              <Icon name="ArrowForward" className="text-primary-500" />
            </div>
            <Text
              translationKey="dashboard.cashback.viewDetails"
              classes="text-xs font-semibold text-neutral-500"
            />
          </div>
        </div>
      </div>
      <div
        id="lower"
        className="flex flex-col gap-2 p-4 rounded-md bg-neutral-50"
      >
        {/*
         */}
        {creditDetailsArray.map(
          ({
            key,
            translationKey,
            tooltipKey,
            tooltipProps,
            currencyValue,
            currencyType,
            spanClass,
            tooltipId,
          }) => (
            <div className="flex items-center justify-between" key={key}>
              <div className="flex items-center gap-2">
                <span className="text-sm font-medium text-neutral-500">
                  <Text translationKey={translationKey} />
                </span>
                <div id={tooltipId} className="-ml-1">
                  <Icon
                    name="Info"
                    className="text-neutral-300 p-[1.33px] h-4 w-4 hover:cursor-pointer"
                  />
                  <Tooltip id={tooltipId} direction="right">
                    <Text
                      translationKey={tooltipKey}
                      translationProps={tooltipProps}
                      classes="text-sm font-medium text-neutral-500"
                    />
                  </Tooltip>
                </div>
              </div>
              <span className={spanClass}>
                {amountToCurrency(currencyValue, currencyType)}
              </span>
            </div>
          )
        )}
      </div>
    </div>
  );
}

CreditAccountWidget.propTypes = {
  creditDetails: PropTypes.object,
  companyName: PropTypes.string,
};
