import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import {
  budgetedPaidOutsideEnabledSelector,
  cardsClaimSettlementEnabledSelector,
} from "@/store/selectors/client";
import { selectedReimbursementUserCanSettleViaCardSelector } from "@/store/selectors/reimbursement";
import Button from "@/components/core/Button";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import PayOutsideMoreOption from "@/components/Reimbursement/ReimbursementPaymentsSlider/PayOutsideMoreOption";
import { amountToCurrency } from "@/utils/common";

export default function ReimbursementPaymentsSliderFooter({
  totalAmount,
  currency,
  count,
  payOutsideVolopayCtaHandler,
  disablePayCta,
  payViaVolopayCtaHandler,
  paySettleViaCardCtaHandler,
}) {
  const budgetedPaidOutsideEnabled = useSelector(
    budgetedPaidOutsideEnabledSelector
  );

  const disablePayOutsideVolopayCta = budgetedPaidOutsideEnabled
    ? disablePayCta
    : null;

  const isShowPayViaCard = useSelector(cardsClaimSettlementEnabledSelector);
  const isShowSettleViaCard = useSelector(
    selectedReimbursementUserCanSettleViaCardSelector
  );

  const moreOptionConfig = [
    {
      label: "reimbursement.payments.sliderFooter.payOutsideVolopay",
      onClick: payOutsideVolopayCtaHandler,
      buttonProps: {},
    },
    {
      label: "reimbursement.payments.sliderFooter.addToAmountCard",
      hide: !isShowPayViaCard,
      onClick: paySettleViaCardCtaHandler,
      buttonProps: isShowSettleViaCard
        ? {}
        : {
            disabled: true,
            tooltipText:
              "reimbursement.payments.sliderFooter.addToAmountCardWarningTooltip",
            tooltipProps: {
              direction: "left",
              maxWidth: "400px",
            },
          },
    },
  ];

  return (
    <div className="flex items-center justify-between ">
      <div>
        {count !== 0 ? (
          <div className="flex gap-2">
            <Text
              classes="text-sm"
              translationKey="reimbursement.payments.sliderFooter.footerText"
            />
            <Text
              classes="text-sm"
              translationKey={`${amountToCurrency(totalAmount, currency)}`}
            />
          </div>
        ) : null}
      </div>

      <div className="flex gap-2">
        <PayOutsideMoreOption
          config={moreOptionConfig}
          disabled={count === 0 || disablePayOutsideVolopayCta ? true : false}
        />

        <Button
          id="payViaVolopayButton"
          onClick={payViaVolopayCtaHandler}
          disabled={count === 0 || disablePayCta}
          counter={count === 0 || disablePayCta ? null : count}
          classes="w-18"
          label="reimbursement.payments.sliderFooter.payNowButton"
        />
        {disablePayCta ? (
          <Tooltip id="payViaVolopayButton" direction="bottom" maxWidth="30rem">
            <Text translationKey="reimbursement.payments.sliderHeadings.disabledPayViaVolopayCtaTooltip" />
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
}
ReimbursementPaymentsSliderFooter.propTypes = {
  totalAmount: PropTypes.string,
  currency: PropTypes.string,
  count: PropTypes.number,
  payOutsideVolopayCtaHandler: PropTypes.func,
  payViaVolopayCtaHandler: PropTypes.func,
  paySettleViaCardCtaHandler: PropTypes.func,
  disablePayCta: PropTypes.bool,
};
