import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLoadingErrorInjector from "@/hooks/useErrorLoader";
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  fetchOnboardingStepsForVirtualCard,
  getMerchantRestrictions,
  setCreateVirtualCardInput,
} from "@/store/reducers/cards";
import { fetchShallowProjects } from "@/store/reducers/company";

import {
  createVirtualCardInputSelector,
  merchantRestrictionsInfoSelector,
  orderVirtualCardStepsSelector,
  shallowMerchantListSelector,
} from "@/store/selectors/cards";
import { categoriesListSelector } from "@/store/selectors/categories";
import { projectsSelector } from "@/store/selectors/company";
import {
  accountingTagsSelector,
  customTagsSelector,
} from "@/store/selectors/tags";
import { selectedUserSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import FileUpload from "@/components/core/FileUpload";
import Input from "@/components/core/Input";
import Text from "@/components/core/Text";
import VpSelect from "@/components/core/VpSelect";

import LinkCardToField from "@/components/Cards/Sliders/CreateVirtualCardSlider/LinkedCardToField";
import { INLINE_GROUP_TYPE_NAME } from "@/components/GenericForm/common";
import DateInputTextBox from "@/components/common/DateInputTextBox";
import EntityListView, {
  renderingListType,
} from "@/components/common/EntityListView";
import vToast from "@/utils/vToast";
import { useForm } from "@/utils/useForm";
import {
  capitalizeFirstAndLowercaseRest,
  dateToString,
  getRange,
} from "@/utils/common";

import {
  CREATE_VIRTUAL_CARD_FIELD_KEYS,
  LINKED_TO_RADIO_OPTIONS,
  LINKED_TO_TYPE,
} from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { DATEINPUT_MODES } from "@/constants/date";
import { API_KEYS_MAPPING } from "@/api/apiKeys";

import AddControlsSection from "../CardMerchantCategoryControls/AddControlsSection";
import {
  formatLinkedMccRestrictionsApiResponse,
  getCardRulesForReview,
  getMccListForPreview,
} from "../CardMerchantCategoryControls/util";
import AddCardRulesSection from "../CardRules/AddCardRulesSection";

function CreateVirtualCard() {
  const [searchParam, setSearchParam] = useSearchParams();

  const dispatch = useDispatch();

  const ref = useLeftHeaderTitle({
    title: "cards.vCards.createCardSlider.title",
  });

  const { t } = useTranslation();

  const isCardForSelectedUser = searchParam.get(
    SLIDERS_SEARCH_PARAMS.cards.selectedUserCard
  );
  const virtualCardsSteps = useSelector(orderVirtualCardStepsSelector);

  const allState = useLoadingErrorInjector({
    apiKey: API_KEYS_MAPPING.GET_CREATE_VIRTUAL_CARD_STEPS,
    showLoader: false,
  });

  const createVirtualCardFormResponse = useSelector(
    createVirtualCardInputSelector
  );

  const currentLinkedMccRestrictions = useSelector(
    merchantRestrictionsInfoSelector
  );

  const selectedUser = useSelector(selectedUserSelector);

  const categoryList = useSelector(categoriesListSelector);

  const merchantList = useSelector(shallowMerchantListSelector);

  const accountingTags = useSelector(accountingTagsSelector);

  const customTags = useSelector(customTagsSelector);

  const generalSection = virtualCardsSteps?.sections
    ? virtualCardsSteps?.sections[0]?.fields
    : [];

  const advancedControls = virtualCardsSteps?.sections
    ? virtualCardsSteps?.sections[1]?.fields
    : [];

  const cardHoldersOptions = generalSection?.[0]?.options;

  const totalList = [...generalSection, ...advancedControls];

  const [frequency, setFrequency] = useState([]);

  const fieldsConfig = useMemo(() => {
    const config = {};

    const allSections = [...generalSection, ...advancedControls];

    allSections?.forEach((sectionField) => {
      if (sectionField?.type === INLINE_GROUP_TYPE_NAME) {
        sectionField?.fields?.map((inlineGroupField) => {
          appendFieldInConfig(config, inlineGroupField);
        });
      } else {
        appendFieldInConfig(config, sectionField);
      }
    });

    return config;
  }, [virtualCardsSteps, createVirtualCardFormResponse]);

  const merchantAndCategoryControlsList = useMemo(() => {
    return getMccListForPreview(
      createVirtualCardFormResponse?.[
        CREATE_VIRTUAL_CARD_FIELD_KEYS.MERCHANT_CATEGORY_CONTROL
      ],
      categoryList,
      merchantList
    );
  }, [createVirtualCardFormResponse]);

  const cardRulesList = useMemo(() => {
    return getCardRulesForReview(
      createVirtualCardFormResponse?.[
        CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_RULES
      ],
      [...accountingTags, ...customTags]
    );
  }, [
    createVirtualCardFormResponse?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_RULES],
  ]);

  const cardsHolderOptions =
    fieldsConfig[CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_HOLDER]?.options;

  const currencyOptions =
    fieldsConfig[CREATE_VIRTUAL_CARD_FIELD_KEYS.CURRENCY]?.options;

  const linkedToMainLabel =
    fieldsConfig[CREATE_VIRTUAL_CARD_FIELD_KEYS.LINKED_TO]?.label;

  const linkedToMainDescription =
    fieldsConfig[CREATE_VIRTUAL_CARD_FIELD_KEYS.LINKED_TO]?.description;

  const projectsList = useSelector(projectsSelector);

  const {
    handleChange,
    values,
    errors,
    handleSubmit,
    _setValues: setValues,
    isFormButtonDisabled,
  } = useForm(fieldsConfig, handleFormSubmit);

  useEffect(() => {
    if (isCardForSelectedUser) {
      setValues((prev) => {
        const updatedValue = {
          ...prev,
          [CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_HOLDER]: +isCardForSelectedUser,
        };
        return updatedValue;
      });
    }
  }, [selectedUser, fieldsConfig, isCardForSelectedUser]);

  useEffect(() => {
    dispatch(fetchOnboardingStepsForVirtualCard());
    if (
      !currentLinkedMccRestrictions ||
      Object.entries(currentLinkedMccRestrictions).length === 0
    ) {
      dispatch(
        getMerchantRestrictions({
          params: { owner_type: "Client", send_editable: true },
        })
      );
    }
  }, []);

  useEffect(() => {
    if (frequency?.length === 1) {
      setValues((prevValues) => ({
        ...prevValues,
        [CREATE_VIRTUAL_CARD_FIELD_KEYS.FREQUENCY]: frequency?.[0]?.key,
      }));
    }
  }, [frequency]);

  useEffect(() => {
    if (
      Object.entries(
        createVirtualCardFormResponse[
          CREATE_VIRTUAL_CARD_FIELD_KEYS.MERCHANT_CATEGORY_CONTROL
        ] || {}
      ).length === 0
    ) {
      const formattedLinkedMccRestrictionsResponse =
        formatLinkedMccRestrictionsApiResponse(currentLinkedMccRestrictions);
      dispatch(
        setCreateVirtualCardInput({
          ...values,
          [CREATE_VIRTUAL_CARD_FIELD_KEYS.MERCHANT_CATEGORY_CONTROL]:
            formattedLinkedMccRestrictionsResponse,
        })
      );
    }
  }, [currentLinkedMccRestrictions]);

  useEffect(() => {
    const formValuesObject = {
      ...createVirtualCardFormResponse,
      [CREATE_VIRTUAL_CARD_FIELD_KEYS.LINKED_TO]:
        values[CREATE_VIRTUAL_CARD_FIELD_KEYS.LINKED_TO],
    };

    if (currencyOptions?.length <= 1) {
      formValuesObject[CREATE_VIRTUAL_CARD_FIELD_KEYS.CURRENCY] =
        currencyOptions[0]?.key;
    }

    if (cardsHolderOptions?.length <= 1) {
      formValuesObject[CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_HOLDER] =
        cardsHolderOptions[0]?.key;
    }

    if (currencyOptions?.length <= 1 || cardsHolderOptions?.length <= 1) {
      setValues((prev) => ({ ...prev, ...formValuesObject }));
    }
  }, [currencyOptions, cardsHolderOptions]);

  useEffect(() => {
    if (values[CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_HOLDER]) {
      const departmentId = cardHoldersOptions?.find((userInfo) => {
        return (
          userInfo?.key === values?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_HOLDER]
        );
      })?.departmentId;

      setValues((prev) => ({
        ...prev,
        [CREATE_VIRTUAL_CARD_FIELD_KEYS.PROJECT_ID]:
          values?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.LINKED_TO] ===
          LINKED_TO_TYPE?.DEPARTMENT.toLowerCase()
            ? departmentId
            : null,
      }));

      dispatch(
        fetchShallowProjects({
          user: values[CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_HOLDER],
        })
      );
    }
  }, [
    values[CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_HOLDER],
    values[CREATE_VIRTUAL_CARD_FIELD_KEYS.LINKED_TO],
  ]);

  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      [CREATE_VIRTUAL_CARD_FIELD_KEYS.LINKED_TO]:
        createVirtualCardFormResponse?.[
          CREATE_VIRTUAL_CARD_FIELD_KEYS.LINKED_TO
        ],
    }));
  }, [
    createVirtualCardFormResponse?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.LINKED_TO],
  ]);

  useEffect(() => {
    if (
      values?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.LINKED_TO] !==
        LINKED_TO_TYPE.PROJECT.toLowerCase() &&
      createVirtualCardFormResponse?.[
        CREATE_VIRTUAL_CARD_FIELD_KEYS.LINKED_TO
      ] !== LINKED_TO_TYPE.PROJECT.toLowerCase()
    ) {
      handleLinkedToField({
        target: {
          name: CREATE_VIRTUAL_CARD_FIELD_KEYS.LINKED_TO,
          value: LINKED_TO_TYPE.DEPARTMENT.toLowerCase(),
        },
      });
    }
  }, []);

  function openControlsHandler() {
    dispatch(setCreateVirtualCardInput(values));
    searchParam.set(
      SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.controlsPage,
      true
    );
    setSearchParam(searchParam);
  }

  function openRulesHandler() {
    dispatch(setCreateVirtualCardInput(values));
    searchParam.set(
      SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.rulesPage,
      true
    );
    setSearchParam(searchParam);
  }

  function handleFormSubmit() {
    dispatch(setCreateVirtualCardInput(values));
    searchParam.append(
      SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.reviewVCardDetails,
      true
    );
    setSearchParam(searchParam);
  }

  function handleLinkedToField(e) {
    if (e) {
      setValues({
        ...values,
        [CREATE_VIRTUAL_CARD_FIELD_KEYS.LINKED_TO]: e.target.value,
        [CREATE_VIRTUAL_CARD_FIELD_KEYS.PROJECT_ID]: null,
      });
    }
  }

  function handleDeleteFile(index) {
    values[CREATE_VIRTUAL_CARD_FIELD_KEYS.DOCUMENT]?.splice(index, 1);
    setValues({
      ...values,
      [CREATE_VIRTUAL_CARD_FIELD_KEYS.DOCUMENT]:
        values[CREATE_VIRTUAL_CARD_FIELD_KEYS.DOCUMENT],
    });
  }

  function handleLinkedToRadioButtonsChange(e) {
    handleChange({
      target: {
        name: CREATE_VIRTUAL_CARD_FIELD_KEYS.PROJECT_ID,
        value: e.target.value,
      },
    });
  }

  function computeShowWhen(showWhenObject) {
    if (!showWhenObject) return true;
    function fieldsComparision(field1, field2, bool) {
      if (bool) {
        return field1 === field2;
      }
      return field1 !== field2;
    }

    const [_andComparatorObj] = Object.values(showWhenObject);

    const showField = _andComparatorObj?.reduce(
      (boolChecker, currentComparatorFieldObj) => {
        const [currentComparedField, condtionBool] = Object.entries(
          currentComparatorFieldObj
        );

        return (
          boolChecker &&
          fieldsComparision(
            values[currentComparedField?.[0]],
            currentComparedField[1],
            condtionBool[1]
          )
        );
      },
      true
    );
    return showField;
  }

  function appendFieldInConfig(config, sectionField) {
    const sectionFieldKey = sectionField?.key;
    const type = sectionField?.type;
    const keyRequired = sectionField?.required;
    const keyOptions = sectionField?.options;
    const keyLabel = sectionField?.label;
    const keyDescription = sectionField?.description;
    const showWhen = sectionField?.showWhen;
    const minNumber = sectionField?.minNumber ?? 0;

    if (sectionFieldKey === CREATE_VIRTUAL_CARD_FIELD_KEYS.FREQUENCY) {
      setFrequency(sectionField?.options);
    }

    config[sectionFieldKey] = {
      options: keyOptions,
      label: keyLabel,
      description: keyDescription,
      type,
      name: sectionFieldKey,
      value: null,
      showWhen,
      validate: {
        required: keyRequired,
      },
    };

    if (
      [
        CREATE_VIRTUAL_CARD_FIELD_KEYS.TRANSACTION_AMOUNT_LIMIT,
        CREATE_VIRTUAL_CARD_FIELD_KEYS.AMOUNT,
      ].includes(sectionFieldKey)
    ) {
      config[sectionFieldKey].validate.minNumber = minNumber;
      config[sectionFieldKey].errorStatement = {
        minNumber: t("cards.vCards.createCardSlider.formErrors.minNumberText", {
          minNumber,
        }),
      };
    }
  }

  function getOptionsForSelectField(fieldName, fieldInfo) {
    const optionsConfig = {
      valueKey: undefined,
      optionsDisplayKey: undefined,
      options: [],
      disabled: false,
    };

    switch (fieldName) {
      case CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_HOLDER:
        optionsConfig.valueKey = "key";
        optionsConfig.optionsDisplayKey = "value";
        optionsConfig.options = cardsHolderOptions;
        optionsConfig.disabled =
          cardsHolderOptions?.length <= 1 || isCardForSelectedUser;
        break;

      case CREATE_VIRTUAL_CARD_FIELD_KEYS.CURRENCY:
        optionsConfig.options = currencyOptions;
        optionsConfig.valueKey = "key";
        optionsConfig.optionsDisplayKey = "value";
        optionsConfig.disabled = currencyOptions?.length <= 1;
        break;

      case CREATE_VIRTUAL_CARD_FIELD_KEYS.FREQUENCY:
        optionsConfig.options = fieldInfo?.options?.map((option) => ({
          ...option,
          value: capitalizeFirstAndLowercaseRest(option?.value ?? ""),
        }));

        optionsConfig.valueKey = "key";
        optionsConfig.optionsDisplayKey = "value";
        optionsConfig.disabled = frequency?.length === 1;
        break;

      default:
        break;
    }

    return optionsConfig;
  }

  function handleImageUpload(e) {
    if (e.target.value.length <= 10) {
      const lastInsertedImage =
        e?.target?.value?.[e?.target?.value?.length ?? 1 - 1]?.path;
      const isImagesDuplicated = e?.target?.value?.find(
        (image, index) =>
          index < (e?.target?.value?.length ?? 1 - 1) &&
          lastInsertedImage === image?.path
      );

      if (isImagesDuplicated) {
        vToast({
          title:
            "cards.cardDetails.editCardDetails.fileUpload.imageDuplicatedTitle",
          description:
            "cards.cardDetails.editCardDetails.fileUpload.imageDuplicatedDescription",
          variant: "danger",
        });
        e?.target?.value?.pop();
      }
      handleChange(e);
    } else {
      vToast({
        title:
          "cards.cardDetails.editCardDetails.fileUpload.attachmentsMaxCountTitle",
        description:
          "cards.cardDetails.editCardDetails.fileUpload.attachmentMaxCountDescription",
        variant: "danger",
      });
      e?.target?.value?.pop();
      handleChange(e);
    }
  }

  function getFieldByType(field, grouped) {
    const fieldType = field?.type;
    const name = field?.key;
    const value = values?.[name];
    const error = errors?.[name];
    const label = field?.label;
    const type = field?.type;
    const maxAllowedDate = field?.maxAllowedDate;
    const description = field?.description;
    const optionsConfigReponse = getOptionsForSelectField(field?.key, field);
    const valueKey = optionsConfigReponse?.valueKey;
    const optionsDisplayKey = optionsConfigReponse?.optionsDisplayKey;
    const options = optionsConfigReponse?.options;
    const disabled = optionsConfigReponse?.disabled;
    const rightText =
      name === CREATE_VIRTUAL_CARD_FIELD_KEYS.AMOUNT ||
      name === CREATE_VIRTUAL_CARD_FIELD_KEYS.TRANSACTION_AMOUNT_LIMIT
        ? values[CREATE_VIRTUAL_CARD_FIELD_KEYS.CURRENCY]
        : "";

    const show =
      name === CREATE_VIRTUAL_CARD_FIELD_KEYS.CURRENCY
        ? currencyOptions?.length > 1
        : true;

    let FieldComponent = <></>;

    switch (fieldType) {
      case "number":
      case "text":
        FieldComponent = (
          <Input
            name={name}
            value={value}
            error={error}
            label={label}
            description={description}
            type={type}
            onChange={handleChange}
            rightText={rightText}
            classes={grouped ? " mt-2 " : ""}
          />
        );

        break;

      case "select":
        FieldComponent = (
          <VpSelect
            label={label}
            placeholder={label}
            options={options}
            name={name}
            handleChange={handleChange}
            error={error}
            value={value}
            menuPosition="absolute"
            valueKey={valueKey}
            optionsDisplayKey={optionsDisplayKey}
            classes={grouped ? " mt-2" : ""}
            disabled={disabled}
            insideForm
          />
        );
        break;

      case "radio":
        FieldComponent = (
          <LinkCardToField
            name={name}
            handleChange={handleLinkedToField}
            values={values}
            value={values[CREATE_VIRTUAL_CARD_FIELD_KEYS.LINKED_TO]}
            linkedToFields={{
              radioOptions: LINKED_TO_RADIO_OPTIONS,
              selectOption: {
                name: CREATE_VIRTUAL_CARD_FIELD_KEYS.PROJECT_ID,
                label: "Select Project",
              },
              handleLinkedToRadioButtonsChange,
            }}
            projectsList={projectsList}
            linkedToMainDescription={linkedToMainDescription}
            linkedToMainLabel={linkedToMainLabel}
            error={errors[CREATE_VIRTUAL_CARD_FIELD_KEYS?.PROJECT_ID]}
          />
        );
        break;

      case "date": {
        const dateInputExtraProp = { minDate: new Date() };
        if (maxAllowedDate) {
          const { from, to } = getRange(maxAllowedDate, []);
          if (to) {
            const [day, month, year] = to.split("-");
            const date = new Date();
            date.setFullYear(year, month - 1, day);
            dateInputExtraProp.maxDate = date;
          }
        }
        FieldComponent = (
          <div className="relative w-full">
            <DateInputTextBox
              name={CREATE_VIRTUAL_CARD_FIELD_KEYS.AUTO_BLOCK_DATE}
              classes="w-full"
              description={description}
              value={dateToString(
                values[CREATE_VIRTUAL_CARD_FIELD_KEYS.AUTO_BLOCK_DATE]
              )}
              dateInputExtraProp={dateInputExtraProp}
              error={errors?.[CREATE_VIRTUAL_CARD_FIELD_KEYS.AUTO_BLOCK_DATE]}
              handleChange={handleChange}
              label={label}
              mode={DATEINPUT_MODES.DATE}
            />
          </div>
        );

        break;
      }
      case "fileUpload":
        FieldComponent = (
          <div className="pt-2">
            <div className="flex flex-row items-center gap-1 mb-3">
              <Text
                translationKey="common.document"
                classes="text-neutral-800 text-lg font-semibold"
              />
              <Text
                translationKey="(optional)"
                classes="font-semibold text-neutral-500"
              />
            </div>

            <FileUpload
              accept={{
                "text/csv": [".pdf"],
                image: [".png", ".gif", ".jpg", ".jpeg"],
              }}
              name={CREATE_VIRTUAL_CARD_FIELD_KEYS.DOCUMENT}
              files={values[CREATE_VIRTUAL_CARD_FIELD_KEYS.DOCUMENT] || []}
              handleFileChange={(e) => {
                handleImageUpload(e);
              }}
              acceptText="common.pngJpgPdfAllowed"
              primaryAction={{
                handler: (index) => handleDeleteFile(index),
                label: "Delete",
                icon: "Delete",
                iconClasses: "text-danger-600 bg-danger-50",
              }}
              secondaryActionDisabled
              insideForm
              formError={errors[CREATE_VIRTUAL_CARD_FIELD_KEYS.DOCUMENT]}
            />
          </div>
        );
        break;

      case "merchantAndCategoryControlSection":
        FieldComponent = computeShowWhen(
          fieldsConfig[CREATE_VIRTUAL_CARD_FIELD_KEYS.MERCHANT_CATEGORY_CONTROL]
            ?.showWhen
        ) ? (
          <>
            <AddControlsSection
              showAddControlsSection={
                fieldsConfig[
                  CREATE_VIRTUAL_CARD_FIELD_KEYS.MERCHANT_CATEGORY_CONTROL
                ] && merchantAndCategoryControlsList[0]?.list?.length === 0
              }
              openControlsHandler={openControlsHandler}
            />

            {merchantAndCategoryControlsList?.[0]?.list?.length > 0 ? (
              <EntityListView
                list={merchantAndCategoryControlsList}
                type={renderingListType.CATEGORIZED}
                count={merchantAndCategoryControlsList[0]?.list?.length || 0}
                showCount
                showEditIcon
                handleEditClick={openControlsHandler}
                mainHeading="cards.cardDetails.editCardDetails.entityListView.merchantAndCategoryControls.mainHeading"
                headingDescription="cards.cardDetails.editCardDetails.entityListView.merchantAndCategoryControls.headingDescription"
              />
            ) : null}
          </>
        ) : null;
        break;

      case "cardRulesSection":
        FieldComponent = computeShowWhen(
          fieldsConfig[CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_RULES]?.showWhen
        ) ? (
          <div>
            <AddCardRulesSection
              openRulesHandler={openRulesHandler}
              showRulesSection={
                fieldsConfig[CREATE_VIRTUAL_CARD_FIELD_KEYS.CARD_RULES] &&
                cardRulesList?.length === 0
              }
            />

            {cardRulesList?.length > 0 ? (
              <EntityListView
                list={cardRulesList}
                mainHeading="cards.cardDetails.editCardDetails.entityListView.cardRules.mainHeading"
                headingDescription="cards.cardDetails.editCardDetails.entityListView.cardRules.headingDescription"
                type={renderingListType.PLAIN}
                count={cardRulesList?.length || 0}
                showCount
                showEditIcon
                handleEditClick={openRulesHandler}
              />
            ) : null}
          </div>
        ) : null;
        break;

      default:
        break;
    }
    return show ? (
      <div className={`mb-6 ${grouped ? "w-full mt-2" : ""}`}>
        {name === CREATE_VIRTUAL_CARD_FIELD_KEYS.AUTO_BLOCK_DATE ? (
          <p className="mb-3 text-neutral-500">
            <Text
              translationKey="cards.cardDetails.editCardDetails.advancedControls.label"
              classes="text-neutral-800 text-lg font-semibold"
            />
            &nbsp;(
            <Text
              translationKey="cards.cardDetails.editCardDetails.advancedControls.optional"
              classes="text-base font-semibold"
            />
            )
          </p>
        ) : null}

        {FieldComponent}
      </div>
    ) : null;
  }

  function closeSlider() {
    searchParam.delete(
      SLIDERS_SEARCH_PARAMS.cards.virtualCards.create.mainPage
    );
    setSearchParam(searchParam);
  }

  if (values?.length <= 0) {
    return;
  }

  return (
    <>
      <div className="flex flex-col slider-content-core" ref={allState?.attach}>
        <div className="flex flex-col gap-1">
          <Text
            refProp={ref}
            translationKey="cards.vCards.createCardSlider.title"
            classes="text-3xl text-neutral-800 font-bold"
          />

          <Text
            translationKey="cards.vCards.createCardSlider.description"
            classes="text-sm text-neutral-500 font-medium"
          />
        </div>

        <div className="pb-6 ">
          <form
            className="mt-10"
            id="create_virtual_card"
            onSubmit={handleSubmit}
          >
            {totalList?.map((field) => {
              return field?.type !== "group" ? (
                getFieldByType(field)
              ) : (
                <div className="flex flex-row items-center justify-between gap-8">
                  {field?.fields?.map((groupField) => {
                    return getFieldByType(groupField, true);
                  })}
                </div>
              );
            })}
          </form>
        </div>
      </div>

      <div className="flex items-center justify-end gap-6 p-6 slider-footer">
        <Button
          label="cards.pCards.sliders.createCardSlider.buttons.cancel"
          variant="tertiary"
          classes="w-16 text-neutral-500 font-medium"
          onClick={closeSlider}
        />

        <Button
          btnType="Submit"
          label="cards.pCards.sliders.createCardSlider.buttons.continue"
          classes="w-16 text-white font-medium"
          form="create_virtual_card"
          disabled={isFormButtonDisabled}
        />
      </div>
    </>
  );
}

export default CreateVirtualCard;
