import { MODULES } from "@/utils/constants/app";

import { ROUTES } from "@/constants/routes";

import {
  SLIDERS_SEARCH_PARAMS,
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
} from "./SearchParams";
import { PROJECT } from "./company";

export const TABS_IDS = {
  SPEND_CONTROLS: "spend-controls",
  ALERTS: "alerts",
  DEVELOPERS_API: "developers-api",
};

export const EDIT_TYPE = {
  for: "for",
  required: "required",
  amount: "amount",
};

export const SETTINGS_KEY = {
  customApprovalPolicy: "customApprovalPolicy",
  physicalCardPolicy: "physicalCardPolicy",
  approvalPolicy: "approvalPolicy",
  claimPolicy: "claimPolicy",
  expenseReviewPolicy: "expenseReviewPolicy",
  customClaimPolicy: "customClaimPolicy",
  submissionPolicy: "submissionPolicy",
  customSubmissionPolicy: "customSubmissionPolicy",
};

export const SETTINGS_PARAMS = {
  customApprovalPolicy: "custom_approval_policy_enabled",
  physicalCardPolicy: "physical_card_policy_enabled",
  approvalPolicy: "approval_policy_enabled",
  submissionPolicy: "submission_policy_enabled",
  customSubmissionPolicy: "custom_submission_policy_enabled",
  claimPolicy: "claim_policy_enabled",
  customClaimPolicy: "custom_claim_policy_enabled",
  // billpay
  billPaymentAutopayEnabled: "bill_payment_autopay_enabled",
  paymentsMakerCheckerEnabled: "payments_maker_checker_enabled",
  cardsMakerCheckerEnabled: "cards_maker_checker_enabled",
  qrwalletMakerCheckerEnabled: "qrwallet_maker_checker_enabled",
  reimbursementMakerCheckerEnabled: "reimbursement_maker_checker_enabled",
  payrollMakerCheckerEnabled: "payroll_maker_checker_enabled",
  expenseReviewMakerCheckerEnabled: "expense_review_maker_checker_enabled",
  customExpenseReviewPolicyEnabled: "custom_expense_review_policy_enabled",
  qrpayReviewMakerCheckerEnabled: "qrpay_review_maker_checker_enabled",
};

export const GLOBAL_SETTINGS_TABS = [
  {
    name: "settings.globalSettingTabs.spendControls",
    id: TABS_IDS.SPEND_CONTROLS,
    path: ROUTES.manage.settings.spendControls.pathName,
    key: 0,
    count: null,
  },
  {
    name: "settings.globalSettingTabs.alerts",
    id: TABS_IDS.ALERTS,
    path: ROUTES.manage.settings.alerts.pathName,
    key: 1,
    count: null,
  },
  // {
  //   name: "settings.globalSettingTabs.developersAPI",
  //   id: TABS_IDS.DEVELOPERS_API,
  //   path: ROUTES.manage.settings.developers.pathName,
  //   key: 2,
  //   count: null,
  // },
];

export const NOTIFICATIONS_PREFERENCES_TAB_PARAMS = {
  PERSONAL: "personal",
  COMPANY: "company",
};

export const NOTIFICATIONS_PREFERENCES_TABS = [
  {
    name: "Personal",
    path: NOTIFICATIONS_PREFERENCES_TAB_PARAMS.PERSONAL,
    key: 0,
    count: null,
    param: "personal",
  },
  {
    name: "Company level",
    path: NOTIFICATIONS_PREFERENCES_TAB_PARAMS.COMPANY,
    key: 1,
    count: null,
    param: "company",
  },
];

export const POLICY_TABS = [
  {
    id: MODULES.CARDS,
    name: "settings.approvalPolicyTabs.cards",
    key: 0,
    count: null,
  },
  {
    id: MODULES.BILL_PAY,
    name: "settings.approvalPolicyTabs.billPay",
    key: 1,
    count: null,
  },
  {
    id: MODULES.REIMBURSEMENTS,
    name: "settings.approvalPolicyTabs.reimbursements",
    key: 2,
    count: null,
  },
  // {
  //   id: MODULES.PAYROLL,
  //   name: "settings.approvalPolicyTabs.payroll",
  //   key: 3,
  //   count: null,
  // },
  // {
  //   id: MODULES.TOP_UPS,
  //   name: "settings.approvalPolicyTabs.topUps",
  //   key: 4,
  //   count: null,
  // },
];

export const CUSTOM_POLICY_TABS = [
  {
    id: "departments",
    name: "Departments",
    key: 0,
    count: null,
  },
  {
    id: "projects",
    name: "Projects",
    key: 1,
    count: null,
  },
];

export const APPROVAL_LEVEL = {
  0: "first_level",
  1: "second_level",
  2: "third_level",
  3: "fourth_level",
  4: "fifth_level",
};

export const TAGS_TYPES = {
  ACCOUNTING_FIELD: "accountingTags",
  CUSTOM_FIELD: "customTags",
};

export const TAGS_TYPES_ARRAY = [
  TAGS_TYPES.ACCOUNTING_FIELD,
  TAGS_TYPES.CUSTOM_FIELD,
];

export const TAGS_MAP = {
  [TAGS_TYPES.ACCOUNTING_FIELD]: "Accounting fields",
  [TAGS_TYPES.CUSTOM_FIELD]: "Custom fields",
};

export const USER_LAYER_TYPES = {
  approver: "approver",
  notification: "notification",
  auto_approve: "auto_approve",
  review: "review",
};

export const LAYER_TYPE_HEADING = {
  approver: "Require approval from any one of",
  review: "Require review from any one of",
  notification: "Notify",
};

export const LAYER_TYPE_LABEL = {
  approver: "Select approvers",
  review: "Select reviewers",
  notification: "Select users",
};

export const REQUIREMENT_OPTIONS = [
  { label: "Required", value: true, id: 0 },
  { label: "Not required", value: false, id: 1 },
];

export const AMOUNT_OPTIONS = [
  { label: "All transactions", value: "all", id: 0 },
  { label: "All transactions above", value: "limit", id: 1 },
];

export const MODULE_NAMES = [
  {
    name: "dashboard.quickAccess.headings.cards",
    id: "cards",
    module: MODULES.CARDS,
    value: "Card",
  },
  {
    name: "dashboard.quickAccess.headings.payments",
    id: "bill-pay",
    value: "BillPay",
    module: MODULES.BILL_PAY,
  },
  {
    name: "dashboard.quickAccess.headings.reimbursements",
    id: "reimbursements",
    value: "Reimbursement",
    module: MODULES.REIMBURSEMENTS,
  },
  {
    name: "dashboard.quickAccess.headings.payroll",
    id: "payroll",
    value: "Payroll",
    module: MODULES.PAYROLL,
  },
];

export const CLAIM_POLICY = "claimPolicy";

export const NOTIFICATION_PREFRENECES_CHANNELS = {
  EMAIL: "email",
  PUSH: "push",
  SLACK: "slack",
  WHATSAPP: "whatsapp",
};

export const NOTIFICATION_PREFRENECES_CHANNELS_CONFIG = {
  [NOTIFICATION_PREFRENECES_CHANNELS.EMAIL]: {
    label: "Email",
  },
  [NOTIFICATION_PREFRENECES_CHANNELS.PUSH]: {
    label: "Push",
  },
  [NOTIFICATION_PREFRENECES_CHANNELS.SLACK]: {
    label: "Slack",
  },
  [NOTIFICATION_PREFRENECES_CHANNELS.WHATSAPP]: {
    label: "WhatsApp",
  },
};

export const MASTER_HISTORY_ACTIVITY_TYPES = {
  CARDS: "cards",
  CARD_REQUEST: "card_request",
  BILL_PAY: "purchase_bill",
  PAYMENT: "payment",
  REIMBURSEMENT: "reimbursement",
  PAYROLL: "payroll",
  QR_PAY: "qr_pay",
  ACCOUNTING: "accounting",
  SETTINGS: "settings",
  DEPARTMENTS: "department",
  PROJECTS: "project",
  PEOPLE: "users",
  BUDGETS: "budget",
  CREDIT: "credit",
  TRAVEL: "travel",
  ROLES_AND_PERMISSIONS: "roles",
  BILLING: "billing",
  OTHER: "",
  VENDOR: "vendor",
  EXPENSE: "expense",
};

export const MASTER_HISTORY_ACTIVITY_TYPES_TITLES_MAP = {
  [MASTER_HISTORY_ACTIVITY_TYPES.CARDS]:
    "settings.masterHistory.activityType.cards",
  [MASTER_HISTORY_ACTIVITY_TYPES.CARD_REQUEST]:
    "settings.masterHistory.activityType.cards",
  [MASTER_HISTORY_ACTIVITY_TYPES.BILL_PAY]:
    "settings.masterHistory.activityType.billPay",
  [MASTER_HISTORY_ACTIVITY_TYPES.PAYMENT]:
    "settings.masterHistory.activityType.billPay",
  [MASTER_HISTORY_ACTIVITY_TYPES.REIMBURSEMENT]:
    "settings.masterHistory.activityType.reimbursement",
  [MASTER_HISTORY_ACTIVITY_TYPES.PAYROLL]:
    "settings.masterHistory.activityType.payroll",
  [MASTER_HISTORY_ACTIVITY_TYPES.QR_PAY]:
    "settings.masterHistory.activityType.qrPay",
  [MASTER_HISTORY_ACTIVITY_TYPES.EXPENSE]:
    "settings.masterHistory.activityType.expense",
  [MASTER_HISTORY_ACTIVITY_TYPES.ACCOUNTING]:
    "settings.masterHistory.activityType.accounting",
  [MASTER_HISTORY_ACTIVITY_TYPES.SETTINGS]:
    "settings.masterHistory.activityType.settings",
  [MASTER_HISTORY_ACTIVITY_TYPES.DEPARTMENTS]:
    "settings.masterHistory.activityType.departments",
  [MASTER_HISTORY_ACTIVITY_TYPES.PROJECTS]:
    "settings.masterHistory.activityType.project",
  [MASTER_HISTORY_ACTIVITY_TYPES.PEOPLE]:
    "settings.masterHistory.activityType.people",
  [MASTER_HISTORY_ACTIVITY_TYPES.BUDGETS]:
    "settings.masterHistory.activityType.budget",
  [MASTER_HISTORY_ACTIVITY_TYPES.CREDIT]:
    "settings.masterHistory.activityType.credit",
  [MASTER_HISTORY_ACTIVITY_TYPES.TRAVEL]:
    "settings.masterHistory.activityType.travel",
  [MASTER_HISTORY_ACTIVITY_TYPES.ROLES_AND_PERMISSIONS]:
    "settings.masterHistory.activityType.rolesAndPermissions",
  [MASTER_HISTORY_ACTIVITY_TYPES.BILLING]:
    "settings.masterHistory.activityType.billing",
  [MASTER_HISTORY_ACTIVITY_TYPES.OTHER]:
    "settings.masterHistory.activityType.other",
  [MASTER_HISTORY_ACTIVITY_TYPES.VENDOR]:
    "settings.masterHistory.activityType.other",
};

// TBD: Add all the owner types
export const MASTER_HISTORY_OWNER_TYPES = {
  VENDOR: "vendor",
  CARD: "card",
  EXPENSE: "expense",
  PEOPLE: "user",
  VIRTUAL_CARD_REQUEST: "virtual_card_request",
  PHYSICAL_CARD_REQUEST: "physical_card_request",
  TOP_UP_REQUEST: "top_up_request",
  ACCOUNTING: "accounting",
  BUDGET: "budget",
  PROJECT: "project",
  DEPARTMENT: "department",
  BILL_PAY: "purchase_bill",
  PAYMENT: "payment",
  REIMBURSEMENT: "reimbursement",
  RULE: "rule",
};

// TBD: Config for navigable sliders,combination of activity btype and owner type
export const MASTER_HISTORY_NAVIGATION_CONFIG = {
  [`${MASTER_HISTORY_ACTIVITY_TYPES.CARDS}-${MASTER_HISTORY_OWNER_TYPES.CARD}`]:
    SLIDERS_SEARCH_PARAMS.cards.id,
  [`${MASTER_HISTORY_ACTIVITY_TYPES.CARDS}-${MASTER_HISTORY_OWNER_TYPES.VIRTUAL_CARD_REQUEST}`]:
    SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest
      .virtualCardRequest,
  [`${MASTER_HISTORY_ACTIVITY_TYPES.CARDS}-${MASTER_HISTORY_OWNER_TYPES.PHYSICAL_CARD_REQUEST}`]:
    SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest
      .physicalCardRequest,
  [`${MASTER_HISTORY_ACTIVITY_TYPES.CARDS}-${MASTER_HISTORY_OWNER_TYPES.TOP_UP_REQUEST}`]:
    SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardLimitRequest,
  [`${MASTER_HISTORY_ACTIVITY_TYPES.VENDOR}-${MASTER_HISTORY_OWNER_TYPES.VENDOR}`]:
    SLIDERS_SEARCH_PARAMS.vendors.id,
  [`${MASTER_HISTORY_ACTIVITY_TYPES.PAYROLL}-${MASTER_HISTORY_OWNER_TYPES.VENDOR}`]:
    SLIDERS_SEARCH_PARAMS.payrollPayments.id,
  [`${MASTER_HISTORY_ACTIVITY_TYPES.EXPENSE}-${MASTER_HISTORY_OWNER_TYPES.EXPENSE}`]:
    SLIDERS_SEARCH_PARAMS.expenses.id,
  [`${MASTER_HISTORY_ACTIVITY_TYPES.ACCOUNTING}-${MASTER_HISTORY_OWNER_TYPES.EXPENSE}`]:
    SLIDERS_SEARCH_PARAMS.expenses.id,
  [`${MASTER_HISTORY_ACTIVITY_TYPES.PEOPLE}-${MASTER_HISTORY_OWNER_TYPES.PEOPLE}`]:
    SLIDERS_SEARCH_PARAMS.company.people.id,
  [`${MASTER_HISTORY_ACTIVITY_TYPES.BUDGETS}-${MASTER_HISTORY_OWNER_TYPES.BUDGET}`]:
    [
      SLIDERS_SEARCH_PARAMS.company.budget.id,
      [SLIDERS_SEARCH_PARAMS.company.budget.type, PROJECT],
    ],
  [`${MASTER_HISTORY_ACTIVITY_TYPES.PROJECTS}-${MASTER_HISTORY_OWNER_TYPES.PROJECT}`]:
    SLIDERS_SEARCH_PARAMS.company.project.id,
  [`${MASTER_HISTORY_ACTIVITY_TYPES.DEPARTMENTS}-${MASTER_HISTORY_OWNER_TYPES.DEPARTMENT}`]:
    SLIDERS_SEARCH_PARAMS.company.department.id,
  [`${MASTER_HISTORY_ACTIVITY_TYPES.BILL_PAY}-${MASTER_HISTORY_OWNER_TYPES.BILL_PAY}`]:
    SLIDERS_SEARCH_PARAMS.masterHistoryIntermediateSlider,
  [`${MASTER_HISTORY_ACTIVITY_TYPES.PAYMENT}-${MASTER_HISTORY_OWNER_TYPES.PAYMENT}`]:
    SLIDERS_SEARCH_PARAMS.payments.id,
  [`${MASTER_HISTORY_ACTIVITY_TYPES.REIMBURSEMENT}-${MASTER_HISTORY_OWNER_TYPES.REIMBURSEMENT}`]:
    SLIDERS_SEARCH_PARAMS.reimbursements.id,
  [`${MASTER_HISTORY_ACTIVITY_TYPES.OTHER}-${MASTER_HISTORY_OWNER_TYPES.RULE}`]:
    SLIDERS_SEARCH_PARAMS.accounting.rules.id,
};

export const MASTER_HISTORY_TYPES = {
  CHILD_LOGS: "CHILD_LOGS",
  MASTER_HISTORY: "MASTER_HISTORY",
};
