import PropTypes from "prop-types";
import { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchAccountingVendors } from "@/store/reducers/accounting";
import { updateAccountingData } from "@/store/reducers/accounting_transactions";
import {
  approveRequest,
  deleteAttachment,
  downloadAttachment,
} from "@/store/reducers/app";
import { addComments, fetchComments } from "@/store/reducers/comments";
import { fetchDepartments, fetchLocations } from "@/store/reducers/company";
import {
  fetchAndSelectQrPayment,
  fetchQrPaymentSplit,
  fetchSelectedQrPaymentActivity,
  remindQrPayment,
  requestReceived,
  syncedQrPayment,
  unverifyQrPayment,
  updateQrPayment,
  uploadQrPaymentReceipts,
  verifyQrPayment,
} from "@/store/reducers/qr-payments";
import { fetchTags } from "@/store/reducers/tags";

import { categoriesListSelector } from "@/store/selectors/categories";
import {
  commentsHasMoreSelector,
  commentsListSelector,
} from "@/store/selectors/comments";
import { submissionPolicyObjectSelector } from "@/store/selectors/policy";
import {
  isFetchingQrPaymentActivitySelector,
  isFetchingQrPayments,
  qrPaymentSplitSelector,
  selectedQrPaymentIsApprovedSelector,
  selectedQrpaymentSelector,
} from "@/store/selectors/qr-payments";
import {
  accountingCategoryTagsSelector,
  accountingNonCategoryTags,
} from "@/store/selectors/tags";
import { userSelector } from "@/store/selectors/user";

import Alert from "@/components/core/Alert";
import Tabs from "@/components/core/Tabs";

import QRPayAndExpenseSliderFooter from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPayFooter";
import { ExpenseAndQRPayHeaderJsx } from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPayHeader";
import ExpenseAndQRPayOverviewTab from "@/components/common/QrPayAndExpense/common/Sliders/ExpenseAndQRPayOverviewTab";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";
import ModuleHistory from "@/components/common/ModuleHistory";
import { COMMENTS_PAGINATION_LIMIT_PER_REQUEST } from "@/utils/constants/payments";
import { COMMENT_TYPE_PAYLOAD } from "@/utils/constants/comments";
import {
  createFileFormData,
  dateToString,
  getDynamicNameForNonAccountingTag,
} from "@/utils/common";

import { OWNER_TYPE_MASTER_HISTORY } from "@/utils/constants/app";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { ACCOUNTING_TRANSACTION_STATUS } from "@/constants/accounting";
import {
  DECLINED,
  DECLINE_CODES,
  DECLINE_EXPENSE_ALERT_CTA,
  DECLINE_SLIDER_ALERT,
  EXPENSE_CTA_KEYS,
  HISTORY,
  OVERVIEW,
  TABS,
} from "@/constants/expense";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { CATEGORY_TYPES } from "@/constants/qrPayments";

export default function QrPaymentSlider({ isAccountingSlider = false }) {
  // expense store operations
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const qrPayment = useSelector(selectedQrpaymentSelector);
  const categories = useSelector(categoriesListSelector);
  const isFetchingQrPayment = useSelector(isFetchingQrPayments);
  const splitQrPayment = useSelector(qrPaymentSplitSelector);
  const submissionPolicy = useSelector(
    submissionPolicyObjectSelector
  )?.qrpaySubmissionPolicy;
  const categoryAccountingTags = useSelector(accountingCategoryTagsSelector);
  const isFetching = useSelector(isFetchingQrPaymentActivitySelector);
  const isApproved = useSelector(selectedQrPaymentIsApprovedSelector);
  const currentUser = useSelector(userSelector);
  const [isApproveProcessing, setIsApproveProcessing] = useState(false);
  const [qrPaymentId, setQrPaymentId] = useState(null);
  const [selectedTab, setSelectedTab] = useState(TABS[0]);
  const [files, setFiles] = useState([]);
  const [memo, setMemo] = useState(qrPayment ? qrPayment.memo : "");
  const isDeclineQrPayment = qrPayment?.transactionType === DECLINED;
  const nonCategoryAccountingTags = useSelector(accountingNonCategoryTags);
  // UseForm
  const initialState = {
    accountingCategory: "",
    accountingVendor: "",
    accountingDate: new Date(),
    category: "",
    ...getDynamicNameForNonAccountingTag(nonCategoryAccountingTags),
  };

  const [pageNum, setPageNum] = useState(1);

  // comments
  const commentsList = useSelector(commentsListSelector);
  const commentHasMore = useSelector(commentsHasMoreSelector);
  function reducer(state, action) {
    switch (action.type) {
      case CATEGORY_TYPES.SET_ACCOUNTING_CATEGORY:
        return { ...state, accountingCategory: action.payload };
      case CATEGORY_TYPES.SET_ACCOUNTING_VENDOR:
        return { ...state, accountingVendor: action.payload };
      case CATEGORY_TYPES.SET_ACCOUNTING_DATE:
        return { ...state, accountingDate: action.payload };

      case CATEGORY_TYPES.SET_VOLOPAY_CATEGORY:
        return { ...state, category: action.payload };
      case CATEGORY_TYPES.SET_NON_CATEGORY:
        return nonCategoryAccountingTags?.length
          ? { ...state, [action.payload.name]: action.payload.value }
          : state;
      default:
        return state;
    }
  }
  const [reducerState, reducerDispatch] = useReducer(reducer, initialState);
  /*
    ctas array
  */
  const footerCtas = qrPayment?.qrPaymentCta?.length
    ? [
        ...(qrPayment?.qrPaymentCta ?? []),
        ...(isAccountingSlider ? (qrPayment?.accountingCtas ?? []) : []),
        ...(isAccountingSlider &&
        qrPayment?.accountingStatus === ACCOUNTING_TRANSACTION_STATUS.SYNCED
          ? [qrPayment?.accountingStatus]
          : []),
      ]
    : [];

  /*
    Functions
  */
  const fileUploadHandler = (latestFileArray) => {
    const filesToUpload = latestFileArray?.filter((item) => {
      return item instanceof File && item.type !== "image/svg+xml";
    });
    setFiles(latestFileArray);
    if (filesToUpload?.length === 0) return;

    const formPayload = createFileFormData(filesToUpload, "receipts[]");

    dispatch(uploadQrPaymentReceipts({ qrPaymentId, payload: formPayload }));
  };

  const primaryAction = {
    handler: (index) => {
      if (files[index]?.id) {
        dispatch(deleteAttachment({ id: files[index]?.id }));
      }
    },
    label: "misc.delete",
    icon: "Delete",
    iconClasses: "text-danger-600 bg-danger-50",
  };

  const secondaryAction = {
    handler: (index) => {
      const file = files[index];
      if (file instanceof File) return;
      dispatch(
        downloadAttachment({
          file,
          id: file?.id,
        })
      );
    },
    label: "misc.download",
    icon: "Download",
    iconClasses: "!text-primary-500 !bg-primary-50", // some issue with SVG, using !
  };

  const onSuccess = () => {
    dispatch(
      fetchAndSelectQrPayment({
        qrPaymentId,
      })
    );
    dispatch(
      fetchQrPaymentSplit({
        qrPaymentId,
      })
    );
    dispatch(fetchSelectedQrPaymentActivity({ qrPaymentId }));
  };

  const handleFooterCtaClick = async (key) => {
    setIsApproveProcessing(true);
    const id = parseInt(
      searchParams.get(SLIDERS_SEARCH_PARAMS.qrPayments.payments.id),
      10
    );
    let payload = {};
    if (id) {
      switch (key) {
        case EXPENSE_CTA_KEYS.FLAG:
          searchParams.append(
            SLIDERS_SEARCH_PARAMS.qrPayments.payments.flagTransaction,
            id
          );
          setSearchParams(searchParams);
          break;

        case EXPENSE_CTA_KEYS.APPROVE:
          dispatch(
            approveRequest({
              payload: { target_id: id, type: "qrPayment" },
              onSuccess,
            })
          );
          break;
        case EXPENSE_CTA_KEYS.REPAYMENT_RECEIVED:
          dispatch(
            requestReceived({
              id,
              onSuccess,
            })
          );
          break;
        case EXPENSE_CTA_KEYS.ACCOUNTING_VERIFY:
          dispatch(verifyQrPayment({ id: qrPayment?.accountingId }));
          break;
        case EXPENSE_CTA_KEYS.ACCOUNTING_SYNC:
          dispatch(syncedQrPayment({ id: qrPayment?.accountingId }));
          break;
        case EXPENSE_CTA_KEYS.ACCOUNTING_UNVERIFY:
          dispatch(unverifyQrPayment({ id: qrPayment?.accountingId }));
          break;
        case EXPENSE_CTA_KEYS.MARK_AS_PAID:
          payload = {
            status: ACCOUNTING_TRANSACTION_STATUS.SYNCED,
          };
          dispatch(
            updateAccountingData({
              accounting_id: qrPayment?.accountingId,
              payload,
            })
          );
          break;
        default:
          break;
      }
    }
    setIsApproveProcessing(false);
  };

  const handleUpdateQrPayment = (payload) => {
    if (qrPaymentId)
      dispatch(
        updateQrPayment({
          qrPaymentId,
          payload,
          onSuccess: () => {
            onSuccess();
            if (qrPayment?.hasComments)
              dispatch(
                fetchComments({
                  type: COMMENT_TYPE_PAYLOAD.QRPAY,
                  id: qrPaymentId,
                  page: 1,
                  limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
                })
              );
          },
        })
      );
  };

  const remindPeople = (key, qrPayId) => {
    dispatch(remindQrPayment({ [key]: qrPayId }));
  };

  const createNewComment = (formData) => {
    formData.append("owner_type", COMMENT_TYPE_PAYLOAD.QRPAY);
    formData.append("owner_id", qrPayment?.id);
    dispatch(
      addComments({
        payload: formData,
        onSuccess: () => {
          dispatch(
            fetchComments({
              type: COMMENT_TYPE_PAYLOAD.QRPAY,
              id: qrPayment?.id,
              page: 1,
              limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
            })
          );
        },
      })
    );
  };

  const loadMoreComments = () => {
    setPageNum((prev) => prev + 1);
    dispatch(
      fetchComments({
        type: COMMENT_TYPE_PAYLOAD.QRPAY,
        id: qrPaymentId,
        page: pageNum + 1,
        limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
      })
    );
  };
  const handleUpdatePayload = () => {
    const accountingCategory = qrPayment?.accountingTags?.find(
      (_) => _?.tagId === categoryAccountingTags?.id
    );

    let payload = {};
    let completePayload = {};
    if (
      reducerState.accountingCategory?.tagValueId !==
      accountingCategory?.tagValueId
    ) {
      payload = {
        expense_tag_values_attributes: [
          accountingCategory?.id
            ? reducerState.accountingCategory?.tagId
              ? {
                  id: accountingCategory?.id,
                  tag_id: reducerState.accountingCategory?.tagId,
                  tag_value_id: reducerState.accountingCategory?.tagValueId,
                }
              : { id: accountingCategory?.id, _destroy: true }
            : {
                tag_id: reducerState.accountingCategory?.tagId ?? null,
                tag_value_id:
                  reducerState.accountingCategory?.tagValueId ?? null,
              },
        ],
      };
      completePayload = { ...completePayload, ...payload };
    }
    nonCategoryAccountingTags?.forEach((val, index) => {
      let accountingNonCategory = null;
      val?.options?.forEach((option) => {
        accountingNonCategory = qrPayment?.accountingTags?.find(
          (_) => _?.tagId === val?.id
        );
      });
      if (accountingNonCategory)
        payload = {
          expense_tag_values_attributes: [
            accountingNonCategory?.id
              ? reducerState[`nonCategory${index + 1}`]?.tagId
                ? {
                    id: accountingNonCategory?.id,
                    tag_id: reducerState[`nonCategory${index + 1}`]?.tagId,
                    tag_value_id:
                      reducerState[`nonCategory${index + 1}`]?.tagValueId,
                  }
                : { id: accountingNonCategory?.id, _destroy: true }
              : {
                  tag_id: reducerState[`nonCategory${index + 1}`]?.tagId,
                  tag_value_id:
                    reducerState[`nonCategory${index + 1}`]?.tagValueId,
                },
          ],
        };
      completePayload = { ...completePayload, ...payload };
    });
    if (qrPayment?.accountingPayeeId !== reducerState?.accountingVendor) {
      payload = { accounting_payee_id: reducerState?.accountingVendor ?? null };
      completePayload = { ...completePayload, ...payload };
    }
    return completePayload;
  };
  const getTransalationPropKey = (key) => {
    switch (key) {
      case DECLINE_CODES.FROZEN_CARD:
      case DECLINE_CODES.CARD_FROZEN_BY_EMPLOYEE:
        return {
          date: qrPayment?.freezedAt,
          role: qrPayment?.walletHolder?.roles[0],
          firstName: qrPayment?.walletHolder?.firstName,
        };
      case DECLINE_CODES.AUTO_BLOCKED:
      case DECLINE_CODES.BLOCKED_CARD:
        return {
          date: qrPayment?.blockedAt,
          role: qrPayment?.walletHolder?.roles[0],
          firstName: qrPayment?.walletHolder?.firstName,
        };

      default:
        return {};
    }
  };
  /*
  useEffects
  */
  useEffect(() => {
    dispatch(fetchAccountingVendors());
    dispatch(fetchTags({ visible: true }));
    dispatch(
      fetchDepartments({ page: 1, limit: PAGINATION_PER_REQUEST_LIMIT })
    );
    dispatch(fetchLocations({ page: 1, limit: PAGINATION_PER_REQUEST_LIMIT }));
  }, []);

  useEffect(() => {
    setFiles(qrPayment?.receipts ?? []);
    setMemo(qrPayment?.memo);
    reducerDispatch({
      type: CATEGORY_TYPES.SET_ACCOUNTING_CATEGORY,
      payload: qrPayment?.accountingPayeeId,
    });
    reducerDispatch({
      type: CATEGORY_TYPES.SET_ACCOUNTING_DATE,
      payload: qrPayment?.accountingDate
        ? dateToString(qrPayment?.accountingDate)
        : new Date(),
    });
  }, [qrPayment]);

  // depends on both categoryAccountingTag and expense
  useEffect(() => {
    const accountingCategory = qrPayment?.accountingTags?.find(
      (_) => _?.tagId === categoryAccountingTags?.id
    );

    if (accountingCategory)
      reducerDispatch({
        type: CATEGORY_TYPES.SET_ACCOUNTING_CATEGORY,
        payload: accountingCategory,
      });
  }, [qrPayment, categoryAccountingTags]);

  useEffect(() => {
    if (qrPayment?.hasComments)
      dispatch(
        fetchComments({
          type: COMMENT_TYPE_PAYLOAD.QRPAY,
          id: qrPaymentId,
          page: 1,
          limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
        })
      );
  }, [qrPaymentId, qrPayment?.hasComments]);

  useEffect(() => {
    if (searchParams.get(SLIDERS_SEARCH_PARAMS.qrPayments.payments.id))
      setQrPaymentId(
        parseInt(
          searchParams.get(SLIDERS_SEARCH_PARAMS.qrPayments.payments.id),
          10
        )
      );
    else if (searchParams.get(SLIDERS_SEARCH_PARAMS.accounting.cards.id))
      setQrPaymentId(
        parseInt(
          searchParams.get(SLIDERS_SEARCH_PARAMS.accounting.cards.id),
          10
        )
      );
  }, []);

  useEffect(() => {
    if (qrPaymentId && !Number.isNaN(qrPaymentId)) {
      dispatch(
        fetchAndSelectQrPayment({
          qrPaymentId,
        })
      );
      dispatch(
        fetchQrPaymentSplit({
          qrPaymentId,
        })
      );
    }
  }, [qrPaymentId]);

  // handle update of value
  useEffect(() => {
    const completePayload = handleUpdatePayload();
    if (qrPaymentId && Object.keys(completePayload).length) {
      dispatch(
        updateQrPayment({
          qrPaymentId,
          payload: completePayload,
          onSuccess,
        })
      );
    }
  }, [reducerState]);
  return (
    <>
      <div className="slider-content-core">
        {isFetchingQrPayment && <div>Loading...</div>}
        {!isFetchingQrPayment && qrPayment ? (
          <div className="pb-6 mb-6">
            <div className="flex items-center justify-between">
              <ExpenseAndQRPayHeaderJsx
                isExpenseStatusHidden={isDeclineQrPayment} // for declined transactionType
                exchangeRate={qrPayment?.exchangeRate}
                transactionAmount={qrPayment?.transactionAmount}
                transactionCurrencyCode={qrPayment?.transactionCurrencyCode}
                settlementStatus={qrPayment?.settlementStatus}
                img={qrPayment?.vendor?.avatarUrl}
                colorCode={qrPayment?.vendor?.colorCode}
                name={qrPayment?.merchant?.name}
                amount={qrPayment?.amount}
                currency={qrPayment?.currency}
                transactionStatus={qrPayment?.transactionStatus}
                updatedAt={qrPayment.updatedAt}
                merchantName={qrPayment.merchant.name}
              />
            </div>
            {isDeclineQrPayment ? (
              <div className="mt-6">
                <Alert
                  wrapperDivClass="justify-start"
                  iconClasses="w-6 h-6 text-neutral-500"
                  variant="warning"
                  title={DECLINE_SLIDER_ALERT[qrPayment?.declineCode]?.title}
                  description={
                    DECLINE_SLIDER_ALERT[qrPayment?.declineCode]?.message
                  }
                  descriptionTransalationProp={getTransalationPropKey(
                    qrPayment?.declineCode
                  )}
                  primaryAction={{
                    label:
                      DECLINE_EXPENSE_ALERT_CTA[qrPayment?.declineCode] ?? "",
                    callback: () => {},
                  }}
                />
              </div>
            ) : null}
            <div className="mt-5">
              <Tabs
                items={TABS}
                selectedTab={selectedTab?.key}
                setCurrentTab={setSelectedTab}
                mode
              />
            </div>
            {selectedTab.name === HISTORY && (
              <div className="mb-5">
                <ModuleHistory
                  ownerId={qrPaymentId}
                  ownerType={OWNER_TYPE_MASTER_HISTORY.QR_PAY}
                />
              </div>
            )}
            {selectedTab.name === OVERVIEW && (
              <ExpenseAndQRPayOverviewTab
                isExpenseStatusHidden={isDeclineQrPayment} // for declined transactionType
                transaction={qrPayment}
                page={QRPAY_EXPENSES_CONTEXT.QRPAY}
                memo={memo}
                isAccountingSlider={isAccountingSlider}
                handleMemoChange={setMemo}
                updateExpense={handleUpdateQrPayment}
                categories={categories}
                expenseId={qrPaymentId}
                splitExpenses={splitQrPayment}
                remindPeople={remindPeople}
                submissionPolicy={submissionPolicy}
                commentsList={commentsList}
                isApproved={isApproved}
                files={files?.length ? files : []}
                fileUploadHandler={fileUploadHandler}
                primaryAction={primaryAction}
                secondaryAction={secondaryAction}
                createNewComment={createNewComment}
                loadMoreComments={loadMoreComments}
                showLoadMoreCta={commentHasMore}
                currentUser={currentUser}
                accountingState={reducerState}
                setAccountingState={reducerDispatch}
              />
            )}
          </div>
        ) : null}
      </div>

      {footerCtas.length ? (
        <QRPayAndExpenseSliderFooter
          expenseFooterCtas={qrPayment?.qrPaymentCta}
          accountingFooterCtas={[
            ...(qrPayment?.accountingCtas ?? []),
            ...(qrPayment?.accountingStatus ===
            ACCOUNTING_TRANSACTION_STATUS.SYNCED
              ? [qrPayment?.accountingStatus]
              : []),
          ]}
          isAccountingSlider={isAccountingSlider}
          onSubmit={handleFooterCtaClick}
          actionByName={qrPayment?.actionBy?.name}
        />
      ) : null}
    </>
  );
}

QrPaymentSlider.propTypes = {
  isAccountingSlider: PropTypes.bool,
};
