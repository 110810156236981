import PropTypes from "prop-types";
import React from "react";

import Text from "@/components/core/Text";

import { CARDS_TABLE_HEADER_KEYS } from "@/constants/Cards";
import { USER_WALLET_TABLE_HEADER_KEYS } from "@/constants/qrPayments";

import AvailableBalance from "./AvailableBalance";
import Card from "./Card";
import CardHolder from "./CardHolder";
import Limit from "./Limit";
import LinkedToComponent from "./LinkedToComponent";
import Spent from "./Spent";

function CardTableComponent({ componentId, componentProps }) {
  const getComponent = () => {
    let Component;
    switch (componentId) {
      case CARDS_TABLE_HEADER_KEYS.CARDS:
        Component = Card;
        break;
      case CARDS_TABLE_HEADER_KEYS.CARD_HOLDER:
      case USER_WALLET_TABLE_HEADER_KEYS.WALLET_HOLDER:
        Component = CardHolder;
        break;
      case CARDS_TABLE_HEADER_KEYS.LIMIT:
        Component = Limit;
        break;
      case CARDS_TABLE_HEADER_KEYS.SPENT:
        Component = Spent;
        break;
      case CARDS_TABLE_HEADER_KEYS.AVAILABLE:
        Component = AvailableBalance;
        break;
      case CARDS_TABLE_HEADER_KEYS.LINKED_TO:
        Component = LinkedToComponent;
        break;
      case CARDS_TABLE_HEADER_KEYS.LOCATION:
      case CARDS_TABLE_HEADER_KEYS.DEPARTMENT:
        Component = Text;
        break;
      case CARDS_TABLE_HEADER_KEYS.CREATED_ON:
      case USER_WALLET_TABLE_HEADER_KEYS.ACTIVATION_DATE:
        Component = Text;
        break;
      default:
        return "-";
    }
    return <Component {...componentProps} />;
  };

  return <div>{getComponent()}</div>;
}

export default CardTableComponent;
CardTableComponent.propTypes = {
  componentId: PropTypes.string,
  componentProps: PropTypes.object,
};
