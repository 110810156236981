import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { selectedAccountingTransactionSelector } from "@/store/selectors/accounting_transactions";
import {
  selectedExpenseCategorySelector,
  selectedExpenseSelector,
} from "@/store/selectors/expense";

import { categoriesListSelector } from "@/store/selectors/categories";
import CopytoClipboard from "@/components/core/CopyToClipboard";

import OverviewCardsSection from "@/components/common/OverviewCardsSection";
import { capitalizeFirstLetter } from "@/utils/common";

import { CARD_TYPE, LINKED_TO_TYPE } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { CARD_STATUS } from "@/constants/card";
import { INFINITY_BUDGET_AVAILABLE_TEXT } from "@/constants/expense";

import CategoryMerchantReportDetailsModal from "./CategoryMerchantReportDetailsModal";

export default function ExpenseSliderOverviewCards({
  isAccountingSlider = false,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const expense = useSelector(
    isAccountingSlider
      ? selectedAccountingTransactionSelector
      : selectedExpenseSelector
  );

  const selectedExpenseCategory = useSelector(selectedExpenseCategorySelector);

  const userName =
    expense?.cardHolder?.displayName ?? expense?.walletHolder?.displayName;
  const expenseId = expense?.voloTransactionId;
  const userId = expense?.cardHolder?.id;
  const role = expense?.cardHolder?.roles[0];
  const department = expense?.cardHolder?.departmentName;
  const location = expense?.cardHolder?.locationName;
  const cardId = expense?.cardId;
  const cardNumber = expense?.cardNumber?.slice(-4);
  const cardType = expense?.cardType;
  const cardStatus = expense?.cardStatus;
  const cardName = expense?.cardName;

  const merchantName = expense?.merchant?.name;
  const providerMerchant = expense?.merchant?.providerMerchant;
  const merchantId = expense?.merchant?.merchantId;

  const category = expense?.categoryName;

  const linkedTo = expense?.linkedTo ?? "";
  const project = expense?.project;
  const cardBudgetId = expense?.cardBudgetId;
  const projectName = project?.name;
  const budgetAvailableBalance = expense?.budgetAvailableBalance;
  const currency = expense?.currency;
  const isPhysicalCard = cardType === CARD_TYPE.PHYSICAL;
  const linkedTypeText = `${capitalizeFirstLetter(linkedTo)} : ${
    linkedTo?.toLowerCase() === LINKED_TO_TYPE.PROJECT.toLowerCase()
      ? projectName
      : department
  } `;

  const volopayCategoriesOptions = useSelector(categoriesListSelector);

  const categoryName = volopayCategoriesOptions?.find(
    (singleCategory) => singleCategory?.id === selectedExpenseCategory
  )?.name;

  const budgetAvailableBalanceText = budgetAvailableBalance
    ? budgetAvailableBalance === INFINITY_BUDGET_AVAILABLE_TEXT
      ? "expenses.overview.noBudgetedText"
      : "expenses.overview.budgetAvailable"
    : "";

  const budgetAvailableBalanceTextTranslationProps = budgetAvailableBalance
    ? budgetAvailableBalance === INFINITY_BUDGET_AVAILABLE_TEXT
      ? {}
      : {
          amount: budgetAvailableBalance?.toFixed(2),
          currency,
        }
    : {};

  const [showReportCategoryModal, setShowReportCategoryModal] = useState(false);

  const EXPENSE_SLIDER_OVERVIEW_CARDS_CONFIG = [
    {
      rows: [
        {
          text: {
            translationKey: "expenses.slider.detailsCard.user",
            translationProps: {},
            color: "neutral-500",
            classes: "text-sm not-italic font-semibold leading-6",
          },
        },
        {
          text: {
            translationKey: userName,
            color: "neutral-800",
            classes: "text-base not-italic font-semibold leading-6 ",
          },
          icon: {
            name: "NEArrow",
            type: "icon",
          },
        },
        {
          renderArray: [
            { label: role },
            {
              label: department,
              tooltipClasses: "w-14.75",
              showTooltip: department?.length > 18,
            },
            {
              label: location,
              showTooltip: location?.length > 18,
              tooltipClasses: "w-14.75",
            },
          ],
        },
      ],
      cardProps: {
        handleCardClick: () => {
          searchParams.append(SLIDERS_SEARCH_PARAMS.company.people.id, userId);
          setSearchParams(searchParams);
        },
      },
    },
    {
      rows: [
        {
          text: {
            translationKey: "expenses.slider.detailsCard.card",
            translationProps: {
              cardType,
              cardNumber,
            },
            color: "neutral-500",
            classes:
              "text-sm not-italic font-semibold leading-6 lowercase first-letter:capitalize",
          },
        },
        {
          text: {
            translationKey: cardName,
            color: "neutral-800",
            classes: "text-base not-italic font-semibold leading-6",
          },
          icon: {
            name: "NEArrow",
            type: "icon",
          },
        },
      ],
      cardProps: {
        handleCardClick: () => {
          searchParams.append(SLIDERS_SEARCH_PARAMS.cards.id, cardId);
          setSearchParams(searchParams);
        },
      },
    },
    {
      rows: [
        {
          text: {
            translationKey: "expenses.slider.detailsCard.merchantName",
            color: "neutral-500",

            classes: "text-sm not-italic font-semibold leading-6",
          },
        },
        {
          text: {
            translationKey: merchantName,
            showTooltip: true,
            color: "neutral-800",
            classes:
              "text-base not-italic font-semibold truncate text-ellipsis max-w-20 leading-6 ",
          },
          icon: {
            name: "NEArrow",
            type: "icon",
          },
        },
        providerMerchant
          ? {
              text: {
                translationKey: providerMerchant,
                color: "neutral-500",
                classes:
                  "text-xs not-italic font-semibold leading-5 truncate text-ellipsis max-w-16.35 mr-2 ",
              },
              icon: {
                name: CopytoClipboard,
                type: "component",
                iconClasses: "",
                text: providerMerchant,
              },
            }
          : {},
      ],
      cardProps: {
        handleCardClick: () => {
          if (merchantId) {
            searchParams.append(
              SLIDERS_SEARCH_PARAMS.expenses.merchantId,
              merchantId
            );
            setSearchParams(searchParams);
          }
        },
      },
    },
    {
      rows: {
        titleRow: {
          text: {
            translationKey: "expenses.slider.detailsCard.category",
            color: "neutral-500",
            classes: "text-sm not-italic font-semibold leading-6",
          },
          icon: {
            name: "TourFlag",
            type: "icon",
            className: "text-neutral-500",
          },
        },
        middleRow: {
          text: {
            translationKey: categoryName,
            color: "neutral-800",
            classes: "text-base not-italic font-semibold leading-6 ",
          },
        },
      },
      cardProps: {
        handleCardClick: () => {
          setShowReportCategoryModal(true);
        },
      },
    },
    {
      rows: [
        {
          text: {
            translationKey: "Linked to",
            color: "neutral-500",
            classes: "text-sm not-italic font-semibold leading-6",
          },
        },
        {
          text: {
            translationKey: linkedTypeText,
            color: "neutral-800",
            classes: "text-base not-italic font-semibold leading-6 ",
            className: "w-full",
          },
          icon: {
            name:
              isPhysicalCard && cardStatus !== CARD_STATUS.BLOCKED
                ? "NEArrow"
                : "",
            type: "icon",
          },
        },
        {
          text: {
            translationKey: budgetAvailableBalanceText,
            translationProps: budgetAvailableBalanceTextTranslationProps,
            color: "neutral-500",
            classes: "text-xs not-italic font-semibold leading-5",
          },
        },
      ],
      cardProps: {
        handleCardClick:
          isPhysicalCard &&
          cardStatus !== CARD_STATUS.BLOCKED &&
          !isAccountingSlider
            ? () => {
                searchParams.append(
                  SLIDERS_SEARCH_PARAMS.cards.linkedTo,
                  cardBudgetId
                );
                setSearchParams(searchParams);
              }
            : null,
      },
    },
    {
      rows: [
        {
          text: {
            translationKey: "expenses.slider.detailsCard.expenseId",
            color: "neutral-500",
            classes: "text-sm not-italic font-semibold leading-6",
          },
        },
        {
          text: {
            translationKey: expenseId,
            color: "neutral-800",
            classes: "text-base not-italic font-semibold leading-6 ",
          },
        },
      ],
    },
  ];

  return (
    <div>
      <div className="my-4">
        <OverviewCardsSection config={EXPENSE_SLIDER_OVERVIEW_CARDS_CONFIG} />
      </div>
      <CategoryMerchantReportDetailsModal
        showModal={showReportCategoryModal}
        setShowModal={setShowReportCategoryModal}
      />
    </div>
  );
}

ExpenseSliderOverviewCards.propTypes = {
  isAccountingSlider: PropTypes.bool,
};
