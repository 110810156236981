import PropsTypes from "prop-types";

import Text from "@/components/core/Text";

import AmountToBePaid from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/AmountToBePaid";
import QuoteExchangeRate from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/QuoteExchangeRate";

/**
 * 3 things - invoice-total-paid-box (only in billpay), exchange-rate and amount-to-be-paid-box
 * {@link https://www.figma.com/file/GJPfiUwCkfGEz6CubufE0x/v2-Bill-Pay?type=design&node-id=3974-221778&mode=dev}
 */
export default function InvoiceTotalPaid({
  exchangeRate,
  transferFees,
  invoiceTotal,
  quoteAmount,
  beneficiaryCurrency,
  senderCurrency,
  context,
  classes = "",
  isPayOutsideVolopay,
  tooltipText,
  tooltipTextProps,
  isYSTRApplicable,
}) {
  return (
    <div className={classes}>
      <div className="flex flex-col gap-6">
        {/* invoice total */}
        <QuoteExchangeRate
          classes="px-6"
          senderCurrency={senderCurrency}
          beneficiaryCurrency={beneficiaryCurrency}
          exchangeRate={exchangeRate}
          transferFees={transferFees}
          isShowTransferFees={!isPayOutsideVolopay}
        />

        {/* amount to be paid */}
        <AmountToBePaid
          currency={quoteAmount?.currency || senderCurrency}
          amount={quoteAmount?.value ?? quoteAmount}
          tooltipText={tooltipText}
          tooltipTextProps={tooltipTextProps}
          descriptionText={
            isYSTRApplicable
              ? "billPay.bill.invoiceInbox.createBill.sections.lineItems.youAreSendingThisAmount"
              : ""
          }
        />
      </div>

      {/* exchange rate guideline text */}
      <Text
        classes="mt-2 text-neutral-500 text-xs font-normal inline-block"
        translationKey="misc.exchangeRateHelperText"
      />
    </div>
  );
}

InvoiceTotalPaid.propTypes = {
  exchangeRate: PropsTypes.oneOfType([PropsTypes.number, PropsTypes.string]), // BE sending number as string, fix later.
  transferFees: PropsTypes.object,
  invoiceTotal: PropsTypes.number,
  quoteAmount: PropsTypes.oneOfType([
    PropsTypes.number,
    PropsTypes.string,
    PropsTypes.object,
  ]),
  beneficiaryCurrency: PropsTypes.string,
  senderCurrency: PropsTypes.string,
  context: PropsTypes.string,
  classes: PropsTypes.string,
  isPayOutsideVolopay: PropsTypes.bool,

  tooltipText: PropsTypes.string,
  tooltipTextProps: PropsTypes.object,
  isYSTRApplicable: PropsTypes.bool,
};
