import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import { t } from "i18next";

import { setIsFormSubmissionProgress } from "@/store/reducers/loadersError";
import {
  getDateInPattern,
  getErrorToastMessage,
  getSuccessToastMessage,
} from "@/utils/common";
import { AVAILABLE_FILTER_KEYS } from "@/utils/constants/filters";
import vToast from "@/utils/vToast";

import {
  INVITATION_STATUS,
  MODULE_REQUEST_TYPES,
  SPEND_FILTER_DURATION,
  SPEND_FILTER_DURATION_LABELS,
  SPEND_FREQUENCY,
  SPEND_TYPES,
} from "@/constants/company";
import {
  PAGINATION_PER_REQUEST_LIMIT,
  TRAVEL_PAGINATION_PER_REQUEST_LIMIT,
} from "@/constants/pagination";
import API from "@/api";

const companyInitialState = {
  people: {
    list: [],
    shallowList: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetchingPeople: false,
    hasMorePeople: true,
  },
  peopleInvited: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetchingPeople: false,
    hasMorePeople: true,
    isLoading: false,
  },
  departments: {
    list: [],
    shallowList: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetchingDepartments: false,
    hasMoreDepartments: true,
  },
  projects: {
    list: [],
    shallowList: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetchingProjects: false,
    hasMoreProjects: true,
    isLoading: false,
  },
  roles: {
    list: [],
    roleName: "",
    roleDescription: "",
    roleDetails: null,
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetchingRoleDetails: false,
  },
  locations: {
    list: [],
    shallowList: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetchingLocations: false,
    hasMoreLocations: true,
    isLoading: false,
  },
  budgets: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetchingBudgets: true,
    hasMoreBudgets: true,
  },
  travel: {
    isCorporateTravelActivated: false,
    isFetching: false,
    peopleList: [],
    userHasAccessToTravel: false,
    truTripUsers: [],
    isLoading: false,
    isLoginInProgress: false,
    peoplePerPage: TRAVEL_PAGINATION_PER_REQUEST_LIMIT,
    offset: 1,
    totalTravelUsers: 0,
    fetchAgain: false,
    totalUser: [],
  },
  permissions: {
    permissions: {
      company: {
        default: false,
        accounts: {
          access: false,
          edit: false,
        },
        peopleLocation: {
          access: false,
          edit: false,
        },
        departments: {
          access: false,
          edit: false,
        },
        projects: {
          access: false,
          edit: false,
        },
        travel: {
          access: false,
        },
      },
      cards: {
        default: false,
        manage: {
          access: false,
          edit: false,
        },
        expenses: {
          access: false,
        },
        manageSettings: {
          access: false,
        },
      },
      billPay: {
        default: false,
        bills: {
          access: false,
          edit: false,
        },
        payments: {
          access: false,
          edit: false,
        },
        manageSettings: {
          access: false,
        },
      },
      reimbursements: {
        default: false,
        claims: {
          access: false,
        },
        claimPayments: {
          access: false,
        },
        manageSettings: {
          access: false,
        },
      },
      accounting: {
        default: false,
        accounting: {
          access: false,
        },
      },
      settings: {
        default: false,
        manageSettings: {
          access: false,
        },
      },
    },
    isFetchingRolePermissions: false,
    isLoadingPermissions: false,
  },
  billing: {
    list: [],
    page: 1,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
    isFetchingTableData: false,
    hasMore: true,
  },
  companyBillingDetails: {
    planDetails: null,
    paymentDetails: [],
    bannerDetails: null,
    pricingUrl: null,
  },
  upcominInvoiceDetails: null,
  invoiceSliderDetails: null,
  companyFilters: {
    spendType: SPEND_TYPES.TOTAL,
    to: getDateInPattern(new Date(), "yyyy-mm-dd"),
    from: getDateInPattern(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      "yyyy-mm-dd"
    ),
    frequency: SPEND_FREQUENCY.WEEKLY,
  },
  peopleInvitedCount: null,
  durationLabel: SPEND_FILTER_DURATION_LABELS[SPEND_FILTER_DURATION.THIS_MONTH],
  walletAccounts: [],
  isFetchingWalletAccounts: false,
  selectedWalletAccount: null,
  isFetchingSelectedWalletAccount: false,
  selectedPaymentOptionDetails: null,
  isFetchingSelectedPaymentOptionDetails: false,
  selectedProject: null,
  selectedDepartment: null,
  isFetchingProject: false,
  isFetchingDepartment: false,
  isFetchingRoles: false,
  budgetFormData: [],
  reviewBudget: { oldBudget: null, newBudget: null },
  selectedBudget: null,
  isFetchingSelectedBudget: false,
  departmentOrProjectFormData: null,
  selectedLocation: null,
  isFetchingLocation: false,
  pendingActions: {
    companyMissingDetails: 0,
    companyDeclinedExpenses: 0,
    companyFailedBillPayments: 0,
  },

  cashbacks: {
    available: { amount: 0, currency: "" },
    currentCycle: { amount: 0, currency: "" },
    currentMonth: { amount: 0, currency: "" },
    total: { amount: 0, currency: "" },
  },
  isFetchingCashbackData: false,
  isFetchingPendingActions: false,
  topCountItems: {
    list: [],
    page: 0,
    hasMore: true,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    total: null,
  },
  isFetchingTopCountItems: false,
  filtersKeys: {
    departments: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.budgetedFor,
      AVAILABLE_FILTER_KEYS.haveDefaultPolicy,
    ],
    budgets: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.budgetedFor,
      AVAILABLE_FILTER_KEYS.budgetType,
      AVAILABLE_FILTER_KEYS.budgetTypeDateRange,
      AVAILABLE_FILTER_KEYS.limit,
    ],
    locations: [AVAILABLE_FILTER_KEYS.searchAndFilter],
    projects: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.budgetedFor,
      AVAILABLE_FILTER_KEYS.haveDefaultPolicy,
    ],
    billing: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.datePeriods,
      AVAILABLE_FILTER_KEYS.amount,
    ],
    people: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.userState,
      AVAILABLE_FILTER_KEYS.cardType,
    ],
    peopleInviteSlider: [AVAILABLE_FILTER_KEYS.searchAndFilter],
  },
  projectApprovers: [],
  pendingRequestTable: {
    list: [],
    hasMore: true,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    isFetching: false,
    page: 0,
    total: 0,
  },
  pendingFilterKeys: {
    [MODULE_REQUEST_TYPES.CARD_REQUEST]: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.cardModuleRequestType,
      AVAILABLE_FILTER_KEYS.cardModuleCardType,
    ],
    [MODULE_REQUEST_TYPES.BILL_PAY_REQUEST]: [],
    [MODULE_REQUEST_TYPES.REIMBURSEMENT_REQUEST]: [
      AVAILABLE_FILTER_KEYS.searchAndFilter,
      AVAILABLE_FILTER_KEYS.claimType,
    ],
    [MODULE_REQUEST_TYPES.PAYROLL_REQUEST]: [],
  },
  alerts: {},
  isFetchingAlerts: false,
  isLoadingAlerts: false,
  isFetchingBudgetData: false,
  budgetData: null,
  allowedBudgetsForModules: [],
};

const companySlice = createSlice({
  name: "company",
  initialState: companyInitialState,
  reducers: {
    resetCompanyStore: () => companyInitialState,
    setInitialDepartments(state) {
      state.departments = companyInitialState.departments;
    },
    // Departments
    resetDepartmentsStoreState(state) {
      state.departments = companyInitialState.departments;
    },
    setDepartments(state, action) {
      state.departments.list = action.payload;
    },
    setDepartmentsShallow(state, action) {
      state.departments.shallowList = action.payload;
    },
    setProjectsShallow(state, action) {
      state.projects.shallowList = action.payload;
    },
    addDepartments(state, action) {
      state.departments.list = [...state.departments.list, ...action.payload];
    },
    setIsFetchingDepartments(state, action) {
      state.departments.isFetchingDepartments = action.payload;
    },
    setDepartmentspage(state, action) {
      state.departments.page = action.payload;
    },
    setDepartmentsTotal(state, action) {
      state.departments.total = action.payload;
    },
    setDepartmentsHasMore(state) {
      state.departments.hasMoreDepartments =
        state.departments.list.length < state.departments.total;
    },
    setDepartmentsLimit(state, action) {
      state.departments.limit = action.payload;
    },
    // Roles
    setRoles(state, action) {
      state.roles.list = action.payload;
    },
    setIsFetchingRoleDetails(state, action) {
      state.roles.isFetchingRoleDetails = action.payload;
    },
    setIsFetchingRoles(state, action) {
      state.isFetchingRoles = action.payload;
    },
    // Locations
    setLocations(state, action) {
      state.locations.list = action.payload;
    },
    setLocationsShallow(state, action) {
      state.locations.shallowList = action.payload;
    },
    resetLocationsStoreState(state) {
      state.locations = companyInitialState.locations;
    },
    addLocations(state, action) {
      state.locations.list = [...state.locations.list, ...action.payload];
    },
    setIsFetchingLocations(state, action) {
      state.locations.isFetchingLocations = action.payload;
    },
    setIsLoadingLocations(state, action) {
      state.locations.isLoading = action.payload;
    },
    setLocationspage(state, action) {
      state.locations.page = action.payload;
    },
    setLocationsTotal(state, action) {
      state.locations.total = action.payload;
    },
    setLocationsHasMore(state) {
      state.locations.hasMoreLocations =
        state.locations.list.length < state.locations.total;
    },
    setLocationsLimit(state, action) {
      state.locations.limit = action.payload;
    },
    setSelectedLocation(state, action) {
      state.selectedLocation = action.payload;
    },
    // People
    resetPeopleStoreState(state) {
      state.people = companyInitialState.people;
    },
    setPeople(state, action) {
      state.people.list = action.payload;
    },
    setPeopleShallow(state, action) {
      state.people.shallowList = action.payload;
    },
    addPeople(state, action) {
      state.people.list = [...state.people.list, ...action.payload];
    },
    setIsFetchingPeople(state, action) {
      state.people.isFetchingPeople = action.payload;
    },
    setPeoplesHasMore(state) {
      state.people.hasMorePeople =
        state.people.list.length < state.people.total;
    },
    setPeopleTotal(state, action) {
      state.people.total = action.payload;
    },
    setPeoplePage(state, action) {
      state.people.page = action.payload;
    },
    setPeopleLimit(state, action) {
      state.people.limit = action.payload;
    },

    // PeopleInvited
    resetPeopleInvitedStoreState(state) {
      state.peopleInvited = companyInitialState.peopleInvited;
    },
    setPeopleInvited(state, action) {
      state.peopleInvited.list = action.payload;
    },
    addPeopleInvited(state, action) {
      state.peopleInvited.list = [
        ...state.peopleInvited.list,
        ...action.payload,
      ];
    },
    setIsFetchingPeopleInvited(state, action) {
      state.peopleInvited.isFetchingPeople = action.payload;
    },
    setPeopleInvitedHasMore(state) {
      state.peopleInvited.hasMorePeople =
        state.peopleInvited.list.length < state.peopleInvited.total;
    },
    setPeopleInvitedLimit(state, action) {
      state.peopleInvited.limit = action.payload;
    },
    setPeopleInvitedTotal(state, action) {
      state.peopleInvited.total = action.payload;
    },

    setPeopleInvitedCount(state, action) {
      state.peopleInvitedCount = action.payload;
    },

    setPeopleInvitedPage(state, action) {
      state.peopleInvited.page = action.payload;
    },
    setIsLoadingPeopleInvited(state, action) {
      state.peopleInvited.isLoading = action.payload;
    },

    // wallets
    setWalletAccounts(state, action) {
      state.walletAccounts = action.payload;
    },
    setIsFetchingWalletAccounts(state, action) {
      state.isFetchingWalletAccounts = action.payload;
    },
    setSelectedWalletAccount(state, action) {
      state.selectedWalletAccount = action.payload;
    },
    setIsFetchingSelectedWalletAccount(state, action) {
      state.isFetchingSelectedWalletAccount = action.payload;
    },
    setSelectedPaymentOptionDetails(state, action) {
      state.selectedPaymentOptionDetails = action.payload;
    },
    setIsFetchingSelectedPaymentOptionDetails(state, action) {
      state.isFetchingSelectedPaymentOptionDetails = action.payload;
    },
    // pending actions
    setCompanyPendingActions(state, action) {
      state.pendingActions = action.payload;
    },
    setCompanyIsFetchingPendingActions(state, action) {
      state.isFetchingPendingActions = action.payload;
    },
    setCashbacks(state, action) {
      state.cashbacks = action.payload;
    },
    setIsFetchingCashbackData(state, action) {
      state.isFetchingCashbackData = action.payload;
    },
    resetTopCountItems(state) {
      state.topCountItems = companyInitialState.topCountItems;
    },
    setTopCountItems(state, action) {
      state.topCountItems = action.payload;
    },
    setTopCountItemsList(state, action) {
      state.topCountItems.list = action.payload;
    },
    appendToTopCountItemsList(state, action) {
      state.topCountItems.list = [
        ...state.topCountItems.list,
        ...action.payload,
      ];
    },
    setIsFetchingTopCountItems(state, action) {
      state.isFetchingTopCountItems = action.payload;
    },
    setTopCountItemsHasMore(state) {
      state.topCountItems.hasMore =
        state.topCountItems.list.length < state.topCountItems.total;
    },
    setPaginationDataTopCountItems(state, action) {
      state.topCountItems.limit = action.payload.limit;
      state.topCountItems.page = action.payload.page;
      state.topCountItems.total = action.payload.total;
    },
    setCompanyFilters(state, action) {
      if (state.companyFilters)
        state.companyFilters = { ...state.companyFilters, ...action.payload };
      else state.companyFilters = action.payload;
    },
    setDurationLabel(state, action) {
      state.durationLabel = action.payload;
    },
    // project
    setInitialProjects(state) {
      state.projects = companyInitialState.projects;
    },
    // Departments
    resetProjectsStoreState(state) {
      state.projects = companyInitialState.projects;
    },
    setProjects(state, action) {
      state.projects.list = action.payload;
    },
    addProjects(state, action) {
      state.projects.list = [...state.projects.list, ...action.payload];
    },
    setIsFetchingProjects(state, action) {
      state.projects.isFetchingProjects = action.payload;
    },
    setProjectspage(state, action) {
      state.projects.page = action.payload;
    },
    setProjectsTotal(state, action) {
      state.projects.total = action.payload;
    },
    setProjectsHasMore(state) {
      state.projects.hasMoreProjects =
        state.projects.list.length < state.projects.total;
    },
    setProjectsLimit(state, action) {
      state.projects.limit = action.payload;
    },
    setIsLoadingProjects(state, action) {
      state.projects.isLoading = action.payload;
    },
    // budgets
    resetBudgetsStoreState(state) {
      state.budgets = companyInitialState.budgets;
    },
    setBudgets(state, action) {
      state.budgets.list = action.payload;
    },
    addBudgets(state, action) {
      state.budgets.list = [...state.budgets.list, ...action.payload];
    },
    setIsFetchingBudgets(state, action) {
      state.budgets.isFetchingBudgets = action.payload;
    },
    setBudgetspage(state, action) {
      state.budgets.page = action.payload;
    },
    setBudgetsTotal(state, action) {
      state.budgets.total = action.payload;
    },
    setBudgetsHasMore(state) {
      state.budgets.hasMoreBudgets =
        state.budgets.list.length < state.budgets.total;
    },
    setBudgetsLimit(state, action) {
      state.departments.limit = action.payload;
    },
    setSelectedProject(state, action) {
      state.selectedProject = action.payload;
    },
    setSelectedDepartment(state, action) {
      state.selectedDepartment = action.payload;
    },
    setIsFetchingProject(state, action) {
      state.isFetchingProject = action.payload;
    },
    setIsFetchingDepartment(state, action) {
      state.isFetchingDepartment = action.payload;
    },
    setBudgetFormData(state, action) {
      state.budgetFormData = [action.payload];
    },
    resetBudgetFormData(state) {
      state.budgetFormData = companyInitialState.budgetFormData;
    },
    setReviewBudgetOldData(state, action) {
      state.reviewBudget.oldBudget = action.payload;
    },
    setReviewBudgetNewData(state, action) {
      state.reviewBudget.newBudget = action.payload;
    },
    addBudgetFormData(state, action) {
      state.budgetFormData = [...state.budgetFormData, action.payload];
    },
    updateBudgetFormData(state, action) {
      state.budgetFormData = state.budgetFormData.map((item, index) => {
        if (action.payload.indexMode && index === action.payload.index)
          return action.payload.data;
        if (item.id && action.payload.id && item.id === action.payload.id)
          return action.payload.data;

        return item;
      });
    },
    resetBudgetFromData(state) {
      state.budgetFormData = [];
    },
    setDepartmentOrProjectFormData(state, action) {
      state.departmentOrProjectFormData = action.payload;
    },
    resetDepartmentOrProjectFormData(state) {
      state.departmentOrProjectFormData = null;
    },
    setSelectedBudget(state, action) {
      state.selectedBudget = action.payload;
    },
    setBudgetsAvailableForVariousModules(state, action) {
      state.allowedBudgetsForModules = action.payload;
    },
    setIsFetchingSelectedBudget(state, action) {
      state.isFetchingSelectedBudget = action.payload;
    },
    setProjectApprovers(state, action) {
      state.projectApprovers = action.payload;
    },

    // travel
    setIsCorporateTravelActivated(state, action) {
      state.travel.isCorporateTravelActivated = action.payload;
    },
    setTravelPeople(state, action) {
      state.travel.truTripUsers = action.payload;
    },
    addToTravelPeople(state, action) {
      state.travel.people = [...state.travel.people, ...action.payload];
    },
    deleteFromTravelPeople(state, action) {
      const idToDelete = action.payload;
      state.travel.people = state.travel.people.filter(
        (person) => person.id !== idToDelete
      );
    },
    setIsLoadingTravel(state, action) {
      state.travel.isLoading = action.payload;
    },
    setLoginInProgress(state, action) {
      state.travel.isLoginInProgress = action.payload;
    },
    // permissions
    resetPermissionsStoreState(state) {
      state.permissions = companyInitialState.permissions;
    },

    setRoleDetails(state, action) {
      state.roles.roleDetails = action.payload;
    },
    resetRoleDetails(state, action) {
      state.roles.roleDetails = null;
    },
    setPermissions(state, action) {
      state.permissions.permissions = action.payload;
    },
    setIsLoadingPermissions(state, action) {
      state.permissions.isLoadingPermissions = action.payload;
    },

    // Billing
    resetBillingDataState(state) {
      state.billing = companyInitialState.billing;
    },
    setBillingTableData(state, action) {
      state.billing.list = action.payload;
    },
    appendBillingTableData(state, action) {
      state.billing.list = [...state.billing.list, ...action.payload];
    },
    setBillingTablePage(state, action) {
      state.billing.page = action.payload;
    },

    setBillingTableHasMore(state, action) {
      state.billing.hasMore = state.billing.list.length < state.billing.total;
    },
    setBillingTableTotal(state, action) {
      state.billing.total = action.payload;
    },
    addBillingTableData(state, action) {
      state.billing.list = [...state.billing.list, ...action.payload];
    },
    setIsFetchingTableData(state, action) {
      state.billing.isFetchingTableData = action.payload;
    },

    // billing upcoming-Invoice-Details
    setUpcomingInvoiceDetails(state, action) {
      state.upcominInvoiceDetails = action.payload.data;
    },
    // billing invoice-Slider-Details
    setInvoiceDetails(state, action) {
      state.invoiceSliderDetails = action.payload.data;
    },
    // billing company-billing-Details
    setCompanyBillingDetails(state, action) {
      state.companyBillingDetails = {
        planDetails: action.payload.data.planDetails,
        paymentDetails: action.payload.data.paymentDetails,
        bannerDetails: action.payload.data.bannerData,
        pricingUrl: action.payload.data.pricingPlanUrl,
      };
    },
    setPendingList(state, action) {
      state.pendingRequestTable.list = action.payload;
    },
    setIsFetchingPendingList(state, action) {
      state.pendingRequestTable.isFetching = action.payload;
    },
    setPendingRequestPageConfig(state, action) {
      const { page, total, limit } = action.payload;
      if (page) {
        state.pendingRequestTable.page = page;
      }

      if (limit) {
        state.pendingRequestTable.limit = limit;
      }

      if (total) {
        state.pendingRequestTable.hasMore =
          state.pendingRequestTable.list.length < total;
      }

      if (total) {
        state.pendingRequestTable.total = total;
      }
    },
    appendToPendingList(state, action) {
      state.pendingRequestTable.list = [
        ...state.pendingRequestTable.list,
        ...action.payload,
      ];
    },
    resetPendingListAndPagination(state) {
      state.pendingRequestTable.list = [];
      state.pendingRequestTable.page = 1;
      state.pendingRequestTable.hasMore = true;
      state.pendingRequestTable.total = 0;
      state.pendingRequestTable.isFetching = true;
    },
    setAlerts(state, action) {
      state.alerts = action.payload;
    },
    setIsFetchingAlerts(state, action) {
      state.isFetchingAlerts = action.payload;
    },
    setIsLoadingAlerts(state, action) {
      state.isLoadingAlerts = action.payload;
    },
    setTravelFetchAgain(state, action) {
      state.travel.fetchAgain =
        action.payload?.length === state.travel.peoplePerPage;
    },
    setOffsetTravel(state, action) {
      state.travel.offset += action.payload;
    },
    setTotalTravelUsers(state, action) {
      const { data } = action.payload;
      state.travel.totalUser = state.travel.offset
        ? [...state.travel.totalUser, ...data]
        : data;
    },
    setIsFetchingBudgetData(state, action) {
      state.isFetchingBudgetData = action.payload;
    },
    setBudgetData(state, action) {
      state.budgetData = action.payload;
    },
  },
});

export const showErrorMessageToast = ({ error, dispatch, rejectWithValue }) => {
  vToast(getErrorToastMessage(error));
};

export const showSuccessMessageToast = ({ success, dispatch }) => {
  vToast(getSuccessToastMessage(success));
};

export const fetchProjectApprovers = createAsyncThunk(
  "company/fetchProjectApprovers",
  async (params, { dispatch }) => {
    const [err, response] = await to(API.Company.Project.getApprovers());
    if (response) {
      dispatch(setProjectApprovers(response));
    }
  }
);

export const fetchDepartments = createAsyncThunk(
  "company/fetchDepartments",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingDepartments(true));

    const [err, response] = await to(
      API.Company.Department.all({ ...params, department: true })
    );
    if (response?.data) {
      if (params?.page === 1) {
        dispatch(setDepartments(response.data?.list));
      } else {
        dispatch(addDepartments(response.data?.list));
      }
      dispatch(setDepartmentsLimit(response.data?.limit));
      dispatch(setDepartmentsTotal(response.data?.total));
      dispatch(setDepartmentspage(response.data?.page));
      dispatch(setDepartmentsHasMore());
    }
    dispatch(setIsFetchingDepartments(false));
  }
);

export const fetchShallowDepartments = createAsyncThunk(
  "company/fetchShallowDepartments",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingProjects(true));
    const [err, response] = await to(
      API.Company.Project.all({ department: true, shallow: true, ...params })
    );
    if (response?.data) {
      dispatch(setDepartmentsShallow(response?.data));
    }
    dispatch(setIsFetchingProjects(false));
  }
);

export const fetchShallowDepartmentAndProjects = createAsyncThunk(
  "company/fetchShallowDepartmentAndProjects",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingProjects(true));
    const [err, response] = await to(
      API.Company.Project.all({ show_both: true, shallow: true, ...params })
    );
    if (response?.data) {
      dispatch(setDepartmentsShallow(response?.data));
    }
    dispatch(setIsFetchingProjects(false));
  }
);

export const fetchCustomPolicy = createAsyncThunk(
  "company/fetchCustomPolicy",
  async (params, { dispatch }) => {
    if (params.type === "Department") dispatch(setIsFetchingDepartment(true));
    else dispatch(setIsFetchingProjects(true));
    const [err, response] = await to(API.Company.Project.policyGroups(params));
    if (response.data) {
      if (params.type === "Department") dispatch(setDepartments(response.data));
      else dispatch(setProjects(response.data));
    }
    if (params.type === "Department") dispatch(setIsFetchingDepartment(false));
    else dispatch(setIsFetchingProjects(false));
  }
);

export const redeemCashback = createAsyncThunk(
  "company/redeemCashback",
  async (params, { dispatch }) => {
    const [err, response] = await to(API.Company.redeemCashback(params));

    if (response?.data) {
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const allocateToPayroll = createAsyncThunk(
  "company/allocateToPayroll",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const [err, response] = await to(
      API.Account.allocateToPayroll(params.payload)
    );

    if (response?.data) {
      params.onSuccess();
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const fetchRoles = createAsyncThunk(
  "company/fetchRoles",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingRoles(true));
    const [err, response] = await to(API.Company.Roles.all(params));
    if (response?.data) {
      dispatch(setRoles(response?.data));
    }
    dispatch(setIsFetchingRoles(false));
  }
);

export const fetchAndSelectDepartment = createAsyncThunk(
  "company/fetchAndSelectDepartment",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingDepartment(true));

    const { id } = params;
    const [error, response] = await to(
      API.Company.Department.get({ id, department: true })
    );
    if (response?.data) {
      dispatch(setSelectedDepartment(response.data));
    }
    dispatch(setIsFetchingDepartment(false));
  }
);

export const fetchLocations = createAsyncThunk(
  "company/fetchLocations",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingLocations(true));
    const [err, response] = await to(API.Company.Location.all(params));
    if (response?.data) {
      if (params.page === 1) {
        dispatch(setLocations(response.data?.list));
      } else {
        dispatch(addLocations(response.data?.list));
      }
      dispatch(setLocationsLimit(response.data?.limit));
      dispatch(setLocationsTotal(response.data?.total));
      dispatch(setLocationspage(response.data?.page));
      dispatch(setLocationsHasMore());
    }
    dispatch(setIsFetchingLocations(false));
  }
);
export const fetchShallowLocations = createAsyncThunk(
  "company/fetchShallowLocations",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingLocations(true));
    const [err, response] = await to(
      API.Company.Location.all({ ...params, shallow: true })
    );
    if (response.data) {
      dispatch(setLocationsShallow(response.data));
    }
    dispatch(setIsFetchingLocations(false));
  }
);

export const createLocations = createAsyncThunk(
  "company/createLocations",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    dispatch(setIsLoadingLocations(true));
    const { onSuccess, locations } = params;
    const [err, response] = await to(
      API.Company.Location.create({ locations })
    );
    if (response?.data) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) onSuccess(true);
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsLoadingLocations(false));
    dispatch(setIsFormSubmissionProgress(false));
  }
);
export const editLocations = createAsyncThunk(
  "company/createLocations",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    dispatch(setIsLoadingLocations(true));
    const { onSuccess, ...rest } = params;
    const [err, response] = await to(API.Company.Location.edit(rest));
    if (response?.data) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) onSuccess(response?.data);
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsLoadingLocations(false));
    dispatch(setIsFormSubmissionProgress(false));
  }
);
export const fetchProjects = createAsyncThunk(
  "company/fetchProjects",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingProjects(true));
    const [err, response] = await to(API.Company.Project.all(params));
    if (response?.data) {
      if (response?.data?.page === 1) {
        dispatch(setProjects(response.data?.list));
      } else {
        dispatch(addProjects(response.data?.list));
      }
      dispatch(setProjectsLimit(response.data?.limit));
      dispatch(setProjectsTotal(response.data?.total));
      dispatch(setProjectspage(response.data?.page));
      dispatch(setProjectsHasMore());
    }
    dispatch(setIsFetchingProjects(false));
  }
);

export const fetchShallowProjects = createAsyncThunk(
  "company/fetchShallowProjects",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingProjects(true));
    const [err, response] = await to(
      API.Company.Project.all({ ...params, shallow: true })
    );
    if (response?.data) {
      dispatch(setProjects(response?.data));
    }
    dispatch(setIsFetchingProjects(false));
  }
);

export const addProject = createAsyncThunk(
  "company/addProject",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    dispatch(setIsLoadingProjects(true));
    const { onSuccess, ...rest } = params;
    const [error, response] = await to(API.Company.Project.addProject(rest));
    if (response?.data) {
      vToast(getSuccessToastMessage(response));
      onSuccess(response?.data?.data);
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsLoadingProjects(false));
    dispatch(setIsFormSubmissionProgress(false));
  }
);
export const updateProject = createAsyncThunk(
  "company/addProject",
  async (params, { dispatch }) => {
    dispatch(setIsLoadingProjects(true));
    const { onSuccess, onFinal = () => {}, ...rest } = params;
    const [error, response] = await to(
      API.Company.Project.update(rest.id, rest.payload)
    );
    if (response?.data) {
      vToast(getSuccessToastMessage(response));
      onSuccess(response?.data?.data);
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsLoadingProjects(false));
    if (onFinal) {
      onFinal();
    }
  }
);

export const updateProjectAndLocation = createAsyncThunk(
  "company/updateProjectAndLocation",
  async (params, { dispatch }) => {
    const { onSuccess = () => {}, projectPayload, locationPayload } = params;

    try {
      const [projectApiResponse, locationApiResponse] = await Promise.all([
        API.Company.Project.update(projectPayload.id, projectPayload.payload),
        API.Company.Location.edit(locationPayload),
      ]);

      vToast(getSuccessToastMessage(locationApiResponse));
      onSuccess();
    } catch (error) {
      vToast(getErrorToastMessage(error));
    }
  }
);

export const archiveProject = createAsyncThunk(
  "company/archiveProject",
  async (params, { dispatch }) => {
    dispatch(setIsLoadingProjects(true));
    const { onSuccess, id, payload } = params;
    const [error, response] = await to(
      API.Company.Project.archiveProject(id, payload)
    );
    if (response?.data) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) onSuccess();
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsLoadingProjects(false));
  }
);
export const archiveLocation = createAsyncThunk(
  "company/archiveLocation",
  async (params, { dispatch }) => {
    const { onSuccess, id, ...rest } = params;
    const [error, response] = await to(
      API.Company.Location.archive({ id, ...rest })
    );
    if (response?.data) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) onSuccess();
    } else {
      vToast(getErrorToastMessage(error));
    }
  }
);

export const fetchAndSelectProject = createAsyncThunk(
  "company/fetchAndSelectProject",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingProject(true));
    const [error, response] = await to(API.Company.Project.get(params));
    dispatch(setSelectedProject(response?.data));
    dispatch(setIsFetchingProject(false));
  }
);

export const fetchAndSelectLocation = createAsyncThunk(
  "company/fetchAndSelectLocation",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingLocations(true));
    const [error, response] = await to(API.Company.Location.get(params));
    if (response) {
      dispatch(setSelectedLocation(response?.data));
    }
    dispatch(setIsFetchingLocations(false));
  }
);

export const fetchBudgets = createAsyncThunk(
  "company/fetchBudgets",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingBudgets(true));
    const [err, response] = await to(API.Company.Budgets.all(params));
    if (response) {
      if (params.page === 1) {
        dispatch(setBudgets(response.data.list));
      } else {
        dispatch(addBudgets(response.data.list));
      }
      dispatch(setBudgetsLimit(response.data.limit));
      dispatch(setBudgetsTotal(response.data.total));
      dispatch(setBudgetspage(response.data.page));
      dispatch(setBudgetsHasMore());
    }
    dispatch(setIsFetchingBudgets(false));
  }
);

export const fetchAndSelectBudget = createAsyncThunk(
  "company/fetchAndSelectBudget",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingSelectedBudget(true));
    const [error, response] = await to(API.Company.Budgets.get(params));
    if (response) {
      dispatch(setSelectedBudget(response.data));
    }
    dispatch(setIsFetchingSelectedBudget(false));
  }
);
export const editBudget = createAsyncThunk(
  "company/editBudget",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    dispatch(setIsFetchingSelectedBudget(true));
    const { onSuccess, ...rest } = params;
    const [error, response] = await to(API.Company.Budgets.edit(rest));
    if (response?.data) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) onSuccess(response?.data?.data);
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsFetchingSelectedBudget(false));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const getBudgetsAvailableForModules = createAsyncThunk(
  "company/getBudgetsAvailableForModules",
  async (params, { dispatch }) => {
    const [error, response] = await to(API.Company.Budgets.getBudgets());
    if (response?.data) {
      dispatch(setBudgetsAvailableForVariousModules(response?.data));
    } else if (error) {
      vToast(getErrorToastMessage(error));
    }
  }
);

export const deleteBudget = createAsyncThunk(
  "company/deleteBudget",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingSelectedBudget(true));
    const { onSuccess, ...rest } = params;
    const [error, response] = await to(API.Company.Budgets.delete(rest));
    if (response?.data) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) onSuccess();
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsFetchingSelectedBudget(false));
  }
);

export const fetchPeople = createAsyncThunk(
  "company/fetchPeople",
  async (params, { dispatch }) => {
    const { page } = params;
    dispatch(setIsFetchingPeople(true));
    const [err, response] = await to(API.User.all(params));
    if (response?.data) {
      if (page === 1) {
        // the OR because fetchPeople across the app has no 'page' param, FIXME.
        dispatch(setPeople(response.data.list));
      } else {
        dispatch(addPeople(response.data.list));
      }
      dispatch(setPeopleTotal(response.data.total));
      dispatch(setPeopleLimit(response.data.limit));
      dispatch(setPeoplePage(response.data.page));
      dispatch(setPeoplesHasMore());
    }
    dispatch(setIsFetchingPeople(false));
  }
);

export const fetchPeopleShallow = createAsyncThunk(
  "company/fetchPeopleShallow",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingPeople(true));
    const [err, response] = await to(
      API.User.all({ ...params, shallow: true })
    );

    if (response?.data) {
      dispatch(setPeopleShallow(response.data));
    }
    dispatch(setIsFetchingPeople(false));
  }
);

export const fetchPeopleInvited = createAsyncThunk(
  "company/fetchPeopleInvited",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingPeopleInvited(true));

    const [err, response] = await to(API.User.all(params));
    if (response?.data) {
      if (params.page === 1 || !params.page) {
        dispatch(setPeopleInvited(response.data.list));
      } else {
        dispatch(addPeopleInvited(response.data.list));
      }

      dispatch(setPeopleInvitedLimit(response.data.limit));

      if (params?.invitation_status?.includes(INVITATION_STATUS.NOT_EXPIRED)) {
        dispatch(setPeopleInvitedCount(response.data.total));
      }
      dispatch(setPeopleInvitedTotal(response.data.total));
      dispatch(setPeopleInvitedPage(response.data.page));
      dispatch(setPeopleInvitedHasMore());
    }
    dispatch(setIsFetchingPeopleInvited(false));
  }
);
export const addCompanyTravel = createAsyncThunk(
  "company/addCompanyTravel",
  async (params, { dispatch }) => {
    const { onSuccess = () => {}, ...rest } = params;
    const [err, response] = await to(API.Company.Trutrips.addCompany(rest));
    if (response?.data) {
      if (onSuccess) onSuccess(response?.data);
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const fetchTravelPeople = createAsyncThunk(
  "company/fetchTravelPeople",
  async (params, { dispatch }) => {
    const { client_id, offset = 1, per_page = 100 } = params;
    const [err, response] = await to(
      API.Company.Trutrips.getUsers({ client_id, per_page, offset })
    );
    const list = response.data?.data;
    if (response && response.data?.data?.length) {
      dispatch(setOffsetTravel(offset + 1));
      dispatch(setTravelPeople(response.data?.data));
      dispatch(setTravelFetchAgain(response?.data?.data));
      dispatch(setTotalTravelUsers({ data: response?.data?.data }));
    }
  }
);

export const companyTravelLogin = createAsyncThunk(
  "company/companyTravelLogin",
  async (params, { dispatch }) => {
    dispatch(setLoginInProgress(true));
    const { onSuccess, ...rest } = params;
    const [err, response] = await to(API.Company.Trutrips.companyLogin(rest));
    if (response?.data) {
      onSuccess(response?.data);
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setLoginInProgress(false));
  }
);

export const addTravelPeople = createAsyncThunk(
  "company/addTravelPeople",
  async (params, { dispatch }) => {
    dispatch(setIsLoadingTravel(true));
    const { onSuccess = () => {}, ...rest } = params;
    const [err, response] = await to(API.Company.Trutrips.addUsers(rest));
    if (response?.data) onSuccess();
    else vToast(getErrorToastMessage(err));
    dispatch(setIsLoadingTravel(false));
  }
);

export const deleteTravelPeople = createAsyncThunk(
  "company/deleteTravelPeople",
  async (params, { dispatch }) => {
    const { onSuccess = () => {}, ...rest } = params;
    const [err, response] = await to(API.Company.Trutrips.deleteUsers(rest));
    if (response?.data) onSuccess();
    else vToast(getErrorToastMessage(err));
  }
);

export const fetchAndSelectWalletAccountDetails = createAsyncThunk(
  "company/fetchAndSelectWalletAccountDetails",
  async (params, { dispatch, getState }) => {
    const currentState = await getState();
    dispatch(setIsFetchingSelectedWalletAccount(true));

    const currentWallet = currentState.client.wallets.list.find(
      (acc) =>
        acc.accountType === params.accountType &&
        acc.currency === params.currency
    );

    dispatch(setSelectedWalletAccount(currentWallet));
    dispatch(setIsFetchingSelectedWalletAccount(false));
  }
);

export const fetchCompanyPendingActions = createAsyncThunk(
  "company/fetchCompanyPendingActions",
  async (params, { dispatch }) => {
    dispatch(setCompanyIsFetchingPendingActions(true));
    const [err, response] = await to(API.Company.getPendingActions());

    if (response) {
      dispatch(setCompanyPendingActions(response));
    }
    dispatch(setCompanyIsFetchingPendingActions(false));
  }
);

export const fetchTopCountItems = createAsyncThunk(
  "company/fetchTopCountItems",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingTopCountItems(true));
    const [err, response] = await to(API.ClientAnalytics.topCount(params));

    if (response?.data) {
      if (response.data?.page > 1) {
        dispatch(appendToTopCountItemsList(response?.data.list));
      } else {
        dispatch(setTopCountItemsList(response?.data.list));
      }

      dispatch(setPaginationDataTopCountItems(response?.data));
      dispatch(setTopCountItemsHasMore());
    }
    dispatch(setIsFetchingTopCountItems(false));
  }
);

export const fetchPermissions = createAsyncThunk(
  "company/fetchPermissions",
  async (params, { dispatch }) => {
    dispatch(setIsLoadingPermissions(true));
    const [err, response] = await to(API.Company.Permissions.all(params));
    if (response?.data) {
      dispatch(setPermissions(response?.data));
    }
    dispatch(setIsLoadingPermissions(false));
  }
);

export const fetchRoleDetails = createAsyncThunk(
  "company/fetchRoleDetails",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingRoleDetails(true));
    const [err, response] = await to(API.Company.Roles.get(params));
    if (response?.data) {
      dispatch(setRoleDetails(response?.data));
    }
    dispatch(setIsFetchingRoleDetails(false));
  }
);

export const addCustomRole = createAsyncThunk(
  "company/addCustomRole",
  async (params, { dispatch, rejectWithValue }) => {
    dispatch(setIsFormSubmissionProgress(true));
    dispatch(setIsLoadingPermissions(true));
    const { onSuccess, ...rest } = params;
    const [err, response] = await to(API.Company.Roles.addCustomRole(rest));
    if (response?.data) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) {
        onSuccess();
      }
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsLoadingPermissions(false));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const editCustomRole = createAsyncThunk(
  "company/editCustomRole",
  async (params, { dispatch, rejectWithValue }) => {
    dispatch(setIsFormSubmissionProgress(true));
    dispatch(setIsLoadingPermissions(true));
    const { onSuccess, ...rest } = params;
    const [err, response] = await to(API.Company.Roles.editCustomRole(rest));
    if (response?.data) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) {
        onSuccess();
      }
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsLoadingPermissions(false));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const deleteCustomRole = createAsyncThunk(
  "company/deleteCustomRole",
  async (params, { dispatch, rejectWithValue }) => {
    dispatch(setIsLoadingPermissions(true));
    const { onSuccess, ...rest } = params;
    const [err, response] = await to(API.Company.Roles.deleteCustomRole(rest));
    if (response?.data) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) onSuccess();
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsLoadingPermissions(false));
  }
);

export const fetchTableData = createAsyncThunk(
  "company/fetchTableData",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingTableData(true));
    const [err, response] = await to(API.Company.Billing.getInvoices(params));

    if (response && !err) {
      if (params.page === 1) {
        dispatch(setBillingTableData(response.data.list));
      } else {
        dispatch(appendBillingTableData(response.data.list));
      }
      dispatch(setBillingTableTotal(response.data.total));
      dispatch(setBillingTableHasMore());
      dispatch(setBillingTablePage(response.data.page));
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsFetchingTableData(false));
  }
);

export const fetchBillingDetails = createAsyncThunk(
  "company/fetchbillingDetails",
  async (params, { dispatch }) => {
    const [err, response] = await to(
      API.Company.Billing.getBillingDetails(params)
    );
    if (response) {
      dispatch(setCompanyBillingDetails(response));
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const fetchUpcomingInvoiceDetails = createAsyncThunk(
  "company/fetchUpcomingInvoiceDetails",
  async (params, { dispatch }) => {
    const [err, response] = await to(
      API.Company.Billing.getUpcomingInvoice(params)
    );
    if (response) {
      dispatch(setUpcomingInvoiceDetails(response));
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const fetchInvoiceSliderDetails = createAsyncThunk(
  "company/fetchInvoiceSliderDetails",
  async (params, { dispatch }) => {
    const [err, response] = await to(
      API.Company.Billing.getInvoiceDetails(params)
    );
    if (response) {
      dispatch(setInvoiceDetails(response));
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const payInvoice = createAsyncThunk(
  "company/fetchInvoiceSliderDetails",
  async (params, { dispatch }) => {
    const [err, response] = await to(API.Company.Billing.pay(params));
    if (response) {
      vToast(
        getSuccessToastMessage({ message: t("company.billing.paidSuccess") })
      );
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const fetchPendingRequestList = createAsyncThunk(
  "company/pendigRequestList",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingPendingList(true));
    const isStepPaginationEnabled = params?.stepPagination;
    delete params.stepPagination;
    const [err, response] = await to(API.Company.getPendingRequests(params));
    if (response?.data) {
      if (!isStepPaginationEnabled && response?.data?.page > 1) {
        dispatch(appendToPendingList(response?.data?.list));
      } else {
        dispatch(setPendingList(response?.data?.list));
      }

      dispatch(
        setPendingRequestPageConfig({
          page: response?.data?.page,
          limit: response?.data?.limit,
          total: response?.data?.total,
        })
      );
    }
    dispatch(setIsFetchingPendingList(false));
  }
);

export const resendUserInvite = createAsyncThunk(
  "company/resendUserInvite",
  async (params, { dispatch }) => {
    dispatch(setIsLoadingPeopleInvited(true));
    const { payload, onSuccess } = params;
    const [error, response] = await to(API.Auth.resendInvite(payload));
    if (response?.data) {
      vToast(getSuccessToastMessage(response));
      onSuccess();
    } else
      vToast(getErrorToastMessage(error, "company.people.invite.resendFail"));
    dispatch(setIsLoadingPeopleInvited(false));
  }
);

export const expireUserInvite = createAsyncThunk(
  "company/expireUserInvite",
  async (params, { dispatch }) => {
    dispatch(setIsLoadingPeopleInvited(true));
    const { payload, onSuccess } = params;
    const [error, response] = await to(API.Auth.expireInvite(payload));
    if (response) {
      vToast(getSuccessToastMessage(response));
      onSuccess();
    } else vToast(getErrorToastMessage(error));
    dispatch(setIsLoadingPeopleInvited(false));
  }
);

export const fetchCompanyAlerts = createAsyncThunk(
  "company/fetchCompanyAlerts",
  async (payload, { dispatch }) => {
    dispatch(setIsFetchingAlerts(true));
    const [error, response] = await to(API.GlobalSettings.getAlerts(payload));
    if (response?.data) {
      dispatch(setAlerts(response.data));
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsFetchingAlerts(false));
  }
);

export const updateCompanyAlert = createAsyncThunk(
  "company/updateCompanyAlert",
  async (payload, { dispatch }) => {
    dispatch(setIsLoadingAlerts(true));
    const [error, response] = await to(
      API.GlobalSettings.updateAlerts(payload)
    );
    if (response?.data) {
      dispatch(setAlerts(response.data));
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsLoadingAlerts(false));
  }
);

export const sendFeedback = createAsyncThunk(
  "company/sendFeedback",
  async (_params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const { onSuccess, payload = {} } = _params;
    const [error, response] = await to(API.Company.sendFeedback(payload));

    if (response?.data) {
      vToast(getSuccessToastMessage(response));
      onSuccess();
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const fetchBudgetData = createAsyncThunk(
  "company/fetchBudgetData",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingBudgetData(true));
    const [error, response] = await to(API.Company.getBudgetData(params));

    if (!error && response?.data) {
      dispatch(setBudgetData(response?.data?.data));
    } else if (error) {
      vToast(getErrorToastMessage({ error }));
    }

    dispatch(setIsFetchingBudgetData(false));
  }
);

export const {
  resetCompanyStore,
  // Departments
  resetDepartmentsStoreState,
  setDepartments,
  setDepartmentsShallow,
  setProjectsShallow,
  addDepartments,
  setIsFetchingDepartments,
  setDepartmentspage,
  setDepartmentsTotal,
  setDepartmentsHasMore,
  setDepartmentsLimit,
  setInitialDepartments,
  // Locations
  resetLocationsStoreState,
  setLocations,
  setLocationsShallow,
  addLocations,
  setIsFetchingLocations,
  setLocationspage,
  setLocationsTotal,
  setLocationsHasMore,
  setLocationsLimit,
  setSelectedLocation,
  setIsLoadingLocations,
  // People
  resetPeopleStoreState,
  setPeople,
  setPeopleShallow,
  addPeople,
  setIsFetchingPeople,
  setPeoplePage,
  setPeopleLimit,
  setPeopleTotal,
  setPeoplesHasMore,
  // People Invited
  resetPeopleInvitedStoreState,
  setPeopleInvited,
  addPeopleInvited,
  setIsFetchingPeopleInvited,
  setPeopleInvitedHasMore,
  setPeopleInvitedLimit,
  setPeopleInvitedPage,
  setPeopleInvitedTotal,
  setIsLoadingPeopleInvited,
  // Wallets
  setWalletAccounts,
  setIsFetchingWalletAccounts,
  setSelectedWalletAccount,
  setIsFetchingSelectedWalletAccount,
  setSelectedPaymentOptionDetails,
  setIsFetchingSelectedPaymentOptionDetails,
  // pending-actions
  setCompanyPendingActions,
  setCompanyIsFetchingPendingActions,
  setCashbacks,
  setIsFetchingCashbackData,
  resetTopCountItems,
  setTopCountItems,
  setTopCountItemsList,
  appendToTopCountItemsList,
  setIsFetchingTopCountItems,
  setTopCountItemsHasMore,
  setPaginationDataTopCountItems,
  setCompanyFilters,
  setDurationLabel,
  // projects
  resetProjectsStoreState,
  setProjectApprovers,
  setProjects,
  addProjects,
  setIsFetchingProjects,
  setProjectspage,
  setProjectsTotal,
  setProjectsHasMore,
  setProjectsLimit,
  setInitialProjects,
  setIsLoadingProjects,
  // budgets
  resetBudgetsStoreState,
  setReviewBudgetOldData,
  setReviewBudgetNewData,
  setBudgets,
  addBudgets,
  setIsFetchingBudgets,
  setBudgetspage,
  setBudgetsTotal,
  setBudgetsHasMore,
  setBudgetsLimit,
  setInitialBudgets,
  setSelectedProject,
  updateBudgetFormData,
  addBudgetFormData,
  setSelectedDepartment,
  setIsFetchingProject,
  setIsFetchingDepartment,
  setBudgetFormData,
  resetBudgetFromData,
  setDepartmentOrProjectFormData,
  setSelectedBudget,
  setIsFetchingSelectedBudget,

  // travel
  setIsCorporateTravelActivated,
  setTravelPeople,
  addToTravelPeople,
  deleteFromTravelPeople,
  setIsLoadingTravel,
  // Roles
  setRoles,
  setIsFetchingRoles,
  setIsFetchingRoleDetails,
  setRoleDescription,
  setRoleName,
  setRoleDetails,
  resetRoleDetails,
  // permissions
  setPermissions,
  setIsLoadingPermissions,
  // billing
  setBillingTableData,
  appendBillingTableData,
  resetBillingDataState,
  setBillingTableTotal,
  setBillingTablePage,
  setBillingTableHasMore,
  setBillingTableLimit,
  setIsFetchingTableData,
  setUpcomingInvoiceDetails,
  setInvoiceDetails,
  setCompanyBillingDetails,
  setPendingList,
  setIsFetchingPendingList,
  setPendingRequestPageConfig,
  appendToPendingList,
  resetPendingListAndPagination,
  resetDepartmentOrProjectFormData,
  setAlerts,
  setIsFetchingAlerts,
  setIsLoadingAlerts,
  setTravelFetchAgain,
  setOffsetTravel,
  setTotalTravelUsers,
  setLoginInProgress,
  setIsFetchingBudgetData,
  setBudgetData,
  setBudgetsAvailableForVariousModules,
  setPeopleInvitedCount,
  resetBudgetFormData,
} = companySlice.actions;

export default companySlice.reducer;
