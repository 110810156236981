import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLoadingErrorInjector from "@/hooks/useErrorLoader";
import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import {
  archivePolicy,
  fetchApprovalPolicy,
  setCurrentPolicy,
  upsertApprovalPolicyGroup,
} from "@/store/reducers/policy";

import {
  currentPoliciesSelector,
  currentPolicySelector,
  isUpdatingSelector,
  submitableSelector,
  tempCurrentPolicySelector,
} from "@/store/selectors/policy";

import Badge from "@/components/core/Badge";
import Button from "@/components/core/Button";
import Text from "@/components/core/Text";

import SliderHeader from "@/components/Accounting/Integrations/common/SliderHeader";
import DisableConfirmation from "@/components/Settings/Modals/DisableConfirmation";
import ApprovalWorkflow from "@/components/Settings/Sliders/ApprovalWorkflow";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  APPROVAL_PAYLOAD_MAPPING,
  APPROVAL_POLICY_PAYLOADS,
  POLICY_CTA,
  POLICY_GROUP_MAPPING,
  POLICY_LAYER,
} from "@/constants/policy";
import { ROUTES } from "@/constants/routes";

import NonEditableSettingSliderFooter from "../common/NonEditableSettingSliderFooter";

export default function ApprovalPolicySlider() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const approvalPolicyObject = useSelector(currentPoliciesSelector);
  const curtPolicy = useSelector(currentPolicySelector);
  const tempCurtPolicy = useSelector(tempCurrentPolicySelector);
  const isUpdating = useSelector(isUpdatingSelector);
  const submitable = useSelector(submitableSelector);

  const [showDisableModal, setShowDisableModal] = useState(false);
  const [resetPolicy, setResetPolicy] = useState(false);

  const editState = searchParams.get(SLIDERS_SEARCH_PARAMS.settings.editState);

  const addDeptOrProject =
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.project.add) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.department.add);

  const projectOrDepartmentId =
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.department.id) ||
    searchParams.get(SLIDERS_SEARCH_PARAMS.company.project.id);

  const approvalPolicyType = searchParams.get(
    SLIDERS_SEARCH_PARAMS.settings.approvalPolicy
  );
  const policyId = searchParams.get(SLIDERS_SEARCH_PARAMS.settings.policyId);
  const selectedModule = searchParams.get(
    SLIDERS_SEARCH_PARAMS.settings.policyModule
  );

  const projectOrDepartment =
    [POLICY_LAYER.project, POLICY_LAYER.department].includes(
      approvalPolicyType
    ) || selectedModule === POLICY_GROUP_MAPPING.expenseReview;

  const toggleEdit = () => {
    if (!editState) {
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.settings.editState,
        curtPolicy?.default ? POLICY_LAYER.company : POLICY_LAYER.custom
      );
      setSearchParams(searchParams);
    } else {
      setShowDisableModal(true);
    }
  };

  const checkPolicyUpdatable = () => {
    return (
      (editState && !submitable) ||
      (editState &&
        projectOrDepartment &&
        curtPolicy?.default &&
        JSON.stringify(curtPolicy) === JSON.stringify(tempCurtPolicy))
    );
  };

  const closeSlider = (id) => {
    if ((editState && id) || resetPolicy) {
      searchParams.delete(SLIDERS_SEARCH_PARAMS.settings.editState);
      searchParams.delete(SLIDERS_SEARCH_PARAMS.settings.policyId);
      searchParams.set(
        SLIDERS_SEARCH_PARAMS.settings.policyId,
        id || curtPolicy.id
      );
    } else searchParams.delete(SLIDERS_SEARCH_PARAMS.settings.editState);
    setSearchParams(searchParams);
  };

  const submitPolicy = async () => {
    setShowDisableModal(false);

    const params = {
      type: POLICY_LAYER.company,
    };
    if (resetPolicy || projectOrDepartmentId) {
      // removing default check since projectId is required for dept_manager or ppl with dept_manage permission to have project id to edit the custom policy
      params.projectId = projectOrDepartmentId;
      params.policyGroupType = selectedModule;

      if (POLICY_GROUP_MAPPING.expenseReview === selectedModule)
        params.policyGroupType = APPROVAL_PAYLOAD_MAPPING[selectedModule];
    }

    if (resetPolicy) await dispatch(archivePolicy({ ...params, closeSlider }));
    else await dispatch(upsertApprovalPolicyGroup({ ...params, closeSlider }));
  };

  useEffect(() => {
    const approvalPolicy =
      approvalPolicyObject &&
      approvalPolicyObject[APPROVAL_POLICY_PAYLOADS[selectedModule]];

    if (!approvalPolicyType || policyId) {
      const params = {
        policy_group_type: APPROVAL_PAYLOAD_MAPPING[selectedModule],
      };
      if (policyId) params.id = policyId;
      if (projectOrDepartment) params.project_id = projectOrDepartmentId;
      dispatch(fetchApprovalPolicy(params));
    }

    if (!editState) dispatch(setCurrentPolicy(approvalPolicy));
  }, [selectedModule, approvalPolicyObject]);

  const sliderHeader =
    selectedModule === POLICY_GROUP_MAPPING.expenseReview
      ? `policy.${selectedModule}`
      : editState
        ? t(
            "settings.spendControls.approvalPolicy.approvalWorkFlow.editTitle",
            {
              MODULE_DISPLAY_NAMES: t(`policy.${selectedModule}`),
            }
          )
        : t("settings.spendControls.approvalPolicy.approvalWorkFlow.title", {
            MODULE_DISPLAY_NAMES: t(`policy.${selectedModule}`),
          });

  const titleRef = useLeftHeaderTitle({ title: sliderHeader });

  const allState = useLoadingErrorInjector({
    apiKey: "ApprovalPolicy:getPolicy",
    showLoader: false,
    error: {
      header: "",
    },
  });

  return (
    <div className="slider-content-container" ref={allState?.attach}>
      <div className="slider-content-core pb-7">
        <div className="flex flex-col gap-1">
          <div className="flex items-center">
            <Text
              classes="text-2xl font-semibold mr-4"
              translationKey={sliderHeader}
              refProp={titleRef}
            />
            {projectOrDepartmentId ? (
              <Badge
                variant={curtPolicy?.default ? "neutral" : "primary"}
                translationKey={
                  curtPolicy?.default ? "common.company" : "common.custom"
                }
                classes="w-13"
              />
            ) : null}
          </div>
          <Text
            classes="text-sm text-neutral-500"
            translationKey={`policy.approvalModules.${selectedModule}.description`}
          />
        </div>
        <ApprovalWorkflow state={editState ? "edit" : "view"} />
      </div>
      {curtPolicy?.ctas && !addDeptOrProject ? (
        <div className="slider-footer">
          <div
            className={`flex items-center ${
              curtPolicy?.ctas?.[0] === POLICY_CTA.EDIT_GLOBAL_POLICY
                ? ""
                : "justify-end"
            } gap-4 p-4`}
          >
            {editState ? (
              <Button
                label="misc.cancel"
                classes="w-[150px]
            text-neutral-600 text-sm"
                variant="tertiary"
                onClick={() => closeSlider(null)}
              />
            ) : curtPolicy?.ctas?.[0] === POLICY_CTA.EDIT_GLOBAL_POLICY ? (
              <NonEditableSettingSliderFooter
                linkRedirect={`${ROUTES.manage.settings.spendControls.absolutePath}?${SLIDERS_SEARCH_PARAMS.settings.approvalPolicy}=${POLICY_LAYER.company}&${SLIDERS_SEARCH_PARAMS.settings.policyId}=${policyId}&${SLIDERS_SEARCH_PARAMS.settings.policyModule}=${selectedModule}&editState=${POLICY_LAYER.company}`}
              />
            ) : projectOrDepartmentId &&
              curtPolicy?.ctas?.[0] === POLICY_CTA.EDIT_POLICY ? (
              <Button
                label="settings.spendControls.revertToCompanyPolicy"
                classes="w-20 text-sm"
                variant="tertiary"
                onClick={() => {
                  setResetPolicy(true);
                  setShowDisableModal(true);
                }}
              />
            ) : null}

            {curtPolicy?.ctas?.[0] !== POLICY_CTA.EDIT_GLOBAL_POLICY ||
            editState ? (
              <Button
                label={
                  !editState
                    ? `settings.spendControls.${curtPolicy?.ctas?.[0]}`
                    : "common.save"
                }
                classes="w-16 text-sm"
                variant="primary"
                disabled={checkPolicyUpdatable()}
                showLoader={isUpdating}
                onClick={toggleEdit}
              />
            ) : null}
          </div>
        </div>
      ) : null}
      <DisableConfirmation
        header="settings.spendControls.approvalPolicy.updatePolicy.header"
        titleBody={
          <ul className="px-8 py-4 font-semibold list-disc">
            <li>
              <Text translationKey="settings.spendControls.approvalPolicy.confirmMsg1" />
            </li>
            <li>
              <Text translationKey="settings.spendControls.approvalPolicy.confirmMsg2" />
            </li>
            <li>
              <Text translationKey="settings.spendControls.approvalPolicy.confirmMsg3" />
            </li>
          </ul>
        }
        desc="settings.spendControls.approvalPolicy.updatePolicy.desc"
        showModal={showDisableModal}
        setShowModal={setShowDisableModal}
        onSubmitClick={() => {
          submitPolicy();
        }}
        labelText="CONFIRM"
        submitBtnType="default"
        submitBtnLabel="Submit"
      />
    </div>
  );
}
