import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { approveCardRequest } from "@/store/reducers/actionCentre";
import { setIndexApiReload } from "@/store/reducers/app";
import {
  getRequestApprovalInfo,
  resetSliderInfo,
  setSelectedTabForCardSpecificRequestSliders,
} from "@/store/reducers/cards";

import { indexApiReloadSelector } from "@/store/selectors/app";
import {
  cardRequestDetailsIsFetchingSelector,
  cardSpecificRequestSlidersSelectedTabSelector,
  newCardRequestDetailsSelector,
} from "@/store/selectors/cards";
import { appliedFilterSelector } from "@/store/selectors/filters";
import { userSelector } from "@/store/selectors/user";

import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";
import ModuleHistory from "@/components/common/ModuleHistory";
import { convertFilters } from "@/utils/filters";

import { OWNER_TYPE_MASTER_HISTORY } from "@/utils/constants/app";
import { ACTION_CENTRE_APPROVAL_TYPE } from "@/constants/ActionCentre";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { BE_FE_REQUEST_TYPE_CARD_REQUEST } from "@/constants/company";

import RequestSliderFooter from "../../common/RequestSliderFooter";
import OverviewTab from "./OverviewTab";

function PhysicalCardRequestSlider({ type, setOnClose }) {
  const isFetching = useSelector(cardRequestDetailsIsFetchingSelector);
  const tabs = [
    {
      name: "cards.cardDetails.overview.navigationLabel",
      count: null,
      key: 1,
    },
    {
      name: "cards.cardDetails.overview.history.navigationLabel",
      count: null,
      key: 2,
      showComingSoon: true,
    },
  ];

  const dispatch = useDispatch();

  const [searchParams, setSerachParams] = useSearchParams();

  const cardRequestDetails = useSelector(newCardRequestDetailsSelector);

  const isEmpty = Object.keys(cardRequestDetails ?? {}).length === 0;
  const reloadIndexApi = useSelector(indexApiReloadSelector);

  const sliderMainTitle =
    "myVolopay.actionCentre.sliders.requestSliders.orderCard.physical.label";

  const ref = useLeftHeaderTitle(
    {
      title: sliderMainTitle,
    },
    true
  );

  const selectedTab = useSelector(
    cardSpecificRequestSlidersSelectedTabSelector
  );
  const selectedTabKey = selectedTab?.key;

  useEffect(() => {
    handleTabChange(tabs[0]);
  }, []);

  function handleTabChange(tab) {
    dispatch(setSelectedTabForCardSpecificRequestSliders(tab));
  }

  function handleReject() {
    searchParams.append(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cancelOrderRequest,
      searchParams.get(
        SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest
          .physicalCardRequest
      )
    );
    setSerachParams(searchParams);
  }

  const onSucess = () => {
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cancelOrderRequest
    );
    searchParams.delete(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest?.[
        BE_FE_REQUEST_TYPE_CARD_REQUEST[cardRequestDetails?.requestType]
      ]
    );
    dispatch(setIndexApiReload(!reloadIndexApi));
    setSerachParams(searchParams);
  };

  const handleApprove = () => {
    const id = searchParams.get(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest
        .physicalCardRequest
    );
    dispatch(
      approveCardRequest({
        target_id: id,
        type: ACTION_CENTRE_APPROVAL_TYPE.card,
        onSucess,
        inSlider: true,
      })
    );
  };

  useEffect(() => {
    setOnClose((searchParam) => {
      const sliderKey =
        SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest
          .physicalCardRequest;
      const isClosed = !searchParam?.includes(sliderKey);
      if (isClosed) {
        dispatch(resetSliderInfo());
      }
    });
  }, []);

  useEffect(() => {
    const id = searchParams.get(
      SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest?.[
        BE_FE_REQUEST_TYPE_CARD_REQUEST[type]
      ]
    );
    if (id) dispatch(getRequestApprovalInfo({ id }));
  }, [searchParams]);

  return (
    <>
      {isFetching || !isEmpty ? (
        <>
          <div className="flex flex-col gap-4 p-9 slider-content-core">
            <div ref={ref}>
              <Text
                translationKey={sliderMainTitle}
                classes="text-4xl font-bold non-italic"
              />
            </div>

            <div className="mt-6">
              <Tabs
                items={tabs}
                selectedTab={selectedTabKey}
                setCurrentTab={handleTabChange}
                mode
              />
            </div>

            {selectedTabKey === 1 ? (
              <OverviewTab type={type} />
            ) : (
              <ModuleHistory
                ownerId={cardRequestDetails?.id}
                ownerType={OWNER_TYPE_MASTER_HISTORY.PHYSICAL_CARD_REQUEST}
              />
            )}
          </div>

          {selectedTabKey === 1 ? (
            <RequestSliderFooter
              handleApprove={handleApprove}
              handleReject={handleReject}
            />
          ) : null}
        </>
      ) : null}
      {isFetching ? "loading..." : null}
      {!isFetching && isEmpty ? "Error" : null}
    </>
  );
}

export default PhysicalCardRequestSlider;
PhysicalCardRequestSlider.propTypes = {
  type: PropTypes.string,
  setOnClose: PropTypes.func,
};
