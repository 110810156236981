import PropTypes from "prop-types";

import Text from "@/components/core/Text";

/**
 * Line item section heading
 */
export default function Heading({
  title = "billPay.bill.invoiceInbox.createBill.sections.lineItems.title",
  onAddLineItemHandler = () => {},
  hidePlusButton,
}) {
  return (
    <div
      className="flex items-center justify-between gap-3"
      aria-label="heading-container"
    >
      <Text
        translationKey={title}
        classes="text-xl font-bold text-neutral-800"
      />
      {hidePlusButton ? null : (
        <span onClick={onAddLineItemHandler} className="cursor-pointer">
          <Text
            translationKey="billPay.bill.invoiceInbox.createBill.sections.lineItems.addLineItem"
            classes="text-primary-500 font-semibold"
          />
        </span>
      )}
    </div>
  );
}

Heading.propTypes = {
  onAddLineItemHandler: PropTypes.func,
  hidePlusButton: PropTypes.bool,
};
