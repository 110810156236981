import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchChildLogs,
  fetchMasterHistoryLogs,
  resetChildLogs,
  resetMasterHistoryLogs,
} from "@/store/reducers/master-history";

import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  childLogsHasMoreSelector,
  childLogsListSelector,
  isFetchingChildLogsSelector,
  isFetchingMasterHistoryLogsSelector,
  masterHistoryLogsHasMoreSelector,
  masterHistoryLogsListSelector,
} from "@/store/selectors/master-history";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import { convertFilters } from "@/utils/filters";
import { camelToSnake, groupByDate } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import {
  MASTER_HISTORY_NAVIGATION_CONFIG,
  MASTER_HISTORY_TYPES,
} from "@/constants/settings";

import MasterHistoryDumbComponent from "../MasterHistoryDumbComponent";

export default function MasterHistoryList({ parentId = null }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState("");

  const appliedFilters = useSelector(appliedFilterSelector);
  const filterOptions = {
    q: searchText,
    ...convertFilters(appliedFilters),
  };

  const historyListType = parentId
    ? MASTER_HISTORY_TYPES.CHILD_LOGS
    : MASTER_HISTORY_TYPES.MASTER_HISTORY;

  const HISTORY_SELECTOR_CONFIG = {
    MASTER_HISTORY: {
      isFetching: isFetchingMasterHistoryLogsSelector,
      hasMore: masterHistoryLogsHasMoreSelector,
      logsData: masterHistoryLogsListSelector,
      fetchHistory: fetchMasterHistoryLogs,
      resetHistory: resetMasterHistoryLogs,
    },
    CHILD_LOGS: {
      isFetching: isFetchingChildLogsSelector,
      hasMore: childLogsHasMoreSelector,
      logsData: childLogsListSelector,
      fetchHistory: fetchChildLogs,
      resetHistory: resetChildLogs,
    },
  };
  const SELECTED_CONFIG = HISTORY_SELECTOR_CONFIG[historyListType];

  const logsData = useSelector(SELECTED_CONFIG.logsData);
  const hasMore = useSelector(SELECTED_CONFIG.hasMore);
  const isFetching = useSelector(SELECTED_CONFIG.isFetching);
  const groupedLogData = groupByDate(logsData, "createdAt");

  const groupedLogsDataLength = Object.keys(groupedLogData).length;

  const handleChildLogsSliderOpen = (parentID) => {
    searchParams.append(SLIDERS_SEARCH_PARAMS.settings.logId, parentID);
    setSearchParams(searchParams);
  };
  const updateSearchParam = ({
    activityType,
    searchParamValueId,
    searchParamsHook,
    ownerType,
  }) => {
    const searchParamConfig =
      MASTER_HISTORY_NAVIGATION_CONFIG[`${activityType}-${ownerType}`];

    if (Array.isArray(searchParamConfig)) {
      searchParamConfig?.map((currentSearchParam) => {
        if (Array.isArray(currentSearchParam)) {
          searchParamsHook.append(currentSearchParam[0], currentSearchParam[1]);
        } else {
          searchParamsHook.append(currentSearchParam, searchParamValueId);
        }
      });
    } else if (searchParamConfig) {
      searchParamsHook.append(searchParamConfig, searchParamValueId);
    }
    return searchParamsHook;
  };
  const handleActivityTypeSliderOpen = (log) => {
    const ownerType = camelToSnake(log?.ownerType);

    const updatedSearchParams = updateSearchParam({
      activityType: log?.activityType,
      ownerType,
      searchParamValueId: log?.ownerId,
      searchParamsHook: searchParams,
    });

    setSearchParams(updatedSearchParams);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchText(searchParams.get("search_query"));
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [searchParams]);

  function loadMore() {
    let params = {
      page: pageNum,
      limit: PAGINATION_PER_REQUEST_LIMIT,
      ...filterOptions,
    };
    if (historyListType === MASTER_HISTORY_TYPES.CHILD_LOGS)
      params = { ...params, parent: parentId };
    dispatch(SELECTED_CONFIG.fetchHistory(params));
  }

  function onReset() {
    dispatch(SELECTED_CONFIG.resetHistory());
  }

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions,
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll);

  return (
    <div>
      {groupedLogsDataLength > 0 ? (
        <MasterHistoryDumbComponent
          handleRefChange={handleRefChange}
          hasMore={hasMore}
          logsData={!parentId ? groupedLogData : logsData}
          handleActivityTypeSliderOpen={handleActivityTypeSliderOpen}
          handleChildLogsSliderOpen={handleChildLogsSliderOpen}
          isGrouped={!parentId}
        />
      ) : null}
      {isFetching
        ? [...Array(5)].map((_, index) => (
            <div
              className="flex items-center w-full gap-10 text-center"
              key={`pending-table-loader-item-${index}`}
            >
              <div className="w-1/2">
                <LoaderSkeleton fullWidth />
              </div>

              <div className="w-1/2">
                <LoaderSkeleton fullWidth />
              </div>
            </div>
          ))
        : null}
    </div>
  );
}

MasterHistoryList.propTypes = {
  parentId: PropTypes.number,
};
