import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { updateClientSettings } from "@/store/reducers/client";

import {
  approvalPolicyEnabledSelector,
  cardsMakerCheckerEnabledSelector,
  customApprovalPolicyEnabledSelector,
  customExpenseReviewPolicyEnabledSelector,
  customSubmissionPolicyEnabledSelector,
  expenseReviewMakerCheckerEnabledSelector,
  expenseReviewPolicyEnabledSelector,
  physicalCardPolicyEnabledSelector,
  primaryCardProviderConfigSelector,
  primaryCardProviderSelector,
  submissionPolicyEnabledSelector,
} from "@/store/selectors/client";

import Text from "@/components/core/Text";

import SettingsList from "@/components/common/BillPayAndPayroll/Settings/common/SettingsList";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";
import PremiumContactSupport from "@/components/common/PremiumContactSupport";
import { MODULES } from "@/utils/constants/app";

import { CARD_PROVIDER } from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  APPROVAL_PAYLOAD_MAPPING,
  POLICY_GROUP_MAPPING,
  POLICY_TYPE,
  SUBMISSION_PAYLOAD_MAPPING,
} from "@/constants/policy";
import { SETTINGS_PARAMS } from "@/constants/settings";

function SpendControlsPageHelper({ page }) {
  const dispatch = useDispatch();

  const submissionPolicyEnabled = useSelector(submissionPolicyEnabledSelector);
  const approvalPolicyEnabled = useSelector(approvalPolicyEnabledSelector);
  const customApprovalPolicyEnabled = useSelector(
    customApprovalPolicyEnabledSelector
  );
  const physicalCardPolicyEnabled = useSelector(
    physicalCardPolicyEnabledSelector
  );
  const customSubmissionPolicyEnabled = useSelector(
    customSubmissionPolicyEnabledSelector
  );
  const primaryCardProvider = useSelector(primaryCardProviderConfigSelector);
  const isMccEnabled = primaryCardProvider?.isMccEnabled;

  const cardProvider = useSelector(primaryCardProviderSelector);
  const cardProviderConfigPhysicalCardPolicyEnabled = useSelector(
    primaryCardProviderConfigSelector
  )?.physicalCardPolicyEnabled;

  const cardProviderName = cardProvider?.slug;

  const cardsMakerCheckerEnabled = useSelector(
    cardsMakerCheckerEnabledSelector
  );

  const expenseReviewMakerCheckerEnabled = useSelector(
    expenseReviewMakerCheckerEnabledSelector
  );

  const expenseReviewsPolicyEnabled = useSelector(
    expenseReviewPolicyEnabledSelector
  );

  const customExpenseReviewsPolicyEnabled = useSelector(
    customExpenseReviewPolicyEnabledSelector
  );

  const openMerchantAndCategoryControls = ![
    CARD_PROVIDER.LIVQUIK,
    CARD_PROVIDER.PINELABS,
  ].includes(cardProviderName?.toLowerCase());

  const SPEND_CONTROL_CONFIG = [
    {
      title: "cards.settings.spendControl.preSpend.title",
      description: "cards.settings.spendControl.preSpend.description",
      rightChildren: [
        {
          id: POLICY_TYPE.APPROVAL,
          title: "settings.spendControls.approvalPolicy.title",
          description: "settings.spendControls.approvalPolicy.description",
          sliderType:
            approvalPolicyEnabled &&
            SLIDERS_SEARCH_PARAMS.settings.policyModule,
          sliderId: approvalPolicyEnabled && POLICY_GROUP_MAPPING.cards,
          disableMessage: approvalPolicyEnabled
            ? null
            : "settings.spendControls.approvalPolicy.alertDisabledModuleDesc",
        },
        {
          id: POLICY_TYPE.CUSTOM_POLICY,
          title: "settings.spendControls.customPolicy.title",
          description: "settings.spendControls.customPolicy.description",
          sliderType:
            customApprovalPolicyEnabled &&
            SLIDERS_SEARCH_PARAMS.settings.customApprovalModule,
          sliderId: customApprovalPolicyEnabled && MODULES.CARDS,
          showItem:
            cardProviderName?.toLowerCase() !== CARD_PROVIDER.UOB &&
            approvalPolicyEnabled,
          disableMessage: customApprovalPolicyEnabled
            ? null
            : "settings.spendControls.customPolicy.alertDisabledModuleDesc",
        },
        {
          id: MODULES.PHYSICAL_CARD,
          title: "settings.spendControls.physicalCardPolicy.title",
          description: "settings.spendControls.physicalCardPolicy.description",
          sliderType:
            physicalCardPolicyEnabled &&
            SLIDERS_SEARCH_PARAMS.settings.policyModule,
          sliderId: physicalCardPolicyEnabled && MODULES.PHYSICAL_CARD,
          showItem: cardProviderConfigPhysicalCardPolicyEnabled,
          disableMessage: physicalCardPolicyEnabled
            ? null
            : "settings.spendControls.physicalCardPolicy.alertDisabledModuleDesc",
        },
        {
          id: "cardsMakerChecker",
          title: "settings.spendControls.makerChecker.title",
          description: "settings.spendControls.makerChecker.card",
          showSwitch: true,
          isSwitchOn: cardsMakerCheckerEnabled,
          showItem: approvalPolicyEnabled,
          handleChange: () =>
            submitSettings(
              SETTINGS_PARAMS.cardsMakerCheckerEnabled,
              !cardsMakerCheckerEnabled
            ),
        },
        {
          id: "category",
          title: (
            <div className="flex gap-3 ">
              <Text
                translationKey="cards.settings.spendControl.categoryTitle"
                classes="text-neutral-800 font-semibold text-base"
              />
              {!openMerchantAndCategoryControls ? (
                <PremiumContactSupport />
              ) : null}
            </div>
          ),
          sectionClasses: !openMerchantAndCategoryControls
            ? "cursor-not-allowed"
            : null,
          description: "cards.settings.spendControl.categoryDesc",
          sliderType: openMerchantAndCategoryControls
            ? SLIDERS_SEARCH_PARAMS.cards.cardsettings
                .marchantAndCategoryControl
            : null,
          sliderId: openMerchantAndCategoryControls ? "create" : null,
          showItem: isMccEnabled,
        },
      ],
    },
    {
      title: "cards.settings.spendControl.postSpend.title",
      description: "cards.settings.spendControl.postSpend.description",
      rightChildren: [
        {
          id: "expense-review",
          title: expenseReviewsPolicyEnabled
            ? "cards.settings.spendControl.companyExpenseReviewTitle"
            : "cards.settings.spendControl.expenseReviewTitle",
          description: "cards.settings.spendControl.expenseReviewDesc",
          sliderType: SLIDERS_SEARCH_PARAMS.settings.policyModule,
          sliderId: POLICY_GROUP_MAPPING.expenseReview,
        },
        {
          id: POLICY_TYPE.CUSTOM_EXPENSE_REVIEW,
          title: "cards.settings.spendControl.customExpenseReviewTitle",
          description: "cards.settings.spendControl.customExpenseReviewDesc",
          showSwitch: true,
          isSwitchOn: customExpenseReviewsPolicyEnabled,
          handleChange: () =>
            submitSettings(
              SETTINGS_PARAMS.customExpenseReviewPolicyEnabled,
              !customExpenseReviewsPolicyEnabled
            ),
          sliderType:
            customExpenseReviewsPolicyEnabled &&
            SLIDERS_SEARCH_PARAMS.settings.customExpenseReviewModule,
          sliderId:
            customExpenseReviewsPolicyEnabled &&
            APPROVAL_PAYLOAD_MAPPING.expenseReview,
          showItem: expenseReviewsPolicyEnabled,
        },
        {
          id: "expenseMakerChecker",
          title: "settings.spendControls.makerChecker.title",
          description: "settings.spendControls.makerChecker.expense",
          showSwitch: true,
          isSwitchOn: expenseReviewMakerCheckerEnabled,
          handleChange: () =>
            submitSettings(
              SETTINGS_PARAMS.expenseReviewMakerCheckerEnabled,
              !expenseReviewMakerCheckerEnabled
            ),
        },
        {
          id: POLICY_TYPE.SUBMISSION,
          title: "settings.spendControls.submissionPolicy.title",
          description: "settings.spendControls.submissionPolicy.description",
          sliderType:
            submissionPolicyEnabled &&
            SLIDERS_SEARCH_PARAMS.settings.submissionModule,
          sliderId: submissionPolicyEnabled && POLICY_GROUP_MAPPING.cards,
          disableMessage: submissionPolicyEnabled
            ? null
            : "settings.spendControls.submissionPolicy.alertDisabledModuleDesc",
        },
        {
          id: POLICY_TYPE.CUSTOM_SUBMISSION,
          title: "settings.spendControls.customSubmissionPolicy.title",
          description:
            "settings.spendControls.customSubmissionPolicy.description",
          sliderType:
            customSubmissionPolicyEnabled &&
            SLIDERS_SEARCH_PARAMS.settings.customSubmissionModule,
          sliderId:
            customSubmissionPolicyEnabled && SUBMISSION_PAYLOAD_MAPPING.cards,
          showItem: submissionPolicyEnabled,
          disableMessage: customSubmissionPolicyEnabled
            ? null
            : "settings.spendControls.customSubmissionPolicy.alertDisabledModuleDesc",
        },
        {
          id: "expense-flag-rule",
          title: "cards.settings.spendControl.expenseFlagTitle",
          description: "cards.settings.spendControl.expenseFlagDesc",
          sliderType: SLIDERS_SEARCH_PARAMS.cards.cardsettings.flagSettings,
          sliderId: "preview",
        },
        {
          id: "expense-alert",
          title: "cards.settings.spendControl.expenseAlertTitle",
          description: "cards.settings.spendControl.expenseAlertDesc",
          sliderType: SLIDERS_SEARCH_PARAMS.cards.cardsettings.alertsSettings,
          sliderId: "preview",
        },
      ],
    },
  ];

  const QRPAY_SPEND_CONTROL_CONFIG = [
    {
      title: "cards.settings.spendControl.preSpend.title",
      description: "cards.settings.spendControl.preSpend.description",
      rightChildren: [
        {
          title: "settings.spendControls.approvalPolicy.title",
          description: "settings.spendControls.approvalPolicy.description",
          sliderType: SLIDERS_SEARCH_PARAMS.settings.policyModule,
          sliderId: POLICY_GROUP_MAPPING.qrWallets,
        },
        {
          title: "cards.settings.spendControl.categoryTitle",
          description: "cards.settings.spendControl.categoryDesc",
          sliderType:
            SLIDERS_SEARCH_PARAMS.cards.cardsettings.marchantAndCategoryControl,
          sliderId: "create",
        },
      ],
    },
    {
      title: "cards.settings.spendControl.postSpend.title",
      description: "cards.settings.spendControl.postSpend.description",
      rightChildren: [
        {
          title: "cards.settings.spendControl.paymentFlagTitle",
          description: "cards.settings.spendControl.paymentFlagDesc",
          sliderType: SLIDERS_SEARCH_PARAMS.cards.cardsettings.flagSettings,
          sliderId: "preview",
        },
        {
          title: "cards.settings.spendControl.paymentAlertTitle",
          description: "cards.settings.spendControl.paymentAlertDesc",
          sliderType: SLIDERS_SEARCH_PARAMS.cards.cardsettings.alertsSettings,
          sliderId: "preview",
        },
        {
          title: "settings.spendControls.submissionPolicy.title",
          description: "settings.spendControls.submissionPolicy.description",
          sliderType: SLIDERS_SEARCH_PARAMS.settings.submissionModule,
          sliderId: POLICY_GROUP_MAPPING.qrpay,
        },
        {
          title: "cards.settings.spendControl.paymentReviewTitle",
          description: "cards.settings.spendControl.paymentReviewDesc",
          sliderType: SLIDERS_SEARCH_PARAMS.settings.policyModule,
          sliderId: POLICY_GROUP_MAPPING.qrPayReview,
        },
      ],
    },
  ];

  const submitSettings = (setting, value) => {
    dispatch(
      updateClientSettings({
        setting,
        value,
      })
    );
  };

  const SPEND_CONTROL_PAGE_SETTINGS_CONFIG =
    page === QRPAY_EXPENSES_CONTEXT.EXPENSES
      ? SPEND_CONTROL_CONFIG
      : QRPAY_SPEND_CONTROL_CONFIG;

  return <SettingsList tabRows={SPEND_CONTROL_PAGE_SETTINGS_CONFIG} />;
}

export default SpendControlsPageHelper;
SpendControlsPageHelper.propTypes = {
  page: PropTypes.string,
};
