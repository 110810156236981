import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchModuleHistory,
  resetModuleHistoryPagination,
} from "@/store/reducers/master-history";

import {
  isFetchingModuleHistorySelector,
  moduleHistoryHasMoreSelector,
  moduleHistoryListSelector,
} from "@/store/selectors/master-history";

import EmptyData from "@/components/core/EmptyData";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";

import MasterHistoryDumbComponent from "@/components/Settings/MasterHistoryDumbComponent";
import { groupByDate } from "@/utils/common";

export default function ModuleHistory({ ownerId, ownerType }) {
  const dispatch = useDispatch();
  const activities = useSelector(moduleHistoryListSelector);
  const isFetching = useSelector(isFetchingModuleHistorySelector);
  const hasMore = useSelector(moduleHistoryHasMoreSelector);

  const loadMore = () => {
    dispatch(
      fetchModuleHistory({
        owner_id: ownerId,
        owner_type: ownerType,
        page: pageNum,
        limit: 10,
      })
    );
  };

  const onReset = () => {
    dispatch(resetModuleHistoryPagination());
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    inSlider: true,
    filterOptions: { owner_id: ownerId, owner_type: ownerType },
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll);
  const activitiesGrouped = groupByDate(activities, "createdAt");

  return (
    <div className="mb-14">
      <MasterHistoryDumbComponent
        handleRefChange={handleRefChange}
        hasMore={hasMore}
        logsData={activitiesGrouped}
        canNavigate={false}
        hideActivityType
        isGrouped
      />
      {!activities?.length && !isFetching ? (
        <div className="w-10/12 mx-auto mt-15">
          <EmptyData
            hideButton
            description="common.moduleHistory.emptyDescription"
            title="common.moduleHistory.emptyTitle"
          />
        </div>
      ) : null}
      {isFetching ? (
        <LoaderSkeleton fullWidth count={5} size={["100px", "100%"]} />
      ) : null}
    </div>
  );
}
ModuleHistory.propTypes = {
  ownerId: PropTypes.number,
  ownerType: PropTypes.string,
};
