import PropTypes from "prop-types";

import Button from "@/components/core/Button";

function OnboardingFooter({
  tncComponent,
  btnLabel,
  isFormDisabled,
  onClick,
  showLoader,
}) {
  return (
    <div className="absolute bottom-0 flex items-center justify-between gap-6 p-4 slider-footer h-14">
      <div>{tncComponent ? tncComponent : null}</div>
      <Button
        btnType={onClick ? "" : "submit"}
        onClick={onClick}
        form="onboardingFrom"
        showLoader={showLoader}
        disabled={isFormDisabled}
        label={btnLabel}
        classes="w-84 h-11  items-self-end justify-self-end ml-auto"
      />
    </div>
  );
}
OnboardingFooter.propTypes = {
  tncComponent: PropTypes.node,
  btnLabel: PropTypes.string,
  isFormDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  showLoader: PropTypes.bool,
};
export default OnboardingFooter;
