export const RECEIPT_STATUS_NAMES = {
  SUBMITTED: "submitted",
  MISSING: "missing",
  NOT_REQUIRED: "not_required",
  VERIFIED: "verified",
};

export const FILE_UPLOAD_ERROR = {
  FILE_INVALID_TYPE: "file-invalid-type",
  FILE_TOO_LARGE: "file-too-large",
  FILE_TOO_SMALL: "file-too-small",
  TOO_MANY_FILES: "too-many-files",
};
export const VP_DATE_INPUT_TYPE = "vpDate";
export const RECEIPT_STATUSES = {
  [RECEIPT_STATUS_NAMES.SUBMITTED]: {
    icon: "ReceiptSubmitted",
    class: "text-success-600",
    label: "Receipt submitted",
  },
  [RECEIPT_STATUS_NAMES.MISSING]: {
    icon: "ReceiptMissing",
    class: "text-danger-400",
    label: "Receipt missing",
  },
  [RECEIPT_STATUS_NAMES.NOT_REQUIRED]: {
    icon: "Minus",
    class: "text-neutral-600",
    label: "Receipt not required",
  },
  [RECEIPT_STATUS_NAMES.VERIFIED]: {
    icon: "ReceiptStatus",
    class: "text-success-600",
    label: "Receipt auto-verified",
  },
  // missing , submitted , not_required , verified --  fieldname -- receiptStatus
};

export const RECEIPT_STATUSES_SLIDER = {
  [RECEIPT_STATUS_NAMES.SUBMITTED]: {
    icon: "ReceiptSubmitted",
    variant: "success",
    label: "expenses.overview.progressBar.receiptSlider.submitted",
    tooltip: "expenses.overview.progressBar.receiptSlider.submittedTooltip",
  },

  [RECEIPT_STATUS_NAMES.VERIFIED]: {
    icon: "ReceiptStatus",
    variant: "success",
    label: "expenses.overview.progressBar.receiptSlider.autoVerified",
    tooltip: "expenses.overview.progressBar.receiptSlider.autoVerifiedTooltip",
  },
  // missing , submitted , not_required , verified --  fieldname -- receiptStatus
};

export const FORM_TYPE = {
  preview: "preview",
  edit: "edit",
  editCurrency: "editCurrency",
  editLocation: "editLocation",
  review: "review",
  request: "request",
  increase: "increase",
};

export const INVOICE_STATUSES = {
  SUBMITTED: {
    icon: "ReceiptSubmitted",
    class: "text-success-600",
    label: "Invoice submitted",
  },
  REQUIRED_NOT_SUBMITTED: {
    icon: "ReceiptMissing",
    class: "text-danger-400",
    label: "Invoice missing",
  },
  NOT_SUBMITTED: {
    icon: "Minus",
    class: "text-neutral-600",
    label: "Invoice not required",
  },
};

export const MEMO_NOTE_STATUSES = {
  PRESENT: {
    icon: "Memo_Present",
    class: "text-success-600",
    label: "Memo present",
  },
  REQUIRED_NOT_PRESENT: {
    icon: "Memo_NotPresent",
    class: "text-danger-400",
    label: "Memo missing",
  },
  NOT_REQUIRED: {
    icon: "Minus",
    class: "text-neutral-600",
    label: "Memo not required",
  },
};
export const BE_FE_BUDGET_KEY_MAPPING = {
  expense: "CARDS",
  reimbursement: "REIMBURSEMENT",
  payment: "BILL_PAY",
  payroll: "PAYROLL",
  qr: "QR_PAY",
  domestic_expense: "DOMESTIC_CARDS",
};

export const CREDIT_KEY_MAPPING = {
  settled: "SETTLED",
  unsettled: "UNSETTLED",
  available: "AVAILABLE",
  availableLimit: "AVAILABLE_LIMIT",
  outstanding: "OUTSTANDING",
};

export const CREDIT_PILL_CONFIG = {
  SETTLED: {
    label: "Settled",
    singularLabel: "Settled",
    icon: "CreditCard",
    variant: "corporate-cards",
    key: "cards",
    chartHexColor: "corporate-cards-500",
  },
  OUTSTANDING: {
    label: "company.credit.outstanding",
    singularLabel: "company.credit.outstanding",
    icon: "CreditCard",
    variant: "corporate-cards",
    key: "cards",
    chartHexColor: "corporate-cards-500",
  },
  UNSETTLED: {
    label: "Unsettled",
    singularLabel: "Unsettled",
    icon: "AccountBalanceWallet",
    variant: "corporate-cards-100",
    cards: "reimbursements",
    chartHexColor: "corporate-cards-100",
  },
  AVAILABLE: {
    label: "Available",
    singularLabel: "Available",
    icon: "Send",
    variant: "available",
    cards: "bill-payments",
    chartHexColor: "neutral-100",
  },
  AVAILABLE_LIMIT: {
    label: "company.credit.availableLimit",
    singularLabel: "company.credit.availableLimit",
    icon: "Send",
    variant: "available",
    cards: "bill-payments",
    chartHexColor: "neutral-100",
  },
};

export const BUDGET_PILL_CONFIG = {
  CARDS: {
    label: "cards.title.plural",
    singularLabel: "qrPay.singularLabel",
    icon: "CreditCard",
    variant: "corporate-cards",
    key: "cards",
    chartHexColor: "#5C95FF",
  },
  DOMESTIC_CARDS: {
    label: "cards.domesticCards",
    singularLabel: "cards.domesticCards",
    icon: "CreditCard",
    variant: "corporate-cards",
    key: "domestic_expense",
    chartHexColor: "#5C95FF",
  },
  QR_PAY: {
    label: "qrPay.title",
    singularLabel: "cards.title.singular",
    icon: "CreditCard",
    variant: "corporate-cards",
    key: "cards",
    chartHexColor: "#633DA7",
  },
  BILL_PAY: {
    label: "accounting.transactions.billPay.title",
    singularLabel: "billPay.singularTitlePayment",
    icon: "Send",
    variant: "accounts-payable",
    cards: "bill-payments",
    chartHexColor: "#53CBA5",
  },
  REIMBURSEMENT: {
    label: "reimbursement.title",
    singularLabel: "reimbursement.title",
    icon: "AccountBalanceWallet",
    variant: "expense-management",
    cards: "reimbursements",
    chartHexColor: "#FDCB40",
  },
  DISABLED: {
    translationKey: "Misc.noBudgets",
    preIcon: "DoNotDisturb",
    key: "disabled",
    disabled: true,
    classes: "px-2",
    chartHexColor: "#E5E6EA",
  },
};

export const DELTA_PERCENTAGE_MODE = {
  PERCENTAGE: "percentage",
  AMOUNT: "amount",
};

export const REVIEW_LABELS = {
  BUDGET_FOR: {
    OLD: "Old",
    NEW: "New",
  },
  BUDGET_TYPE: {
    OLD: "Old",
    NEW: "New",
  },
  FREQUENCY: {
    OLD: "Old frequency",
    NEW: "New frequency",
  },
  AMOUNT: {
    OLD: "Old Amount",
    NEW: "New Amount",
  },
};

export const REVIEW_TYPES = {
  AMOUNT: "amount",
  FREQUENCY: "frequency",
};

export const FILE_UPLOAD_VARAINTS = {
  CUSTOM: "custom",
  BOX: "box",
  BUTTON: "button",
};

export const OVERVIEW = "Overview";
export const HISTORY = "History";

export const TABS = [
  { name: OVERVIEW, key: 1, path: OVERVIEW, showComingSoon: false },
  { name: HISTORY, key: 2, path: HISTORY, showComingSoon: true },
];
export const ADMIN_ROLE = "admin";

export const CAROUSEL_MODE = {
  SINGLE_VIEW: "singleView",
  MULTI_VIEW: "multiView",
};

export const VENDOR_CLASS = {
  EMPLOYEE: "employee", // for profile bank detail
  PAYROLL: "payroll", // vendor in payroll module
  USER_CREATED: "user_created", // vendor in billpay
};

export const DANGER = "danger";
export const WARNING = "warning";
export const SUCCESS = "success";

export const BANNER_COLORS = {
  [DANGER]: "bg-danger-500",
  [WARNING]: "bg-warning-500",
  [SUCCESS]: "bg-success-600",
};

export const DATE_DIFFRENCE_DURATIONS = {
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
  YEARLY: "yearly",
};
// TODO: need icon confirmation then will replace
export const CATEGORY_ICON = {
  Travel: "",
  "Marketing & advertising": "",
  Software: "",
  "Equipment & hardware": "",
  "Phone & internet": "",
  "Meals & drinks": "",
  Entertainment: "",
  "Office expenses": "",
  Other: "",
  "Marketing & advertising ": "",
  "Software ": "",
  "Equipment & hardware ": "",
  "Phone & internet ": "",
  "Meals & drinks ": "",
  "Entertainment ": "",
  "Office expenses ": "",
  "Other ": "",
  Insurance: "",
  "Digital Goods": "",
  "Financial Institution": "",
  Utilities: "",
  Shopping: "",
  Airlines: "",
  "Food n Beverage": "",
  "Expense n Rental": "",
  "Hotels n Resorts": "",
  "Non Financial institution": "",
  Miscellaneous: "",
  Government: "",
  "Direct Marketing": "",
  Education: "",
  Medical: "",
  Housing: "",
  Fuel: "",
  "ATM Cash Withdrawal": "",
  "Membership n Subscriptions": "",
  "Construction n Interiors": "",
  Luxury: "",
  "Miscellaneous Stores": "",
  "Publishing n Printing": "",
  "Car Rental": "",
  Pets: "",
  "Personal Service Providers": "",
  "Social Services": "",
  Automobiles: "",
  Sports: "",
  "Legal Services": "",
  "Industrial Supplies": "",
  Repairs: "",
  Jewellery: "",
};

export const VP_ERROR_COMPONENT_TYPE = {
  COMPACT: "compact",
  FULL: "full",
};

export const PERMISSION_DENIED = 403;

export const PREFERRED_COUNTRIES = ["sg", "au", "in", "id"];
