import { SORTING_CATEGORY } from "./sorting";

export const FAILURES_TABLE_HEADERS = [
  {
    label: "QRPay.failures.headers.date",
    haveArrow: true,
    sortingCategory: SORTING_CATEGORY.DATE,
    className: "flex-row-reverse justify-end gap-5",
  },
  {
    label: "QRPay.failures.headers.amount",
    haveArrow: true,
    sortingCategory: SORTING_CATEGORY.AMOUNT,
    className: "justify-end gap-4",
  },
  { label: "QRPay.failures.headers.walletHolder" },
  { label: "QRPay.failures.headers.declineReason" },
];

export const EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY = {
  DATE: "date",
  AMOUNT: "amount",
  CARD: "card",
  CARD_HOLDER: "cardHolder",
  WALLET_HOLDER: "walletHolder",
  SUBMISSION_POLICY: "submissionPolicyStatus",
  RECEIPT: "receipt",
  MEMO: "memo",
  REVIEW_STATUS_MULTIPLE_REVIEW_LAYER: "reviewStatusMultipleReviewLayers",
  ACCOUNTING_CATEGORY: "accountingCategory",
  ACCOUNTING_VENDOR: "accountingVendor",
  ACTION: "action",
};

export const QRPAY_NEEDS_REVIEW_TABLE_HEADERS = [
  {
    label: "QRPay.failures.headers.date",
    sortable: true,
    id: EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.DATE,
    class: "flex flex-1 min-w-0 gap-2 cursor-pointer",
  },
  {
    id: EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.AMOUNT,
    label: "QRPay.failures.headers.amount",
    haveArrow: true,
    class: "flex justify-end gap-4 cursor-pointer",
  },
  {
    id: EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.WALLET_HOLDER,
    label: "QRPay.failures.headers.walletHolder",
  },
  {
    id: EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.SUBMISSION_POLICY,
    label: "QRPay.submissionPolicyStatus",
  },
  {
    id: EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.RECEIPT,
    label: "expenses.flagged.tableHeaders.receipt",
    class: "text-center",
  },
  {
    id: EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.MEMO,
    label: "expenses.flagged.tableHeaders.memo",
  },
  {
    id: EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.ACCOUNTING_CATEGORY,
    label: "expenses.flagged.tableHeaders.accountingCategory",
    isAccountingTag: true,
  },
  {
    id: EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.ACCOUNTING_VENDOR,
    label: "expenses.flagged.tableHeaders.accountingVendor",
    isAccountingTag: true,
  },
  {
    id: EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.ACTION,
    label: "expenses.flagged.tableHeaders.action",
  },
];

export const EXPENSES_NEEDS_REVIEW_TABLE_HEADERS = [
  {
    id: EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.DATE,
    label: "QRPay.failures.headers.date",
    sortable: true,
    colWidth: 300,
  },

  {
    id: EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.AMOUNT,
    label: "QRPay.failures.headers.amount",
    sortable: true,
    classes: "text-right flex-1",
    colWidth: 200,
  },
  {
    id: EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.CARD,
    label: "expenses.overview.overviewTable.headers.card",
    colWidth: 250,
  },
  {
    id: EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.REVIEW_STATUS_MULTIPLE_REVIEW_LAYER,
    label: "expenses.overview.overviewTable.headers.reviewStatus",
    colWidth: 200,
  },
  {
    id: EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.CARD_HOLDER,
    label: "expenses.needsReview.tableHeaders.cardHolder",
    colWidth: 150,
  },
  {
    id: EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.SUBMISSION_POLICY,
    label: "QRPay.submissionPolicyStatus",
    classes: "text-center flex-1",
    colWidth: 150,
  },
  {
    id: EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.RECEIPT,
    label: "expenses.flagged.tableHeaders.receipt",
    classes: "text-center flex-1",
    colWidth: 100,
  },
  {
    id: EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.MEMO,
    label: "expenses.flagged.tableHeaders.memo",
    classes: "text-center flex-1",
    colWidth: 100,
  },
  {
    id: EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.ACCOUNTING_CATEGORY,
    label: "expenses.needsReview.tableHeaders.accountingCategory",
    classes: "text-left flex-1",
    colWidth: 200,
    labelTranslationProps: null,
    accountingTag: true,
  },
  {
    id: EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.ACCOUNTING_VENDOR,
    label: "expenses.needsReview.tableHeaders.accountingMerchant",
    classes: "text-left flex-1",
    colWidth: 200,
    accountingTag: true,
  },
  {
    id: EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.ACTION,
    label: "expenses.flagged.tableHeaders.action",
    classes: "text-center flex-1",
    colWidth: 200,
  },
];
export const FLAGGED_QRPAY_HEADER = [
  {
    label: "expenses.flagged.tableHeaders.date",
    haveArrow: true,
    class: "flex items-center gap-2 cursor-pointer justify-start",
    iconClass: "w-3 h-3",
    sortingCategory: SORTING_CATEGORY.DATE,
  },
  {
    label: "expenses.flagged.tableHeaders.amount",
    haveArrow: true,
    class:
      "flex items-center gap-2 flex-row-reverse  cursor-pointer justify-start",
    iconClass: "w-3 h-3",
    sortingCategory: SORTING_CATEGORY.AMOUNT,
  },
  { label: "QRPay.failures.headers.walletHolder" },

  {
    label: "expenses.flagged.tableHeaders.flaggedBy",
  },
  { label: "QRPay.submissionPolicyStatus" },

  {
    label: "expenses.flagged.tableHeaders.receipt",
    class: "text-center",
  },
  {
    label: "expenses.flagged.tableHeaders.memo",
    class: "text-left",
  },
  {
    label: "expenses.flagged.tableHeaders.accountingCategory",
    isAccountingTag: true,
  },
  {
    label: "expenses.flagged.tableHeaders.accountingVendor",
    isAccountingTag: true,
  },
  {
    label: "expenses.flagged.tableHeaders.action",
    class: "text-center",
  },
];
