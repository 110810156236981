import PropTypes from "prop-types";

import { t } from "i18next";

import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import { getCardTableDataConfig } from "@/components/Cards/util";
import { amountToCurrency } from "@/utils/common";

import {
  CARD_FREQUENCY_LABEL,
  CARD_STATUS,
  CARD_TYPE,
  CARD_TYPE_TITLE,
  PHYICAL_CARDS_CTA,
} from "@/constants/Cards";
import { AWAITING_FUNDS, PENDING_APPROVAL } from "@/constants/card";

export default function Card({
  id,
  type,
  val,
  status,
  name,
  cardNumber,
  isTemporaryLimit,
  topUpRequestTooltip,
  topUpRequests,
  temporaryLimitTooltip,
  cardUsageType,
  hasCardProvidersWithInternationalCardOrder,
  insufficientFunds,
}) {
  const config = getCardTableDataConfig(
    hasCardProvidersWithInternationalCardOrder,
    cardUsageType
  )?.[status];
  const limit = temporaryLimitTooltip?.limit;
  const currency = temporaryLimitTooltip?.currency;
  const frequency = CARD_FREQUENCY_LABEL?.[topUpRequestTooltip?.frequency];

  const tempRequestsText = "cards.listingFields.card.tooltip.tempLimit";

  const showComingSoonTooltip = val?.allowedCtas?.includes(
    PHYICAL_CARDS_CTA.PHYSICAL_CARD_COMING_SOON
  );

  return (
    <div className="flex items-center" id={`tooltip-${id}`}>
      <ProfileWidget
        textClasses="text-sm font-semibold text-neutral-800"
        iconComponent={
          <div className={`rounded-full ${config.classes} p-2 px-3`}>
            <Icon
              name={config[type]?.icon}
              className={`${config.textClasses} my-1 mx-[1px]`}
            />
          </div>
        }
        name={name}
      >
        <span className="flex gap-1 text-xs font-medium text-neutral-500">
          {status !== CARD_STATUS.INACTIVE && (
            <Text translationKey={CARD_TYPE_TITLE[type]} />
          )}
          {cardNumber?.length >= 0 && status !== CARD_STATUS.INACTIVE && "•"}
          {cardNumber?.length ? (
            <Text translationKey={cardNumber?.slice(-4)} />
          ) : (
            "****"
          )}
        </span>
      </ProfileWidget>

      <div className="flex gap-2 ml-auto">
        {isTemporaryLimit ? (
          <>
            <div id={`eventRepeat-${id}`}>
              <Icon
                name="EventRepeat"
                className="w-6 h-6 p-1 border rounded-lg text-neutral-400 bg-neutral-50 border-neutral-100"
              />
            </div>

            <Tooltip
              id={`eventRepeat-${id}`}
              direction="top-right"
              maxWidth="400px"
            >
              <Text
                translationKey="cards.listingFields.card.tooltip.label"
                classes="text-sm text-neutral-800"
              />
              <Text
                translationKey="cards.listingFields.card.tooltip.description"
                translationProps={{
                  amountCurrency: amountToCurrency(limit, currency),
                  frequency: t(frequency),
                }}
                classes="block text-sm text-neutral-500"
              />
            </Tooltip>
          </>
        ) : null}

        {topUpRequests ? (
          <div id={`top-up-tooltip-${id}`}>
            <Icon
              name="Schedule"
              className="w-6 h-6 p-1 border rounded-lg text-warning-300 bg-warning-50 border-warning-100"
            />
            <Tooltip id={`top-up-tooltip-${id}`} direction="bottom">
              <Text
                classes="text-sm text-neutral-800"
                translationKey={tempRequestsText}
                translationProps={{
                  approvalStatus: insufficientFunds
                    ? t(AWAITING_FUNDS)
                    : t(PENDING_APPROVAL),
                  amountCurrency: amountToCurrency(
                    topUpRequestTooltip?.amount,
                    topUpRequestTooltip?.currency
                  ),
                  frequency: topUpRequestTooltip?.frequency,
                }}
              />
            </Tooltip>
          </div>
        ) : null}

        {showComingSoonTooltip ? (
          <Tooltip id={`tooltip-${id}`}>
            <Text translationKey="common.comingSoon" />
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
}

Card.propTypes = {
  type: PropTypes.oneOf(Object.values(CARD_TYPE)),
  name: PropTypes.string,
  status: PropTypes.oneOf(Object.values(CARD_STATUS)),
  cardNumber: PropTypes.string,
  isTemporaryLimit: PropTypes.bool,
  topUpRequests: PropTypes.object,
  id: PropTypes.number,
  topUpRequestTooltip: PropTypes.object,
  temporaryLimitTooltip: PropTypes.object,
  cardUsageType: PropTypes.string,
  hasCardProvidersWithInternationalCardOrder: PropTypes.object,
  val: PropTypes.object,
};
