import PropTypes from "prop-types";
import { useMemo } from "react";

import VirtualizedTable from "@/components/core/VirtualizedTable";
import { PHYICAL_CARDS_CTA } from "@/constants/Cards";
import CardTableComponent from "./CardTableComponents";

function CardsTable({
  hasMore,
  handleRefChange,
  isFetchingData,
  headers,
  isDepartmentClient,
  handleRowClick,
  emptyDataTitle,
  emptyDataDescription,
  emptyDataChildren,
  classes,
  data,
}) {
  const isEmpty = data?.length === 0;
  const updatedHeaders = useMemo(
    () =>
      headers?.filter(
        (header) => !header?.dependsOnDepartmentClient && !isDepartmentClient
      ),
    [headers]
  );
  const getCellComponent = useMemo(
    // eslint-disable-next-line react/display-name
    () => (id, _data, index, tagId, val, _isActionLoader) => {
      return (
        <CardTableComponent
          componentId={id}
          componentProps={{ ...val[id], val }}
        />
      );
    },
    [JSON.stringify(data)]
  );

  const getExtraClasses = (row) =>
    row?.allowedCtas?.includes(PHYICAL_CARDS_CTA.PHYSICAL_CARD_COMING_SOON)
      ? "!bg-neutral-100 cursor-not-allowed"
      : "";

  const tableArgs = {
    headerSticky: true,
    numberOfStickyColsLeft: 1,
    colWidths: updatedHeaders?.map((header) => header?.colWidth),
    emptyDataTitle,
    emptyDataDescription,
    emptyDataChildren,
  };

  return (
    <div>
      <VirtualizedTable
        {...tableArgs}
        data={data}
        headerConfig={updatedHeaders}
        isLoading={isFetchingData}
        onRowClick={(row) => handleRowClick(row?.id)}
        handleRefChange={(ref, index) => {
          if (data.length - 1 === index && hasMore) return handleRefChange(ref);
        }}
        getEnableRowClick={(_, card) => {
          return !card?.allowedCtas?.includes(
            PHYICAL_CARDS_CTA.PHYSICAL_CARD_COMING_SOON
          );
        }}
        showCheckBoxRow={false}
        extraCellClass={getExtraClasses}
        getCellComponent={(rowData, headerId, header, isActionLoader) => {
          return getCellComponent(
            headerId,
            rowData?.getValue(),
            rowData?.row?.index,
            header.tagId,
            rowData?.row?.original,
            isActionLoader
          );
        }}
      />
    </div>
  );
}

export default CardsTable;
CardsTable.propTypes = {
  headers: PropTypes.array,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isFetchingData: PropTypes.bool,
  isDepartmentClient: PropTypes.bool,
  hasMore: PropTypes.bool,
  handleRefChange: PropTypes.func,
  handleRowClick: PropTypes.func,
  emptyDataTitle: PropTypes.string,
  classes: PropTypes.string,
  emptyDataDescription: PropTypes.string,
  emptyDataChildren: PropTypes.node,
};
