export const NOT_OKAY = "not_okay";
export const AUTO_APPROVED = "auto_approved";

export const TRANSACTION_STATUS_BADGE = {
  APPROVED: {
    color: "success",
    text: "expenses.needsReview.badgeStatus.approved",
  },
  NEEDS_REVIEW: {
    color: "warning",
    text: "expenses.needsReview.badgeStatus.needsReview",
  },
  FLAGGED: {
    color: "danger",
    text: "expenses.needsReview.badgeStatus.flagged",
  },
};

export const AMOUNT_SLIDER_COLOR = {
  unsettled: "text-neutral-500",
  settled: "text-neutral-800",
};

export const DATA_TO_CONVERT_TYPE = {
  SPLIT: "split",
};
export const FLAGGED_EXPENSE_HEADER_KEY = {
  DATE: "date",
  AMOUNT: "amount",
  CARD: "card",
  CARD_HOLDER: "cardHolder",
  FLAGGED_BY: "flaggedBy",
  SUBMISSION_POLICY_STATUS: "submissionPolicyStatus",
  RECEIPT: "receipt",
  MEMO: "memo",
  ACCOUNTING_CATEGORY: "accountingCategory",
  ACCOUNTING_VENDOR: "accountingVendor",
  ACTION: "action",
};
export const FLAGGED_EXPENSE_HEADER = [
  {
    id: FLAGGED_EXPENSE_HEADER_KEY.DATE,
    label: "expenses.flagged.tableHeaders.date",
    sortable: true,
    colWidth: 300,
  },
  {
    id: FLAGGED_EXPENSE_HEADER_KEY.AMOUNT,
    label: "expenses.flagged.tableHeaders.amount",
    classes: "flex-1 text-right",
    sortable: true,
    colWidth: 200,
  },
  {
    id: FLAGGED_EXPENSE_HEADER_KEY.CARD,
    label: "expenses.overview.overviewTable.headers.card",
    colWidth: 300,
  },
  {
    id: FLAGGED_EXPENSE_HEADER_KEY.CARD_HOLDER,
    label: "expenses.flagged.tableHeaders.cardHolder",
    colWidth: 200,
  },
  {
    id: FLAGGED_EXPENSE_HEADER_KEY.FLAGGED_BY,
    label: "expenses.flagged.tableHeaders.flaggedBy",
    colWidth: 200,
  },
  {
    id: FLAGGED_EXPENSE_HEADER_KEY.SUBMISSION_POLICY_STATUS,
    classes: "flex-1 text-center",
    label: "QRPay.submissionPolicyStatus",
    colWidth: 200,
  },
  {
    id: FLAGGED_EXPENSE_HEADER_KEY.RECEIPT,
    label: "expenses.flagged.tableHeaders.receipt",
    classes: "flex-1  text-center",
    colWidth: 200,
  },
  {
    id: FLAGGED_EXPENSE_HEADER_KEY.MEMO,
    label: "expenses.flagged.tableHeaders.memo",
    class: "text-left",
    colWidth: 200,
  },
  {
    id: FLAGGED_EXPENSE_HEADER_KEY.ACCOUNTING_CATEGORY,
    label: "expenses.flagged.tableHeaders.accountingCategory",
    isAccountingTag: true,
    colWidth: 200,
  },
  {
    id: FLAGGED_EXPENSE_HEADER_KEY.ACCOUNTING_VENDOR,
    label: "expenses.flagged.tableHeaders.accountingVendor",
    isAccountingTag: true,
    colWidth: 200,
  },
  {
    id: FLAGGED_EXPENSE_HEADER_KEY.ACTION,
    label: "expenses.flagged.tableHeaders.action",
    class: "text-center",
    colWidth: 200,
  },
];
export const DECLINES_TABLE_HEADERS_KEY = {
  DATE: "date",
  AMOUNT: "amount",
  CARD: "card",
  CARD_HOLDER: "cardHolder",
  DECLINE_REASON: "declineReason",
};
export const DECLINES_TABLE_HEADERS = [
  {
    id: DECLINES_TABLE_HEADERS_KEY.DATE,
    label: "expenses.declines.headers.date",
    sortable: true,
    className: "flex-row-reverse justify-end gap-2",
    colWidth: 260,
  },
  {
    id: DECLINES_TABLE_HEADERS_KEY.AMOUNT,
    label: "expenses.declines.headers.amount",
    sortable: true,
    className: "justify-end gap-2",
    colWidth: 200,
  },
  {
    id: DECLINES_TABLE_HEADERS_KEY.CARD,
    label: "expenses.declines.headers.card",
    colWidth: 250,
  },
  {
    id: DECLINES_TABLE_HEADERS_KEY.CARD_HOLDER,
    label: "expenses.declines.headers.cardHolder",
    colWidth: 200,
  },
  {
    id: DECLINES_TABLE_HEADERS_KEY.DECLINE_REASON,
    label: "expenses.declines.headers.declineReason",
    colWidth: 300,
  },
];

export const GST_RADIO_BUTTONS = [
  {
    label: "expenses.slider.gst.radioButtonLabels.yes",
    textClasses: "text-base font-semibold text-neutral-500",
    isChecked: (isGstApplied) => isGstApplied,
    handleChange: (handleGstApplied) => handleGstApplied(true),
  },
  {
    label: "expenses.slider.gst.radioButtonLabels.no",
    textClasses: "text-base font-semibold text-neutral-500",
    isChecked: (isGstApplied) => !isGstApplied,
    handleChange: (handleGstApplied) => handleGstApplied(false),
  },
];
