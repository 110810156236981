/* eslint-disable  */
import { to } from "await-to-js";

import $axios from "@/api/axios";
import MOCK_DATA from "@/api/data.json";

import { getToken, subdomain, waitForMilliseconds } from "@/utils/common";
import { WHITELIST_URL } from "@/utils/constants/common";

import { API_KEYS_MAPPING } from "./apiKeys";

const SUB_DOMAIN_MAP = {
  SG: "api",
  AU: "api-au",
};

const getHeaders = () => {
  const whiteListedUrl = WHITELIST_URL.find(
    (url) => url === window.location.pathname.split("/")[1]
  );

  if (whiteListedUrl) {
    return {
      "access-token": getToken(),
      account: subdomain(),
    };
  }

  return {
    "access-token": localStorage.getItem("access-token"),
    "token-type": localStorage.getItem("token-type"),
    uid: localStorage.getItem("uid"),
    expiry: localStorage.getItem("expiry"),
    client: localStorage.getItem("client"),
    account: subdomain(),
  };
};

const getBaseMockUrl = async () => {
  try {
    return import.meta.env.VITE_MOCK_API_URL;
  } catch (error) {
    throw new Error(error);
  }
};

const getMockApiHeaders = () => {
  const token = window.btoa(
    `${import.meta.env.VITE_MOCK_API_USERNAME}:${import.meta.env.VITE_MOCK_API_PASSWORD}`
  );
  return {
    Authorization: `Basic ${token}`,
  };
};
const globalApiUrl = () => import.meta.env.VITE_GLOBAL_API_BASE_URL;
const ocrServiceApiUrl = () => import.meta.env.VITE_OCR_SERVICE_URL;
export const getBaseHostUrl = () => {
  try {
    if (import.meta.env.VITE_MODE === "development") {
      return import.meta.env.VITE_API_HOST;
    }
    const url = window.localStorage.getItem("api_subdomain");
    if (url) {
      return import.meta.env.VITE_API_HOST.replace("subdomain", url);
    }
  } catch (err) {}
};

const getPublicAssetsUrl = async () => {
  return import.meta.env.VITE_PUBLIC_ASSETS_ENDPOINT;
};

const getBaseUrl = async () => {
  try {
    if (import.meta.env.VITE_MODE === "development") {
      return import.meta.env.VITE_API_ENDPOINT.replace("subdomain", "api");
    }
    const url = window.localStorage.getItem("api_subdomain");

    if (url) {
      return import.meta.env.VITE_API_ENDPOINT.replace("subdomain", url);
    }

    const [err, response] = await to(API.Auth.clients(subdomain()));

    if (response?.data) {
      if (
        !(
          Object.keys(response.data).length === 0 &&
          response.data.constructor === Object
        )
      ) {
        // response not empty
        window.localStorage.setItem(
          "api_subdomain",
          response.data.api_subdomain
        );
      } else {
        if (import.meta.env.VITE_MODE === "development") {
          return import.meta.env.VITE_API_ENDPOINT.replace("subdomain", "api");
        }

        throw new Error({
          response: {
            data: {
              message: "Company not found.",
            },
          },
        });
      }

      return import.meta.env.VITE_API_ENDPOINT.replace(
        "subdomain",
        response.data.api_subdomain
      );
    }

    if (import.meta.env.VITE_MODE === "staging") {
      return import.meta.env.VITE_API_ENDPOINT.replace(
        "subdomain",
        "staging-api"
      );
    }

    return import.meta.env.VITE_API_ENDPOINT.replace("subdomain", "api");
  } catch (error) {
    throw new Error(error);
  }
};

const getBaseUrlV1 = async () => {
  let url = await getBaseUrl();
  url = `${url.slice(0, -2)}v1`; // v3 -> v1
  return url;
};
const searchResults = (items, searchText) => {
  const searchTextLowerCase = searchText?.toLocaleLowerCase();
  if (!searchTextLowerCase) return items;

  items = items.filter((item) =>
    JSON.stringify(item).toLocaleLowerCase().includes(searchTextLowerCase)
  );

  return items;
};

// data repeater - to test infinite scroll
const repeatedPseudoUniqueData = (items, paginationParams, count = 100) => {
  if (paginationParams?.repeatData && items?.length) {
    items = Array(Math.max(1, Math.floor(count / items.length)))
      .fill(items)
      .flat()
      .map((item, idx) => ({ ...item, id: idx + 1 })); // unique id
  }

  return items;
};

const getPaginatedData = async (items, paginationParams) => {
  items = repeatedPseudoUniqueData(items, paginationParams);

  const total = items.length;
  const { limit = PAGINATION_PER_REQUEST_LIMIT } = paginationParams;
  const pageNumber = paginationParams.pageNumber || paginationParams.page || 1;
  const offset = pageNumber ? pageNumber - 1 : 0;
  const list = items.slice(offset * limit, limit * pageNumber);

  await waitForMilliseconds(2000);
  return {
    list,
    pageNumber,
    page: pageNumber,
    limit,
    total,
  };
};

export const setApiSubDomain = (countryCode) => {
  if (import.meta.env.VITE_MODE === "staging") {
    window.localStorage.setItem("api_subdomain", "staging-api");
    return;
  }

  window.localStorage.setItem(
    "api_subdomain",
    SUB_DOMAIN_MAP[countryCode] || SUB_DOMAIN_MAP.SG
  );
};

const API = {
  Modules: {
    async getModules() {
      return $axios.get(`${await getBaseUrl()}/modules`, {
        params: {},
        headers: getHeaders(),
      });
    },
  },
  Auth: {
    async validateToken() {
      return $axios.get(`${await getBaseUrl()}/auth/user/validate_token`, {
        params: {},
        headers: getHeaders(),
      });
    },

    async signIn(payload) {
      return $axios.post(`${await getBaseUrl()}/auth/user/sign_in`, payload, {
        headers: getHeaders(),
      });
    },

    async logout() {
      return $axios.delete(`${await getBaseUrl()}/auth/user/sign_out`, {
        headers: getHeaders(),
      });
    },

    async getAccounts(params) {
      return $axios.post(`${globalApiUrl()}/accounts/get-accounts`, params, {
        headers: {},
      });
    },

    async sendResetPasswordLink(payload) {
      return $axios.post(`${await getBaseUrl()}/auth/user/password`, payload, {
        headers: getHeaders(),
      });
    },

    async resetPasswordVerification(params) {
      return $axios.get(`${await getBaseUrl()}/auth/user/password/edit`, {
        params,
        headers: getHeaders(),
      });
    },

    async resetPassword(payload) {
      return $axios.put(`${await getBaseUrl()}/auth/user/password`, payload, {
        headers: getHeaders(),
      });
    },

    async clients(subDomain) {
      return $axios.get(`${globalApiUrl()}/clients/${subDomain}`, {
        params: {},
        headers: {},
      });
    },

    async googleSignIn(rest) {
      return $axios.get(`${await getBaseUrl()}/login/google-oauth2-url`, {
        params: { subdomain: subdomain(), ...rest },
        headers: {},
      });
    },

    async googleCodeVerification(payload) {
      return $axios.post(
        `${await getBaseUrl()}/login/google-oauth2-verification`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },

    async checkInvitation(payload) {
      return $axios.get(`${await getBaseUrl()}/auth/user/invitation/accept`, {
        params: { subdomain: subdomain(), ...payload },
        headers: getHeaders(),
      });
    },

    async invite(payload) {
      return $axios.post(
        `${await getBaseUrl()}/auth/user/invitation`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async resendInvite(payload) {
      return $axios.put(
        `${await getBaseUrl()}/auth/user/invitation/re_invite`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },

    async expireInvite(payload) {
      return $axios.put(
        `${await getBaseUrl()}/auth/user/invitation/expire_invite`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },

    async createPassword(payload) {
      return $axios.put(
        `${await getBaseUrl()}/auth/user/invitation`,
        { ...payload, subdomain: subdomain() },
        {
          headers: getHeaders(),
        }
      );
    },
  },

  Client: {
    async updateClientSettings(payload) {
      return $axios.post(
        `${await getBaseUrl()}/global_settings/toggle-setting`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
  },

  Account: {
    async fetchAccountDetail(params) {
      return $axios.get(
        `${await getBaseUrl()}/accounts/${params.id}/bank_details`,
        { params, headers: getHeaders() }
      );
    },
    async downloadAccountDetail(params) {
      return $axios.get(`${await getBaseUrl()}/accounts/download.pdf`, {
        params,
        headers: getHeaders(),
        responseType: "blob",
      });
    },
    async client() {
      return $axios.get(`${await getBaseUrl()}/accounts/client`, {
        headers: getHeaders(),
        apiKey: API_KEYS_MAPPING.ACCOUNT_CLIENT,
      });
    },
    async allocateToPayroll(payload) {
      return $axios.put(
        `${await getBaseUrl()}/accounts/allocate_to_payroll`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async all(params) {
      return $axios.get(`${await getBaseUrlV1()}/accounts`, {
        params,
        headers: getHeaders(),
      });
    },
    async get(id) {
      return $axios.get(`${await getBaseUrl()}/accounts/${id}`, {
        headers: getHeaders(),
      });
    },
    async settlementAccounts() {
      return $axios.get(`${await getBaseUrl()}/accounts/settlement-accounts`, {
        headers: getHeaders(),
      });
    },
    async globalAccounts() {
      return $axios.get(`${await getBaseUrl()}/global_accounts`, {
        headers: getHeaders(),
      });
    },
    async requestCollection() {
      return $axios.put(
        `${await getBaseUrl()}/accounts/request_collection_va`,
        {},
        {
          headers: getHeaders(),
        }
      );
    },
    async createGlobalAccount(payload) {
      return $axios.post(`${await getBaseUrl()}/global_accounts`, payload, {
        headers: getHeaders(),
      });
    },
    async globalCountryCurrencies() {
      return $axios.get(
        `${await getBaseUrl()}/global_accounts/global_country_currencies`,
        {
          apiKey: API_KEYS_MAPPING.GLOBAL_COUNTRY_CURRENCIES,
          headers: getHeaders(),
        }
      );
    },
    async downloadGlobalAccountTransactions(params) {
      return $axios.get(
        `${await getBaseUrl()}/accounts/download-global-account-transactions`,
        {
          params,
          headers: getHeaders(),
          responseType: "blob",
        }
      );
    },
    async fetchGlobalAccountTransactions(params) {
      return $axios.get(`${await getBaseUrl()}/global_accounts/transactions`, {
        params,
        apiKey: API_KEYS_MAPPING.FETCH_GLOBAL_ACCOUNT_TRANSACTIONS,
        headers: getHeaders(),
      });
    },
    async rapydCheckoutUrl(params, id) {
      return $axios.post(
        `${await getBaseUrl()}/accounts/${id}/rapyd-checkout-url`,
        params,
        {
          headers: getHeaders(),
        }
      );
    },
    async developerOptions() {
      return $axios.get(`${await getBaseUrl()}/accounts/developer-options`, {
        headers: getHeaders(),
      });
    },
  },
  Alert: {
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/alerts`, {
        params: params,
        headers: getHeaders(),
      });
    },

    async create(payload) {
      return $axios.post(`${await getBaseUrl()}/alerts`, payload, {
        headers: getHeaders(),
      });
    },

    async delete(id) {
      return $axios.delete(`${await getBaseUrl()}/alerts/${id}`, {
        headers: getHeaders(),
      });
    },

    async update(id, payload) {
      return $axios.put(`${await getBaseUrl()}/alerts/${id}`, payload, {
        headers: getHeaders(),
      });
    },
  },
  ApprovalRequests: {
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/approval_requests`, {
        params,
        headers: getHeaders(),
      });
    },
    async approve(payload) {
      return $axios.put(
        `${await getBaseUrl()}/approval_requests/approve`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async reject(payload) {
      return $axios.put(
        `${await getBaseUrl()}/approval_requests/reject`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async getCount() {
      return $axios.get(`${await getBaseUrl()}/approval_requests/count`, {
        headers: getHeaders(),
      });
    },
    async getApprovalRequestHistoryInfo(params) {
      return $axios.get(
        `${await getBaseUrl()}/approval_requests/approval_request_history`,
        { params, headers: getHeaders() }
      );
    },
  },
  Country: {
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/countries`, {
        params,
        headers: getHeaders(),
      });
    },

    async get({ countryId }) {
      return $axios.get(`${await getBaseUrl()}/countries/${countryId}`, {
        headers: getHeaders(),
      });
    },
  },

  ClientAnalytics: {
    async spends(params) {
      return $axios.get(`${await getBaseUrl()}/client_analytics/spends`, {
        params,
        headers: getHeaders(),
      });
    },
    async spendMetrics(params) {
      return $axios.get(
        `${await getBaseUrl()}/client_analytics/spend-metrics`,
        {
          params,
          headers: getHeaders(),
        }
      );
    },
    async transactions(params) {
      return $axios.get(`${await getBaseUrl()}/client_analytics/transactions`, {
        params,
        headers: getHeaders(),
      });
    },
    async topCount(params) {
      return $axios.get(`${await getBaseUrl()}/client_analytics/top-count`, {
        params,
        headers: getHeaders(),
      });
    },
    async topSpenders(params) {
      return $axios.get(`${await getBaseUrl()}/client_analytics/top-spenders`, {
        params,
        headers: getHeaders(),
      });
    },
  },
  Expenses: {
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/expenses`, {
        params,
        headers: getHeaders(),
      });
    },
    async getMissingDetils(params) {
      return $axios.get(`${await getBaseUrl()}/expenses/missing-details`, {
        apiKey: API_KEYS_MAPPING.EXPENSE_GET_MISSING_DETAILS,
        params,
        headers: getHeaders(),
      });
    },
    async getCreditDetails() {
      // expenses/credit_details
      return $axios.get(`${await getBaseUrl()}/expenses/credit_details`, {
        headers: getHeaders(),
      });
    },
    async uploadRecipts({ expenseId, payload }) {
      return $axios.put(
        `${await getBaseUrl()}/expenses/${expenseId}/upload-receipt`,
        payload,
        {
          apiKey: API_KEYS_MAPPING.UPLOAD_RECEIPT_EXPENSE,
          headers: getHeaders(),
        }
      );
    },

    async fetchStatistics(params) {
      return $axios.get(`${await getBaseUrl()}/expenses/statistics`, {
        params,
        headers: getHeaders(),
      });
    },

    async fetchExpenseDetails(params) {
      return $axios.get(`${await getBaseUrl()}/expenses/details`, {
        apiKey: API_KEYS_MAPPING.FETCH_EXPENSE_DETAILS,
        params,
        headers: getHeaders(),
      });
    },

    async get({ expenseId }) {
      return $axios.get(`${await getBaseUrl()}/expenses/${expenseId}`, {
        headers: getHeaders(),
        apiKey: API_KEYS_MAPPING.EXPENSE_DETAIL,
      });
    },

    // TODO: Endpoint might change for the below async function
    async reportMerchantCategoryDetails({ expenseId, payload }) {
      return $axios.put(
        `${await getBaseUrl()}/expenses/${expenseId}/reportMerchantCategoryDetails`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async declineDetails(params) {
      return $axios.get(`${await getBaseUrl()}/expenses/declines-details`, {
        params,
        headers: getHeaders(),
      });
    },

    async update({ expenseId, payload }) {
      return $axios.put(
        `${await getBaseUrl()}/expenses/${expenseId}`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },

    async cardBudgets(id) {
      return $axios.get(`${await getBaseUrl()}/expenses/${id}/card-budgets`, {
        headers: getHeaders(),
      });
    },

    async getSplitExpense({ expenseId }) {
      return $axios.get(
        `${await getBaseUrl()}/expenses/${expenseId}/split_expenses`,
        {
          apiKey: API_KEYS_MAPPING.GET_SPLIT_EXPENSE,
          headers: getHeaders(),
        }
      );
    },
    async updateSplitExpense({ expenseId, payload }) {
      return $axios.post(
        `${await getBaseUrl()}/expenses/${expenseId}/split_expenses`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async flagExpense({ expenseId, payload }) {
      return $axios.put(
        `${await getBaseUrl()}/expenses/${expenseId}/flag`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },

    async bulkApprove(payload) {
      return $axios.put(
        `${await getBaseUrl()}/expenses/bulk-approve`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async remind(payload) {
      return $axios.put(`${await getBaseUrl()}/expenses/remind`, payload, {
        headers: getHeaders(),
      });
    },
    async dismissSubmissionPolicy(expenseId) {
      return $axios.put(
        `${await getBaseUrl()}/expenses/${expenseId}/skip_submission_policy`,
        {},
        {
          headers: getHeaders(),
        }
      );
    },
    async requestRepayment({ expenseId, payload }) {
      return $axios.put(
        `${await getBaseUrl()}/expenses/${expenseId}/request-repayment`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async repaymentReceived(id) {
      const payload = {};
      return $axios.put(
        `${await getBaseUrl()}/expenses/${id}/repayment-received`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async cancelRepayment({ expenseId, payload }) {
      return $axios.put(
        `${await getBaseUrl()}/expenses/${expenseId}/cancel-repayment`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async getMerchantDetails(id) {
      // return $axios.get(`${await getBaseUrl()}/merchants/${id}`, {
      //   headers: getHeaders(),
      // });
      const merchantDetails = MOCK_DATA["api/v1/expenses/merchantDetails"];
      return merchantDetails;
    },
    async getMissingDetailsCount({ params }) {
      return $axios.get(
        `${await getBaseUrl()}/expenses/missing_details_count`,
        {
          params,
          headers: getHeaders(),
        }
      );
    },
    // /expenses/:expense_id/split_expenses/undo_split
    async undoSplit(id, payload = {}) {
      return $axios.post(
        `${await getBaseUrl()}/expenses/${id}/split_expenses/undo_split`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
  },
  // for payroll features - approval, payment and paid page
  Payrolls: {
    // https://docs.google.com/document/d/11asoe8UkeaY7Ugen9t1RK169VnQcM1WxA0ECR3ueXIQ/edit#heading=h.gelqp9qt66zl
    // invoice inbox billpay and Approvals - API.PurchaseBill
    // remaining 2 payment and paid billpay - API.Payments

    // invoice inbox payroll and approvals - API.Payrolls
    // remaining 2 payment and paid payroll - API.Payments

    async all(params) {
      return $axios.get(`${await getBaseUrl()}/payrolls`, {
        params,
        headers: getHeaders(),
      });
    },
    async performAction(id, payload) {
      return $axios.put(
        `${await getBaseUrl()}/payrolls/${id}/modify_action`,
        payload,
        { headers: getHeaders() }
      );
    },
    async create(payload) {
      return $axios.post(`${await getBaseUrl()}/payrolls`, payload, {
        headers: getHeaders(),
      });
    },
    async update(id, payload) {
      return $axios.put(`${await getBaseUrl()}/payrolls/${id}`, payload, {
        headers: getHeaders(),
      });
    },
    async deleteDraft(id) {
      return $axios.delete(`${await getBaseUrl()}/payrolls/${id}`, {
        headers: getHeaders(),
      });
    },
    async archive(id, params) {
      return $axios.delete(`${await getBaseUrl()}/payrolls/${id}`, {
        params,
        headers: getHeaders(),
      });
    },
    async getLineItems(id) {
      return $axios.get(`${await getBaseUrl()}/payrolls/${id}/line_items`, {
        headers: getHeaders(),
      });
    },
    async allApprovals(params) {
      return $axios.get(`${await getBaseUrl()}/payrolls`, {
        params,
        headers: getHeaders(),
      });
    },
    async getApproval(id) {
      // add mock data for slider here
      return $axios.get(`${await getBaseUrl()}/payrolls/${id}`, {
        apiKey: API_KEYS_MAPPING.PAYROLLS_GET,
        headers: getHeaders(),
      });
    },
    async payrollWallet(params) {
      return $axios.get(`${await getBaseUrl()}/accounts/payroll_wallet`, {
        params,
        headers: getHeaders(),
      });
    },
    async updateApproval(id, payload) {
      return $axios.put(`${await getBaseUrl()}/payrolls/${id}`, payload, {
        headers: getHeaders(),
      });
    },
    // payroll settings
    async enablePayroll() {
      return $axios.put(
        `${await getBaseUrl()}/payroll_settings/enable_payroll`,
        {},
        { headers: getHeaders() }
      );
    },
    async bulkOperation(payload) {
      return $axios.put(
        `${await getBaseUrl()}/payrolls/bulk_operation`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async getDuplicate(params) {
      // payroll_month, vendor_id, amount
      return $axios.get(
        `${await getBaseUrl()}/payrolls/check_duplicate_payroll`,
        {
          params,
          headers: getHeaders(),
        }
      );
    },
    async downloadSampleFileForBulkUploadForPayrollSheet() {
      return $axios.get(`${await getBaseUrl()}/payrolls/export`, {
        headers: getHeaders(),
      });
    },
    async bulkUploadPayrolls(payload) {
      return $axios.post(
        `${await getBaseUrl()}/payrolls/bulk_upload`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async fetchBulkPayrollStatus(params) {
      return $axios.get(`${await getBaseUrl()}/payrolls/bulk_payroll_status`, {
        params,
        headers: getHeaders(),
      });
    },
    async modifyActionBulkPayroll(payload) {
      return $axios.put(
        `${await getBaseUrl()}/payrolls/modify_action_bulk_payroll`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
  },

  Exports: {
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/file_records`, {
        params,
        headers: getHeaders(),
      });
    },
    async create(payload) {
      return $axios.post(`${await getBaseUrl()}/export`, payload, {
        headers: getHeaders(),
      });
    },
    async delete(params) {
      return $axios.delete(`${await getBaseUrl()}/file_records/${params.id}`, {
        headers: getHeaders(),
      });
    },
    async retry(params) {
      return $axios.put(
        `${await getBaseUrl()}/file_records/${params.id}/retry`,
        {},
        {
          headers: getHeaders(),
        }
      );
    },
  },

  GlobalSettings: {
    async toggleSetting(payload) {
      return $axios.post(
        `${await getBaseUrl()}/global_settings/toggle-setting`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async getAlerts(params) {
      return $axios.get(`${await getBaseUrl()}/global_settings/alerts`, {
        params,
        headers: getHeaders(),
      });
    },
    async updateAlerts(params) {
      return $axios.post(
        `${await getBaseUrl()}/global_settings/update_alerts`,
        params,
        {
          headers: getHeaders(),
        }
      );
    },
  },

  NotificationPreferences: {
    async all() {
      return $axios.get(`${await getBaseUrl()}/notification_preferences`, {
        headers: getHeaders(),
      });
    },
    async resetDefaultSettings(params) {
      return $axios.post(
        `${await getBaseUrl()}/notification_preferences/reset-to-default`,
        params,
        {
          headers: getHeaders(),
        }
      );
    },
    async bulkUpdate(params) {
      return $axios.post(
        `${await getBaseUrl()}/notification_preferences/bulk-update`,
        params,
        {
          headers: getHeaders(),
        }
      );
    },
  },

  MerchantRestrictions: {
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/merchant_restrictions`, {
        params,
        headers: getHeaders(),
      });
    },
  },

  Payments: {
    // This is used for both payroll and billPay
    // `/payments` (for Billpay Payment, Billpay Paid)
    // `/purchase_bills` (for Billpay Approval)
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/payments`, {
        params,
        headers: getHeaders(),
      });
    },
    async allApprovals(params) {
      return $axios.get(`${await getBaseUrl()}/purchase_bills`, {
        params,
        headers: getHeaders(),
      });
    },
    async get(id, params) {
      // add mock data for payment
      // add mock data for paid
      return $axios.get(`${await getBaseUrl()}/payments/${id}`, {
        apiKey: API_KEYS_MAPPING.PAYMENTS_GET,
        params,
        headers: getHeaders(),
      });
    },
    async getApproval(id) {
      return $axios.get(`${await getBaseUrl()}/purchase_bills/${id}`, {
        apiKey: API_KEYS_MAPPING.PAYMENTS_GET,
        headers: getHeaders(),
      });
    },
    async update(id, payload) {
      return $axios.get(`${await getBaseUrl()}/payments/${id}`, payload, {
        apiKey: API_KEYS_MAPPING.PAYMENTS_UPDATE,
        headers: getHeaders(),
      });
    },
    async updateApproval(id, payload) {
      return $axios.put(`${await getBaseUrl()}/purchase_bills/${id}`, payload, {
        headers: getHeaders(),
      });
    },
    async getApprovers(params) {
      // Example: /api/v3/budgets/1/approvers?amount=1&owner_type=PurchaseBill

      return $axios.get(`${await getBaseUrl()}/policy_groups/approvers`, {
        params,
        headers: getHeaders(),
      });
    },
    async payNow(id, payload) {
      return $axios.post(
        `${await getBaseUrl()}/payments/${id}/pay_now`,
        payload,
        { headers: getHeaders() }
      );
    },
    async markAsPaid(id, params = {}) {
      return $axios.put(
        `${await getBaseUrl()}/payments/${id}/mark_as_paid`,
        {},
        {
          params,
          headers: getHeaders(),
        }
      );
    },
    async retryPayment(id, params = {}) {
      return $axios.put(
        `${await getBaseUrl()}/payments/${id}/retry`,
        {},
        { params, headers: getHeaders() }
      );
    },
    async bulkOperation(payload) {
      return $axios.put(
        `${await getBaseUrl()}/purchase_bills/bulk_operation`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async downloadReceiptEndpoint(id, params) {
      return $axios.get(`${await getBaseUrl()}/payments/${id}/receipt.pdf`, {
        params,
        headers: getHeaders(),
        responseType: "blob",
      });
    },
    // settings
    // Need to remove code for adding roles since now we have only one API which will handle adding and deletion of users as per role
  },

  PayrollSetting: {
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/payroll_settings/settings`, {
        params,
        headers: getHeaders(),
      });
    },

    async update(payload) {
      return $axios.put(
        `${await getBaseUrl()}/payroll_settings/update_settings`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
  },

  PurchaseBills: {
    // For Billpay Invoice Inbox page, and bill creation
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/purchase_bills`, {
        params,
        headers: getHeaders(),
      });
    },
    async getQuote(payload) {
      return $axios.post(
        `${await getBaseUrl()}/payment_quotes/generate-quote`,
        payload,
        { headers: getHeaders() }
      );
    },
    async create(payload) {
      return $axios.post(`${await getBaseUrl()}/purchase_bills`, payload, {
        headers: getHeaders(),
      });
    },
    async update(id, payload) {
      return $axios.put(`${await getBaseUrl()}/purchase_bills/${id}`, payload, {
        headers: getHeaders(),
      });
    },
    async performAction(id, payload) {
      return $axios.put(
        `${await getBaseUrl()}/purchase_bills/${id}/modify_action`,
        payload,
        { headers: getHeaders() }
      );
    },
    async deleteDraft(id) {
      return $axios.delete(`${await getBaseUrl()}/purchase_bills/${id}`, {
        headers: getHeaders(),
      });
    },
    async archive(id, params) {
      return $axios.delete(`${await getBaseUrl()}/purchase_bills/${id}`, {
        params,
        headers: getHeaders(),
      });
    },
    async getLineItems(id) {
      return $axios.get(
        `${await getBaseUrl()}/purchase_bills/${id}/line_items`,
        { headers: getHeaders() }
      );
    },
    async getDuplicate(params) {
      // invoice_number, vendor_id, invoice_date
      return $axios.get(
        `${await getBaseUrl()}/purchase_bills/check_duplicate_bill`,
        {
          params,
          headers: getHeaders(),
        }
      );
    },
    async getBillMasterHistory({ id }) {
      return $axios.get(
        `${await getBaseUrl()}/purchase_bills/${id}/get_bill_master_history`,
        { headers: getHeaders() }
      );
    },
  },

  ReimbursementMileageRate: {
    async all() {
      return $axios.get(
        `${await getBaseUrl()}/reimbursement_settings/mileage_rates`,
        {
          apiKey: API_KEYS_MAPPING.REIMBURSEMENT_MILEAGE_RATE_ALL,
          headers: getHeaders(),
        }
      );
    },
    async get(countryName) {
      const list = MOCK_DATA["api/v1/reimbursement-settings/mileage-rates"];
      const mileageRateObject = list.find(
        (data) => data.fullName === countryName
      );
      return mileageRateObject;
    },

    async update(payload) {
      return $axios.put(
        `${await getBaseUrl()}/reimbursement_settings/update_settings`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
  },

  Reimbursements: {
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/claims`, {
        params,
        headers: getHeaders(),
      });
    },
    async create(payload) {
      return $axios.post(`${await getBaseUrl()}/claims`, payload, {
        headers: getHeaders(),
      });
    },

    async update(id, payload) {
      return $axios.put(`${await getBaseUrl()}/claims/${id}`, payload, {
        headers: getHeaders(),
      });
    },

    async get(params) {
      return $axios.get(`${await getBaseUrl()}/claims/${params.reimId}`, {
        apiKey: API_KEYS_MAPPING.REIMBURSEMENT_GET,
        headers: getHeaders(),
      });
    },

    async performAction(id, payload) {
      return $axios.put(
        `${await getBaseUrl()}/claims/${id}/modify_action`,
        payload,
        { headers: getHeaders() }
      );
    },

    async bulkOperation(payload) {
      return $axios.put(
        `${await getBaseUrl()}/claims/bulk_operation`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },

    async approvers(params) {
      return $axios.get(`${await getBaseUrl()}/claims/approvers`, {
        params,
        headers: getHeaders(),
      });
    },

    async generateBeneficiaryAmount(payload) {
      return $axios.put(
        `${await getBaseUrl()}/claims/generate_beneficiary_amount`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },

    async delete(id) {
      return $axios.delete(`${await getBaseUrl()}/claims/${id}`, {
        headers: getHeaders(),
      });
    },
    async archive(id, params) {
      return $axios.delete(`${await getBaseUrl()}/claims/${id}`, {
        params,
        headers: getHeaders(),
      });
    },
    async bulkUpload(payload) {
      return $axios.postForm(
        `${await getBaseUrl()}/claims/bulk_upload`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async checkDuplicateReimbursment(params) {
      return $axios.get(
        `${await getBaseUrl()}/claims/check_duplicate_reimbursement`,
        { params, headers: getHeaders() }
      );
    },

    async bulkUploadStatus(params) {
      return $axios.get(`${await getBaseUrl()}/bulk_records`, {
        params,
        headers: getHeaders(),
      });
    },
  },

  Reports: {
    async all(params) {
      // TODO: At the time of integration
      return $axios.get(`${await getBaseUrl()}/reports`, {
        params,
        headers: getHeaders(),
      });
    },

    async allUserReport(params) {
      return $axios.get(`${await getBaseUrl()}/reports/users`, {
        params,
        headers: getHeaders(),
      });
    },

    async getUserReportReimbursementDetails(params) {
      return $axios.get(
        `${await getBaseUrl()}/reports/user_reimbursement_detail`,
        {
          apiKey:
            API_KEYS_MAPPING.REPORTS_GET_USER_REPORT_REIMBURSEMENT_DETAILS,
          params,
          headers: getHeaders(),
        }
      );
    },

    async create(payload) {
      return $axios.post(`${await getBaseUrl()}/reports`, payload, {
        headers: getHeaders(),
      });
    },

    async get(params) {
      return $axios.get(`${await getBaseUrl()}/reports/${params.reportId}`, {
        apiKey: API_KEYS_MAPPING.REPORTS_GET,
        headers: getHeaders(),
      });
    },

    async performAction(id, payload) {
      return $axios.put(
        `${await getBaseUrl()}/reports/${id}/modify_action`,
        payload,
        { headers: getHeaders() }
      );
    },

    async update(id, payload) {
      return $axios.put(`${await getBaseUrl()}/reports/${id}`, payload, {
        headers: getHeaders(),
      });
    },

    async updateClaim(id, payload) {
      return $axios.put(
        `${await getBaseUrl()}/reports/${id}/update_claims`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },

    async delete(id, params) {
      return $axios.delete(`${await getBaseUrl()}/reports/${id}`, {
        params,
        headers: getHeaders(),
      });
    },

    async claims(params) {
      return $axios.get(`${await getBaseUrl()}/reports/claims`, {
        params,
        headers: getHeaders(),
      });
    },

    async submit(id, payload) {
      return $axios.put(`${await getBaseUrl()}/reports/${id}/submit`, payload, {
        headers: getHeaders(),
      });
    },

    async bulkOperation(payload) {
      return $axios.put(
        `${await getBaseUrl()}/reports/bulk_operation`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
  },
  UserReimbursement: {
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/claims/users`, {
        params,
        headers: getHeaders(),
      });
    },
    async get(params) {
      return $axios.get(
        `${await getBaseUrl()}/claims/user_reimbursement_detail`,
        {
          apiKey: API_KEYS_MAPPING.USER_REIMBURSEMENT_GET,
          params,
          headers: getHeaders(),
        }
      );
    },
  },

  ReimbursementSettlement: {
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/reimbursement_settlements`, {
        params,
        headers: getHeaders(),
      });
    },

    async get(params) {
      const { id } = params;
      return $axios.get(
        `${await getBaseUrl()}/reimbursement_settlements/${id}`,
        {
          apiKey: API_KEYS_MAPPING.REIMBURSEMENT_SETTLEMENT_GET,
          headers: getHeaders(),
        }
      );
    },

    async getAssociatedData(params) {
      const { id } = params;
      return $axios.get(
        `${await getBaseUrl()}/reimbursement_settlements/${id}/associated_reimbursements`,
        { params, headers: getHeaders() }
      );
    },

    async settleClaim(payload) {
      return $axios.post(
        `${await getBaseUrl()}/reimbursement_settlements`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
  },

  ReimbursementSetting: {
    async all(params) {
      return $axios.get(
        `${await getBaseUrl()}/reimbursement_settings/settings`,
        {
          params,
          headers: getHeaders(),
        }
      );
    },

    async update(payload) {
      return $axios.put(
        `${await getBaseUrl()}/reimbursement_settings/update_settings`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },

    async getClaimPolicy(params) {
      return $axios.get(`${await getBaseUrl()}/claim_policies/claim_policy`, {
        params,
        headers: getHeaders(),
      });
    },

    async getClaimCategoryDetails(params) {
      return $axios.get(`${await getBaseUrl()}/claims/check_category_limit`, {
        params,
        headers: getHeaders(),
      });
    },
    async updateReimbursementPaymentInitiators({ payload }) {
      return $axios.post(
        `${await getBaseUrl()}/user_roles/upsert-user-roles`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
  },

  CardPrefundPaymet: {
    async generateQuote(params) {
      return $axios.get(
        `${await getBaseUrl()}/card_prefund_payments/generate_conversion_quote`,
        {
          params,
          headers: getHeaders(),
        }
      );
    },

    async exchangeFunds(payload) {
      return $axios.post(
        `${await getBaseUrl()}/card_prefund_payments/`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
  },

  ClaimPolicies: {
    async get(params) {
      return $axios.get(`${await getBaseUrl()}/claim_policies/claim_policy`, {
        apiKey: API_KEYS_MAPPING.CLAIM_POLICIES_GET,
        params,
        headers: getHeaders(),
      });
    },

    async upsert(payload) {
      return $axios.post(
        `${await getBaseUrl()}/claim_policies/upsert_claim_policy`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },

    async archive(params) {
      return $axios.delete(
        `${await getBaseUrl()}/claim_policies/${params.id}`,
        {
          params,
          headers: getHeaders(),
        }
      );
    },
  },

  Cards: {
    async get(id) {
      return $axios.get(`${await getBaseUrl()}/cards/${id}`, {
        headers: getHeaders(),
        apiKey: API_KEYS_MAPPING.CARDS_GET,
      });
    },

    async spent(cardBudgetId, params) {
      return $axios.get(
        `${await getBaseUrl()}/card_budgets/${cardBudgetId}/spent`,
        {
          params,
          headers: getHeaders(),
        }
      );
    },

    async all(params) {
      return $axios.get(`${await getBaseUrl()}/cards`, {
        params,
        headers: getHeaders(),
      });
    },

    async create(payload) {
      return $axios.post(`${await getBaseUrl()}/card_requests`, payload, {
        headers: getHeaders(),
      });
    },

    async update(id, payload) {
      return $axios.put(`${await getBaseUrl()}/cards/${id}`, payload, {
        headers: getHeaders(),
      });
    },

    async freeze(id, payload = {}) {
      return $axios.put(`${await getBaseUrl()}/cards/${id}/freeze`, payload, {
        headers: getHeaders(),
      });
    },

    async unfreeze(id, payload = {}) {
      return $axios.put(`${await getBaseUrl()}/cards/${id}/unfreeze`, payload, {
        headers: getHeaders(),
      });
    },

    async block(id, payload = {}) {
      return $axios.put(`${await getBaseUrl()}/cards/${id}/block`, payload, {
        headers: getHeaders(),
      });
    },
    async changeLinkedTo(card_budget_id) {
      return $axios.put(
        `${await getBaseUrl()}/card_budgets/${card_budget_id}/select-card-budget `,
        {},
        {
          headers: getHeaders(),
        }
      );
    },
    async updateSettingsInfo({ id, payload }) {
      return $axios.put(
        `${await getBaseUrl()}/cards/${id}/update_card_settings`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async paymentInfo(id) {
      return $axios.get(`${await getBaseUrl()}/cards/${id}/payment-info`, {
        apiKey: API_KEYS_MAPPING.FETCH_PAYMENT_INFO,
        headers: getHeaders(),
      });
    },

    async requirement() {
      return $axios.get(`${await getBaseUrl()}/cards/requirement`, {
        headers: getHeaders(),
      });
    },

    async activate(id, payload) {
      return $axios.put(`${await getBaseUrl()}/cards/${id}/activate`, payload, {
        headers: getHeaders(),
      });
    },

    async pinReset(id, payload) {
      return $axios.put(
        `${await getBaseUrl()}/cards/${id}/reset-pin`,
        payload,
        { headers: getHeaders() }
      );
    },

    async getCreateVirtualCardSteps() {
      return $axios.get(`${await getBaseUrl()}/card_requests/steps`, {
        apiKey: API_KEYS_MAPPING.GET_CREATE_VIRTUAL_CARD_STEPS,
        headers: getHeaders(),
      });
    },

    async getCardChannels(id) {
      return $axios.get(`${await getBaseUrl()}/cards/${id}/card_channel`, {
        headers: getHeaders(),
      });
    },

    async cardTagValues(id) {
      return $axios.get(`${await getBaseUrl()}/cards/${id}/card-tag-values`, {
        headers: getHeaders(),
      });
    },

    async cardChannel(id) {
      return $axios.get(`${await getBaseUrl()}/cards/${id}/card-channel`, {
        headers: getHeaders(),
      });
    },

    async resetPinUrl(id) {
      return $axios.put(
        `${await getBaseUrl()}/cards/${id}/reset-pin-url`,
        {},
        {
          headers: getHeaders(),
        }
      );
    },

    async updateCardChannel(id, payload) {
      return $axios.put(
        `${await getBaseUrl()}/cards/${id}/update-card-channel`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async poolCardDetails(params) {
      return $axios.get(`${await getBaseUrl()}/cards/pool-card-details`, {
        params,
        headers: getHeaders(),
      });
    },
    async getProjectList(params) {
      return $axios.get(`${await getBaseUrl()}/card_budgets`, {
        apiKey: API_KEYS_MAPPING.GET_PROJECT_LIST,
        params,
        headers: getHeaders(),
      });
    },
    async physicalCardsActivationFormFields(params) {
      return $axios.get(`${await getBaseUrl()}/cards/requirement`, {
        apiKey: API_KEYS_MAPPING.PHSYICAL_CARDS_ACTIVATION_FORM_FIELDS,
        params,
        headers: getHeaders(),
        params,
      });
    },
    async updateCardLimit(payload) {
      return $axios.post(`${await getBaseUrl()}/card_requests`, payload, {
        headers: getHeaders(),
      });
    },
    async updateExpenseReviewRange(payload) {
      const expenseReviewRange =
        MOCK_DATA["api/v1/cards/expense-review/id/ranges"];
      return { ...expenseReviewRange, ...payload };
    },

    async getMerchantList(params) {
      return $axios.get(`${await getBaseUrl()}/merchants`, {
        apiKey: API_KEYS_MAPPING.GET_MERCHANT_LIST,
        params,
        headers: getHeaders(),
      });
    },

    async getMerchantDetails(merchantId) {
      return $axios.get(`${await getBaseUrl()}/merchants/${merchantId}`, {
        apiKey: API_KEYS_MAPPING.CARDS_GET_MERCHANT_DETAILS,
        headers: getHeaders(),
      });
    },

    async updateMerchant(id, payload) {
      return $axios.put(`${await getBaseUrl()}/merchants/${id}`, payload, {
        headers: getHeaders(),
      });
    },

    async getCardRequest(params) {
      const { id, ...rest } = params;
      return $axios.get(`${await getBaseUrl()}/card_requests/${id}`, {
        params: rest,
        apiKey: API_KEYS_MAPPING.GET_CARD_REQUEST,
        headers: getHeaders(),
      });
    },

    async skipApprovalCardRequest(payload) {
      const { id, ...rest } = payload;
      return $axios.put(
        `${await getBaseUrl()}/card_requests/${id}/modify_action`,
        rest,
        {
          headers: getHeaders(),
        }
      );
    },

    async bulkOperation(payload) {
      return $axios.put(
        `${await getBaseUrl()}/card_requests/bulk_operation`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async getCardRequestList(params) {
      return $axios.get(`${await getBaseUrl()}/card_requests`, {
        params,
        headers: getHeaders(),
      });
    },
    async getMerchantRestrictionDetails(params) {
      return $axios.get(`${await getBaseUrl()}/merchant_restrictions`, {
        apiKey: API_KEYS_MAPPING.GET_MERCHANT_RESTRICTION_DETAILS,
        params,
        headers: getHeaders(),
      });
    },
    async updateMerchantRestrictionDetails(payload, id) {
      return $axios.put(
        `${await getBaseUrl()}/merchant_restrictions/${id}`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async createMerchantRestrictions(payload) {
      return $axios.post(
        `${await getBaseUrl()}/merchant_restrictions`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async getCardActionsGuidelineVideos() {
      return $axios.get(
        `${await getPublicAssetsUrl()}app/icici-guidelines.json`,
        {
          apiKey: API_KEYS_MAPPING.CARD_ACTIONS_GUIDELINE_VIDEOS,
        }
      );
    },
  },
  Company: {
    async redeemCashback(payload) {
      return $axios.put(
        `${await getBaseUrl()}/company/cashbacks/redeem`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },

    async getBudgetData(params) {
      return $axios.get(
        `${await getBaseUrl()}/company/projects/budget_balances`,
        {
          params,
          headers: getHeaders(),
        }
      );
    },

    async getPendingRequests(params) {
      return $axios.get(`${await getBaseUrl()}/users/pending-requests`, {
        params,
        headers: getHeaders(),
      });
    },
    async allDepartments() {
      const filteredDepartments = MOCK_DATA["api/v1/company/departments"];
      return filteredDepartments;
    },
    async sendFeedback(payload) {
      return $axios.post(`${await getBaseUrl()}/company/feedbacks`, payload, {
        headers: getHeaders(),
      });
    },
    Credit: {
      async fetchCreditDetails() {
        return $axios.get(
          `${await getBaseUrl()}/company/credit/credit_details`,
          {
            headers: getHeaders(),
          }
        );
      },
      async drawCredit(payload) {
        return $axios.put(
          `${await getBaseUrl()}/company/credit/allocate_to_bill_pay`,
          payload,
          {
            headers: getHeaders(),
          }
        );
      },
      async repayment(payload) {
        return $axios.put(
          `${await getBaseUrl()}/company/credit/repayment`,
          payload,
          {
            headers: getHeaders(),
          }
        );
      },
      async fetchCreditBills() {
        return $axios.get(`${await getBaseUrl()}/company/credit/credit_bills`, {
          apiKey: API_KEYS_MAPPING.FETCH_CREDIT_BILLS,
          headers: getHeaders(),
        });
      },
      async downloadBill(id) {
        return $axios.get(
          `${await getBaseUrl()}/company/credit/${id}/download_bill.pdf`,
          {
            headers: getHeaders(),
            responseType: "blob",
          }
        );
      },
      async createRequest(payload) {
        return $axios.post(
          `${await getBaseUrl()}/company/credit/create_request`,
          payload,
          {
            headers: getHeaders(),
          }
        );
      },
    },
    Department: {
      async all(params) {
        return $axios.get(`${await getBaseUrl()}/company/projects`, {
          params,
          headers: getHeaders(),
        });
      },
      async get(params) {
        const { id, ...paramsWithoutId } = params;
        return $axios.get(`${await getBaseUrl()}/company/projects/${id}`, {
          params: paramsWithoutId,
          headers: getHeaders(),
        });
      },
    },
    Project: {
      async all(params) {
        return $axios.get(`${await getBaseUrl()}/company/projects`, {
          apiKey: API_KEYS_MAPPING.PROJECT_ALL,
          params,
          headers: getHeaders(),
        });
      },

      async get(params) {
        return $axios.get(
          `${await getBaseUrl()}/company/projects/${params.id}`,
          {
            apiKey: API_KEYS_MAPPING.GET_PROJECT_DETAILS,
            headers: getHeaders(),
          }
        );
      },

      async getApprovers(params) {
        // const { id } = params;
        // api/v1/company/projects/id/approvers
        const approvers = MOCK_DATA["api/v1/company/projects/approvers"];
        return approvers;
      },

      async addProject(payload) {
        return $axios.post(`${await getBaseUrl()}/company/projects`, payload, {
          headers: getHeaders(),
        });
      },

      async archiveProject(id, params) {
        return $axios.put(
          `${await getBaseUrl()}/company/projects/${id}/archive`,
          params,
          {
            headers: getHeaders(),
          }
        );
      },

      async update(id, payload) {
        return $axios.put(
          `${await getBaseUrl()}/company/projects/${id}`,
          payload,
          {
            headers: getHeaders(),
          }
        );
      },

      async policyGroups(params) {
        return $axios.get(
          `${await getBaseUrl()}/company/projects/policy_groups`,
          {
            apiKey: API_KEYS_MAPPING.COMPANY_POLICY_GROUPS,
            params,
            headers: getHeaders(),
          }
        );
      },
    },

    Location: {
      async all(params) {
        return $axios.get(`${await getBaseUrl()}/company/locations`, {
          params,
          headers: getHeaders(),
        });
      },

      async get(params) {
        return $axios.get(
          `${await getBaseUrl()}/company/locations/${params.id}`,
          {
            apiKey: API_KEYS_MAPPING.COMPANY_LOCATION_GET,
            headers: getHeaders(),
          }
        );
      },
      async create(params) {
        return $axios.post(`${await getBaseUrl()}/company/locations`, params, {
          headers: getHeaders(),
        });
      },
      async edit({ id, params }) {
        return $axios.put(
          `${await getBaseUrl()}/company/locations/${id}`,
          params,
          {
            headers: getHeaders(),
          }
        );
      },
      async archive({ id, params }) {
        return $axios.delete(`${await getBaseUrl()}/company/locations/${id}`, {
          headers: getHeaders(),
        });
      },
    },

    Budgets: {
      async all(params) {
        return $axios.get(`${await getBaseUrl()}/budgets`, {
          params,
          headers: getHeaders(),
        });
      },
      async get(params) {
        const { id, ...rest } = params;
        return $axios.get(`${await getBaseUrl()}/budgets/${id}`, {
          apiKey: API_KEYS_MAPPING.COMPANY_BUDGETS_GET,
          params: rest,
          headers: getHeaders(),
        });
      },
      async delete(params) {
        const { id, ...rest } = params;
        return $axios.put(`${await getBaseUrl()}/budgets/${id}/archive`, rest, {
          headers: getHeaders(),
        });
      },
      async edit(params) {
        const { id, ...rest } = params;
        return $axios.put(`${await getBaseUrl()}/budgets/${id}`, rest, {
          headers: getHeaders(),
        });
      },
      async getBudgets() {
        return $axios.get(`${await getBaseUrl()}/budgets/available_modules`, {
          headers: getHeaders(),
        });
      },
    },
    Trutrips: {
      async addCompany(payload) {
        return $axios.post(
          `${await getBaseUrl()}/trutrips/add_company`,
          payload,
          {
            headers: getHeaders(),
          }
        );
      },
      async getUsers(params) {
        return $axios.get(`${await getBaseUrl()}/trutrips/get_users`, {
          params,
          headers: getHeaders(),
        });
      },
      async companyLogin(payload) {
        return $axios.post(
          `${await getBaseUrl()}/trutrips/company_login`,
          payload,
          {
            headers: getHeaders(),
          }
        );
      },
      async addUsers(payload) {
        return $axios.post(
          `${await getBaseUrl()}/trutrips/add_users`,
          payload,
          {
            headers: getHeaders(),
          }
        );
      },
      async deleteUsers(payload) {
        return $axios.put(
          `${await getBaseUrl()}/trutrips/delete_user`,
          payload,
          {
            headers: getHeaders(),
          }
        );
      },
    },
    Roles: {
      async all(params) {
        return $axios.get(`${await getBaseUrl()}/roles`, {
          params,
          headers: getHeaders(),
        });
      },
      async get(params) {
        const { roleId } = params;
        return $axios.get(`${await getBaseUrl()}/roles/${roleId}`, {
          apiKey: API_KEYS_MAPPING.COMPANY_ROLES_GET,
          headers: getHeaders(),
        });
      },
      async addCustomRole(params) {
        return $axios.post(`${await getBaseUrl()}/roles`, params, {
          headers: getHeaders(),
        });
      },
      async editCustomRole(params) {
        const { id, payload } = params;
        return $axios.put(`${await getBaseUrl()}/roles/${id}`, payload, {
          headers: getHeaders(),
        });
      },
      async deleteCustomRole(params) {
        const { id } = params;
        return $axios.delete(`${await getBaseUrl()}/roles/${id}`, {
          headers: getHeaders(),
        });
      },
    },
    Permissions: {
      async all(params) {
        return $axios.get(`${await getBaseUrl()}/permissions`, {
          apiKey: API_KEYS_MAPPING.COMPANY_PERMISSSIONS_ALL,
          params,
          headers: getHeaders(),
        });
      },
    },
    Billing: {
      async getInvoices(params) {
        return $axios.get(`${await getBaseUrl()}/company/invoices`, {
          params,
          headers: getHeaders(),
        });
      },
      async getBillingDetails() {
        return $axios.get(
          `${await getBaseUrl()}/company/invoices/plan_details`,
          {
            headers: getHeaders(),
          }
        );
      },
      async getUpcomingInvoice() {
        return $axios.get(
          `${await getBaseUrl()}/company/invoices/upcoming_invoice`,
          {
            headers: getHeaders(),
          }
        );
      },
      async getInvoiceDetails({ id }) {
        return $axios.get(`${await getBaseUrl()}/company/invoices/${id}`, {
          apiKey: API_KEYS_MAPPING.COMPANY_BILLING_GET_INVOICE_DETAILS,
          headers: getHeaders(),
        });
      },
      async pay(params) {
        const { id } = params;
        return $axios.put(
          `${await getBaseUrl()}/company/invoices/${id}/pay`,
          {},
          {
            headers: getHeaders(),
          }
        );
      },
    },
    async locations() {
      return $axios.get(`${await getBaseUrl()}/company/locations/list`, {
        headers: getHeaders(),
      });
    },
    async roles(params) {
      const roles = MOCK_DATA["api/v1/company/roles"];
      return roles;
    },
    async people(params) {
      const people = MOCK_DATA["api/v1/users"];
      const { status } = params;
      return people.filter((item) =>
        status?.length ? status?.includes(item.status) : []
      );
    },
    async getPendingActions() {
      return MOCK_DATA["api/v1/company/pending-actions"];

      // return $axios.get(`${await getBaseUrl()}/company/pending-actions`, {
      //   headers: getHeaders(),
      // });
    },
  },

  Tags: {
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/tags`, {
        params,
        headers: getHeaders(),
      });
    },
    async createTag(params) {
      return $axios.post(`${await getBaseUrl()}/tags/`, params, {
        headers: getHeaders(),
      });
    },
    async editTag(params) {
      const { id } = params;
      return $axios.put(`${await getBaseUrl()}/tags/${id}`, params, {
        headers: getHeaders(),
      });
    },
    async deleteTag(id) {
      return $axios.delete(`${await getBaseUrl()}/tags/${id}`, {
        headers: getHeaders(),
      });
    },
  },

  ApprovalPolicy: {
    async getPolicyGroups(params) {
      return $axios.get(`${await getBaseUrl()}/policy_groups`, {
        params,
        headers: getHeaders(),
      });
    },

    async getPolicy(params) {
      return $axios.get(`${await getBaseUrl()}/policy_groups/get_policy`, {
        apiKey: API_KEYS_MAPPING.APPROVAL_GET_POLICY,
        params,
        headers: getHeaders(),
      });
    },

    async upsertPolicyGroup(params) {
      return $axios.post(
        `${await getBaseUrl()}/policy_groups/upsert_approval_policy`,
        params,
        {
          headers: getHeaders(),
        }
      );
    },

    async archivePolicyGroup(params) {
      return $axios.delete(`${await getBaseUrl()}/policy_groups/${params.id}`, {
        params,
        headers: getHeaders(),
      });
    },
  },
  SubmissionPolicy: {
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/submission_policies`, {
        params,
        headers: getHeaders(),
      });
    },
    async getPolicy(params) {
      return $axios.get(
        `${await getBaseUrl()}/submission_policies/get_policy`,
        {
          params,
          headers: getHeaders(),
        }
      );
    },

    async upsertPolicyGroup(params) {
      return $axios.post(
        `${await getBaseUrl()}/submission_policies/upsert_submission_policy`,
        params,
        {
          headers: getHeaders(),
        }
      );
    },

    async archiveSubmissionPolicy(params) {
      return $axios.delete(
        `${await getBaseUrl()}/submission_policies/${params.id}`,
        {
          params,
          headers: getHeaders(),
        }
      );
    },
  },

  Dashboard: {
    async getPendingActions() {
      return $axios.get(`${await getBaseUrl()}/dashboard/pending_counts`, {
        headers: getHeaders(),
      });
    },
    async getQuickActions() {
      return $axios.get(`${await getBaseUrl()}/dashboard/quick_access`, {
        headers: getHeaders(),
      });
    },
  },
  User: {
    async get(params) {
      const { userId } = params;
      return $axios.get(`${await getBaseUrl()}/users/${userId}`, {
        apiKey: API_KEYS_MAPPING.USER_GET,
        headers: getHeaders(),
      });
    },

    async changeUserAccess(payload) {
      const id = payload?.id;
      delete payload?.id;
      return $axios.put(`${await getBaseUrl()}/users/${id}/access`, payload, {
        headers: getHeaders(),
      });
    },
    async getUserKycStatusInfo() {
      return $axios.get(`${await getBaseUrl()}/users/ckyc-status`, {
        headers: getHeaders(),
      });
    },
    async getGettingStartedDetails(params) {
      const { id, ...rest } = params;
      return $axios.get(`${await getBaseUrl()}/users/getting-started`, {
        params: rest,
        headers: getHeaders(),
      });
    },
    async getRetryLinkForBannerCta() {
      return $axios.get(`${await getBaseUrl()}/users/get_retry_link`, {
        headers: getHeaders(),
      });
    },
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/users`, {
        apiKey: params?.shallow
          ? API_KEYS_MAPPING.USER_ALL_SHALLOW
          : API_KEYS_MAPPING.USER_ALL,
        params,
        headers: getHeaders(),
      });
    },
    async update(payload, id) {
      return $axios.put(`${await getBaseUrl()}/users/${id}`, payload, {
        headers: getHeaders(),
      });
    },
    async activeSessions(payload) {
      return $axios.get(`${await getBaseUrl()}/users/sessions`, {
        apiKey: API_KEYS_MAPPING.USER_ACTIVE_SESSIONS,
        headers: getHeaders(),
      });
    },

    // payload: { client_id: cookies client value to delete }
    async removeSession(payload) {
      return $axios.put(`${await getBaseUrl()}/users/remove-session`, payload, {
        headers: getHeaders(),
      });
    },

    async revokeAllLoggedInDevices() {
      return $axios.put(
        `${await getBaseUrl()}/users/remove-all-sessions`,
        {},
        {
          headers: getHeaders(),
        }
      );
    },

    async generateMfa() {
      return $axios.put(
        `${await getBaseUrl()}/users/generate-mfa`,
        {},
        {
          headers: getHeaders(),
        }
      );
    },
    // payload: { mfa_mode: 'mobile' } / { mfa_mode: 'email' }
    // payload: { mfa_mode: "gauth", otp: "289746" }
    async activateMfa(payload) {
      return $axios.put(`${await getBaseUrl()}/users/activate-mfa`, payload, {
        headers: getHeaders(),
      });
    },

    async resendOtp(id, payload) {
      return $axios.put(
        `${await getBaseUrl()}/users/${id}/resend-otp`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async update(payload, id) {
      return $axios.put(`${await getBaseUrl()}/users/${id}`, payload, {
        headers: getHeaders(),
      });
    },
    async updateAvatar(payload, id) {
      return $axios.put(
        `${await getBaseUrl()}/users/${id}/update-avatar`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async updateAddressDetails(payload) {
      return $axios.put(`${await getBaseUrl()}/users/update-address`, payload, {
        headers: getHeaders(),
      });
    },
    async updateIdentityDetails(payload) {
      return $axios.put(
        `${await getBaseUrl()}/users/update-identity`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },

    async updateKycConsent(consent) {
      return $axios.put(
        `${await getBaseUrl()}/users/update_kyc_consent`,
        { consent },
        {
          headers: getHeaders(),
        }
      );
    },

    async updateCKycStatus() {
      return $axios.put(
        `${await getBaseUrl()}/users/update_ckyc_status`,
        {},
        {
          headers: getHeaders(),
        }
      );
    },

    async updateUserRoles({ payload }) {
      return $axios.post(
        `${await getBaseUrl()}/user_roles/upsert-user-roles`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },

    async blockUserRequirementsCount(params) {
      const { id } = params;
      return $axios.get(
        `${await getBaseUrl()}/users/${id}/block_requirements`,
        {
          apiKey: API_KEYS_MAPPING.BLOCK_USER_REQUIREMENTS_COUNT,
          headers: getHeaders(),
        }
      );
    },

    async departmentRequirementsCount(params) {
      const { id } = params;
      return $axios.get(
        `${await getBaseUrl()}/users/${id}/department_change_requirements`,
        {
          headers: getHeaders(),
        }
      );
    },

    async blockUserPolicyRequirements(params) {
      const { id } = params;
      return $axios.get(
        `${await getBaseUrl()}/users/${id}/block_policy_requirements`,
        {
          apiKey: API_KEYS_MAPPING.BLOCK_USER_POLICY_REQUIREMENTS,
          headers: getHeaders(),
        }
      );
    },

    async myPhysicalCard(id, params) {
      return $axios.get(`${await getBaseUrl()}/users/${id}/my_physical_card`, {
        params,
        headers: getHeaders(),
      });
    },
    async getConnectedEntities() {
      return $axios.get(`${await getBaseUrl()}/users/get_connected_accounts`, {
        apiKey: API_KEYS_MAPPING.GET_CONNECT_ENTITIES,
        headers: getHeaders(),
      });
    },
    async connectEntity(payload) {
      return $axios.post(
        `${await getBaseUrl()}/users/connect_entity`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async switchEntity(payload) {
      return $axios.post(`${await getBaseUrl()}/users/switch_entity`, payload, {
        headers: getHeaders(),
      });
    },
    async validateUserSwitch(payload) {
      return $axios.post(
        `${await getBaseUrl()}/users/validate_user_switch`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
  },
  Ledger: {
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/accounting/ledger`, {
        params,
        headers: getHeaders(),
      });
    },
    async getLedgerMetrics(params) {
      return $axios.get(`${await getBaseUrl()}/accounting/ledger-details`, {
        params,
        headers: getHeaders(),
      });
    },

    async exportLedger(params) {
      return $axios.get(`${await getBaseUrl()}/accounting/ledger-export`, {
        params,
        headers: getHeaders(),
      });
    },

    async get(params) {
      const { id } = params;
      const selectedLedger = MOCK_DATA["api/v1/ledger"].find(
        (val) => val.id === id
      );
      return selectedLedger;
    },
  },

  PartnerAnalyticsAccounting: {
    async all({ type, ...rest }) {
      return $axios.get(
        `${await getBaseUrl()}/partner_analytics_accounting/${type}`,
        {
          params: rest,
          headers: getHeaders(),
        }
      );
    },

    async entities(params) {
      return $axios.get(
        `${await getBaseUrl()}/partner_analytics_accounting/clients`,
        {
          params,
          headers: getHeaders(),
        }
      );
    },

    async bulkExport(params) {
      return $axios.post(
        `${await getBaseUrl()}/partner_analytics_accounting/bulk_export`,
        params,
        {
          headers: getHeaders(),
        }
      );
    },
    async analyticsTags(params) {
      return $axios.get(
        `${await getBaseUrl()}/partner_analytics_accounting/tags`,
        { params, headers: getHeaders() }
      );
    },
    async analyticsLedgerCurrencies(params) {
      return $axios.get(
        `${await getBaseUrl()}/partner_analytics_accounting/ledger_currencies`,
        {
          params,
          headers: getHeaders(),
        }
      );
    },

    async requestStatement(payload) {
      return $axios.post(
        `${await getBaseUrl()}/partner_analytics_accounting/request_statement`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
  },
  Accounting: {
    async all(params) {
      return $axios.get(
        `${await getBaseUrl()}/accounting/provider-config-details`,
        {
          params,
          headers: getHeaders(),
        }
      );
    },
    async connect(params) {
      return $axios.get(`${await getBaseUrl()}/accounting/authorize-url`, {
        params,
        headers: getHeaders(),
      });
    },
    async disconnect(params) {
      return $axios.put(
        `${await getBaseUrl()}/accounting/remove-connection`,
        params,
        { headers: getHeaders() }
      );
    },
    async deleteTag(params) {
      return $axios.delete(`${await getBaseUrl()}/tags/${params}`, {
        headers: getHeaders(),
      });
    },
    async editAccountingPayee(params) {
      const { id } = params;
      return $axios.put(
        `${await getBaseUrl()}/accounting/accounting_payees/${id}`,
        params,
        { headers: getHeaders() }
      );
    },
    async getBankAccounts(params) {
      return $axios.get(`${await getBaseUrl()}/accounting/bank-accounts`, {
        params,
        headers: getHeaders(),
      });
    },
    async downloadSpendFile(params) {
      return $axios.get(`${await getBaseUrl()}/accounting/files/${params.id}`, {
        params,
        headers: getHeaders(),
        responseType: "blob",
      });
    },
    async getAccountingIntegrationSettings() {
      return $axios.get(`${await getBaseUrl()}/accounting/get-settings`, {
        headers: getHeaders(),
      });
    },
    async syncAccountingChartOfAccounts() {
      return $axios.put(
        `${await getBaseUrl()}/accounting/chart-of-accounts`,
        {},
        { headers: getHeaders() }
      );
    },
    async syncAccountingVendors() {
      return $axios.put(
        `${await getBaseUrl()}/accounting/vendors`,
        {},
        { headers: getHeaders() }
      );
    },
    async accountingVendors(params) {
      return $axios.get(`${await getBaseUrl()}/accounting/accounting-vendors`, {
        params,
        headers: getHeaders(),
      });
    },
    async syncAccountingTrackingCategories() {
      return $axios.put(
        `${await getBaseUrl()}/accounting/tracking-categories`,
        {},
        { headers: getHeaders() }
      );
    },
    async syncAccountingTaxes() {
      return $axios.put(
        `${await getBaseUrl()}/accounting/taxes`,
        {},
        { headers: getHeaders() }
      );
    },
    async setSettings(params) {
      return $axios.put(
        `${await getBaseUrl()}/accounting/set-settings`,
        params,
        { headers: getHeaders() }
      );
    },
    async getNetsuiteConfigurations(params) {
      return $axios.get(
        `${await getBaseUrl()}/accounting/netsuite/get-configurations`,
        {
          params,
          headers: getHeaders(),
        }
      );
    },
    async addNetsuiteConnection(params) {
      return $axios.put(
        `${await getBaseUrl()}/accounting/netsuite/add-connection`,
        params,
        { headers: getHeaders() }
      );
    },
    async setNetsuiteConfiguration(params) {
      return $axios.put(
        `${await getBaseUrl()}/accounting/netsuite/set-configurations`,
        params,
        { headers: getHeaders() }
      );
    },
    async getUCSVSupportedFormatOptions() {
      return $axios.get(
        `${await getBaseUrl()}/accounting/universal_csv/supported_export_formats`,
        {
          headers: getHeaders(),
          apiKey: API_KEYS_MAPPING.ACCOUNTING_GET_UCSV_SUPPORTED_FORMAT_OPTIONS,
        }
      );
    },
    async setExportFormat(params) {
      return $axios.post(
        `${await getBaseUrl()}/accounting/universal_csv/set_export_format`,
        params,
        { headers: getHeaders() }
      );
    },
    async importUCSVFiles(formData) {
      return $axios.post(
        `${await getBaseUrl()}/accounting/universal_csv/import`,
        formData,
        { headers: getHeaders() }
      );
    },
    async addUCSVConnection() {
      return $axios.post(
        `${await getBaseUrl()}/accounting/universal_csv/activate_provider`,
        {},
        { headers: getHeaders() }
      );
    },
    async getCompanyFiles(params) {
      const { slug } = params;
      return $axios.get(
        `${await getBaseUrl()}/accounting/company-settings/${slug}/company-files`,
        {
          params,
          headers: getHeaders(),
          apiKey: API_KEYS_MAPPING.ACCOUNTING_GET_COMPANY_FILES,
        }
      );
    },
    async setCompany(params) {
      const { company_file_id, slug } = params;
      return $axios.post(
        `${await getBaseUrl()}/accounting/company-settings/${slug}/select-company-file`,
        { company_file_id },
        { headers: getHeaders() }
      );
    },
    async getTallyAccessToken() {
      return $axios.post(
        `${await getBaseUrl()}/oauth_applications/tally_access_token`,
        {},
        { headers: getHeaders() }
      );
    },
    async getPendingTabCount(params = {}) {
      return $axios.get(`${await getBaseUrl()}/accounting/pending-counts`, {
        params,
        headers: getHeaders(),
      });
    },
    async getSyncAttempts(params) {
      return $axios.get(`${await getBaseUrl()}/accounting/attempts/list`, {
        params,
        headers: getHeaders(),
      });
    },
    async downloadSyncHistory(payload) {
      return $axios.put(
        `${await getBaseUrl()}/accounting/attempts/download`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
  },
  Categories: {
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/categories`, {
        apiKey: API_KEYS_MAPPING.CATEGORIES_ALL,
        params,
        headers: getHeaders(),
      });
    },
    async get(params) {
      const categories = MOCK_DATA["api/v1/categories"];
      const selectedCategory = categories.find((val) => val.id === params.id);
      return selectedCategory;
    },
  },

  Filters: {
    async all(params) {
      const filtersList = params.filters;
      if (filtersList) {
        const completeFilter = filtersList.map((filter, index) => {
          let completeData = [];
          if (filter === "vendors")
            completeData = MOCK_DATA["api/v1/vendors"].map((value) => {
              return {
                id: `ven${value.id}`,
                title: value.name,
                value: value.name.toLowerCase(),
              };
            });
          else if (filter === "departments")
            completeData = MOCK_DATA["api/v1/company/departments"].map(
              (value) => {
                return {
                  id: `dep${value.id}`,
                  title: value.name,
                  value: value.name.toLowerCase(),
                };
              }
            );
          return {
            title: filter,
            children: completeData,
          };
        });

        return completeFilter;
      }
    },
    async fetchSearchFiltersCategories(params) {
      return $axios.get(`${await getBaseUrl()}/search_filters`, {
        params,
        headers: getHeaders(),
      });
    },

    async fetchFilterOptions({ url, params }) {
      return $axios.get(`${await getBaseUrl()}/${url}`, {
        params,
        headers: getHeaders(),
      });
    },
  },

  AccountingTransactions: {
    async all(payload) {
      const { type, ...params } = payload;
      return $axios.get(`${await getBaseUrl()}/accounting/${type}`, {
        params,
        headers: getHeaders(),
      });
    },
    async get(params) {
      return $axios.get(`${await getBaseUrl()}/accounting/${params}`, {
        apiKey: API_KEYS_MAPPING.ACCOUNTING_TRANSACTIONS_GET,
        headers: getHeaders(),
      });
    },
    async availableToSync(params) {
      return $axios.get(`${await getBaseUrl()}/accounting/syncable-stats`, {
        params,
        headers: getHeaders(),
      });
    },
    async failedToSync(params) {
      return $axios.get(`${await getBaseUrl()}/accounting/sync-failed-stats`, {
        params,
        headers: getHeaders(),
      });
    },
    async failedToSyncTransactions(params) {
      return $axios.get(`${await getBaseUrl()}/accounting/sync-failed-stats`, {
        params,
        headers: getHeaders(),
      });
    },
    async pendingToVerifyTransactions(params) {
      const data =
        MOCK_DATA["api/v3/accounting-transactions/pending-to-verify"];
      return data;
    },
    async verifyTransaction(payload) {
      return $axios.put(`${await getBaseUrl()}/accounting/verify`, payload, {
        headers: getHeaders(),
      });
    },
    async syncTransaction(payload) {
      return $axios.put(`${await getBaseUrl()}/accounting/sync`, payload, {
        headers: getHeaders(),
      });
    },
    async bulkOperation(payload) {
      return $axios.put(
        `${await getBaseUrl()}/accounting/accounting-bulk-operation`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async tagUpdate(payload) {
      return $axios.put(
        `${await getBaseUrl()}/accounting/update-accounting-tags`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async updateVendor(payload) {
      const { type, id, ...rest } = payload;
      return $axios.put(`${await getBaseUrl()}/${type}/${id}`, rest, {
        headers: getHeaders(),
      });
    },
    // Doesn't exist
    async unverifyTransaction(payload) {
      return $axios.put(`${await getBaseUrl()}/accounting/unverify`, payload, {
        headers: getHeaders(),
      });
    },
    // Doesn't exist
    async markAsSyncedTransaction(payload) {
      return $axios.put(
        `${await getBaseUrl()}/accounting/mark-as-synced`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async getAccountingTransaction(params) {
      const { id } = params;
      return $axios.get(`${await getBaseUrl()}/accounting_transactions/${id}`, {
        headers: getHeaders(),
      });
    },
    async updateAccountingTransaction(params) {
      const { id } = params;
      return $axios.put(
        `${await getBaseUrl()}/accounting_transactions/${id}`,
        params,
        {
          headers: getHeaders(),
        }
      );
    },

    async updateAccounting(accounting_id, payload) {
      return $axios.put(
        `${await getBaseUrl()}/accounting/${accounting_id}`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async transactionTimeline(params) {
      return $axios.get(`${await getBaseUrl()}/accounting/syncable-stats`, {
        params,
        headers: getHeaders(),
      });
    },
  },
  Rules: {
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/rules`, {
        apiKey: API_KEYS_MAPPING.RULES_ALL,
        params,
        headers: getHeaders(),
      });
    },
    async get(id) {
      return $axios.get(`${await getBaseUrl()}/rules/${id}`, {
        headers: getHeaders(),
      });
    },
    async mappings(id) {
      return $axios.get(`${await getBaseUrl()}/rules/${id}/mappings`, {
        headers: getHeaders(),
      });
    },
    async create(payload) {
      return $axios.post(`${await getBaseUrl()}/rules`, payload, {
        headers: getHeaders(),
      });
    },
    async delete(params) {
      return $axios.put(`${await getBaseUrl()}/rules`, params, {
        headers: getHeaders(),
      });
    },
    async edit(payload) {
      const { id, ...rest } = payload;
      return $axios.put(`${await getBaseUrl()}/rules/${id}`, rest, {
        headers: getHeaders(),
      });
    },
    async ruleItemTypes(params) {
      return $axios.get(`${await getBaseUrl()}/rules/rule_items`, {
        params,
        headers: getHeaders(),
      });
    },
    async fetchCardRulesByRuleId(id) {
      return $axios.get(`${await getBaseUrl()}/rules/${id}`, {
        headers: getHeaders(),
      });
    },
    async ruleCheck(params) {
      return $axios.put(`${await getBaseUrl()}/rules/rule_check`, params, {
        headers: getHeaders(),
      });
    },
    async fetchRulesCount(params) {
      return $axios.get(`${await getBaseUrl()}/rules/count`, {
        headers: getHeaders(),
      });
    },
    async getTransactionsCount(params) {
      return $axios.put(
        `${await getBaseUrl()}/rules/transaction_count`,
        params,
        {
          headers: getHeaders(),
        }
      );
    },
  },
  SyncAttempts: {
    async get(id) {
      const syncAttempts = MOCK_DATA["api/v1/sync-attempts/<id>"];

      return syncAttempts.find(
        (syncAttemptDetail) => syncAttemptDetail.id === id
      );
      // return $axios.get(`${await getBaseUrl()}/sync-attempts/${id}`, {
      //   headers: getHeaders(),
      // });
    },
    async getLastSyncAttempt(params) {
      const syncAttempts = MOCK_DATA["api/v1/sync-attempts"];

      let filteredSyncAttempts = syncAttempts;
      if (params.type)
        filteredSyncAttempts = filteredSyncAttempts.filter(
          ({ type }) => type === params.type
        );

      const lastAttempt =
        filteredSyncAttempts[filteredSyncAttempts.length - 1] ?? null;
      const lastSyncedDateTime = lastAttempt?.attemptDate ?? new Date();

      return {
        lastAttempt,
        lastSyncedDateTime,
        type: params.type,
      };
    },
  },
  Wallets: {
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/accounts/wallets`, {
        params,
        headers: getHeaders(),
      });
    },
  },

  Vendors: {
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/vendors`, {
        params,
        headers: getHeaders(),
      });
    },
    async get(id, params) {
      return $axios.get(`${await getBaseUrl()}/vendors/${id}`, {
        apiKey: API_KEYS_MAPPING.VENDORS_GET,
        params,
        headers: getHeaders(),
      });
    },
    async update({ id, payload, params = {} }) {
      return $axios.put(`${await getBaseUrl()}/vendors/${id}`, payload, {
        params,
        headers: getHeaders(),
      });
    },
    async verify({ id, payload, params = {} }) {
      return $axios.post(
        `${await getBaseUrl()}/vendors/${id}/verify`,
        payload,
        {
          params,
          headers: getHeaders(),
        }
      );
    },
    async getRequirements(params) {
      return $axios.get(`${await getBaseUrl()}/vendors/requirements`, {
        params,
        headers: getHeaders(),
      });
    },

    async getRequirementsForMailFlow(params) {
      return $axios.get(
        `${await getBaseUrl()}/vendor_mail_onboarding/requirements`,
        {
          params,
          headers: getHeaders(),
        }
      );
    },

    async getOnBoardingDetails(params) {
      return $axios.get(
        `${await getBaseUrl()}/vendor_mail_onboarding/details`,
        { params, headers: getHeaders() }
      );
    },

    async triggerOnBoardingMail(id, payload) {
      return $axios.post(
        `${await getBaseUrl()}/vendors/${id}/trigger_mail_onboarding`,
        payload,
        { headers: getHeaders() }
      );
    },

    async getCountriesForMail(params) {
      return $axios.get(`${await getBaseMockUrl()}/countries`, {
        apiKey: API_KEYS_MAPPING.VENDORS_GET_COUNTRIES_FOR_MAIL,
        params,
        headers: getMockApiHeaders(),
      });
    },
    async getCountryCurrenciesForMail(params) {
      return $axios.get(
        `${await getBaseMockUrl()}/country_currency_mappings/${params}`,
        {
          params,
          headers: getMockApiHeaders(),
        }
      );
    },

    async create({ payload, params = {} }) {
      return $axios.post(`${await getBaseUrl()}/vendors`, payload, {
        params,
        headers: getHeaders(),
      });
    },

    async createViaMail({ payload, params = {} }) {
      return $axios.post(
        `${await getBaseUrl()}/vendor_mail_onboarding/bank_details`,
        payload,
        {
          params,
          headers: getHeaders(),
        }
      );
    },

    async archive(payload) {
      return $axios.put(`${await getBaseUrl()}/vendors/archive`, payload, {
        headers: getHeaders(),
      });
    },
    async getCountryCurrencyMappings() {
      return $axios.get(
        `${await getBaseUrl()}/vendors/country_currency_mappings`,
        { headers: getHeaders() }
      );
    },
    async getPaymentMethods(params) {
      return $axios.get(`${await getBaseUrl()}/vendors/payout_method_types`, {
        params,
        headers: getHeaders(),
      });
    },

    async getPaymentMethodsViaMail(params) {
      return $axios.get(
        `${await getBaseUrl()}/vendor_mail_onboarding/payout_method_types`,
        { params, headers: getHeaders() }
      );
    },

    async getPaymentMode(vendor_id) {
      return $axios.get(
        `${await getBaseUrl()}/vendors/${vendor_id}/payment_methods`,
        {
          apiKey: API_KEYS_MAPPING.VENDORS_GET_PAYMENT_MODE,
          headers: getHeaders(),
        }
      );
    },
    async getBankAndSwiftCodes() {
      // ad hoc API, used to complete requirements in create vendor
      return $axios.get(`${await getBaseUrl()}/vendors/bank_swift_codes`, {
        headers: getHeaders(),
      });
    },
    async getBankAndSwiftCodesForMail(params) {
      // ad hoc API, used to complete requirements in create vendor
      return $axios.get(`${await getBaseMockUrl()}/bank_swift_codes`, {
        params,
        headers: getMockApiHeaders(),
      });
    },
    async getCityAndRegionMapping(params) {
      // Same function used for mail and normal flow
      // ad hoc API, used to complete requirements in create vendor
      const { country } = params;
      return $axios.get(
        `${await getBaseMockUrl()}/wallex_region_mapping/${country}`,
        {
          headers: getMockApiHeaders(),
        }
      );
    },
    async getTaxDetails(params) {
      return $axios.get(`${await getBaseUrl()}/vendors/tax_details`, {
        params,
        headers: getHeaders(),
      });
    },
  },
  Misc: {
    async deleteAttachment(params) {
      const { id } = params;
      return $axios.delete(`${await getBaseUrl()}/attachments/${id}`, {
        headers: getHeaders(),
      });
    },
    async getAttachementUrl(id) {
      return $axios.get(`${await getBaseUrl()}/attachments/${id}/get_url`, {
        headers: getHeaders(),
      });
    },
    async getAttachementsUrls(params) {
      return $axios.get(`${await getBaseUrl()}/attachments/urls`, {
        params,
        headers: getHeaders(),
      });
    },
    async approve(payload) {
      return $axios.put(
        `${await getBaseUrl()}/approval_requests/approve`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async reject(payload) {
      return $axios.put(
        `${await getBaseUrl()}/approval_requests/reject`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async getComments(params) {
      return $axios.get(`${await getBaseUrl()}/comments`, {
        apiKey: API_KEYS_MAPPING.MISC_GET_COMMENTS,
        params,
        headers: getHeaders(),
      });
    },
    async createComments(payload) {
      return $axios.post(`${await getBaseUrl()}/comments`, payload, {
        headers: getHeaders(),
      });
    },
    async getCurrencies(params) {
      return $axios.get(
        `${await getBaseUrl()}/currencies/supported_currencies`,
        {
          params,
          headers: getHeaders(),
        }
      );
    },
  },
  Onboarding: {
    async onboardingStep(params) {
      return $axios.get(`${await getBaseUrl()}/users/onboarding/steps`, {
        params,
        headers: getHeaders(),
      });
    },
    async onboardingSection(params) {
      return $axios.get(`${await getBaseUrl()}/users/onboarding/section`, {
        params,
        headers: getHeaders(),
      });
    },
    async vkycVerfied() {
      return $axios.put(
        `${await getBaseUrl()}/users/vkyc_verified`,
        {},
        {
          headers: getHeaders(),
        }
      );
    },

    async reviewDetails() {
      return $axios.put(
        `${await getBaseUrl()}/users/update_review`,
        {},
        {
          headers: getHeaders(),
        }
      );
    },
    async sendOtp(id) {
      return $axios.get(`${await getBaseUrl()}/users/${id}/mobile-otp`, {
        headers: getHeaders(),
      });
    },
    async submitKyc(params) {
      const { id, payload } = params;
      return $axios.put(
        `${await getBaseUrl()}/users/${id}/submit-kyc`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async vkycLink(id) {
      return $axios.put(`${await getBaseUrl()}/users/${id}/vkyc-link`, {
        headers: getHeaders(),
      });
    },
  },
  QRPayments: {
    async get(qrPaymentId) {
      // return $axios.get(`${await getBaseUrl()}/qr-payment/${qrPaymentId}`, {
      //   headers: getHeaders(),
      // });
      const qrPaymentDetail = MOCK_DATA["api/v3/qr-payments/<id>"];
      return qrPaymentDetail;
    },
    async fetchQrPaymentDetails(params) {
      // return $axios.get(`${await getBaseUrl()}/qr-payment/details`, {
      //   params,
      //   headers: getHeaders(),
      // });
      const qrPaymentOverviewDetails = MOCK_DATA["api/v3/qr-payments/details"];
      return qrPaymentOverviewDetails;
    },
    async fetchStatistics(params) {
      // return $axios.get(`${await getBaseUrl()}/qr_payments/statistics`, {
      //   params,
      //   headers: getHeaders(),
      // });
      const qrPaymentStatisticsDetails =
        MOCK_DATA["api/v3/qr-payments/statistics"];
      return qrPaymentStatisticsDetails;
    },
    async all(params) {
      // return $axios.get(`${await getBaseUrl()}/qr_payments`, {
      //   params,
      //   headers: getHeaders(),
      // });
      const qrPaymentsList = MOCK_DATA["api/v3/qr-payments"];
      return qrPaymentsList;
    },
    async qrPaymentsBulkApprove(payload) {
      // return $axios.put(
      //   `${await getBaseUrl()}/qr-payments/bulk-approve`,
      //   payload,
      //   {
      //     headers: getHeaders(),
      //   }
      // );
      return { success: payload?.qrPayment_ids?.length, failure: 0 };
    },
    async update({ qrPaymentId, payload }) {
      return $axios.put(
        `${await getBaseUrl()}/qr-payments/${qrPaymentId}`,
        payload,
        {
          headers: getHeaders(),
        }
      );
    },
    async failureDetails(params) {
      // return $axios.get(`${await getBaseUrl()}/qr-payments/failure-details`, {
      //   headers: getHeaders(),
      // });
      const failureDetails = MOCK_DATA["api/v3/qr-payments/failure-details"];
      return failureDetails;
    },
    async getSplitQrPayment(qrPaymentId) {
      // return $axios.get(
      //   `${await getBaseUrl()}/qr-payments/${qrPaymentId}/split_qrPayments`,
      //   {
      //     headers: getHeaders(),
      //   }
      // );
      const split_qr_payments = [];
      return split_qr_payments;
    },
    async remind(payload) {
      // return $axios.put(`${await getBaseUrl()}/qr_payments/remind`, payload, {
      //   headers: getHeaders(),
      // });
      const remind = { message: "Successfully reminded users" };
      return remind;
    },
    async repaymentReceived(id) {
      return {};
    },
    async uploadRecipts({ qrPaymentId, payload }) {
      // return $axios.put(
      //   `${await getBaseUrl()}/qr-payments/${qrPaymentId}/upload-receipt`,
      //   payload,
      //   {
      //     headers: getHeaders(),
      //   }
      // );
    },
    async addSplitQrPayment({ qrPaymentId, payload }) {
      // return $axios.post(
      //   `${await getBaseUrl()}/qr-payments/${qrPaymentId}/split_qr_payment`,
      //   payload,
      //   {
      //     headers: getHeaders(),
      //   }
      // );
    },
    async flagQrPayment({ qrPaymentId, payload }) {
      // return $axios.put(
      //   `${await getBaseUrl()}/qr-payments/${qrPaymentId}/flag`,
      //   payload,
      //   {
      //     headers: getHeaders(),
      //   }
      // );
    },
    UserWallets: {
      async all(params) {
        // return $axios.get(`${await getBaseUrl()}/user-wallet`, {
        //   params,
        //   headers: getHeaders(),
        // });
        const userWalletDetails = MOCK_DATA["api/v3/qr-payments/user-wallet"];
        return userWalletDetails;
      },
      async get(id) {
        // return $axios.get(`${await getBaseUrl()}/user-wallet/${id}`, {
        //   headers: getHeaders(),
        // });
        const selectedUserWalletDetail = MOCK_DATA["api/v3/user-wallet/<id>"];
        return selectedUserWalletDetail;
      },
      async freeze(id) {
        return $axios.put(
          `${await getBaseUrl()}/qr-payments/user-wallet/${id}/freeze`,
          {},
          { headers: getHeaders() }
        );
      },

      async unfreeze(id) {
        return $axios.put(
          `${await getBaseUrl()}/qr-payments/user-wallet/${id}/unfreeze`,
          {},
          { headers: getHeaders() }
        );
      },
    },
  },
  MyVolopay: {
    async all() {
      return $axios.get(`${await getBaseUrl()}/my-volopay/getting-started`, {
        headers: getHeaders(),
      });
    },
  },
  OCR: {
    async resultsSync(payload, extraHeaders) {
      return $axios.post(`${ocrServiceApiUrl()}/ocr/upload`, payload, {
        headers: {
          ...getHeaders(),
          ...extraHeaders,
        },
      });
    },
  },
  Security: {
    async getDevicesYouTrust() {
      return $axios.get(`${await getBaseUrl()}/trusted_devices`, {
        apiKey: API_KEYS_MAPPING.SECURITY_GET_DEVICES_YOU_TRUST,
        headers: getHeaders(),
      });
    },
    async revokeAllTrustedDevices() {
      return $axios.post(
        `${await getBaseUrl()}/trusted_devices/revoke_all`,
        {},
        {
          headers: getHeaders(),
        }
      );
    },
    async removeTrustedDevice(id) {
      return $axios.delete(`${await getBaseUrl()}/trusted_devices/${id}`, {
        headers: getHeaders(),
      });
    },
  },
  MasterHistory: {
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/master_history`, {
        params,
        headers: getHeaders(),
      });
    },
  },
  Timeline: {
    async get(params) {
      const { id, ...rest } = params;
      return $axios.get(`${await getBaseUrl()}/approval_timeline/${id}`, {
        params: rest,
        headers: getHeaders(),
      });
    },
  },
  Notifications: {
    async all(params) {
      return $axios.get(`${await getBaseUrl()}/notifications`, {
        params,
        headers: getHeaders(),
      });
    },
    async readAll(payload) {
      return $axios.post(
        `${await getBaseUrl()}/notifications/read_all`,
        payload,
        { headers: getHeaders() }
      );
    },
    async fetchUnreadNotifications() {
      return $axios.get(
        `${await getBaseUrl()}/notifications/unread-notifications`,
        { headers: getHeaders() }
      );
    },
  },

  QuickGuide: {
    async fetchQuickGuideList(apiEndPoint) {
      return $axios.get(
        `${await getPublicAssetsUrl()}app/help-${apiEndPoint}.json`,
        { apiKey: API_KEYS_MAPPING.FETCH_QUICK_GUIDE_LIST }
      );
    },
  },
};

const mappedAPI = {}; // TECH_DEBT: avoid using global mutable objects, difficult to track

/**
 * Recursively maps the structure of an API and extracts endpoints.
 * @param {object} api - The API structure to map.
 * @param {string} parentKey - The parent key of the current API structure (optional).
 */
function mapApiStructure(api, parentKey = "") {
  // TECH_DEBT: avoid writing outside objects, difficult to track
  // Iterate through each key in the API structure
  for (let key in api) {
    // Check if the value associated with the key is an object (nested structure)
    if (typeof api[key] === "object") {
      // Recursively call mapApiStructure for nested structures, updating the parentKey
      mapApiStructure(api[key], parentKey ? `${parentKey}:${key}` : key);
    }
    // Check if the value associated with the key is a function
    else if (typeof api[key] === "function") {
      // Convert the function to a string
      const funcString = api[key].toString();
      // Match the URL pattern using a regular expression for functions with `.get()` method calls
      const match = funcString.match(/\.get\(['"`]([^'"`]+)['"`]/);
      // If a match is found
      if (match) {
        // Extract the URL from the match
        let url = match[1];
        // Replace dynamic parts of the URL with placeholders
        const staticUrl = url
          .replace(
            /(\${(?:await )?getBaseUrl\(\)})|(\${(?:await )?globalApiUrl\(\)})|(\${(?:await )?getBaseMockUrl\(\)})|(\${(?:await )?getBaseMockUrl\(\)})|(\${(?:await )?getBaseUrlV1\(\)})/g,
            ""
          )
          .replace(/\${([a-zA-Z]+Id|[a-z]+_id|.*\.id)}/g, "${id}");
        // Format the endpoint by converting camelCase to kebab-case and removing leading '/'
        const endpoint = staticUrl
          .replace(/([A-Z])/g, (match) => `/${match.toLowerCase()}`)
          .replace(/^\/+/, "");
        // Map the endpoint to the corresponding function key in the mappedAPI object
        mappedAPI[endpoint] = parentKey ? `${parentKey}:${key}` : key;
      }
    }
  }
}

mapApiStructure(API);

export const mappedAPIObject = mappedAPI;
/**
 * Retrieves the endpoint for a given parameter from a mapped API.
 * @param {string} param - The parameter for which to retrieve the endpoint.
 * @returns {string} The endpoint corresponding to the parameter, or an empty string if not found.
 */
export function getEndpointForParam(_param) {
  // TECH_DEBT: avoid writing outside objects, difficult to track
  const param = _param?.charAt(0) === "/" ? _param?.substring(1) : _param;
  // Retrieve the mapped API
  const endpoints = mappedAPI;

  // Check for exact matches first
  if (endpoints[param]) {
    return endpoints[param];
  }

  // If no exact match, check for cases with placeholders
  for (const endpoint in endpoints) {
    // Split the endpoint by '/'
    const endpointParts = endpoint.split("/");
    // Split the parameter by '/'
    const paramParts = param.split("/");
    if (paramParts.length !== endpointParts.length) {
      continue;
    }
    // Flag to track if all parts match
    let allPartsMatch = true;
    // Loop through the parts of paramParts and check if they match the corresponding parts of endpointParts
    for (let i = 0; i < paramParts.length; i++) {
      if (
        paramParts[i] !== endpointParts[i] &&
        !endpointParts[i].startsWith("${id")
      ) {
        allPartsMatch = false;
        break;
      }
    }

    // If all parts match, return the corresponding value from endpoints
    if (allPartsMatch) {
      return endpoints[endpoint];
    }
  }
  // Return an empty string if no matching endpoint is found
  return "";
}

export default API;
