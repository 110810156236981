import { createSelector } from "@reduxjs/toolkit";

import { AVAILABLE_FILTERS } from "@/utils/constants/filters";

export const selectMasterHistoryStore = (store) => store.masterHistory;

export const masterHistoryLogsSelector = createSelector(
  selectMasterHistoryStore,
  (masterHistory) => masterHistory?.masterHistoryLogs
);

export const filtersKeysSelector = createSelector(
  selectMasterHistoryStore,
  (masterHistory) => masterHistory.filtersKeys
);

export const masterHistoryLogsListSelector = createSelector(
  masterHistoryLogsSelector,
  (masterHistory) => masterHistory?.list
);

export const isFetchingMasterHistoryLogsSelector = createSelector(
  masterHistoryLogsSelector,
  (masterHistory) => masterHistory?.isFetching
);

export const masterHistoryLogsPageSelector = createSelector(
  masterHistoryLogsSelector,
  (masterHistory) => masterHistory?.page
);
export const masterHistoryLogsHasMoreSelector = createSelector(
  masterHistoryLogsSelector,
  (masterHistory) => masterHistory?.hasMore
);
export const masterHistoryLogsTotalSelector = createSelector(
  masterHistoryLogsSelector,
  (masterHistory) => masterHistory?.total
);

export const masterHistoryLogsFiltersSelector = createSelector(
  filtersKeysSelector,
  (filtersKeys) =>
    filtersKeys?.masterHistoryLogs?.map((key) => AVAILABLE_FILTERS[key])
);

export const childLogsSelector = createSelector(
  selectMasterHistoryStore,
  (masterHistory) => masterHistory?.childLogs
);

export const childLogsListSelector = createSelector(
  childLogsSelector,
  (childLogs) => childLogs?.list
);

export const isFetchingChildLogsSelector = createSelector(
  childLogsSelector,
  (childLogs) => childLogs?.isFetching
);

export const childLogsPageSelector = createSelector(
  childLogsSelector,
  (childLogs) => childLogs?.page
);
export const childLogsHasMoreSelector = createSelector(
  childLogsSelector,
  (childLogs) => childLogs?.hasMore
);
export const childLogsTotalSelector = createSelector(
  childLogsSelector,
  (childLogs) => childLogs?.total
);

export const moduleHistorySelector = createSelector(
  selectMasterHistoryStore,
  (app) => app.moduleHistory
);
export const isFetchingModuleHistorySelector = createSelector(
  moduleHistorySelector,
  (moduleHistory) => moduleHistory.isFetching
);

export const moduleHistoryListSelector = createSelector(
  moduleHistorySelector,
  (moduleHistory) => moduleHistory.list
);

export const moduleHistoryTotalSelector = createSelector(
  moduleHistorySelector,
  (moduleHistory) => moduleHistory.total
);

export const moduleHistoryPageSelector = createSelector(
  moduleHistorySelector,
  (moduleHistory) => moduleHistory.page
);

export const moduleHistoryHasMoreSelector = createSelector(
  moduleHistorySelector,
  (moduleHistory) => moduleHistory.hasMore
);
