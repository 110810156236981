/**
 * search Param here should be unique
 */
export const SLIDERS_SEARCH_PARAMS = {
  // TECH_DEBT make ScrollTo component/hook, props.condition | props.condition() for wait for page compute to end, then scroll
  scrollTo: "scrollTo",
  focus: "focus",
  type: "type",
  id: "id",
  spend: "spend",
  masterHistoryIntermediateSlider: "masterHistoryIntermediateSlider",
  payments: {
    id: "payId",
    relatedPayId: "relatedPayId",
    relatedPayContext: "relatedPayContext",
    relatedPayIsPayment: "relatedPayIsPayment",
    createVendorPrompt: "createVendorPrompt",
    payrollSalaryId: "payrollSalaryId",
    sliderType: "sliders",
    pageType: "page",
    urlKeys: ["payId"],

    createBillDetails: "createBillDetails",
    billLinkedTo: "billLinkedTo",

    editBill: "editBill",

    reviewBill: "reviewBill",
    reCreateBill: "reCreateBill",
    reviewPayment: "reviewPayment",
    editBillMode: "editBillMode",

    reject: "paymentsRejectSlider",
    settings: {
      general: {
        apClerks: "apClerks",
        paymentInitiators: "paymentInitiators",
        automatedPayments: "automatedPayments",
      },

      spendControls: {
        approvalPolicy: "approvalPolicy",
        customApprovalPolicy: "customApprovalPolicy",
        submissionPolicy: "submissionPolicy",
        customSubmissionPolicy: "customSubmissionPolicy",
      },
    },
    ocrFetched: "ocrFetched",
    showCreateVendor: "showCreateVendor",
    showOCRModal: "showOCRModal",
  },
  payrollPayments: {
    id: "payrollPayId",
    createEmployeePrompt: "createEmployeePrompt",
    reject: "payrollRejectSlider",
    sliderType: "payrollSliders",
    pageType: "payrollPage",
    urlKeys: ["payrollPayId"],

    permissionType: "permissionType",

    bulkUploadPayroll: "bulkUploadPayroll",
    createSalary: "createSalary",
    billLinkedTo: "salaryLinkedTo",

    reviewSalaryBill: "reviewSalaryBill",
    reCreateSalaryBill: "reCreateSalaryBill",
    reviewSalaryPayment: "reviewSalaryPayment",
    editBill: "editSalaryBill",
    editBillMode: "editSalaryBillMode",
    settings: {
      general: {
        togglePayroll: "togglePayroll",
        payrollAdmins: "payrollAdmins",
        addPayrollManagers: "addPayrollManagers",
        addPayrollMembers: "addPayrollMembers",
        payrollClerks: "payrollClerks",
        paymentInitiators: "payrollPaymentInitiators",
        automatedPayments: "payrollAutomatedPayments",
      },

      spendControls: {
        approvalPolicy: "approvalPolicy",
        submissionPolicy: "submissionPolicy",
      },
    },
  },
  reimbursements: {
    approveId: "approveReimId",
    claimId: "claimId",
    id: "reimId",
    activeClaimId: "activeClaimId",
    historyClaimId: "historyClaimId",
    userId: "userId",
    sliderType: "reimbursementsSliders",
    paymentHistoryId: "paymentHistoryId",
    pageType: "page",
    create: "createReimbursement",
    review: "review",
    confirm: "confirm",
    editClaim: "editClaim",
    editClaimAmount: "editClaimAmount",
    paymentPage: "paymentPage",
    settleViaCardConfirmation: "settleViaCardConfirmation",
    payOutsideVolopay: "payOutsideVolopay",
    reject: "rejectClaim",
    urlKeys: ["reimId"],
    settings: {
      general: {
        reimbursementsAutomatedPayments: "reimbursementsAutomatedPayments",
        paymentInitiators: "reimbursementsPaymentInitiators",
        mileageRate: {
          title: "mileageRate",
          addMileageRate: "addMileageRate",
          editMileageRate: "editMileageRate",
        },
      },

      spendControls: {
        approvalPolicy: "approvalPolicy",
        customApprovalPolicy: "customApprovalPolicy",
        submissionPolicy: "submissionPolicy",
        customSubmissionPolicy: "customSubmissionPolicy",
        companyClaimPolicy: "companyClaimPolicy",
        customClaimPolicy: "customClaimPolicy",
        departmentID: "departmentID",
        projectID: "projectID",
        claimPolicy: "claimPolicy",
      },
    },
  },
  accounting: {
    unSynced: "unsyncedSlider",
    expenseId: "expenseId",
    billPayId: "billPayId",
    reimbursementId: "reimbursementId",
    othersId: "othersId",
    qrpay: {
      id: "accountingQrpayId",
    },
    payroll: {
      id: "accountingPayrollId",
    },
    failedToSync: "failedToSync",
    syncFailedTransaction: "syncFailedTransaction",
    billPay: {
      id: "accountingBillPayId",
      page: "page",
      sync: { id: "syncId", showAll: "syncShowAll", entityType: "entity" },
    },
    reimbursement: {
      id: "accountingReimbursementId",
    },
    cards: {
      id: "accountingCardsId",
      missingDetails: "cardsMissing",
    },
    others: { id: "othersId", type: "transactionType" },
    ledger: {
      others: "ledgerOther",
    },
    integrations: {
      software: "software",
      softwareSettings: "softwareSettings",
      defaultAccounts: "defaultAccounts",
      manageAccountingTags: "manageAccountingTags",
      accountAutomation: "accountAutomation",
      editAccountingFields: "editAccountingFields",
      sync: "sync",
      importDocuments: "importDocuments",
      document: "document",
      createNewField: "createNewField",
      updateField: "updateField",
      setEntity: "setEntity",
    },
    requestStatement: "requestStatement",
    accountId: "accountId",
    settings: {
      customField: "customField",
      bulkUpload: "bulkUpload",
    },
    rules: {
      id: "rules",
      newEditRule: "newEditRule",
      newEditAdvancedRule: "newEditAdvancedRule",
      ruleId: "ruleId",
      volopayField: "volopayField",
      accountingField: "accountingField",
      createNewRuleFor: "createNewRuleFor",
      fieldType: "fieldType",
      fieldId: "fieldId",
    },
  },

  analytics: {
    export: "exportSlider",
    analyticsRequestStatement: "analyticsRequestStatement",
  },
  expenses: {
    id: "expenseId",
    splitExpense: "expenseSplit",
    missingDetails: "missingDetailsExpenses",
    userMissingDetails: "userMissingDetails",
    pastPayments: "pastPayments",
    flagTransaction: "flagTransaction",
    requestRepayment: "requestRepayment",
    cancelRepayment: "cancelRepayment",
    repaymentDeclined: "declineRepayment",
    merchantId: "merchantId",
  },
  vendors: {
    id: "vendorId",
    create: "createVendor",
    edit: "editVendor",
    overrideEdit: "overrideEditVendor",
    delete: "deleteVendor",
    addBankDetails: "vendorAddBankDetails",
    preAddBankDetails: "vendorPreAddBankDetails",
  },
  employees: {
    id: "employeeId",
    create: "createEmployee",
    edit: "editEmployee",
    overrideEdit: "overrideEditEmployee",
    delete: "deleteEmployee",
    addBankDetails: "payrollAddBankDetails",
    preAddBankDetails: "employeePreAddBankDetails",
  },
  cards: {
    id: "cardId",
    settings: "settings",
    editCard: "editCard",
    freeze: "freeze",
    block: "block",
    unfreeze: "unfreeze",
    menuTypeCards: "menuTypeCards",
    changePin: "changePin",
    linkedTo: "linkedTo",
    internationalCardOperationSlider: "internationalCardOperationSlider",
    cardSliderSettings: {
      controlsPage: "controlsCardSettings",
      rulesPage: "cardSettingsRules",
    },
    virtualCards: {
      create: {
        mainPage: "mainVCreate",
        controlsPage: "controlsVCreate",
        rulesPage: "rulesVCreate",
        reviewVCardDetails: "reviewVCardDetails",
      },
    },
    editLimit: "editLimit",
    editLimitReview: "reviewEditLimitDetails",
    physicalCards: {
      create: {
        physicalCard: "orderPCard",
        reviewDetails: "reviewDetails",
      },
    },
    selectedUserCard: "selectedUserCard",
    activation: "cardActivation",
    cardsettings: {
      controlsPage: "controlsGlobalSettings",
      marchantAndCategoryControl: "controlSettings",
      flagSettings: "flagSettings",
      alertsSettings: "alertsSettings",
      editSettingId: "editSettingId",
      reviewSettings: "reviewSettings",
      editReviewSettings: "editReviewSettings",
      merchantListing: "merchantListing",
      cardManagers: "cardManagers",
      expenseManagers: "expenseManagers",
    },
    actionCentre: {
      myPendingRequest: "myPendingRequest",
      cardLimitRequest: "cardLimitRequest",
      cardOrderRequest: {
        physicalCardRequest: "physicalCardRequest",
        virtualCardRequest: "virtualCardRequest",
        TopCardRequest: "topCardRequest",
      },
      cancelOrderRequest: "cancelOrderRequest",
    },
  },
  company: {
    openTabInBudgetLocationAndDepartment: "tabType",
    currency: "currency",
    cashback: "cashback",
    allocate: "allocate",
    paymentOption: "paymentOption",
    fundAccount: "fundAccount",
    exchangeFund: "exchangeFund",
    globalAccounts: "globalAccounts",
    globalAccountId: "globalAccountId",
    primaryAccount: "primaryAccount",
    addAccountLocation: "addAccountLocation",
    reviewGlobalAccount: "reviewGlobalAccount",
    transactions: "transactions",
    project: {
      id: "projectId",
      add: "addProject",
      edit: "editProject",
    },
    department: {
      id: "departmentId",
      add: "addDepartment",
      edit: "editDepartment",
    },
    budget: {
      id: "budgetId",
      type: "budgetType",
      create: "createBudget",
      createExisting: "createExistingBudget",
      createForm: {
        newProjBudget: "createNewProjBudget",
        newDeptBudget: "createNewDeptBudget",
        oldProjBudget: "createOldProjBudget",
        oldDeptBudget: "createOldProjBudget",
      },
      storeMode: "storeMode",
      editBudget: {
        newProjBudget: "editNewProjBudget",
        newDeptBudget: "editNewDeptBudget",
        oldProjBudget: "editOldProjBudget",
        oldDeptBudget: "editOldProjBudget",
      },
      reviewBudget: "reviewBudget",
    },
    location: {
      id: "locationId",
      add: "addlocation",
      edit: "editLocation",
    },
    people: {
      add: "addPeople",
      edit: "edit",
      invite: "invitePeople",
      inviteUser: "inviteUser",
      mainInvite: "mainInvite",
      pendingInvite: "pendingInvite",
      from: "openfrom",
      id: "userId",
      myProfile: "myProfile",
      addAddressDetails: "userAddressDetails",
      editAddressDetails: "editAddressDetails",
      addBankDetails: "userAddBankDetails",
      preAddBankDetails: "userPreAddBankDetails",
      editBankDetails: "editBankDetails",
      freezeUser: "freezeUser",
      lockUser: "lockUser",
      blockUser: "blockUser",
      switchDepartment: "switchDepartment",
      switchDepartmentTo: "switchDepartmentTo",
      switchLocation: "switchLocation",
      switchLocationTo: "switchLocationTo",
      switchRole: "switchRole",
      switchRoleTo: "switchRoleTo",
      pendingRequest: "pendingRequest",
      missingDetails: "missingDetails",
    },
    travel: {
      managePeople: "managePeople",
    },
    rolesPermissions: {
      addCustomRole: "addCustomRole",
      editCustomRole: "editCustomRole",
      role: "role",
      viewPermissions: "viewPermissions",
      assistant: "assistant",
    },
    billing: {
      invoiceId: "invoiceId",
    },
    credit: {
      repaymentHistory: "repaymentHistory",
      repayment: "repayment",
      creditLimit: "creditLimit",
      drawCredit: "drawCredit",
    },
  },
  settings: {
    approvalPolicy: "approvalPolicy",
    submissionPolicy: "submissionPolicy",
    customPolicy: "customPolicy",
    projectDepartmentId: "projectDepartmentId",
    policyModule: "policyModule",
    policyId: "policyId",
    submissionModule: "submissionModule",
    customModule: "customModule",
    customApprovalModule: "customApprovalModule",
    customSubmissionModule: "customSubmissionModule",
    customExpenseReviewModule: "customExpenseReviewModule",
    customClaimModule: "customClaimModule",
    editState: "editState",
    editRequirement: "editRequirement",
    policy: "policy",
    physicalCardPolicy: "physicalCardPolicy",
    logId: "logId",
  },
  qrPayments: {
    payments: {
      id: "qrPaymentsId",
      splitQrPayment: "splitQrpayment",
      flagTransaction: "flagTransactions",
    },
    userWallet: {
      id: "walletId",
      settings: "settings",
      freezeWallet: "freezeWallet",
    },
  },
  export: "export",
  inbox: "inbox",
  security: {
    securitySettings: "securityEditProfile",
    resetPassword: "resetPassword",
    twoStepVerification: "verification",
    setUpGoogleAuth: "setUpGoogleAuth",
  },
  switchEntity: {
    switch: "switchEntity",
    addAccount: "addAccount",
    connecting: {
      oneTimeToken: "oneTimeToken",
      fromSubdomain: "fromSubdomain",
      actionType: "actionType",
      successfullyConnected: "successfullyConnected",
      entityConnectionMessage: "entityConnectionMessage",
    },
    switching: {
      oneTimeToken: "oneTimeToken",
      email: "email",
      fromSubdomain: "fromSubdomain",
    },
  },

  reports: {
    create: "createReport",
    addClaims: "addClaims",
    reviewReport: "reviewReport",
    reviewId: "reviewId",
    reportId: "reportId",
    rejectReport: "rejectReport",
    userReportId: "userReportId",
  },
  myVolopay: {
    actionCentre: {
      requestHistory: "requestHistory",
    },
  },
  quickGuide: {
    slider: "quickGuideSlider",
    feedbackSlider: "feedbackSlider",
  },
};

/**
 * Flattening object to infinite level
 * @param {Object} obj
 * @param {Object} flattened
 * @param {String} prefix
 * @returns
 */
export function flatten(obj, flattened = {}, prefix = "") {
  Object.entries(obj).forEach(([key, value]) => {
    const newKey = prefix ? `${prefix}.${key}` : key;
    if (typeof value === "object" && value !== null) {
      flatten(value, flattened, newKey);
    } else {
      flattened[newKey] = value;
    }
  });
  return flattened;
}

export const FLATTENED_SEARCH_PARAM_OBJECT = flatten(SLIDERS_SEARCH_PARAMS);

export const SLIDER_LEFT_SIDE_SEARCH_PARAMS = {
  rightSideKey: "rightSideKey",
  dependentKeyForRightSlider: "dependentKeyForRightSlider",
  components: {
    bulkUploadPayments: "bulkUploadPayments",
  },
  leftSideDisabled: "leftSideDisabled",
};

export const FLATTENED_SLIDER_LEFT_SIDE_SEARCH_PARAMS = flatten(
  SLIDER_LEFT_SIDE_SEARCH_PARAMS
);
