import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  fetchAndSelectUserWallet,
  freezeWallet,
  unfreezeWallet,
} from "@/store/reducers/qr-payments";

import {
  selectedUserWalletCtaBtnsSelector,
  selectedUserWalletSelector,
} from "@/store/selectors/qr-payments";

import Tabs from "@/components/core/Tabs";

import CardSliderMenu from "@/components/Cards/Sliders/CardSlider/Common/CardSliderMenu";
import CardSummaryShort from "@/components/Cards/Sliders/CardSlider/Common/CardSumaryShort";
import OverviewTab from "@/components/Cards/Sliders/CardSlider/OverviewTab";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";
import ModuleHistory from "@/components/common/ModuleHistory";
import { OWNER_TYPE_MASTER_HISTORY } from "@/utils/constants/app";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import {
  SELECTED_TABS_USER_WALLET,
  USER_WALLET_ALL_SLIDER_MENU_CONFIG,
  USER_WALLET_ALL_SLIDER_MENU_KEYS,
} from "@/constants/qrPayments";

// const inAU = false; // handle later

export default function UserWalletSlider() {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState(SELECTED_TABS_USER_WALLET[0]);

  const sliderId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.qrPayments.userWallet.id
  );
  const walletId = searchParams.get(
    SLIDERS_SEARCH_PARAMS.qrPayments.userWallet.id
  );

  const wallet = useSelector(selectedUserWalletSelector);

  const sliderBtns = useSelector(selectedUserWalletCtaBtnsSelector);

  const [ctasConfig, setCtasConfig] = useState([]);

  const generateCardsSliderCtaBtnsConfig = () => {
    const config = sliderBtns?.map((btnName) => ({
      ...USER_WALLET_ALL_SLIDER_MENU_CONFIG[btnName],
    }));
    setCtasConfig(config);
  };
  const handleMenuClick = (menuKey) => {
    switch (menuKey) {
      case USER_WALLET_ALL_SLIDER_MENU_KEYS.FREEZE_WALLET:
        dispatch(freezeWallet(walletId));
        break;

      case USER_WALLET_ALL_SLIDER_MENU_KEYS.UNFREEZE:
        dispatch(unfreezeWallet(walletId));
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (sliderId) {
      dispatch(fetchAndSelectUserWallet({ walletId: parseInt(sliderId, 10) }));
    }
  }, [sliderId]);

  useEffect(() => {
    generateCardsSliderCtaBtnsConfig();
  }, [sliderBtns]);

  // ctasConfig =  [ {key, disabled, tooltipTipConfig:{ hasToolTip : "", text : " "}, label, preIcon, key},{},{} ]

  return wallet ? (
    <div className="px-9">
      <CardSummaryShort
        heading={wallet?.walletHolder?.displayName}
        status={wallet?.status}
        cardType={wallet?.type}
      />

      <CardSliderMenu
        ctasConfig={ctasConfig}
        handleMenuClick={handleMenuClick}
      />

      <Tabs
        items={SELECTED_TABS_USER_WALLET}
        selectedTab={selectedTab?.key}
        setCurrentTab={(tab) => setSelectedTab(tab)}
        mode
      />

      <div className="pt-4">
        {selectedTab?.key === SELECTED_TABS_USER_WALLET[0]?.key ? (
          <OverviewTab card={wallet} page={QRPAY_EXPENSES_CONTEXT.QRPAY} />
        ) : (
          <ModuleHistory
            ownerId={wallet?.id}
            ownerType={OWNER_TYPE_MASTER_HISTORY.QR_PAY}
          />
        )}
      </div>
    </div>
  ) : null;
}
