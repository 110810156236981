import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { isFormSubmissionProgressSelector } from "@/store/selectors/loadersError";

import FormLoader from "@/components/core/FormLoader";

function FormLoaderProvider({ children }) {
  const isFormInProgress = useSelector(isFormSubmissionProgressSelector);

  useEffect(() => {
    if (isFormInProgress) {
      document.body.classList.add("hide-overlay");
    } else {
      document.body.classList.remove("hide-overlay");
    }
  }, [isFormInProgress]);
  return (
    <div>
      {isFormInProgress ? (
        <div className="fixed z-[9999] min-h-screen h-full  overflow-hidden cursor-progress flex flex-1 w-screen items-center justify-center  bg-black opacity-50">
          <FormLoader />
        </div>
      ) : null}
      {children}
    </div>
  );
}

export default FormLoaderProvider;
