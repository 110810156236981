import { createSelector } from "@reduxjs/toolkit";

import { selectedPaymentSelector } from "@/store/selectors/payments";

import {
  CREATE_BILL_TAX_RESPONSE,
  TAX_DISPLAY_VALUES,
} from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";

export const selectPurchaseBillsStore = (store) => store.purchaseBills;

export const purchaseBillsSelector = createSelector(
  selectPurchaseBillsStore,
  (purchaseBills) => purchaseBills.purchaseBills
);

export const purchaseBillsListSelector = createSelector(
  purchaseBillsSelector,
  (purchaseBills) => purchaseBills.list
);
export const purchaseBillsCtasSelector = createSelector(
  purchaseBillsSelector,
  (purchaseBills) => purchaseBills.ctas
);
export const isPurchaseBillsFetchingSelector = createSelector(
  purchaseBillsSelector,
  (purchaseBills) => purchaseBills.isFetching
);

export const isPurchaseBillLimitSelector = createSelector(
  purchaseBillsSelector,
  (purchaseBills) => purchaseBills.limit
);

export const purchaseBillsPageSelector = createSelector(
  purchaseBillsSelector,
  (purchaseBills) => purchaseBills.page
);

export const purchaseBillsTotalSelector = createSelector(
  purchaseBillsSelector,
  (purchaseBills) => purchaseBills.total
);

export const purchaseBillsHasMoreSelector = createSelector(
  purchaseBillsSelector,
  (purchaseBills) => purchaseBills.hasMore
);
export const isProcessingCountSelector = createSelector(
  selectPurchaseBillsStore,
  (purchaseBills) => purchaseBills.isProcessingCount
);

export const recurringPaymentsSelector = createSelector(
  selectPurchaseBillsStore,
  (purchaseBills) => purchaseBills.recurringPayments
);

export const recurringPaymentsListSelector = createSelector(
  recurringPaymentsSelector,
  (recurringPayments) => recurringPayments.list
);

export const isRecurringPaymentsFetchingSelector = createSelector(
  recurringPaymentsSelector,
  (recurringPayments) => recurringPayments.isFetching
);

export const recurringPaymentsLimitSelector = createSelector(
  recurringPaymentsSelector,
  (recurringPayments) => recurringPayments.limit
);

export const recurringPaymentsPageSelector = createSelector(
  recurringPaymentsSelector,
  (recurringPayments) => recurringPayments.page
);

export const recurringPaymentsTotalSelector = createSelector(
  recurringPaymentsSelector,
  (recurringPayments) => recurringPayments.total
);

export const recurringPaymentsHasMoreSelector = createSelector(
  recurringPaymentsSelector,
  (recurringPayments) => recurringPayments.hasMore
);

export const purchaseBillQuoteSelector = createSelector(
  selectPurchaseBillsStore,
  (purchaseBills) => purchaseBills.quote
);

export const isLoadingPurchaseBillSelector = createSelector(
  selectPurchaseBillsStore,
  (purchaseBills) => purchaseBills.isLoading
);

export const isFetchingPurchaseBillQuoteSelector = createSelector(
  selectPurchaseBillsStore,
  (purchaseBills) => purchaseBills.isFetchingQuote
);

export const purchaseBillTaxDetailsSelector = createSelector(
  selectPurchaseBillsStore,
  (purchaseBills) => purchaseBills.purchaseBillTaxDetails
);

export const isFetchingPurchaseBillTaxDetailsSelector = createSelector(
  selectPurchaseBillsStore,
  (purchaseBills) => purchaseBills.isFetchingPurchaseBillTaxDetails
);

export const isFetchedPurchaseBillTaxDetailsSelector = createSelector(
  selectPurchaseBillsStore,
  (purchaseBills) => purchaseBills.isFetchedPurchaseBillTaxDetails
);

export const additivePurchaseBillTaxesSelector = createSelector(
  selectPurchaseBillsStore,
  (purchaseBills) =>
    purchaseBills.purchaseBillTaxDetails.filter(
      (item) => item[CREATE_BILL_TAX_RESPONSE.ADDITIVE]
    )
);

export const additivePurchaseTaxParentSelector = createSelector(
  additivePurchaseBillTaxesSelector,
  (additiveTaxes) =>
    additiveTaxes.find(
      (item) =>
        item[CREATE_BILL_TAX_RESPONSE.DISPLAY] ===
        TAX_DISPLAY_VALUES.SHOW_ALWAYS
    )
);

export const additivePurchaseTaxChildrenSelector = createSelector(
  additivePurchaseBillTaxesSelector,
  (additiveTaxes) =>
    additiveTaxes.filter(
      (item) =>
        item[CREATE_BILL_TAX_RESPONSE.DISPLAY] === TAX_DISPLAY_VALUES.OPTIONAL
    )
);

export const subtractivePurchaseBillTaxesSelector = createSelector(
  selectPurchaseBillsStore,
  (purchaseBills) =>
    purchaseBills.purchaseBillTaxDetails.filter(
      (item) => !item[CREATE_BILL_TAX_RESPONSE.ADDITIVE]
    )
);

export const subtractivePurchaseTaxParentSelector = createSelector(
  subtractivePurchaseBillTaxesSelector,
  (subtractiveTaxes) =>
    subtractiveTaxes.find(
      (item) =>
        item[CREATE_BILL_TAX_RESPONSE.DISPLAY] ===
        TAX_DISPLAY_VALUES.SHOW_ALWAYS
    )
);

export const subtractivePurchaseTaxChildrenSelector = createSelector(
  subtractivePurchaseBillTaxesSelector,
  (subtractiveTaxes) =>
    subtractiveTaxes.filter(
      (item) =>
        item[CREATE_BILL_TAX_RESPONSE.DISPLAY] === TAX_DISPLAY_VALUES.OPTIONAL
    )
);

// utility selector
// fixes parent tax not ready because it has a .find inside
// the compute is inside selector, not API call or reducer
export const isPurchaseBillTaxesReadySelector = createSelector(
  isFetchingPurchaseBillTaxDetailsSelector,
  isFetchedPurchaseBillTaxDetailsSelector,
  //
  purchaseBillTaxDetailsSelector,
  additivePurchaseTaxParentSelector,
  additivePurchaseTaxChildrenSelector,
  subtractivePurchaseTaxParentSelector,
  subtractivePurchaseTaxChildrenSelector,
  (
    isFetching,
    isFetched,
    allTaxes,
    additiveParent,
    additiveChildren,
    subtractiveParent,
    subtractiveChildren
  ) =>
    !isFetching &&
    isFetched &&
    allTaxes.length ===
      (additiveParent ? 1 : 0) +
        (additiveChildren?.length ?? 0) +
        (subtractiveParent ? 1 : 0) +
        (subtractiveChildren?.length ?? 0)
);

export const isFetchingApiCallSelector = createSelector(
  selectPurchaseBillsStore,
  (purchaseBills) => purchaseBills.isFetchingApiCall
);

export const taxVersionSelector = createSelector(
  selectPurchaseBillsStore,
  (purchaseBills) => false
  // true means old, false means new
);

export const isFetchingDuplicateBillSelector = createSelector(
  selectPurchaseBillsStore,
  (purchaseBills) => purchaseBills.isFetchingDuplicateBill
);

export const duplicateBillsSelector = createSelector(
  selectPurchaseBillsStore,
  (purchaseBills) => purchaseBills.duplicateBill
);

export const recentDuplicateBillSelector = createSelector(
  selectPurchaseBillsStore,
  selectedPaymentSelector,
  (purchaseBills, billOrPayment) => {
    const billOrPaymentId = billOrPayment?.id;

    let indexOfRecent = purchaseBills?.duplicateBill?.findIndex(
      (item) => Number(item.id) > Number(billOrPaymentId)
    );

    // if just two, point to each other, otherwise, recent previous
    indexOfRecent = indexOfRecent <= 0 ? 0 : indexOfRecent - 1;

    return Array.isArray(purchaseBills.duplicateBill)
      ? (purchaseBills.duplicateBill[indexOfRecent] ?? null)
      : purchaseBills.duplicateBill;
  }
);

export const billPayrollCreationSubmissionPolicySelector = createSelector(
  selectPurchaseBillsStore,
  (purchaseBills) => purchaseBills.billPayrollCreationSubmissionPolicy
);

export const isFetchingBillPayrollCreationSubmissionPolicySelector =
  createSelector(
    selectPurchaseBillsStore,
    (purchaseBills) =>
      purchaseBills.isFetchingBillPayrollCreationSubmissionPolicy
  );

export const billPayrollCreationApprovalPolicySelector = createSelector(
  selectPurchaseBillsStore,
  (purchaseBills) => purchaseBills.billPayrollCreationApprovalPolicy
);

export const isFetchingBillPayrollCreationApprovalPolicySelector =
  createSelector(
    selectPurchaseBillsStore,
    (purchaseBills) => purchaseBills.isFetchingbillPayrollCreationApprovalPolicy
  );

export const isActionPendingForPurchaseBillSelector = createSelector(
  selectPurchaseBillsStore,
  (purchaseBills) => purchaseBills.isActionPendingFor
);

export const billMasterHistorySelector = createSelector(
  selectPurchaseBillsStore,
  (purchaseBills) => purchaseBills.billMasterHistory
);

export const isFetchingBillMasterHistorySelector = createSelector(
  selectPurchaseBillsStore,
  (purchaseBills) => purchaseBills.isFetchingBillMasterHistory
);
