/* eslint-disable import/order */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import to from "await-to-js";

import API from "@/api";

import {
  camelToSnake,
  getErrorToastMessage,
  getSuccessToastMessage,
} from "@/utils/common";
import { AVAILABLE_FILTER_KEYS } from "@/utils/constants/filters";
import vToast from "@/utils/vToast";

import {
  CARD_SETTINGS_FORM_FIELD_KEYS,
  CARD_SLIDER_SELECTED_TABS_CARD,
  CARD_TYPES,
} from "@/constants/Cards";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

import { setIsFormSubmissionProgress } from "./loadersError";
import { AxiosError } from "axios";

const initialState = {
  cards: {
    list: [],
    hasMore: true,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    isFetching: false,
    page: 0,
    total: 0,
    physicalCardComingSoon: false,
  },
  selectedCard: null,
  physicalCard: {},
  isFetchingCard: false,
  isCardFreezePending: false,
  isFetchingPhysicalCardBudgets: true,
  fetchingCards: false,
  fetchingCardSingular: false,
  physicalCardBudgets: [],
  cardBudgets: [],
  cardChannel: null,
  paymentInfo: {},
  fetchedCardDetail: false,
  createVirtualCardInput: {},
  isCardOperationProcessing: false,
  settings: {
    selectedValues: [],
    flagRules: {
      list: [],
      isFetching: false,
    },
    expenseAlerts: {
      list: [],
      isFetching: false,
    },
    expenseReviewRange: [],
    expenseReviewLayersDisableStatus: [],
  },
  filtersKey: {
    physical: {
      all: [
        AVAILABLE_FILTER_KEYS.searchAndFilter,
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.cardLimit,
        AVAILABLE_FILTER_KEYS.cardFrequency,
        AVAILABLE_FILTER_KEYS.cardState,
        AVAILABLE_FILTER_KEYS.utilization,
        AVAILABLE_FILTER_KEYS.department,
        AVAILABLE_FILTER_KEYS.cardUsageType,
      ],
      yetToActivate: [
        AVAILABLE_FILTER_KEYS.searchAndFilter,
        AVAILABLE_FILTER_KEYS.department,
      ],
      blocked: [
        AVAILABLE_FILTER_KEYS.searchAndFilter,
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.cardLimit,
        AVAILABLE_FILTER_KEYS.cardFrequency,
        AVAILABLE_FILTER_KEYS.department,
        AVAILABLE_FILTER_KEYS.cardUsageType,
      ],
    },
    virtual: {
      all: [
        AVAILABLE_FILTER_KEYS.searchAndFilter,
        AVAILABLE_FILTER_KEYS.datePeriods,
        AVAILABLE_FILTER_KEYS.cardLimit,
        AVAILABLE_FILTER_KEYS.cardFrequency,
        AVAILABLE_FILTER_KEYS.cardState,
        AVAILABLE_FILTER_KEYS.utilization,
        AVAILABLE_FILTER_KEYS.department,
      ],
      blocked: [
        AVAILABLE_FILTER_KEYS.searchAndFilter,
        AVAILABLE_FILTER_KEYS.department,
      ],
    },
  },
  editedLimitData: null,
  orderPhysicalCardFormData: {},
  physicalCardsActivationFormData: {},
  poolCardDetails: null,
  projectList: [],
  updatedLimitData: {},
  limitChanged: {
    inProgress: false,
    isChanged: false,
  },
  physicalCardsActivationFields: [
    {
      label: null,
      step: null,
      sections: [],
    },
  ],
  cardRequestData: {
    selectedTab: {},
    slidersInfo: {
      approvalReqeustData: {},
      isFetching: false,
    },
  },
  activateCard: {
    inProgress: true,
    activateCardStatus: false,
  },
  orderVirtualCardSteps: {
    steps: {},
    isFetching: true,
  },
  orderCardStatus: false,
  bulkOperationInPending: false,
  isPinReset: false,
  resetPinLink: null,
  changedLinkedToStatus: {
    isChanged: false,
    inProgress: false,
  },
  // For fetching initial merchant restrictions info
  merchantRestrictions: {
    isFetching: false,
    data: {},
  },
  cardSettingsFormInfo: {
    isCardSettingsUpdating: false,
  },
  cardControlsInfo: {
    isFetching: false,
    controlsList: [],
  },
  cardSliderTabsInfo: {
    selectedTab: CARD_SLIDER_SELECTED_TABS_CARD[0],
    tabsList: CARD_SLIDER_SELECTED_TABS_CARD,
  },
  shallowMerchantInfo: {
    isFetching: false,
    list: [],
  },
  merchants: {
    list: [],
    hasMore: true,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    isFetching: false,
    page: 0,
    total: 0,
  },
  cardRequestTableInfo: {
    list: [],
    hasMore: true,
    limit: PAGINATION_PER_REQUEST_LIMIT,
    isFetching: false,
    page: 0,
    total: 0,
  },
  cardModuleRequestFilterKeys: [
    AVAILABLE_FILTER_KEYS.searchAndFilter,
    AVAILABLE_FILTER_KEYS.cardModuleRequestType,
    AVAILABLE_FILTER_KEYS.cardModuleCardType,
  ],
  selectedMerchantDetails: null,
  cardActionGuidelineVideos: {},
};

const cardSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {
    setCards(state, action) {
      state.cards = action.payload;
    },
    setCardsList(state, action) {
      state.cards.list = action.payload;
    },
    setIsFetchingCards(state, action) {
      state.cards.isFetching = action.payload;
    },
    resetCardsListAndPagination(state) {
      state.cards.list = [];
      state.cards.page = 1;
      state.cards.hasMore = true;
      state.cards.total = 0;
      state.cards.isFetching = false;
      state.cards.physicalCardComingSoon = false;
    },

    resetSliderInfo(state) {
      state.cardRequestData.slidersInfo = {
        approvalReqeustData: {},
        isFetching: false,
      };
    },
    setState(state, action) {
      const { key, value } = action.payload;
      state[key] = value;
    },

    setSelectedCard(state, action) {
      state.selectedCard = action.payload;
    },

    setResetPinUrl(state, action) {
      if (!state.selectedCard) {
        state.selectedCard = {};
      }
      state.selectedCard.resetPinLink = action.payload;
    },

    setPhysicalCard(state, action) {
      state.physicalCard = action.payload;
    },

    setPhysicalCardBudgets(state, action) {
      state.physicalCardBudgets = action.payload;
    },

    setCardBudgets(state, action) {
      state.cardBudgets = action.payload;
    },
    setAvailableToSpend(state, action) {
      state.selectedCard.availableToSpend.value = action.payload.value;
    },

    setTotalSpent(state, action) {
      state.selectedCard.totalSpent = action.payload;
    },

    setIsFetchingCard(state, action) {
      state.isFetchingCard = action.payload;
    },
    setCardBudgetAvailableToSpend(state, action) {
      const { id, value } = action.payload;
      state.cardBudgets.find(
        (cardBudget) => cardBudget.id === id
      ).availableToSpend.value = value;
    },

    setFetchedCardDetail(state, action) {
      state.fetchedCardDetail = action.payload;
    },

    setCardPageConfig(state, action) {
      const { page, total, limit, physicalCardComingSoon } = action.payload;

      if (page) {
        state.cards.page = page;
      }

      if (limit) {
        state.cards.limit = limit;
      }

      if (total) state.cards.hasMore = state.cards.list.length < total;

      if (total) {
        state.cards.total = total;
      }

      if (physicalCardComingSoon) {
        state.cards.physicalCardComingSoon = physicalCardComingSoon;
      }
    },
    updateCardAvailableToSpend(state, action) {
      setAvailableToSpend(action.payload);
    },

    updateCardBudgetAvailableToSpend(state, action) {
      setCardBudgetAvailableToSpend(action.payload);
    },

    updateCard(state, action) {
      const index = state.cards.list.findIndex(
        (_card) => _card.id === action.payload.id
      );
      state.cards.list[index] = action.payload.card;
      state.cards.list = [...state.cards.list];
    },

    updateCardInCards(state, action) {
      const index = state.cards.list.findIndex(
        (_card) => _card.id === action.payload.id
      );
      state.cards.list[index] = action.payload.card;
      state.cards.list = [...state.cards.list];
    },

    updateCurrentCard(state, action) {
      state.selectedCard = action.payload;
    },

    updateCardBudgetInStore(state, action) {
      const { id, cardBudget } = action.payload;
      const index = state.cards.list.findIndex((el) => el.id === id);
      const card = state.cards.list.find((el) => el.id === id);
      const idx = card.cardBudgets.findIndex((el) => el.id === cardBudget.id);
      card.cardBudgets[idx] = { ...cardBudget };
      this.setState((prevState) => ({
        ...prevState,
        cards: {
          ...prevState.cards,
          list: prevState.cards.list.map((val, Idx) =>
            Idx === index ? { ...card } : { ...val }
          ),
        },
      }));
    },

    addCard(state, action) {
      state.cards.push(action.payload);
    },

    appendToCardsList(state, action) {
      state.cards.list = [...state.cards.list, ...action.payload];
    },
    resetPhysicalCardBudgets(state, action) {
      state.cardBudgets = action.payload;
    },

    resetCard(state) {
      state.selectedCard = null;
    },
    setCreateVirtualCardInput(state, action) {
      state.createVirtualCardInput = {
        ...state.createVirtualCardInput,
        ...action.payload,
      };
    },
    resetCreateVirtualCardInput(state) {
      state.createVirtualCardInput = {};
    },
    setControlType(state, action) {
      state.settings.categoriesType = action.payload;
    },
    setSelectedValues(state, action) {
      state.settings.selectedValues = action.payload;
    },
    deleteSelectedValue(state, action) {
      state.settings.selectedValues = state.settings.selectedValues?.filter(
        (itemId) => itemId !== action.payload
      );
    },
    setMerchantType(state, action) {
      state.settings.merchantType = action.payload;
    },
    setMerchantSelectedValues(state, action) {
      state.settings.merchantSelectedValues = action.payload;
    },
    addFlagRules(state, action) {
      state.settings.flagRules.list = [
        ...state.settings.flagRules,
        action.payload,
      ];
    },
    setFlagRules(state, action) {
      state.settings.flagRules.list = action.payload;
    },
    deleteFlagRules(state, action) {
      state.settings.flagRules.list = state.settings.flagRules?.list.filter(
        (item) => item.id !== action.payload
      );
    },
    updateFlagRules(state, action) {
      state.settings.flagRules.list = state.settings.flagRules?.map((flag) =>
        flag?.id === action.payload?.id ? action.payload : flag
      );
    },
    setIsFetchingFlagRules(state, action) {
      state.settings.flagRules.isFetching = action.payload;
    },
    addExpenseAlerts(state, action) {
      state.settings.expenseAlerts.list = [
        ...state.settings.expenseAlerts,
        action.payload,
      ];
    },
    setExpenseAlerts(state, action) {
      state.settings.expenseAlerts.list = action.payload;
    },
    deleteExpenseAlerts(state, action) {
      state.settings.expenseAlerts.list =
        state.settings.expenseAlerts?.list?.filter(
          (item) => item.id !== action.payload
        );
    },
    updateExpenseAlerts(state, action) {
      state.settings.expenseAlerts.list = state.settings.expenseAlerts?.map(
        (flag) => (flag?.id === action.payload?.id ? action.payload : flag)
      );
    },
    setIsFetchingExpenseAlerts(state, action) {
      state.settings.expenseAlerts.isFetching = action.payload;
    },
    setExpenseReviewRange(state, action) {
      state.settings.expenseReviewRange = action.payload;
    },
    updateExpenseReviewRange(state, action) {
      state.settings.expenseReviewRange = [
        ...state.settings.expenseReviewRange,
        ...action.payload,
      ];
    },
    setIsCardFreezePending(state, action) {
      state.isCardFreezePending = action.payload;
    },
    setEditedLimitData(state, action) {
      state.editedLimitData = action.payload;
    },
    resetEditedLimitData(state) {
      state.editedLimitData = null;
    },
    setOrderPhysicalCardFormData(state, action) {
      state.orderPhysicalCardFormData = action.payload;
    },
    resetOrderPhysicalCardFormData(state) {
      state.orderPhysicalCardFormData = {};
    },
    setPaymentInfo(state, action) {
      state.paymentInfo = action.payload;
    },
    setPoolCardDetails(state, action) {
      state.poolCardDetails = action.payload;
    },
    setProjectList(state, action) {
      state.projectList = action.payload;
    },
    setPhysicalCardActivationFormFields(state, action) {
      state.physicalCardsActivationFields[0].sections = [action.payload];
    },
    setPhysicalCardActivationFieldsStep(state, action) {
      state.physicalCardsActivationFields[0].step = action.payload;
    },
    setPhysicalCardsActivationFormData(state, action) {
      state.physicalCardsActivationFormData = action.payload;
    },
    resetPhysicalCardsActivationFormData(state) {
      state.physicalCardsActivationFormData = {};
    },
    resetPhysicalCardsActivationFields(state) {
      state.physicalCardsActivationFields = [
        {
          label: null,
          step: null,
          sections: [],
        },
      ];
    },
    mergePhysicalCardActivationFormData(state, action) {
      state.physicalCardsActivationFormData = {
        ...state.physicalCardsActivationFormData,
        ...action.payload,
      };
    },
    updateLimitSelectedCard(state, action) {
      state.updatedLimitData = action.payload;
    },
    setSelectedTabForCardSpecificRequestSliders(state, action) {
      state.cardRequestData.selectedTab = action.payload;
    },
    setReqeustApprovalInfo(state, action) {
      state.cardRequestData.slidersInfo.approvalReqeustData = action.payload;
    },
    setRequestApprovalInfoInprogress(state, action) {
      state.cardRequestData.slidersInfo.isFetching = action.payload;
    },
    setIsCardOperationProcessing(state, action) {
      state.isCardOperationProcessing = action.payload;
    },
    setExpenseReviewLayersDisableStatus(state, action) {
      state.settings.expenseReviewLayersDisableStatus = action.payload;
    },
    setInProgressActivateCard(state, action) {
      state.activateCard.inProgress = action.payload;
    },
    setActivateCardStatus(state, action) {
      state.activateCard.activateCardStatus = action.payload;
    },
    resetActivateCardConfig(state) {
      state.activateCard = { inProgress: true, activateCardStatus: true };
    },
    setIsFetchingVirtualCardSteps(state, action) {
      state.orderVirtualCardSteps.isFetching = action.payload;
    },
    setVirtualCardsSteps(state, action) {
      state.orderVirtualCardSteps.steps = action.payload;
    },
    setOrderCardStatus(state, action) {
      state.orderCardStatus = action.payload;
    },
    setBulkOperationInPending(state, action) {
      state.bulkOperationInPending = action.payload;
    },
    setIsLimitChanged(state, action) {
      state.limitChanged.isChanged = action.payload;
    },
    setInProgressLimitChange(state, action) {
      state.limitChanged.inProgress = action.payload;
    },
    setIsPinReset(state, action) {
      state.isPinReset = action.payload;
    },
    setIsLinkedToChanged(state, action) {
      state.changedLinkedToStatus.isChanged = action.payload;
    },
    setIsLinkedToInprogress(state, action) {
      state.changedLinkedToStatus.inProgress = action.payload;
    },
    setMerchantRestrictions(state, action) {
      state.merchantRestrictions.data = action.payload;
    },
    setIsFethingMerchantRestrictions(state, action) {
      state.merchantRestrictions.isFetching = action.payload;
    },
    setCardSettingsFormInfo(state, action) {
      state.cardSettingsFormInfo = {
        ...state.cardSettingsFormInfo,
        ...action.payload,
      };
    },
    resetCardSettingsFormInfo(state) {
      state.cardSettingsFormInfo = {};
    },
    setCardControlsInfo(state, action) {
      state.cardControlsInfo.controlsList = action.payload;
    },
    setIsFetchingCardControls(state, action) {
      state.cardControlsInfo.isFetching = action.payload;
    },
    setIsCardSettingsUpdating(state, action) {
      state.cardSettingsFormInfo.isCardSettingsUpdating = action.payload;
    },
    setCardSliderTab(state, action) {
      state.cardSliderTabsInfo.selectedTab = action.payload;
    },
    setShallowMerchantList(state, action) {
      state.shallowMerchantInfo.list = action.payload;
    },
    setIsFetchingShallowMerchant(state, action) {
      state.shallowMerchantInfo.isFetching = action.payload;
    },
    setMerchantList(state, action) {
      state.merchants.list = action.payload;
    },
    appendToMerchantsList(state, action) {
      state.merchants.list = [...state.merchants.list, ...action.payload];
    },
    setIsFetchingMerchantsList(state, action) {
      state.merchants.isFetching = action.payload;
    },
    setMerchantsListConfig(state, action) {
      const { page, total, limit } = action.payload;

      if (page) {
        state.merchants.page = page;
      }

      if (limit) {
        state.merchants.limit = limit;
      }

      if (total) {
        state.merchants.hasMore = state.merchants.list.length < total;
      }

      if (total) {
        state.merchants.total = total;
      }
    },
    resetMerchantListInfo(state) {
      state.merchants = {
        list: [],
        hasMore: true,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        isFetching: false,
        page: 0,
        total: 0,
      };
    },
    setGlobalSettingsMccInfo(state, action) {
      state.settings[CARD_SETTINGS_FORM_FIELD_KEYS.MERCHANT_AND_CATEGORY] =
        action.payload;
    },
    addGlobalSettingsFields(state, action) {
      state.settings = { ...state.settings, ...action.payload };
    },
    setCardRequestTableList(state, action) {
      state.cardRequestTableInfo.list = action.payload;
    },
    appendCardRequestTableList(state, action) {
      state.cardRequestTableInfo.list = [
        ...state.cardRequestTableInfo.list,
        ...action.payload,
      ];
    },
    setIsFetchingCardRequestTableInfo(state, action) {
      state.cardRequestTableInfo.isFetching = action.payload;
    },
    setCardRequestTableListHasMore(state, action) {
      state.cardRequestTableInfo.hasMore =
        state?.cardRequestTableInfo?.list?.length <
        state?.cardRequestTableInfo?.total;
    },
    setCardRequestLimit(state, action) {
      state.cardRequestTableInfo.limit = action.payload;
    },
    setCardRequestTotalCount(state, action) {
      state.cardRequestTableInfo.total = action.payload;
    },
    setCardRequestPage(state, action) {
      state.cardRequestTableInfo.page = action.payload;
    },
    resetCardRequestTableInfo(state) {
      state.cardRequestTableInfo = {
        list: [],
        hasMore: true,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        isFetching: false,
        page: 0,
        total: 0,
      };
    },
    setSelectedMerchantDetails(state, action) {
      state.selectedMerchantDetails = action?.payload;
    },
    setCardActionsGuidelineVideos(state, action) {
      state.cardActionGuidelineVideos = action.payload;
    },
  },
});

// action creator

export const resetCards = () => (dispatch) => {
  dispatch(setState({ key: "fetchingCards", value: true }));
  dispatch(
    setCardPageConfig({
      page: 0,
      total: 0,
      hasMore: true,
      physicalCardComingSoon: false,
    })
  );
  dispatch(setCards([]));
};

// async action creaters
export const fetchCards = createAsyncThunk(
  "cards/fetchCards",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingCards(true));
    const [err, response] = await to(API.Cards.all(params));
    if (response?.data) {
      if (response?.data?.page > 1) {
        dispatch(appendToCardsList(response?.data?.list));
      } else {
        dispatch(setCardsList(response?.data?.list));
      }

      dispatch(
        setCardPageConfig({
          page: response?.data?.page,
          limit: response?.data?.limit,
          total: response?.data?.total,
          physicalCardComingSoon: response?.data?.physicalCardComingSoon,
        })
      );
    }

    dispatch(setIsFetchingCards(false));
  }
);

export const fetchShallowCards = createAsyncThunk(
  "cards/fetchShallowCards",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingCards(true));
    const [err, response] = await to(
      API.Cards.all({ ...params, shallow: true })
    );
    if (response.data) {
      dispatch(setCardsList(response.data));
    }
    dispatch(setIsFetchingCards(false));
  }
);

export const fetchAndSelectCard = createAsyncThunk(
  "cards/fetchAndSelectCard",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingCard(true));

    const { cardId, onSuccess = null } = params;
    const [err, response] = await to(API.Cards.get(cardId));
    if (response?.data) {
      const updatedResponse = {
        ...response.data,
      };
      dispatch(setSelectedCard(updatedResponse));
      if (onSuccess) {
        onSuccess(response?.data);
      }
    }

    dispatch(setIsFetchingCard(false));
  }
);

export const fetchCardBudgetSpent = createAsyncThunk(
  "cards/fetchCardSpent",
  async (params, { dispatch }) => {
    const { cardBudgetId } = params;
    const [err, response] = await to(API.Cards.spent(cardBudgetId, params));
    if (response?.data) {
      dispatch(setTotalSpent(response?.data?.spent));
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const fetchOnboardingStepsForVirtualCard = createAsyncThunk(
  "cards/onboardingRequirements",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingVirtualCardSteps(true));
    const [err, response] = await to(API.Cards.getCreateVirtualCardSteps());
    if (response?.data) {
      dispatch(setVirtualCardsSteps(response?.data?.[0]));
    }
    if (err) {
      vToast({ title: "Failed to fetch steps", variant: "danger" });
    }
    dispatch(setIsFetchingVirtualCardSteps(false));
  }
);

export const getRequirements = createAsyncThunk(
  "cards/getRequirements",
  async () => {
    const [err, response] = await to(API.Cards.requirement());
    return [err, response];
  }
);

export const activateCard = createAsyncThunk(
  "cards/activateCard",
  async ({ cardId, payload, onSuccess }, { dispatch }) => {
    const vToastConfig = { title: null, description: null, variant: null };
    dispatch(setIsFormSubmissionProgress(true));

    dispatch(setInProgressActivateCard(false));

    const [err, response] = await to(API.Cards.activate(cardId, payload));

    if (response?.data) {
      vToastConfig.title =
        "cards.pCards.sliders.physicalCardActivation.toasterMessages.success.title";

      vToastConfig.description =
        "cards.pCards.sliders.physicalCardActivation.toasterMessages.success.description";

      vToastConfig.variant = "success";
      dispatch(setActivateCardStatus(true));
    } else if (err instanceof AxiosError && err.message) {
      vToastConfig.title =
        "cards.pCards.sliders.physicalCardActivation.toasterMessages.failed.title";

      vToastConfig.description =
        "cards.pCards.sliders.physicalCardActivation.toasterMessages.failed.description";

      vToastConfig.variant = "danger";
    }

    if (vToastConfig?.title) {
      vToast({ ...vToastConfig });
    }

    if (onSuccess) {
      onSuccess();
    }

    dispatch(setInProgressActivateCard(false));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const resetPin = createAsyncThunk(
  "cards/resetPin",
  async ({ cardId, payload }, { dispatch }) => {
    setIsPinReset(false);
    dispatch(setIsFormSubmissionProgress(true));

    const [err, response] = await to(API.Cards.pinReset(cardId, payload));

    if (response) {
      dispatch(setIsPinReset(true));
      vToast({ title: "PIN has been changed Successfully" });
    }

    if (err) {
      vToast({ title: "Failed to change PIN. Contact support@volopay.co" });
    }

    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const orderCard = createAsyncThunk(
  "cards/orderCard",
  async ({ payload, onSuccess }, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    dispatch(setOrderCardStatus(false));

    const [err, response] = await to(API.Cards.create(payload));
    if (err) {
      vToast(getErrorToastMessage(err));
    }

    if (response) {
      dispatch(setOrderCardStatus(true));
      vToast(getSuccessToastMessage(response));
      if (onSuccess) onSuccess();
    }
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const freezeCard = createAsyncThunk(
  "cards/freezeCards",
  async (
    {
      cardId,
      payload = {},
      onSuccess,
      showToaster = true,
      showErrorToaster = true,
    },
    { dispatch }
  ) => {
    dispatch(setIsFormSubmissionProgress(true));
    setIsCardFreezePending(true);

    setIsCardOperationProcessing(true);

    const [err, response] = await to(API.Cards.freeze(cardId, payload));
    if (response) {
      if (showToaster) {
        vToast(getSuccessToastMessage(response));
      }

      onSuccess();
      dispatch(updateCard({ cardId, payload: response }));
    } else if (err) {
      if (showErrorToaster) {
        vToast(getErrorToastMessage(err));
      }
    }
    setIsCardFreezePending(false);
    setIsCardOperationProcessing(false);
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const unfreezeCard = createAsyncThunk(
  "cards/unfreezeCards",
  async (
    {
      cardId,
      payload = {},
      onSuccess,
      showToaster = true,
      showErrorToaster = true,
    },
    { dispatch }
  ) => {
    dispatch(setIsFormSubmissionProgress(true));
    setIsCardOperationProcessing(true);

    const [err, response] = await to(API.Cards.unfreeze(cardId, payload));

    if (response) {
      if (showToaster) {
        vToast(getSuccessToastMessage(response));
      }
      onSuccess();
      dispatch(updateCard({ cardId, payload: response }));
    } else if (err) {
      if (showErrorToaster) {
        vToast(getErrorToastMessage(err));
      }
    }
    setIsCardOperationProcessing(false);
    dispatch(setIsFormSubmissionProgress(false));
    return [err, response];
  }
);

export const blockCard = createAsyncThunk(
  "cards/blockCards",
  async (
    {
      cardId,
      payload = {},
      onSuccess,
      showToaster = true,
      showErrorToaster = true,
    },
    { dispatch }
  ) => {
    dispatch(setIsFormSubmissionProgress(true));
    setIsCardOperationProcessing(true);
    const [err, response] = await to(API.Cards.block(cardId, payload));
    if (response) {
      if (showToaster) {
        vToast(getSuccessToastMessage(response));
      }

      onSuccess();
      dispatch(updateCard({ cardId, payload: response }));
    } else if (err) {
      if (showErrorToaster) {
        vToast(getErrorToastMessage(err));
      }
    }
    setIsCardOperationProcessing(false);
    dispatch(setIsFormSubmissionProgress(false));
    return [err, response];
  }
);

export const fetchPaymentInfo = createAsyncThunk(
  "cards/fetchPaymentInfo",
  async (cardId, { dispatch }) => {
    const [err, response] = await to(API.Cards.paymentInfo(cardId));
    dispatch(setFetchedCardDetail(true));
    if (response?.data) {
      dispatch(setPaymentInfo(response?.data));
    }
    return [err, response];
  }
);

export const resetPinUrl = createAsyncThunk(
  "cards/resetPinUrl",
  async ({ cardId, onSuccess }, { dispatch }) => {
    const [err, response] = await to(API.Cards.resetPinUrl(cardId));
    if (response) {
      if (onSuccess) {
        onSuccess();
      }
      dispatch(setResetPinUrl(response?.data));
    }

    if (err) {
      vToast({ title: "Failed to fetch Reset Pin Url", variant: "danger" });
    }
  }
);

export const fetchCardBudgets = createAsyncThunk(
  "cards/fetchCardBudgets",
  async (params, { dispatch }) => {
    dispatch(setState({ key: "isFetchingCardBudgets", value: true }));
    const [err, response] = await to(API.CardBudgets.all(params));
    if (response) {
      dispatch(setCardBudgets(response));
    }
    dispatch(setState({ key: "isFetchingCardBudgets", value: false }));
  }
);

export const getPoolCardDetails = createAsyncThunk(
  "cards/poolCardDetails",
  async (props, { dispatch }) => {
    const [err, response] = await to(API.Cards.poolCardDetails());
    dispatch(setPoolCardDetails(response));
  }
);
export const updateCardLimit = createAsyncThunk(
  "cards/updateCardLimit",
  async (params, { dispatch }) => {
    let payload = params?.payload;
    const onSuccess = params?.onSuccess;

    dispatch(setIsFormSubmissionProgress(true));
    dispatch(setIsLimitChanged(false));
    dispatch(setInProgressLimitChange(true));
    payload = Object.keys(payload).reduce((acc, key) => {
      const snakeKey = camelToSnake(key);
      acc[snakeKey] = payload[key];
      return acc;
    }, {});

    const [err, response] = await to(API.Cards.updateCardLimit(payload));

    if (response) {
      dispatch(updateLimitSelectedCard(response));
      dispatch(setIsLimitChanged(true));

      if (onSuccess) {
        onSuccess();
      }
    } else {
      dispatch(setIsLimitChanged(false));
    }

    vToast({
      title: err
        ? "errorMessage.failedLimitChangeReq"
        : "successfulMessage.successfullLimitChange",
      description: err ? "errorMessage.failedLimitChangeReqDesc" : "",
      variant: err ? "danger" : "success",
    });
    dispatch(setInProgressLimitChange(false));
    dispatch(setIsFormSubmissionProgress(false));
  }
);
export const getMerchantlist = createAsyncThunk(
  "cards/getMerchantlist",
  async (payload, { dispatch }) => {
    dispatch(setIsFetchingMerchantsList(true));

    const [err, response] = await to(API.Cards.getMerchantList(payload));

    if (response?.data) {
      if (response?.data?.page > 1) {
        dispatch(appendToMerchantsList(response?.data?.list));
      } else {
        dispatch(setMerchantList(response?.data?.list));
      }

      dispatch(
        setMerchantsListConfig({
          page: response?.data?.page,
          limit: response?.data?.limit,
          total: response?.data?.total,
        })
      );
    } else {
      vToast({ title: "Failed to fetch Merchant List", variant: "danger" });
    }
    dispatch(setIsFetchingMerchantsList(false));
  }
);

export const getMerchantShallow = createAsyncThunk(
  "cards/getMerchantShallow",
  async (payload, { dispatch }) => {
    dispatch(setIsFetchingShallowMerchant(true));
    const [err, response] = await to(
      API.Cards.getMerchantList({ ...payload, shallow: true })
    );
    if (err) {
      vToast(getErrorToastMessage(err));
    } else if (response) {
      dispatch(setShallowMerchantList(response?.data));
    }
    dispatch(setIsFetchingShallowMerchant(false));
  }
);

export const fetchMerchantDetails = createAsyncThunk(
  "cards/merchnatInfo",
  async (params, { dispatch }) => {
    const [error, response] = await to(API.Cards.getMerchantDetails(params));
    if (response) {
      dispatch(setSelectedMerchantDetails(response?.data));
    } else {
      vToast(getErrorToastMessage(error));
    }
  }
);

export const updateMerchant = createAsyncThunk(
  "cards/updateMerchant",
  async ({ payload = {}, onSuccess = null }, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const [err, response] = await to(
      API.Cards.updateMerchant(payload?.id, payload)
    );

    if (response && onSuccess) {
      onSuccess();
    }
    vToast(
      response ? getSuccessToastMessage(response) : getErrorToastMessage(err)
    );
    dispatch(setIsFormSubmissionProgress(false));
    return [err, response];
  }
);

export const changeLinkedTo = createAsyncThunk(
  "cards/card-budgets",
  async (payload, { dispatch }) => {
    const [err, response] = await to(API.Expenses.update(payload?.id, payload));
    if (response) {
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const getProjectList = createAsyncThunk(
  "cards/card-budgets",
  async (params, { dispatch }) => {
    const [err, response] = await to(API.Cards.getProjectList(params));
    if (response.data) {
      dispatch(setProjectList(response.data));
    }
  }
);

export const getPhysicalCardActivationSections = createAsyncThunk(
  "cards/physicalCardActivationFields",
  async (params, { dispatch }) => {
    const [err, response] = await to(
      API.Cards.physicalCardsActivationFormFields(params)
    );
    if (response?.data) {
      dispatch(setPhysicalCardActivationFormFields(response?.data));
    }
    if (err) {
      vToast({ title: "Failed to fetch Virtual Card Fields" });
    }
  }
);

export const getRequestApprovalInfo = createAsyncThunk(
  "cards/cardOrderRequestInfo",
  async (params, { dispatch }) => {
    dispatch(setRequestApprovalInfoInprogress(true));
    const [err, response] = await to(API.Cards.getCardRequest(params));
    if (response?.data) {
      dispatch(setReqeustApprovalInfo(response?.data));
    }
    dispatch(setRequestApprovalInfoInprogress(false));
  }
);

export const getExpenseReviewRange = createAsyncThunk(
  "cards/expense-review/ranges",
  async (params, { dispatch }) => {
    const [err, response] = await to(API.ApprovalPolicy.getPolicy(params));
    if (response.data) dispatch(setExpenseReviewRange(response.data.policies));
    else vToast(getErrorToastMessage(err));
  }
);

export const updateExpenseReviewRangeDetails = createAsyncThunk(
  "cards/updateExpenseReviewRangeDetails",
  async (payload, { dispatch }) => {
    const [err, response] = await to(
      API.ApprovalPolicy.upsertPolicyGroup(payload)
    );
    dispatch(updateExpenseReviewRange(response));
  }
);

export const bulkOperationCardRequest = createAsyncThunk(
  "cards/bulkOperationCardRequest",
  async (payload, { dispatch }) => {
    dispatch(setRequestApprovalInfoInprogress(true));
    const { onSuccess, ...rest } = payload;
    const [err, response] = await to(API.Cards.bulkOperation(rest));
    if (response) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) onSuccess();
    }
    if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setRequestApprovalInfoInprogress(false));
  }
);

export const changeCardLinkedTo = createAsyncThunk(
  "cards/cardLinkedTo",
  async (params, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    dispatch(setIsLinkedToInprogress(true));
    dispatch(setIsLinkedToChanged(false));
    const [err, response] = await to(API.Cards.changeLinkedTo(params));
    if (response) {
      dispatch(setIsLinkedToInprogress(false));
      dispatch(setIsLinkedToChanged(true));
      vToast({ title: "Project switching is sucessfull" });
    }
    if (err) {
      vToast({ title: "Failed to fetch response " });
    }
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const createExpenseAlert = createAsyncThunk(
  "cards/createExpenseRule",
  async ({ payload = {}, onSuccess = () => {} }, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const [err, response] = await to(API.Alert.create(payload));
    if (response) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) {
        onSuccess();
      }
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const getMerchantRestrictions = createAsyncThunk(
  "cards/getMerchantRestrictions",
  async (args, { dispatch }) => {
    const { params = null } = args;
    dispatch(setIsFethingMerchantRestrictions(true));

    const [err, response] = await to(
      API.Cards.getMerchantRestrictionDetails(params)
    );

    if (response?.data) {
      dispatch(setMerchantRestrictions(response?.data));
    }

    if (err) {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsFethingMerchantRestrictions(false));
  }
);

export const updateMerchantRestrictions = createAsyncThunk(
  "cards/updateMerhantRestrictions",
  async (args, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const { payload, id, onSuccess = null, onFail } = args;

    const [err, response] = await to(
      API.Cards.updateMerchantRestrictionDetails(payload, id)
    );

    if (response && onSuccess) {
      onSuccess(response);
    } else {
      onFail(err);
    }
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const createMerchantRestrictions = createAsyncThunk(
  "cards/createMerhantRestrictions",
  async (args, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const { payload, onSuccess = null, onFail = null } = args;

    const [err, response] = await to(
      API.Cards.createMerchantRestrictions(payload)
    );

    if (response && onSuccess) {
      onSuccess(response);
    } else {
      onFail(err);
    }
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const updateExpenseAlert = createAsyncThunk(
  "cards/updateExpenseRule",
  async ({ payload = {}, onSuccess = null }, { dispatch }) => {
    const [err, response] = await to(API.Alert.update(payload?.id, payload));
    if (response) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) {
        onSuccess();
      }
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const updateFlagRule = createAsyncThunk(
  "cards/updateExpenseRule",
  async ({ payload = {}, onSuccess = null }, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    const [err, response] = await to(API.Alert.update(payload?.id, payload));
    if (response) {
      vToast(getSuccessToastMessage(response));
      if (onSuccess) {
        onSuccess();
      }
    } else if (err) {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const deleteExpenseAlert = createAsyncThunk(
  "cards/deleteExpenseRule",
  async (id, { dispatch }) => {
    const [err, response] = await to(API.Alert.delete(id));
    if (response) {
      vToast(getSuccessToastMessage(response));
      dispatch(deleteExpenseAlerts(id));
    } else if (err) {
      vToast(getErrorToastMessage(err));
      return err;
    }
    return response;
  }
);

export const deleteFlagRule = createAsyncThunk(
  "cards/deleteExpenseRule",
  async (id, { dispatch }) => {
    const [err, response] = await to(API.Alert.delete(id));
    if (response) {
      vToast(getSuccessToastMessage(response));
      dispatch(deleteFlagRules(id));
    } else if (err) {
      vToast(getErrorToastMessage(err));
      return err;
    }
    return response;
  }
);

export const getAlerts = createAsyncThunk(
  "cards/getAlerts",
  async (payload, { dispatch }) => {
    dispatch(setIsFetchingExpenseAlerts(true));
    const [err, response] = await to(API.Alert.all(payload));
    if (response.data) {
      dispatch(setExpenseAlerts(response.data));
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsFetchingExpenseAlerts(false));
  }
);

export const getFlagRules = createAsyncThunk(
  "cards/getFlagRules",
  async (payload, { dispatch }) => {
    dispatch(setIsFetchingFlagRules(true));
    const [err, response] = await to(API.Alert.all(payload));
    if (response.data) {
      dispatch(setFlagRules(response.data));
    } else {
      vToast(getErrorToastMessage(err));
    }

    dispatch(setIsFetchingFlagRules(false));
  }
);

export const fetchCardControls = createAsyncThunk(
  "cards/getCardControls",
  async (cardId, { dispatch }) => {
    dispatch(setIsFetchingCardControls(true));

    const [err, response] = await to(API.Cards.getCardChannels(cardId));

    if (response?.data) {
      dispatch(setCardControlsInfo(response?.data?.cardChannels));
    }

    dispatch(setIsFetchingCardControls(false));
  }
);

export const updateCardSettingsInfo = createAsyncThunk(
  "cards/getCardControls",
  async ({ id, payload, onSuccess }, { dispatch }) => {
    dispatch(setIsFormSubmissionProgress(true));
    dispatch(setIsCardSettingsUpdating(true));

    const [err, response] = await to(
      API.Cards.updateSettingsInfo({ id, payload })
    );
    if (response?.data) {
      onSuccess();
      vToast(getSuccessToastMessage(response));
    } else {
      vToast(getErrorToastMessage(err));
    }
    dispatch(setIsCardSettingsUpdating(false));
    dispatch(setIsFormSubmissionProgress(false));
  }
);

export const fetchCardRequestTableInfo = createAsyncThunk(
  "cards/fetchRequestTableInfo",
  async (params, { dispatch }) => {
    dispatch(setIsFetchingCardRequestTableInfo(true));

    const [error, response] = await to(API.Cards.getCardRequestList(params));

    if (response?.data) {
      if (response?.data?.page === 1) {
        dispatch(setCardRequestTableList(response?.data?.list));
      } else {
        dispatch(appendCardRequestTableList(response?.data?.list));
      }

      dispatch(setCardRequestTotalCount(response?.data?.total));
      dispatch(setCardRequestPage(response?.data?.page));
      dispatch(setCardRequestLimit(response?.data?.limit));
      dispatch(setCardRequestTableListHasMore(response?.data?.hasMore));
    } else {
      vToast(getErrorToastMessage(error));
    }
    dispatch(setIsFetchingCardRequestTableInfo(false));
  }
);

export const fetchCardActionGuidelinesVideos = createAsyncThunk(
  "cards/fetchCardActionGuidelinesVideos",
  async (params, { dispatch }) => {
    const [err, response] = await to(API.Cards.getCardActionsGuidelineVideos());
    if (response?.data) {
      dispatch(setCardActionsGuidelineVideos(response?.data));
    } else {
      vToast(getErrorToastMessage(err));
    }
  }
);

export const {
  setCards,
  setCardsList,
  resetCardsListAndPagination,
  setState,
  setSelectedCard,
  setPhysicalCard,
  setPhysicalCardBudgets,
  setCardBudgets,
  setAvailableToSpend,
  setTotalSpent,
  setCardBudgetAvailableToSpend,
  setFetchedCardDetail,
  setCardPageConfig,
  updateLimitSelectedCard,
  updateCardAvailableToSpend,
  updateCardBudgetAvailableToSpend,
  updateCard,
  updateCardInCards,
  updateCurrentCard,
  updateCardBudgetInStore,
  addCard,
  appendToCardsList,
  resetPhysicalCardBudgets,
  resetCard,
  setCreateVirtualCardInput,
  resetCreateVirtualCardInput,
  setControlType,
  setSelectedValues,
  setMerchantType,
  setMerchantSelectedValues,
  setFlagRules,
  addFlagRules,
  deleteFlagRules,
  updateFlagRules,
  addExpenseAlerts,
  setExpenseAlerts,
  deleteExpenseAlerts,
  updateExpenseAlerts,
  deleteSelectedValue,
  setIsFetchingCards,
  setIsFetchingCard,
  setIsCardFreezePending,
  setEditedLimitData,
  resetEditedLimitData,
  setOrderPhysicalCardFormData,
  setPaymentInfo,
  setPoolCardDetails,
  setProjectList,
  setPhysicalCardActivationFormFields,
  setPhysicalCardsActivationFormData,
  resetPhysicalCardsActivationFormData,
  mergePhysicalCardActivationFormData,
  setResetPinUrl,
  setPhysicalCardActivationFieldsStep,
  setExpenseReviewRange,
  updateExpenseReviewRange,
  setSelectedTabForCardSpecificRequestSliders,
  setReqeustApprovalInfo,
  setRequestApprovalInfoInprogress,
  setIsCardOperationProcessing,
  appendToCardsListOfUser,
  setCardsListOfUser,
  setCardPageConfigOfUser,
  setIsFetchingPeopleCardsList,
  setExpenseReviewLayersDisableStatus,
  setActivateCardStatus,
  setInProgressActivateCard,
  resetActivateCardConfig,
  resetPhysicalCardsActivationFields,
  resetSliderInfo,
  setIsFetchingVirtualCardSteps,
  setVirtualCardsSteps,
  setOrderCardStatus,
  setBulkOperationInPending,
  setIsLimitChanged,
  setInProgressLimitChange,
  setIsPinReset,
  setIsLinkedToChanged,
  setIsLinkedToInprogress,
  setMerchantRestrictions,
  setCardSettingsFormInfo,
  setCardControlsInfo,
  setIsFetchingCardControls,
  resetCardSettingsFormInfo,
  setIsCardSettingsUpdating,
  setIsFethingMerchantRestrictions,
  setCardSliderTab,
  setShallowMerchantList,
  setIsFetchingShallowMerchant,
  setMerchantList,
  setIsFetchingMerchantsList,
  setMerchantsListConfig,
  appendToMerchantsList,
  setGlobalSettingsMccInfo,
  addGlobalSettingsFields,
  setIsFetchingCardRequestTableInfo,
  setCardRequestTableList,
  appendCardRequestTableList,
  setCardRequestTotalCount,
  setCardRequestPage,
  setCardRequestLimit,
  setCardRequestTableListHasMore,
  resetCardRequestTableInfo,
  setIsFetchingFlagRules,
  setIsFetchingExpenseAlerts,
  resetMerchantListInfo,
  setSelectedMerchantDetails,
  resetOrderPhysicalCardFormData,
  setCardActionsGuidelineVideos,
} = cardSlice.actions;

export default cardSlice.reducer;
