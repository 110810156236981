import "@/components/common/BillPayAndPayroll/style.scss";

import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { addComments, fetchComments } from "@/store/reducers/comments";
import { setUploadedFiles } from "@/store/reducers/ocr-results";
import {
  fetchAndSelectPayment,
  fetchAndSelectPaymentApproval,
  fetchAndSelectPayrollApproval,
  fetchLineItems,
  setLineItems,
  setSelectedPayment,
} from "@/store/reducers/payments";
import {
  fetchDuplicateBill,
  setDuplicateBill,
} from "@/store/reducers/purchase-bills";
import { fetchTags } from "@/store/reducers/tags";
import { setSelectedVendor } from "@/store/reducers/vendors";

import {
  commentsHasMoreSelector,
  commentsListSelector,
} from "@/store/selectors/comments";
import {
  isPaymentFetchingSelector,
  selectedPaymentSelector,
} from "@/store/selectors/payments";
import { isFetchingTagsSelector } from "@/store/selectors/tags";

import Tabs from "@/components/core/Tabs";

import { generatePayloadFromFormValue } from "@/components/GenericForm/common";
import OverviewTab from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/OverviewTab";
import PaymentSliderFooter from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/PaymentSliderFooter";
import PaymentSliderHead from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/PaymentSliderHead";
import ModuleHistory from "@/components/common/ModuleHistory";

import { BILL_RESPONSE_KEYS } from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Inbox/Create/common/enums";
import {
  COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
  PAGE_TYPE,
  PAYMENT_OWNER_TYPE_FOR_COMMENT_PAYLOAD,
  PAYMENT_STATUSES,
} from "@/utils/constants/payments";
import { COMMENT_TYPE, COMMENT_TYPE_PAYLOAD } from "@/utils/constants/comments";
import { getDateInPattern } from "@/utils/common";

import { OWNER_TYPE_MASTER_HISTORY } from "@/utils/constants/app";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

import { MODULE_REQUEST_TYPES } from "@/constants/company";

// Used by Billpay Approval, Payment and Paid pages
export default function PaymentSlider({
  context,
  isAccounting,
  closeDrawer,
  setOnClose,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;

  const payment = useSelector(selectedPaymentSelector);

  const paymentFiles = useMemo(
    () =>
      payment?.[
        inPayrollContext
          ? BILL_RESPONSE_KEYS.PAYSLIPS
          : BILL_RESPONSE_KEYS.INVOICES
      ],
    [payment]
  );
  const isFetching = useSelector(isPaymentFetchingSelector);
  const draftId = payment?.owner?.id || payment?.id; // inbox, approval objects have direct id. payment, paid store draft id in `owner`.
  const onPaidPage = payment?.transactionStatus === PAYMENT_STATUSES.paid; // to avoid duplicate bill message
  const isFetchingTags = useSelector(isFetchingTagsSelector);

  const sliderId = searchParams
    .getAll(
      inPayrollContext
        ? SLIDERS_SEARCH_PARAMS.payments.payrollSalaryId
        : SLIDERS_SEARCH_PARAMS.payments.id
    )
    .at(-1);
  const sliderType = searchParams
    .getAll(SLIDERS_SEARCH_PARAMS.payments.sliderType)
    .at(-1);
  const pageType = searchParams
    .getAll(SLIDERS_SEARCH_PARAMS.payments.pageType)
    .at(-1);

  const [pageNum, setPageNum] = useState(1);

  // comments
  const commentsList = useSelector(commentsListSelector);
  const commentHasMore = useSelector(commentsHasMoreSelector);

  const tabs = [
    {
      name: "cards.cardDetails.overview.navigationLabel",
      count: null,
      key: 1,
    },
    {
      name: "cards.cardDetails.overview.history.navigationLabel",
      count: null,
      key: 2,
      showComingSoon: true,
    },
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const createNewComment = (formData) => {
    formData.append(
      "owner_type",
      inPayrollContext
        ? PAYMENT_OWNER_TYPE_FOR_COMMENT_PAYLOAD.PAYROLL
        : PAYMENT_OWNER_TYPE_FOR_COMMENT_PAYLOAD.PURCHASE_BILL
    );

    formData.append("owner_id", payment?.owner?.id ?? payment?.id);

    dispatch(
      addComments({
        payload: formData,
        onSuccess: () => {
          setPageNum(1);
          dispatch(
            fetchComments({
              type: inPayrollContext
                ? COMMENT_TYPE_PAYLOAD.PAYROLL
                : COMMENT_TYPE_PAYLOAD.PURCHASEBILL,
              id: payment?.owner?.id ?? payment?.id,
              comment_type: COMMENT_TYPE.USER_CREATED,
              page: 1,
              limit: 5,
            })
          );
        },
      })
    );
  };

  const loadMoreComments = () => {
    setPageNum((prev) => prev + 1);
    dispatch(
      fetchComments({
        type: inPayrollContext
          ? COMMENT_TYPE_PAYLOAD.PAYROLL
          : COMMENT_TYPE_PAYLOAD.PURCHASEBILL,
        id: payment?.owner?.id ?? payment?.id,
        comment_type: COMMENT_TYPE.USER_CREATED,
        page: pageNum + 1,
        limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
      })
    );
  };

  useEffect(() => {
    if (!sliderId) return;

    if (sliderType === PAGE_TYPE.approvals) {
      if (context === BILL_PAYROLL_CONTEXT.BILLPAY) {
        dispatch(
          fetchAndSelectPaymentApproval({
            id: sliderId,
          })
        );
      }
      if (context === BILL_PAYROLL_CONTEXT.PAYROLL) {
        dispatch(
          fetchAndSelectPayrollApproval({
            id: sliderId,
          })
        );
      }
    } else
      dispatch(
        fetchAndSelectPayment({
          id: sliderId,
          payroll: context === BILL_PAYROLL_CONTEXT.PAYROLL,
        })
      );

    return () => {
      dispatch(setSelectedPayment(null));
    };
  }, [sliderId]);
  //
  useEffect(() => {
    if (draftId) dispatch(fetchLineItems({ id: draftId, context }));

    return () => {
      if (draftId) dispatch(setLineItems([]));
    };
  }, [draftId]);
  //
  useEffect(() => {
    if (paymentFiles?.length) dispatch(setUploadedFiles(paymentFiles));

    return () => {
      if (paymentFiles?.length) dispatch(setUploadedFiles([]));
    };
  }, [paymentFiles]);
  useEffect(() => {
    if (!isFetchingTags) dispatch(fetchTags());
  }, []);

  // duplicate check
  const duplicateBillParams = inPayrollContext
    ? {
        vendor_id: payment?.vendor?.id,
        payroll_month: getDateInPattern(payment?.payrollMonth),
        amount: payment?.quote?.toAmount,
        currency: payment?.quote?.toCurrency,
        current_id: draftId,
      }
    : {
        vendor_id: payment?.vendor?.id,
        invoice_date: getDateInPattern(payment?.invoiceDate),
        invoice_number: payment?.invoiceNumber,
        amount: payment?.quote?.toAmount,
        currency: payment?.quote?.toCurrency,
        current_id: draftId,
      };

  const slider = useRef();

  useEffect(() => {
    if (onPaidPage) return; // alert is now shown at paid page stage
    if (Object.values(duplicateBillParams).every((item) => !!item))
      // avoid early/blank calls
      dispatch(
        fetchDuplicateBill({
          context,
          ...generatePayloadFromFormValue(duplicateBillParams),
        })
      );
    return () => dispatch(setDuplicateBill(null));
  }, [onPaidPage, JSON.stringify(duplicateBillParams)]);

  useEffect(() => {
    const paymentIdForCommentPayload = payment?.owner?.id ?? payment?.id;

    if (paymentIdForCommentPayload)
      dispatch(
        fetchComments({
          type: inPayrollContext
            ? COMMENT_TYPE_PAYLOAD.PAYROLL
            : COMMENT_TYPE_PAYLOAD.PURCHASEBILL,
          id: paymentIdForCommentPayload,
          comment_type: COMMENT_TYPE.USER_CREATED,
          page: 1,
          limit: COMMENTS_PAGINATION_LIMIT_PER_REQUEST,
        })
      );
  }, [payment, sliderType]);

  const scrollToComments = () => {
    slider.current.scrollTop = slider.current.scrollHeight;
  };

  useEffect(() => {
    setOnClose((searchParamsArray) => {
      if (!searchParamsArray.includes(SLIDERS_SEARCH_PARAMS.payments.id)) {
        dispatch(setSelectedVendor(null));
      }
    });
  }, []);

  if (!isFetching && !payment) return "Error";
  return (
    <>
      <div
        ref={slider}
        className="pb-12 overflow-auto slider-content-core px-9 scroll-smooth"
      >
        {payment?.vendor?.name ? (
          <PaymentSliderHead
            text={
              inPayrollContext
                ? t("billPay.bill.common.personsSalaryPayment", {
                    name: payment?.vendor?.name,
                  })
                : payment?.vendor?.name
            }
            paymentAmount={payment?.quote?.totalAmount}
            paymentDate={payment?.paymentDate}
            paymentOnApprove={payment?.paymentOnApprove}
            context={context}
          />
        ) : null}
        <div className="mt-6">
          <Tabs
            items={tabs}
            selectedTab={selectedTab?.key}
            setCurrentTab={(tab) => setSelectedTab(tab)}
            mode
          />
        </div>
        <div className="mt-8">
          {selectedTab.key === 1 ? (
            <OverviewTab
              context={context}
              payment={payment}
              isFetching={isFetching}
              isAccounting={isAccounting}
              createNewComment={createNewComment}
              commentsList={commentsList}
              showLoadMoreCta={commentHasMore}
              loadMoreComments={loadMoreComments}
            />
          ) : (
            <ModuleHistory
              ownerId={payment?.id}
              ownerType={
                payment?.requestType === MODULE_REQUEST_TYPES.BILL_PAY_REQUEST
                  ? OWNER_TYPE_MASTER_HISTORY.PURCHASE_BILL
                  : OWNER_TYPE_MASTER_HISTORY.PAYMENT
              }
            />
          )}
        </div>
      </div>
      {selectedTab.key === 1 ? (
        <div className="px-6 py-4 slider-footer">
          <PaymentSliderFooter
            scrollToComments={scrollToComments}
            context={context}
            payment={payment}
            tabType={pageType}
            closeDrawer={closeDrawer}
          />
        </div>
      ) : null}
    </>
  );
}

PaymentSlider.propTypes = {
  context: PropTypes.string,
  isAccounting: PropTypes.bool,
  closeDrawer: PropTypes.func,
};
