import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import parse from "html-react-parser";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import { getDatetimeBasedOnLapsed } from "@/utils/common";
import {
  API_END_PAGE_TYPE,
  PAGE_TYPE as PAYMENT_PAGE_TYPE,
  REIMBURSEMENT_PAGE_TYPE,
} from "@/utils/constants/reimbursement";

import {
  SLIDERS_SEARCH_PARAMS,
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
} from "@/constants/SearchParams";
import { PAGINATION_PARAM_KEYS } from "@/constants/pagination";
import { MESSAGE_TYPE, MESSAGE_TYPE_KEY_VALUE_MAPPING } from "@/constants/user";

export default function UserNotificationsListingComponent({
  notificationsData = [],
  hasMore,
  handleRefChange,
  shaddedNotificationIds,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  function handleClick(type, id) {
    switch (type) {
      case MESSAGE_TYPE.PROJECT:
        searchParams.append(SLIDERS_SEARCH_PARAMS.company.project.id, id);
        break;
      case MESSAGE_TYPE.DEPARTMENT:
        searchParams.append(SLIDERS_SEARCH_PARAMS.company.department.id, id);
        break;
      case MESSAGE_TYPE.VIRTUAL_CARD_REQUEST:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest
            .virtualCardRequest,
          id
        );
        break;
      case MESSAGE_TYPE.CARD:
        searchParams.append(SLIDERS_SEARCH_PARAMS.cards.id, id);
        break;

      case MESSAGE_TYPE.TOPUP_REQUEST:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardLimitRequest,
          id
        );
        break;

      case MESSAGE_TYPE.PHYSICAL_CARD_REQUEST:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.cards.actionCentre.cardOrderRequest
            .physicalCardRequest,
          id
        );
        break;

      case MESSAGE_TYPE.CLAIM_REQUEST:
        searchParams.append(SLIDERS_SEARCH_PARAMS.reimbursements.approveId, id);
        searchParams.append(PAGINATION_PARAM_KEYS.PAGE, API_END_PAGE_TYPE.all);
        break;

      case MESSAGE_TYPE.PAYROLL_REQUEST:
        searchParams.append(SLIDERS_SEARCH_PARAMS.payments.payrollSalaryId, id);
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.payments.sliderType,
          REIMBURSEMENT_PAGE_TYPE.approvals
        );
        searchParams.append(PAGINATION_PARAM_KEYS.PAGE, API_END_PAGE_TYPE.all);
        break;

      case MESSAGE_TYPE.BILL_PAYMENT_REQUEST:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.payments.sliderType,
          PAYMENT_PAGE_TYPE.approvals
        );
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.payments.pageType,
          API_END_PAGE_TYPE.all
        );
        searchParams.append(
          SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider,
          SLIDERS_SEARCH_PARAMS.payments.id
        );
        searchParams.append(
          SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey,
          SLIDER_LEFT_SIDE_SEARCH_PARAMS.components.bulkUploadPayments
        );
        searchParams.append(
          SLIDER_LEFT_SIDE_SEARCH_PARAMS.leftSideDisabled,
          true
        );
        searchParams.append(SLIDERS_SEARCH_PARAMS.payments.id, id);
        break;

      case MESSAGE_TYPE.EXPENSE_FLAGGED:
        searchParams.append(SLIDERS_SEARCH_PARAMS.expenses.id, id);
        break;

      default:
        break;
    }

    if (searchParams?.size > 0) {
      setSearchParams(searchParams);
    }
  }

  return (
    <div className="py-4">
      {notificationsData?.length
        ? notificationsData?.map((notification, index) => {
            const timeElapsed = getDatetimeBasedOnLapsed(
              notification?.createdAt
            );

            return (
              <div
                className={`p-4 ${
                  shaddedNotificationIds?.includes(notification?.id)
                    ? "bg-neutral-50"
                    : ""
                } ${
                  notificationsData.length - 1 === index ? "mb-8" : ""
                } flex flex-col gap-1 border-b border-neutral-200`}
                key={`user-notifivations-${index}`}
                ref={(ref) => {
                  if (notificationsData.length - 1 === index && hasMore) {
                    handleRefChange(ref);
                  }
                }}
              >
                <div
                  className="flex items-center justify-between cursor-pointer"
                  onClick={() =>
                    handleClick(
                      notification?.owner?.type,
                      notification?.owner?.id
                    )
                  }
                >
                  <div className="flex flex-col w-11/12">
                    <span className="font-semibold">
                      {notification?.content
                        ? parse(notification?.content, {})
                        : null}
                    </span>
                    <div className="flex flex-row gap-1 items">
                      <Text
                        translationKey={timeElapsed}
                        classes="text-xs font-medium text-neutral-400"
                      />

                      {notification?.owner?.type && (
                        <div className="flex items-center">
                          <span className="profile-widget-dot" />

                          <Text
                            translationKey={
                              MESSAGE_TYPE_KEY_VALUE_MAPPING[
                                notification?.owner?.type
                              ]
                            }
                            classes="text-xs font-medium text-expense-management-700 ml-2"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {notification?.owner?.type && (
                    <Icon
                      name="RightArrow"
                      className="w-5 h-5 text-neutral-500"
                    />
                  )}
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
}

UserNotificationsListingComponent.propTypes = {
  notificationsData: PropTypes.object,
  hasMore: PropTypes.bool,
  handleRefChange: PropTypes.func,
  shaddedNotificationIds: PropTypes.array,
};
