import AadharIcon from "./AadharIcon.png";
import ActivationCard from "./ActivationCard.png";
import Airoplane from "./Airoplane.png";
import AppStore from "./AppStore.png";
import AuthLayout from "./AuthLayout.svg";
import AyoConnectCardPreview from "./AyoConnectCardPreview.png";
import CardImage from "./Card_image.png";
import Chip from "./Chip.png";
import CompanyNotFound from "./CompanyNotFound.svg";
import DotShadow from "./DotShadow.png";
import DotShadow2 from "./DotShadow2.png";
import HomePage from "./HomePage.png";
import IPhone13ProShellScreen from "./IPhone13ProShellScreen.png";
import InternationalCardChip from "./InternationalCardChip.png";
import MC from "./MC.png";
import Pan from "./Pan.png";
import PayNOW from "./PayNOW.png";
import PlayStore from "./PlayStore.png";
import QRcode from "./QRcode.png";
import Quickbooks from "./Quickbooks.png";
import VISACorporate1 from "./VISA_Corporate_1.svg";
import VISAPlatinum1 from "./VISA_platinum_1.svg";
import VISAPlatinum2 from "./VISA_platinum_2.svg";
import VolopayCard from "./Volopay_Card.png";
import AadhaarAddressCautionaryMessage from "./aadhaar-address-cautionary-message.png";
import EmptyCardListing from "./emptyCardListing.png";
import EmptyScreen from "./emptyScreen.svg";
import Nium from "./nium.png";

/**
 * All images used in the application (local assets).
 * Usage is
 * ```jsx
 * import { IMAGES } from "@/assets/images";
 * <Image name="AadharIcon" />
 * ```
 *
 * If u add a new image, make sure to add it here.
 */
export const IMAGES = {
  AadharIcon,
  ActivationCard,
  Airoplane,
  AppStore,
  AuthLayout,
  AyoConnectCardPreview,
  CardImage,
  Chip,
  CompanyNotFound,
  DotShadow,
  DotShadow2,
  HomePage,
  IPhone13ProShellScreen,
  InternationalCardChip,
  MC,
  Pan,
  PayNOW,
  PlayStore,
  QRcode,
  Quickbooks,
  VISACorporate1,
  VISAPlatinum1,
  VISAPlatinum2,
  VolopayCard,
  AadhaarAddressCautionaryMessage,
  EmptyCardListing,
  EmptyScreen,
  Nium,
};
