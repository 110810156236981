import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  declineDetails,
  fetchExpenses,
  resetExpensesListAndPagination,
} from "@/store/reducers/expense";
import {
  failureDetails,
  fetchQrPayments,
  resetQrPaymentsListAndPagination,
} from "@/store/reducers/qr-payments";

import { defaultCurrencySelector } from "@/store/selectors/client";
import {
  declineDetailsSelector,
  expensesHasMoreSelector,
  expensesListSelector,
  isDeclineDetailsFetchingSelectors,
  isExpensesFetchingSelector,
} from "@/store/selectors/expense";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  failureDetailsSelector,
  isFetchingQrPayments,
  qrPaymentsHasMoreSelector,
  qrPaymentsListSelector,
} from "@/store/selectors/qr-payments";

import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import CardHolder from "@/components/Cards/CardsTable/CardTableComponents/CardHolder";
import CardUsageType from "@/components/common/QrPayAndExpense/common/Cells/CardUsageType";
import DeclineDetails from "@/components/common/QrPayAndExpense/common/NeedsReview/DeclineDetails";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";
import VirtualizedTable from "@/components/core/VirtualizedTable";
import { convertFilters } from "@/utils/filters";
import {
  SORTING_CATEGORY,
  SORTING_TYPE,
  SORT_KEYS,
} from "@/utils/constants/sorting";
import { FAILURES_TABLE_HEADERS } from "@/utils/constants/qr-payments";
import {
  DECLINES_TABLE_HEADERS,
  DECLINES_TABLE_HEADERS_KEY,
} from "@/utils/constants/expenses";
import { amountToCurrency, dateToString } from "@/utils/common";

import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

function DeclinesList({ viewTransaction, page, filteringParams = {} }) {
  const dispatch = useDispatch();
  const isExpensesPage = page === QRPAY_EXPENSES_CONTEXT.EXPENSES;

  const expenseSelectors = {
    hasMore: useSelector(expensesHasMoreSelector),
    transaction: useSelector(expensesListSelector),
    isFetching: useSelector(isExpensesFetchingSelector),
    declineOrFailureDetailsObj: useSelector(declineDetailsSelector),
    isDeclineOrFailureDetailsFetching: useSelector(
      isDeclineDetailsFetchingSelectors
    ),
    transaction_type: "declined",
    tableHeaders: DECLINES_TABLE_HEADERS,
    supplier: "merchant",
    date: "expenseDate",
  };
  const paymentsSelector = {
    hasMore: useSelector(qrPaymentsHasMoreSelector),
    transaction: useSelector(qrPaymentsListSelector),
    isFetching: useSelector(isFetchingQrPayments),
    declineOrFailureDetailsObj: useSelector(failureDetailsSelector),
    isDeclineOrFailureDetailsFetching: useSelector(failureDetailsSelector),
    transaction_type: "failed",
    tableHeaders: FAILURES_TABLE_HEADERS,
    supplier: "vendor",
    date: "paymentDate",
  };
  const currentSelectors = isExpensesPage ? expenseSelectors : paymentsSelector;

  // common selectors for qrpay and expenses
  const appliedFilters = useSelector(appliedFilterSelector);
  const defaultCurrency = useSelector(defaultCurrencySelector);
  const isEmpty = currentSelectors?.transaction?.length === 0;
  const [sorting, setSorting] = useState({
    type: SORTING_TYPE.DEC,
    category: SORTING_CATEGORY.DATE,
  });

  const getCellComponent = useMemo(
    // eslint-disable-next-line react/display-name
    () => (id, _data, index, tagId, val, _isActionLoader) => {
      switch (id) {
        case DECLINES_TABLE_HEADERS_KEY.DATE:
          return (
            <ProfileWidget
              img={val?.[currentSelectors?.supplier]?.avatarUrl}
              avatarColor={val?.merchant?.avatarColor}
              name={val?.[currentSelectors?.supplier]?.name}
              textClasses="text-sm text-neutral-800 font-semibold"
            >
              <div className="flex items-center text-xs truncate text-neutral-500 gap-0.5">
                <span>{dateToString(val?.[currentSelectors?.date])}</span>
                {val?.[currentSelectors?.date] && val?.projectName ? (
                  <span className="profile-widget-dot text-neutral-500" />
                ) : null}
                <span>{val?.projectName}</span>
              </div>
            </ProfileWidget>
          );
        case DECLINES_TABLE_HEADERS_KEY.AMOUNT:
          return (
            <Text
              noTranslate
              translationKey={amountToCurrency(val?.amount, val?.currency)}
            />
          );
        case DECLINES_TABLE_HEADERS_KEY.CARD:
          return (
            <CardUsageType
              cardName={val?.cardName}
              cardType={val?.cardType}
              cardUsageType={val?.cardUsageType}
              cardNumber={val?.cardNumber}
              cardId={val?.cardId}
            />
          );
        case DECLINES_TABLE_HEADERS_KEY.CARD_HOLDER: {
          const accountHolder = getAccountHolder(val);
          return (
            <CardHolder
              displayName={accountHolder?.displayName}
              role={accountHolder?.roles}
              department={accountHolder?.departmentName}
              location={accountHolder?.locationName}
              showIconComponent={false}
              classes="text-left"
            />
          );
        }
        case DECLINES_TABLE_HEADERS_KEY.DECLINE_REASON:
          return (
            <div className="flex items-center justify-between gap-1">
              <Text
                translationKey={val?.declineDetails?.title}
                toolTipText={val?.declineDetails?.message}
                showTooltip
                id={val?.id}
                classes="text-sm font-semibold"
              />
              {val?.declineDetails?.title ? (
                <>
                  <Tooltip id={`declineMessage-${index}`} direction="bottom">
                    <Text translationKey={val?.declineDetails?.message} />
                  </Tooltip>
                  <div id={`declineMessage-${index}`}>
                    <Icon className="text-danger-400" name="Error" />
                  </div>
                </>
              ) : null}
            </div>
          );
        default:
          return "-";
      }
    },
    [
      JSON.stringify(currentSelectors?.transaction),
      JSON.stringify(currentSelectors),
    ]
  );
  const onReset = () => {
    const resetTransaction = isExpensesPage
      ? resetExpensesListAndPagination
      : resetQrPaymentsListAndPagination;
    dispatch(resetTransaction());
  };
  const loadMore = () => {
    const fetchAction = isExpensesPage ? fetchExpenses : fetchQrPayments;

    const fetchDeclineDetails = isExpensesPage
      ? declineDetails
      : failureDetails;

    dispatch(
      fetchAction({
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        transaction_type: currentSelectors?.transaction_type,
        ...convertFilters(appliedFilters),
        ...filteringParams,
        [SORT_KEYS.COLUMN]: sorting?.category,
        [SORT_KEYS.DIRECTION]: sorting?.type,
      })
    );

    dispatch(fetchDeclineDetails(filteringParams));
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore: currentSelectors?.hasMore,
    loadMore,
    onReset,
    filterOptions: {
      filteringParams,
      ...convertFilters(appliedFilters),
      category: sorting?.category,
      type: sorting?.type,
    },
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll);

  function getAccountHolder(transaction) {
    return isExpensesPage ? transaction.cardHolder : transaction.walletHolder;
  }

  return (
    <>
      <DeclineDetails
        declineOrFailureData={currentSelectors?.declineOrFailureDetailsObj}
        currency={defaultCurrency}
        isFetching={currentSelectors?.isDeclineOrFailureDetailsFetching}
        page={page}
      />
      <div className="mt-6">
        <VirtualizedTable
          showCheckBoxRow={false}
          headerSticky
          colWidths={currentSelectors?.tableHeaders?.map(
            (item) => item?.colWidth
          )}
          onRowClick={(val) => {
            viewTransaction(val?.id);
          }}
          onSortingChange={setSorting}
          getCellComponent={(rowData, headerId, header, isActionLoader) => {
            return getCellComponent(
              headerId,
              rowData?.getValue(),
              rowData?.row?.index,
              header.tagId,
              rowData?.row?.original,
              isActionLoader
            );
          }}
          headerConfig={currentSelectors?.tableHeaders}
          data={currentSelectors?.transaction}
          loading={currentSelectors?.isFetching}
          handleRefChange={(ref, index) => {
            if (
              index === (currentSelectors?.transaction?.length ?? 0) - 1 &&
              currentSelectors?.hasMore
            )
              handleRefChange(ref);
          }}
          emptyDataTitle="expenses.declines.tableEmptyStates.title"
          emptyDataDescription="expenses.declines.tableEmptyStates.description"
        />
      </div>
    </>
  );
}

export default DeclinesList;
DeclinesList.propTypes = {
  viewTransaction: PropTypes.func,
  page: PropTypes.string,
  filteringParams: PropTypes.object,
};
