import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  fetchBillMasterHistory,
  resetBillMasterHistoryStore,
} from "@/store/reducers/purchase-bills";

import {
  billMasterHistorySelector,
  isFetchingBillMasterHistorySelector,
} from "@/store/selectors/purchase-bills";

import SliderLoader from "@/components/core/SliderLoader";
import { getPaymentSliderInfo } from "@/utils/constants/payments";

import {
  SLIDERS_SEARCH_PARAMS,
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
} from "@/constants/SearchParams";

/**
 * @description This component is used to redirect to the payment slider page,
 * once the master history details are received.
 * It will be used in the intermediate page of master history
 * !important : This only for UI purpose so user will not be blocked
 * @param {function} setOnClose - This is a function which will be called when
 * the component is being closed. It will be called with the search params keys
 * which are being removed.
 */
function BillPayMasterHistoryIntermediateSlider({ setOnClose }) {
  const billPayMaster = useSelector(billMasterHistorySelector);
  const isFetching = useSelector(isFetchingBillMasterHistorySelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const id = searchParams.get(
    SLIDERS_SEARCH_PARAMS.masterHistoryIntermediateSlider
  );

  useEffect(() => {
    if (id) dispatch(fetchBillMasterHistory({ id }));
  }, [id]);
  useEffect(() => {
    setOnClose((searchParamsKeys) => {
      if (
        !searchParamsKeys.includes(
          SLIDERS_SEARCH_PARAMS.masterHistoryIntermediateSlider
        )
      ) {
        dispatch(resetBillMasterHistoryStore());
      }
    });
  }, []);
  useEffect(() => {
    if (billPayMaster && !isFetching) {
      const {
        sliderKey,
        sliderValue,
        sliderType,
        leftSideDisabled = false,
      } = getPaymentSliderInfo(billPayMaster, false);
      const builtInSearchParam = [
        {
          key: SLIDER_LEFT_SIDE_SEARCH_PARAMS.leftSideDisabled,
          value: leftSideDisabled,
        },
        {
          key: SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey,
          value: SLIDER_LEFT_SIDE_SEARCH_PARAMS.components.bulkUploadPayments,
        },
        { key: sliderKey, value: sliderValue },
        { key: SLIDERS_SEARCH_PARAMS.payments.sliderType, value: sliderType },
        {
          key: SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider,
          value: sliderKey,
        },
      ];
      console.log({ sliderKey, sliderValue, sliderType });
      builtInSearchParam?.forEach(({ key, value }) =>
        searchParams.append(key, value)
      );
      searchParams.delete(
        SLIDERS_SEARCH_PARAMS.masterHistoryIntermediateSlider
      );
      setSearchParams(searchParams);
    }
  }, [JSON.stringify(billPayMaster)]);
  return <SliderLoader />;
}

export default BillPayMasterHistoryIntermediateSlider;
