// billpay, payroll and reimbursement ctas
import {
  fetchActionCentreTabs,
  fetchRequestTableInfo,
} from "@/store/reducers/actionCentre";
import { closeAppModal, setAppModalData } from "@/store/reducers/app";

import {
  PAYMENTS_SLICE_ATTRIBUTE_KEY,
  approve,
  fetchPaymentApprovals,
  fetchPayrollApprovals,
  markAsPaid,
  payNow,
  removePayment,
  retryPayment,
  setPayment,
  setSelectedPayment,
} from "@/store/reducers/payments";
import {
  archive,
  deleteDraft,
  performActionThunk,
} from "@/store/reducers/purchase-bills";
import {
  approveReimbursement,
  archiveReimbursement,
  deleteReimbursement,
  fetchReimbursements,
  fetchReports,
  skipApprovalReimbursement,
  submitReport,
} from "@/store/reducers/reimbursement";
import {
  BILLPAY_ACTION_PARAMS,
  BILL_PAYROLL_CONTEXT,
} from "@/utils/constants/paymentsStore";
import { VP_MODALS } from "@/utils/constants/app";

import {
  BILLPAY_BILL_CTA_KEYS,
  PAYMENT_SCROLL_TO_KEYS,
  PAYROLL_INBOX_CTA_KEYS,
  REIMBURSEMENT_CTA_KEYS,
  REPORT_CTA_KEYS,
  TAB_TYPES,
} from "@/utils/constants/payments";
import { REPORT_CONTEXT } from "@/utils/constants/myVolopay";
import { BILL_PAYROLL_ACTION_CENTER_PAGE_CONFIG } from "@/constants/BillPayListPayroll";
import {
  SLIDERS_SEARCH_PARAMS,
  SLIDER_LEFT_SIDE_SEARCH_PARAMS,
} from "@/constants/SearchParams";
import { FORM_TYPE } from "@/constants/common";
import { PAYMENT_OPTION_TYPE } from "@/constants/company";
import { APPROVAL_REQUEST_TYPE, APPROVE } from "@/constants/myVolopay";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { ROUTES } from "@/constants/routes";
import { REIMBURSEMENT_CONTEXT } from "@/constants/reimbursement";
import { ACTION_CENTRE_PAGE_CONTEXT } from "@/constants/ActionCentre";

export const BILLPAY_BILL_CTA_CONFIGS = {
  // Configs
  // maximal config = { label, hoverText, icon, type, variant, classes, isPrimary, onClick }
  //
  // 1. main buttons need a color/text config atleast
  // 2. context buttons just need a label and an icon. Anything else will be ignored.
  // 3. for listing (ActionCell), the default 'variant' is 'tertiary' (no bgcolor, only textcolor)
  // 4. for slider(PaymentSliderFooter), the default 'variant' is 'primary' (full bgcolor, white text). 'icon' is ignored in slider
  // 5. isPrimary - if the button can be a primary in the footer
  // 6. onClick - run simple code or run a store action (dispatch is provided).
  //       Try to use only global/util functions here (like vToast), instead of too much feature/context dependent code.
  /*

  `onClick` examples:
  1. Simple code
    onClick = () {

    }

    so, simple code is possible.
    but not encouraged, since we want to handle data interactions in
     Page/Component <--> CTA handlers <-> Store <-> ApiEndpoints file
    way. Otherwise there are one-off Api calls and logic across the app which results in excess calls and are generally hard to build upon.

  2. Through the store (recommended)
    onClick = (params, componentProvidedStuff) {
      const componentProvidedStuff = componentProvidedStuff;
      dispatch(updateVendor(params
        const { dispatch = null, searchParams = null, setSearchParams = null } = componentProvidedStuff;));
    }

    - What is `params`?
      1. In a listing - it's the row data (object). So `params.id` and other things will be available.
      2. In a slider - it's the show data (object). So `params.id` and other things like `status` will be available.

    - `componentProvidedStuff` refers to variables/functions that mutate the UI tree,
        that are mostly accessible inside components. Example - hooks, Redux's dispatch, useSelector etc
        Doing it this way prevents unecessary context clutter in pages/components and also makes the CTA logic reusable.
  */

  [BILLPAY_BILL_CTA_KEYS.review]: {
    label: (inPayrollContext) =>
      inPayrollContext
        ? "billPay.bill.ctasPayroll.review"
        : "billPay.bill.ctas.review",
    icon: "Review",
    type: "success",
    isPrimary: true,
    isSliderPrimary: true,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;

      const inPayrollContext = val.context === BILL_PAYROLL_CONTEXT.PAYROLL;
      const paramName = inPayrollContext
        ? SLIDERS_SEARCH_PARAMS.payrollPayments.reviewSalaryBill
        : SLIDERS_SEARCH_PARAMS.payments.reviewBill;
      const billpayUploadQueryParams = {
        [paramName]: val.id,
        [SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey]:
          SLIDER_LEFT_SIDE_SEARCH_PARAMS.components.bulkUploadPayments,
        [SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider]: paramName,
      };

      Object.entries(billpayUploadQueryParams).forEach(([k, v]) => {
        searchParams.append(k, v);
      });
      setSearchParams(searchParams);
    },
  },
  [BILLPAY_BILL_CTA_KEYS.approve]: {
    label: (inPayrollContext) =>
      inPayrollContext
        ? "billPay.bill.ctasPayroll.approve"
        : "billPay.bill.ctas.approve",
    icon: "CircleCheck",
    type: "success",
    isPrimary: true,
    isSliderPrimary: true,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
        appliedFilters,
        openModal = (modalId) => {},
      } = componentProvidedStuff;
      const context = val?.context;
      // context passing is pending
      const onSuccess = (responseData) => {
        // to verify if this is action page or not
        const isOnActionCentrePagePayroll =
          window.location.pathname ===
          ROUTES.myVolopay.actionCentre.payroll.absolutePath;
        const isOnActionCentrePageBillPay =
          window.location.pathname ===
          ROUTES.myVolopay.actionCentre.billPay.absolutePath;

        const isPayrollPage = val?.context === BILL_PAYROLL_CONTEXT.PAYROLL;

        if (!isPayrollPage) {
          if (val?.tabType === TAB_TYPES.NEEDS_YOUR_APPROVAL) {
            dispatch(removePayment(val?.id));
          } else {
            dispatch(
              fetchPaymentApprovals({
                value: {
                  page: 1,
                  limit: PAGINATION_PER_REQUEST_LIMIT,
                  tab: val.tabType,
                  sort_column: null,
                  sort_direction: null,
                },
                context: val.context,
                key: PAYMENTS_SLICE_ATTRIBUTE_KEY,
                ...(appliedFilters ? appliedFilters : {}),
              })
            );
          }
        } else {
          dispatch(
            fetchPayrollApprovals({
              key: PAYMENTS_SLICE_ATTRIBUTE_KEY,
              value: {
                page: 1,
                limit: PAGINATION_PER_REQUEST_LIMIT,
                tab: val.tabType,
                sort_column: null,
                sort_direction: null,
              },
              context: val.context,
              ...(appliedFilters ? appliedFilters : {}),
            })
          );
        }

        if (isOnActionCentrePageBillPay || isOnActionCentrePagePayroll) {
          dispatch(
            fetchRequestTableInfo({
              limit: PAGINATION_PER_REQUEST_LIMIT,
              page: 1,
              request_type: isOnActionCentrePageBillPay
                ? APPROVAL_REQUEST_TYPE.BILL_PAY
                : APPROVAL_REQUEST_TYPE.PAYROLL,
              user: val?.currentUser?.id,
              ...(appliedFilters ? appliedFilters : {}),
            })
          );

          dispatch(fetchActionCentreTabs());
        }

        if (closeDrawer) {
          closeDrawer();
        }
        dispatch(closeAppModal());
      };

      const commonBillpayPayrollCondition =
        (val?.approverLevels === (val?.currentApprovalLevel ?? 0) + 1 ||
          val?.approvalLevels === (val?.currentApprovalLevel ?? 0) + 1) &&
        val?.paymentDate &&
        new Date(val?.paymentDate).setHours(0, 0, 0, 0) <=
          new Date().setHours(0, 0, 0, 0);

      const billpayContextCondition =
        val?.billAutoPayEnabled &&
        (val?.context === BILL_PAYROLL_CONTEXT.BILLPAY ||
          val?.context === BILL_PAYROLL_CONTEXT.ACTION_CENTER_BILLPAY);

      const payrollContextCondition =
        val?.payrollAutoPayEnabled &&
        (val?.context === BILL_PAYROLL_CONTEXT.PAYROLL ||
          val?.context === BILL_PAYROLL_CONTEXT.ACTION_CENTER_PAYROLL);

      if (
        commonBillpayPayrollCondition &&
        (billpayContextCondition || payrollContextCondition)
      ) {
        dispatch(setAppModalData(val));
        openModal(VP_MODALS.SCHEDULED_PAYMENT);
      } else {
        dispatch(approve({ context: val.context, onSuccess, id: val.id }));
      }
    },
  },
  [BILLPAY_BILL_CTA_KEYS.reject]: {
    label: (inPayrollContext) =>
      inPayrollContext
        ? "billPay.bill.ctasPayroll.reject"
        : "billPay.bill.ctas.reject",
    icon: "",
    type: "danger",
    variant: "tertiary",
    isPrimary: false,
    isSliderPrimary: true,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;

      const rejectSearchParam =
        BILL_PAYROLL_ACTION_CENTER_PAGE_CONFIG[val.context].seacrhParams.reject;

      const pageTypeSearchParam =
        BILL_PAYROLL_ACTION_CENTER_PAGE_CONFIG[val.context].seacrhParams
          .pageType;

      const [tab] = window.location.pathname.split("/").slice(-1);

      searchParams?.append(rejectSearchParam, val?.id);
      searchParams?.append(pageTypeSearchParam, tab);
      setSearchParams(searchParams);
    },
  },
  [BILLPAY_BILL_CTA_KEYS.markAsPaid]: {
    label: (inPayrollContext) =>
      inPayrollContext
        ? "billPay.bill.ctasPayroll.markAsPaid"
        : "billPay.bill.ctas.markAsPaid",
    icon: "",
    type: "success",
    isPrimary: true,
    isSliderPrimary: true,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;
      const context = val?.context;

      const onSuccess = (responseData) => {
        dispatch(removePayment(val?.id));
        if (closeDrawer) closeDrawer();
      };

      dispatch(markAsPaid({ context: val.context, id: val.id, onSuccess }));
    },
  },
  [BILLPAY_BILL_CTA_KEYS.retryPayment]: {
    label: (inPayrollContext) =>
      inPayrollContext
        ? "billPay.bill.ctasPayroll.retryPayment"
        : "billPay.bill.ctas.retryPayment",
    icon: "",
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: true,
    isSliderPrimary: true,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;
      const context = val?.context;

      const onSuccess = (responseData) => {
        // move to 'All', effectively this
        if (val?.tabType === TAB_TYPES.ALL) {
          dispatch(
            setPayment({
              id: responseData?.data?.id,
              value: responseData?.data,
            })
          );
        } else {
          dispatch(removePayment(val?.id));
        }
        if (closeDrawer) closeDrawer();
      };

      dispatch(retryPayment({ context: val.context, id: val.id, onSuccess }));
    },
  },
  [BILLPAY_BILL_CTA_KEYS.payNow]: {
    label: (inPayrollContext) =>
      inPayrollContext
        ? "billPay.bill.ctasPayroll.payNow"
        : "billPay.bill.ctas.payNow",
    icon: "",
    type: "success",
    isPrimary: true,
    isSliderPrimary: true,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;
      const context = val?.context;

      const onSuccess = (responseData) => {
        dispatch(setPayment({ id: responseData?.id, value: responseData }));

        if (closeDrawer) closeDrawer();
      };

      dispatch(
        payNow({ id: val.id, payload: val, context: val.context, onSuccess })
      );
    },
  },
  [BILLPAY_BILL_CTA_KEYS.deleteDraft]: {
    label: (inPayrollContext) =>
      inPayrollContext
        ? "billPay.bill.ctasPayroll.deleteDraft"
        : "billPay.bill.ctas.deleteDraft",
    icon: "",
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: false,
    isSliderPrimary: false,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;

      dispatch(deleteDraft({ context: val.context, id: val.id }));
    },
  },

  // non-atomic actions (i.e. they open a UI)
  [BILLPAY_BILL_CTA_KEYS.addFundsAccount]: {
    label: (inPayrollContext) =>
      inPayrollContext
        ? "billPay.bill.ctasPayroll.addFunds"
        : "billPay.bill.ctas.addFunds",
    icon: "",
    hoverText: (val) => {
      if (val?.isAdmin) return "";

      return val?.context === BILL_PAYROLL_CONTEXT.PAYROLL
        ? "billPay.bill.ctaHoverText.addFundsPayroll"
        : "billPay.bill.ctaHoverText.addFunds"; // show nothing to admins
    },
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: true,
    isSliderPrimary: true,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;

      const inPayrollContext = val.context === BILL_PAYROLL_CONTEXT.PAYROLL;
      if (inPayrollContext) {
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.company.allocate,
          FORM_TYPE.edit
        );
      } else {
        const currency = val?.totalAmount?.currency;

        searchParams.append(SLIDERS_SEARCH_PARAMS.company.currency, currency);
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.company.paymentOption,
          PAYMENT_OPTION_TYPE.ADDFUND
        );
      }
      setSearchParams(searchParams);
    },
  },
  [BILLPAY_BILL_CTA_KEYS.addFundsDepartment]: {
    label: (inPayrollContext) =>
      inPayrollContext
        ? "billPay.bill.ctasPayroll.addFunds"
        : "billPay.bill.ctas.addFunds",
    icon: "",
    hoverText: (val) => {
      if (val?.isAdmin) return "";

      return val?.context === BILL_PAYROLL_CONTEXT.PAYROLL
        ? "billPay.bill.ctaHoverText.addFundsPayroll"
        : "billPay.bill.ctaHoverText.addFunds"; // show nothing to admins
    },
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: true,
    isSliderPrimary: true,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;

      const departmentId = val?.vendor?.owner?.departmentId;
      const budgetId = val?.linkedTo?.budgetId;
      const budgetType = val?.linkedTo?.type;
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.company.department.id,
        departmentId
      );
      searchParams.append(SLIDERS_SEARCH_PARAMS.company.budget.id, budgetId);
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.company.budget.type,
        budgetType
      );
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.company.budget.editBudget.oldDeptBudget,
        budgetId
      );
      searchParams.append(SLIDERS_SEARCH_PARAMS.payments.relatedPayId, val.id);
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.payments.relatedPayContext,
        val?.context
      );
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.payments.relatedPayIsPayment,
        "transactionStatus" in val
      );
      setSearchParams(searchParams);
    },
  },
  [BILLPAY_BILL_CTA_KEYS.addFundsProject]: {
    label: (inPayrollContext) =>
      inPayrollContext
        ? "billPay.bill.ctasPayroll.addFunds"
        : "billPay.bill.ctas.addFunds",
    icon: "",
    hoverText: (val) => {
      if (val?.isAdmin) return "";

      return val?.context === BILL_PAYROLL_CONTEXT.PAYROLL
        ? "billPay.bill.ctaHoverText.addFundsPayroll"
        : "billPay.bill.ctaHoverText.addFunds"; // show nothing to admins
    },
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: true,
    isSliderPrimary: true,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;

      const projectId = val?.vendor?.projectId;
      const budgetId = val?.linkedTo?.budgetId;
      const budgetType = val?.linkedTo?.type;
      searchParams.append(SLIDERS_SEARCH_PARAMS.company.project.id, projectId);
      searchParams.append(SLIDERS_SEARCH_PARAMS.company.budget.id, budgetId);
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.company.budget.type,
        budgetType
      );
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.company.budget.editBudget.oldProjBudget,
        budgetId
      );
      searchParams.append(SLIDERS_SEARCH_PARAMS.payments.relatedPayId, val.id);
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.payments.relatedPayContext,
        val?.context
      );
      searchParams.append(
        SLIDERS_SEARCH_PARAMS.payments.relatedPayIsPayment,
        "transactionStatus" in val
      );
      setSearchParams(searchParams);
      setSearchParams(searchParams);
    },
  },
  [BILLPAY_BILL_CTA_KEYS.addPaymentDate]: {
    label: (inPayrollContext) =>
      inPayrollContext
        ? "billPay.bill.ctasPayroll.addPaymentDate"
        : "billPay.bill.ctas.addPaymentDate",
    icon: "",
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: false,
    isSliderPrimary: false,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;

      const draftId = val?.owner?.id || val?.id; // inbox, approval objects have direct id. payment, paid store draft id in `owner`.

      searchParams.delete(SLIDERS_SEARCH_PARAMS.scrollTo);

      const paramName =
        val.context === BILL_PAYROLL_CONTEXT.PAYROLL
          ? SLIDERS_SEARCH_PARAMS.payrollPayments.editBill
          : SLIDERS_SEARCH_PARAMS.payments.editBill;

      const billpayUploadQueryParams = {
        [paramName]: draftId,
        [SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider]: paramName,
        [SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey]:
          SLIDER_LEFT_SIDE_SEARCH_PARAMS.components.bulkUploadPayments,
        [SLIDER_LEFT_SIDE_SEARCH_PARAMS.leftSideDisabled]: true,
        [SLIDERS_SEARCH_PARAMS.scrollTo]: PAYMENT_SCROLL_TO_KEYS.PAYMENT_DATE,
      };

      Object.entries(billpayUploadQueryParams).forEach(([k, v]) => {
        searchParams.append(k, v);
      });

      setSearchParams(searchParams);
    },
  },
  // left ones
  [BILLPAY_BILL_CTA_KEYS.resync]: {
    label: (inPayrollContext) =>
      inPayrollContext
        ? "billPay.bill.ctasPayroll.resync"
        : "billPay.bill.ctas.resync",
    icon: "",
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: false,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;

      const onSuccess = (responseData) => {
        // resync can happen at approvals or payment pages, this takes care of both
        dispatch(
          setPayment({ id: responseData?.data?.id, value: responseData?.data })
        );
      };
      dispatch(
        performActionThunk({
          context: val?.context,
          id: val?.id,
          onSuccess,
          do: "resync",
        })
      );
    },
  },
  [BILLPAY_BILL_CTA_KEYS.archive]: {
    label: (inPayrollContext) =>
      inPayrollContext
        ? "billPay.bill.ctasPayroll.archive"
        : "billPay.bill.ctas.archive",
    icon: "",
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: false,
    isSliderPrimary: false,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        openModal = (modalId) => {},
        closeModal = () => {},
        closeDrawer,
      } = componentProvidedStuff;
      const context = val?.context;

      if (
        val?.destroySchedulerOnArchive ||
        val?.recurringPayment?.destroySchedulerOnArchive
      ) {
        // open the modal, flow will continue from there
        dispatch(setAppModalData(val));
        openModal(
          context === BILL_PAYROLL_CONTEXT.BILLPAY
            ? VP_MODALS.ARCHIVE_RECURRING_PAYMENT
            : context === BILL_PAYROLL_CONTEXT.PAYROLL
              ? VP_MODALS.ARCHIVE_RECURRING_PAYROLL
              : null
        );
      } else {
        const id = val?.owner?.id ?? val?.id;
        const onSuccess = () => {
          dispatch(removePayment(val?.id));
          if (closeDrawer) closeDrawer();
        };

        dispatch(archive({ context, id, onSuccess }));
      }
    },
  },
  [BILLPAY_BILL_CTA_KEYS.deleteRecurringPayment]: {
    // delete recurring payment/salary payment
    label: (inPayrollContext) =>
      inPayrollContext
        ? "billPay.bill.ctasPayroll.deleteRecurringPayment"
        : "billPay.bill.ctas.deleteRecurringPayment",
    icon: "",
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: false,
    isSliderPrimary: false,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        openModal = (modalId) => {},
        closeModal = () => {},
        closeDrawer,
      } = componentProvidedStuff;
      const context = val?.context;

      // open the modal, flow will continue from there
      dispatch(setAppModalData(val));
      openModal(
        context === BILL_PAYROLL_CONTEXT.BILLPAY
          ? val?.destroySchedulerOnArchive ||
            val?.recurringPayment?.destroySchedulerOnArchive
            ? VP_MODALS.ARCHIVE_RECURRING_PAYMENT
            : VP_MODALS.DELETE_RECURRING_PAYMENT
          : context === BILL_PAYROLL_CONTEXT.PAYROLL
            ? val?.destroySchedulerOnArchive ||
              val?.recurringPayment?.destroySchedulerOnArchive
              ? VP_MODALS.ARCHIVE_RECURRING_PAYROLL
              : VP_MODALS.DELETE_RECURRING_PAYROLL
            : null
      );
    },
  },
  [BILLPAY_BILL_CTA_KEYS.skipApprovals]: {
    label: (inPayrollContext) =>
      inPayrollContext
        ? "billPay.bill.ctasPayroll.skipApprovals"
        : "billPay.bill.ctas.skipApprovals",
    icon: "",
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: false,
    isSliderPrimary: false,
    isShowInAdminGroup: true,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        openModal = (modalId) => {},
        closeDrawer,
        appliedFilters,
      } = componentProvidedStuff;
      // to verify if this is action page or not
      const onSuccess = () => {
        // to verify if this is action page or not
        const isOnActionCentrePagePayroll =
          window.location.pathname ===
          ROUTES.myVolopay.actionCentre.payroll.absolutePath;
        const isOnActionCentrePageBillPay =
          window.location.pathname ===
          ROUTES.myVolopay.actionCentre.billPay.absolutePath;

        const isPayrollPage = val?.context === BILL_PAYROLL_CONTEXT.PAYROLL;

        if (!isPayrollPage) {
          if (val?.tabType === TAB_TYPES.NEEDS_YOUR_APPROVAL) {
            dispatch(removePayment(val?.id));
          } else {
            dispatch(
              fetchPaymentApprovals({
                value: {
                  page: 1,
                  limit: PAGINATION_PER_REQUEST_LIMIT,
                  tab: val.tabType,
                  sort_column: null,
                  sort_direction: null,
                },
                context: val.context,
                key: PAYMENTS_SLICE_ATTRIBUTE_KEY,
                ...(appliedFilters ? appliedFilters : {}),
              })
            );
          }
        } else {
          dispatch(
            fetchPayrollApprovals({
              key: PAYMENTS_SLICE_ATTRIBUTE_KEY,
              value: {
                page: 1,
                limit: PAGINATION_PER_REQUEST_LIMIT,
                tab: val.tabType,
                sort_column: null,
                sort_direction: null,
              },
              context: val.context,
              ...(appliedFilters ? appliedFilters : {}),
            })
          );
        }

        if (isOnActionCentrePageBillPay || isOnActionCentrePagePayroll) {
          dispatch(
            fetchRequestTableInfo({
              limit: PAGINATION_PER_REQUEST_LIMIT,
              page: 1,
              request_type: isOnActionCentrePageBillPay
                ? APPROVAL_REQUEST_TYPE.BILL_PAY
                : APPROVAL_REQUEST_TYPE.PAYROLL,
              user: val?.currentUser?.id,
              ...(appliedFilters ? appliedFilters : {}),
            })
          );

          dispatch(fetchActionCentreTabs());
        }

        if (closeDrawer) {
          closeDrawer();
        }
        dispatch(closeAppModal());
      };

      const commonBillpayPayrollCondition =
        (val?.approverLevels === (val?.currentApprovalLevel ?? 0) + 1 ||
          val?.approvalLevels === (val?.currentApprovalLevel ?? 0) + 1) &&
        val?.paymentDate &&
        new Date(val?.paymentDate).setHours(0, 0, 0, 0) <=
          new Date().setHours(0, 0, 0, 0);

      const billpayContextCondition =
        val?.billAutoPayEnabled &&
        (val?.context === BILL_PAYROLL_CONTEXT.BILLPAY ||
          val?.context === BILL_PAYROLL_CONTEXT.ACTION_CENTER_BILLPAY);

      const payrollContextCondition =
        val?.payrollAutoPayEnabled &&
        (val?.context === BILL_PAYROLL_CONTEXT.PAYROLL ||
          val?.context === BILL_PAYROLL_CONTEXT.ACTION_CENTER_PAYROLL);

      if (
        commonBillpayPayrollCondition &&
        (billpayContextCondition || payrollContextCondition)
      ) {
        dispatch(setAppModalData({ ...val, skipApprovals: true, closeDrawer }));
        openModal(VP_MODALS.SCHEDULED_PAYMENT);
      } else {
        dispatch(
          setAppModalData({ ...val, confirmSkipApproval: true, closeDrawer })
        );
        openModal(VP_MODALS.SKIP_APPROVAL);
      }
    },
  },

  // not mentioned in design
  [BILLPAY_BILL_CTA_KEYS.unSchedulePayment]: {
    label: (inPayrollContext) =>
      inPayrollContext
        ? "billPay.bill.ctasPayroll.unSchedulePayment"
        : "billPay.bill.ctas.unSchedulePayment",
    icon: "",
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: false,
    isSliderPrimary: false,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;
      const context = val?.context;

      const onSuccess = (updatedPayment) => {
        dispatch(
          setPayment({
            id: updatedPayment?.id,
            value: updatedPayment,
          })
        );

        dispatch(setSelectedPayment(updatedPayment));
        if (closeDrawer) closeDrawer();
      };
      //
      const draftId = val?.owner?.id || val?.id; // inbox, approval objects have direct id. payment, paid store draft id in `owner`.
      dispatch(
        performActionThunk({
          id: draftId,
          do: BILLPAY_ACTION_PARAMS.DO.UNSCHEDULE_PAYMENT,
          //
          context: val.context,
          onSuccess,
        })
      );
    },
  },
  [BILLPAY_BILL_CTA_KEYS.recreatePayment]: {
    label: (inPayrollContext) =>
      inPayrollContext
        ? "billPay.bill.ctasPayroll.recreatePayment"
        : "billPay.bill.ctas.recreatePayment",
    icon: "",
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: true,
    isSliderPrimary: true,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;

      const draftId = val?.owner?.id || val?.id; // inbox, approval objects have direct id. payment, paid store draft id in `owner`.
      const inPayrollContext = val.context === BILL_PAYROLL_CONTEXT.PAYROLL;
      const paramName = inPayrollContext
        ? SLIDERS_SEARCH_PARAMS.payrollPayments.reCreateSalaryBill
        : SLIDERS_SEARCH_PARAMS.payments.reCreateBill;

      const billpayUploadQueryParams = {
        [paramName]: draftId,
        [SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider]: paramName,
      };

      Object.entries(billpayUploadQueryParams).forEach(([k, v]) => {
        searchParams.append(k, v);
      });
      setSearchParams(searchParams);
    },
  },
  [BILLPAY_BILL_CTA_KEYS.create]: {
    label: (inPayrollContext) =>
      inPayrollContext
        ? "billPay.bill.ctasPayroll.create"
        : "billPay.bill.ctas.create",
    icon: "",
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: false,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;

      searchParams.append(
        val.context === BILL_PAYROLL_CONTEXT.PAYROLL
          ? SLIDERS_SEARCH_PARAMS.payrollPayments.createSalary
          : SLIDERS_SEARCH_PARAMS.payments.createBillDetails,
        true
      );
      setSearchParams(searchParams);
    },
  },
  [BILLPAY_BILL_CTA_KEYS.edit]: {
    label: (inPayrollContext) =>
      inPayrollContext
        ? "billPay.bill.ctasPayroll.edit"
        : "billPay.bill.ctas.edit",
    icon: "",
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: false,
    isSliderPrimary: false,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;

      const draftId = val?.owner?.id || val?.id; // inbox, approval objects have direct id. payment, paid store draft id in `owner`.
      const inPayrollContext = val.context === BILL_PAYROLL_CONTEXT.PAYROLL;

      // open edit bill and left side document part
      const paramName = inPayrollContext
        ? SLIDERS_SEARCH_PARAMS.payrollPayments.editBill
        : SLIDERS_SEARCH_PARAMS.payments.editBill;
      const billpayUploadQueryParams = {
        [paramName]: draftId,
        [SLIDER_LEFT_SIDE_SEARCH_PARAMS.dependentKeyForRightSlider]: paramName,
        [SLIDER_LEFT_SIDE_SEARCH_PARAMS.rightSideKey]:
          SLIDER_LEFT_SIDE_SEARCH_PARAMS.components.bulkUploadPayments,
      };

      Object.entries(billpayUploadQueryParams).forEach(([k, v]) => {
        searchParams.append(k, v);
      });
      setSearchParams(searchParams);
    },
  },
};

export const REIMBURSEMENT_CTA_CONFIGS = {
  // Configs
  // maximal config = { label, icon, type, variant, classes, isPrimary, onClick }
  //
  // 1. main buttons need a color/text config atleast
  // 2. context buttons just need a label and an icon. Anything else will be ignored.
  // 3. for listing (ActionCell), the default 'variant' is 'tertiary' (no bgcolor, only textcolor)
  // 4. for slider(PaymentSliderFooter), the default 'variant' is 'primary' (full bgcolor, white text). 'icon' is ignored in slider
  // 5. isPrimary - if the button can be a primary in the footer
  // 6. onClick - run simple code or run a store action (dispatch is provided).
  //       Try to use only global/util functions here (like vToast), instead of too much feature/context dependent code.
  /*

  `onClick` examples:
  1. Simple code
    onClick = () {

    }

    so, simple code is possible.
    but not encouraged, since we want to handle data interactions in
     Page/Component <--> CTA handlers <-> Store <-> ApiEndpoints file
    way. Otherwise there are one-off Api calls and logic across the app which results in excess calls and are generally hard to build upon.

  2. Through the store (recommended)
    onClick = (params, componentProvidedStuff) {
      const componentProvidedStuff = componentProvidedStuff;
      dispatch(updateVendor(params
        const { dispatch = null, searchParams = null, setSearchParams = null } = componentProvidedStuff;));
    }

    - What is `params`?
      1. In a listing - it's the row data (object). So `params.id` and other things will be available.
      2. In a slider - it's the show data (object). So `params.id` and other things like `status` will be available.

    - `componentProvidedStuff` refers to variables/functions that mutate the UI tree,
        that are mostly accessible inside components. Example - hooks, Redux's dispatch, useSelector etc
        Doing it this way prevents unecessary context clutter in pages/components and also makes the CTA logic reusable.
  */

  [REIMBURSEMENT_CTA_KEYS.review]: {
    label: "reimbursement.ctas.review",
    icon: "Review",
    type: "success",
    isPrimary: true,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;

      searchParams.append(SLIDERS_SEARCH_PARAMS.reimbursements.review, val.id);
      setSearchParams(searchParams);
    },
  },
  [REIMBURSEMENT_CTA_KEYS.approve]: {
    label: "reimbursement.ctas.approve",
    icon: "CircleCheck",
    type: "success",
    isPrimary: true,
    isSliderPrimary: true,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
        appliedFilters,
      } = componentProvidedStuff;

      const onSuccess = () => {
        const isOnActionCentrePage =
          window.location.pathname ===
          ROUTES.myVolopay.actionCentre.reimbursement.absolutePath;
        if (isOnActionCentrePage) {
          dispatch(
            fetchRequestTableInfo({
              limit: PAGINATION_PER_REQUEST_LIMIT,
              page: 1,
              request_type: APPROVAL_REQUEST_TYPE.REIMBURSEMENT,
              ...(appliedFilters ? appliedFilters : {}),
            })
          );

          dispatch(fetchActionCentreTabs());
        } else {
          const [tab] = window.location.pathname.split("/").slice(-1);
          dispatch(
            fetchReimbursements({
              page: 1,
              limit: PAGINATION_PER_REQUEST_LIMIT,
              tab,
              request_type: APPROVAL_REQUEST_TYPE.REIMBURSEMENT,
              ...(appliedFilters ? appliedFilters : {}),
            })
          );
        }
        searchParams.delete(SLIDERS_SEARCH_PARAMS.reimbursements.approveId);
        setSearchParams(searchParams);
      };

      dispatch(approveReimbursement({ id: val.id, onSuccess }));
    },
  },
  [REIMBURSEMENT_CTA_KEYS.reject]: {
    label: "reimbursement.ctas.reject",
    icon: "",
    type: "danger",
    variant: "tertiary",
    isPrimary: false,
    isSliderPrimary: true,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;

      searchParams.append(SLIDERS_SEARCH_PARAMS.reimbursements.reject, val.id);
      setSearchParams(searchParams);
    },
  },

  [REIMBURSEMENT_CTA_KEYS.deleteDraft]: {
    label: "reimbursement.ctas.deleteDraft",
    icon: "",
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: false,
    isSliderPrimary: false,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        currentUser = null,
        closeDrawer,
        appliedFilters,
      } = componentProvidedStuff;

      dispatch(deleteReimbursement({ id: val.id }));
    },
  },
  [REIMBURSEMENT_CTA_KEYS.archive]: {
    label: "reimbursement.ctas.archive",
    icon: "",
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: false,
    isSliderPrimary: false,
    onClick: (val, componentProvidedStuff) => {
      const { dispatch = null, closeDrawer } = componentProvidedStuff;

      const onSuccess = () => {
        if (closeDrawer) closeDrawer();
      };

      dispatch(archiveReimbursement({ id: val.id, onSuccess }));
    },
  },
  [REIMBURSEMENT_CTA_KEYS.skipApprovals]: {
    label: "reimbursement.ctas.skipApprovals",
    icon: "",
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: false,
    isSliderPrimary: false,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;

      const onSuccess = () => {
        searchParams.delete(SLIDERS_SEARCH_PARAMS.reimbursements.approveId);
        setSearchParams(searchParams);
      };

      dispatch(skipApprovalReimbursement({ id: val.id, onSuccess }));
    },
  },
  [REIMBURSEMENT_CTA_KEYS.create]: {
    label: "reimbursement.ctas.create",
    icon: "",
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: false,
    onClick: (componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;

      searchParams.append(SLIDERS_SEARCH_PARAMS.reimbursements.create, true);
      setSearchParams(searchParams);
    },
  },
  [REIMBURSEMENT_CTA_KEYS.edit]: {
    label: "reimbursement.ctas.edit",
    icon: "",
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: false,
    isSliderPrimary: false,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;

      searchParams.append(
        SLIDERS_SEARCH_PARAMS.reimbursements.editClaim,
        val.id
      );
      setSearchParams(searchParams);
    },
  },
};

export const REPORT_CTA_CONFIGS = {
  [REPORT_CTA_KEYS.review]: {
    label: "reimbursement.ctas.review",
    icon: "Review",
    type: "success",
    isPrimary: true,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;

      searchParams.append(SLIDERS_SEARCH_PARAMS.reports.reviewId, val.id);
      setSearchParams(searchParams);
    },
  },

  [REPORT_CTA_KEYS.deleteDraft]: {
    label: "reimbursement.ctas.deleteDraft",
    icon: "",
    type: "default",
    variant: "tertiary",
    classes: "text-neutral-500",
    isPrimary: false,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        currentUser = null,
        closeDrawer,
      } = componentProvidedStuff;

      const { setShowModal, setDeleteId } = val;
      setShowModal(true);
      setDeleteId(val?.id);
    },
  },

  [REPORT_CTA_KEYS.approve]: {
    label: "reimbursement.ctas.approve",
    icon: "CircleCheck",
    type: "success",
    isPrimary: true,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
        appliedFilters,
      } = componentProvidedStuff;

      const onSuccess = () => {
        const isActionCentre =
          window.location.pathname ===
          ROUTES.myVolopay.actionCentre.reimbursement.absolutePath;

        if (isActionCentre) {
          dispatch(
            fetchRequestTableInfo({
              limit: PAGINATION_PER_REQUEST_LIMIT,
              page: 1,
              request_type: APPROVAL_REQUEST_TYPE.REIMBURSEMENT,
              ...(appliedFilters ? appliedFilters : {}),
            })
          );
        } else {
          const [tab] = window.location.pathname.split("/").slice(-1);
          dispatch(
            fetchReports({
              page: 1,
              limit: PAGINATION_PER_REQUEST_LIMIT,
              tab,
              request_type: APPROVAL_REQUEST_TYPE.REIMBURSEMENT,
              ...(appliedFilters ? appliedFilters : {}),
            })
          );
        }
      };

      const payload = {
        operation: APPROVE,
      };

      dispatch(submitReport({ id: val.id, payload, onSuccess }));
    },
  },
  [REPORT_CTA_KEYS.reject]: {
    label: "reimbursement.ctas.reject",
    icon: "",
    type: "danger",
    variant: "tertiary",
    isPrimary: true,
    onClick: (val, componentProvidedStuff) => {
      const {
        dispatch = null,
        searchParams = null,
        setSearchParams = null,
        closeDrawer,
      } = componentProvidedStuff;

      searchParams.append(SLIDERS_SEARCH_PARAMS.reports.rejectReport, val.id);
      setSearchParams(searchParams);
    },
  },
};

export const PAYROLL_INBOX_CTA_CONFIG = {
  [PAYROLL_INBOX_CTA_KEYS.bulkUpload]: {
    label: "payroll.salaryPayment.payrollInbox.pageOptions.bulkUpload",
    onClick: (val, componentProvidedStuff) => {},
    customComponent: true,
  },
  [PAYROLL_INBOX_CTA_KEYS.create]: {
    label: "payroll.salaryPayment.payrollInbox.pageOptions.addNewEmployee",
    onClick: (val, componentProvidedStuff) => {},
    customComponent: true,
  },
};

export const CTA_CONFIG_PAYMENTS = {
  [REIMBURSEMENT_CONTEXT]: REIMBURSEMENT_CTA_CONFIGS,
  [BILL_PAYROLL_CONTEXT.BILLPAY]: BILLPAY_BILL_CTA_CONFIGS,
  [BILL_PAYROLL_CONTEXT.PAYROLL]: BILLPAY_BILL_CTA_CONFIGS,
  [ACTION_CENTRE_PAGE_CONTEXT.BILL_PAY]: BILLPAY_BILL_CTA_CONFIGS,
  [ACTION_CENTRE_PAGE_CONTEXT.PAYROLL]: BILLPAY_BILL_CTA_CONFIGS,
  [ACTION_CENTRE_PAGE_CONTEXT.REIMBURSEMT]: REIMBURSEMENT_CTA_CONFIGS,
  [REPORT_CONTEXT]: REPORT_CTA_CONFIGS,
};
