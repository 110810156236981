import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchNotificationPreferences } from "@/store/reducers/notification_preferences";

import {
  isFetchingNotificationPreferencesSelector,
  notificationPreferenceListSelector,
} from "@/store/selectors/notification_preferences";

import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";

import NotificationDiscardChangesModal from "@/components/Settings/Modals/NotificationDiscardChangesModal";
import NotificationsSelection from "@/components/Settings/NotificationsSelection";

import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import { NOTIFICATIONS_PREFERENCES_TABS } from "@/constants/settings";

export default function NotificationsPreferencesPage() {
  const [selectedTab, setSelectedTab] = useState(
    NOTIFICATIONS_PREFERENCES_TABS[0]
  );
  const [changesCount, setChangesCount] = useState(0);
  const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);
  const [setActiveTabFunction, setSetActiveTabFunction] = useState();
  const notificationPreferences = useSelector(
    notificationPreferenceListSelector
  );
  const isFetching = useSelector(isFetchingNotificationPreferencesSelector);
  const dispatch = useDispatch();
  const [selectedTabInModal, setSelectedTabInModal] = useState(null);

  useEffect(() => {
    dispatch(fetchNotificationPreferences());
  }, []);

  const hasCompanyPreferences = Boolean(
    notificationPreferences?.companyPreferences?.company?.length > 0 ||
      notificationPreferences?.companyPreferences?.department?.length > 0 ||
      notificationPreferences?.companyPreferences?.project?.length > 0
  );

  const handleTabChange = (tab, setActive) => {
    setSetActiveTabFunction(() => setActive);

    if (changesCount > 0 && tab.key !== selectedTab.key) {
      // If changesCount is greater than 0, show the modal and do not change the tab immediately
      setShowDiscardChangesModal(true);
      setSelectedTabInModal(tab); // Store the selected tab in the modal state
    } else {
      // If changesCount is 0, change the tab immediately
      setSelectedTab(tab);
    }
  };

  useEffect(() => {
    if (setActiveTabFunction) {
      // If changesCount is 0, change the tab immediately
      setActiveTabFunction(selectedTab.key); // Set the active tab using setActive function
    }
  }, [setActiveTabFunction, selectedTab]);

  const handleDiscard = () => {
    setChangesCount(0);
    setShowDiscardChangesModal(false);
    // Check if a tab was stored in the modal state
    if (selectedTabInModal !== null) {
      setActiveTabFunction(selectedTabInModal.key); // Set the active tab using setActive function
      setSelectedTab(selectedTabInModal);
      setSelectedTabInModal(null); // Reset the state variable
    }
  };

  const handleCancel = () => {
    setShowDiscardChangesModal(false);
    // Reset the state variable if a tab was stored in the modal state
    if (selectedTabInModal !== null) {
      setSelectedTabInModal(null);
    }
  };

  return !isFetching ? (
    <div className="px-11">
      <div className="flex flex-col">
        <Text
          translationKey="settings.profile"
          classes="text-sm font-semibold text-neutral-500"
        />
        <Text
          translationKey="settings.manageType.notifications"
          classes="text-neutral-800 text-4xl font-bold"
        />
      </div>
      <div className="mt-9">
        {hasCompanyPreferences ? (
          <Tabs
            items={NOTIFICATIONS_PREFERENCES_TABS}
            selectedTab={selectedTab?.key}
            setCurrentTab={(tab, setActive) => handleTabChange(tab, setActive)}
            classes="!font-semibold"
            mode
          />
        ) : null}

        <NotificationsSelection
          notificationPreferences={
            selectedTab.key === 1
              ? notificationPreferences?.companyPreferences
              : notificationPreferences?.personalPreferences
          }
          tab={selectedTab}
          setChangesCount={setChangesCount}
          changesCount={changesCount}
        />
        {showDiscardChangesModal ? (
          <NotificationDiscardChangesModal
            showModal={showDiscardChangesModal}
            setShowModal={setShowDiscardChangesModal}
            handleDiscard={handleDiscard}
            handleCancel={handleCancel}
          />
        ) : null}
      </div>
    </div>
  ) : (
    <div className="px-5">
      <LoaderSkeleton count={10} size={[100, "full"]} />
    </div>
  );
}
