import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import { fetchActionCentreTabs } from "@/store/reducers/actionCentre";
import { closeAppModal, setIndexApiReload } from "@/store/reducers/app";
import { setExportData } from "@/store/reducers/exports";
import {
  PAYMENTS_SLICE_ATTRIBUTE_KEY,
  approve,
  bulkOperationPayments,
  removePayment,
  setIntialState,
  setPayment,
  setPayments,
} from "@/store/reducers/payments";
import { archive, performActionThunk } from "@/store/reducers/purchase-bills";

import {
  appModalDataSelector,
  appModalSelector,
  indexApiReloadSelector,
} from "@/store/selectors/app";
import {
  billAutoPayEnabledSelector,
  payrollAutoPayEnabledSelector,
} from "@/store/selectors/client";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  approvalsAllBillPayPageFilterKeysSelector,
  approvalsAllPayrollPageFilterKeysSelector,
  approvalsNeedsApprovalPageFilterKeysSelector,
  approvalsPendingPageFilterKeysSelector,
  isApproveActionPendingForIdSelector,
} from "@/store/selectors/payments";
import { isActionPendingForPurchaseBillSelector } from "@/store/selectors/purchase-bills";
import { userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import Icon from "@/components/core/Icon";
import Modal from "@/components/core/Modal";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";

import BulkActionComponent from "@/components/Accounting/Transactions/common/BulkAction";
import ApprovalPendingLoaderHelper from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Approvals/ApprovalPendingLoader/ApprovalPendingLoaderHelper";
import { getApprovalTableDefaultHeader } from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Approvals/tableConfig";
import BillPayFilters from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/BillPayFilters";
import HeaderCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/HeaderCell";
import RadioModal from "@/components/common/RadioModal";
import { convertFilters } from "@/utils/filters";
import { handleExport } from "@/utils/exports";
import { SORT_KEYS } from "@/utils/constants/sorting";
import {
  BILLPAY_ACTION_PARAMS,
  BILL_PAYROLL_CONTEXT,
} from "@/utils/constants/paymentsStore";
import { VP_MODALS } from "@/utils/constants/app";
import { checkIfDateCurrentDate } from "@/utils/common";

import { TAB_TYPES } from "@/utils/constants/payments";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { ROUTES } from "@/constants/routes";
import { TRANSACTION_ACTIONS } from "@/constants/transactions";
import { APPROVE } from "@/constants/myVolopay";
import { EXPORT_PAGE } from "@/constants/exports";
import { PENDING_REQUEST_TABS_KEYS } from "@/constants/company";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { BILL_PAYROLL_ACTION_CENTER_PAGE_CONFIG } from "@/constants/BillPayListPayroll";
import { ACTION_CENTRE_PAGE_CONTEXT } from "@/constants/ActionCentre";

export default function PaymentsApprovalList({
  page,
  tabType, // type of the current tab,(needs your approval, pending ,all)
  openSliderHandler,
  isActionCentrePage,
}) {
  const { t } = useTranslation();
  const reloadIndexApi = useSelector(indexApiReloadSelector);

  const {
    listSelector,
    isFetchingSelector,
    totalSelector,
    resetMethod,
    loadMoreMethod,
    hasMoreSelector,
  } = BILL_PAYROLL_ACTION_CENTER_PAGE_CONFIG[page];

  const filterConfig = {
    [BILL_PAYROLL_CONTEXT.BILLPAY]: {
      [TAB_TYPES.NEEDS_YOUR_APPROVAL]:
        approvalsNeedsApprovalPageFilterKeysSelector,
      [TAB_TYPES.PENDING]: approvalsPendingPageFilterKeysSelector,
      [TAB_TYPES.ALL]: approvalsAllBillPayPageFilterKeysSelector,
    },
    [BILL_PAYROLL_CONTEXT.PAYROLL]: {
      [TAB_TYPES.NEEDS_YOUR_APPROVAL]:
        approvalsNeedsApprovalPageFilterKeysSelector,
      [TAB_TYPES.PENDING]: approvalsPendingPageFilterKeysSelector,
      [TAB_TYPES.ALL]: approvalsAllPayrollPageFilterKeysSelector,
    },
    [ACTION_CENTRE_PAGE_CONTEXT.BILL_PAY]: {
      [TAB_TYPES.NEEDS_YOUR_APPROVAL]:
        approvalsNeedsApprovalPageFilterKeysSelector,
      [TAB_TYPES.PENDING]: approvalsPendingPageFilterKeysSelector,
      [TAB_TYPES.ALL]: approvalsAllBillPayPageFilterKeysSelector,
    },
    [ACTION_CENTRE_PAGE_CONTEXT.PAYROLL]: {
      [TAB_TYPES.NEEDS_YOUR_APPROVAL]:
        approvalsNeedsApprovalPageFilterKeysSelector,
      [TAB_TYPES.PENDING]: approvalsPendingPageFilterKeysSelector,
      [TAB_TYPES.ALL]: approvalsAllPayrollPageFilterKeysSelector,
    },
  };

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const payments = useSelector(listSelector);
  const isFetching = useSelector(isFetchingSelector);
  const totalPayments = useSelector(totalSelector);
  const filters = useSelector(filterConfig[page][tabType]);
  const isEmpty = !payments.length;

  const isActionApprovePending = useSelector(
    (state) =>
      isApproveActionPendingForIdSelector(state) ||
      isActionPendingForPurchaseBillSelector(state)
  );

  const queuedPaymentLength = payments?.filter((reim) => reim?.queued)?.length;
  const allQueued = !payments?.filter((reim) => !reim?.queued)?.length;
  const currentUser = useSelector(userSelector);
  const appliedFilters = useSelector(appliedFilterSelector);

  const appModal = useSelector(appModalSelector);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [deSelectedIds, setDeSelectedIds] = useState([]);
  const [headerSelectAll, setHeaderSelectAll] = useState(false);
  const [bulkSelectAll, setBulkSelectAll] = useState(false);
  const hasMore = useSelector(hasMoreSelector);
  const billAutoPayEnabled = useSelector(billAutoPayEnabledSelector);
  const payrollAutoPayEnabled = useSelector(payrollAutoPayEnabledSelector);
  const appModalData = useSelector(appModalDataSelector);

  const addUserParam = ![
    ACTION_CENTRE_PAGE_CONTEXT.BILL_PAY,
    ACTION_CENTRE_PAGE_CONTEXT.PAYROLL,
  ].includes(page);

  const [sorting, setSorting] = useState({
    type: null,
    category: null,
  });

  const [finalFilter, setFinalFilters] = useState(filters);
  const showRequestHistoryButton =
    page === ACTION_CENTRE_PAGE_CONTEXT.BILL_PAY ||
    page === ACTION_CENTRE_PAGE_CONTEXT.PAYROLL;

  const filterOptions = {
    limit: PAGINATION_PER_REQUEST_LIMIT,
    ...convertFilters(appliedFilters),
  };

  if (addUserParam) {
    filterOptions.user = currentUser?.id;
  }

  const onReset = () => {
    const { func, args } = resetMethod();
    dispatch(func({ ...args }));
  };

  useEffect(() => {
    dispatch(setIntialState({ key: PAYMENTS_SLICE_ATTRIBUTE_KEY }));
  }, [tabType]);

  const loadMore = () => {
    const allPArams = {
      page: pageNum,
      ...convertFilters(appliedFilters),
      [SORT_KEYS.COLUMN]: sorting?.category,
      [SORT_KEYS.DIRECTION]: sorting?.type,
      context: page,
      key: PAYMENTS_SLICE_ATTRIBUTE_KEY,
      value: {
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        tab: tabType,
        ...convertFilters(appliedFilters),
        [SORT_KEYS.COLUMN]: sorting?.category,
        [SORT_KEYS.DIRECTION]: sorting?.type,
      },
      ...filterOptions,
    };

    if (addUserParam) {
      allPArams.user = currentUser?.id;
    }

    const { func, args } = loadMoreMethod(allPArams);
    dispatch(func({ ...args }));
  };
  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore,
    loadMore,
    onReset,
    filterOptions: {
      user: currentUser?.id,
      limit: PAGINATION_PER_REQUEST_LIMIT,
      ...appliedFilters,
      ...sorting,
      reloadIndexApi,
    },
  });

  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };
  const handleRefChange = useInfiniteScroll(onScroll);

  const headerSelected = headerSelectAll; // all selected or else unchecked

  const headerSelectionHandler = (checked) => {
    if (checked) {
      if (bulkSelectAll) {
        setDeSelectedIds([]);
      } else {
        setHeaderSelectAll(true);
        setSelectedPayments(
          payments
            ?.filter(
              (payment) =>
                !(isActionCentrePage ? payment.request.queued : payment.queued)
            )
            ?.map((payment) =>
              isActionCentrePage ? payment.request.id : payment.id
            )
        );
      }
    } else {
      setHeaderSelectAll(false);
      setSelectedPayments([]);
    }
  };

  const rowSelectionHandler = (payment) => {
    if (selectedPayments?.includes(payment?.id)) {
      setSelectedPayments((prev) => prev.filter((id) => id !== payment?.id));
      setHeaderSelectAll(false);
      if (bulkSelectAll) {
        setDeSelectedIds((prev) => [...prev, payment?.id]);
      }
    } else {
      setSelectedPayments((prev) => [...prev, payment?.id]);
      setDeSelectedIds((prev) => prev.filter((id) => id !== payment?.id));
    }
    dispatch(
      setPayment({
        id: payment.id,
        value: {
          ...payment,
          selected: !payment.selected,
        },
      })
    );
  };

  const showApproveCta = payments
    ?.filter((item) => {
      return selectedPayments?.find((data) => data === item.id);
    })
    .map((data) => data?.ctas?.some((val) => val === APPROVE))
    ?.every((value) => value);

  const handleBulkApprove = (action) => {
    const onSuccess = () => {
      setHeaderSelectAll(false);
      setSelectedPayments([]);
      if (pageNum !== 1) setPageNum(1);
      else dispatch(setIndexApiReload(!reloadIndexApi));
    };
    if (TRANSACTION_ACTIONS.APPROVE) {
      dispatch(
        bulkOperationPayments({
          context: [
            BILL_PAYROLL_CONTEXT.BILLPAY,
            BILL_PAYROLL_CONTEXT.ACTION_CENTER_BILLPAY,
          ]?.includes(page)
            ? BILL_PAYROLL_CONTEXT.BILLPAY
            : BILL_PAYROLL_CONTEXT.PAYROLL,
          onSuccess,

          param: {
            operation: action,
            ...convertFilters(appliedFilters),

            ...(bulkSelectAll
              ? {
                  all: true,
                  ...(deSelectedIds?.length
                    ? { except_ids: deSelectedIds }
                    : null),
                }
              : {
                  ids: selectedPayments,
                }),
          },
        })
      );
    }
  };

  // Right now we have kept it on hold since we are not using the slider, in future we'll make it a common function
  const exportHandler = () => {
    dispatch(
      setExportData({
        data: selectedPayments,
        page:
          page === BILL_PAYROLL_CONTEXT.BILLPAY
            ? EXPORT_PAGE.PURCHASE_BILL
            : EXPORT_PAGE.PAYROLL,
        cancelHandler: () => {
          searchParams.delete(SLIDERS_SEARCH_PARAMS.export);
          setSearchParams(searchParams);
        },
        csvExportDesc:
          page === BILL_PAYROLL_CONTEXT.BILLPAY
            ? "billPay.bill.exportSlider.csvExport"
            : "payroll.export.csvPaymentDesc",
        pdfExportDesc:
          page === BILL_PAYROLL_CONTEXT.BILLPAY
            ? "billPay.bill.exportSlider.pdfExport"
            : "payroll.export.pdfPaymentDesc",
      })
    );
    searchParams.append(SLIDERS_SEARCH_PARAMS.export, true);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    // When user selects - 'Select all transactions'
    if (bulkSelectAll) {
      setHeaderSelectAll(true);
      if (!deSelectedIds?.length) {
        setSelectedPayments(payments?.map((data) => data.id));
      } else {
        const filteredIds = payments
          ?.map((data) => data.id)
          .filter((id) => !deSelectedIds.includes(id));
        setSelectedPayments(filteredIds);
      }
    }
  }, [bulkSelectAll, payments, deSelectedIds]);

  useEffect(() => {
    // When user selects checkbox from header
    setHeaderSelectAll(
      (selectedPayments.length === pageNum * PAGINATION_PER_REQUEST_LIMIT ||
        selectedPayments.length === payments.length - queuedPaymentLength) &&
        !isEmpty
    );
  }, [selectedPayments, pageNum, payments]);

  const {
    tableSetting,
    tableHeadingRowClasses,
    tableDataRowClasses,
    tableHeading,
    emptyDataTitle,
    emptySubHeadingDataTitle,
  } = getApprovalTableDefaultHeader(page);

  return (
    <>
      <BillPayFilters
        filters={filters}
        page={
          page === BILL_PAYROLL_CONTEXT.PAYROLL ? "Payroll" : "PurchaseBill"
        }
        requestHistorySliderContext={
          page === ACTION_CENTRE_PAGE_CONTEXT.PAYROLL
            ? PENDING_REQUEST_TABS_KEYS.PAYROLL
            : PENDING_REQUEST_TABS_KEYS.BILL_PAY
        }
        showRequestHistoryButton={showRequestHistoryButton}
        tab={tabType}
      />

      <Table
        {...{
          ...tableSetting,
          emptyDataTitle,
          emptyDataDescription: emptySubHeadingDataTitle,
        }}
        bulkApproveVisible={selectedPayments?.length > 0}
        bulkApproveHeight="100px"
        bulkApproveContent={
          <BulkActionComponent
            selectedRows={
              bulkSelectAll
                ? deSelectedIds?.length
                  ? totalPayments - deSelectedIds.length
                  : totalPayments
                : selectedPayments?.length
            }
            totalRows={totalPayments}
            handleBulkAction={handleBulkApprove}
            descriptionText={
              showApproveCta ? "common.approvalDesc" : "common.exportDesc"
            }
            bulkApproveSelectAll={
              (bulkSelectAll && !deSelectedIds?.length) ||
              selectedPayments?.length === totalPayments
            }
            showSelectAllButton={headerSelectAll}
            selectAllHandler={(val) => {
              setDeSelectedIds([]);
              setBulkSelectAll(val);
            }}
            handleExport={() => {
              handleExport(
                false,
                selectedPayments?.length,
                appliedFilters,
                dispatch,
                {
                  export_type:
                    page === BILL_PAYROLL_CONTEXT.BILLPAY
                      ? EXPORT_PAGE.PURCHASE_BILL
                      : EXPORT_PAGE.PAYROLL,

                  ...(bulkSelectAll
                    ? { deselected_ids: deSelectedIds }
                    : { selected_ids: selectedPayments }),

                  tab: tabType,
                }
              );
            }}
            showExport={
              page === BILL_PAYROLL_CONTEXT.BILLPAY ||
              page === BILL_PAYROLL_CONTEXT.PAYROLL
            }
            showApprove={showApproveCta}
            clearSelection={() => {
              setBulkSelectAll(false);
              setHeaderSelectAll(false);
              setSelectedPayments([]);
              setDeSelectedIds([]);

              dispatch(
                setPayments(
                  payments.map((payment) => {
                    return { ...payment, selected: !payment.selected };
                  })
                )
              );
            }}
          />
        }
      >
        <tr className={tableHeadingRowClasses}>
          {tableHeading.map((headVal, index) => (
            <th className={headVal.classes} key={headVal.id}>
              <HeaderCell
                val={{
                  ...headVal,
                  onCheckboxClick: headerSelectionHandler,
                  checkedValue: headerSelected && !isEmpty,
                  showCheckbox: index === 0 && !isFetching,
                  disabled: isEmpty || allQueued,
                  sorting,
                  setSorting,
                }}
              />
            </th>
          ))}
        </tr>
        {(!isFetching || !isEmpty) &&
          payments.map((val, index, arr) => {
            const disableCheck =
              Boolean(
                isActionApprovePending && isActionApprovePending === val.id
              ) || val?.queued;

            return (
              <tr
                key={val.id}
                className={`${tableDataRowClasses} ${
                  disableCheck
                    ? "bg-neutral-100 text-neutral-500 pointer-events-none "
                    : ""
                }`}
                ref={(ref) => {
                  if (index === arr.length - 1 && hasMore) handleRefChange(ref);
                }}
              >
                {tableHeading.map(
                  ({ cellComponent: Component, classes, id }) => {
                    return (
                      <td
                        className={` ${
                          selectedPayments?.includes(val?.id)
                            ? "selected-row-cell"
                            : ""
                        } ${
                          disableCheck && "bg-neutral-100 text-neutral-500 "
                        } ${classes} `}
                        key={id}
                      >
                        <Component
                          val={{
                            ...val,
                            tabType,
                            context: page,
                            openSliderHandler: !disableCheck
                              ? openSliderHandler
                              : null,
                            showCheckbox: true,
                            disabled: disableCheck,
                            onCheckboxClick: () => {
                              rowSelectionHandler(val);
                            },
                            checkedValue:
                              headerSelectAll || bulkSelectAll
                                ? showApproveCta
                                  ? selectedPayments?.includes(val?.id)
                                  : true
                                : selectedPayments?.includes(val?.id),
                            billAutoPayEnabled,
                            payrollAutoPayEnabled,
                          }}
                          key={id}
                        />
                      </td>
                    );
                  }
                )}
              </tr>
            );
          })}
        {isFetching && <ApprovalPendingLoaderHelper />}
      </Table>

      <Modal
        open={[VP_MODALS.SCHEDULED_PAYMENT, VP_MODALS.SKIP_APPROVAL]?.includes(
          appModal
        )}
        onClick={() => dispatch(closeAppModal())}
      >
        <div className="flex flex-col gap-4 p-6 w-105 ">
          <Icon name="Info" className="text-warning-500 w-9 h-9" />
          <Text
            translationKey={
              appModalData?.confirmSkipApproval
                ? "billPay.bill.approvals.skipApprovalModalTitle"
                : checkIfDateCurrentDate(appModalData?.paymentDate)
                  ? "billPay.bill.approvals.scheduleDateTodayAlertTitle"
                  : "billPay.bill.approvals.scheduleDatePassedAlertTitle"
            }
            classes="font-bold text-lg"
          />

          <Text
            translationKey={
              appModalData?.confirmSkipApproval
                ? "billPay.bill.approvals.skipApprovalModalDesc"
                : checkIfDateCurrentDate(appModalData?.paymentDate)
                  ? "billPay.bill.approvals.scheduleDateTodayDesc"
                  : "billPay.bill.approvals.scheduleDatePassedDesc"
            }
            translationProps={{
              context:
                appModalData?.context === BILL_PAYROLL_CONTEXT.BILLPAY
                  ? t("billPay.bill.approvals.bill")
                  : t("billPay.bill.approvals.salary"),
            }}
            classes="font-semibold text-xs text-neutral-500"
          />

          <Text
            translationKey="billPay.bill.approvals.scheduleDateAlertConfirmation"
            classes="font-semibold text-xs text-neutral-700"
          />

          <div className="flex items-center gap-2 ">
            <Button
              label="billPay.vendors.createVendor.cancel"
              type="neutral"
              classes="px-5 py-3"
              onClick={() => dispatch(closeAppModal())}
              disabled={Boolean(
                isActionApprovePending &&
                  isActionApprovePending === appModalData?.id
              )}
            />
            <Button
              label={
                appModalData?.confirmSkipApproval
                  ? "billPay.bill.approvals.confirmCta"
                  : "billPay.bill.approvals.applyAndProcessPayment"
              }
              variant="primary"
              type={appModalData?.confirmSkipApproval ? "default" : "success"}
              showLoader={Boolean(
                isActionApprovePending &&
                  isActionApprovePending === appModalData?.id
              )}
              classes="px-5 py-3"
              onClick={() => {
                const onSuccess = () => {
                  // to verify if this is action page or not
                  const isPayrollePage =
                    window.location.pathname ===
                    ROUTES.payroll.salaryPayment.approvals.absolutePath;

                  const skipApprovalViaSlider = searchParams.get(
                    SLIDERS_SEARCH_PARAMS.payments.id
                  );

                  if (!isPayrollePage) {
                    if (
                      appModalData?.tabType === TAB_TYPES.NEEDS_YOUR_APPROVAL
                    ) {
                      dispatch(removePayment(appModalData?.id));
                    } else {
                      dispatch(setIndexApiReload(!reloadIndexApi));
                    }
                  }

                  if (
                    page === BILL_PAYROLL_CONTEXT.ACTION_CENTER_BILLPAY ||
                    page === BILL_PAYROLL_CONTEXT.ACTION_CENTER_PAYROLL
                  ) {
                    dispatch(fetchActionCentreTabs());
                    dispatch(setIndexApiReload(!reloadIndexApi));
                  }

                  dispatch(closeAppModal());

                  if (skipApprovalViaSlider) {
                    appModalData?.closeDrawer();
                  }
                };

                if (
                  appModalData?.skipApprovals ||
                  appModalData?.confirmSkipApproval
                ) {
                  dispatch(
                    performActionThunk({
                      id: appModalData.id,
                      do: BILLPAY_ACTION_PARAMS.DO.SKIP_APPROVALS,
                      context: appModalData.context,
                      onSuccess,
                    })
                  );
                } else {
                  dispatch(
                    approve({
                      context: appModalData.context,
                      onSuccess,
                      id: appModalData.id,
                    })
                  );
                }
              }}
            />
          </div>
        </div>
      </Modal>

      <RadioModal
        title="billPay.bill.payments.paymentFooter.archiveRecurringPayment.title"
        description="billPay.bill.payments.paymentFooter.archiveRecurringPayment.description"
        visible={[
          VP_MODALS.ARCHIVE_RECURRING_PAYMENT,
          VP_MODALS.ARCHIVE_RECURRING_PAYROLL,
        ].includes(appModal)}
        onSubmit={(selectedOptionId, val) => {
          const onSuccess = () => {
            dispatch(closeAppModal());
            dispatch(removePayment(val?.id));
            setSearchParams({});
          };

          if (selectedOptionId === "all") {
            dispatch(
              archive({
                context: val?.context,
                id: val?.id,
                onSuccess,
                archive_all: true,
              })
            );
          } else {
            dispatch(
              archive({ context: val?.context, id: val?.id, onSuccess })
            );
          }
        }}
        submitBtnProps={{
          type: page === BILL_PAYROLL_CONTEXT.PAYROLL ? "success" : undefined,
        }}
        configs={[
          {
            id: "all",
            label:
              "billPay.bill.payments.paymentFooter.archiveRecurringPayment.all",
          },
          {
            id: "selected",
            label:
              "billPay.bill.payments.paymentFooter.archiveRecurringPayment.current",
          },
        ]}
        onClose={() => dispatch(closeAppModal())}
        submitLabel="misc.saveChanges"
      />
    </>
  );
}
PaymentsApprovalList.propTypes = {
  page: PropTypes.string,
  tabType: PropTypes.string,
  openSliderHandler: PropTypes.func,
  isActionCentrePage: PropTypes.bool,
};
