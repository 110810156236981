import { createSelector } from "@reduxjs/toolkit";

import {
  getCardsModuleRequestFilters,
  getCardsPageFiltersConfig,
} from "@/components/Cards/util";
import { AVAILABLE_FILTERS } from "@/utils/constants/filters";

import {
  CARDS_ALL_SLIDER_MENU_KEYS,
  CARD_TYPE,
  CARD_TYPES,
  RECURRING,
} from "@/constants/Cards";

import {
  cardProviderWithInternationalCardOrderEnabledSelector,
  cardsProvidersListItemSelector,
  primaryCardProviderSelector,
} from "./client";
import { userDepartmentIdSelector } from "./user";

export const selectCardsStore = (store) => store.cards;

export const cardsSelector = createSelector(
  selectCardsStore,
  (cards) => cards.cards
);

export const cardsListSelector = createSelector(
  cardsSelector,
  (cards) => cards.list
);

export const isFetchingCardsListSelector = createSelector(
  cardsSelector,
  (cards) => cards.isFetching
);

export const hasMoreCardsSelector = createSelector(
  cardsSelector,
  (cards) => cards.hasMore
);

export const totalCardsSelector = createSelector(
  cardsSelector,
  (cards) => cards.total
);

export const physicalCardComingSoonSelector = createSelector(
  cardsSelector,
  (cards) => cards.physicalCardComingSoon
);

export const perPageSelector = createSelector(
  cardsSelector,
  (cards) => cards.perPage
);

export const pageSelector = createSelector(
  cardsSelector,
  (cards) => cards.page
);

export const cardsHasMoreSelector = createSelector(
  cardsSelector,
  (cards) => cards.hasMore
);

export const selectedCardSelector = createSelector(
  selectCardsStore,
  (cards) => cards.selectedCard
);

export const isFetchingSelectedCardSelector = createSelector(
  selectCardsStore,
  (cards) => cards?.isFetchingCard
);

export const adminRequestSelector = createSelector(
  selectedCardSelector,
  (cards) => cards?.adminRequest
);

export const selectedCardCtaBtnsSelector = createSelector(
  selectedCardSelector,
  (card) => card?.allowedCtas
);

export const resetPinUrlSelector = createSelector(
  selectedCardSelector,
  (card) => card?.resetPinLink
);

export const selectedCardSlugSelector = createSelector(
  selectedCardSelector,
  (selectedCard) => selectedCard?.slug
);

export const physicalCardSelector = createSelector(
  selectCardsStore,
  (cards) => cards.physicalCard
);

export const isFetchingPhysicalCardSelector = createSelector(
  selectCardsStore,
  (cards) => cards.isFetchingPhysicalCard
);

export const fetchingCardSingularSelector = createSelector(
  selectCardsStore,
  (cards) => cards.fetchingCardSingular
);

export const fetchingCardsSelector = createSelector(
  selectCardsStore,
  (cards) => cards.fetchingCards
);

export const isFetchingPhysicalCardBudgetsSelector = createSelector(
  selectCardsStore,
  (cards) => cards.isFetchingPhysicalCardBudgets
);

export const cardChannelSelector = createSelector(
  selectCardsStore,
  (cards) => cards.cardChannel
);

export const fetchedCardDetailSelector = createSelector(
  selectCardsStore,
  (cards) => cards.fetchedCardDetail
);
export const paymentInfoSelector = createSelector(
  selectCardsStore,
  (cards) => cards.paymentInfo
);

export const editedLimitDataSelector = createSelector(
  selectCardsStore,
  (cards) => cards.editedLimitData
);

export const poolCardDetailsSelector = createSelector(
  selectCardsStore,
  (cards) => cards.poolCardDetails
);
export const projectListSelector = createSelector(
  selectCardsStore,
  (cards) => cards.projectList
);
export const recurringCardBudgets = createSelector(selectCardsStore, (cards) =>
  cards.cardBudgets.filter((item) => item?.budget?.budgetType === RECURRING)
);

export const firstPhysicalCard = createSelector(
  cardsListSelector,
  (cards) => cards.filter((elem) => elem.cardType === CARD_TYPES.PHYSICAL)[0]
);

export const physicalCardsList = createSelector(cardsListSelector, (cards) =>
  cards.filter((card) => card.type === CARD_TYPE.PHYSICAL)
);

export const virtualCardsList = createSelector(cardsListSelector, (cards) =>
  cards.filter((card) => card.type === CARD_TYPE.VIRTUAL)
);

export const createVirtualCardInputSelector = createSelector(
  selectCardsStore,
  (cards) => cards.createVirtualCardInput
);
export const settingsSelector = createSelector(
  selectCardsStore,
  (cards) => cards?.settings
);

export const globalSettingsMccInfoSelector = createSelector(
  settingsSelector,
  (cardSetting) => cardSetting?.merchant_restrictions
);

export const selectedValuesSettingSelector = createSelector(
  settingsSelector,
  (cardSetting) => cardSetting.selectedValues
);

export const flagRulesSettingInfoSelector = createSelector(
  settingsSelector,
  (cardSetting) => cardSetting.flagRules
);

export const flagRulesSettingSelector = createSelector(
  flagRulesSettingInfoSelector,
  (flagSettingsInfo) => flagSettingsInfo?.list
);

export const isFetchingFlagRulesSelector = createSelector(
  flagRulesSettingInfoSelector,
  (flagSettingsInfo) => flagSettingsInfo?.isFetching
);

export const expenseAlertsSettingInfoSelector = createSelector(
  settingsSelector,
  (cardSetting) => cardSetting.expenseAlerts
);

export const expenseAlertsSettingSelector = createSelector(
  expenseAlertsSettingInfoSelector,
  (expenseAlertsInfo) => expenseAlertsInfo?.list
);

export const isFetchingExpenseAlertsSettingSelector = createSelector(
  expenseAlertsSettingInfoSelector,
  (expenseAlertsInfo) => expenseAlertsInfo?.isFetching
);

export const expenseReviewRangeSettingsSelector = createSelector(
  settingsSelector,
  (cardSetting) => cardSetting.expenseReviewRange
);
export const filtersKeySelector = createSelector(
  selectCardsStore,
  (cards) => cards.filtersKey
);
export const physicalCardFilterKeySelector = createSelector(
  filtersKeySelector,
  (filterkeys) => filterkeys.physical
);
export const allPhysicalCardPageFiltersSelector = createSelector(
  physicalCardFilterKeySelector,
  primaryCardProviderSelector,
  userDepartmentIdSelector,
  cardProviderWithInternationalCardOrderEnabledSelector,
  (keys, clientInfo, departmentId, hasCardWithInternationCardOrderEnabled) =>
    getCardsPageFiltersConfig({
      filterKeys: keys?.all,
      clientInfo,
      departmentId,
      hasCardWithInternationCardOrderEnabled,
    })
);

export const yetToActivatePhysicalCardPageFiltersSelector = createSelector(
  physicalCardFilterKeySelector,
  userDepartmentIdSelector,
  (keys, departmentId) =>
    getCardsPageFiltersConfig({
      filterKeys: keys?.yetToActivate,
      departmentId,
    })
);

export const blockedPhysicalCardPageFiltersSelector = createSelector(
  physicalCardFilterKeySelector,
  primaryCardProviderSelector,
  userDepartmentIdSelector,
  cardProviderWithInternationalCardOrderEnabledSelector,
  (keys, clientInfo, departmentId, hasCardWithInternationCardOrderEnabled) =>
    getCardsPageFiltersConfig({
      filterKeys: keys?.blocked,
      clientInfo,
      departmentId,
      hasCardWithInternationCardOrderEnabled,
    })
);
export const virtualCardFilterKeySelector = createSelector(
  filtersKeySelector,
  (filterkeys) => filterkeys.virtual
);
export const allVirtualCardPageFiltersSelector = createSelector(
  virtualCardFilterKeySelector,
  primaryCardProviderSelector,
  userDepartmentIdSelector,
  (keys, clientInfo, departmentId) =>
    getCardsPageFiltersConfig({
      filterKeys: keys?.all,
      clientInfo,
      departmentId,
    })
);
export const blockedVirtualCardPageFiltersSelector = createSelector(
  virtualCardFilterKeySelector,
  userDepartmentIdSelector,
  (keys, departmentId) =>
    getCardsPageFiltersConfig({ filterKeys: keys?.blocked, departmentId })
);

export const orderPhysicalCardFormDataSelector = createSelector(
  selectCardsStore,
  (cards) => cards.orderPhysicalCardFormData
);

export const physicalCardApproversList = createSelector(
  selectCardsStore,
  (cards) => cards.physicalCardApprovers
);

export const physicalCardActivationFieldsSelector = createSelector(
  selectCardsStore,
  (cards) => cards.physicalCardsActivationFields
);

export const physicalCardsActionFromDataSelector = createSelector(
  selectCardsStore,
  (cards) => cards.physicalCardsActivationFormData
);

export const cardRequestDataSelector = createSelector(
  selectCardsStore,
  (cards) => cards.cardRequestData
);

export const cardSpecificRequestSlidersSelectedTabSelector = createSelector(
  cardRequestDataSelector,
  (cards) => cards.selectedTab
);

export const cardRequestSliderInfoSelector = createSelector(
  cardRequestDataSelector,
  (cards) => cards.slidersInfo
);
export const newCardRequestDetailsSelector = createSelector(
  cardRequestSliderInfoSelector,
  (cards) => cards.approvalReqeustData
);
export const cardRequestDetailsIsErrorSelector = createSelector(
  cardRequestSliderInfoSelector,
  (cards) => cards.isError
);
export const cardRequestDetailsIsFetchingSelector = createSelector(
  cardRequestSliderInfoSelector,
  (cards) => cards.isFetching
);
export const isCardOperationProcessingSelector = createSelector(
  selectCardsStore,
  (cards) => cards.isCardOperationProcessing
);
export const selectedCardProviderDataSelector = createSelector(
  selectedCardSelector,
  cardsProvidersListItemSelector,
  (card, cardProvidersList) => {
    return cardProvidersList.find(
      (cardProvider) => cardProvider.slug === card?.slug
    );
  }
);

export const expenseReviewLayersDisableStatusSelector = createSelector(
  settingsSelector,
  (cardSetting) => cardSetting.expenseReviewLayersDisableStatus
);

export const activateCardInprogressSelector = createSelector(
  selectCardsStore,
  (cards) => cards?.activateCard?.inProgress
);

export const activateCardStatusSelector = createSelector(
  selectCardsStore,
  (cards) => cards?.activateCard?.activateCardStatus
);

export const isFetchingVirtualCardsStepsSelector = createSelector(
  selectCardsStore,
  (cards) => cards?.orderVirtualCardSteps?.isFetching
);

export const orderVirtualCardStepsSelector = createSelector(
  selectCardsStore,
  (cards) => cards?.orderVirtualCardSteps?.steps
);

export const orderCardStatusSelector = createSelector(
  selectCardsStore,
  (cards) => cards?.orderCardStatus
);

export const bulkOperationInProgressSelector = createSelector(
  selectCardsStore,
  (cards) => cards?.bulkOperationInPending
);

export const isLimitChangedInfoSelector = createSelector(
  selectCardsStore,
  (cards) => cards?.limitChanged
);

export const isLimtChangedSelector = createSelector(
  isLimitChangedInfoSelector,
  (limitChanged) => limitChanged?.isChanged
);

export const isLimitChangeInProgressSelector = createSelector(
  isLimitChangedInfoSelector,
  (limitChanged) => limitChanged?.inProgress
);

export const isPinUpdatedStatusSelector = createSelector(
  selectCardsStore,
  (cards) => cards?.isPinReset
);

export const isLinkedToChangedInfoSelector = createSelector(
  selectCardsStore,
  (cards) => cards.changedLinkedToStatus
);

export const isLinkedToChangedSelector = createSelector(
  isLinkedToChangedInfoSelector,
  (isLinkedToChangedInfo) => isLinkedToChangedInfo?.isChanged
);

export const isLinkedToChangedInpgressSelector = createSelector(
  isLinkedToChangedInfoSelector,
  (isLinkedToChangedInfo) => isLinkedToChangedInfo?.inProgress
);

export const merchantRestrictionsSelector = createSelector(
  selectCardsStore,
  (cards) => cards?.merchantRestrictions
);

export const merchantRestrictionsInfoSelector = createSelector(
  merchantRestrictionsSelector,
  (merchantRestrictions) => merchantRestrictions?.data
);

export const isFetchingMerchantRestrictionsSelector = createSelector(
  merchantRestrictionsSelector,
  (merchantRestrictions) => merchantRestrictions?.isFetching
);

export const cardSettingsFormInfoSelector = createSelector(
  selectCardsStore,
  (cards) => cards?.cardSettingsFormInfo
);

export const isCardSettingsUpdatingSelector = createSelector(
  cardSettingsFormInfoSelector,
  (cardSettings) => cardSettings?.isCardSettingsUpdating
);

export const cardControlsInfoSelector = createSelector(
  selectCardsStore,
  (cards) => cards.cardControlsInfo
);

export const cardControlsListSelector = createSelector(
  cardControlsInfoSelector,
  (cardControlsInfo) => cardControlsInfo?.controlsList
);

export const isFetchingCardControlsInfoSelector = createSelector(
  cardControlsInfoSelector,
  (cardControlsInfo) => cardControlsInfo?.isFetching
);

export const cardSliderTabsInfoSelector = createSelector(
  selectCardsStore,
  (cards) => cards?.cardSliderTabsInfo
);

export const cardSliderSelectedTabSelector = createSelector(
  cardSliderTabsInfoSelector,
  (tabsInfo) => tabsInfo?.selectedTab
);

export const cardSliderTabsListSelector = createSelector(
  cardSliderTabsInfoSelector,
  selectedCardSelector,
  (tabsInfo, selectedCard) => {
    const hasSettingsKey =
      selectedCard?.allowedCtas?.findIndex(
        (cta) => cta === CARDS_ALL_SLIDER_MENU_KEYS.SETTINGS
      ) >= 0;

    if (!hasSettingsKey) {
      return tabsInfo?.tabsList?.filter(
        (tab) => tab.key !== CARDS_ALL_SLIDER_MENU_KEYS.SETTINGS
      );
    }
    return tabsInfo?.tabsList;
  }
);

export const shallowMerchantListInfoSelector = createSelector(
  selectCardsStore,
  (cards) => cards?.shallowMerchantInfo
);

export const shallowMerchantListSelector = createSelector(
  shallowMerchantListInfoSelector,
  (shallowMerchantListInfo) => shallowMerchantListInfo?.list
);

export const isFethingShallowMerchantListSelector = createSelector(
  shallowMerchantListInfoSelector,
  (shallowMerchantListInfo) => shallowMerchantListInfo?.isFetching
);

export const merchantsInfoSelector = createSelector(
  selectCardsStore,
  (cards) => cards?.merchants
);

export const merchantListSelector = createSelector(
  merchantsInfoSelector,
  (merchantsInfo) => merchantsInfo?.list
);

export const merchantListHasMoreSelector = createSelector(
  merchantsInfoSelector,
  (merchantsInfo) => merchantsInfo?.hasMore
);

export const merchantListisFetchingSelector = createSelector(
  merchantsInfoSelector,
  (merchantsInfo) => merchantsInfo?.isFetching
);

export const cardRequestTableInfoSelector = createSelector(
  selectCardsStore,
  (cards) => cards?.cardRequestTableInfo
);

export const cardRequestTableListSelector = createSelector(
  cardRequestTableInfoSelector,
  (cardRequestTableInfo) =>
    cardRequestTableInfo.list?.map((item) => ({ ...item, ...item?.request }))
);

export const isFetchingCardRequestListSelector = createSelector(
  cardRequestTableInfoSelector,
  (cardRequestTableInfo) => cardRequestTableInfo.isFetching
);

export const hasMoreCardRequestTableSelector = createSelector(
  cardRequestTableInfoSelector,
  (cardRequestTableInfo) => cardRequestTableInfo.hasMore
);

export const totalCountCardRequestTableSelector = createSelector(
  cardRequestTableInfoSelector,
  (cardRequestTableInfo) => cardRequestTableInfo.total
);

export const cardModuleRequestPageFilterKeysSelector = createSelector(
  selectCardsStore,
  primaryCardProviderSelector,
  (cards, clientInfo) =>
    getCardsModuleRequestFilters(cards?.cardModuleRequestFilterKeys, clientInfo)
);

export const selectedMerchantDetailsSelector = createSelector(
  selectCardsStore,
  (cards) => cards?.selectedMerchantDetails
);

export const cardActionGuidelineVideosSelector = createSelector(
  selectCardsStore,
  (cards) => cards?.cardActionGuidelineVideos
);
