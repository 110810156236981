import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";

import { SLIDERS_SEARCH_PARAMS } from "./SearchParams";
import {
  PHYSICAL_CARDS_ALL_PATH_NAME,
  PHYSICAL_CARDS_BLOCKED_PATH_NAME,
  PHYSICAL_CARDS_YET_TO_ACTIVATE_PATH_NAME,
  ROUTES,
  VIRTUAL_CARDS_ALL_PATH_NAME,
  VIRTUAL_CARDS_BLOCKED_PATH_NAME,
} from "./routes";

export const CARD_TYPES = {
  VIRTUAL: "virtual",
  PHYSICAL: "physical",
};

export const API_KEYS = {
  STATUS: "status",
  CARD_TYPE: "card_type",
};
export const CARD_FREQUENCY = {
  ONE_TIME: "misc.oneTime",
  MONTHLY: "misc.monthly",
};

export const CARD_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  BLOCKED: "blocked",
  FROZEN: "freezed",
};

export const CARD_STATUS_TITLE_MAP = {
  // TECH_DEBT translation
  [CARD_STATUS.ACTIVE]: "Active",
  [CARD_STATUS.INACTIVE]: "Inactive",
  [CARD_STATUS.BLOCKED]: "Blocked",
  [CARD_STATUS.FROZEN]: "Freezed",
};

export const CARD_STATUS_ICON = {
  [CARD_STATUS.BLOCKED]: "Block",
  [CARD_STATUS.FROZEN]: "AcUnits",
};

export const SUBMISSION_POLICY_STATUSES = {
  MET: "met",
  NOT_MET: "unfulfilled",
};
export const CARDS_ALL_SLIDER_MENU_KEYS = {
  SETTINGS: "settings",
  EDIT_CARD: "edit",
  FREEZE_CARD: "freeze",
  CHANGE_PIN: "change-pin",
  UNFREEZE: "unfreeze",
  ACTIVATE: "activate",
  BLOCK: "block",
  ORDER_CARD: "order",
  LINKED_TO: "linked-to",
  EDIT_CARD_CONTROLS: "card-controls",
  EDIT_TRANSACTION_LIMIT: "transaction-amount-limit",
};
export const RECURRING = "recurring";

export const MERCHANT_CATEGORY_TYPES = {
  ALLOWED: "allow",
  RESTRICT: "restrict",
};
export const MERCHANT_CATEGORY_ICONS = {
  [MERCHANT_CATEGORY_TYPES.ALLOWED]: {
    icon: "CircleCheck",
    color: "text-success-500",
  },
  [MERCHANT_CATEGORY_TYPES.RESTRICT]: {
    icon: "Cancel",
    color: "text-danger-500",
  },
};

export const CARDS_SETTING_CONTROL_SLIDER_MODE = {
  EDIT: "edit",
  PREVIEW: "preview",
};

export const CONTROL_SETTING_BUTTON_SUBMIT_KEY = {
  EDIT_LABEL: "editLabel",
  CANCEL: "cancel",
  SET_CONTROLS: "setCOntrols",
};

export const CONTROL_SETTING_BUTTON_CONFIG_CARDS = {
  // TECH_DEBT translation
  [CARDS_SETTING_CONTROL_SLIDER_MODE.PREVIEW]: [
    {
      classes: "w-15.5",
      variant: "tertiary",

      label: "Edit label",
      submitKey: CONTROL_SETTING_BUTTON_SUBMIT_KEY.EDIT_LABEL,
    },
  ],
  [CARDS_SETTING_CONTROL_SLIDER_MODE.EDIT]: [
    {
      classes: "w-15.5",
      variant: "tertiary",
      label: "Cancel",
      submitKey: CONTROL_SETTING_BUTTON_SUBMIT_KEY.CANCEL,
    },
    {
      classes: "w-15.5",
      label: "Set Controls",
      isSubmitBtn: true,
      submitKey: CONTROL_SETTING_BUTTON_SUBMIT_KEY.SET_CONTROLS,
    },
  ],
};
export const CHECKBOX_CARD_SETTING = {
  DEPARTMENT: "project",
  MERCHANT: "merchant",
  CATEGORY: "category",
};
export const ACTION_BUTTONS = [
  // TECH_DEBT translation
  {
    id: 1,
    icon: "Add",
    classes: "w-17.1 h-8",
    title: "Add department/project",
    variant: "tertiary",
    iconClasses: "w-2.5 h-2.5",
    textClasses: "text-xs",
    componentTitle: "If department/project is any one of",
    vpSelectKey: CHECKBOX_CARD_SETTING.DEPARTMENT,
  },
  {
    id: 2,
    icon: "Add",
    title: "Add merchant",
    classes: "w-15 h-8",
    variant: "tertiary",
    iconClasses: "w-2.5 h-2.5",
    textClasses: "text-xs",
    componentTitle: "If merchant is",
    vpSelectKey: CHECKBOX_CARD_SETTING.MERCHANT,
  },
  {
    id: 3,
    icon: "Add",
    title: "Add category",
    classes: "w-15 h-8",
    textClasses: "text-xs",
    iconClasses: "w-2.5 h-2.5",
    variant: "tertiary",
    componentTitle: "If category is any one of",
    vpSelectKey: CHECKBOX_CARD_SETTING.CATEGORY,
  },
];

export const RULES_AND_ALERT_TYPE = {
  RULES: "rules",
  ALERT: "alert",
};
export const LABEL_SELECTED_VALUES = {
  category: "cardSetting.expenseRules.category",
  department: "cardSetting.expenseRules.department",
  merchant: "cardSetting.expenseRules.merchant",
};
export const SUBMIT_BUTTON = {
  [RULES_AND_ALERT_TYPE.RULES]: "cardSetting.flagRules.emptyButtonLabel",
  [RULES_AND_ALERT_TYPE.ALERT]: "cardSetting.expenseRules.emptyButtonLabel",
};

export const GENERAL_SETTINGS_CONFIG = [
  // TECH_DEBT translation
  {
    title: "Split expenses",
    description: "Allow employees to split expenses",
    child: {
      type: "switch",
      title: "Enable split expenses ",
      setting: "split_expenses_enabled",
      description:
        "Allow admins the ability to enable/disable splitting expense",
    },
  },
  {
    title: "Merchant listing",
    description:
      "Discover the list of Merchants where your Volopay cards have been swiped",
    child: {
      type: "slider",
      title: "Merchant requirements",
      description:
        "Enable/disable submission policy requirements for individual merchant",
      searchParam: SLIDERS_SEARCH_PARAMS.cards.cardsettings.merchantListing,
      searchValue: "true",
    },
  },
  {
    title: "cardSetting.cardManagers.title",
    description: "cardSetting.cardManagers.description",
    child: {
      type: "slider",
      title: "cardSetting.cardManagers.addCardManagers.title",
      description: "cardSetting.cardManagers.addCardManagers.description",
      searchParam: SLIDERS_SEARCH_PARAMS.cards.cardsettings.cardManagers,
      searchValue: "true",
    },
  },
  {
    title: "cardSetting.expenseManagers.title",
    description: "cardSetting.expenseManagers.description",
    child: {
      type: "slider",
      title: "cardSetting.expenseManagers.addExpenseManagers.title",
      description: "cardSetting.expenseManagers.addExpenseManagers.description",
      searchParam: SLIDERS_SEARCH_PARAMS.cards.cardsettings.expenseManagers,
      searchValue: "true",
    },
  },
];

export const CREATE_NEW_CONFIG = {
  [RULES_AND_ALERT_TYPE.RULES]: {
    title: "cardSetting.flagRules.title",
    description: "cardSetting.flagRules.description",
    emptyDataTitle: "cardSetting.flagRules.emptyDataTitle",
    emptyDataDescription: "cardSetting.flagRules.emptyDataDescription",
    emptyButtonIcon: "Flag",
    emptyButtonLabel: "cardSetting.flagRules.emptyButtonLabel",
  },
  [RULES_AND_ALERT_TYPE.ALERT]: {
    title: "cardSetting.expenseRules.title",
    description: "cardSetting.expenseRules.description",
    emptyDataTitle: "cardSetting.expenseRules.emptyDataTitle",
    emptyDataDescription: "cardSetting.expenseRules.emptyDataDescription",
    emptyButtonIcon: "Notifications",
    emptyButtonLabel: "cardSetting.expenseRules.emptyButtonLabel",
  },
};

export const EXPENSE_ALERT_TYPES = {
  FLAG: "flag",
  ALERT: "alert",
};
export const CONTROL_MERCHANT_CATEGORY_CONTROL = [
  {
    id: 1,
    label:
      "cards.vCards.createCardSlider.controls.category.allowCategories.title",
    value: "allow",
  },
  {
    id: 2,
    label:
      "cards.vCards.createCardSlider.controls.category.restrictCategories.title",
    value: "restrict",
  },
  {
    id: 3,
    label: "cards.vCards.createCardSlider.controls.category.noCategory.title",
    value: null,
  },
];

export const SETTINGS_SEARCH_PARAM_VALUE = {
  CREATE: "create",
  PREVIEW: "preview",
  EDIT: "edit",
};

// cards Provider
export const CARD_PROVIDER = {
  MATCH_MOVE: "matchmove",
  NIUM: "nium", // singapore
  SBM: "sbm",
  LIVQUIK: "livquik", // india
  AIRWALLEX: "airwallex", // aus
  CONNEXPAY: "connexpay",
  UOB: "uob", // indo
  PINELABS: "pinelabs",
  AYOCONNECT: "ayoconnect",
  ICICI: "icici",
};

export const CARD_TYPE = {
  PHYSICAL: "physical",
  VIRTUAL: "virtual",
};

export const CARD_TYPE_TITLE = {
  [CARD_TYPE.PHYSICAL]: "cards.physical",
  [CARD_TYPE.VIRTUAL]: "cards.virtual",
};
export const CARD_TYPE_LABEL = {
  [CARD_TYPE.PHYSICAL]: "cards.physicalCard",
  [CARD_TYPE.VIRTUAL]: "cards.virtualCard",
};
export const CARD_FREQUENCY_CORE = {
  MONTHLY: "monthly",
  ONE_TIME: "one_time",
  DAILY: "daily",
  WEEKLY: "weekly",
};
export const CARDS_TABLE_HEADER_KEYS = {
  CARDS: "cards",
  CARD_HOLDER: "cardHolder",
  LIMIT: "limit",
  SPENT: "spent",
  AVAILABLE: "available",
  LINKED_TO: "linkedTo",
  CREATED_ON: "createdOn",
  DEPARTMENT: "department",
  LOCATION: "location",
};

export const CARDS_HEADER_LABEL = {
  [CARDS_TABLE_HEADER_KEYS.CARDS]: "cards.cardTable.cards",
  [CARDS_TABLE_HEADER_KEYS.CARD_HOLDER]: "cards.cardTable.cardHolder",
  [CARDS_TABLE_HEADER_KEYS.LIMIT]: "cards.cardTable.limit",
  [CARDS_TABLE_HEADER_KEYS.SPENT]: "cards.cardTable.spent",
  [CARDS_TABLE_HEADER_KEYS.AVAILABLE]: "cards.cardTable.available",
  [CARDS_TABLE_HEADER_KEYS.CREATED_ON]: "cards.cardTable.createdOn",
  [CARDS_TABLE_HEADER_KEYS.LINKED_TO]: "cards.cardTable.linkedTo",
  [CARDS_TABLE_HEADER_KEYS.DEPARTMENT]: "cards.cardTable.department",
  [CARDS_TABLE_HEADER_KEYS.LOCATION]: "cards.cardTable.location",
};
export const CARD_HEADERS_CONFIG = {
  [CARDS_TABLE_HEADER_KEYS.CARDS]: {
    id: CARDS_TABLE_HEADER_KEYS.CARDS,
    label: CARDS_HEADER_LABEL[CARDS_TABLE_HEADER_KEYS.CARDS],
    colWidth: 300,
  },
  [CARDS_TABLE_HEADER_KEYS.CARD_HOLDER]: {
    id: CARDS_TABLE_HEADER_KEYS.CARD_HOLDER,
    label: CARDS_HEADER_LABEL[CARDS_TABLE_HEADER_KEYS.CARD_HOLDER],
    colWidth: 300,
  },
  [CARDS_TABLE_HEADER_KEYS.LIMIT]: {
    id: CARDS_TABLE_HEADER_KEYS.LIMIT,
    label: CARDS_HEADER_LABEL[CARDS_TABLE_HEADER_KEYS.LIMIT],
    colWidth: 200,
  },
  [CARDS_TABLE_HEADER_KEYS.SPENT]: {
    id: CARDS_TABLE_HEADER_KEYS.SPENT,
    label: CARDS_HEADER_LABEL[CARDS_TABLE_HEADER_KEYS.SPENT],
    colWidth: 200,
  },
  [CARDS_TABLE_HEADER_KEYS.AVAILABLE]: {
    id: CARDS_TABLE_HEADER_KEYS.AVAILABLE,
    label: CARDS_HEADER_LABEL[CARDS_TABLE_HEADER_KEYS.AVAILABLE],
    colWidth: 200,
  },
  [CARDS_TABLE_HEADER_KEYS.LINKED_TO]: {
    id: CARDS_TABLE_HEADER_KEYS.LINKED_TO,
    label: CARDS_HEADER_LABEL[CARDS_TABLE_HEADER_KEYS.LINKED_TO],
    colWidth: 300,
  },
  [CARDS_TABLE_HEADER_KEYS.CREATED_ON]: {
    id: CARDS_TABLE_HEADER_KEYS.CREATED_ON,
    label: CARDS_HEADER_LABEL[CARDS_TABLE_HEADER_KEYS.CREATED_ON],
    colWidth: 200,
  },
  [CARDS_TABLE_HEADER_KEYS.DEPARTMENT]: {
    id: CARDS_TABLE_HEADER_KEYS.DEPARTMENT,
    label: CARDS_HEADER_LABEL[CARDS_TABLE_HEADER_KEYS.DEPARTMENT],
    colWidth: 200,
    dependsOnDepartmentClient: true,
  },
  [CARDS_TABLE_HEADER_KEYS.LOCATION]: {
    id: CARDS_TABLE_HEADER_KEYS.LOCATION,
    label: CARDS_HEADER_LABEL[CARDS_TABLE_HEADER_KEYS.LOCATION],
    colWidth: 200,
    dependsOnDepartmentClient: true,
  },
};
export const CARDS_TABLE_HEADERS = {
  [CARD_TYPES.PHYSICAL]: [
    CARD_HEADERS_CONFIG[CARDS_TABLE_HEADER_KEYS.CARDS],
    CARD_HEADERS_CONFIG[CARDS_TABLE_HEADER_KEYS.CARD_HOLDER],
    CARD_HEADERS_CONFIG[CARDS_TABLE_HEADER_KEYS.LIMIT],
    CARD_HEADERS_CONFIG[CARDS_TABLE_HEADER_KEYS.SPENT],
    CARD_HEADERS_CONFIG[CARDS_TABLE_HEADER_KEYS.AVAILABLE],
    CARD_HEADERS_CONFIG[CARDS_TABLE_HEADER_KEYS.LINKED_TO],
    CARD_HEADERS_CONFIG[CARDS_TABLE_HEADER_KEYS.CREATED_ON],
  ],
  [CARD_TYPES.VIRTUAL]: [
    CARD_HEADERS_CONFIG[CARDS_TABLE_HEADER_KEYS.CARDS],
    CARD_HEADERS_CONFIG[CARDS_TABLE_HEADER_KEYS.CARD_HOLDER],
    CARD_HEADERS_CONFIG[CARDS_TABLE_HEADER_KEYS.LIMIT],
    CARD_HEADERS_CONFIG[CARDS_TABLE_HEADER_KEYS.SPENT],
    CARD_HEADERS_CONFIG[CARDS_TABLE_HEADER_KEYS.AVAILABLE],
    CARD_HEADERS_CONFIG[CARDS_TABLE_HEADER_KEYS.DEPARTMENT],
    CARD_HEADERS_CONFIG[CARDS_TABLE_HEADER_KEYS.LOCATION],
    CARD_HEADERS_CONFIG[CARDS_TABLE_HEADER_KEYS.LINKED_TO],
    CARD_HEADERS_CONFIG[CARDS_TABLE_HEADER_KEYS.CREATED_ON],
  ],
};

export const PHYSICAL_CARD_TAB_STATUS_MAP = {
  [PHYSICAL_CARDS_ALL_PATH_NAME]: {
    [API_KEYS.CARD_TYPE]: CARD_TYPE.PHYSICAL,
    [API_KEYS.STATUS]: [CARD_STATUS.ACTIVE, CARD_STATUS.FROZEN],
  },
  [PHYSICAL_CARDS_BLOCKED_PATH_NAME]: {
    [API_KEYS.CARD_TYPE]: CARD_TYPE.PHYSICAL,
    [API_KEYS.STATUS]: [CARD_STATUS.BLOCKED],
  },
  [PHYSICAL_CARDS_YET_TO_ACTIVATE_PATH_NAME]: {
    [API_KEYS.CARD_TYPE]: CARD_TYPE.PHYSICAL,
    [API_KEYS.STATUS]: [CARD_STATUS.INACTIVE],
  },
};

export const VIRTUAL_CARD_TAB_STATUS_MAP = {
  [VIRTUAL_CARDS_ALL_PATH_NAME]: {
    [API_KEYS.CARD_TYPE]: CARD_TYPE.VIRTUAL,
    [API_KEYS.STATUS]: [CARD_STATUS.ACTIVE, CARD_STATUS.FROZEN],
  }, // default
  [VIRTUAL_CARDS_BLOCKED_PATH_NAME]: {
    [API_KEYS.CARD_TYPE]: CARD_TYPE.VIRTUAL,
    [API_KEYS.STATUS]: [CARD_STATUS.BLOCKED],
  },
};

export const CARD_FREQUENCY_LABEL = {
  [CARD_FREQUENCY_CORE.MONTHLY]: "cards.frequencyLabel.recurring",
  [CARD_FREQUENCY_CORE.ONE_TIME]: "cards.frequencyLabel.non_recurring",
  [CARD_FREQUENCY_CORE.DAILY]: "cards.frequencyLabel.daily",
  [CARD_FREQUENCY_CORE.WEEKLY]: "cards.frequencyLabel.weekly",
};

export const CARD_COMPONENT_CONFIG = {
  [CARD_STATUS.ACTIVE]: {
    classes: "bg-corporate-cards-50",
    [CARD_TYPE.PHYSICAL]: {
      icon: "CreditCard",
    },
    [CARD_TYPE.VIRTUAL]: {
      icon: "Cloud",
    },
    textClasses: "text-corporate-cards-700",
  },
  [CARD_STATUS.BLOCKED]: {
    classes: "bg-neutral-100",
    [CARD_TYPE.PHYSICAL]: {
      icon: "CreditCard",
    },
    [CARD_TYPE.VIRTUAL]: {
      icon: "Cloud",
    },
    textClasses: "text-neutral-400",
  },
  [CARD_STATUS.INACTIVE]: {
    classes: "bg-corporate-cards-50",
    [CARD_TYPE.PHYSICAL]: {
      icon: "CreditCard",
    },
    [CARD_TYPE.VIRTUAL]: {
      icon: "Cloud",
    },
    textClasses: "text-corporate-cards-700",
  },
  [CARD_STATUS.FROZEN]: {
    classes: "bg-neutral-100",
    [CARD_TYPE.PHYSICAL]: {
      icon: "FreezedPhysicalCard",
    },
    [CARD_TYPE.VIRTUAL]: {
      icon: "FreezeVirtualCard",
    },
    textClasses: "text-neutral-400",
  },
};
export const CREATE_PHYSICAL_CARD_TILDA_LINKS = {
  SG: "https://ww.volopay.co/sg/order-physical-card",
};

export const CARD_STATUS_BADGE_MAP = {
  [CARD_STATUS.ACTIVE]: "success",
  [CARD_STATUS.BLOCKED]: "danger",
  [CARD_STATUS.FROZEN]: "disabled",
  [CARD_STATUS.INACTIVE]: "neutral",
};
export const CARDS_ALL_SLIDER_MENU_CONFIG = {
  [CARDS_ALL_SLIDER_MENU_KEYS.SETTINGS]: {
    label: "cards.settings.title",
    key: CARDS_ALL_SLIDER_MENU_KEYS.SETTINGS,
    icon: "Settings",
  },
  [CARDS_ALL_SLIDER_MENU_KEYS.EDIT_CARD]: {
    label: "cards.editCard",
    key: CARDS_ALL_SLIDER_MENU_KEYS.EDIT_CARD,
    icon: "Edit",
  },
  [CARDS_ALL_SLIDER_MENU_KEYS.FREEZE_CARD]: {
    label: "cards.freezeCard",
    key: CARDS_ALL_SLIDER_MENU_KEYS.FREEZE_CARD,
    icon: "AcUnits",
  },
  [CARDS_ALL_SLIDER_MENU_KEYS.CHANGE_PIN]: {
    label: "cards.changePin",
    key: CARDS_ALL_SLIDER_MENU_KEYS.CHANGE_PIN,
    icon: "ChangePin",
  },
  [CARDS_ALL_SLIDER_MENU_KEYS.UNFREEZE]: {
    label: "cards.unfreeze",
    key: CARDS_ALL_SLIDER_MENU_KEYS.UNFREEZE,
    icon: "AcUnits",
  },
  [CARDS_ALL_SLIDER_MENU_KEYS.BLOCK]: {
    label: "cards.block",
    key: CARDS_ALL_SLIDER_MENU_KEYS.BLOCK,
    icon: "Block",
  },
};

export const CARDS_SLIDER_MENU_VS_STATUS = {
  [CARD_STATUS.ACTIVE]: [
    CARDS_ALL_SLIDER_MENU_CONFIG[CARDS_ALL_SLIDER_MENU_KEYS.SETTINGS],
    CARDS_ALL_SLIDER_MENU_CONFIG[CARDS_ALL_SLIDER_MENU_KEYS.EDIT_CARD],
    CARDS_ALL_SLIDER_MENU_CONFIG[CARDS_ALL_SLIDER_MENU_KEYS.FREEZE_CARD],
    CARDS_ALL_SLIDER_MENU_CONFIG[CARDS_ALL_SLIDER_MENU_KEYS.CHANGE_PIN],
  ],
  [CARD_STATUS.FROZEN]: [
    CARDS_ALL_SLIDER_MENU_CONFIG[CARDS_ALL_SLIDER_MENU_KEYS.UNFREEZE],
    CARDS_ALL_SLIDER_MENU_CONFIG[CARDS_ALL_SLIDER_MENU_KEYS.BLOCK],
  ],
  [CARD_STATUS.BLOCKED]: [
    CARDS_ALL_SLIDER_MENU_CONFIG[CARDS_ALL_SLIDER_MENU_KEYS.UNFREEZE],
    CARDS_ALL_SLIDER_MENU_CONFIG[CARDS_ALL_SLIDER_MENU_KEYS.BLOCK],
  ],
};

export const CARD_ACTION_SLIDER_CONFIG = {
  [CARDS_ALL_SLIDER_MENU_KEYS.BLOCK]: {
    heading: "blockCard.heading",
    subheading: "blockCard.subheading",
    secondaryHeading: "blockCard.secondaryHeading",
    isForm: true,
  },
  [CARDS_ALL_SLIDER_MENU_KEYS.FREEZE_CARD]: {
    heading: "freezeCards.heading",
    subheading: "freezeCards.subheading",
    secondaryHeading: "freezeCards.secondaryHeading",
    isForm: true,
  },
  [CARDS_ALL_SLIDER_MENU_KEYS.UNFREEZE]: {
    heading: "unfreezeCards.heading",
    subheading: "unfreezeCards.subheading",
    secondaryHeading: "unfreezeCards.secondaryHeading",
    isForm: true,
  },
  [CARDS_ALL_SLIDER_MENU_KEYS.CHANGE_PIN]: {
    heading: "changePinCard.heading",
    alert: "changePinCard.alert",
    alertVariant: "warning",
  },
};
export const LINKED_TO_TYPE = {
  PROJECT: "Project",
  DEPARTMENT: "Department",
};

export const ORDER_PHYSICAL_CARDS_TYPE = {
  BULK_PHYSICAL_CARD_ORDER: {
    title: "Bulk Order",
    key: "bulk_order",
  },
  PERSONALISED_PHYSICAL_CARD_ORDER: {
    title: "Personalised Order",
    key: "personalised_order",
  },
  INTERNATIONAL_PHYSICAL_CARD_ORDER: {
    title: "International Cards",
    key: "bulk_international_card_order",
  },
};

export const ORDER_PHYSICAL_CARDS_OPTIONS = [
  {
    label: "Bulk order cards",
    description: "Bulk order cards for your company",
    route: {
      paramName: SLIDERS_SEARCH_PARAMS.cards.physicalCards.create.physicalCard,
      paramValue: ORDER_PHYSICAL_CARDS_TYPE.BULK_PHYSICAL_CARD_ORDER.key,
    },
  },
  {
    label: "Personalised card",
    description: "Order a personalised card for users in your company",
    route: {
      paramName: SLIDERS_SEARCH_PARAMS.cards.physicalCards.create.physicalCard,
      paramValue:
        ORDER_PHYSICAL_CARDS_TYPE.PERSONALISED_PHYSICAL_CARD_ORDER.key,
    },
  },
  {
    label: "Order international cards",
    description: "For spending on international and domestic merchants",
    route: {
      paramName: SLIDERS_SEARCH_PARAMS.cards.physicalCards.create.physicalCard,
      paramValue:
        ORDER_PHYSICAL_CARDS_TYPE.INTERNATIONAL_PHYSICAL_CARD_ORDER.key,
    },
  },
];

export const BULK_PHYSICAL_CARD_TYPE = {
  PERSONALISED_ORDER: "Personalised Order",
  NON_PERSONALISED_ORDER: "Non Personalised Order",
};

export const BULK_PHYSICAL_CARD_OPTIONS = {
  [BULK_PHYSICAL_CARD_TYPE.PERSONALISED_ORDER]: {
    title: "Personalised Order",
    heading:
      "cards.pCards.sliders.createCardSlider.fields.bulkPhysicalCardOrderType.radioOptions.personliasedCards.label",
    value: BULK_PHYSICAL_CARD_TYPE.PERSONALISED_ORDER,
    description:
      "cards.pCards.sliders.createCardSlider.fields.bulkPhysicalCardOrderType.radioOptions.personliasedCards.radioDescripton",
  },
  [BULK_PHYSICAL_CARD_TYPE.NON_PERSONALISED_ORDER]: {
    title: "Non Personalised Order",
    heading:
      "cards.pCards.sliders.createCardSlider.fields.bulkPhysicalCardOrderType.radioOptions.nonPersonalisedCards.label",
    value: BULK_PHYSICAL_CARD_TYPE.NON_PERSONALISED_ORDER,
    description:
      "cards.pCards.sliders.createCardSlider.fields.bulkPhysicalCardOrderType.radioOptions.nonPersonalisedCards.radioDescription",
  },
};

export const PHYSICAL_CARD_MARKET_PLACES = {
  SINGAPORE: {
    isdCode: "+65",
    iso2Code: "SG",
    isoCode: "SGP",
    name: "Singapore",
  },
  AUSTRALIA: {
    isdCode: "+61",
    iso2Code: "AU",
    isoCode: "AUS",
    name: "Australia",
  },
  INDIA: {
    isdCode: "+91",
    iso2Code: "IN",
    isoCode: "IND",
    name: "India",
  },
  INDONESIA: {
    isdCode: "+62",
    iso2Code: "ID",
    isoCode: "IDN",
    name: "Indonesia",
  },
};

export const COUNTRY_SPECIFIC_CARD_PROVIDERS = {
  [CARD_PROVIDER.NIUM]: PHYSICAL_CARD_MARKET_PLACES.SINGAPORE,
  [CARD_PROVIDER.SBM]: PHYSICAL_CARD_MARKET_PLACES.INDIA,
  [CARD_PROVIDER.LIVQUIK]: PHYSICAL_CARD_MARKET_PLACES.INDIA,
  [CARD_PROVIDER.AIRWALLEX]: PHYSICAL_CARD_MARKET_PLACES.AUSTRALIA,
};

export const CARD_ACTIVATION_STATUSES = {
  PENDING: "pending", // when not shipped yet
  IN_PROGRESS: "in_progress", // post activation
  APPROVED: "approved",
};
export const ADMIN_REQUEST_TYPE = {
  FREEZE_CARD: "freeze_card",
  UNFREEZE_CARD: "unfreeze_card",
  BLOCK_CARD: "block_card",
};
export const ADMIN_REQUEST_STATUS = {
  PENDING: "pending",
  IN_PROGRESS: "in_progress",
  APPROVED: "approved",
};

export const LINKED_TO_RADIO_OPTIONS = [
  {
    label: "Department",
    description: "Card will be linked to to the department of the cardholder",
    value: "department",
  },
  {
    label: "Project",
    description: "Card will be linked  to the project",
    value: "project",
  },
];

export const CREATE_VIRTUAL_CARD_FIELD_KEYS = {
  AMOUNT: "amount",
  CARD_HOLDER: "user_ids",
  CARD_NAME: "card_name",
  CURRENCY: "currency",
  DOCUMENT: "attachments",
  FREQUENCY: "budget_type",
  LINKED_TO: "linked_to",
  PROJECT_ID: "project_id",
  MEMO: "reason",
  AUTO_BLOCK_DATE: "expiry",
  TRANSACTION_AMOUNT_LIMIT: "transaction_amount_limit",
  MERCHANT_CATEGORY_CONTROL: "merchant_restriction_attributes",
  CARD_RULES: "rule_attributes",
};

export const entityListTitleConfig = {
  // TECH_DEBT translation
  block: {
    labelObject: {
      // label: "Restricted Categories",
      merchant: "Restricted Merchants",
      category: "Restricted Categories",
      classes: "text-danger-400",
    },
    preIconObject: {
      icon: "Block",
      classes: "text-danger-400",
    },
  },
  allow: {
    labelObject: {
      // label: "Allowed Categories",
      merchant: "Allowed Merchants",
      category: "Allowed Categories",
      classes: "text-success-600",
    },
    preIconObject: {
      icon: "Priority",
      classes: "text-success-600",
    },
  },
};

export const controlRadioOptions = [
  // TECH_DEBT translation
  {
    heading: "Category controls",
    description:
      "You have the option to allow or restrict specific categories for this card",
    value: "category",
  },
  {
    heading: "Merchant controls",
    value: "merchant",
    description:
      "You have the option to allow or restrict specific merchants for this card",
  },
];

export const categoryTypeOptions = [
  // TECH_DEBT translation
  {
    name: "Allow Categories",
    value: "allow",
  },
  {
    name: "Restrict Categories",
    value: "block",
  },
];

export const merchantTypeOptions = [
  // TECH_DEBT translation
  {
    name: "Allow Merchants",
    value: "allow",
  },
  {
    name: "Restrict Merchants",
    value: "block",
  },
];

export const control = {
  CATEGORY: "category",
  MERCHANT: "merchant",
};

export const merchantAndCategoryControlsFieldKeys = {
  CONTROL: "controlType",
  SELECTED_CONTROL_TYPE: "selectedControlType",
  SELECTED_CONTROL_TYPE_OPTIONS: "selectedControlTypeOptions",
  APPLY_TYPE: "applyType",
  DISABLED_OPTIONS: "disabledOptions",
};

export const frequencyOrder = {
  [CARD_FREQUENCY_CORE.DAILY]: 0,
  [CARD_FREQUENCY_CORE.WEEKLY]: 1,
  [CARD_FREQUENCY_CORE.MONTHLY]: 2,
  [CARD_FREQUENCY_CORE.ONE_TIME]: 3,
};

export const APPROVED_KYC_STATUS = "approved";

export const MCC_SELECTED_CONTROLS_TYPES = {
  ALLOW: "allow",
  BLOCK: "block",
};

export const ONE_TIME_RECUR_TEXT = "One time limit";

export const CARD_ACTIVATION_STATUS = {
  PENDING: "pending",
  IN_PROGRESS: "in_progress",
};

export const CARD_PENDING_REQUEST = {
  FREEZE_CARD: "freeze_card",
  BLOCK_CARD: "block_card",
  UNFREEZE_CARD: "unfreeze_card",
  NONE: "none",
};

export const CARD_STATUS_OPERATIONS_MAPPING = {
  freeze: CARD_PENDING_REQUEST.FREEZE_CARD,
  block: CARD_PENDING_REQUEST.BLOCK_CARD,
  unfreeze: CARD_PENDING_REQUEST.UNFREEZE_CARD,
};

export const CARD_SETTINGS_FORM_FIELD_KEYS = {
  CARD_HOLDER: "cardHolder",
  CARD_NAME: "name",
  TRANSACTION_AMOUNT_LIMIT: "transaction_amount_limit",
  EXPIRY: "expiry",
  CARD_CHANNEL: "card_channel",
  MERCHANT_AND_CATEGORY: "merchant_restrictions",
  CARD_RULES: "cardRules",
  LOW_CARD_BALANCE: "low_card_balance.enabled",
  NOTIFY_LOW_CARD_BALANCE: "low_card_balance.amount",
};

export const CARD_SLIDER_TABS_KEY = {
  OVERVIEW: "overview",
  CARD_SETTINGS: "settings",
  HISTORY: "history",
};

export const CARD_SLIDER_SELECTED_TABS_CARD = [
  {
    name: "cards.cardDetails.overview.navigationLabel",
    count: null,
    key: CARD_SLIDER_TABS_KEY.OVERVIEW,
    tabComponentProps: { page: QRPAY_EXPENSES_CONTEXT.EXPENSES },
  },
  {
    name: "cards.cardDetails.overview.settings.navigationLabel",
    count: null,
    key: CARD_SLIDER_TABS_KEY.CARD_SETTINGS,
  },
  {
    name: "cards.cardDetails.overview.history.navigationLabel",
    count: null,
    key: CARD_SLIDER_TABS_KEY.HISTORY,
    showComingSoon: true,
  },
];

export const COMMON_SLIDERS_TRIGGER_KEYS = {
  CARD_SLIDER: "card_slider",
  CREATE_VIRTUAL_CARD: "create_virtual_slider",
  GLOBAL_SETTINGS: "global_settings",
};

export const CARDS_CONTEXT = {
  CARDS: "cards",
};

export const MCC_OWNER_TYPE = {
  CLIENT: "Client",
  CARD: "Card",
};

export const CARD_MODULE_REQUEST_PAGE = "cardModuleRequestPage";
export const CHANGE_PIN_METHODS_TYPE = {
  API: "api",
  URL: "url",
  CUSTOMER_SUPPORT: "customer_support",
};

export const CARD_MODULE_REQUEST_PAGE_TABS = {
  NEEDS_YOUR_ACTION: "needs-my-action",
  PENDING: "pending",
  HISTORY: "history",
  ALL: "all",
};

export const CARDS_CTA_KEYS = {
  orderCard: "orderCard",
  virtualCard: "requestVirtualCard",
};

export const FREEZE_TYPES = {
  ADMIN_FREEZED: "admin_freezed",
  VOLO_ADMIN_FREEZE: "volo_admin_freeze",
};

export const FREEZE_TYPE_LABELS = {
  [FREEZE_TYPES.ADMIN_FREEZED]:
    "cards.cardSlider.statusTimeLine.forzen.freezeTypeLabels.admin",
  [FREEZE_TYPES.VOLO_ADMIN_FREEZE]:
    "cards.cardSlider.statusTimeLine.forzen.freezeTypeLabels.volopay",
};

export const CARDS_TABS_ROUTES_CONGIG = {
  [ROUTES.cards.physicalCards.all.absolutePath]: {
    cardType: CARD_TYPES.PHYSICAL,
    cardStatus: [CARD_STATUS.ACTIVE, CARD_STATUS.FROZEN],
  },
  [ROUTES.cards.physicalCards.yetToActivate.absolutePath]: {
    cardType: CARD_TYPES.PHYSICAL,
    cardStatus: [CARD_STATUS.INACTIVE],
  },
  [ROUTES.cards.physicalCards.blocked.absolutePath]: {
    cardType: CARD_TYPES.PHYSICAL,
    cardStatus: [CARD_STATUS.BLOCKED],
  },
  [ROUTES.cards.virtualCards.all.absolutePath]: {
    cardType: CARD_TYPE.VIRTUAL,
    cardStatus: [CARD_STATUS.ACTIVE, CARD_STATUS.FROZEN],
  },
  [ROUTES.cards.virtualCards.blocked.absolutePath]: {
    cardType: CARD_TYPE.VIRTUAL,
    cardStatus: [CARD_STATUS.BLOCKED],
  },
};

export const CARD_OPERATION_TYPES = {
  FREEZE: "freeze",
  UN_FREEZE: "unfreeze",
  BLOCK: "block",
  CHANGE_PIN: "changePin",
  EDIT_CARD_CONTROLS: "card-controls",
  EDIT_TRANSACTION_LIMIT: "transaction-amount-limit",
};

export const MENU_KEY_ACTION_TYPES = {
  [CARDS_ALL_SLIDER_MENU_KEYS.FREEZE_CARD]: CARD_OPERATION_TYPES.FREEZE,
  [CARDS_ALL_SLIDER_MENU_KEYS.UNFREEZE]: CARD_OPERATION_TYPES.UN_FREEZE,
  [CARDS_ALL_SLIDER_MENU_KEYS.BLOCK]: CARD_OPERATION_TYPES.BLOCK,
  [CARDS_ALL_SLIDER_MENU_KEYS.CHANGE_PIN]: CARD_OPERATION_TYPES.CHANGE_PIN,
};

export const CARDS_SLIDER_ACTION_TYPES = {
  URL: "url",
  INTENT_URL: "intentUrl",
  API: "api",
  INTENT_API: "intentApi",
};

export const CARD_USAGE_TYPES = {
  INTERNATIONAL: "International",
  DOMESTIC: "Domestic",
};

export const VIDEO_PAGE_CONTEXT = {
  MY_VOLOPAY: "myVolopay",
  CARDS: "cards",
};

export const INTERNATIONAL_CARD_OPERATION_SLIDER_CONFIG = {
  [CARDS_ALL_SLIDER_MENU_KEYS.EDIT_CARD_CONTROLS]: {
    mainTitle: "cards.cardSlider.internationalCard.editCardControls.title",
    sectionHeaderTitle:
      "cards.cardSlider.internationalCard.editCardControls.description",
  },
  [CARDS_ALL_SLIDER_MENU_KEYS.EDIT_TRANSACTION_LIMIT]: {
    mainTitle: "cards.cardSlider.internationalCard.editTransactionLimit.title",
    sectionHeaderTitle:
      "cards.cardSlider.internationalCard.editTransactionLimit.description",
  },
  [CARDS_ALL_SLIDER_MENU_KEYS.FREEZE_CARD]: {
    mainTitle: "cards.cardSlider.internationalCard.freezeCard.title",
    sectionHeaderTitle:
      "cards.cardSlider.internationalCard.freezeCard.description",
  },
  [CARDS_ALL_SLIDER_MENU_KEYS.BLOCK]: {
    mainTitle: "cards.cardSlider.internationalCard.blockCard.title",
    sectionHeaderTitle:
      "cards.cardSlider.internationalCard.blockCard.description",
  },
  [CARDS_ALL_SLIDER_MENU_KEYS.UNFREEZE]: {
    mainTitle: "cards.cardSlider.internationalCard.unfreezeCard.title",
    sectionHeaderTitle:
      "cards.cardSlider.internationalCard.unfreezeCard.description",
  },
  [CARDS_ALL_SLIDER_MENU_KEYS.CHANGE_PIN]: {
    mainTitle: "cards.cardSlider.internationalCard.changePin.title",
    sectionHeaderTitle:
      "cards.cardSlider.internationalCard.changePin.description",
  },
};

export const INTERNATIONAL_CARD_ORDER_GUIDELINES = [
  {
    title:
      "cards.cardSlider.internationalCard.orderPhysicalCard.stepper.approval.title",
    description:
      "cards.cardSlider.internationalCard.orderPhysicalCard.stepper.approval.description",
  },
  {
    title:
      "cards.cardSlider.internationalCard.orderPhysicalCard.stepper.requiredDocuments.title",
    descriptionListHeader:
      "cards.cardSlider.internationalCard.orderPhysicalCard.stepper.requiredDocuments.description.headerTitle",
    descriptionList: [
      "cards.cardSlider.internationalCard.orderPhysicalCard.stepper.requiredDocuments.description.list.selfAttachPan",
      "cards.cardSlider.internationalCard.orderPhysicalCard.stepper.requiredDocuments.description.list.selfAttachAadhar",
    ],
  },
  {
    title:
      "cards.cardSlider.internationalCard.orderPhysicalCard.stepper.poc.title",
    descriptionList: [
      "cards.cardSlider.internationalCard.orderPhysicalCard.stepper.poc.descriptionList.contact",
      "cards.cardSlider.internationalCard.orderPhysicalCard.stepper.poc.descriptionList.poc",
    ],
  },
  {
    title:
      "cards.cardSlider.internationalCard.orderPhysicalCard.stepper.submitCompletedForm.title",
    description:
      "cards.cardSlider.internationalCard.orderPhysicalCard.stepper.submitCompletedForm.description",
  },
];

export const BULK_ORDER_SECTION = "bulkOrderSection";

export const SHOW_USER_SELECTED_SECTION = "showUserSelectedSection";

export const ORDER_PHYSICAL_CARD_OPTIONS_KEYS = {
  BULK_PERSONALIZED_ORDER_CARD: "bulkPersonalisedOrderCard",
  BULK_NON_PERSONALIZED_ORDER_CARD: "bulkNonPersonalisedOrderCard",
  INDIVIDUAL_PERSONALIZED_ORDER_CARD: "individualPersonalisedOrderCard",
  BULK_DOMESTIC_PERSONALIZED_ORDER_CARD: "bulkDomesticPersonalisedOrderCard",
  BULK_DOMESTIC_NON_PERSONALIZED_ORER_CARD:
    "bulkDomesticeNonPersonalisedOrderCard",
  INDIVIDUAL_DOMESTIC_PERSONALIZED_ORDER_CARD:
    "individualDomesticPersonalisedOrderCard",
  BULK_INTERNATIONAL_PERSONALIZED_ORDER_CARD:
    "bulkInternationalPersonalisedOrderCard",
  INDIVIDUAL_INTERNATIONAL_ORDER_CARD:
    "individualInternationalPersonalisedOrderCard",
};

export const PHYICAL_CARDS_CTA = {
  PHYSICAL_CARD_COMING_SOON: "physicalCardComingSoon",
  ACTIVATE_CARD: "activateCard",
  ACTIVATE: "activate",
  ORDER: "order",
  ORDER_CARD: "orderCard",
};
