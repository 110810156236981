import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { cardProviderWithInternationalCardOrderEnabledSelector } from "@/store/selectors/client";

import Button from "@/components/core/Button";
import Chip from "@/components/core/Chip";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import CardDetailMask from "@/components/Cards/Sliders/CardSlider/OverviewTab/CardDetailMask";
import ComingSoonBadge from "@/components/common/ComingSoon";
import { capitalizeFirstLetter, commaFormattedNumber } from "@/utils/common";

import {
  CARD_FREQUENCY_CORE,
  CARD_PROVIDER,
  CARD_STATUS,
  CARD_STATUS_TITLE_MAP,
  CARD_TYPE,
  CARD_USAGE_TYPES,
  ORDER_PHYSICAL_CARDS_TYPE,
  PHYICAL_CARDS_CTA,
} from "@/constants/Cards";
import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";

import DotIterator from "./DotIterator";

export default function CardSnapshot({
  chipImage,
  availableBalance = 0,
  cardNumber = "",
  cardType = "",
  currency = "",
  limit,
  cardStatus,
  cardProvider,
  onClick,
  allowedCtas,
  cardId,
  frequency,
  emptyCard = false,
  classes,
  imgClasses,
  showMaskCard = false,
  card,
  cardProviderId,
  cardUsageType,
}) {
  const { t } = useTranslation();
  const additionalLabelsForFrequencyStatement = {
    [CARD_FREQUENCY_CORE.DAILY]: "today",
    [CARD_FREQUENCY_CORE.ONE_TIME]: "one time",
    [CARD_FREQUENCY_CORE.WEEKLY]: "this week",
    [CARD_FREQUENCY_CORE.MONTHLY]: "this month",
  };

  const selectedFrequency = additionalLabelsForFrequencyStatement[frequency];

  const spendFrequencyStatement =
    frequency && frequency.length > 0
      ? "company.people.cardSnapshot.spendFrequency"
      : "";

  const [searchParams, setSearchParams] = useSearchParams();

  const hasCardProvidersWithInternationalCardOrder = useSelector(
    cardProviderWithInternationalCardOrderEnabledSelector
  );

  const availableBalanceTextColor =
    cardStatus === CARD_STATUS.FROZEN
      ? "text-neutral-500"
      : cardType === CARD_TYPE.PHYSICAL
        ? "text-neutral-800"
        : "text-neutral-300";

  const cardTypeCaptalizeText = getCardTypeLabel();

  const cardTypeLabel = "company.people.cardSnapshot.cardTypeLabel";

  const limitValue = `${
    limit ? commaFormattedNumber(limit) : "No limit available "
  }`;

  const limitValueClasses =
    cardType === CARD_TYPE.PHYSICAL ? "text-neutral-800" : "text-neutral-300";

  const cardNumberTextClasses =
    cardType === CARD_TYPE.PHYSICAL ? "text-neutral-800" : "text-neutral-300";

  const showChip = [CARD_STATUS.FROZEN, CARD_STATUS.INACTIVE].includes(
    cardStatus
  );

  const [hasOrderCardKey, hasActivateCardKey, hasShowComingSoon] =
    useMemo(() => {
      let hasCardKey = false;
      let hasActivateKey = false;
      let showComingSoon = false;
      allowedCtas?.forEach((key) => {
        if (key === PHYICAL_CARDS_CTA.PHYSICAL_CARD_COMING_SOON) {
          showComingSoon = true;
        } else if (
          [
            PHYICAL_CARDS_CTA.ACTIVATE_CARD,
            PHYICAL_CARDS_CTA.ACTIVATE,
          ]?.includes(key)
        ) {
          hasActivateKey = true;
        } else if (
          [PHYICAL_CARDS_CTA.ORDER, PHYICAL_CARDS_CTA.ORDER_CARD]?.includes(key)
        ) {
          hasCardKey = true;
        }
      });

      return [hasCardKey, hasActivateKey, showComingSoon];
    }, [allowedCtas]);

  function getCardTypeLabel() {
    if (cardType === CARD_TYPE.PHYSICAL) {
      if (hasCardProvidersWithInternationalCardOrder) {
        if (cardUsageType === CARD_USAGE_TYPES.INTERNATIONAL) {
          return t("company.people.cardSnapshot.internationalCardLabel");
        }

        return t("company.people.cardSnapshot.domesticCardLabel");
      }

      return capitalizeFirstLetter(CARD_TYPE.PHYSICAL);
    }

    return capitalizeFirstLetter(CARD_TYPE.VIRTUAL);
  }

  if (
    cardType === CARD_TYPE.PHYSICAL &&
    cardStatus === CARD_STATUS.INACTIVE &&
    !emptyCard
  ) {
    // TODO: handleClick for order physical is not done , need to be disscussed

    let buttonLabel = "";
    let disableButton = false;
    let showInActiveChip = false;
    let showCardTypeLabel = false;
    let innerContent = null;
    let showToolTip = false;
    let handleButtonClick = () => {};

    if (
      (cardProvider === CARD_PROVIDER.AIRWALLEX &&
        !cardProviderId &&
        hasOrderCardKey) ||
      (!cardProvider && hasOrderCardKey) ||
      hasShowComingSoon
    ) {
      buttonLabel = "company.people.cardSnapshot.orderCardLabel";
      handleButtonClick = () => {
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.cards.physicalCards.create.physicalCard,
          ORDER_PHYSICAL_CARDS_TYPE.PERSONALISED_PHYSICAL_CARD_ORDER.key
        );
        setSearchParams(searchParams);
      };
      innerContent = (
        <div className="flex flex-col gap-2 mb-auto">
          <Text
            translationKey="company.people.cardSnapshot.physicalCardLabel"
            classes="font-bold text-lg text-neutral-800"
          />
          <Text
            translationKey="company.people.cardSnapshot.physicalCardDescription"
            classes="font-medium text-lg text-neutral-500"
          />

          {hasShowComingSoon ? <ComingSoonBadge classes="w-fit" /> : null}
        </div>
      );
    } else {
      if (!hasActivateCardKey) {
        disableButton = true;
        showToolTip = true;
      } else {
        handleButtonClick = () => {
          searchParams.append(SLIDERS_SEARCH_PARAMS.cards.activation, cardId);
          setSearchParams(searchParams);
        };
      }
      showInActiveChip = true;
      showCardTypeLabel = true;
      buttonLabel = "company.people.cardSnapshot.activateNow";
    }
    return (
      <div
        className={`card-wrapper  relative w-80 h-[230px] overflow-hidden flex flex-col gap-4 ${
          !innerContent ? "justify-between" : ""
        } ${classes}`}
      >
        {innerContent}
        <div className="absolute -top-2 right-8">
          <Icon name="CardStraps" className={imgClasses} />
        </div>
        <div className="flex flex-row justify-between w-full">
          {showInActiveChip ? (
            <div className="mt-1 ">
              <Chip
                label="company.people.cardSnapshot.inactive"
                variant="tertiary"
                classes="text-neutral-400"
              />
            </div>
          ) : null}

          {showCardTypeLabel ? (
            <Text
              translationKey={cardTypeLabel}
              translationProps={{ cardTypeCaptalizeText }}
              classes="font-semibold text-sm text-neutral-500"
            />
          ) : null}
        </div>
        {hasShowComingSoon ? null : (
          <Button
            label={buttonLabel}
            classes="w-full"
            disabled={disableButton}
            onClick={handleButtonClick}
            id={disableButton ? "inactive-no-actvate-card" : ""}
          />
        )}
        {showToolTip ? (
          <Tooltip id="inactive-no-actvate-card" direction="top">
            <Text
              translationKey="company.people.cardSnapshot.activateCardAcessToolTipText"
              classes="font-medium text-sm text-neutral-500"
            />
          </Tooltip>
        ) : null}
      </div>
    );
  }

  return showMaskCard ? (
    <CardDetailMask
      slug={cardProvider}
      status={cardStatus}
      cardId={cardId}
      card={card}
    />
  ) : emptyCard ? (
    <div
      className={`relative pt-0 card-wrapper w-80 h-[200px] flex flex-col justify-between ${
        cardType === CARD_TYPE.PHYSICAL
          ? "bg-gradient-to-r from-neutral-50 to-neutral-100"
          : "bg-neutral-800"
      } ${onClick ? "cursor-pointer" : ""}`}
      onClick={onClick}
    >
      <div className="absolute right-8">
        <Icon name="CardStraps" />
      </div>

      <div
        className={`flex flex-row  mt-3 ${
          !showChip ? "justify-end" : "justify-between"
        }`}
      >
        {showChip ? (
          <div className="mt-1">
            <Chip
              label={CARD_STATUS_TITLE_MAP[cardStatus]}
              classes="text-xs !font-bold text-neutral-400"
            />
          </div>
        ) : null}

        <div className="flex flex-col items-end">
          <Text
            translationKey={cardTypeLabel}
            translationProps={{ cardTypeCaptalizeText }}
            classes="font-semibold text-sm text-neutral-500"
          />
        </div>
      </div>

      {chipImage ? <img src={chipImage} alt="ActivationCard" /> : null}
    </div>
  ) : (
    <div
      className={`relative pt-0 card-wrapper w-80 h-[200px] flex flex-col justify-between ${
        cardType === CARD_TYPE.PHYSICAL
          ? "bg-gradient-to-r from-neutral-50 to-neutral-100"
          : "bg-neutral-800"
      } ${onClick ? "cursor-pointer" : ""} ${classes}`}
      onClick={onClick}
    >
      <div className="absolute right-8">
        <Icon name="CardStraps" />
      </div>

      <div
        className={`flex flex-row  mt-3 ${
          !showChip ? "justify-end" : "justify-between"
        }`}
      >
        {showChip ? (
          <div className="mt-1 ">
            <Chip
              label="company.people.cardSnapshot.frozen"
              classes="text-neutral-400"
            />
          </div>
        ) : null}

        <div className="flex flex-col items-end">
          <Text
            translationKey={cardTypeLabel}
            translationProps={{ cardTypeCaptalizeText }}
            classes="font-semibold text-sm text-neutral-500"
          />
          <div className="flex flex-row items-center">
            <DotIterator iterateCount={4} />
            <Text translationKey={cardNumber} classes={cardNumberTextClasses} />
          </div>
        </div>
      </div>

      {chipImage ? <img src={chipImage} alt="ActivationCard" /> : null}

      <div className="flex flex-col">
        <Text
          translationKey={`${commaFormattedNumber(
            availableBalance
          )} ${currency}`}
          classes={`${availableBalanceTextColor} text-xl font-bold`}
        />

        <Text
          translationKey={spendFrequencyStatement}
          translationProps={{ selectedFrequency }}
          classes="font-semibold text-sm text-neutral-400"
        />

        <div className="flex flex-row gap-2">
          <Text
            translationKey="company.people.cardSnapshot.limit"
            classes="font-semibold text-xs text-neutral-400"
          />
          <Text
            translationKey={limitValue}
            classes={`font-semibold text-xs ${limitValueClasses}`}
          />
        </div>
      </div>
    </div>
  );
}

CardSnapshot.propTypes = {
  chipImage: PropTypes.string,
  availableBalance: PropTypes.string,
  cardNumber: PropTypes.string,
  cardType: PropTypes.string,
  currency: PropTypes.string,
  limit: PropTypes.number,
  cardStatus: PropTypes.string,
  onClick: PropTypes.string,
  cardProvider: PropTypes.string,
  allowedCtas: PropTypes.array,
  cardId: PropTypes.string,
  frequency: PropTypes.string,
  emptyCard: PropTypes.bool,
  classes: PropTypes.string,
  imgClasses: PropTypes.string,
  card: PropTypes.object,
  showMaskCard: PropTypes.bool,
  cardProviderId: PropTypes.string,
  cardUsageType: PropTypes.string,
};
