import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useLeftHeaderTitle from "@/hooks/useLeftHeaderTitle";

import { fetchRequestTableInfo } from "@/store/reducers/actionCentre";
import { setCommentsInitialState } from "@/store/reducers/comments";
import {
  fetchAndSelectReport,
  fetchReports,
  setRejectReportReason,
  setRejectedClaimPayload,
  setSelectedReport,
  submitReport,
} from "@/store/reducers/reimbursement";

import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  reimbursementsListSelector,
  rejectReportReasonSelector,
  rejectedClaimPayloadSelector,
  selectedReportSelector,
} from "@/store/selectors/reimbursement";
import { userSelector } from "@/store/selectors/user";

import Button from "@/components/core/Button";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Tabs from "@/components/core/Tabs";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

import Status from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/PaymentSliderFooter/Status";
import { convertFilters } from "@/utils/filters";
import { PAYMENT_STATUSES } from "@/utils/constants/payments";
import { amountToCurrency, dateToTimeStampString } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { APPROVAL_REQUEST_TYPE, APPROVE, REJECT } from "@/constants/myVolopay";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import { ACTIVE } from "@/constants/reimbursement";
import { ROUTES } from "@/constants/routes";

import ReportOverviewTab from "../ReportOverviewTab";

function ReportSlider({ setOnClose, setOnBack }) {
  const tabs = [
    {
      name: "myVolopay.reimbursements.reports.history.slider.tabs.overview",
      count: null,
      key: 1,
    },
    {
      name: "myVolopay.reimbursements.reports.history.slider.tabs.history",
      count: null,
      key: 2,
      showComingSoon: true,
    },
  ];

  const ctaConfig = {
    reject: {
      label: "reimbursement.ctas.reject",
      type: "danger",
      variant: "tertiary",
      onClick: () => {
        // TODO: REJECT API CALL
      },
    },
  };

  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();
  const slider = useRef();

  const selectedReportData = useSelector(selectedReportSelector);

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [approveAll, setApproveAll] = useState(false);
  const [rejectAll, setRejectAll] = useState(false);

  const reportId = searchParam.get(SLIDERS_SEARCH_PARAMS.reports.reportId);
  const selectedTabKey = selectedTab?.key;
  const paymentCompleted = [
    PAYMENT_STATUSES.paid,
    PAYMENT_STATUSES.settledViaCard,
  ].includes(selectedReportData?.status);
  const rejectedClaimPayload = useSelector(rejectedClaimPayloadSelector);
  const rejectReportReason = useSelector(rejectReportReasonSelector);
  const reimbursements = useSelector(reimbursementsListSelector);
  const appliedFilters = convertFilters(useSelector(appliedFilterSelector));

  const currentUser = useSelector(userSelector);
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const cancelHandler = () => {
    searchParam.delete(SLIDERS_SEARCH_PARAMS.reports.reportId);
    setSearchParam(searchParam);
  };

  const scrollToComments = () => {
    slider.current.scrollTop = slider.current.scrollHeight;
  };

  const submitHandler = () => {
    const approvableClaims =
      selectedReportData.claims - selectedReportData.rejectedClaims;
    const isReject =
      rejectReportReason || rejectedClaimPayload?.length === approvableClaims;
    searchParam.delete(SLIDERS_SEARCH_PARAMS.reports.rejectReport);
    setSearchParam(searchParam);

    const payload = {
      operation: isReject ? REJECT : APPROVE,
      rejected_claims: rejectedClaimPayload,
      ...(isReject && { comment: rejectReportReason }),
    };

    dispatch(submitReport({ id: selectedReportData.id, payload, onSuccess }));
  };

  const [tab] = window.location.pathname.split("/").slice(-1);

  const onSuccess = () => {
    setSearchParam({});

    const isActionCentre =
      window.location.pathname ===
      ROUTES.myVolopay.actionCentre.reimbursement.absolutePath;

    const filterParams = {
      page: 1,
      limit: PAGINATION_PER_REQUEST_LIMIT,
      tab,
      ...(appliedFilters ? appliedFilters : {}),
    };
    if (isActionCentre) {
      dispatch(
        fetchRequestTableInfo({
          limit: PAGINATION_PER_REQUEST_LIMIT,
          page: 1,
          request_type: APPROVAL_REQUEST_TYPE.REIMBURSEMENT,
          ...(appliedFilters ? appliedFilters : {}),
        })
      );
    } else {
      if (tab === ACTIVE) filterParams.user = currentUser?.id;
      dispatch(fetchReports(filterParams));
    }
  };

  useEffect(() => {
    handleTabChange(tabs[0]);
  }, []);

  useEffect(() => {
    if (reportId && !selectedReportData) {
      dispatch(
        fetchAndSelectReport({
          reportId,
        })
      );
    }
  }, [reportId, selectedReportData]);

  const titleRef = useLeftHeaderTitle(
    {
      title: selectedReportData?.name,
      icon: "Report",
      iconStyle:
        "bg-success-100 text-success-600 w-10 h-10 rounded-full font-bold",
    },
    { root: null },
    true
  );

  const submitDisabled = () => {
    const totalClaims = selectedReportData?.claims;
    const approvableClaims = totalClaims - selectedReportData.rejectedClaims;
    const approvedClaims = reimbursements.filter(
      (claim) => claim?.status === APPROVE
    )?.length;
    const totalValidRejects = rejectedClaimPayload.filter(
      (payload) => payload?.comment
    )?.length;
    const totalInvalidRejects = rejectedClaimPayload.length - totalValidRejects;

    if (
      !approveAll &&
      !rejectReportReason &&
      approvedClaims + totalValidRejects === approvableClaims
    ) {
      return false;
    }

    if (rejectReportReason) {
      return false;
    }
    if (approveAll && totalInvalidRejects === 0) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    setOnClose((searchParamArray) => {
      if (
        !searchParamArray?.includes(SLIDERS_SEARCH_PARAMS.reports.reportId) ||
        !searchParamArray?.includes(SLIDERS_SEARCH_PARAMS.reports.reviewId)
      ) {
        dispatch(setSelectedReport(null));
        dispatch(setRejectReportReason(null));
        dispatch(setCommentsInitialState());
        dispatch(setRejectedClaimPayload([]));
      }
    });

    setOnBack(() => {
      dispatch(setSelectedReport(null));
    });
  }, []);

  return (
    <div className="slider-content-container">
      <div
        ref={(titleRef, slider)}
        className="slider-content-core scroll-smooth"
      >
        {selectedReportData ? (
          <div className="flex flex-col mt-4">
            <Text
              translationKey={amountToCurrency(selectedReportData?.totalAmount)}
              classes="font-bold text-xl"
            />
            <div className="text-sm text-neutral-500">
              <Text translationKey="myVolopay.reimbursements.reports.history.slider.createdOn" />
              <Text
                translationKey={dateToTimeStampString(
                  selectedReportData?.submissionDate
                )}
                classes="font-semibold"
              />
            </div>
            <div className="flex gap-1 text-sm text-neutral-500 ">
              <Text
                translationKey="myVolopay.reimbursements.reports.history.slider.reportDesc"
                classes="min-w-fit"
              />

              <Text
                translationKey={selectedReportData?.description}
                classes="font-semibold inline-block truncate hover:cursor-pointer"
                id="ha"
              />

              <Tooltip id="ha" maxWidth={450} direction="bottom">
                <Text translationKey={selectedReportData?.description} />
              </Tooltip>
            </div>

            <div className="mt-6">
              <Tabs
                items={tabs}
                selectedTab={selectedTabKey}
                setCurrentTab={handleTabChange}
                mode
              />
            </div>

            {selectedTabKey === 1 ? (
              <ReportOverviewTab
                data={selectedReportData}
                {...{ approveAll, rejectAll, setRejectAll, setApproveAll }}
              />
            ) : null}
          </div>
        ) : (
          <div>
            <LoaderSkeleton count={10} fullWidth classes="py-4 my-2" />
          </div>
        )}
      </div>

      <div className="flex justify-between px-6 py-4 slider-footer ">
        {paymentCompleted ? <Status /> : null}

        <div className="flex items-center justify-end gap-6 ml-auto ">
          <Button
            preIcon="ModeComment"
            variant="tertiary"
            classes="w-fit text-neutral-500 px-5 py-3"
            onClick={scrollToComments}
          />
          {selectedReportData?.ctas?.length && tab !== ACTIVE ? (
            selectedReportData?.ctas?.includes("approve") &&
            selectedReportData?.ctas?.includes("reject") ? (
              <>
                <Button
                  label="reimbursement.createReimbursement.footer.cancel"
                  type="neutral"
                  classes="w-15 px-8 py-3 font-medium"
                  onClick={cancelHandler}
                />

                <Button
                  label="Submit"
                  classes="w-15 px-5 py-3 text-white font-medium"
                  variant="primary"
                  btnType="submit"
                  onClick={submitHandler}
                  disabled={submitDisabled()}
                />
              </>
            ) : (
              <Button
                label={ctaConfig[selectedReportData?.ctas[0]]?.label}
                variant={ctaConfig[selectedReportData?.ctas[0]]?.variant}
                type={ctaConfig[selectedReportData?.ctas[0]]?.type}
                classes="w-16"
                onClick={ctaConfig[selectedReportData?.ctas[0]]?.onClick}
              />
            )
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ReportSlider;
