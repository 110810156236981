import VolopayLogo from "@/assets/icons/VolopayLogo.svg";

export default function ProfileWidget() {
  return (
    <div className="flex items-center">
      <img src={VolopayLogo} alt="logo" />
      <div className="flex flex-col ml-3">
        <span>Volopay</span>
        <span className="text-neutral-500 text-xs">
          Abhiraj Singh • Subscription card (2839)
        </span>
      </div>
    </div>
  );
}
