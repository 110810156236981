import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import {
  fetchReimbursementPaymentHistory,
  setReimbursementPaymentHistoryInitialState,
} from "@/store/reducers/reimbursement";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  isFetchingReimbursementPaymentHistorySelector,
  reimbursementPaymentHistoryHasMoreSelector,
} from "@/store/selectors/reimbursement";
import Badge from "@/components/core/Badge";
import Checkbox from "@/components/core/Checkbox";
import LoaderSkeleton from "@/components/core/LoaderSkeleton";
import Table from "@/components/core/Table";
import Text from "@/components/core/Text";
import BulkActionComponent from "@/components/Accounting/Transactions/common/BulkAction";
import HeaderCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/common/Cells/HeaderCell";
import { amountToCurrency, dateToString } from "@/utils/common";
import { PAYMENT_MODES, STATUS_COLORS } from "@/utils/constants/reimbursement";
import { SORT_KEYS } from "@/utils/constants/sorting";
import { convertFilters } from "@/utils/filters";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";
import {
  PAYMENT_HISTORY_DEFAULT_HEADERS,
  REPORT_PAYMENT_HISTORY_DEFAULT_HEADERS,
} from "@/constants/reimbursement";

export default function ReimbursementPaidSliderPaymentHistoryTable({
  data,
  userId,
  sorting,
  selectedTab,
  setSorting,
  reportEnabled,
  multiSelectData,
  showMultiSelect = false,
  extraApiParam = null,
  inSlider = false,
}) {
  const [searchParams, setSearchParam] = useSearchParams();
  const dispatch = useDispatch();
  const appliedFilters = useSelector(appliedFilterSelector);
  const hasMoreReimbursementHistory = useSelector(
    reimbursementPaymentHistoryHasMoreSelector
  );

  const isFetching = useSelector(isFetchingReimbursementPaymentHistorySelector);

  const onResetReimbursementPaymentHistory = () => {
    dispatch(setReimbursementPaymentHistoryInitialState());
  };

  const loadMoreReimbursementHistory = () => {
    dispatch(
      fetchReimbursementPaymentHistory({
        page: reimbursementPaymentHistoryPageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        user: parseInt(userId, 10),
        [SORT_KEYS.COLUMN]: sorting?.category || sorting?.defaultCategory,
        [SORT_KEYS.DIRECTION]: sorting?.type || sorting?.defaultType,

        ...(extraApiParam ? extraApiParam : {}),
        ...convertFilters(appliedFilters),
      })
    );
  };

  const [
    reimbursementPaymentHistoryPageNum,
    setReimbursementPaymentHistoryPageNum,
  ] = usePagination({
    initialPageNum: 1,
    hasMore: hasMoreReimbursementHistory,
    loadMore: loadMoreReimbursementHistory,
    inSlider: true,
    onReset: onResetReimbursementPaymentHistory,
    filterOptions: {
      user: parseInt(userId, 10),
      selectedTab,
      ...sorting?.type,
      ...appliedFilters,
    },
  });

  const onScroll = () => {
    setReimbursementPaymentHistoryPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll);

  const {
    headerSelected,
    headerSelectedAll,
    bulkSelectAll,
    setBulkSelectAll,
    selectedData,
    isEmpty,
    headerSelectionHandler,
    handleRowSelection,
    totalReimbursementsSelected,
    clearSelection,
    deSelectedIds,
    exportHandler,
    setDeSelectedIds,
  } = multiSelectData ?? {};

  const { tableSetting, tableHeadingRowClasses, tableHeading } = reportEnabled
    ? REPORT_PAYMENT_HISTORY_DEFAULT_HEADERS
    : PAYMENT_HISTORY_DEFAULT_HEADERS;

  return (
    <Table
      {...tableSetting}
      bulkApproveVisible={selectedData?.length > 0}
      bulkApproveHeight="100px"
      bulkApproveContent={
        <BulkActionComponent
          selectedRows={
            deSelectedIds?.length
              ? totalReimbursementsSelected - (deSelectedIds?.length ?? 0)
              : selectedData?.length
          }
          totalRows={totalReimbursementsSelected}
          descriptionText="common.exportDesc"
          bulkApproveSelectAll={
            (bulkSelectAll && !deSelectedIds?.length) ||
            selectedData?.length === totalReimbursementsSelected
          }
          showSelectAllButton={headerSelectedAll}
          selectAllHandler={(val) => {
            setDeSelectedIds([]);
            setBulkSelectAll(val);
          }}
          handleExport={exportHandler}
          showExport
          clearSelection={clearSelection}
        />
      }
    >
      <tr className={tableHeadingRowClasses}>
        {tableHeading?.map((headVal, index) => (
          <th className={headVal.classes} key={headVal.id}>
            <HeaderCell
              val={{
                ...headVal,
                onCheckboxClick: headerSelectionHandler,
                checkedValue: headerSelected && !isEmpty,
                showCheckbox: showMultiSelect
                  ? index === 0 && showMultiSelect && !isFetching
                  : false,
                disabled: isEmpty,
                sorting,
                setSorting,
              }}
            />
          </th>
        ))}
      </tr>

      {data?.list?.map((paymentHistoryItem, index, arr) => {
        const isCheckedCondition = selectedData?.includes(
          paymentHistoryItem?.id
        )
          ? "selected-row-cell"
          : "";

        return (
          <tr
            className="history-table report-history-table"
            key={`paid-slider-payment-history-${index}`}
            onClick={() => {
              searchParams.append(
                SLIDERS_SEARCH_PARAMS.reimbursements.paymentHistoryId,
                paymentHistoryItem.id
              );

              setSearchParam(searchParams);
            }}
            ref={(ref) => {
              if (
                index === (arr?.length ?? 0) - 1 &&
                hasMoreReimbursementHistory
              ) {
                handleRefChange(ref);
              }
            }}
          >
            <td className={`text-sm text-left ${isCheckedCondition} `}>
              <div className="flex items-center gap-3">
                {showMultiSelect ? (
                  <Checkbox
                    checked={
                      selectedData?.includes(paymentHistoryItem?.id) ?? false
                    }
                    onClickHandler={() =>
                      handleRowSelection(paymentHistoryItem)
                    }
                  />
                ) : null}

                <Text
                  translationKey={dateToString(paymentHistoryItem.paymentDate)}
                />
              </div>
            </td>
            <td className={`text-right ${isCheckedCondition}`}>
              <div className="text-sm font-semibold">
                {amountToCurrency(
                  paymentHistoryItem.amount.value,
                  paymentHistoryItem.amount.currency
                )}
              </div>
              {paymentHistoryItem.amount.currency ===
                paymentHistoryItem.beneficiaryAmount.currency ||
              [
                PAYMENT_MODES.payOutsideVolopay,
                PAYMENT_MODES.settleViaCard, // always in base currency, no subtext needed.
              ].includes(paymentHistoryItem.status) ? null : (
                <div className="text-xs text-neutral-500">
                  {amountToCurrency(
                    paymentHistoryItem.beneficiaryAmount.value,
                    paymentHistoryItem.beneficiaryAmount.currency
                  )}
                </div>
              )}
            </td>

            <td className={`${isCheckedCondition}`}>
              <div className="mx-auto w-min">
                <Badge
                  variant={
                    paymentHistoryItem
                      ? STATUS_COLORS[paymentHistoryItem.status.toLowerCase()]
                          ?.color
                      : "success"
                  }
                  translationKey={
                    STATUS_COLORS[paymentHistoryItem.status.toLowerCase()]?.name
                  }
                  classes="w-15 h-6 text-xs"
                />
              </div>
            </td>
            <td className={`text-sm text-center ${isCheckedCondition}`}>
              <Text
                noTranslate
                translationKey={
                  paymentHistoryItem.settledClaims ??
                  paymentHistoryItem?.settledReports
                }
              />
            </td>
          </tr>
        );
      })}
      {isFetching &&
        [...Array(10)].map((val, i) => (
          <>
            <tr className="text-center">
              <td className="flex items-center gap-3 ml-4">
                <LoaderSkeleton type="circle" />
                <LoaderSkeleton size={[20, 180]} />
              </td>
              <td className="text-right">
                <LoaderSkeleton size="lg" />
              </td>
              <td className="text-center">
                <LoaderSkeleton size="lg" />
              </td>
              <td className="text-center">
                <LoaderSkeleton />
              </td>
            </tr>
          </>
        ))}
    </Table>
  );
}
ReimbursementPaidSliderPaymentHistoryTable.propTypes = {
  data: PropTypes.object,
  userId: PropTypes.number,
  sorting: PropTypes.object,
  setSorting: PropTypes.func,
  reportEnabled: PropTypes.bool,
  selectedTab: PropTypes.object,
  multiSelectData: PropTypes.object,
  extraApiParam: PropTypes.object,
  showMultiSelect: PropTypes.bool,
  inSlider: PropTypes.bool,
};
