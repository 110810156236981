import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateTagAndVendor } from "@/store/reducers/accounting_transactions";
import { fetchLineItems } from "@/store/reducers/payments";

import {
  accountingIntegrationSoftwareSelector,
  transactionLevelTagsSetSelector,
} from "@/store/selectors/client";
import { selectedPaymentSelector } from "@/store/selectors/payments";
import {
  accountingCategoryTagSelector,
  accountingNonCategoryTags,
  accountingTagsSelector,
  billPayCustomTagsSelector,
  payrollCustomTagsSelector,
} from "@/store/selectors/tags";

import Table from "@/components/core/Table";
import Text from "@/components/core/Text";

import LineItemTaxCell from "@/components/common/BillPayAndPayroll/PaymentWorkflow/Payments/PaymentSlider/LineItemTaxCell";
import TagInput from "@/components/common/BillPayAndPayroll/VendorOrEmployee/common/TagInput";
import { BILL_PAYROLL_CONTEXT } from "@/utils/constants/paymentsStore";
import { PAYMENT_STATUSES } from "@/utils/constants/payments";
import { amountToCurrency } from "@/utils/common";

import {
  ACCOUNTING_PAYMENTS_TAG_TYPES,
  ACCOUNTING_TRANSACTION_PAGES,
} from "@/constants/accounting";

/**
 *
 * payroll {@link https://www.figma.com/file/GJPfiUwCkfGEz6CubufE0x/v2-Bill-Pay?type=design&node-id=3796-196702&mode=dev}
 */
export default function LineItemsTable({
  lineItems,
  context,
  accountingStatus,
}) {
  const inPayrollContext = context === BILL_PAYROLL_CONTEXT.PAYROLL;
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const accountingEnabled = !!accountingSoftware;
  const _accountingTags = useSelector(accountingTagsSelector);
  const _accountingCategoryTag = useSelector(accountingCategoryTagSelector);
  const _accountingNonTags = useSelector(accountingNonCategoryTags);
  const _billpayCustomTags = useSelector(billPayCustomTagsSelector);
  const _payrollCustomTags = useSelector(payrollCustomTagsSelector);
  const _customTags = inPayrollContext
    ? _payrollCustomTags
    : _billpayCustomTags;
  const netSuiteTagsSet = useSelector(transactionLevelTagsSetSelector);

  const accountingCategoryTag = useMemo(
    () =>
      netSuiteTagsSet.has(_accountingCategoryTag?.id)
        ? null
        : _accountingCategoryTag,
    [_accountingCategoryTag, netSuiteTagsSet]
  );
  const accountingTags = useMemo(
    () => _accountingTags.filter((tag) => !netSuiteTagsSet.has(tag.id)),
    [_accountingTags, netSuiteTagsSet]
  );
  const accountingNonTags = useMemo(
    () => _accountingNonTags.filter((tag) => !netSuiteTagsSet.has(tag.id)),
    [_accountingNonTags, netSuiteTagsSet]
  );
  const customTags = useMemo(
    () => _customTags.filter((tag) => !netSuiteTagsSet.has(tag.id)),
    [_customTags, netSuiteTagsSet]
  );

  const allTags = useMemo(
    () => [
      ...(accountingEnabled && accountingCategoryTag
        ? [accountingCategoryTag]
        : []),
      ...(accountingEnabled ? accountingNonTags : []),
      ...customTags,
    ],
    [
      inPayrollContext,
      accountingEnabled,
      accountingCategoryTag,
      accountingNonTags,
      customTags,
    ]
  ); // avoid accounting tags for payroll

  const [accountingTag, setAccountingTag] = useState([]);
  const [customTag, setCustomTag] = useState([]);
  const payment = useSelector(selectedPaymentSelector);
  const dispatch = useDispatch();
  const doAccountingTagMapping = (tags, setState) => {
    const totalArrayLineItems = new Array(lineItems?.length ?? 1).fill({});
    setState(
      totalArrayLineItems.map((_, index) =>
        tags
          ?.map((item) => {
            const lineItemsAllValue = lineItems?.[index]?.lineItemTagValues;
            const selectedOption = item?.options?.find(
              (option) =>
                option?.name ===
                lineItemsAllValue?.find(
                  (lineItem) => +lineItem.tagId === +item.id
                )?.tagValue
            );
            return { [item?.id]: selectedOption?.id ?? "" };
          })
          ?.reduce((acc, curr) => ({ ...acc, ...curr }), {})
      )
    );
  };
  useEffect(() => {
    doAccountingTagMapping(accountingTags, setAccountingTag);
    doAccountingTagMapping(customTags, setCustomTag);
  }, [customTags, accountingTags, JSON.stringify(lineItems)]);
  // accounting tag headers, with the first one being 'accounting category tag'

  // row cells for accounting tags - array of arrays, with first element of each being 'accounting category tag'

  // tax headers
  const taxHeaders = useMemo(() => {
    if (!lineItems?.length) return [];

    const firstLineItem = lineItems?.[0]; // taking 1st, all are the same anyway
    const { taxes = [] } = firstLineItem;
    return taxes.map((item) => item.name);
  });

  const handleAPiCall = (payload, onSuccess) => {
    dispatch(updateTagAndVendor({ payload, onSuccess }));
  };
  const getPayload = (lineItem, name, value, isDropdown = false) => ({
    accountable_type: inPayrollContext
      ? ACCOUNTING_TRANSACTION_PAGES.PAYROLL
      : ACCOUNTING_TRANSACTION_PAGES.PURCHASE_BILL,
    accounting_id: payment?.accountingId,
    line_item_id: lineItem?.id,
    tag_id: name,
    ...(isDropdown ? { tag_value_id: value } : { custom_text_value: value }),
    transaction_type: ACCOUNTING_PAYMENTS_TAG_TYPES.LINE_ITEM,
  });

  const handleChangeOfTag = (e, lineItem) => {
    const { type, ...rest } = e?.target || e;
    const isDropdown = type === "vp-dropdown";
    const name = isDropdown ? rest.name : rest.id;
    const value = rest.value || rest.textValue;

    const payload = getPayload(lineItem, name, value, type === "vp-dropdown");
    handleAPiCall(payload, () => {
      const draftId = payment?.owner?.id || payment?.id;
      dispatch(fetchLineItems({ id: draftId, context }));
    });
  };

  return (
    <div className="flex flex-col overflow-hidden ">
      <div className="overflow-x-auto">
        <Table
          colWidths={[70, 300, 200]}
          rightColWidths={[
            ...allTags.map((_) => 300),
            ...Array(taxHeaders.length).fill(200),
          ]}
        >
          <tr className="text-xs text-left bg-neutral-50 text-neutral-800 border-neutral-200">
            {/* fixed headers */}
            <th className="font-semibold text-center">
              <Text translationKey="billPay.bill.payments.invoiceDetails.invoiceTable.srHeading" />
            </th>
            <th className="font-semibold text-left">
              <Text translationKey="billPay.bill.payments.invoiceDetails.invoiceTable.descriptionHeading" />
            </th>
            <th className="font-semibold text-right">
              <Text translationKey="billPay.bill.payments.invoiceDetails.invoiceTable.amountHeading" />
            </th>

            {allTags?.map((item) => (
              <th key={item.id}>
                <Text translationKey={item.name} noTranslate />
              </th>
            ))}

            {/* tax headers */}
            {taxHeaders.map((taxName) => (
              <th
                key={`taxes-table-header-${taxName}`}
                className="font-semibold text-left"
              >
                <span>{taxName}</span>
              </th>
            ))}
          </tr>
          {lineItems?.map((lineItem, index) => (
            <tr
              key={`payment-table-child-${lineItem?.id || index}`}
              className="text-sm font-semibold text-neutral-800"
            >
              {/* fixed columns */}
              <td className="text-center">{index + 1}</td>
              <td>{lineItem.description || "-"}</td>
              <td className="text-right">
                {amountToCurrency(lineItem.amount)}
              </td>
              {allTags?.map((item) => {
                const selectedValue = lineItem?.lineItemTagValues?.find(
                  (tag) => tag?.tagId === item?.id
                );

                const isEditable = accountingStatus !== PAYMENT_STATUSES.synced;

                return (
                  <td key={item?.id} className="table-tapinput">
                    <TagInput
                      key={item?.id}
                      tag={item}
                      handleChange={(e) =>
                        handleChangeOfTag(e, lineItem, index)
                      }
                      values={
                        selectedValue?.tagValueId || selectedValue?.tagValue
                      } // both text and list covered
                      isDirectValue
                      wantValueOnBlur={isEditable}
                      wantEditable={isEditable}
                      disabled={!payment?.editable || !isEditable} // this is a read-only table
                      dropdownInputKey={item.id}
                      insideTable
                      textInputKey={item.id?.toString()}
                      vpSelectProps={{
                        hideLabelAfterSelect: true,
                        labelTranslate: false,
                      }}
                    />
                  </td>
                );
              })}
              {/* tax columns */}
              {lineItem?.taxes?.map?.((tax, idx) => (
                <td key={tax?.name ?? idx}>
                  <LineItemTaxCell tax={tax} lineItemAmount={lineItem.amount} />
                </td>
              ))}
            </tr>
          ))}
        </Table>
      </div>
    </div>
  );
}
LineItemsTable.propTypes = {
  lineItems: PropTypes.array,
  context: PropTypes.string,
  accountingStatus: PropTypes.string,
};
