import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  bulkUpdateNotificationPreferences,
  fetchNotificationPreferences,
} from "@/store/reducers/notification_preferences";

import { isLoadingSelector } from "@/store/selectors/notification_preferences";

import {
  slackNotificationEnabledSelector,
  whatsappNotificationEnabledSelector,
} from "@/store/selectors/client";
import Button from "@/components/core/Button";
import Checkbox from "@/components/core/Checkbox";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import { NOTIFICATION_PREFRENECES_CHANNELS } from "@/constants/settings";

import NotificationResetToDefaultModal from "../Modals/NotificationResetToDefaultModal";
import NotificationsSavePopUp from "../NotificationsSavePopup";

export default function NotificationsSelection({
  notificationPreferences,
  tab,
  changesCount,
  setChangesCount,
}) {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);

  const [showAccordion, setShowAccordion] = useState({});
  const [showResetToDefaultModal, setShowResetToDefaultModal] = useState(false);
  const [notificationState, setNotificationState] = useState({});
  const [originalNotificationState, setOriginalNotificationState] = useState(
    {}
  );
  const slackNotificationEnabled = useSelector(
    slackNotificationEnabledSelector
  );
  const whatsappNotificationEnabled = useSelector(
    whatsappNotificationEnabledSelector
  );
  const [notificationParamsList, setNotificationsParamsList] = useState([]);

  const handleSaveChanges = async () => {
    await dispatch(
      bulkUpdateNotificationPreferences({ actions: notificationParamsList })
    );
    await dispatch(fetchNotificationPreferences());
    setChangesCount(0);
  };

  const handleShowResetModal = () => {
    setShowResetToDefaultModal(true);
  };

  useEffect(() => {
    setShowAccordion((prevAccordion) => {
      if (Object.keys(prevAccordion)?.length === 0) {
        const initialSelectors = Object.keys(notificationPreferences)?.reduce(
          (acc, key) => {
            acc[key] = false;
            return acc;
          },
          {}
        );
        return initialSelectors;
      }
      return prevAccordion;
    });

    // original state was also changing when the current state was changing
    const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

    const updatedNotificationState = {};

    const channelsToCheck = Object.values(NOTIFICATION_PREFRENECES_CHANNELS);

    Object.keys(notificationPreferences).forEach((category) => {
      updatedNotificationState[category] = {};

      channelsToCheck.forEach((channel) => {
        const allItemsHaveChannel = notificationPreferences[category].every(
          (item) => item.channels.includes(channel)
        );
        updatedNotificationState[category][channel] = allItemsHaveChannel;
      });

      notificationPreferences[category].forEach((item) => {
        updatedNotificationState[category][item.name] = {
          email: item.channels.includes(
            NOTIFICATION_PREFRENECES_CHANNELS.EMAIL
          ),
          push: item.channels.includes(NOTIFICATION_PREFRENECES_CHANNELS.PUSH),
          ...(slackNotificationEnabled
            ? {
                slack: item.channels.includes(
                  NOTIFICATION_PREFRENECES_CHANNELS.SLACK
                ),
              }
            : {}),
          ...(whatsappNotificationEnabled
            ? {
                whatsapp: item.channels.includes(
                  NOTIFICATION_PREFRENECES_CHANNELS.WHATSAPP
                ),
              }
            : {}),
        };
      });
    });

    setNotificationState(updatedNotificationState);
    setOriginalNotificationState(deepCopy(updatedNotificationState));
  }, [tab, notificationPreferences]);

  const notificationPreferencesChannels = useMemo(() => {
    const preferencesChannels = {
      ...NOTIFICATION_PREFRENECES_CHANNELS,
    };
    if (!slackNotificationEnabled) delete preferencesChannels.SLACK;
    if (!whatsappNotificationEnabled) delete preferencesChannels.WHATSAPP;
    return preferencesChannels;
  }, [slackNotificationEnabled, whatsappNotificationEnabled]);

  useEffect(() => {
    setChangesCount(countChanges(notificationState, originalNotificationState));
  }, [notificationState]);

  const handleCheckAll = (category, channel, value) => {
    setNotificationState((prevNotificationState) => {
      const updatedCategoryState = { ...prevNotificationState[category] };

      // Update the specified channel for the entire category
      updatedCategoryState[channel] = value;

      // Set the specified channel for all items in the category
      Object.keys(updatedCategoryState).forEach((itemName) => {
        if (typeof updatedCategoryState[itemName] === typeof {}) {
          updatedCategoryState[itemName][channel] = value;
          const paramObject = {
            notification_preference_name: itemName,
            channel,
            enable: value,
          };

          handleUpdateNotificationsParamList({
            param: paramObject,
            removeObject:
              !originalNotificationState[category][itemName][channel] && !value,
          });
        }
      });

      // Update the state with the modified category
      const updatedNotificationState = {
        ...prevNotificationState,
        [category]: updatedCategoryState,
      };

      return updatedNotificationState;
    });
  };

  const handleCheckboxChange = (category, itemName, channel, value) => {
    setNotificationState((prevNotificationState) => {
      const categoryState = prevNotificationState[category];

      if (!categoryState || !categoryState[itemName]) {
        return prevNotificationState;
      }

      const updatedCategoryState = {
        ...categoryState,
        [itemName]: {
          ...categoryState[itemName],
          [channel]: value,
        },
      };

      const categoryItems = Object.values(updatedCategoryState).filter(
        (item) => typeof item === typeof {}
      );

      const allChecked = categoryItems.every((item) => item[channel]);

      const updatedCategory = {
        ...updatedCategoryState,
        [channel]: allChecked,
      };

      const updatedNotificationState = {
        ...prevNotificationState,
        [category]: updatedCategory,
      };

      return updatedNotificationState;
    });

    const paramObject = {
      notification_preference_name: itemName,
      channel,
      enable: value,
    };

    handleUpdateNotificationsParamList({
      param: paramObject,
      removeObject:
        !originalNotificationState[category]?.[itemName]?.[channel] && !value,
    });
  };

  const flattenObject = (obj, parentKey = "") => {
    return Object.keys(obj).reduce((acc, key) => {
      const currentKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof obj[key] === typeof {} && obj[key] !== null) {
        return { ...acc, ...flattenObject(obj[key], currentKey) };
      }

      return { ...acc, [currentKey]: obj[key] };
    }, {});
  };

  const countChanges = (currentState, originalState) => {
    const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

    const flattenedCurrentState = flattenObject(deepClone(currentState));
    const flattenedOriginalState = flattenObject(deepClone(originalState));

    const changedKeys = Object.keys(flattenedCurrentState).filter(
      (key) => flattenedCurrentState[key] !== flattenedOriginalState[key]
    );

    const excludeKeys = Object.values(NOTIFICATION_PREFRENECES_CHANNELS);

    const filteredChangedKeys = changedKeys.filter((key) => {
      const keyParts = key.split(".");
      return !excludeKeys.includes(keyParts[1]);
    });

    return filteredChangedKeys.length;
  };

  function handleUpdateNotificationsParamList({ param, removeObject }) {
    setNotificationsParamsList((prevParams) => {
      if (removeObject) {
        const updatedParams = prevParams.filter(
          (existingParam) =>
            existingParam.notification_preference_name !==
              param.notification_preference_name ||
            existingParam.channel !== param.channel
        );
        return updatedParams;
      }

      const isDuplicateIndex = prevParams.findIndex((existingParam) =>
        areObjectsEqual(existingParam, param)
      );

      if (isDuplicateIndex !== -1) {
        const updatedParams = prevParams.map((existingParam, index) =>
          index === isDuplicateIndex ? param : existingParam
        );

        return updatedParams;
      }

      return [...prevParams, param];
    });
  }

  function areObjectsEqual(obj1, obj2) {
    return (
      obj1.notification_preference_name === obj2.notification_preference_name &&
      obj1.channel === obj2.channel
    );
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="mt-9">
        <Button
          label="settings.notificationPreferences.buttonLabel.resetToDefault"
          labelStyleClasses="text-base font-semibold text-primary-500"
          preIcon="Sync"
          classes="w-fit px-5 py-3"
          variant="tertiary"
          onClick={handleShowResetModal}
        />
        {showResetToDefaultModal ? (
          <NotificationResetToDefaultModal
            showModal={showResetToDefaultModal}
            setShowModal={setShowResetToDefaultModal}
            tab={tab}
          />
        ) : null}
      </div>
      <div className="flex flex-col gap-11 mb-7">
        {Object.entries(notificationPreferences)?.map(([key, value]) =>
          value?.length ? (
            <div key={`notification-${key}`}>
              <div className="flex flex-col w-full gap-6">
                <div className="flex flex-row justify-around">
                  <div className="flex flex-row items-center w-1/2 gap-3">
                    <div className="p-2 rounded-full bg-neutral-100">
                      <Icon
                        name={showAccordion[key] ? "ChevronUp" : "ChevronDown"}
                        className="w-5 h-5 cursor-pointer text-neutral-500"
                        handleClick={() => {
                          setShowAccordion((prev) => ({
                            ...prev,
                            [key]: !prev[key],
                          }));
                        }}
                      />
                    </div>
                    <Text
                      translationKey={`${key} `}
                      classes="text-xl font-bold text-neutral-800 first-letter:capitalize"
                    />
                  </div>
                  <div className="flex items-center justify-end w-full">
                    {Object.entries(NOTIFICATION_PREFRENECES_CHANNELS).map(
                      ([configKey, configValue]) => (
                        <div
                          className="flex flex-col items-center gap-2 w-14.7"
                          key={configKey}
                        >
                          <Checkbox
                            checked={notificationState[key]?.[configValue]}
                            onClickHandler={(val) =>
                              handleCheckAll(key, configValue, val)
                            }
                          />

                          <Text
                            translationKey={configKey}
                            classes="text-base font-semibold text-neutral-500 lowercase first-letter:capitalize"
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>

                <hr className="text-neutral-300" />
                {value?.map((item) => (
                  <div
                    key={`${key}-${item?.alias}-notification-preferences`}
                    className={`${
                      showAccordion[key] ? "block" : "hidden"
                    } flex`}
                  >
                    <div className="flex flex-col gap-1">
                      <Text
                        translationKey={item?.alias}
                        classes="text-base font-semibold text-neutral-800"
                      />
                      <Text
                        translationKey={item?.description}
                        classes="text-sm font-medium text-neutral-500"
                      />
                    </div>
                    <div className="flex items-center justify-end w-full">
                      {Object.entries(notificationPreferencesChannels).map(
                        ([configKey, configValue]) => (
                          <div
                            className="flex flex-col items-center gap-2 w-14.7"
                            key={configKey}
                          >
                            <Checkbox
                              checked={
                                notificationState[key]?.[item?.name]?.[
                                  configValue
                                ]
                              }
                              onClickHandler={(val) =>
                                handleCheckboxChange(
                                  key,
                                  item?.name,
                                  configValue,
                                  val
                                )
                              }
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null
        )}
      </div>
      {changesCount > 0 ? (
        <NotificationsSavePopUp
          changesCount={changesCount}
          handleCancel={() => {
            setNotificationState(originalNotificationState);
          }}
          handleSave={handleSaveChanges}
          isLoading={isLoading}
        />
      ) : null}
    </div>
  );
}

NotificationsSelection.propTypes = {
  notificationPreferences: PropTypes.object,
  tab: PropTypes.object,
  changesCount: PropTypes.number,
  setChangesCount: PropTypes.func,
};
