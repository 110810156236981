import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import VisaCorporateIcon from "@/assets/images/VISA_Corporate_1.svg";

function AirwallexIframeComponent({ token, cards, hide }) {
  const [cardToken, setCardToken] = useState("");

  function getAirwallexCardDetailsIframeUrl() {
    const hash = {
      token,
      rules: {
        body: { overflow: "hidden" },
        ".details": {
          backgroundColor: "white",
          color: "#54545E",
          fontFamily: "Arial",
          margin: "0",
        },
        ".details__row": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0px 0px 12px",
          paddingBottom: "12px",
          borderBottom: "1px solid #EBECF0",
        },
        ".details__content": {
          fontWeight: "400",
          fontSize: "14px",
          overflow: "hidden",
        },
        ".details__label": {
          marginBottom: "8px",
          color: "#2A2A2A",
          width: "100px",
          fontWeight: "bold",
          fontSize: "13px",
        },
        ".details__value": {
          margin: "0",
        },
        ".details__button": {
          display: "inline-flex",
          padding: "8px 17px",
          cursor: "pointer",
        },
        ".details__button svg": { color: "#6B7281" },
      },
    };

    const hashUri = encodeURIComponent(JSON.stringify(hash));

    return `${import.meta.env.VITE_AIRWALLEX_CARD_HOST}/issuing/pci/v2/${cards?.providerCardId}/details#${hashUri}`;
  }

  useEffect(() => {
    if (token && cards?.providerCardId) {
      setCardToken(getAirwallexCardDetailsIframeUrl(cards, token));
    }
  }, [token, cards]);

  if (!cardToken) {
    return null;
  }

  return (
    <div className={`flex justify-between ${hide ? "blur-sm" : ""}`}>
      <iframe
        src={cardToken}
        title="airwallecx card frame"
        className="w-[60%] h-[250px] p-5"
      />
      <div className="flex flex-col items-end justify-between p-4 pl-0">
        <div className="flex gap-1 right-4">
          <Icon className="text-neutral-600" name="VolopayLogo" />

          <Text
            classes="text-lg font-extrabold text-neutral-600"
            translationKey="bankDetailsViaMail.leftColumn.volopayLogo"
          />
        </div>
        <div>
          <div>
            <img src={VisaCorporateIcon} alt="network icon" className="h-9 " />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AirwallexIframeComponent;
AirwallexIframeComponent.propTypes = {
  token: PropTypes.string,
  cards: PropTypes.object,
  hide: PropTypes.bool,
};
