import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { moduleHistoryEnabledSelector } from "@/store/selectors/client";
import Chip from "@/components/core/Chip";
import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";

import ComingSoonBadge from "@/components/common/ComingSoon";

/**
 * Tabs component - shows tabs and renders configured UI below
 *
 * Two modes:
 *  1. Router mode - tabs rendered. Content UI rendered below.
 *     Usage:
 *     Step 1: Create a component (say "main") that uses Tabs inside. Add the component into React Router markup.
 *     Step 2: Create multiple components - one for each tab. This will be the tab content.
 *     Step 3: Add these components into the React Router markup too, but nested and routed inside the "main" component.
 *
 *    That's it. When a tab is clicked --> core/Tabs will navigate page to the clicked tab's `path` --> respective content will render.
 *  2. Standalone mode - only the tabs are rendered.
 *    Usage: You handle the content UI outside (usually a state is maintained, which conditionally renders the content).
 *
 *
 * @param {Array<Object>} items tab configs (name, key, path? count?)
 * @param {Number} selectedTab `key` of selected tab
 * @param {Boolean} mode  run in standalone mode (without router)? Default is `false`
 * @param {Any} setCurrentTab callback on tab click (you get clicked tab object as first param)
 * @param {Any} classes  styles classes
 * @param {Any} itemClasses styles classes (tab level)
 * @param {Any} conditionalTabChange boolean flag if tab needs to be changed conditionally
 *
 */
// use the prop "mode" to run the tab component in a programmatic mode
export default function Tabs({
  items = [],
  selectedTab,
  mode = false,
  setCurrentTab,
  classes = "font-medium",
  itemClasses = "",
  conditionalTabChange = false,
  disabled = false,
}) {
  const [active, setActive] = useState(items[0].key);
  const navigate = useNavigate();
  const location = useLocation();
  const moduleHistoryEnabled = useSelector(moduleHistoryEnabledSelector);

  selectedTab = useMemo(() => {
    if (!mode) {
      return items.find(({ path }) => path === location.pathname)?.key;
    }
    return selectedTab;
  }, [location.pathname, mode]);

  useEffect(() => {
    const { search } = location;
    if (selectedTab || selectedTab === 0) {
      const currentTab = items.find((val) => val.key === selectedTab);
      if (!conditionalTabChange) setActive(currentTab.key);
      if (!mode) {
        if (location.pathname !== `${currentTab.path}${search}`)
          navigate(`${currentTab.path}${search}`);
      }
      setCurrentTab(currentTab);
    } else if (!mode) {
      setCurrentTab(items[0]);
      if (location.pathname !== `${items[0].path}${search}`)
        navigate(`${items[0].path}${search}`);
    }
  }, [selectedTab]);

  const handleNavigation = (val) => {
    if (conditionalTabChange) {
      setCurrentTab(val, setActive);
    } else {
      setActive(val.key);
      if (!mode) {
        if (location.pathname !== val.path) navigate(`${val.path}`);
      }
      setCurrentTab(val);
    }
  };

  // slide logic
  const [slidePage, setSlidePage] = useState(0);

  const parentRef = useRef();
  const innerParentRef = useRef();
  const firstTabRef = useRef();
  const lastTabRef = useRef();

  const parentWidth = parentRef?.current?.getBoundingClientRect().width;
  const innerParentWidth =
    (lastTabRef?.current?.getBoundingClientRect().x ?? 0) -
    (parentRef?.current?.getBoundingClientRect().x ?? 0) +
    (lastTabRef?.current?.getBoundingClientRect().width ?? 0);

  const overflowing = Math.ceil(innerParentWidth) >= Math.floor(parentWidth);
  const leftButtonVisible =
    overflowing &&
    Math.floor(
      (parentRef?.current?.getBoundingClientRect().x ?? 0) -
        (firstTabRef?.current?.getBoundingClientRect().x ?? 0)
    ) > 0;
  const rightButtonVisible = overflowing && !leftButtonVisible;

  const slideLeft = () => {
    innerParentRef.current.scrollBy(-parentWidth, 0);
    setSlidePage((prev) => prev - 1);
  };

  const slideRight = () => {
    innerParentRef.current.scrollBy(parentWidth, 0);
    setSlidePage((prev) => prev + 1);
  };

  const handlePartialTabClick = (e) => {
    const { x: pX, width: pW } = parentRef.current.getBoundingClientRect();
    const { x: tX, width: tW } = e.target.getBoundingClientRect();

    if (tX <= pX) {
      innerParentRef.current.scrollBy(-(pX - tX), 0);
    } else {
      innerParentRef.current.scrollBy(-(pX - tX + tW - pW), 0);
    }
  };

  if (items.length <= 0) return null;

  return (
    <div className="relative" ref={parentRef}>
      <span
        onClick={slideLeft}
        className={`absolute left-0 top-0 h-full flex items-center pr-3 pb-1 bg-white ${
          leftButtonVisible ? "" : "hidden"
        }`}
      >
        <Icon
          name="LeftArrow"
          className="w-8 h-8 p-2 rounded-full text-neutral-500 bg-primary-50"
        />
      </span>
      <div
        className={`flex border-b  border-neutral-300 max-w-full overflow-x-hidden tab-slide-animation ${classes}`}
        style={{ animation: "rotate 1s linear infinite" }}
        ref={innerParentRef}
      >
        {items?.map((tab, idx) => {
          const showComingSoon = !moduleHistoryEnabled && tab?.showComingSoon;

          return (
            <div
              className={`px-6 flex items-left py-3 shrink-0 ${
                showComingSoon || (disabled && active !== tab.key)
                  ? "cursor-not-allowed text-neutral-400"
                  : "cursor-pointer text-neutral-500"
              } ${
                active === tab.key
                  ? "border-b-2 border-primary-500 text-base"
                  : ""
              } ${itemClasses}`}
              key={tab?.key}
              onClick={(e) => {
                if (!showComingSoon && !disabled) {
                  handleNavigation(tab);
                  if (overflowing) handlePartialTabClick(e);
                }
              }}
              ref={
                idx === 0
                  ? firstTabRef
                  : idx === items.length - 1
                    ? lastTabRef
                    : null
              }
            >
              <div
                className={`pointer-events-none pr-1 items-center flex gap-2 ${
                  active === tab.key ? "text-neutral-800" : ""
                }`}
              >
                <Text translationKey={tab.name} />
                {showComingSoon ? <ComingSoonBadge /> : null}
              </div>

              {tab.count ? <Chip label={tab.count} /> : null}
            </div>
          );
        })}
      </div>
      <span
        onClick={slideRight}
        className={`absolute right-0 top-0 h-full flex items-center pl-3 pb-1 bg-white ${
          rightButtonVisible ? "" : "hidden"
        }`}
      >
        <Icon
          name="RightArrow"
          className="w-8 h-8 p-2 rounded-full text-neutral-500 bg-primary-50"
        />
      </span>
      {!mode && (
        <div className="block">
          <Outlet />
        </div>
      )}
    </div>
  );
}

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any.isRequired,
      name: PropTypes.string,
      path: PropTypes.string,
      count: PropTypes.string,
    })
  ),
  selectedTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mode: PropTypes.bool,
  setCurrentTab: PropTypes.func,
  classes: PropTypes.string,
  itemClasses: PropTypes.string,
  conditionalTabChange: PropTypes.bool,
  disabled: PropTypes.bool,
};
