import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePagination from "@/hooks/usePagination";

import { fetchAccountingVendors } from "@/store/reducers/accounting";
import { approveRequest, setIndexApiReload } from "@/store/reducers/app";
import { fetchCategories } from "@/store/reducers/categories";
import {
  bulkApprove,
  fetchExpenses,
  requestReceived,
  resetExpensesListAndPagination,
  updateExpense,
} from "@/store/reducers/expense";
import {
  fetchQrPayments,
  qrPaymentsBulkApprove,
  resetQrPaymentsListAndPagination,
  updateQrPayments,
} from "@/store/reducers/qr-payments";
import { fetchTags } from "@/store/reducers/tags";
import { fetchVendors } from "@/store/reducers/vendors";

import { accountingVendorsSelector } from "@/store/selectors/accounting";
import {
  dataActionLoadingIdSelector,
  indexApiReloadSelector,
} from "@/store/selectors/app";
import { categoriesListSelector } from "@/store/selectors/categories";
import {
  accountingEnabledSelector,
  accountingIntegrationSoftwareSelector,
  expenseMultiLayerReviewEnabledSelector,
} from "@/store/selectors/client";
import {
  dataActionInProgressIdSelector,
  expensesHasMoreSelector,
  expensesListSelector,
  expensesTotalSelector,
  isBulkApproveInProgressSelector,
  isExpensesFetchingSelector,
} from "@/store/selectors/expense";
import { appliedFilterSelector } from "@/store/selectors/filters";
import {
  isFetchingQrPayments,
  qrPaymentsBulkApproveSelector,
  qrPaymentsHasMoreSelector,
  qrPaymentsListSelector,
  qrPaymentsTotalSelector,
} from "@/store/selectors/qr-payments";
import {
  accountingCategoryTagsSelector,
  isTagsFetchedSelector,
} from "@/store/selectors/tags";
import { userSelector } from "@/store/selectors/user";

import ApprovalProgress from "@/components/core/ApprovalProgress";
import Icon from "@/components/core/Icon";
import ProfileWidget from "@/components/core/ProfileWidget";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";
import VirtualizedTable from "@/components/core/VirtualizedTable";
import VpSelect from "@/components/core/VpSelect";

import CardHolder from "@/components/Cards/CardsTable/CardTableComponents/CardHolder";
import MemoNoteIcon from "@/components/common/MemoNoteIcon";
import ActionCell from "@/components/common/QrPayAndExpense/common/Cells/ActionCell";
import CardUsageType from "@/components/common/QrPayAndExpense/common/Cells/CardUsageType";
import BulkApproveComponent from "@/components/common/QrPayAndExpense/common/NeedsReview/NeedsReviewList/BulkApproveComponent";
import { QRPAY_EXPENSES_CONTEXT } from "@/components/common/QrPayAndExpense/constants";
import ReceiptIcon from "@/components/common/ReceiptIcon";
import SubmissionPolicyIcon from "@/components/common/SubmissionPolicyIcon";
import { convertFilters } from "@/utils/filters";
import {
  SORTING_CATEGORY,
  SORTING_TYPE,
  SORT_KEYS,
} from "@/utils/constants/sorting";
import {
  EXPENSES_NEEDS_REVIEW_TABLE_HEADERS,
  EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY,
  QRPAY_NEEDS_REVIEW_TABLE_HEADERS,
} from "@/utils/constants/qr-payments";
import { MEMO_NOTE_VARAINT } from "@/utils/constants/common";
import { amountToCurrency, dateToString, debounce } from "@/utils/common";

import { SLIDERS_SEARCH_PARAMS } from "@/constants/SearchParams";
import { EXPENSE_CTA_KEYS, EXPENSE_TYPE } from "@/constants/expense";
import { PAGINATION_PER_REQUEST_LIMIT } from "@/constants/pagination";

import "./styles.scss";

export default function NeedsReviewList({ viewTransactionItem, page, tab }) {
  const dispatch = useDispatch();
  const isExpensesPage = page === QRPAY_EXPENSES_CONTEXT.EXPENSES;
  const multipleExpenseReviewLayersSupported = useSelector(
    expenseMultiLayerReviewEnabledSelector
  );
  const tableRef = useRef(null);
  const accountingEnabled = useSelector(accountingEnabledSelector);
  const accountingSoftware = useSelector(accountingIntegrationSoftwareSelector);
  const expenseTableHeaders = useMemo(
    () =>
      EXPENSES_NEEDS_REVIEW_TABLE_HEADERS?.map((item) =>
        item.accountingTag
          ? {
              ...item,
              show: accountingEnabled,
              labelTranslationProps: { accounting: accountingSoftware },
            }
          : {
              ...item,
              show:
                item.id !==
                  EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.REVIEW_STATUS_MULTIPLE_REVIEW_LAYER ||
                multipleExpenseReviewLayersSupported,
            }
      )?.filter((item) => item.show),
    [accountingEnabled, multipleExpenseReviewLayersSupported]
  );
  console.log(expenseTableHeaders);
  const expenseSelectors = {
    transaction: useSelector((state) => expensesListSelector(state)),
    isFetching: useSelector((state) => isExpensesFetchingSelector(state)),
    totalTransactions: useSelector(expensesTotalSelector),
    isApprovalInProgress: useSelector(isBulkApproveInProgressSelector),
    hasMore: useSelector(expensesHasMoreSelector),
    tableHeaders: expenseTableHeaders,
    date: "expenseDate",
  };
  const qrPaymentsSelectors = {
    transaction: useSelector((state) => qrPaymentsListSelector(state)),
    isFetching: useSelector((state) => isFetchingQrPayments(state)),
    totalTransactions: useSelector(qrPaymentsTotalSelector),
    hasMore: useSelector(qrPaymentsHasMoreSelector),
    isApprovalInProgress: useSelector(qrPaymentsBulkApproveSelector),
    tableHeaders: QRPAY_NEEDS_REVIEW_TABLE_HEADERS,
    tableColWidths: [300, 200, 300, 200, 150, 350],
    acoountingEnabledTableColWidths: [300, 200, 220, 200, 150, 350, 300, 300],
    date: "paymentDate",
  };

  // common selectors for qrPay and expenses

  const isFetchedAccountingTags = useSelector(isTagsFetchedSelector);
  const isApprovalInProgress = useSelector(isBulkApproveInProgressSelector);
  const accountingVendorOptions = useSelector(accountingVendorsSelector);
  const accountingCategory = useSelector(accountingCategoryTagsSelector);

  const appliedFilter = useSelector(appliedFilterSelector);
  const currentUser = useSelector(userSelector);
  const categories = useSelector(categoriesListSelector);

  const dataActionInProgressId = useSelector(dataActionInProgressIdSelector);
  const approveActionInProgressId = useSelector(dataActionLoadingIdSelector);
  const reloadIndexApi = useSelector(indexApiReloadSelector);

  const currentSelectors = isExpensesPage
    ? expenseSelectors
    : qrPaymentsSelectors;
  const isEmpty = !currentSelectors?.transaction?.length;

  // use states
  const [searchText, setSearchText] = useState({});
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [deSelectedIds, setDeSelectedIds] = useState([]);
  const [headerSelectAll, setHeaderSelectAll] = useState(false);
  const [bulkSelectAll, setBulkSelectAll] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedVendors, setSelectedVendors] = useState({});
  // select total Expense
  const [totalExpenseSelected, setTotalExpenseSelected] = useState(false);
  const [buttonHovered, setButtonHovered] = useState({
    flag: null,
    approve: null,
  });
  const [sorting, setSorting] = useState({
    type: SORTING_TYPE.DEC,
    category: SORTING_CATEGORY.DATE,
  });

  const searchHandler = debounce((text) => {
    setSearchText(text);
  }, 500);

  const onReset = () => {
    const resetTransaction = isExpensesPage
      ? resetExpensesListAndPagination
      : resetQrPaymentsListAndPagination;
    dispatch(resetTransaction());
  };
  const loadMore = () => {
    const fetchAction = isExpensesPage ? fetchExpenses : fetchQrPayments;
    dispatch(
      fetchAction({
        page: pageNum,
        limit: PAGINATION_PER_REQUEST_LIMIT,
        ...convertFilters(appliedFilter),
        [SORT_KEYS.COLUMN]: sorting?.category,
        [SORT_KEYS.DIRECTION]: sorting?.type,
        ...(tab?.defaultQueryParams ?? {}),
      })
    );
  };

  const [pageNum, setPageNum] = usePagination({
    initialPageNum: 1,
    hasMore: currentSelectors?.hasMore,
    loadMore,
    onReset,
    filterOptions: {
      ...appliedFilter,
      page: 1,
      category: sorting?.category,
      type: sorting?.type,
      reloadIndexApi,
    },
  });
  const onScroll = () => {
    setPageNum((prev) => prev + 1);
  };

  const handleRefChange = useInfiniteScroll(onScroll);

  const refreshExpenseData = () => {
    dispatch(setIndexApiReload(!reloadIndexApi));
    tableRef.current?.clearSelection();
  };

  const bulkApproveHandler = (transactionIds) => {
    const fetchAction = isExpensesPage ? bulkApprove : qrPaymentsBulkApprove;

    const payloadKey = isExpensesPage ? "expense_ids" : "qrPayment_ids";

    const payload = {
      payload: {
        [payloadKey]: transactionIds,
      },
    };

    dispatch(
      fetchAction({
        payload: {
          [payloadKey]: transactionIds,
        },
        onSuccess: () => {
          refreshExpenseData();
        },
      })
    );
  };

  const handleAccountingCategorySelection = (e, index, val) => {
    // updating UI with Value
    setSelectedCategory((prev) => {
      return { ...prev, [index]: e?.id };
    });
    // getting tag id
    const id = val?.accountingTags?.find(
      (_) => _?.tagId === accountingCategory?.id
    )?.id;
    //  sending api update request
    const fetchAction = isExpensesPage ? updateExpense : updateQrPayments;

    const payloadKey = isExpensesPage ? "expenseId" : "qrPayId";

    const payload = {
      [payloadKey]: val?.id,
      payload: {
        expense_tag_values_attributes: [
          id
            ? {
                id,
                tag_id: accountingCategory?.id,
                tag_value_id: e?.id,
              }
            : {
                tag_id: accountingCategory?.id,
                tag_value_id: e?.id,
              },
        ],
      },
    };

    dispatch(fetchAction(payload));
  };

  function getActionButtons(key, val) {
    switch (key) {
      case EXPENSE_CTA_KEYS.APPROVE:
        dispatch(
          approveRequest({
            payload: {
              target_id: val?.id,
              type: isExpensesPage
                ? EXPENSE_TYPE.EXPENSE
                : EXPENSE_TYPE.QR_PAYMENT,
            },
            onSuccess: () => {
              dispatch(setIndexApiReload(!reloadIndexApi));
            },
          })
        );
        break;

      case EXPENSE_CTA_KEYS.FLAG:
        searchParams.append(
          isExpensesPage ? SLIDERS_SEARCH_PARAMS.expenses.flagTransaction : "",
          val?.id
        );
        setSearchParams(searchParams);
        break;

      case EXPENSE_CTA_KEYS.REQUEST_REPAYMENT:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.expenses.requestRepayment,
          val?.id
        );
        setSearchParams(searchParams);
        break;

      case EXPENSE_CTA_KEYS.REPAYMENT_RECEIVED:
        dispatch(
          requestReceived({
            id: val?.id,
            onSuccess: () => {
              dispatch(setIndexApiReload(!reloadIndexApi));
            },
          })
        );
        break;

      case EXPENSE_CTA_KEYS.REPAYMENT_CANCELLED:
        searchParams.append(
          SLIDERS_SEARCH_PARAMS.expenses.cancelRepayment,
          val?.id
        );
        setSearchParams(searchParams);
        break;

      default:
        break;
    }
  }
  const handleVendorSelection = (e, index, val) => {
    // update UI
    setSelectedVendors((prev) => {
      return { ...prev, [index]: e?.id };
    });
    // sending request to backend
    const fetchAction = isExpensesPage ? updateExpense : updateQrPayments;

    const payloadKey = isExpensesPage ? "expenseId" : "qrPayId";

    const payload = {
      [payloadKey]: val?.id,
      payload: { accounting_payee_id: e?.id },
    };
    dispatch(fetchAction(payload));
  };
  function getAccountHolder(transaction) {
    return isExpensesPage ? transaction.cardHolder : transaction.walletHolder;
  }

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchVendors({ page: 1, limit: PAGINATION_PER_REQUEST_LIMIT }));
    dispatch(fetchAccountingVendors());
    dispatch(fetchTags({ visible: true }));
  }, []);

  const getCellComponent = useMemo(
    // eslint-disable-next-line react/display-name
    () => (id, _data, index, tagId, val, _isActionLoader) => {
      const disableCheck = Boolean(
        (approveActionInProgressId && approveActionInProgressId === val.id) ||
          (dataActionInProgressId && dataActionInProgressId === val.id)
      );
      switch (id) {
        case EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.DATE:
          return (
            <ProfileWidget
              img={val?.vendor?.avatarUrl ?? val?.merchant?.avatarUrl}
              textClasses="font-semibold "
              avatarSize="md"
              tooltipClasses="w-15.5"
              name={val?.vendor?.name ?? val?.merchant?.name}
            >
              <div className="flex text-xs text-left truncate text-neutral-500">
                <span>{dateToString(val?.[currentSelectors?.date])}</span>
                {isExpensesPage ? (
                  <div className="w-3/5 truncate" id={`projectName-${val.id}`}>
                    <span className="profile-widget-dot text-neutral-500" />
                    <Text translationKey={val?.projectName} />

                    <Tooltip id={`projectName-${val.id}`}>
                      <Text translationKey={val?.projectName} />
                    </Tooltip>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </ProfileWidget>
          );
        case EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.AMOUNT:
          return (
            <div className="flex items-center justify-end gap-2 font-semibold">
              {val?.transactionCurrencyCode !== val?.currency ? (
                <>
                  <div id={`transaction-needs-review-${index}`}>
                    <Icon name="swapHorizontal" className="text-neutral-400" />
                  </div>
                  <Tooltip
                    id={`transaction-needs-review-${index}`}
                    direction="top"
                  >
                    <span>
                      {amountToCurrency(
                        val?.transactionAmount,
                        val?.transactionCurrencyCode
                      )}
                    </span>
                  </Tooltip>
                </>
              ) : null}
              <span>{amountToCurrency(val?.amount, val?.currency)}</span>
            </div>
          );
        case EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.REVIEW_STATUS_MULTIPLE_REVIEW_LAYER:
          return (
            <div className="flex items-center justify-between">
              <ApprovalProgress
                id={val?.id}
                disabled={val?.disabled}
                totalApprovalSteps={val?.approvalLevels ?? val?.approverLevels}
                currentStep={val?.currentApprovalLevel}
                currentStatus={val?.currentApprovalLevelStatus}
                previousApprovers={val?.previousApprovers}
                isExpense
              />
            </div>
          );
        case EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.CARD:
          return (
            <CardUsageType
              cardName={val?.cardName}
              cardType={val?.cardType}
              cardUsageType={val?.cardUsageType}
              cardNumber={val?.cardNumber}
              truncate
              cardId={val?.cardId}
            />
          );
        case EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.CARD_HOLDER:
          return (
            <CardHolder
              displayName={getAccountHolder(val)?.displayName}
              role={getAccountHolder(val)?.roles}
              department={getAccountHolder(val)?.departmentName}
              location={getAccountHolder(val)?.locationName}
              showIconComponent={false}
              classes="text-left"
            />
          );
        case EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.SUBMISSION_POLICY:
          return (
            <div className="flex justify-center flex-1">
              <SubmissionPolicyIcon
                value={val?.submissionPolicyStatus}
                index={val?.id}
              />
            </div>
          );
        case EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.RECEIPT:
          return (
            <ReceiptIcon index={index} receiptStatus={val?.receiptStatus} />
          );
        case EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.MEMO:
          return (
            <div className="flex justify-center flex-1 text-center">
              <MemoNoteIcon
                index={index}
                required={val.memoRequired}
                variant={MEMO_NOTE_VARAINT.TEXT}
                value={val.memo}
              />
            </div>
          );
        case EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.ACCOUNTING_CATEGORY:
          return (
            <VpSelect
              required
              hideLabelAfterSelect
              value={
                selectedCategory[val?.id]
                  ? selectedCategory[val?.id]
                  : val?.accountingTags?.find(
                      (_) => _?.tagId === accountingCategory?.id
                    )?.tagValueId
              }
              placeholder="expenses.slider.accountingMerchant"
              label="expenses.slider.select"
              placeholderTranslationProp={{
                accounting: accountingSoftware,
              }}
              handleChange={(e) =>
                handleAccountingCategorySelection(e, val?.id, val)
              }
              valueKey="id"
              options={accountingCategory?.options}
              isOptionsFetched={isFetchedAccountingTags}
              isOptionsLoading={!isFetchedAccountingTags}
              fetchOptions={() => dispatch(fetchTags({ visble: true }))}
              optionsDisplayKey="alias"
            />
          );
        case EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.ACCOUNTING_VENDOR:
          return (
            <VpSelect
              hideLabelAfterSelect
              value={
                selectedVendors[val?.id]
                  ? selectedVendors[val?.id]
                  : val?.accountingPayeeId
              }
              handleChange={(e) => handleVendorSelection(e, val?.id, val)}
              label="expenses.slider.select"
              placeholder="expenses.slider.accountingCategory"
              placeholderTranslationProp={{
                accounting: accountingSoftware,
              }}
              options={accountingVendorOptions}
              optionsDisplayKey="alias"
            />
          );
        case EXPENSE_QRPAY_NEEDS_REVIEW_TABLE_HEADERS_KEY.ACTION:
          return (
            <div className="flex items-center justify-center gap-6 text-neutral-500">
              <ActionCell
                expenseFooterCtas={val?.expenseCta}
                onSubmit={getActionButtons}
                val={val}
                disabled={approveActionInProgressId || dataActionInProgressId}
                showLoader={disableCheck}
              />
            </div>
          );
        default:
          return "-";
      }
    },
    [
      JSON.stringify(selectedCategory),
      JSON.stringify(selectedVendors),
      accountingCategory,
    ]
  );

  return (
    <VirtualizedTable
      ref={tableRef}
      headerSticky
      onSortingChange={setSorting}
      colWidths={currentSelectors?.tableHeaders?.map((val) => val?.colWidth)}
      headerConfig={currentSelectors?.tableHeaders}
      numberOfStickyColsLeft={1}
      numberOfStickyColsRight={1}
      isLoading={currentSelectors?.isFetching}
      data={currentSelectors?.transaction}
      selectAllRows={bulkSelectAll}
      onRowSelectionChange={({
        selectedRows: _selectedRows,
        selectedRowsArrayOfObject,
        deselectedRowsIds,
        deselectedRowsIdsSet,
        selectAllRows,
        isHeaderSelected,
      }) => {
        setDeSelectedIds(deselectedRowsIds);
        setSelectedTransactions(selectedRowsArrayOfObject);
        setHeaderSelectAll(isHeaderSelected);
        setBulkSelectAll(selectAllRows);
      }}
      showCheckBoxRow={tab?.key !== 3}
      onRowClick={(val) => {
        viewTransactionItem(val?.id);
      }}
      handleRefChange={(ref, index) => {
        if (
          index === (currentSelectors?.transaction?.length ?? 0) - 1 &&
          currentSelectors?.hasMore
        )
          handleRefChange(ref);
      }}
      getCellComponent={(rowData, headerId, header, isActionLoader) => {
        return getCellComponent(
          headerId,
          rowData?.getValue(),
          rowData?.row?.index,
          header.tagId,
          rowData?.row?.original,
          isActionLoader
        );
      }}
      bulkApproveVisible={selectedTransactions?.length > 0}
      bulkApproveContent={
        <BulkApproveComponent
          selectAllVisible={
            currentSelectors?.totalTransactions >= PAGINATION_PER_REQUEST_LIMIT
          }
          totalApprove={currentSelectors?.totalTransactions}
          selectTotalExpense={totalExpenseSelected}
          selectedRows={
            bulkSelectAll
              ? deSelectedIds?.length
                ? (currentSelectors?.totalTransactions ?? 0) -
                  (deSelectedIds?.length ?? 0)
                : currentSelectors?.totalTransactions
              : selectedTransactions?.length
          }
          handleTotalExpenseSelection={(val) => {
            setDeSelectedIds([]);
            setHeaderSelectAll(val);
            setBulkSelectAll(val);
            setTotalExpenseSelected(val);
            if (!val) tableRef.current.clearSelection();
          }}
          handleApprove={() => {
            const selectedRows = selectedTransactions?.map((item) => item?.id);
            bulkApproveHandler(selectedRows, totalExpenseSelected);
          }}
          isApproveInProgress={isApprovalInProgress}
        />
      }
      bulkApproveHeight="100px"
      bulkApproveWidth="60%"
      emptyDataTitle="expenses.flagged.tableEmptyStates.title"
      emptyDataDescription="expenses.flagged.tableEmptyStates.description"
    />
  );
}

NeedsReviewList.propTypes = {
  viewTransactionItem: PropTypes.func,
  page: PropTypes.string,
  tab: PropTypes.object,
};
