import PropTypes from "prop-types";
import React, { Fragment } from "react";

import Icon from "@/components/core/Icon";
import Text from "@/components/core/Text";
import Tooltip from "@/components/core/Tooltip";

export default function OverviewCardsSection({ config = [] }) {
  const renderArrayComponent = (renderArray) => {
    return (
      <div className="flex items-center w-2 text-clip">
        {renderArray
          .filter((obj) => !!obj)
          .map((obj, index) => {
            return (
              <div className="flex items-center mr-1" key={index}>
                {index !== 0 ? (
                  <div className="profile-widget-dot bg-neutral-500" />
                ) : null}

                <Text
                  color="neutral-500"
                  classes={`text-xs not-italic font-semibold leading-5 w-max ${obj?.classes}`}
                  translationKey={obj?.label}
                  truncate
                  showTooltip={obj?.showTooltip}
                  tooltipClasses={obj?.tooltipClasses}
                  id={`overview-card-${index}`}
                />
              </div>
            );
          })}
      </div>
    );
  };

  const renderIconCompoent = (props) => {
    const { show, name, type, handleClick, className, tooltipText, ...rest } =
      props;

    const isComponent = type === "component";
    const RenderComponent = name;

    return show ? (
      isComponent ? (
        <RenderComponent {...rest} />
      ) : (
        <div>
          <div
            onClick={() => (handleClick ? handleClick(rest) : () => {})}
            id={tooltipText ? "report-field-info" : ""}
            className="cursor-pointer text-primary-500"
          >
            <Icon name={name} className={`ml-2 ${className}`} />
          </div>

          {tooltipText ? (
            <Tooltip id="report-field-info">
              <Text translationKey={tooltipText} />
            </Tooltip>
          ) : null}
        </div>
      )
    ) : null;
  };

  return (
    <div className="grid grid-cols-2 gap-5">
      {config?.map((overviewCardInfo, index) => {
        const hasCardFunction = overviewCardInfo?.cardProps?.handleCardClick;

        return (
          <div
            className={`flex border items-center justify-between hover:shadow-md flex-row card-wrapper w-72 max-w-72 ${
              hasCardFunction ? "cursor-pointer" : ""
            }`}
            onClick={
              hasCardFunction && overviewCardInfo.cardProps.handleCardClick
            }
            key={index}
          >
            <div className="max-w-full">
              {Object.values(overviewCardInfo.rows).map((card, ind) => {
                const {
                  translationKey,
                  translationProps,
                  showTooltip,
                  tooltipClasses,
                  classes,
                  color,
                } = card.text || {
                  translationKey: "",
                  translationProps: {},
                  classes: "",
                  showTooltip: false,
                  tooltipClasses: "",
                  color: "",
                };

                return (
                  <Fragment key={card?.id}>
                    {card.renderArray || card.text ? (
                      <div
                        className="flex flex-row items-center max-w-full"
                        id={card?.id}
                      >
                        {card.renderArray ? (
                          renderArrayComponent(card.renderArray)
                        ) : card.text ? (
                          <Text
                            translationKey={translationKey}
                            translationProps={translationProps}
                            color={color}
                            classes={classes}
                            showTooltip={showTooltip}
                            maxWidthTooltip="40%"
                            toolTipDirection="top"
                            truncate
                            tooltipClasses={tooltipClasses}
                          />
                        ) : null}
                        {renderIconCompoent({
                          show: card.icon,
                          ...card.icon,
                        })}
                      </div>
                    ) : null}
                  </Fragment>
                );
              })}
            </div>

            {renderIconCompoent({
              show: overviewCardInfo?.cardProps?.centralizeIcon?.show,
              ...overviewCardInfo?.cardProps?.centralizeIcon,
            })}
          </div>
        );
      })}
    </div>
  );
}

OverviewCardsSection.propTypes = {
  config: PropTypes.array,
  show: PropTypes.bool,
  name: PropTypes.string,
  handleClick: PropTypes.func,
  type: PropTypes.string,
  tooltipText: PropTypes.string,
};
