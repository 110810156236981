export const VENDOR_SLIDER_INVOICES_TABS = [
  { name: "billPay.vendors.slider.recentInvoices", count: null, key: 0 },
  { name: "billPay.vendors.slider.largestInvoices", count: null, key: 1 },
];

export const VENDOR_TYPES = {
  CORPORATE: "corporate",
  INDIVIDUAL: "individual",
};

export const VENDOR_TYPE_LABELS = {
  [VENDOR_TYPES.CORPORATE]: "billPay.vendors.types.corporate",
  [VENDOR_TYPES.INDIVIDUAL]: "billPay.vendors.types.individual",
};

export const VENDOR_TYPE_DESCRIPTION = {
  [VENDOR_TYPES.CORPORATE]: "billPay.vendors.types.corporateDescription",
  [VENDOR_TYPES.INDIVIDUAL]: "billPay.vendors.types.individualDescription",
};

export const VENDOR_DEFAULT_PAYLOAD_KEYS = {
  SELECTED_ACCOUNTING_VENDOR: "accounting_payee_id",
  ACCOUNTING_VENDOR_NAME: "alias_name",
};

export const VENDOR_SHOW_RESPONSE = {
  SELECTED_VENDOR: "accountingPayeeId",
  BANK_DETAILS_PRESENT: "bankDetailsPresent",
};

export const VENDOR_STATUS = {
  UNVERIFIED: "unverified",
  ACTIVE: "active",
  PENDING: "pending",
  MISSING_VENDOR_DETAILS: "missingVendorDetails",
};

export const VENDOR_SCOPES = {
  NOT_ARCHIVED: "not_archived",
};

export const VENDOR_CREATE_REQUIREMENTS_PARAMS = {
  SENDER_CURRENCY: "sender_currency",
  BENEFICIARY_CURRENCY: "currency",
  BENEFICIARY_COUNTRY: "beneficiary_country",
  VENDOR_TYPE: "vendor_type",
  BENEFICIARY_BANK_COUNTRY: "beneficiary_bank_country",
  PAYOUT_METHOD_TYPE: "payout_method_type",
  BENEFICIARY_DETAIL_CONTROL_FIELD: "beneficiary_detail",
  OTHER_DETAILS_CONTROL_FIELD: "other_details",
};

export const VENDOR_CREATE_FINAL_PAYLOAD_PARAMS = {
  VENDOR_NAME: "alias_name",
  SELECTED_VENDOR: "accounting_payee_id",
  TAX_NUMBER: "tax_number",
  LINKED_TO: {
    PROJECT_ID: "project_id", // linked to and project/department handled in one go
  },
  VENDOR_CLASS: "vendor_class",
  PAYMENT_METHOD: "payout_method_type",
  ACCOUNTING_VENDOR_TAG: {
    KEY: "vendor_tag_values_attributes",
    ID: "id",
    _DESTROY: "_destroy",
    TAG_ID: "tag_id",
    TAG_VALUE_ID: "tag_value_id",
    TAG_TEXT_VALUE: "custom_text_value",
  },
  PAYMENT_PROVIDER_DATA: "payment_provider_data",
  MAIL_ONBOARDING: "mail_onboarding",
};

// used in pre add bank details page, for fetching requirements
export const VENDOR_CREATE_PARAMS = {
  VENDOR_OWNER_ID: "vendor_owner_id",
  OWNER_ID: "owner_id",
  EMPLOYEE_ID: "employee_id",
  OWNER_TYPE: "owner_type",
};

export const VENDOR_UPDATE_REQUEST_PARAMS = {
  VENDOR_TYPE: VENDOR_CREATE_REQUIREMENTS_PARAMS.VENDOR_TYPE,
  CONTACTS_DETAILS: {
    KEY: "contacts_attributes",
    VALUES: {
      // keys that show PUT/POST wants
      ID: "id",
      _DESTROY: "_destroy",
      NAME: "name",
      EMAIL: "email_address",
      PHONE: "phone_number",
    },
    RESPONSE_VALUES: {
      // keys that show API returns
      ID: "id",
      NAME: "name",
      EMAIL: "email",
      PHONE: "phone",
    },
  },
  ACCOUNTING_VENDOR_TAG:
    VENDOR_CREATE_FINAL_PAYLOAD_PARAMS.ACCOUNTING_VENDOR_TAG,
  NAME: "alias_name",
  SELECTED_VENDOR: "accounting_payee_id",
  ATTACHMENTS: "contracts[]",
  EMPLOYEE_ID: "employee_id",
  _DESTROY: "_destroy",
};

export const VENDOR_VERIFY_REQUEST_KEY = "other_details";

export const VENDOR_LINKED_TO_TYPES = {
  DEPARTMENT: "Department",
  PROJECT: "Project",
};

// related to fetchBankAndSwiftCodes
export const CREATE_VENDOR_BANK_SWIFT_CODE_KEYS = {
  BANK: "bank", // don't use (non unique)
  LABEL: "label", // unique
  SWIFT_CODE: "swift_code",
  REQUIREMENTS_BANK_FIELD_KEY: "beneficiary.bankAccount.bankName",
  REQUIREMENTS_BANK_NAME_FIELD_KEY: "beneficiary.bank_details.bank_name",
  REQUIREMENTS_BANK_TYPE_KEY: "beneficiary.bankAccount.type",
};

export const CREATE_VENDOR_BANK_CITY_REGION_KEYS = {
  LABEL: "label",
  REGION: "region",
  REQUIREMENTS_CITY_FIELD_INDIVIDUAL_KEY: "city",
  REQUIREMENTS_CITY_FIELD_KEY: "beneficiary.city",
};

export const VENDOR_TABLE_SORT_KEYS = {
  TOTAL_SPEND: "total_spend",
  LAST_30_DAYS_SPEND: "last_30_days_spend",
};

export const ALL_VENDOR_CTAS = {
  EDIT: "edit",
  ADD_BANK_DETAILS: "addBankDetails",
  ARCHIVE: "archive",
  RESEND_EMAIL: "resendEmail",
  VERIFY: "verify",
};

export const PAYMENT_PROVIDER_DATA_KEYS = {
  RESPONSE: {
    BENEFICIARY: "bankDetails",
    OTHER: "otherDetails",
  },
  REQUEST: {
    BENEFICIARY: "beneficiary",
    OTHER: "sender",
  },
};

export const BANK_TOOLTIP_FIELD_KEYS = {
  STATE_OR_PROVINCE: "stateOrProvince",
  STATE: "state",
  TYPE: "type",
};

export const JP_TOOLTIP_ENUMS = {
  COUNTRY: "JP",
  CURRENCY: "JPY",
};

export const GST = "GST";
