import to from "await-to-js";

import { parsePhoneNumber } from "libphonenumber-js";
import CryptoJS from "crypto-js";
import { COLOR_CODE_LIST } from "@/utils/constants/colorCode";
import { MONTH_NAME } from "@/utils/constants/date";
import { LEDGER_STATUSES } from "@/utils/constants/ledger";

import {
  ACCOUNTING_SOFTWARES,
  ACCOUNTING_TRANSACTION_STATUS,
} from "@/constants/accounting";
import { DATE_DIFFRENCE_DURATIONS } from "@/constants/common";
import { CURRENCY } from "@/constants/currency";
import {
  DATE_RANGE_DAYS_COUNT_MAP,
  DATE_RANGE_KEYS,
  DATE_RANGE_MONTHS_COUNT_MAP,
  DATE_RANGE_YEARS_COUNT_MAP,
} from "@/constants/date";
import { ROUTES } from "@/constants/routes";

export const subdomain = () => window.location.host.split(".")[0];
export const getToken = () =>
  window.location.href.split("/").at(-1).split("?")[0];

export const getUrlPathName = () => window.location.pathname.split("/")[1];

export const waitForMilliseconds = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const isDevelopEnv = () => import.meta.env.VITE_MODE === "development";

export function getColorCode(index) {
  return COLOR_CODE_LIST[index];
}

let commonStore = null;
export const injectStoreForCommon = (_store) => {
  commonStore = _store;
};

/**
 * Converts a color code (either hexadecimal or RGB) to an RGBA color code with a specified opacity.
 *
 * @param {string} colorCode - The color code to be converted (hexadecimal or RGB).
 * @param {number} opacity - The opacity value between 0 (transparent) and 1 (fully opaque).
 * @returns {string} The RGBA color code with the specified opacity.
 * @example
 * const hexColor = "#FF5733";
 * const rgbaColor = getRGBAColor(hexColor, 0.7);
 * // Result: "rgba(255, 87, 51, 0.7)"
 *
 * const rgbColor = "255, 120, 50";
 * const rgbaColor2 = getRGBAColor(rgbColor, 0.5);
 * // Result: "rgba(255, 120, 50, 0.5)"
 */
export function getRGBAColor(colorCode, opacity) {
  // change for hex value
  if (colorCode && colorCode.includes("#"))
    return `${colorCode}${Math.floor(opacity * 255)
      .toString(16)
      .toUpperCase()}`;
  return `rgba(${`${colorCode}`}, ${opacity})`;
}

/**
 * Converts a date string into a formatted date string based on specified options and hyphenation.
 *
 * @param {string} dateString - The date string to be formatted.
 * @param {Object|null} options_ - Optional formatting options for the date. (e.g., { year: '2-digit', month: 'short', day: '2-digit' })
 * @param {boolean} hyphenated - If true, hyphens will be used instead of spaces or slashes as separators.
 * @returns {string} The formatted date string.
 * @example
 * const dateStr = "2023-09-02";
 * const formattedDate = dateToString(dateStr, { year: 'numeric', month: 'long', day: 'numeric' }, true);
 * // Result: "02-September-2023 at 00:00:00"
 */

export const dateToString = (
  dateString,
  options_ = null,
  hyphenated = false
) => {
  if (dateString && dateString !== "Invalid Date") {
    dateString = validateAndConvertToISO8601(dateString);
    let date;

    // Create a date object
    try {
      date = new Date(dateString);
    } catch (error) {
      console.log("Error ", error);
      return "";
    }

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const options = {
      year: "2-digit",
      month: "short",
      day: "2-digit",
      timeZone,
      ...options_,
    };

    return new Intl.DateTimeFormat("en-IN", options)
      .format(date)
      .replaceAll(...(hyphenated ? ["/", "-"] : ["-", " "]))
      .split(",")
      .join(" at");
  }

  return "";
};

export const dateToTimeStampString = (
  dateString,
  haveAt = true,
  _options = {}
) => {
  if (dateString) {
    const formattedDateString = validateAndConvertToISO8601(dateString);
    const date = new Date(formattedDateString);
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const options = _options?.replace
      ? { ..._options, timeZone }
      : {
          year: "2-digit",
          month: "short",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          timeZone,
          ..._options,
        };

    let dateList = new Intl.DateTimeFormat("en-IN", options)
      .format(date)
      .split("-");

    dateList = dateList.map((val) => {
      if (val.includes("am") || val.includes("pm")) return val.toUpperCase();
      return val;
    });

    if (haveAt) {
      return dateList.join(" ").split(",").join(" at");
    }
    return dateList.join(" ");
  }
  return "";
};

export function validateAndConvertToISO8601(dateString) {
  // If the date string doesn't include a time part, append "00:00:00"
  if (!/\d{2}:\d{2}:\d{2}/.test(dateString)) {
    dateString += " 00:00:00";
  }

  // Validate the date string and convert to ISO 8601 format
  if (/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} [+-]\d{4}/.test(dateString)) {
    return dateString.replace(" ", "T").replace(" ", "");
  }

  return dateString;
}

/**
 *
 * @param {function} callback
 * @param {Number} delay
 * to use example  const updateApi = debounce((text) => { apicall(text)}, delay);
 * @returns
 */

let timeout;
export function debounce(callback, delay = 1000) {
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback(...args);
    }, delay);
  };
}

export const amountToCurrency = (
  amount,
  currency,
  options = { maximumFractionDigits: 2 },
  cr = false
) => {
  if (typeof amount === typeof {}) {
    currency = amount?.currency;
    amount = amount?.amount || amount?.value;
  }

  const baseCurrency = commonStore?.getState()?.client?.client?.defaultCurrency;

  const baseLocale = CURRENCY?.[baseCurrency]?.locale;

  const currencyString = new Intl.NumberFormat(baseLocale, options).format(
    amount
  );
  return `${
    currencyString === "-0"
      ? 0
      : cr
        ? currencyString.replace(/-/g, "Cr ")
        : currencyString
  } ${currency ? currency : ""}`;
};

/**
 * @param {Number} num: ex- 1234
 *
 * @returns {String} - 1,234
 */

export const commaFormattedNumber = (num, options = {}) => {
  return new Intl.NumberFormat("en-US", options).format(num);
};

export const dateDiff = (firstDate, secondDate) => {
  return firstDate && secondDate
    ? new Date(firstDate) - new Date(secondDate)
    : null;
};

/**
 * Serialize plain objects to Model instances
 * @param {Object[]}: array of plain objects
 * @param {Model}: Model
 * @param {any[]}: constructor params after the first one
 *
 * @returns {Model[]} serialized array
 */
export const mapArrayByModel = (array, Model, ...extraParams) =>
  array.map((item) => new Model(item, ...extraParams));

export const formatNumber = (num) =>
  Number.isInteger(num) ? parseInt(num, 10) : num;

export const getLegerSyncStatus = (syncStatus) => {
  if (syncStatus === ACCOUNTING_TRANSACTION_STATUS.SYNCED)
    return LEDGER_STATUSES.synced;
  if (
    syncStatus === ACCOUNTING_TRANSACTION_STATUS.VERIFIED ||
    syncStatus === ACCOUNTING_TRANSACTION_STATUS.PENDING ||
    syncStatus === ACCOUNTING_TRANSACTION_STATUS.SYNC_IN_PROGRESS
  )
    return LEDGER_STATUSES.notSynced;

  if (syncStatus === ACCOUNTING_TRANSACTION_STATUS.SYNC_FAILED)
    return LEDGER_STATUSES.syncFailed;
  return LEDGER_STATUSES.syncProgress;
};

export const getDateRange = (gap = 0, string = null) => {
  if (!string) {
    const d = new Date();

    d.setMonth(d.getMonth() - gap);
    d.setDate(1);
    const lastDate = new Date();
    if (gap === 1) {
      lastDate.setMonth(lastDate.getMonth() - 1);
    }

    return {
      startDate: new Date(d),
      endDate: new Date(lastDate.getFullYear(), lastDate.getMonth() + 1, 0),
    };
  }
  // for Today and yesterday
  const startOfDay = new Date();
  if (string === DATE_RANGE_KEYS.yesterday)
    startOfDay.setDate(startOfDay.getDate() - 1);

  startOfDay.setUTCHours(0, 0, 0, 0);

  const endOfDay = new Date();
  if (string === DATE_RANGE_KEYS.yesterday)
    endOfDay.setDate(endOfDay.getDate() - 1);

  endOfDay.setUTCHours(23, 59, 59, 999);
  return {
    startDate: startOfDay,
    endDate: endOfDay,
  };
};
export const getId = () => {
  // time stamp will give always new id and if called multiple time in one second so remove that scenerio multiplied with random
  return Math.round(new Date().getTime() + Math.random());
};

/**
 * @param {Array<Number>} arr
 *
 * @returns {Number} sum of array elements
 */
export function arraySum(arr) {
  return arr.reduce((accum, curr) => accum + curr, 0);
}

/**
 * @param {Array<Number>} arr
 * @param {Number} [available] available
 *
 * @returns {Number} sum of array elements
 */
export function distributeProportionally(arr, available) {
  const total = arraySum(arr);
  available ||= total;

  return arr.map((l) => (available * l) / total);
}

/**
 * Group provided list of items by given date key
 * @param {Array<Object>} items
 * @param {String} dateKey
 * @returns {Object}
 */
export function groupByDate(items, dateKey) {
  const groupedData = {};

  items?.forEach((item) => {
    const _dateKey =
      typeof dateKey === typeof (() => {}) ? dateKey(item) : dateKey;
    const date = new Date(item[_dateKey]);
    const options = { day: "2-digit", month: "short", year: "2-digit" };
    const sectionHead = date.toLocaleDateString("en-GB", options);

    if (groupedData[sectionHead]) {
      groupedData[sectionHead] = [...groupedData[sectionHead], item];
    } else {
      groupedData[sectionHead] = [item];
    }
  });

  return groupedData;
}

/**
 * Groups an array of items by a date key and returns a flat array with date headers inserted.
 *
 * @param {Array} items - The array of items to be grouped.
 * @param {string|function} dateKey - The key or a function to extract the date from each item.
 *    If a string is provided, it should be the key in the item object that holds the date value.
 *    If a function is provided, it should return the date value when passed an item.
 *
 * @returns {Array} - A flat array where each unique date is followed by the corresponding items.
 *    Each date header is an object with a `date` property (formatted as "dd mmm yy") and an `isDate` property set to `true`.
 *    The other items remain unchanged.
 *
 * @example
 * const items = [
 *   { id: 1, date: '2024-08-19T12:00:00Z', name: 'Item 1' },
 *   { id: 2, date: '2024-08-19T15:00:00Z', name: 'Item 2' },
 *   { id: 3, date: '2024-08-20T09:00:00Z', name: 'Item 3' }
 * ];
 *
 * const grouped = groupByDateFlat(items, 'date');
 * // grouped = [
 * //   { date: '19 Aug 24', isDate: true },
 * //   { id: 1, date: '2024-08-19T12:00:00Z', name: 'Item 1' },
 * //   { id: 2, date: '2024-08-19T15:00:00Z', name: 'Item 2' },
 * //   { date: '20 Aug 24', isDate: true },
 * //   { id: 3, date: '2024-08-20T09:00:00Z', name: 'Item 3' }
 * // ]
 */
export function groupByDateFlat(items, dateKey) {
  const dateHeaders = new Set();
  items?.forEach((item) => {
    const _dateKey = typeof dateKey === "function" ? dateKey(item) : dateKey;
    const date = new Date(item[_dateKey]);
    const options = { day: "2-digit", month: "short", year: "2-digit" };
    const sectionHead = date.toLocaleDateString("en-GB", options);

    if (!dateHeaders.has(sectionHead)) {
      dateHeaders.add(sectionHead);
    }
  });
  const groupedData = new Array(dateHeaders.size + items.length);
  const isAddedDate = new Set();
  let indexCount = 0;
  items?.forEach((item, index) => {
    const _dateKey = typeof dateKey === "function" ? dateKey(item) : dateKey;
    const date = new Date(item[_dateKey]);
    const options = { day: "2-digit", month: "short", year: "2-digit" };
    const sectionHead = date.toLocaleDateString("en-GB", options);

    if (!isAddedDate.has(sectionHead)) {
      isAddedDate.add(sectionHead);
      groupedData[indexCount] = { date: sectionHead, isDate: true };
      indexCount += 1;
    }
    groupedData[indexCount] = item;
    indexCount += 1;
  });

  return groupedData;
}

/**
 * Fetches value from given object using a keyPath
 * @param {String} keyPath
 * @param {Object} object
 * @returns
 */
export function getValueThroughKeyPath(keyPath, object) {
  let result = object;
  keyPath.split(".").forEach((key) => {
    result = result[key];
  });
  return result;
}

/**
 * @param {funtion} getErrorToastMessage
 * @description
 *   the first argument should be error, if error object is not there or you just want to pass title and desc, pass an empty string,
 *   the second argument is error title,
 *   the third argument is error description
 */

export const getErrorToastMessage = (
  error,
  errorMessage = "",
  errorDescription = ""
) => {
  let title = "";
  if (errorMessage) {
    title = errorMessage;
  }
  title ||=
    error?.response?.data?.message ??
    error?.message ??
    "errorMessage.errorOccured";

  let description = "";
  if (errorDescription) {
    description = errorDescription;
  }
  description ||=
    (error?.response?.data?.errors?.[0] ||
      error?.response?.data?.error ||
      error?.response?.data?.message ||
      error?.response?.data?.messages?.[0]) ??
    "errorMessage.somethingWentWrong";

  return {
    title,
    description,
    variant: "danger",
    noToast: error?.response?.data?.noToast,
  };
};
export const getSuccessToastMessage = (
  successResponse,
  fallbackToastOption
) => {
  const successResponseMessage =
    successResponse?.data?.messages ?? successResponse?.data?.message;
  const message =
    typeof successResponseMessage === typeof []
      ? successResponseMessage[0]
      : !successResponseMessage
        ? (successResponse?.messages ?? successResponse?.message)
        : successResponseMessage;

  return {
    title: fallbackToastOption?.title,
    description:
      fallbackToastOption?.message ?? message ?? "successfulMessage.successful",
    noToast:
      successResponse?.noToast ||
      successResponse?.data?.noToast ||
      fallbackToastOption?.noToast,
  };
};
export const numberFormatter = (number) => {
  return number?.toLocaleString("en-US");
};
export const capitalizeFirstLetter = (str) => {
  if (typeof str === typeof "" && str.length > 0) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return "";
};

export function capitalizeFirstAndLowercaseRest(word) {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

/**
 * Returns a date range object based on the specified key or custom date range.
 *
 * @param {string} key - The key representing a predefined date range (e.g., 'today', 'customRange') or 'customRange' for a custom date range.
 * @param {Array} customRange - An array containing the custom 'from' and 'to' date values when 'key' is 'customRange'.
 * @returns {Object} A date range object with 'from' and 'to' properties representing the selected date range.
 * @example
 * const predefinedRange = getRange('today');
 * // Result: { from: '02-09-2023', to: '02-09-2023' }
 *
 * const customRange = getRange('customRange', [new Date('2023-09-01'), new Date('2023-09-05')]);
 * // Result: { from: '01-09-2023', to: '05-09-2023' }
 */
export const getRange = (
  key,
  [customFrom, customTo],
  increaseByDays = 0,
  format = null
) => {
  const res = {};
  const date = new Date();
  switch (key) {
    case DATE_RANGE_KEYS.today:
      res.from = new Date();
      res.to = new Date();
      break;
    case DATE_RANGE_KEYS.yesterday:
      res.from = new Date(date.setDate(date.getDate() - 1));
      res.to = date;
      break;
    case DATE_RANGE_KEYS.tomorrow:
      res.from = new Date(date.setDate(date.getDate() + 1));
      res.to = date;
      break;
    case DATE_RANGE_KEYS.currentWeek: {
      const _currentDay = date.getDay();
      const currentWeekStart = new Date(date);
      currentWeekStart.setDate(date.getDate() - _currentDay); // Move to the start of the current week (Sunday)

      const currentWeekEnd = new Date(date);
      currentWeekEnd.setDate(date.getDate() + (6 - _currentDay)); // Move to the end of the current week (Saturday)

      res.from = currentWeekStart;
      res.to = currentWeekEnd;
      break;
    }
    case DATE_RANGE_KEYS.last7Days:
    case DATE_RANGE_KEYS.last15Days:
    case DATE_RANGE_KEYS.last30Days: {
      const days = DATE_RANGE_DAYS_COUNT_MAP[key];
      const today = new Date(date);

      const startDate = new Date(today);
      startDate.setDate(today.getDate() - days);

      const endDate = new Date(today);

      res.from = startDate;
      res.to = endDate;
      break;
    }
    case DATE_RANGE_KEYS.currentMonth:
      res.from = new Date(date.getFullYear(), date.getMonth(), 1);
      res.to = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      break;
    case DATE_RANGE_KEYS.lastMonth:
      res.from = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      res.to = new Date(date.getFullYear(), date.getMonth(), 0);
      break;
    case DATE_RANGE_KEYS.last3Month:
    case DATE_RANGE_KEYS.last6Month:
    case DATE_RANGE_KEYS.last12Month: {
      const month = DATE_RANGE_MONTHS_COUNT_MAP[key];
      const currentMonth = date.getMonth();
      let remainingYear = date.getFullYear();

      let remainingMonths = currentMonth - month;

      if (remainingMonths < 0) {
        remainingYear -= 1;
        remainingMonths += 12;
      }

      res.from = new Date(remainingYear, remainingMonths, 1);

      res.to = new Date(date.getFullYear(), currentMonth + 1, 0);
      break;
    }

    case DATE_RANGE_KEYS.quarter:
      res.from = new Date(
        date.getFullYear(),
        Math.floor(date.getMonth() / 3) * 3,
        1
      );
      res.to = new Date(
        date.getFullYear(),
        Math.floor(date.getMonth() / 3) * 3 + 3,
        0
      );
      break;

    case DATE_RANGE_KEYS.halfYearly:
      res.from = new Date(
        date.getFullYear(),
        Math.floor(date.getMonth() / 6) * 6,
        1
      );
      res.to = new Date(
        date.getFullYear(),
        Math.floor(date.getMonth() / 6) * 6 + 6,
        0
      );
      break;

    case DATE_RANGE_KEYS.next3Month:
    case DATE_RANGE_KEYS.next6Month: {
      const month = DATE_RANGE_MONTHS_COUNT_MAP[key];
      const currentMonth = date.getMonth();
      let nextYear = date.getFullYear();

      let nextMonths = currentMonth + month;

      if (nextMonths > 11) {
        nextYear += Math.floor(nextMonths / 12);
        nextMonths %= 12;
      }

      res.from = new Date(date.getFullYear(), currentMonth, 1);
      res.to = new Date(nextYear, nextMonths + 1, 0);
      break;
    }

    case DATE_RANGE_KEYS.currentYear:
      res.from = new Date(date.getFullYear(), 0, 1);
      res.to = new Date(date.getFullYear(), 11, 31);
      break;
    case DATE_RANGE_KEYS.twoYear:
    case DATE_RANGE_KEYS.threeYear:
    case DATE_RANGE_KEYS.fourYear:
    case DATE_RANGE_KEYS.fiveYear: {
      const year = DATE_RANGE_YEARS_COUNT_MAP[key];
      const currentMonth = date.getMonth();
      const remainingYear = date.getFullYear();
      const _date = date.getDate();
      const updatedYear = remainingYear + year;
      res.from = new Date(year, currentMonth, _date);

      res.to = new Date(updatedYear, currentMonth, _date);
      break;
    }
    default:
      // Handle default case if necessary
      res.to = customTo;
      res.from = customFrom;
      break;
  }
  if (res.from && res.to && increaseByDays) {
    res.from.setDate(res.from.getDate() + increaseByDays);
    res.to.setDate(res.to.getDate() + increaseByDays);
  }
  if (format) {
    res.from = getDateInPattern(res.from, format);
    res.to = getDateInPattern(res.to, format);
    return res;
  }
  return !res?.key
    ? {
        to: dateToString(
          res.to,
          {
            month: "2-digit",
            year: "numeric",
          },
          true
        ),
        from: dateToString(
          res.from,
          {
            month: "2-digit",
            year: "numeric",
          },
          true
        ),
      }
    : res;
};

/**
 * Returns a FormData object for given array of Files
 * @param {Array<File>} files
 * @param {String} key
 *
 * @returns {FormData}
 */
export const createFileFormData = (files, key = "attachments[]") => {
  const formData = new FormData();
  files
    ?.filter((doc) => doc instanceof File)
    ?.forEach((doc) => {
      formData.append(key, doc);
    });
  return formData;
};
function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !(data instanceof Blob)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[]` : key);
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
}
/**
 * Deep clone a FormData instance.
 * Needed since `structuredClone` and `JSON.parse.stringify` dont work on FormData
 * @param {FormData} data formdata instance
 *
 * @returns {FormData}
 */
export function cloneFormData(data) {
  const retFormData = new FormData();
  data.entries().forEach(([k, v]) => {
    retFormData.append(k, v);
  });
  return retFormData;
}

export function jsToFormData(data) {
  const formData = new FormData();

  buildFormData(formData, data, "");

  return formData;
}

/**
 * Cause a download from remote URL
 * @param {String} blobUrl
 * @param {String} name file will be downloaded as
 */
export const downloadFileFromBlob = async (blobUrl, name) => {
  // Create an anchor element
  const downloadLink = document.createElement("a");

  // Set the href attribute to the blob URL
  downloadLink.href = blobUrl;

  downloadLink.download = name;
  downloadLink.target = "_blank";
  // Append the anchor element to the document
  document.body.appendChild(downloadLink);

  // Trigger a click event on the anchor element
  downloadLink.click();

  // Remove the anchor element from the document
  document.body.removeChild(downloadLink);
};

export const getCorrectedKeysObject = (payload) => {
  return Object.entries(payload).reduce((accum, [key, value]) => {
    accum[camelToSnake(key)] = value;
    return accum;
  }, {});
};

export const downloadFileFromResponse = async (
  response,
  fileName = "vp_file.xlsx",
  format = "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
) => {
  try {
    const url = window.URL.createObjectURL(
      new Blob([response], { type: format })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    return true;
  } catch (error) {
    console.error("failed to download file", error);
    return false;
  }
};
/**
 * convert array into array matrix, to be able create layout grid like can be made
 *
 */
export const listToMatrix = (list, elementsPerSubArray) => {
  const matrix = [];
  let i;
  let k;

  for (i = 0, k = -1; i < list.length; i += 1) {
    if (i % elementsPerSubArray === 0) {
      k += 1;
      matrix[k] = [];
    }

    matrix[k].push(list[i]);
  }

  return matrix;
};
/**
 * caompare date
 * @param {string} date1
 * @param {string} date2
 * @returns {boolean}
 */
export function compareDates(date1String, date2String) {
  const date1 = new Date(date1String);
  const date2 = new Date(date2String);

  // Compare the timestamps to see if they match
  if (
    date1.getDate() === date2.getDate() &&
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth()
  ) {
    return true;
  }
  return false;
}

/**
 * Group provided list of items into list of list if same user
 * @param {Array<Object>} items
 * @param {String} dateKey
 * @param {String} timeKey
 * @param {String} userKey
 * @returns {Array<Object>}
 */

export function groupByDateTimeUserAndCommentType(
  items,
  dateKey,
  timeKey,
  userKey
) {
  if (items?.length > 0) {
    const groupedItems = [];

    items?.forEach((item) => {
      const {
        [dateKey]: currentDate,
        [userKey]: currentUserObject,
        [timeKey]: currentTime,
      } = item;

      let dateGroup = groupedItems?.find(
        (group) => group?.date === currentDate
      );

      if (!dateGroup) {
        dateGroup = { date: currentDate, data: [] };
        groupedItems?.push(dateGroup);
      }

      let userGroup = dateGroup.data.length
        ? dateGroup.data[dateGroup.data.length - 1]
        : null;

      if (!userGroup || userGroup.user.id !== currentUserObject.id) {
        userGroup = { user: currentUserObject, time: currentTime, data: [] };
        dateGroup?.data?.push(userGroup);
      }

      userGroup?.data?.push(item);
    });

    return groupedItems;
  }

  return [];
}

/**
 * get Local Time from Timestamp
 * @param {string} timestamp
 * @returns {string}
 */
export function getLocalTime(timestamp) {
  const dateObj = new Date(timestamp);
  let hours = dateObj.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours %= 12;
  hours = hours ? hours : 12;
  const minutes = `0${dateObj.getMinutes()}`.slice(-2);
  const timeString = `${hours}:${minutes} ${ampm}`;
  return timeString;
}

/** get 12 hour formatted time from 24hour format
 * @param {string} time24
 * @returns {string}
 */
export function convertTo12HourFormat(time24 = "") {
  const [hours, minutes, seconds] = time24.split(":");
  const period = hours >= 12 ? "PM" : "AM";
  const adjustedHours = hours % 12 || 12;
  return `${adjustedHours}:${minutes} ${period}`;
}
/**
 *field required or not in case of submmission policy
 * @param {Number} minAmount
 * @param {Boolean} required
 * @param {Number} expenseAmount
 * @returns
 */ export const fieldIsRequired = (minAmount, required, expenseAmount) => {
  return minAmount >= expenseAmount && required ? "misc.required" : null;
};

/**
 * Get Date object that's date focused, time ignorant
 *
 * Why: to avoid infinite loops when used with useForm
 *
 * @param {String | Date} date
 *
 * @returns {Date} date with time set to all zeros
 */
export const getConstantTimeDate = (date) => {
  const retVal = new Date(date);
  retVal.setHours(0, 0, 0, 0);
  return retVal;
};

/**
 * Get date by offset
 *
 * @param {Number} days +ve or -ve integer
 * @param {Date=} originalDate date
 *
 * @returns {Date}
 */
export const getDateOffsetBy = (days, originalDate = new Date()) => {
  originalDate.setDate(originalDate.getDate() + days);
  return originalDate;
};
export const getYesterdayDate = () => getDateOffsetBy(-1);
export const getTomorrowDate = () => getDateOffsetBy(+1);

/**
 * Example: 1 -> 1st, 2 -> 2nd, 3 -> 3rd
 *
 * @param {Number} num
 *
 * @return {String}
 */
export const positionifyNumber = (num) => {
  num = Math.abs(num);

  const unitDigit = num % 10;
  const tensDigit = num % 100;

  switch (true) {
    case unitDigit === 1 && tensDigit !== 11:
      return `${num}st`;
    case unitDigit === 2 && tensDigit !== 12:
      return `${num}nd`;
    case unitDigit === 3 && tensDigit !== 13:
      return `${num}rd`;
    default:
      return `${num}th`;
  }
};

/*
 * give string and return date object
 * @param {String} date
 * @returns
 */
export const dateStringToDate = (dateString) => {
  const dateStringArray = dateString
    .split("-")
    .map((val, idx) => (idx === 1 ? val - 1 : val))
    .reverse();
  return new Date(...dateStringArray);
};

/**
 * converts camelcase to snake case
 * @param {String} str
 * @returns
 */
/**
 * Converts a camelCase string to snake_case.
 *
 * @param {string} str - The camelCase string to be converted.
 * @returns {string} The snake_case version of the input string.
 * @example
 * const camelString = 'thisIsCamelCase';
 * const snakeString = camelToSnake(camelString);
 * // Result: 'this_is_camel_case'
 */

export function camelToSnake(str) {
  return (
    str[0].toLowerCase() +
    str
      .slice(1, str.length)
      .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
  );
}

export const deepCamelToSnake = (item) => {
  if (typeof item === "object" && item !== null) {
    if (Array.isArray(item)) {
      return item.map(deepCamelToSnake);
    }
    const updatedObject = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key in item) {
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty(key)) {
        const updatedKey = key.replace(
          /[A-Z]/g,
          (match) => `_${match.toLowerCase()}`
        );
        updatedObject[updatedKey] = deepCamelToSnake(item[key]);
      }
    }
    return updatedObject;
  }
  return item;
};

/**
 * Converts a camelCase string to a sentence.
 *
 * @param {string} str - The camelCase string to be converted.
 * @returns {string} The sentence made from the input string.
 * @example
 * const camelString = 'thisIsCamelCase';
 * const sentenceString = camelToSentence(camelString);
 * // Result: 'This is camel case'
 */
export function camelToSentence(str) {
  return capitalizeFirstLetter(
    str?.replace(/[A-Z]/g, (letter) => ` ${letter}`)?.toLowerCase()
  );
}

/**
 * Converts a space-separated string into camelCase.
 *
 * @param {string} inputStr - the input string to convert
 * @return {string} the camelCase version of the input string
 */
export function toCamelCase(inputStr) {
  if (!inputStr) return inputStr;

  const words = inputStr.split(" ");

  // Capitalize the first letter of each word except the first one
  const camelCaseWords = [words[0].toLowerCase()].concat(
    words.slice(1).map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  );

  // Concatenate the modified words to form the camelCase string
  const camelCaseOutput = camelCaseWords.join("");

  return camelCaseOutput;
}

/**
 * Converts a camelCase string to kebab-case.
 *
 * @param {string} str - The camelCase string to convert.
 * @returns {string} - The kebab-case string.
 */
export function camelToKebab(str) {
  // Check if the string contains any uppercase letters
  if (!/[A-Z]/.test(str)) {
    return str;
  }

  // Convert camelCase to kebab-case
  return str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}

/**
 * check if currentUser is resource (expense,payment,reimbursement etc. ) owner also
 * @param {Object} resourceUser
 * @param {Object} currentUser
 * @returns
 */
export const checkIfResouceOwner = (resourceUser, currentUser) =>
  resourceUser?.id === currentUser.id;
/**
 * check whether array have same value or not
 * @param {Array} arr1
 * @param {Array} arr2
 * @returns
 */
export function arraysHaveSameValues(arr1, arr2) {
  if (!arr1 || !arr2) return false;
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

/**
 * @param {Array}    arr1
 * @param {Array}    arr2
 * @param {Function} compareCriteria returns boolean (do match)
 */
export const arraysHaveSameContentUnordered = (
  arr1,
  arr2,
  compareCriteria = (a, b) => a === b
) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  // save memory (n*logn, n)
  const arr1Sorted = arr1.toSorted();
  const arr2Sorted = arr2.toSorted();

  // // save time (n, n)
  // const arr1Set = new Set(arr1);
  // return arr2.every((item) => arr1Set.has(item));

  return arr1Sorted.every((item, index) =>
    compareCriteria(item, arr2Sorted[index])
  );
};

/**
 * Generates dynamic names for non-accounting tags based on the number of tags provided.
 *
 * @param {Array} nonAccounting - An array of non-accounting tags.
 * @returns {Object} An object containing dynamic names for non-accounting tags with keys like "nonCategory1", "nonCategory2", etc.
 *                   The values for all keys are initially set to an empty string.
 * @example
 * const nonAccountingTags = ['Tag A', 'Tag B', 'Tag C'];
 * const dynamicNames = getDynamicNameForNonAccountingTag(nonAccountingTags);
 * // Result:
 * // {
 * //   nonCategory1: '',
 * //   nonCategory2: '',
 * //   nonCategory3: '',
 * // }
 */
export const getDynamicNameForNonAccountingTag = (nonAccounting) =>
  nonAccounting?.length
    ? nonAccounting?.reduce((acc, _, index) => {
        const key = `nonCategory${index + 1}`;
        acc[key] = "";
        return acc;
      }, {})
    : {};

/**
 * Generates dynamic names for custom tags based on the number of tags provided.
 *
 * @param {Array} customTags - An array of custom tags.
 * @returns {Object} An object containing dynamic names for custom tags with keys like "customTag1", "customTag2", etc.
 *                   The values for all keys are initially set to an empty string.
 * @example
 * const customTags = ['Tag A', 'Tag B', 'Tag C'];
 * const dynamicNames = getDynamicNameForCustomTag(customTags);
 * // Result:
 * // {
 * //   customTag1: '',
 * //   customTag2: '',
 * //   customTag3: '',
 * // }
 */
export const getDynamicNameForCustomTag = (customTags) =>
  customTags?.length
    ? customTags?.reduce((acc, _, index) => {
        const key = `customTag${index + 1}`;
        acc[key] = "";
        return acc;
      }, {})
    : {};

/**
 * Formats a given date object into a string based on the specified pattern.
 *
 * @param {Date} date - The date to be formatted.
 * @param {string} pattern - The pattern to use for formatting (e.g., "dd-mm-yyyy", "yyyy-dd-mm", "yyyy-mm-dd").
 * @param {string} currentDatePattern - The pattern of the current date.
 * @returns {string} The formatted date string or "Invalid pattern" if the pattern is not recognized.
 * @example
 * const date = new Date('2023-09-02');
 * const formattedDate = getDateInPattern(date, 'dd-mm-yyyy');
 * // Result: "02-09-2023"
 */
export function getDateInPattern(
  date,
  pattern = "yyyy-mm-dd", // most commonly used as of v2 BE
  currentDatePattern = null
) {
  if (!(date || new Date(date))) return null;
  if (date instanceof Date) date = date.toISOString();
  let _date = null;
  let year = null;
  let month = null;
  let day = null;
  if (!date) return null;
  if (currentDatePattern === "dd-mm-yyyy") {
    [day, month, year] = date.split("-").map((i) => parseInt(i, 10));
  }
  if (currentDatePattern === "yyyy-dd-mm") {
    [year, day, month] = date.split("-").map((i) => parseInt(i, 10));
  }
  if (currentDatePattern === "yyyy-mm-dd") {
    [year, month, day] = date.split("-").map((i) => parseInt(i, 10));
  }

  if (!currentDatePattern) {
    _date = new Date(date);
    year = _date.getFullYear();
    month = _date.getMonth() + 1;
    day = _date.getDate();
    // Add leading zeros if necessary
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
  }

  if (pattern === "dd-mm-yyyy") {
    return `${day}-${month}-${year}`;
  }
  if (pattern === "yyyy-dd-mm") {
    return `${year}-${day}-${month}`;
  }
  if (pattern === "yyyy-mm-dd") {
    return `${year}-${month}-${day}`;
  }
  if (pattern === "mmm dd, yyyy") {
    return `${MONTH_NAME[parseInt(month, 10) - 1]} ${day} , ${year}`;
  }

  return "Invalid pattern";
}

/**
 * Sorts an array of objects based on a specified key using a custom order defined by `configTypeOrder`.
 *
 * @param {Array} data - The array of objects to be sorted.
 * @param {Array} configTypeOrder - The custom order in which to sort the objects based on `sortedKey`.
 * @param {string} sortedKey - The key by which to sort the objects.
 * @returns {Array} The sorted array of objects.
 *
 * @example
 * const data = [
 *   { id: 1, type: 'C' },camelToSentence
 *   { id: 2, type: 'A' },
 *   { id: 3, type: 'B' },
 * ];
 * const configTypeOrder = ['A', 'B', 'C'];
 * const sortedData = getSortedArrayOnPriorityBasis(data, configTypeOrder, 'type');
 * // Result:
 * // [
 * //   { id: 2, type: 'A' },
 * //   { id: 3, type: 'B' },
 * //   { id: 1, type: 'C' },
 * // ]
 */
export const getSortedArrayOnPriorityBasis = (
  data,
  configTypeOrder = [],
  sortedKey = ""
) => {
  if (!data || data.length === 0) {
    return data; // Return empty or undefined data as is
  }
  return data.sort((a, b) => {
    const aIndex = a ? configTypeOrder?.indexOf(a[sortedKey]) : -1;
    const bIndex = b ? configTypeOrder?.indexOf(b[sortedKey]) : -1;

    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    }
    if (aIndex !== -1) {
      return -1;
    }
    if (bIndex !== -1) {
      return 1;
    }
    return 0;
  });
};

/**
 * Groups an array of items by a specified key.
 *
 * @param {Array} items - The array of items to be grouped.
 * @param {string} dateKey - The key to use for grouping.
 * @returns {Object} An object where keys are unique values from `dateKey`, and values are arrays
 *                   containing items with the same `dateKey` value.
 * @example
 * const items = [
 *   { date: '2023-09-01', value: 10 },
 *   { date: '2023-09-01', value: 20 },
 *   { date: '2023-09-02', value: 15 },
 * ];
 * const groupedData = groupByKey(items, 'date');
 *  Result:
 *  {
 *    '2023-09-01': [
 *      { date: '2023-09-01', value: 10 },
 *     { date: '2023-09-01', value: 20 },
 *    ],
 *    '2023-09-02': [
 *      { date: '2023-09-02', value: 15 },
 *    ],
 *  }
 */
export function groupByKey(items, dateKey) {
  const groupedData = {};

  items?.forEach((item) => {
    const sectionHead = item[dateKey];
    if (groupedData[sectionHead])
      groupedData[sectionHead] = [...groupedData[sectionHead], item];
    else groupedData[sectionHead] = [item];
  });

  return groupedData;
}

/**
 * Converts a flat array of items into a tree structure based on a specified key and data label.
 *
 * @param {Array} array - The flat array of items to be converted.
 * @param {string} key - The key to use for grouping and creating the tree structure.
 * @param {string} dataLabel - A label to describe the data in the resulting tree structure.
 * @returns {Array} An array of objects representing the tree structure.
 *
 * @example
 * const flatArray = [
 *   { id: 1, role: 'Admin', name: 'John' },
 *   { id: 2, role: 'Employee', name: 'Alice' },
 *   { id: 3, role: 'Admin', name: 'Bob' },
 * ];
 * const treeStructure = flatToTree(flatArray, 'role', 'User');
 * Result:
 * [
 *   { groupLabel: 'User groups', entries: [
 *       [
 *         { id: 1, role: 'Admin', name: 'John' },
 *         { id: 3, role: 'Admin', name: 'Bob' },
 *       ],
 *       [
 *         { id: 2, role: 'Employee', name: 'Alice' },
 *       ],
 *    ]},
 *    { groupLabel: 'all Users', entries: [
 *        { id: 1, role: 'Admin', name: 'John' },
 *        { id: 2, role: 'Employee', name: 'Alice' },
 *        { id: 3, role: 'Admin', name: 'Bob' },
 *    ]}
 *  ]
 */
export const flatToTree = (array, key, dataLabel) => {
  const groupedData = groupByKey(array, key);
  const groupedDataValues = Object.values(groupedData);
  return [
    { groupLabel: `${dataLabel} groups`, entries: groupedDataValues },
    { groupLabel: `all ${dataLabel}s`, entries: array },
  ];
};

/**
 * Creates a data structure suitable for a dropdown component with multiple levels, organizing
 * items based on their roles and providing labels and sublabels.
 *
 * @param {Array} array - The array of items to be organized in the dropdown.
 *
 * @returns {Array} An array of objects representing the dropdown structure.
 *
 * @example
 * const items = [
 *   { id: 1, label: 'John', role: 'Admin', subLabel: 'admin sublabel' },
 *   { id: 2, label: 'Alice', role: 'Accountant', subLabel: 'Accountants sublabel' },
 *   { id: 3, label: 'Bob', role: 'Admin', subLabel: 'admin sublabel' },
 * ];
 * const dropdownData = createPropForCrazyDropdown(items);
 *  Result:
 *  [
 *    {
 *      level: 0,
 *      groupLabel: 'User groups',
 *      entries: [
 *        {
 *          id: 1,
 *          label: 'Admins',
 *          subLabel: 'admin sublabel',
 *          children: [
 *            { id: 1, label: 'John', subLabel: 'admin sublabel', children: [] },
 *            { id: 3, label: 'Bob', subLabel: 'admin sublabel', children: [] },
 *          ],
 *        },
 *        {
 *          id: 2,
 *          label: 'Accountants managers',
 *     subLabel: 'Accountants sublabel',
 *         children: [
 *           { id: 2, label: 'Alice', subLabel: 'Accountants sublabel', children: [] },
 *         ],
 *       },
 *     ],
 *   },
 *   {
 *     groupLabel: 'All users',
 *     entries: [
 *       { id: 1, label: 'John' },
 *       { id: 2, label: 'Alice' },
 *       { id: 3, label: 'Bob' },
 *     ],
 *   },
 * ]
 * ]
 */
export const createPropForCrazyDropdown = (array) => {
  const prop = [
    {
      level: 0,
      groupLabel: "User groups",
      entries: [
        {
          id: 1,
          label: "Admins",
          subLabel: "admin sublabel",
          children: array
            .filter((item) => item.role === "Admin")
            .map((item) => ({
              id: item.id,
              label: item.label,
              subLabel: item.subLabel,
              children: [],
            })),
        },
        {
          id: 2,
          label: "Accountants managers",
          subLabel: "Accountants sublabel",
          children: array
            .filter((item) => item.role === "Accountant")
            .map((item) => ({
              id: item.id,
              label: item.label,
              subLabel: item.subLabel,
              children: [],
            })),
        },
      ],
    },
    {
      groupLabel: "All users",
      entries: array.map((item) => ({
        id: item.id,
        label: item.label,
      })),
    },
  ];

  return prop;
};

/**
 * Returns an array of keys from an object where the corresponding values are `true`.
 *
 * @param {Object} obj - The object to extract keys from.
 * @returns {Array} An array of keys with `true` values in the input object.
 * @example
 * const sampleObject = {
 *   key1: true,
 *   key2: false,
 *   key3: true,
 *   key4: true,
 * };
 * const trueKeys = getObjectKeysWithTrueValues(sampleObject);
 * // Result: ['key1', 'key3', 'key4']
 */
export const getObjectKeysWithTrueValues = (obj) => {
  return Object.entries(obj)
    .filter(([key, value]) => value === true)
    .map(([key]) => key);
};

/**
 * to flatten key we use this
 * @param {Object} obj
 * @example
 * const obj = { createdBy: {id: "",name: ""}, flag: true}
 * const result = flattenObject(obj)
 * Result:{id: "", name: "", flag: ""}
 */

export const flattenObject = (obj = {}) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value && typeof value === typeof {} && !Array.isArray(value)) {
      // If the value is an object, recurse
      // Assumption: arrays won't have nesting, so treating arrays as simple values
      Object.assign(acc, flattenObject(value, key));
    } else {
      // Otherwise, add the key-value pair to the accumulator
      acc[key] = value;
    }

    return acc;
  }, {});
};

/**
 * to flatten key we use this
 * @param {Object} obj
 * @param {String} prefix like "."
 * @param {Boolean} convertToSnakeCase
 * @returns updated object with keys flattened
 */
export function convertToGenericFormat(
  obj,
  prefix = "",
  convertToSnakeCase = false
) {
  const result = {};

  const convertKey = (key) => {
    return convertToSnakeCase
      ? key.replace(/([A-Z])/g, "_$1").toLowerCase()
      : key;
  };

  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === typeof {} && value !== null) {
      const nestedPrefix = prefix
        ? `${prefix}.${convertKey(key)}`
        : convertKey(key);
      const nestedResult = convertToGenericFormat(
        value,
        nestedPrefix,
        convertToSnakeCase
      );
      Object.assign(result, nestedResult);
    } else {
      const fieldKey = prefix
        ? `${prefix}.${convertKey(key)}`
        : convertKey(key);
      result[fieldKey] = { value };
    }
  });

  return result;
}

/**
 * Used when form fields (dropdown) can be filled in any order, but are constrained to each other
 * Used in vendor form that uses countryCurrencyMapping
 *
 * @param {Object} ds data set (Object of objects where leaf data is an array)
 * @param {Array<Function>} includeCriteria number of functions = depth - 1
 * @param {Array<Function>} doFunctions     number of functions = depth
 * @param {Array=} previousLevelPairs       kv pairs encountered until now, for internal use (recursion), useful in callbacks
 */
export const dependentDataSetsDo = (
  ds,
  includeCriteria = [],
  doFunctions = [],
  finalDoFunction = (leafValue, plp) => {},
  previousLevelPairs = [] // in order, top to bottom  <--> first to last
) => {
  if (Array.isArray(ds)) {
    ds.forEach((dsObj) => finalDoFunction(dsObj, previousLevelPairs));

    return ds;
  }

  Object.entries(ds)
    .filter(
      ([key, value]) => !includeCriteria.at(0)?.(key, value, previousLevelPairs)
    )
    .forEach(([key, value]) => {
      doFunctions.at(0)(key, value, previousLevelPairs);
      return dependentDataSetsDo(
        value,
        includeCriteria.slice(1),
        doFunctions.slice(1),
        finalDoFunction,
        [...previousLevelPairs, [key, value]]
      );
    });
};

/**
 * Return short ellipsized string
 *
 * @param {Array<String>} names array of names (strings)
 * @param {Function=} t         useTranslation().t
 *
 * @returns {String}
 */
/**
 * Formats an array of names into a concise and human-readable string.
 *
 * @param {Array} names - An array of names to be formatted.
 * @param {Function} t - A translation function for localization (optional).
 * @returns {string|undefined} The formatted string representing the names?.
 * @example
 * const nameArray1 = ['Alice', 'Bob', 'Charlie'];
 * const formattedNames1 = formatNames(nameArray1);
 * // Result: 'Alice, Bob, Charlie'
 *
 * const nameArray2 = ['Prashant', 'Ashish', 'Sanjar', 'John', 'Jane'];
 * const formattedNames2 = formatNames(nameArray2, translateFunction);
 * // Result: 'Prashant, Ashish... +2 more'
 */
export const formatNames = (names, t = () => null) => {
  const totalCount = names?.length;

  if (totalCount === 0) {
    return;
  }

  if (totalCount <= 2) {
    return names?.join(" & ");
  }

  const firstTwoNames = names?.slice(0, 2).join(", ");
  const remainingCount = totalCount - 2;

  if (remainingCount === 1) {
    const plusOneMoreLabel = t("misc.plusOneMore") || "+1 more";
    return `${firstTwoNames}... ${plusOneMoreLabel}`;
  }

  const plusMultipleMore =
    t("misc.plusXMore", { count: remainingCount }) || `+${remainingCount} more`;
  return `${firstTwoNames}, ${names?.[2]}... ${plusMultipleMore}`;
};

/**
 * Tests if a given regular expression matches a target string.
 *
 * @param {string|RegExp} regex - The regular expression pattern to test against.
 * @param {string} targetValue - The string to be tested against the regular expression.
 * @returns {boolean} `true` if the regular expression matches the target string, `false` otherwise.
 * @example
 * const pattern = /\d{3}-\d{2}-\d{4}/;
 * const targetString = '123-45-6789';
 * const isMatch = testRegex(pattern, targetString);
 * // Result: true
 */
export const testRegex = (regex, targetValue) => {
  return new RegExp(regex).test(targetValue);
};

export const removeSubstringFromString = (str, selectedstr, replaceStr) => {
  return str.replace(selectedstr, replaceStr || "");
};

export const getOrdinal = (n) => {
  let ord = "th";

  if (n % 10 === 1 && n % 100 !== 11) {
    ord = "st";
  } else if (n % 10 === 2 && n % 100 !== 12) {
    ord = "nd";
  } else if (n % 10 === 3 && n % 100 !== 13) {
    ord = "rd";
  }

  return ord;
};
/**
 * Split array into chunks of equal size
 *
 * @param {Array} arr
 * @param {Number} chunkSize
 *
 * @returns {Array<Array>}
 */
export const getChunksOfSize = (arr, chunkSize) => {
  return arr.reduce((accum, item, index) => {
    const isFirstElementOfGroup = index % chunkSize === 0;

    if (isFirstElementOfGroup) accum.push([item]);
    else accum.at(-1).push(item);

    return accum;
  }, []);
};

/**
 * Limit number of decimals of a number. Also, rounds off the part being removed.
 *
 * @param {value}
 * @param {precision}
 *
 * if the precision argument is more than digits after decimal, the value remains unchanged
 * Examples:
 * (0.00008717, 7) -> 0.0000872  // precision less than decial digits,  truncate and round of the ending
 * (0.0000871 , 7) -> 0.0000871  // precision equal to decimal digits,  no change!
 * (0.02      , 7) -> 0.02       // precision more than decimal digits, no change!
 */
export const limitDecimalsWithRounding = (value, maxDecimals = 2) => {
  const amount = parseFloat(value);
  const power = 10 ** maxDecimals;
  return Math.round(amount * power) / power;
};

/**
 *
 * This function will check every key and value of that is exactly same
 *
 * @param {Object} obj1
 * @param {Object} obj2
 *
 * @returns {Boolean}
 */

export const checkTwoObjectAreExactlySame = (obj1, obj2) => {
  if (obj1 === obj2) return true;

  if (
    typeof obj1 !== typeof {} ||
    typeof obj2 !== typeof {} ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  return keys1.every((key) => {
    if (!keys2.includes(key)) return false;
    return checkTwoObjectAreExactlySame(obj1[key], obj2[key]);
  });
};

/**
 *
 * @param {String} phoneNumber - string as phone number
 * @returns {Object}
 * {
 *   countryCode,
 *   dialCode,
 *   fullNumber,
 *   number,
 * };

 * used below library 👇
 * @link https://www.npmjs.com/package/libphonenumber-js

 * @example
 * // getDialCodeAndNumberFromPhoneNumber("+6392088723872")
 * // return {
 * //  "country": "PH",
 * //  "countryCallingCode": "63",
 * //  "nationalNumber": "92088723872",
 * //  "number": "+6392088723872",
 * //  "__countryCallingCodeSource": "FROM_NUMBER_WITH_PLUS_SIGN"
 * }
 */

export const getDialCodeAndNumberFromPhoneNumber = (phoneNumber) => {
  const data = parsePhoneNumber(phoneNumber);
  return {
    countryCode: data?.country,
    dialCode: `+${data?.countryCallingCode}`,
    fullNumber: data?.number,
    number: data?.nationalNumber,
  };
};
/*
 * Goal: Generate all possibilities given a nested object.
 * Generally used with `getRelevantValuesFromAllPossibleNodes`.
 * Tip: this is an expensive function. cache it (useMemo for example).
 *
 * @param {Object | Array } nestedObject Object of objects with multiple keys, with leaf values being array of non objects
 *
 * general form { a: {b: { d: ....{ someKey: ['stringOrNumberSomething_not_object'], ... }, ... }, ... }, ... }
 *
 * example:
 *  ```js
 *  {
 *      Singapore: {local: ['SGD', 'AED'], swift: ['USD', CAD']},
 *      India: {local: ['INR', 'NPR'], swift: ['SGD', USD']}
 *  }
 *  ```
 *
 *
 * @param {Array<String>} keys - in the output, name of key to be used in each object of output
 *
 * @returns {Array<Object>} All possibilities derivable from input. Simple array of Objects, no nesting.
 *
 * Example:
 *  input
 *  ```js
 *  nestedObj = {local: ['SGD', 'AED'], swift: ['USD', CAD']}
 *  keys = ['method', 'currency']}
 *  ```
 *  output
 *  ```js
 *  [
 *    { method: local, currency: 'SGD' },
 *    { method: local, currency: 'AED' },
 *    { method: swift, currency: 'USD' },
 *    { method: swift, currency: 'CAD' }
 *  ]
 *  ```
 *
 * Metrics - Time O(n^d), Space O(n + d), Output size O(n^d) , DX: fixed lines of code to add/remove if dropdown is added/removed, since it's recursive.
 *
 * Example code:
 * ```js
 * const allPossibleValues = cache_generateAllPossibleNodesFromNestedObject(data_nested_object, fields_array)
 * const formObject = someStateObject;
 * const relevantValues = generateAllPossibleNodesFromNestedObject(cache_generateAllPossibleNodesFromNestedObject, formObject);
 *
 * <Dropdown name="field1" options={relevantValues.field1.map()} />
 * <Dropdown name="field2" options={relevantValues.field2.map()} />
 * ```
 */
export const generateAllPossibleNodesFromNestedObject = (
  nestedObject,
  keys
) => {
  const currentKeyName = keys[0];
  if (Array.isArray(nestedObject)) {
    // ['SGD', 'AED'], ['currency'] => [{currency: 'SGD'}, { currency: 'AED'}]

    return nestedObject?.map((value) => ({
      [currentKeyName]: value,
    }));
  }

  // we are an object
  //   input: {local: ['SGD', 'AED'], swift: ['USD', CAD']}, ['method', 'currency']} =>
  //  output: [{ method: local, currency: 'SGD' }, { method: local, currency: 'AED' }, { method: swift, currency: 'USD' }, { method: swift, , currency: 'CAD' }]

  const currentLevelKeyValuePairs = Object.entries(nestedObject);

  const allDescendantsWithCurrentLevelAdded = currentLevelKeyValuePairs.map(
    ([currentLevelKey, currentLevelDescendants]) => {
      // currentLevelKey is 'local',  currentLevelChildren: ['SGD', 'AED']
      //   descendants all possiblity array
      const descendantsNodeArray = generateAllPossibleNodesFromNestedObject(
        currentLevelDescendants,
        keys.slice(1)
      ); // eq [{currency: 'SGD'}, { currency: 'AED'}]

      //  add self to all descendants
      return descendantsNodeArray.map((item) =>
        // item is {currency: 'SGD'} => { method: local, currency: 'SGD'}
        ({ [currentKeyName]: currentLevelKey, ...item })
      ); // eq [{ method: local, currency: 'SGD' }, { method: local, currency: 'AED' }]
    }
  );

  // since all descendants (recur call is an array, and we call map on each), we get array of arrays. Example (see below)
  // type, allNodesIncludingCurrentLevel
  // example
  //  [
  //    [{ method: local, currency: 'SGD' }, { method: local, currency: 'AED'   }],
  //    [{ method: swift, currency: 'USD' }, { method: swift, , currency: 'CAD' }]
  // ]
  // but since all have the same number of keys, we can combine them to form a single array.

  const allDescendantsAtOneLevelWithCurrentLevelAdded =
    allDescendantsWithCurrentLevelAdded.flat();

  return allDescendantsAtOneLevelWithCurrentLevelAdded;
};

/**
 * Get relevant values, i.e. filtered values based on filled dropdown values. From all possible values.
 * Generally used with `generateAllPossibleNodesFromNestedObject`.
 * Don't cache this, it should run on every dropdown change (useEffect w.r.t formObject for example).
 *
 * @param {Array<Object>} allPossibleNodes array of simple objects (flat). Only dropdown field names should be present here.
 * example [{ country: 'SG', method: 'local', currency: 'SGD' }, { country: 'US', method: 'swift', currency: 'CAD' }]
 *
 * @param {Object} formObject simple (flat object) containing field names and current values(or optionally functions)
 * example: { country: 'SG', method: 'local', currency: 'SGD'}. unfilled values are OK too, { country: 'SG', method: '', currency: ''}
 *
 * @param {Object} formObjectCriterias
 * `formObjectCriteria` is an optional. You can pass a "match criteria" function that determines "revevant or not",
 *  instead of the default (exact match or unfilled)
 *
 *  Usage is simple, just pass key (same as formObject key) and value a function.
 *  This will override the formObject value criteria (i.e. exact match or unfilled).
 *
 *  Criteria function: params 'possibleValue, currentlyFilledValue' as param and must return boolean (true means possibility is relevant).
 *    Additionally, the defaultCriteria is passed as third criteria - in case you want to skip custom criteria
 *
 *  Just to be clear, the default match function (if you pass string, and don't use this constiation) is exact match or unfilled value.
 *
 *  Example:
 *    formObject={ country: 'SG', method: 'local', currency: 'SGD'}
 *    formObjectCriteria={ method: (possibleValue, currentlyFilledValue, defaultCriteriaFunc=) => { return boolean } }
 *
 *  We need this if exact match is too strict. Since CountryCurrencyMappings (even in Wallex) understands only [local, swift], but fetched payment methods can be more than
 *    2 and any custom string (ideal would be [local, wallex]).
 *
 *  ---
 *
 * @returns {Object} keys are field names, and values are simple arrays (*values* for dropdown).
 * example: { country: ['IN', 'US', 'CA', 'AE'], method: ['local', 'swift'],  currency: ['INR', 'USD' , 'CAD', 'SGD', 'AED']}
 *
 * Used with `generateAllPossibleNodesFromNestedObject`
 *
 * Example code:
 * ```js
 * const allPossibleValues = cache_generateAllPossibleNodesFromNestedObject(data_nested_object, fields_array)
 * const formObject = someStateObject;
 * const relevantValues = generateAllPossibleNodesFromNestedObject(cache_generateAllPossibleNodesFromNestedObject, formObject);
 *
 * <Dropdown name="field1" options={relevantValues.field1.map()} />
 * <Dropdown name="field2" options={relevantValues.field2.map()} />
 * ```
 */
export const getRelevantValuesFromAllPossibleNodes = (
  allPossibleNodes,
  formObject,
  formObjectCriterias = null
) => {
  // internalData.filter by filled values
  // return {name1: [''], name2: [''], name3: ['']}
  const fieldsAndFilledValues = Object.entries(formObject);

  // relevantValues_
  // doing `.reduce` since we need an object
  return fieldsAndFilledValues.reduce(
    (accum, [formField, formFieldFilledValue]) => {
      // example: key=country, value='SG'

      // Find relevant nodes from alll possible list
      // internalData filtered according to currently filled values
      const relevantNodesForField = allPossibleNodes.filter((node) => {
        // since we want a list of countries (as per example) from nodes
        // we should ignore 'country', and filter out other keys (of node) w.r.t filled values

        const nodeEntries = Object.entries(node);
        return nodeEntries.every(([nodeField, nodeValue]) => {
          if (nodeField === formField) return true; // self, ignore

          // the field is something other than current key ('country')
          // try to match with filled value
          const filledValue = formObject[nodeField];
          if (!filledValue) return true; // unfilled. doesn't take part in filtering

          const defaultCriteria = (possibilityValue, filledValue_) =>
            possibilityValue === filledValue_; // default criteria, exact match

          const criteriaFunction = formObjectCriterias?.[nodeField];
          if (!criteriaFunction) return defaultCriteria(nodeValue, filledValue);

          return criteriaFunction(nodeValue, filledValue, defaultCriteria);
        });
      });

      // Combine all relevant nodes' field values, remove duplicates
      const relevantValuesForField = new Set(
        relevantNodesForField.map((item) => item[formField])
      );

      // 3. finally, return the relevant values (strings) as array
      accum[formField] = [...relevantValuesForField];

      return accum;
    },
    {}
  );
};

export function extractDate(dateString) {
  try {
    // Split the input string using the "-" delimiter
    const [day, month, year] = dateString.split("-").map(Number);

    // Check if the parsed values are valid
    if (Number.isNaN(day) || Number.isNaN(month) || Number.isNaN(year)) {
      throw new Error("Invalid date format. Please use the format dd-mm-yyyy.");
    }

    return { day, month, year };
  } catch (error) {
    // Handle the case where the input string is not in the expected format
    console.error(error.message);
    return null; // or handle the error in a way that makes sense for your application
  }
}

export const getMonthName = (selectedFrequency) => {
  let string = null;
  const date = new Date();
  switch (selectedFrequency) {
    case DATE_RANGE_KEYS.currentMonth:
      string = MONTH_NAME[date.getMonth()];
      break;
    case DATE_RANGE_KEYS.last3Month:
    case DATE_RANGE_KEYS.last6Month:
    case DATE_RANGE_KEYS.currentYear: {
      const wholeObj = getRange(selectedFrequency, []);
      const before = extractDate(wholeObj.to);
      const after = extractDate(wholeObj.from);
      string = `${MONTH_NAME[after.month - 1]} ${after.year} To ${
        MONTH_NAME[before.month - 1]
      } ${before.year}`;
      break;
    }
    default:
      string = "Custom range";
      break;
  }
  return string;
};

export const startOfMonth = (date = new Date()) => {
  return new Date(
    new Date(date.getFullYear(), date.getMonth(), 1).getTime() -
      date.getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[0];
};

export const endOfMonth = (date = new Date()) => {
  return new Date(
    new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime() -
      date.getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[0];
};

export const percentageDiff = (a, b) => {
  if (a === 0 && b === 0) {
    return 0;
  }

  return Number((((a - b) * 100) / ((a + b) / 2)).toFixed(2));
};

export const calculateAddsAndDeletes = (newArr, oldArr) => {
  const added = newArr.filter((item) => !oldArr.includes(item));
  const removed = oldArr.filter((item) => !newArr.includes(item));

  const changes = {
    added,
    removed,
  };

  return changes;
};

export function convertDateStringTOYYYYMMDD(inputDate) {
  // Split the input date into day, month, and year components
  const dateComponents = inputDate.split("-");

  // Create a new Date object using the components in the "mm-dd-yyyy" format
  const formattedDate = new Date(
    dateComponents[2],
    dateComponents[1] - 1,
    dateComponents[0]
  );

  // Extract year, month, and day from the Date object
  const year = formattedDate.getFullYear();
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
  const day = formattedDate.getDate().toString().padStart(2, "0");

  // Format the date in the "yyyy-dd-mm" pattern
  const outputDate = `${year}-${month}-${day}`;

  return outputDate;
}

export const checkHasOwnProperty = (obj, key) => {
  if (obj) return Object.prototype.hasOwnProperty.call(obj, key);
  return false;
};

export const removeFloatingZero = (amount) => {
  let _amount = amount;
  if (_amount % 1 === 0) _amount = parseInt(_amount, 10);
  return _amount;
};

/**
 * Check if regex just a single value?
 * Does a simplified check, may fail for hard edge cases
 * @param {String}
 *
 * @returns {Boolean}
 */
export const isLiteralRegex = (potentialRegex) => {
  const REGEX_VARIATION_TOKENS = [
    ".",
    "$",
    "|",
    "[",
    "]",
    "{",
    "}",
    "\\",
    "\\w",
    "\\d",
  ];

  const hasVariation = potentialRegex
    .toLowerCase()
    .split("")
    .some((letter, index) => {
      if (
        (index === 0 && letter === "^") ||
        (index === potentialRegex.length - 1 && letter === "$")
      )
        return false;

      return REGEX_VARIATION_TOKENS.includes(letter);
    });

  return !hasVariation;
};
isLiteralRegex.test = () => {
  console.log("isLiteralRegex");

  // regex which are actually literal
  console.log(isLiteralRegex("^bsb$"));
  console.log(isLiteralRegex("bsb"));
  console.log(isLiteralRegex("1/1/2001"));

  // regex with variation
  console.log(isLiteralRegex("d{0,9}"));
  console.log(isLiteralRegex("\\d{3}"));
  console.log(isLiteralRegex("\\d"));
  console.log(isLiteralRegex("[A-Za-z]+"));
  console.log(isLiteralRegex("\\w"));
  console.log(isLiteralRegex("\\s*"));
  console.log(isLiteralRegex("(abc|def)"));
};

/**
 * Remove starting (^) and ending ($) of a regex, if present.
 *
 * @param   {String}
 * @returns {String}
 */
export const removedTerminalsOfRegex = (regex) => {
  return regex
    .split("")
    .filter(
      (letter, index, { length }) =>
        !(
          (index === 0 && letter === "^") ||
          (index === length - 1 && letter === "$")
        )
    )
    .join("");
};

/**
 * Converts a list of strings into a formatted string with commas and an "and" or "&" before the last element.
 *
 * @param {Array<string>} list - The list of strings to be converted.
 * @returns {string} The formatted string.
 *
 * @example
 * const inputList = ["apple", "banana", "orange"];
 * const result = convertListOfStringToArray(inputList);
 * // Output: "apple, banana, and orange"
 *
 * @example
 * const inputList2 = ["one"];
 * const result2 = convertListOfStringToArray(inputList2);
 * // Output: "one"
 *
 * @example
 * const inputList3 = ["alpha", "beta", "gamma", "delta"];
 * const result3 = convertListOfStringToArray(inputList3);
 * // Output: "alpha, beta, gamma, and delta"
 */
export const convertListOfStringToArray = (list) => {
  if (list?.length > 1) {
    const lastValue = list.filter(
      (i, index, arr) => index === arr.length - 1
    )[0];
    const string = list
      .filter((i, index, arr) => index !== arr.length - 1)
      .join(", ");
    return `${string} ${list.length > 2 ? "and" : "&"} ${lastValue}`;
  }
  return list?.[0] ?? "";
};
/**
 * Calculates the number of non-visible words in a text container.
 * @param {Object} textRef - Reference to the text container element.
 * @param {Array} renderArray - Array of words to be rendered in the container.
 * @param {String} containerCss - CSS styles of the container.
 * @returns {number} - Number of non-visible words.
 */
export const getNonVisibleNumberOfText = (
  textRef,
  renderArray = [],
  containerCss = ""
) => {
  const wholeTextWidth = textRef?.current?.getBoundingClientRect()?.width;
  const words = renderArray;

  let visibleWidth = 0;
  let visibleWords = 0;

  words.forEach((word) => {
    const wordWidth = getTextWidth(word, containerCss); // Use a function to get accurate word width

    if (visibleWidth + wordWidth < wholeTextWidth) {
      visibleWidth += wordWidth;
      visibleWords += 1;
    } else {
      return renderArray.length - visibleWords;
    }
  });

  return renderArray.length - visibleWords;
};
/**
 * Calculates the width of a given text within a container based on the applied styles.
 * @param {String} text - The text for which the width needs to be calculated.
 * @param {String} containerCss - CSS class applied to the container for text styling (optional).
 * @returns {number} - The width of the provided text within the container.
 */

// Function to get accurate width of a text string
export const getTextWidth = (text, containerCss) => {
  const span = document.createElement("span");
  span.style.visibility = "hidden";
  span.style.position = "absolute";
  span.style.whiteSpace = "nowrap";
  span.innerText = text;
  if (containerCss)
    containerCss?.split(" ").forEach((css) => span.classList.add(css.trim()));
  document.body.appendChild(span);
  const { width } = span.getBoundingClientRect();
  document.body.removeChild(span);
  return width;
};

export function dateDifferenceInfo(startDate, endDate) {
  // Calculate the difference in milliseconds
  const timeDifference = endDate.getTime() - startDate.getTime();

  // Calculate the difference in days
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  // Calculate the difference in months and weeks
  const weeksDifference = Math.floor(daysDifference / 7);
  const monthsDifference = Math.floor(weeksDifference / 4);

  // Calculate the difference in years
  const yearsDifference = Math.floor(monthsDifference / 12);

  // Check if it can be considered as weekly, monthly, or yearly
  let durationType = "";
  if (yearsDifference >= 1) {
    durationType = DATE_DIFFRENCE_DURATIONS.YEARLY;
  } else if (monthsDifference >= 2) {
    durationType = DATE_DIFFRENCE_DURATIONS.MONTHLY;
  } else if (weeksDifference >= 2) {
    durationType = DATE_DIFFRENCE_DURATIONS.WEEKLY;
  } else {
    durationType = DATE_DIFFRENCE_DURATIONS.DAILY;
  }

  // Prepare the result string
  const result = {
    durationType,
    monthsDifference,
    weeksDifference,
    yearsDifference,
  };
  return result;
}
export const getFullMonthName = (
  dateString,
  options = {},
  langCode = "en-IN"
) => {
  const dateObj = new Date(dateString);

  if (!dateObj.valueOf()) return ""; // NaN

  return new Intl.DateTimeFormat(langCode, {
    month: "long",
    ...options,
  }).format(new Date(dateString));
};

/**
 * Get next date occurrence based on frequency
 * Follows 'Try to copy date to next month, if date is invalid, regress to end of month' for month
 * Handles to feb, from feb, 30 -> 31, 31 -> 30 all cases
 *
 * @param {Date}    date
 * @param {String}  frequency
 *
 * @returns {Date}
 */
export const getNextDate = (date, frequency) => {
  date = date && new Date(date);
  if (!date) return;

  const SPEND_FREQUENCY_inner = {
    MONTHLY: "monthly",
    WEEKLY: "weekly",
    DAILY: "daily",
    YEARLY: "yearly",
    QUARTERLY: "quarterly",
    SEMIANNUALLY: "semiannually",
  };
  // TODO: need to sync with constants SPEND_FREQUENCY

  let nextDate = null;
  switch (frequency) {
    case SPEND_FREQUENCY_inner.WEEKLY:
      nextDate = new Date(date.setDate(date.getDate() + 7));
      break;
    case SPEND_FREQUENCY_inner.DAILY:
      nextDate = new Date(date.setDate(date.getDate() + 1));
      break;
    case SPEND_FREQUENCY_inner.MONTHLY:
      nextDate = new Date(
        date.getFullYear(),
        (date.getMonth() + 1) % 12,
        date.getDate()
      );
      if (nextDate.getMonth() - date.getMonth() !== 1) {
        nextDate = new Date(
          date.getFullYear(),
          (date.getMonth() + 1 + 1) % 12,
          0
        );
      }
      break;
    case SPEND_FREQUENCY_inner.QUARTERLY:
      nextDate = new Date(
        date.getFullYear() + (date.getMonth() + 3 >= 12 ? 1 : 0),
        (date.getMonth() + 3) % 12,
        date.getDate()
      );
      if (Math.abs(nextDate.getMonth() - date.getMonth()) % 3 !== 0) {
        nextDate = new Date(
          date.getFullYear() + (date.getMonth() + 3 >= 12 ? 1 : 0),
          (date.getMonth() + 3 + 1) % 12,
          0
        );
      }
      break;
    case SPEND_FREQUENCY_inner.SEMIANNUALLY:
      nextDate = new Date(
        date.getFullYear() + (date.getMonth() + 6 >= 12 ? 1 : 0),
        (date.getMonth() + 6) % 12,
        date.getDate()
      );
      if (Math.abs(nextDate.getMonth() - date.getMonth()) % 6 !== 0) {
        nextDate = new Date(
          date.getFullYear() + (date.getMonth() + 6 >= 12 ? 1 : 0),
          (date.getMonth() + 6 + 1) % 12,
          0
        );
      }
      break;
    case SPEND_FREQUENCY_inner.YEARLY:
      nextDate = new Date(
        date.getFullYear() + 1,
        date.getMonth(),
        date.getDate()
      );
      if (Math.abs(nextDate.getMonth() - date.getMonth()) !== 0) {
        nextDate = new Date(
          date.getFullYear() + 1,
          (date.getMonth() + 1) % 12,
          0
        );
      }
      break;
    default:
      return date;
  }

  return nextDate;
};

getNextDate.test = () => {
  const tests = {
    // Monthly
    monthly: [
      [new Date(2024, 0, 28), new Date(2024, 1, 28)],
      [new Date(2024, 0, 29), new Date(2024, 1, 29)],
      [new Date(2024, 0, 30), new Date(2024, 1, 29)],
      [new Date(2024, 0, 31), new Date(2024, 1, 29)],
      //
      [new Date(2024, 1, 27), new Date(2024, 2, 27)],
      [new Date(2024, 1, 28), new Date(2024, 2, 28)],
      [new Date(2024, 1, 29), new Date(2024, 2, 29)],
      //
      [new Date(2024, 2, 29), new Date(2024, 3, 29)],
      [new Date(2024, 2, 30), new Date(2024, 3, 30)],
      [new Date(2024, 2, 31), new Date(2024, 3, 30)],
      //
      [new Date(2024, 3, 29), new Date(2024, 4, 29)],
      [new Date(2024, 3, 30), new Date(2024, 4, 30)],
    ],
    weekly: [
      //   weekly
      [new Date(2024, 0, 23), new Date(2024, 0, 30)],
      [new Date(2024, 0, 24), new Date(2024, 0, 31)],
      [new Date(2024, 0, 25), new Date(2024, 1, 1)],
      //
      //   [new Date(2024, 1, 28), new Date(2024, 1, 29)],
      [new Date(2024, 1, 29), new Date(2024, 2, 7)],
    ],
    daily: [
      //   daily
      [new Date(2024, 0, 29), new Date(2024, 0, 30)],
      [new Date(2024, 0, 30), new Date(2024, 0, 31)],
      [new Date(2024, 0, 31), new Date(2024, 1, 1)],
      //
      [new Date(2024, 1, 28), new Date(2024, 1, 29)],
      [new Date(2024, 1, 29), new Date(2024, 2, 1)],
    ],
    quarterly: [
      //   quarterly
      [new Date(2023, 10, 28), new Date(2024, 1, 28)],
      [new Date(2023, 10, 29), new Date(2024, 1, 29)],
      [new Date(2023, 10, 30), new Date(2024, 1, 29)],
      //
      [new Date(2024, 1, 28), new Date(2024, 4, 28)],
      [new Date(2024, 1, 29), new Date(2024, 4, 29)],
    ],
    semiannually: [
      //   semianually
      [new Date(2024, 7, 28), new Date(2025, 1, 28)],
      [new Date(2024, 7, 29), new Date(2025, 1, 28)],
      [new Date(2024, 7, 30), new Date(2025, 1, 28)],
      [new Date(2024, 7, 31), new Date(2025, 1, 28)],
      //
      [new Date(2024, 1, 28), new Date(2024, 7, 28)],
      [new Date(2024, 1, 29), new Date(2024, 7, 29)],
    ],
    yearly: [
      //   yearly
      [new Date(2024, 0, 28), new Date(2025, 0, 28)],
      [new Date(2024, 0, 29), new Date(2025, 0, 29)],
      [new Date(2024, 0, 30), new Date(2025, 0, 30)],
      [new Date(2024, 0, 31), new Date(2025, 0, 31)],
      //
      [new Date(2024, 1, 28), new Date(2025, 1, 28)],
      [new Date(2024, 1, 29), new Date(2025, 1, 28)],
      //
      [new Date(2027, 1, 28), new Date(2028, 1, 28)],
      [new Date(2027, 2, 1), new Date(2028, 2, 1)],
    ],
  };
  console.log(
    `Input${Array(9 - 5)
      .fill(" ")
      .join("")} Result${Array(9 - 6)
      .fill(" ")
      .join("")} Output`
  );
  Object.entries(tests).forEach(([key, candidates]) => {
    console.log({ key });
    candidates.map(([input, output]) => {
      const result = getNextDate(input, key);
      const str = `${input.toLocaleDateString()} ${result.toLocaleDateString()} ${output.toLocaleDateString()} ${
        result.toString() === output.toString() ? "Ok" : "Not ok"
      }`;
      console.log(str);
    });
    console.log("---");
  });
};
// getNextDate.test();

/**
 * Returns the value in MB or KB based on the input bytes.
 *
 * @param {number} bytes - the input value in bytes
 * @return {Object} an object with the value and type (MB or KB)
 */
export const getMBs = (bytes) => {
  const mb = 1024 * 1024;
  if (bytes >= mb)
    return { value: (bytes / (1024 * 1024)).toFixed(2), type: "MB" };
  return { value: (bytes / 1024).toFixed(2), type: "KB" };
};
/**
 * Converts a number to its ordinal form.
 *  convert number to 1st 2nd 3rd like this
 * @param {number} number - The number to convert
 * @return {string} The ordinal form of the input number
 */
export const convertNumberToOrdinalNumber = (number) => {
  const reimOf10 = number % 10;
  const reimOf100 = number % 100;
  if (reimOf10 === 1 && reimOf100 !== 11) {
    return `${number}st`;
  }
  if (reimOf10 === 2 && reimOf100 !== 12) {
    return `${number}nd`;
  }
  if (reimOf10 === 3 && reimOf100 !== 13) {
    return `${number}rd`;
  }
  return `${number}th`;
};

export const dotSeparatedString = (arr = []) => {
  return arr.join(" • ");
};

// TECH_DEBT: other functions of this type don't handle nesting, use this and delete them
/**
 * Proper function, that takes complex object (nested arrays, objects) and generates formData in proper format,
 * values may have `File` objects, takes care of them too.
 *
 * for arrays, update notation is [index]
 * for objects, update notation is [key]
 *
 * @param {Object} data complex object, may have nesting of arrays, objects, `File` values
 * @param {Object} formData existing formdata, optional
 * @param {String} parentKey
 *
 * @returns {FormData}
 */
export function objectToFormData(
  data,
  formData = new FormData(),
  parentKey = null
) {
  if (data === null || data === undefined) {
    return formData;
  }

  Object.keys(data).forEach((key) => {
    const value = data[key];
    // Generate the form key for arrays and objects
    const formKey = parentKey ? `${parentKey}[${key}]` : key;

    if (Array.isArray(value)) {
      value.forEach((val, index) => {
        if (
          val &&
          typeof val === "object" &&
          !(val instanceof File) &&
          !(val instanceof Blob) &&
          !(val?.uri && val?.type && val?.size) // mobile doesn't release `File` instance, but normal object, this is kind of a unique way to detect
        ) {
          objectToFormData(val, formData, `${formKey}[${index}]`);
        } else {
          // Handle the array of primitive types
          formData.append(`${formKey}[]`, val);
        }
      });
    } else if (
      value &&
      typeof value === "object" &&
      !(value instanceof Date) &&
      !(value instanceof File) &&
      !(value instanceof Blob) &&
      !(value?.uri && value?.type && value?.size) // mobile doesn't release `File` instance, but normal object, this is kind of a unique way to detect
    ) {
      // Recurse for nested objects
      objectToFormData(value, formData, formKey);
    } else {
      // Append the value to the FormData object for primitive types
      formData.append(formKey, value);
    }
  });

  return formData;
}

/**
 * Get lapsed time
 *
 * @param {Date | String} date
 * @param {String} languageCode changes string format, minor strings (12 Aug 2020 vs Aug 12 2020)
 *
 * Note: 'min', 'sec', 'd', 'h' are hardcoded.
 *
 * Works fine with Feb 28, 29 as per leap year
 */
export const getDatetimeBasedOnLapsed = (date, languageCode = "en-IN") => {
  date = new Date(date); // safety
  const now = new Date();
  const diff = Math.abs(now - date);

  // Milliseconds in different time intervals
  const second = 1000;
  const minute = 60 * second;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;
  const year = 365 * day;

  if (diff < minute) {
    const seconds = Math.floor(diff / second);
    return `${seconds} sec`;
  }
  if (diff < hour) {
    const minutes = Math.floor(diff / minute);
    return `${minutes} min`;
    // eslint-disable-next-line no-else-return
  } else if (diff < day) {
    const hours = Math.floor(diff / hour);
    return `${hours}h`;
  } else if (diff < week) {
    const days = Math.floor(diff / day);
    return `${days}d`;
  } else if (diff < year) {
    const options = { month: "short", day: "numeric" };
    return date.toLocaleDateString(languageCode, options);
  } else {
    const options = { month: "short", day: "numeric", year: "numeric" };
    return date.toLocaleDateString(languageCode, options);
  }
};

export const isBoolean = (val) =>
  typeof val === typeof true || val instanceof Boolean;
/**
 * Rounds a number to two decimal places.
 *
 * @param {number} number - The number to round.
 * @returns {number} The rounded number.
 */
export function roundToTwoDecimalPlaces(number) {
  return Math.round(number * 100) / 100;
}
/**
 * Calculates the split amount for an expense based on the total expense amount.
 *
 * @param {number} totalExpense - The total expense amount.
 * @returns {number[]} An array containing the split amount for each person.
 */
export function splitExpenseAmount(totalExpense) {
  // Calculate the base amount each person should pay by dividing the total expense by 2
  const baseAmount = totalExpense / 2;

  // Round down the base amount to two decimal places using Math.floor to avoid floating-point issues
  const roundedBaseAmount = Math.floor(baseAmount * 100) / 100;

  // Assign the first person's amount to the rounded base amount
  const finalAmount1 = roundedBaseAmount;

  // Calculate the second person's amount as the remainder of the total expense minus the rounded base amount
  let finalAmount2 = totalExpense - roundedBaseAmount;

  // Ensure finalAmount2 is formatted to two decimal places
  finalAmount2 = finalAmount2.toFixed(2);

  // Return an array with both amounts, making sure both values are properly formatted to two decimal places
  return [parseFloat(finalAmount1.toFixed(2)), parseFloat(finalAmount2)];
}

export const inVendorMailFlow = () => {
  // Example: /add-bank-account/pgqb0B7Wpakf2sxIDXqCsJsg
  const initialPart = `/${ROUTES.billpay.addBankDetails.absolutePath}/`;
  const remainingPart = window.location.pathname.replace(initialPart, "");
  return (
    window.location.pathname.startsWith(initialPart) &&
    !remainingPart.includes("/")
  );
};

export const accountingSelectedTabs = (
  accountingSoftware,
  path,
  pendingCount
) => [
  {
    name: "expenseStatuses.all",
    count: "",
    path: ROUTES.accounting.transactions[path].all.absolutePath,
    key: 1,
  },
  {
    name: "expenseStatuses.pending",
    count:
      Number.parseInt(pendingCount, 10) > 10000 ? "9999+" : `${pendingCount}`,
    path: ROUTES.accounting.transactions[path].pending.absolutePath,
    key: 2,
  },
  {
    name:
      accountingSoftware === ACCOUNTING_SOFTWARES.UNIVERSAL_CSV
        ? "expenseStatuses.exported"
        : "expenseStatuses.synced",
    count: "",
    path: ROUTES.accounting.transactions[path].synced.absolutePath,
    key: 3,
  },
];

export const checkIfURLIsValid = async (url, trimBlob = false) => {
  const finalUrl = trimBlob ? url?.replace("blob:", "") : url;
  const [error, response] = await to(
    fetch(finalUrl, {
      method: "GET",
    })
  );

  return !!response?.ok; // Fetch error, URL is considered invalid
};

/**
 * Gets all the allowed user actions for the current page
 * @param {*} allNavUrls          all available navigation urls mapping
 * @param {*} userAllowedActions  user allowed actions config
 * @returns {Array}
 */
export const allowedActionsForCurrentPage = (
  allNavUrls,
  userAllowedActions
) => {
  //  converting all url key as path and value as key
  const _allKeys = allNavUrls?.reduce(
    (accum, item) => ({ ...accum, [item.path]: item.key }),
    {}
  );
  let completeRoute = window.location.pathname;

  let currentActionKey = null;
  // checking from end to find best suited url and its key
  while (completeRoute?.length > 1) {
    currentActionKey = _allKeys[completeRoute];
    if (currentActionKey) break;
    completeRoute = completeRoute.split("/").slice(0, -1).join("/");
  }
  return currentActionKey ? userAllowedActions?.[currentActionKey] : [];
};

/**
 * Checks if a given cta is allowed by checking the user actions config
 * @param {*} allNavUrls          all available navigation urls mapping
 * @param {*} userAllowedActions  user allowed actions config
 * @param {*} ctaKey              cta key
 * @returns {Boolean}
 */
export const checkIfUserActionAllowed = (
  allNavUrls,
  userAllowedActions,
  ctaKey
) => {
  const actions = allowedActionsForCurrentPage(allNavUrls, userAllowedActions);
  return actions?.length && actions.includes(ctaKey);
};

export const checkIfUserActionAllowedUsingActionKey = (
  key,
  userAllowedActions,
  ctaKey
) => {
  const actions = userAllowedActions[key];
  return actions?.length && actions.includes(ctaKey);
};

export const getRedirectionLinkForKycStatusApproved = (allNavUrls) => {
  const allNavKeys = allNavUrls.map(({ key }) => key);
  if (allNavKeys.includes("dashboard"))
    // Admin goes to dashboard
    return ROUTES.dashboard.base.absolutePath;
  if (allNavKeys.includes("myVolopay:gettingStarted"))
    // Member goes to dashbaord
    return ROUTES.myVolopay.gettingStarted.absolutePath;
  if (allNavKeys.includes("accounting"))
    // External Accountant goes to dashbaord
    return ROUTES.accounting.transactions.absolutePath;
  // Fallback to myvolopay (this scenario should never occur)
  return ROUTES.myVolopay.gettingStarted.absolutePath;
};

/**
 *
 * Use with core/Tooltip id prop and on the anchor's id
 * Sometimes Tooltip does not work due to characters like below, (possible messes with CSS)
 */
export const getTooltipFriendlyId = (id) => {
  return `${id}`
    .replaceAll(".", "-")
    .replaceAll("#", "-")
    .replaceAll(" ", "-")
    .replaceAll(",", "-")
    .replaceAll("/", "-");
};

/**
 * Convert an array containing File instances or {uri, fileName, fileType}
 * into File instances (uniformly typed array).
 * Needed for multi-file upload since core components returns heterogeneous typed array
 *
 * @param {Array<File | Object>}
 *
 * @returns {Array<File>}
 */
/* eslint-disable no-await-in-loop */
export const convertToFileInstances = async (fileObjects) => {
  const convertedFiles = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const fileObject of fileObjects) {
    if (fileObject instanceof File) {
      // If it's already a File instance, just push it to the converted array
      convertedFiles.push(fileObject);
    } else {
      // If it's a {fileName, fileType, uri} object, fetch its content and create a File instance
      const response = await fetch(fileObject.uri);
      const blob = await response.blob();
      const file = new File([blob], fileObject.fileName, {
        type: fileObject.fileType,
      });
      convertedFiles.push(file);
    }
  }

  return convertedFiles;
};
/* eslint-enable no-await-in-loop */

export const formatAndCleanUpCaptchaText = (userName, uppercase = true) => {
  if (!userName) return "";
  const formattedName = userName?.replaceAll(/\s{2,}/g, " ");

  if (uppercase) return formattedName?.toUpperCase();
  return formattedName;
};

/**
 * Generates an array of numbers from a start value to an end value with a specified step.
 *
 * @param {number} start - The starting value of the range.
 * @param {number} [end] - The ending value of the range. If not provided, it will be set to the value of `start`.
 * @param {number} [step=1] - The increment between each value in the range. Default is 1.
 * @throws {Error} Throws an error if the step is zero.
 * @return {number[]} An array of numbers from the start value to the end value with the specified step.
 */
export function range(start, end, step = 1) {
  // Handle the case when only one argument is provided
  if (end === undefined) {
    end = start;
    start = 0;
  }

  // Handle the case when the step is zero (which would cause an infinite loop)
  if (step === 0) {
    throw new Error("Step cannot be zero.");
  }

  // Initialize an empty array to hold the range values
  const rangeArray = [];

  // If step is positive, count up from start to end
  if (step > 0) {
    for (let i = start; i < end; i += step) {
      rangeArray.push(i);
    }
  }
  // If step is negative, count down from start to end
  else {
    for (let i = start; i > end; i += step) {
      rangeArray.push(i);
    }
  }

  // Return the array of range values
  return rangeArray;
}

export const isEllipsisActive = (ele) => {
  return ele?.offsetWidth < ele?.scrollWidth;
};
/**
 * Checks if the given number, when rounded to two decimal places, is not zero.
 *
 * This function converts the input to a number, rounds it to two decimal places,
 * and then compares it to "0.00". If the rounded number is not "0.00", it returns true,
 * indicating that the amount is not zero. Otherwise, it returns false.
 *
 * @param {number|string} number - The number to be checked. Can be a number or a string that can be converted to a number.
 * @returns {boolean} - Returns true if the number is not zero when rounded to two decimal places, otherwise returns false.
 *
 * @example
 * checkSplitAmountIsZeroOrNot(0); // false
 * checkSplitAmountIsZeroOrNot(0.004); // true
 * checkSplitAmountIsZeroOrNot('0.004'); // true
 * checkSplitAmountIsZeroOrNot('0.00'); // false
 */
export const checkSplitAmountIsZeroOrNot = (number) => {
  return Number(number).toFixed(2) !== "0.00";
};

/**
 * Checks if the given date is today's date.
 *
 * @param {Date|string} date - The date to be checked. Can be a Date object or a string that can be converted to a Date.
 * @returns {boolean} - Returns true if the date is today, otherwise returns false.
 *
 * @example
 * checkIfDateCurrentDate(new Date()); // true
 * checkIfDateCurrentDate('2023-09-02'); // true if today is 2023-09-02
 * checkIfDateCurrentDate('2023-09-03'); // false if today is 2023-09-02
 */
export const checkIfDateCurrentDate = (date) => {
  return (
    new Date(date).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
  );
};

/**
 * Extracts the initial values from an object.
 *
 * The input object should have a structure like this:
 * {
 *   key1: { value: value1, ...otherProps },
 *   key2: { value: value2, ...otherProps },
 *   ...
 * }
 *
 * The function returns an object with the same keys as the input object, but
 * with the values being the `value` property of the corresponding object in the
 * input object.
 *
 * @param {Object} obj - The input object
 * @returns {Object} - An object with the same keys as the input object, but with
 * the values being the `value` property of the corresponding object in the input
 * object.
 *
 * @example
 * extractValuesFromInitialValue({
 *   key1: { value: 'value1' },
 *   key2: { value: 'value2' },
 * });
 * // {
 * //   key1: 'value1',
 * //   key2: 'value2',
 * // }
 */
export function extractValuesFromInitialValue(obj) {
  return Object.entries(obj).reduce((acc, [key, valueObj]) => {
    acc[key] = valueObj.value;
    return acc;
  }, {});
}

export function convertToArrayGetRange(date) {
  if (date?.from && date?.to) return [date?.to, date?.from];
  console.warn("date can not be converted", date);
}

export function convertRangeToText(date) {
  if (date?.from && date?.to) return `${date?.from} - ${date?.to}`;
  console.warn("date can not be converted", date);
}
export const toTitleCase = (text) => {
  if (!text) return "";
  return text[0].toUpperCase() + text.substr(1);
};

/**
 * Generates a random color for a given name.
 * @param {string} name - The name to generate a color for.
 */
export const getMemoizedColor = (() => {
  const colorMap = new Map();
  return (name) => {
    if (!colorMap.has(name)) {
      const randomColor =
        COLOR_CODE_LIST[Math.floor(Math.random() * COLOR_CODE_LIST.length)];
      colorMap.set(name, randomColor);
    }
    return colorMap.get(name);
  };
})();

/**
 * Converts the keys of all objects in an array to snake case.
 *
 * @param {Array<Object>} array - The array of objects to convert.
 * @returns {Array<Object>|null} - The array of objects with snake case keys,
 * or null if the input array is empty or null.
 */
export const convertArrayKeysToSnake = (array) => {
  return array?.length
    ? array.map((obj) => {
        const newObj = {};
        Object.keys(obj).forEach((key) => {
          const newKey = camelToSnake(key);
          newObj[newKey] = obj[key];
        });
        return newObj;
      })
    : null;
};

/**
 * Decrypts user encrypted data using AES encryption.
 *
 * @param {Object} encryptedObject - The encrypted data object.
 * @param {string} encryptedObject.encryptedData - The Base64 encoded encrypted data.
 * @param {string} encryptedObject.iv - The Base64 encoded initialization vector.
 * @param {Object} userDetails - The user details used for deriving the decryption key.
 * @param {string} userDetails.departmentId - The department ID of the user.
 * @param {string} userDetails.id - The user ID.
 * @param {string} userDetails.email - The user's email address.
 * @returns {Object|null} - The decrypted user details object or null if decryption fails.
 */
export const decryptData = ({ encryptedData, iv }, userDetails) => {
  // Decode the Base64 encrypted data back to its original byte array
  const userKey = () => {
    const input = `${userDetails.departmentId}|${userDetails.id}|${userDetails.email}`;
    const iterations = 10000;
    const keyLength = 32;
    const keySize = keyLength / 4;
    const key = CryptoJS.PBKDF2(
      input,
      import.meta.env.VITE_ROLE_AND_PERMISSIONS_PRIVATE_KEY_SALT,
      {
        keySize,
        iterations,
        hasher: CryptoJS.algo.SHA256,
      }
    );
    return key.toString(CryptoJS.enc.Base64);
  };

  const encryptedBase64 = CryptoJS.enc.Base64.parse(encryptedData);
  const secretKey = CryptoJS.enc.Base64.parse(userKey());
  const secretIv = CryptoJS.enc.Base64.parse(iv);
  // Decrypt the data using AES with the key and IV
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: encryptedBase64 },
    secretKey,
    {
      iv: secretIv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
};

/**
 * Filters the options in a filter component based on available modules.
 *
 * This function takes a filter component and an array of available modules, then filters
 * the options in the filter component. It returns only the options that either have no associated
 * module or are associated with a module present in the `availableModules` array.
 *
 * @param {Object} filter - The filter component containing props and options to filter.
 * @param {Array<string>} availableModules - An array of module names that are available.
 *
 * @returns {Object} - The updated filter component with filtered options.
 */
export const getAvailableOptionBasedOnModule = (filter, availableModules) => {
  const options = filter?.props?.options;
  const _options = options?.filter(
    (option) => !option?.module || availableModules?.includes(option?.module)
  );
  filter.props.options = _options;
  return filter;
};

/**
 * Join an array of sentences into a single sentence. If there is only one sentence,
 * return it as is. If there are two sentences, join them with "and". If there are
 * more than two sentences, join all but the last one with commas, and then add
 * the last one with "and".
 *
 * @param {string[]} sentences The sentences to join
 * @param {function} t The translation function
 * @returns {string} The joined sentence
 */
export function joinArrayOfSentences(sentences, t) {
  if (sentences && sentences.length === 0) {
    return "";
  }
  if (sentences.length === 1) {
    return sentences[0];
  }

  if (sentences.length === 2) {
    return sentences.join(` ${t("misc.and")} `);
  }

  const lastSentence = sentences.pop();
  return `${sentences.join(", ")} ${t("misc.and")} ${lastSentence}`;
}

/**
 * Sums two floating-point numbers gracefully by avoiding floating-point arithmetic issues.
 *
 * @param {number} num1 - The first floating-point number to be summed.
 * @param {number} num2 - The second floating-point number to be summed.
 * @returns {number} The sum of the two floating-point numbers.
 */
export function gracefullySumFloatNumber(num1, num2) {
  return (num1 * 100 + num2 * 100) / 100;
}

/**
 * Subtracts two floating-point numbers gracefully by avoiding floating-point arithmetic issues.
 *
 * @param {number} num1 - The first floating-point number to be subtracted.
 * @param {number} num2 - The second floating-point number to be subtracted.
 * @returns {number} The difference of the two floating-point numbers.
 */
export function gracefullySubtractFloatNumber(num1, num2) {
  return (num1 * 100 - num2 * 100) / 100;
}
